import axios from '@/lib/axios'

const initialState = {
  loading: false,
  patients: [],
  count: 0,
  numberOfPages: 0,
}

const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_PATIENTS(state, payload) {
    state.patients = payload
  },
  SET_COUNT(state, payload) {
    state.count = payload
  },
  SET_NUMBER_OF_PAGES(state, payload) {
    state.numberOfPages = payload
  },
}

const actions = {
  listPatients({ commit }, filters = {}) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.get('patient/radar', { params: filters })
        .then(({ data: { patients, count, numberOfPages } }) => {
          commit('SET_PATIENTS', patients)
          commit('SET_COUNT', count)
          commit('SET_NUMBER_OF_PAGES', numberOfPages)
          resolve(patients)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
}

const getters = {
  loading: state => state.loading,
  patients: state => state.patients,
  count: state => state.count,
  numberOfPages: state => state.numberOfPages,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
