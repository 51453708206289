<template>
  <div>
    <component-title
      new-care-team
      @update:care-team="listCareTeam"
    />
    <care-team-list />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import { mapActions } from 'vuex'
import CareTeamList from './CareTeamList'

export default {
  components: {
    ComponentTitle,
    CareTeamList,
  },
  methods: {
    ...mapActions({
      listCareTeam: 'careTeam/listCareTeam',
    }),
  },
}
</script>
