<template>
  <vc-date-picker
    v-model="filter.model"
    :is-range="filter.isRange"
    locale="pt-PT"
    color="green"
    :attributes="filter.attributes"
  >
    <template v-slot="{ inputValue, inputEvents }">
      <v-text-field
        :value="formattedDate"
        hide-details
        clearable
        readonly
        label="Data"
        append-icon="mdi-calendar"
        filled
        @click:clear="resetDateFilter()"
        v-on="inputEvents.start || inputEvents"
      />
    </template>
  </vc-date-picker>
</template>

<script>
import moment from 'moment'
import {
  isNil,
  equals,
  join,
  values,
  mapObjIndexed,
} from 'ramda'

export default {
  name: 'AmparoCalendar',
  props: {
    filterObject: {
      type: Object,
      required: true,
    },
  },
  computed: {
    filter: {
      get() {
        return this.filterObject
      },
      set(value) {
        this.$emit('update:filter', value)
      },
    },
    formattedDate() {
      if (isNil(this.filter.model)) return ''

      return this.filter.isRange
        ? this.formatDateByRange(this.filter.model)
        : this.$filters.formatDate(this.filter.model)
    },
  },
  methods: {
    resetDateFilter() {
      this.filter.model = this.filter.isRange ? {} : null
    },
    formatDateByRange(datesObj) {
      const formattedDates = mapObjIndexed(
        date => moment(date).format('DD/MM/YYYY'),
        datesObj,
      )
      return equals(formattedDates.start, formattedDates.end)
        ? formattedDates.start
        : join(' a ')(values(formattedDates))
    },
  },
}
</script>
