<template>
  <div>
    <v-container
      :class="{
        'fluid': true,
        'px-10': $vuetify.breakpoint.smAndUp,
        'px-16': $vuetify.breakpoint.lgAndUp,
        'grid-list-lg': true
      }"
    >
      <v-layout>
        <div
          class="div--clickable ml-2 my-4"
          @click="redirectToListContract"
        >
          <icon-previous icon-color="green" />
        </div>
      </v-layout>
      <v-flex class="justify-space-between">
        <div>
          <div class="mb-6 text-h6 font-weight-bold">
            {{ contract.name || 'N/A' }} - {{ contractHmoName }} - {{ contractHealthProductName }}
            <p class="text-h6 ml-2">
              {{ contractStatusText }}
            </p>
          </div>

          <div class="mb-6">
            <h4>Nome</h4>
            <p class="text-body-1">
              {{ contract.name || '-' }}
            </p>
          </div>

          <v-flex class="mb-2">
            <div>
              <h4>Data de inicio</h4>
              <p class="text-body-1">
                {{ contract.startDate || '-' | formatDate }}
              </p>
            </div>
          </v-flex>

          <div class="mb-6">
            <h4>Descrição</h4>
            <p class="text-body-1 contract-description__text">
              {{ contract.description || '-' }}
            </p>
          </div>

          <div class="mb-6">
            <h4>Jornada de ativação</h4>
            <p
              v-if="journeys && journeys.length === 0"
              class="text-body-1"
            >
              Sem Jornada de ativação configurada
            </p>
            <v-row
              v-else
              class="mt-3"
            >
              <v-col
                v-for="(journey, index) of journeys"
                :key="index"
              >
                <v-card
                  elevation="3"
                  width="300"
                  height="auto"
                  class="pl-2 pb-2 ml-1"
                >
                  <v-card-title class="pl-0">
                    {{ journey.clinic ? 'Unidade ' + journey.clinic.name : 'Padrão' }}
                  </v-card-title>
                  <p class="font-weight-medium">
                    Slots por especialidade:
                  </p>
                  <p class="font-weight-regular">
                    Médico de família:
                    <span class="font-weight-bold">
                      {{ journey.doctorSlots }}
                    </span>
                  </p>
                </v-card>
              </v-col>
            </v-row>
          </div>

          <div class="mb-6">
            <h4>Obrigatoriedade de RQE</h4>
            <p class="text-body-1">
              {{ contractRequiredRqeText }}
            </p>
          </div>

          <v-flex class="mb-6">
            <div>
              <h4>Usuario criador</h4>
              <p class="text-body-1">
                {{ userCreatedBy || '-' }}
              </p>
            </div>
            <div class="ml-10">
              <h4>Data de Criação</h4>
              <p class="text-body-1">
                {{ contract.createdAt | formatDate }}
              </p>
            </div>
          </v-flex>

          <h4>
            Contrato Original
          </h4>
          <list-attachments
            :contract-id="contract.id"
            :attachments="contract.contractAttachments"
            :limited-list="true"
            :route="'/billing/contract_attachment'"
            :update-data="getContractData"
            type="general"
          />
        </div>
        <div>
          <v-layout class="align-center">
            <v-flex v-if="hasContractLog">
              <div>
                <v-card
                  width="200"
                  class="card-log"
                >
                  <v-list>
                    <v-list-group
                      :value="false"
                    >
                      <template v-slot:activator>
                        <v-list-item-title class="card-log__title">
                          Histórico
                        </v-list-item-title>
                      </template>
                      <v-list-item
                        v-for="log in contractLog"
                        :key="log.id"
                        three-line
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ log.userName }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ log.date | dateWithBrTime }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            {{ getContractStatusValue(log.oldStatus) }} -
                            {{ getContractStatusValue(log.newStatus) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                </v-card>
              </div>
            </v-flex>
            <v-flex
              v-if="!contractIsArchived"
              class="align-self-start mt-3"
            >
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    text
                    icon
                    @click="redirectToEditContract"
                    v-on="on"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
      <v-flex class="text-h5 font-weight-bold my-4">
        Tabela de Cobertura
      </v-flex>
      <v-btn
        color="primary"
        class="text-capitalize text-subtitle-1 font-weight-lightbold pl-1 mb-2"
        text
        @click="showDownloadXlsxDialog = true"
      >
        Download Planilha
        <v-icon right>
          mdi-download
        </v-icon>
      </v-btn>
      <v-text-field
        v-model="searchName"
        label="Buscar item"
        class="mb-4"
        clearable
        hide-details
        filled
      />
      <contract-items-table
        :contract-items="contractItems"
        :page="page"
        @setPageAndListItems="setPageAndListItems"
      />
      <download-contract-xlsx-dialog
        :show-download-xlsx-dialog="showDownloadXlsxDialog"
        :contract="contract"
        @closeDialog="showDownloadXlsxDialog = false"
      />
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
import {
  defaultTo,
  equals,
  find,
  isEmpty,
  isNil,
  path,
  prop,
  propEq,
  reject,
  values,
} from 'ramda'
import { CONTRACT } from 'amparo-enums'

export default {
  name: 'ContractItemContainer',
  components: {
    ContractItemsTable: () => import('@/components/Common/ContractItemsTable'),
    DownloadContractXlsxDialog: () => import('@/components/Common/DownloadContractXlsxDialog'),
    IconPrevious: () => import('@/components/UI/IconPrevious'),
    ListAttachments: () => import('@/components/Common/ListAttachments'),
  },
  data() {
    return {
      notFoundMessage: 'Não encontrado',
      page: 1,
      showDownloadXlsxDialog: false,
      isDownloadingXslsx: false,
      searchName: '',
      isRqeTextRequired: {
        true: {
          label: 'Sim',
          value: true,
        },
        false: {
          label: 'Não',
          value: false,
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      contract: 'billing/contract',
      contractItems: 'billing/contractItems',
    }),
    journeys() {
      return this.contract.journeys
    },
    contractStatusText() {
      return this.getLabelBy(this.contract.status, CONTRACT.status)
    },
    contractRequiredRqeText() {
      return this.getLabelBy(this.contract.requiredRqe, this.isRqeTextRequired)
    },
    userCreatedBy() {
      return path(['createdBy', 'name'], this.contract)
    },
    contractIsArchived() {
      return equals(this.contract.status, CONTRACT.status.archived.value)
    },
    contractHmoName() {
      return path(['hmo', 'name'], this.contract)
    },
    contractHealthProductName() {
      return path(['healthProduct', 'name'], this.contract)
    },
    hasContractLog() {
      return !(
        isNil(this.contract.log)
        || isEmpty(this.contractLog)
      )
    },
    contractLog() {
      return reject(
        ({ oldStatus, newStatus }) => equals(oldStatus, newStatus),
        defaultTo([], this.contract.log),
      )
    },
  },
  watch: {
    searchName: debounce(async function search() {
      await this.setPageAndListItems(1)
    }, 500),
  },
  methods: {
    ...mapActions({
      getContract: 'billing/getContract',
      setContractItemPage: 'billing/setContractItemPage',
      listContractItem: 'billing/listContractItem',
    }),
    async getContractData() {
      await this.getContract(this.contract.id)
    },
    getContractStatusValue(contractStatus) {
      if (isNil(contractStatus)) return ''
      return this.getLabelBy(contractStatus, CONTRACT.status)
    },
    getLabelBy(value, options) {
      if (isNil(value)) return ''
      return prop(
        'label',
        find(propEq(value, 'value'), values(options)),
      )
    },
    redirectToEditContract() {
      this.$router.push(`/billing/edit-contract/${this.contract.id}`)
    },
    async setPageAndListItems(contractItemPage) {
      this.setContractItemPage(contractItemPage)
      this.page = contractItemPage
      await this.listContractItem({ contractId: this.contract.id, name: this.searchName })
    },
    redirectToListContract() {
      this.$router.push('/billing/list-contract')
    },
    contractItemClinicName(item) {
      const clinicLabel = isNil(item.clinicId)
        ? 'Padrão'
        : path(['clinic', 'name'], item)

      return clinicLabel
    },
  },
}
</script>

<style lang='stylus' scoped>
.title
  font-size 33.8px
  letter-spacing 0.25px
  font-family 'Montserrat'

.subtitle
  font-size 15.9px
  letter-spacing 0.5px
  font-family 'Montserrat'
  color rgba(0, 0, 0, 0.6)

.form-subtitle
  font-size 16px

.btn-end-contract
  justify-content space-between

.div--clickable
  cursor pointer

.btn-actions
  justify-content flex-end

.filters__date-menu
  width 100%

.contract-description__text
  white-space pre-line
</style>
