<template>
  <div>
    <order-filters />
    <order-summary-chips />
    <LoadingFinancesCenter v-if="isLoadingOrderList" />
    <order-list />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoadingFinancesCenter from './LoadingFinancesCenter'
import OrderSummaryChips from './OrderSummaryChips'
import OrderList from './OrderList'
import OrderFilters from './OrderFilters'

export default {
  name: 'StockContainer',
  components: {
    OrderList,
    OrderSummaryChips,
    OrderFilters,
    LoadingFinancesCenter,
  },
  computed: {
    ...mapGetters({
      isLoadingOrderList: 'billing/isLoadingOrderList',
    }),
  },
  mounted() {
    this.listClinic()
  },
  methods: {
    ...mapActions({
      listClinic: 'clinic/listClinic',
    }),
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'
</style>
