<template>
  <div class="modal-body">
    <v-flex>
      <v-container
        :class="{
          'container--fluid': true,
          'px-12': $vuetify.breakpoint.smAndUp,
          'grid-list-sm': $vuetify.breakpoint.sm,
          'grid-list-md': $vuetify.breakpoint.md,
          'grid-list-lg': $vuetify.breakpoint.lgAndUp,
        }"
      >
        <v-layout
          align-center
          column
          fill-height
        >
          <v-flex
            class="title"
          >
            Gerenciar Profissionais em lote
          </v-flex>
          <v-flex class="subtitle">
            Você pode importar usuários simultâneos a partir de um ou vários arquivos CSV.
            Fique atento ao padrão do seu arquivo para que a importação seja feita com sucesso.
          </v-flex>
          <v-flex
            m5
          >
            <a
              @click.prevent="getExampleFile"
            >
              Download do CSV padrão
            </a>
          </v-flex>
          <v-flex
            m5
          >
            <profession-code-list />
          </v-flex>
          <v-flex
            m5
          >
            <speciality-code-list />
          </v-flex>
          <v-flex
            m5
          >
            <clinic-code-list />
          </v-flex>
          <vue-dropzone
            id="myDropzone"
            ref="myDropzone"
            class="card__dropzone"
            :options="dropzoneOptions"
            @vdropzone-sending="validateParams"
            @vdropzone-queue-complete="getImportFileQueue"
          />
        </v-layout>
        <v-layout
          row
          wrap
          class="older-queues"
        >
          <v-flex
            v-if="queueList.rows && queueList.rows.length > 0"
            md9
            sm12
            offset-md3
          >
            <h2>Arquivos Anteriores</h2>
          </v-flex>
          <v-flex
            v-for="queue in queueList.rows"
            :key="queue.id"
            offset-md3
            md9
          >
            <v-flex
              v-if="queue.status === 'completed'"
              md9
              class="older-queues__filename--completed"
            >
              <professional-import-list
                :original-filename="queue.originalFilename"
                :import-file-queue-id="queue.id"
                :log-file-key="queue.logFileKey"
                :import-error-count="queue.importErrorCount"
                :donwload-log-link="queue.downloadLink"
              />
            </v-flex>
            <v-flex
              v-else
              md9
              class="older-queues__filename"
            >
              {{ queue.originalFilename }}
            </v-flex>
            <v-flex
              v-if="queue.status === 'completed'"
              md6
              class="older-queues__filename"
            >
              completo
            </v-flex>
            <v-flex
              v-else
              md6
              class="older-queues__status"
            >
              importação em andamento
            </v-flex>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import { mapActions, mapGetters } from 'vuex'
import {
  required,
} from 'vuelidate/lib/validators'
import ProfessionalImportList from './ProfessionalImportList'
import ProfessionCodeList from './ProfessionCodeList'
import SpecialityCodeList from './SpecialityCodeList'
import ClinicCodeList from './ClinicCodeList'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'PatientImportContainer',
  components: {
    VueDropzone: vue2Dropzone,
    ProfessionalImportList,
    ProfessionCodeList,
    SpecialityCodeList,
    ClinicCodeList,
  },
  data() {
    return {
      selectedAction: 'insert',
      queueList: [],
      actionList: [
        {
          name: 'Adicionar',
          id: 'insert',
        },
        {
          name: 'Remover',
          id: 'delete',
        },
      ],
      updateFilesInterval: null,
    }
  },
  validations() {
    return {
      selectedAction: {
        required,
      },
    }
  },
  computed: {
    ...mapGetters({
      token: 'authentication/token',
    }),
    dropzoneOptions() {
      return {
        url: new URL('/importer/professional', process.env.VUE_APP_ROOT_API).href,
        acceptedFiles: 'text/csv',
        thumbnailWidth: 450,
        thumbnailHeight: 400,
        addRemoveLinks: true,
        parallelUploads: 10,
        uploadMultiple: true,
        dictDefaultMessage: 'Preencha os campos e escolha ou arraste os dados dos profissionais para importar',
        dictFileTooBig: 'Arquivo muito grande ({{filesize}}MiB). tamanho máximo: {{maxFilesize}}MiB.',
        dictInvalidFileType: 'Você não pode enviar arquivos deste tipo.',
        dictResponseError: 'Servidor respondeu com o código {{statusCode}}.',
        dictCancelUpload: 'Cancelar envio',
        dictCancelUploadConfirmation: 'Tem certeza que deseja cancelar o envio ?',
        dictRemoveFile: 'Remover Arquivo',
        dictMaxFilesExceeded: 'Você não pode enviar mais arquivos.',
        headers: { Authorization: `Bearer ${this.token}` },
        autoProcessQueue: true,
        paramName: 'attachments',
      }
    },
  },
  async activated() {
    await this.getImportFileQueue()

    this.updateFilesInterval = setInterval(() => {
      this.getImportFileQueue()
    }, 1000)
  },
  deactivated() {
    clearInterval(this.updateFilesInterval)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
    }),
    showSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    validateParams(file, xhr, formData) {
      this.$v.$touch()
      if (this.$v.$error) {
        const msg = 'Verifique os campos em vermelho.'
        this.showSnackbar('error', msg)
        this.$refs.myDropzone.removeFile(file)
        return
      }

      formData.append('action', this.selectedAction)
    },
    async getExampleFile() {
      const fileResponse = await this.$http.get('importer/professional/example_file')

      const blob = new Blob([`\uFEFF${fileResponse.data}`], {
        type: 'text/csv;charset=utf-8',
      })

      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'example.csv'
      link.click()
    },
    async getImportFileQueue() {
      const queueList = await this.$http.get('importer/file_queue', { params: { importType: 'professional' } })
      this.queueList = queueList.data.queues
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.modal-body
  background-color #ffffff

.card__dropzone
  width 100%

.card__actions
  display flex
  flex-direction column

.title
  font-size 33.8px
  letter-spacing 0.25px
  font-family 'Montserrat'

.subtitle
  font-size 15.9px
  letter-spacing 0.5px
  font-family 'Montserrat'
  color rgba(0, 0, 0, 0.6)

.dropzone
  height 300px
  width 900px

#myDropzone > .dz-preview .dz-details
  background-color #55D058 !important

.older-queues__filename
  font-family Montserrat
  font-size 15.9px
  line-height 1.76
  letter-spacing 0.5px
  color #00670c

.older-queues__filename--completed
  font-family Montserrat
  font-size 15.9px
  line-height 1.76
  letter-spacing 0.5px
  color #00670c
  cursor pointer
  text-decoration underline

.older-queues__status
  font-family Montserrat
  font-size 14.9px
  line-height 1.76
  letter-spacing 0.5px

</style>
