import axios from '@/lib/axios'

export default {
  state: {
    loadingCareLinePatient: false,
    careLinesPatient: [],
  },
  mutations: {
    SET_LOADING_CARE_LINE_ACTION_PATIENT(state, payload) {
      state.loadingCareLinePatient = payload
    },
    SET_CARE_LINES_PATIENT(state, payload) {
      state.careLinesPatient = payload
    },
  },
  actions: {
    listCareLinePatient({ commit }, { params, storeData = true }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CARE_LINE_ACTION_PATIENT', true)
        axios.get('/emr/care_line_patient', { params })
          .then(({ data: { careLinePatients } }) => {
            if (storeData) commit('SET_CARE_LINES_PATIENT', careLinePatients)
            resolve(careLinePatients)
          })
          .catch((reject))
          .finally(() => commit('SET_LOADING_CARE_LINE_ACTION_PATIENT', false))
      })
    },
    createCareLinePatient({ commit }, attributes) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CARE_LINE_ACTION_PATIENT', true)
        axios.post('/emr/care_line_patient', attributes)
          .then(resolve)
          .catch(reject)
          .finally(() => commit('SET_LOADING_CARE_LINE_ACTION_PATIENT', false))
      })
    },
    updateCareLinePatient({ commit }, { id, attributes }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CARE_LINE_ACTION_PATIENT', true)
        axios.patch(`/emr/care_line_patient/${id}`, attributes)
          .then(resolve)
          .catch(reject)
          .finally(() => commit('SET_LOADING_CARE_LINE_ACTION_PATIENT', false))
      })
    },
    deleteCareLinePatient({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CARE_LINE_ACTION_PATIENT', true)
        axios.delete(`/emr/care_line_patient/${id}`)
          .then(resolve)
          .catch(reject)
          .finally(() => commit('SET_LOADING_CARE_LINE_ACTION_PATIENT', false))
      })
    },
    sendCarePlanPatient(__, id) {
      return new Promise((resolve, reject) => {
        axios.post(`/emr/care_line_patient/care_plan/patient/${id}`)
          .then(resolve)
          .catch(reject)
      })
    },
  },
  getters: {
    isLoadingCareLinesPatient: state => state.loadingCareLineActionCondition,
    careLinesPatient: state => state.careLinesPatient,
  },
}
