<template>
  <div>
    <component-title :last-breadcrumb="careLineName" />
    <v-layout v-if="isLoading">
      <v-flex class="pre-visualization">
        <circular-loader />
      </v-flex>
    </v-layout>
    <v-layout v-else-if="showWrongCareLineMessage">
      <v-flex class="pre-visualization">
        Linha de cuidado inválida.
      </v-flex>
    </v-layout>
    <v-tabs
      v-else
      grow
      height="58"
      background-color="light-green"
      hide-slider
    >
      <v-tab active-class="color: primary">
        <span class="tab__title font-weight-bold">Atribuição automática de pacientes</span>
      </v-tab>
      <v-tab active-class="color: primary">
        <span class="tab__title font-weight-bold">Fluxo de monitoramento</span>
      </v-tab>
      <v-tab active-class="color: primary">
        <span class="tab__title font-weight-bold">Exames Vinculados</span>
      </v-tab>
      <v-tab active-class="color: primary">
        <span class="tab__title font-weight-bold">Tags do paciente</span>
      </v-tab>
      <v-tab-item>
        <care-line-patients-assign />
      </v-tab-item>
      <v-tab-item>
        <care-line-action />
      </v-tab-item>
      <v-tab-item>
        <care-line-exams />
      </v-tab-item>
      <v-tab-item>
        <care-line-tags />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import CircularLoader from '@/components/UI/CircularLoader'
import { mapActions, mapGetters } from 'vuex'
import CareLinePatientsAssign from './CareLinePatientsAssign'

export default {
  name: 'CareLineAssignsPage',
  components: {
    CareLineAction: () => import('./CareLineAction'),
    CareLineTags: () => import('./CareLineTags'),
    CareLineExams: () => import('./CareLineExams'),
    ComponentTitle,
    CareLinePatientsAssign,
    CircularLoader,
  },
  data() {
    return {
      isLoading: false,
      showWrongCareLineMessage: false,
      careLineName: '',
    }
  },
  computed: {
    ...mapGetters({
      careLine: 'careLine/careLine',
    }),
  },
  mounted() {
    this.setCareLine()
  },
  methods: {
    ...mapActions({
      getCareLine: 'careLine/getCareLine',
      listCareLineAction: 'careLine/listCareLineAction',
      listCareLineDisease: 'careLine/listCareLineDisease',
      listCareLineAge: 'careLine/listCareLineAge',
    }),
    toggleIsLoading() {
      this.isLoading = !this.isLoading
    },
    setShowWrongCareLineMessage(show) {
      this.showWrongCareLineMessage = show
    },
    setCareLineName(value) {
      this.careLineName = value
    },
    async setCareLine() {
      this.toggleIsLoading()
      const { id } = this.$route.params
      try {
        await this.getCareLine(id)
        this.setPageOptions()
        await this.listCareLineDisease()
        await this.listCareLineAge()
        await this.listCareLineAction()
      } catch (error) {
        this.setShowWrongCareLineMessage(true)
      }
      this.toggleIsLoading()
    },
    setPageOptions() {
      const { name } = this.careLine
      this.setCareLineName(name)
      this.$route.meta.description = `Atribuições para a linha ${name}`
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.pre-visualization
  display flex
  justify-content center
  margin-top 5rem
  font-size 1.8rem

.tab__title
  color #ffffff
</style>
