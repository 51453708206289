import { rest } from 'msw'
import { equals } from 'ramda'
import clinicsEnums from '../../../enums/clinics'
import baseUrl from '../../../../src/utils/baseUrlTest'
import agendaListData from '../../fixtures/careTeamData'
import agendaListAgendaSettingsData from '../../fixtures/public/agenda/listAgendaSettings'

export default [
  rest.get(baseUrl('/agenda'), (req, res, ctx) => {
    const status = req.url.searchParams.get('status')
    const clinicId = req.url.searchParams.get('clinicId')

    if (status) {
      return res(
        ctx.status(200),
        ctx.json(agendaListAgendaSettingsData.agendaWithStatusOpen),
      )
    }

    if (equals(clinicId, clinicsEnums.PINHEIROS)) {
      return res(
        ctx.status(200),
        ctx.json(agendaListAgendaSettingsData.agendaWithoutStatus),
      )
    }

    return res(
      ctx.status(200),
      ctx.json({
        agendas: agendaListData,
        numberOfPages: 1,
        count: '2',
      }),
    )
  }),
  rest.post(baseUrl('/agenda'), (req, res, ctx) => {
    if (equals(req.body.endTime, '07:05')) {
      return res(
        ctx.status(200),
      )
    }

    if (equals(req.body.endTime, '07:25')) {
      return res(
        ctx.status(409),
        ctx.json(
          {
            errorCode: 'agenda_invalid',
            type: 'conflictError',
            errorMessage: 'Agenda is conflicting with another that already exists',
            errors: {},
          },
        ),
      )
    }

    return res(
      ctx.status(409),
      ctx.json(
        {
          errorCode: 'agenda_invalid',
          type: 'conflictError',
          errorMessage: 'Agenda invalid',
          errors: {
            startTime: 'Start date should be equal or after than now',
          },
        },
      ),
    )
  }),
]
