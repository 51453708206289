export default [
  {
    id: 'pro_cln3hmycg002t2iqk5308e7lx',
    name: 'Adnan Gilbert',
    biologicalSex: 'M',
    telphone: '11111111111',
    documentType: 'CRM',
    documentNumber: '1234',
    documentState: 'AC',
    cpf: '22961649005',
    cnpj: null,
    rg: '1234',
    birthDate: '1990-01-01T00:00:00.000Z',
    nationality: 'br',
    email: 'ag@gmail.com',
    startedAtClinic: '1990-01-01T00:00:00.000Z',
    enterpriseId: 'ent_ckrmkx56l00007odq0272fkvn',
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_cl1e0chpy0000zpdq7jidc388',
        name: 'Aeroporto - SBBR',
      },
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_cldp2aw4c0001glvo4s5u4zr4',
        name: 'Alergologista pediatra',
        isAps: false,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cln3hnr5g00322iqkg7vyf37v',
    name: 'Albert Perkins',
    biologicalSex: 'M',
    telphone: '11111111111',
    documentType: 'CRM',
    documentNumber: '1234',
    documentState: 'AC',
    cpf: '33546504003',
    cnpj: null,
    rg: '1234',
    birthDate: '1990-01-01T00:00:00.000Z',
    nationality: 'br',
    email: 'ap@gmail.com',
    startedAtClinic: '1990-01-01T00:00:00.000Z',
    enterpriseId: 'ent_ckrmkx56l00007odq0272fkvn',
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_cl1e0chpy0000zpdq7jidc388',
        name: 'Aeroporto - SBBR',
      },
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_cldp2aw4c0001glvo4s5u4zr4',
        name: 'Alergologista pediatra',
        isAps: false,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cjy91uu6m001ri3voafz99aww',
    name: 'Aristóteles',
    biologicalSex: 'M',
    telphone: '(11) 99999-9999',
    documentType: 'CRP',
    documentNumber: '000006',
    documentState: 'SP',
    cpf: '66146865699',
    cnpj: null,
    rg: '175575009',
    birthDate: '1982-01-01T00:00:00.000Z',
    nationality: 'Brasileiro',
    email: 'aristoteles@amparosaude.com',
    startedAtClinic: '2019-12-19T00:00:00.000Z',
    enterpriseId: null,
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00040nxbf1ple8ju',
      name: 'Técnico de Enfermagem',
    },
    clinics: [
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
    ],
    specialities: [
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
        name: 'Médico de família',
        isAps: true,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cln3hpg36003k2iqk9auie5hg',
    name: 'Asad Wade',
    biologicalSex: 'M',
    telphone: '11111111111',
    documentType: 'CRM',
    documentNumber: '1234',
    documentState: 'AC',
    cpf: '28953989094',
    cnpj: null,
    rg: '1234',
    birthDate: '1990-01-01T00:00:00.000Z',
    nationality: 'br',
    email: 'asw@gmail.com',
    startedAtClinic: '1990-01-01T00:00:00.000Z',
    enterpriseId: 'ent_ckrmkx56l00007odq0272fkvn',
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
      {
        id: 'cli_cl1e0chpy0000zpdq7jidc388',
        name: 'Aeroporto - SBBR',
      },
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_cldp2aw4c0001glvo4s5u4zr4',
        name: 'Alergologista pediatra',
        isAps: false,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cln3hoird003b2iqk43iifimi',
    name: 'Ashleigh Lynn',
    biologicalSex: 'M',
    telphone: '11111111111',
    documentType: 'CRM',
    documentNumber: '1234',
    documentState: 'AC',
    cpf: '31185894004',
    cnpj: null,
    rg: '1234',
    birthDate: '1990-01-01T00:00:00.000Z',
    nationality: 'br',
    email: 'al@gmail.com',
    startedAtClinic: '1990-01-01T00:00:00.000Z',
    enterpriseId: 'ent_ckrmkx56l00007odq0272fkvn',
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_cl1e0chpy0000zpdq7jidc388',
        name: 'Aeroporto - SBBR',
      },
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_cldp2aw4c0001glvo4s5u4zr4',
        name: 'Alergologista pediatra',
        isAps: false,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cln3hhx47001b2iqk4i2i5qq4',
    name: 'Ashley Tucker',
    biologicalSex: 'M',
    telphone: '11111111111',
    documentType: 'CRM',
    documentNumber: '1234',
    documentState: 'AC',
    cpf: '81578742030',
    cnpj: null,
    rg: '1234',
    birthDate: '1990-01-01T00:00:00.000Z',
    nationality: 'br',
    email: 'at@gmail.com',
    startedAtClinic: '1990-01-01T00:00:00.000Z',
    enterpriseId: 'ent_ckrmkx56l00007odq0272fkvn',
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
      {
        id: 'cli_cl1e0chpy0000zpdq7jidc388',
        name: 'Aeroporto - SBBR',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_cldp2aw4c0001glvo4s5u4zr4',
        name: 'Alergologista pediatra',
        isAps: false,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cln3hdpju00022iqk5xcu7jwx',
    name: 'Awais Oneal',
    biologicalSex: 'M',
    telphone: '11111111111',
    documentType: 'CRM',
    documentNumber: '1234',
    documentState: 'AC',
    cpf: '87425336006',
    cnpj: null,
    rg: '1234',
    birthDate: '1990-01-01T00:00:00.000Z',
    nationality: 'br',
    email: 'aw@gmail.com',
    startedAtClinic: '1990-01-01T00:00:00.000Z',
    enterpriseId: 'ent_ckrmkx56l00007odq0272fkvn',
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
      {
        id: 'cli_cl1e0chpy0000zpdq7jidc388',
        name: 'Aeroporto - SBBR',
      },
    ],
    specialities: [
      {
        id: 'spe_cldp2aw4c0001glvo4s5u4zr4',
        name: 'Alergologista pediatra',
        isAps: false,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cln3hk9cb00222iqk15gj2wjx',
    name: 'Benjamin Lam',
    biologicalSex: 'M',
    telphone: '11111111111',
    documentType: 'CRM',
    documentNumber: '1234',
    documentState: 'AC',
    cpf: '18646203077',
    cnpj: null,
    rg: '1234',
    birthDate: '1990-01-01T00:00:00.000Z',
    nationality: 'br',
    email: 'jl@gmail.com',
    startedAtClinic: '1990-01-01T00:00:00.000Z',
    enterpriseId: 'ent_ckrmkx56l00007odq0272fkvn',
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
      {
        id: 'cli_cl1e0chpy0000zpdq7jidc388',
        name: 'Aeroporto - SBBR',
      },
    ],
    specialities: [
      {
        id: 'spe_cldp2aw4c0001glvo4s5u4zr4',
        name: 'Alergologista pediatra',
        isAps: false,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cln3hh5oq00122iqkfoi4airp',
    name: 'Billie Coffey',
    biologicalSex: 'M',
    telphone: '11111111111',
    documentType: 'CRM',
    documentNumber: '1234',
    documentState: 'AC',
    cpf: '84687771025',
    cnpj: null,
    rg: '1234',
    birthDate: '1990-01-01T00:00:00.000Z',
    nationality: 'br',
    email: 'bc@gmail.com',
    startedAtClinic: '1990-01-01T00:00:00.000Z',
    enterpriseId: 'ent_ckrmkx56l00007odq0272fkvn',
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_cl1e0chpy0000zpdq7jidc388',
        name: 'Aeroporto - SBBR',
      },
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_cldp2aw4c0001glvo4s5u4zr4',
        name: 'Alergologista pediatra',
        isAps: false,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_ck3ogpnob0001mhpq5of978kw',
    name: 'Brutus (.com)',
    biologicalSex: 'M',
    telphone: '(11) 99999-9999',
    documentType: 'CRP',
    documentNumber: '000008',
    documentState: 'SP',
    cpf: '54218044457',
    cnpj: null,
    rg: '175575009',
    birthDate: '1982-01-01T00:00:00.000Z',
    nationality: 'Brasileiro',
    email: 'brutus@amparosaude.com',
    startedAtClinic: '2019-12-19T00:00:00.000Z',
    enterpriseId: null,
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
        name: 'Médico de família',
        isAps: true,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_ck3ogpno90000mhpq2e5oa7dz',
    name: 'Brutus (.ro)',
    biologicalSex: 'M',
    telphone: '(11) 99999-9999',
    documentType: 'CRP',
    documentNumber: '000007',
    documentState: 'SP',
    cpf: '83373411220',
    cnpj: null,
    rg: '175575009',
    birthDate: '1982-01-01T00:00:00.000Z',
    nationality: 'Brasileiro',
    email: 'brutus@amparosaude.com',
    startedAtClinic: '2019-12-19T00:00:00.000Z',
    enterpriseId: null,
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
    ],
    specialities: [
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
        name: 'Médico de família',
        isAps: true,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cln3hsdgx004k2iqk6j3r6xoy',
    name: 'Claude Richard',
    biologicalSex: 'M',
    telphone: '11111111111',
    documentType: 'CRM',
    documentNumber: '1234',
    documentState: 'AC',
    cpf: '17474217074',
    cnpj: null,
    rg: '1234',
    birthDate: '1990-01-01T00:00:00.000Z',
    nationality: 'br',
    email: 'cr@gmail.com',
    startedAtClinic: '1990-01-01T00:00:00.000Z',
    enterpriseId: 'ent_ckrmkx56l00007odq0272fkvn',
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_cl1e0chpy0000zpdq7jidc388',
        name: 'Aeroporto - SBBR',
      },
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
    ],
    specialities: [
      {
        id: 'spe_cldp2aw4c0001glvo4s5u4zr4',
        name: 'Alergologista pediatra',
        isAps: false,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_ck3ogpnob0003mhpq0jp47o2l',
    name: 'Devs + Pro (.com)',
    biologicalSex: 'M',
    telphone: '(11) 99999-9999',
    documentType: 'CRP',
    documentNumber: '000010',
    documentState: 'SP',
    cpf: '54616442797',
    cnpj: null,
    rg: '175575009',
    birthDate: '1982-01-01T00:00:00.000Z',
    nationality: 'Brasileiro',
    email: 'brutus@amparosaude.com',
    startedAtClinic: '2019-12-19T00:00:00.000Z',
    enterpriseId: null,
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
        name: 'Médico de família',
        isAps: true,
      },
    ],
    careTeams: [
      {
        id: 'car_tea_ckn7rbkf00001fpdq60vk9pse',
        name: 'Equipe Pinheiros',
      },
    ],
  },
  {
    id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
    name: 'Devs + Pro (.ro)',
    biologicalSex: 'M',
    telphone: '(11) 99999-9999',
    documentType: 'CRP',
    documentNumber: '000009',
    documentState: 'SP',
    cpf: '79462651400',
    cnpj: null,
    rg: '175575009',
    birthDate: '1982-01-01T00:00:00.000Z',
    nationality: 'Brasileiro',
    email: 'brutus@amparosaude.com',
    startedAtClinic: '2019-12-19T00:00:00.000Z',
    enterpriseId: null,
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
    ],
    specialities: [
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
        name: 'Médico de família',
        isAps: true,
      },
    ],
    careTeams: [
      {
        id: 'car_tea_ckn7rbkez0000fpdqge9aa3hm',
        name: 'Equipe Lapa',
      },
    ],
  },
  {
    id: 'pro_cln3hgaoi000t2iqkdi9j0huw',
    name: 'Dillon Duffy',
    biologicalSex: 'M',
    telphone: '11111111111',
    documentType: 'CRM',
    documentNumber: '1324',
    documentState: 'AC',
    cpf: '18675972091',
    cnpj: null,
    rg: '1234',
    birthDate: '1990-01-01T00:00:00.000Z',
    nationality: 'br',
    email: 'dd@gmail.com',
    startedAtClinic: '1990-01-01T00:00:00.000Z',
    enterpriseId: 'ent_ckrmkx56l00007odq0272fkvn',
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_cl1e0chpy0000zpdq7jidc388',
        name: 'Aeroporto - SBBR',
      },
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_cldp2aw4c0001glvo4s5u4zr4',
        name: 'Alergologista pediatra',
        isAps: false,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cln3hrn5a004b2iqk7unk4xmr',
    name: 'Dominik Andersen',
    biologicalSex: 'M',
    telphone: '11111111111',
    documentType: 'CRM',
    documentNumber: '1234',
    documentState: 'AC',
    cpf: '68368241006',
    cnpj: null,
    rg: '1234',
    birthDate: '1990-01-01T00:00:00.000Z',
    nationality: 'br',
    email: 'da@gmail.com',
    startedAtClinic: '1990-01-01T00:00:00.000Z',
    enterpriseId: 'ent_ckrmkx56l00007odq0272fkvn',
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_cl1e0chpy0000zpdq7jidc388',
        name: 'Aeroporto - SBBR',
      },
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_cldp2aw4c0001glvo4s5u4zr4',
        name: 'Alergologista pediatra',
        isAps: false,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cln3heibb000b2iqk4llycmqc',
    name: 'Francesco Carver',
    biologicalSex: 'M',
    telphone: '11111111111',
    documentType: 'CRM',
    documentNumber: '1234',
    documentState: 'AC',
    cpf: '21972995065',
    cnpj: null,
    rg: '1234',
    birthDate: '1990-01-01T00:00:00.000Z',
    nationality: 'br',
    email: 'fr@gmail.com',
    startedAtClinic: '1990-01-01T00:00:00.000Z',
    enterpriseId: 'ent_ckrmkx56l00007odq0272fkvn',
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_cl1e0chpy0000zpdq7jidc388',
        name: 'Aeroporto - SBBR',
      },
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_cldp2aw4c0001glvo4s5u4zr4',
        name: 'Alergologista pediatra',
        isAps: false,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cjy8zrbn1001fi3vo66ys842k',
    name: 'Friedrich Netzsche',
    biologicalSex: 'M',
    telphone: '(11) 99999-9999',
    documentType: 'CRM',
    documentNumber: '000001',
    documentState: 'SP',
    cpf: '78572175148',
    cnpj: null,
    rg: '274214854',
    birthDate: '1977-01-01T00:00:00.000Z',
    nationality: 'Brasileiro',
    email: 'friedrich.netzsche@amparosaude.com',
    startedAtClinic: '2019-12-19T00:00:00.000Z',
    enterpriseId: null,
    isRqe: false,
    profession: {
      id: 'profession_cjzbz9pgk000hb0vogejnajuo',
      name: 'Psicólogo',
    },
    clinics: [
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
    ],
    specialities: [
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
        name: 'Médico de família',
        isAps: true,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cjy912cb7001pi3vo6noc2q14',
    name: 'Georg Hegel',
    biologicalSex: 'M',
    telphone: '(11) 99999-9999',
    documentType: 'CRM',
    documentNumber: '000004',
    documentState: 'SP',
    cpf: '80486227421',
    cnpj: null,
    rg: '234996699',
    birthDate: '1967-01-01T00:00:00.000Z',
    nationality: 'Brasileiro',
    email: 'georg.hegel@amparosaude.com',
    startedAtClinic: '2019-12-19T00:00:00.000Z',
    enterpriseId: null,
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zj00020nxbgcea9qh5',
      name: 'Enfermeiro',
    },
    clinics: [
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
    ],
    specialities: [
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
        name: 'Médico de família',
        isAps: true,
      },
    ],
    careTeams: [
      {
        id: 'car_tea_ckn7rbkez0000fpdqge9aa3hm',
        name: 'Equipe Lapa',
      },
    ],
  },
  {
    id: 'pro_cln3hm74c002k2iqk9lnh7udf',
    name: 'Gregory Moran',
    biologicalSex: 'M',
    telphone: '11111111111',
    documentType: 'CRM',
    documentNumber: '1234',
    documentState: 'AC',
    cpf: '23012436057',
    cnpj: null,
    rg: '1234',
    birthDate: '1990-01-01T00:00:00.000Z',
    nationality: 'br',
    email: 'gm@gmail.com',
    startedAtClinic: '1990-01-01T00:00:00.000Z',
    enterpriseId: 'ent_ckrmkx56l00007odq0272fkvn',
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_cl1e0chpy0000zpdq7jidc388',
        name: 'Aeroporto - SBBR',
      },
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_cldp2aw4c0001glvo4s5u4zr4',
        name: 'Alergologista pediatra',
        isAps: false,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cln3hlcko002b2iqkgm6f6yjw',
    name: 'Haider Rivas',
    biologicalSex: 'M',
    telphone: '11111111111',
    documentType: 'CRM',
    documentNumber: '1234',
    documentState: 'AC',
    cpf: '78531418070',
    cnpj: null,
    rg: '1234',
    birthDate: '1990-01-01T00:00:00.000Z',
    nationality: 'br',
    email: 'hr@gmail.com',
    startedAtClinic: '1990-01-01T00:00:00.000Z',
    enterpriseId: 'ent_ckrmkx56l00007odq0272fkvn',
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_cl1e0chpy0000zpdq7jidc388',
        name: 'Aeroporto - SBBR',
      },
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_cldp2aw4c0001glvo4s5u4zr4',
        name: 'Alergologista pediatra',
        isAps: false,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cjy90nbi6001oi3vofij4dz3q',
    name: 'Harriet Taylor',
    biologicalSex: 'F',
    telphone: '(11) 99999-9999',
    documentType: 'CRM',
    documentNumber: '000003',
    documentState: 'SP',
    cpf: '36228139703',
    cnpj: null,
    rg: '445696941',
    birthDate: '1969-01-01T00:00:00.000Z',
    nationality: 'Brasileiro',
    email: 'harriet.taylor@amparosaude.com',
    startedAtClinic: '2019-12-19T00:00:00.000Z',
    enterpriseId: null,
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zj00020nxbgcea9qh5',
      name: 'Enfermeiro',
    },
    clinics: [
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
    ],
    specialities: [
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
        name: 'Médico de família',
        isAps: true,
      },
    ],
    careTeams: [
      {
        id: 'car_tea_ckn7rbkez0000fpdqge9aa3hm',
        name: 'Equipe Lapa',
      },
      {
        id: 'car_tea_ckn7rbkf00001fpdq60vk9pse',
        name: 'Equipe Pinheiros',
      },
    ],
  },
  {
    id: 'pro_cln3hip0d001k2iqka8t5avxr',
    name: 'Hashim Best',
    biologicalSex: 'M',
    telphone: '11111111111',
    documentType: 'CRM',
    documentNumber: '1234',
    documentState: 'AC',
    cpf: '09512700018',
    cnpj: null,
    rg: '1234',
    birthDate: '1990-01-01T00:00:00.000Z',
    nationality: 'br',
    email: 'hb@gmail.com',
    startedAtClinic: '1990-01-01T00:00:00.000Z',
    enterpriseId: 'ent_ckrmkx56l00007odq0272fkvn',
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_cl1e0chpy0000zpdq7jidc388',
        name: 'Aeroporto - SBBR',
      },
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_cldp2aw4c0001glvo4s5u4zr4',
        name: 'Alergologista pediatra',
        isAps: false,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cln3hfe0n000k2iqkeats8znp',
    name: 'Henry Gibbons',
    biologicalSex: 'M',
    telphone: '11111111111',
    documentType: 'CRM',
    documentNumber: '1234',
    documentState: 'AC',
    cpf: '09118148093',
    cnpj: null,
    rg: '1234',
    birthDate: '1990-01-01T00:00:00.000Z',
    nationality: 'br',
    email: 'hg@gmail.com',
    startedAtClinic: '1990-01-01T00:00:00.000Z',
    enterpriseId: 'ent_ckrmkx56l00007odq0272fkvn',
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_cl1e0chpy0000zpdq7jidc388',
        name: 'Aeroporto - SBBR',
      },
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_cldp2aw4c0001glvo4s5u4zr4',
        name: 'Alergologista pediatra',
        isAps: false,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cjy91hkji001qi3vobp2c0fh8',
    name: 'Immanuel Kant',
    biologicalSex: 'M',
    telphone: '(11) 99999-9999',
    documentType: 'CRM',
    documentNumber: '000005',
    documentState: 'SP',
    cpf: '16724420288',
    cnpj: null,
    rg: '500832389',
    birthDate: '1984-01-01T00:00:00.000Z',
    nationality: 'Brasileiro',
    email: 'immanuel.kant@amparosaude.com',
    startedAtClinic: '2019-12-19T00:00:00.000Z',
    enterpriseId: null,
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00040nxbf1ple8ju',
      name: 'Técnico de Enfermagem',
    },
    clinics: [
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
    ],
    specialities: [
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
        name: 'Médico de família',
        isAps: true,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cln3hjgb9001t2iqke29zfpux',
    name: 'Jacques Wong',
    biologicalSex: 'M',
    telphone: '11111111111',
    documentType: 'CRM',
    documentNumber: '1234',
    documentState: 'AC',
    cpf: '29187199009',
    cnpj: null,
    rg: '1234',
    birthDate: '1990-01-01T00:00:00.000Z',
    nationality: 'br',
    email: 'jw@gmail.com',
    startedAtClinic: '1990-01-01T00:00:00.000Z',
    enterpriseId: 'ent_ckrmkx56l00007odq0272fkvn',
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_cl1e0chpy0000zpdq7jidc388',
        name: 'Aeroporto - SBBR',
      },
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_cldp2aw4c0001glvo4s5u4zr4',
        name: 'Alergologista pediatra',
        isAps: false,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cln3ht211004t2iqkhv4zfb3n',
    name: 'Otto Tate',
    biologicalSex: 'M',
    telphone: '11111111111',
    documentType: 'CRM',
    documentNumber: '1234',
    documentState: 'AC',
    cpf: '73427019047',
    cnpj: null,
    rg: '1234',
    birthDate: '1990-01-01T00:00:00.000Z',
    nationality: 'br',
    email: 'ot@gmail.com',
    startedAtClinic: '1990-01-01T00:00:00.000Z',
    enterpriseId: 'ent_ckrmkx56l00007odq0272fkvn',
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_cl1e0chpy0000zpdq7jidc388',
        name: 'Aeroporto - SBBR',
      },
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_cldp2aw4c0001glvo4s5u4zr4',
        name: 'Alergologista pediatra',
        isAps: false,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cln3hq778003t2iqk7c5b8sgm',
    name: 'Ryan Juarez',
    biologicalSex: 'M',
    telphone: '11111111111',
    documentType: 'CRM',
    documentNumber: '1234',
    documentState: 'AC',
    cpf: '92662795018',
    cnpj: null,
    rg: '1234',
    birthDate: '1990-01-01T00:00:00.000Z',
    nationality: 'br',
    email: 'rj@gmail.com',
    startedAtClinic: '1990-01-01T00:00:00.000Z',
    enterpriseId: 'ent_ckrmkx56l00007odq0272fkvn',
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
      {
        id: 'cli_cl1e0chpy0000zpdq7jidc388',
        name: 'Aeroporto - SBBR',
      },
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_cldp2aw4c0001glvo4s5u4zr4',
        name: 'Alergologista pediatra',
        isAps: false,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cjy909srw001ni3vo5dxjffqd',
    name: 'Santo Agostinho',
    biologicalSex: 'M',
    telphone: '(11) 99999-9999',
    documentType: 'CRM',
    documentNumber: '000002',
    documentState: 'SP',
    cpf: '77974725052',
    cnpj: null,
    rg: '108668708',
    birthDate: '1963-01-01T00:00:00.000Z',
    nationality: 'Brasileiro',
    email: 'santo.agostinho@amparosaude.com',
    startedAtClinic: '2019-12-19T00:00:00.000Z',
    enterpriseId: null,
    isRqe: false,
    profession: {
      id: 'profession_cjzbz1g7t000gb0vo907k3kv3',
      name: 'Nutricionista',
    },
    clinics: [
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
    ],
    specialities: [
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
        name: 'Médico de família',
        isAps: true,
      },
    ],
    careTeams: [],
  },
  {
    id: 'pro_cln3hqwn700422iqkfocuhser',
    name: 'Siobhan Archer',
    biologicalSex: 'M',
    telphone: '11111111111',
    documentType: 'CRM',
    documentNumber: '1234',
    documentState: 'AC',
    cpf: '50741319012',
    cnpj: null,
    rg: '1234',
    birthDate: '1990-01-01T00:00:00.000Z',
    nationality: 'br',
    email: 'sa@gmail.com',
    startedAtClinic: '1990-01-01T00:00:00.000Z',
    enterpriseId: 'ent_ckrmkx56l00007odq0272fkvn',
    isRqe: false,
    profession: {
      id: 'profession_cjz3an5zk00030nxbejbsg6wj',
      name: 'Médico',
    },
    clinics: [
      {
        id: 'cli_cl1e0chpy0000zpdq7jidc388',
        name: 'Aeroporto - SBBR',
      },
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
        name: 'Médico de família',
        isAps: false,
      },
    ],
    careTeams: [],
  },
]
