<template>
  <div>
    <v-layout>
      <v-flex class="timeline__container">
        <loading-emr-history
          v-if="isLoading"
          justify-center
        />
        <v-timeline
          v-else
          align-top
          dense
          class="timeline__header"
        >
          <slot class="ml-8" />
        </v-timeline>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  components: {
    LoadingEmrHistory: () => import('@/components/loaders/LoadingEmrHistory'),
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="stylus" scoped>
.timeline__container
  width 100%
  margin-left 32px
  margin-right 32px
  margin-top 44px

.timeline__header
  padding-top 0px
  width 100%

</style>
