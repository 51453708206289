<template>
  <div>
    <component-title
      emr
      :last-breadcrumb="patientName"
    />
    <emr-container />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import { mapGetters } from 'vuex'
import EmrContainer from './EmrContainer'

export default {
  components: {
    ComponentTitle,
    EmrContainer,
  },
  computed: {
    ...mapGetters({
      patient: 'emr/patient',
    }),
    patientName() {
      return this.$filters.formatDisplayName(this.patient)
    },
  },
}
</script>
