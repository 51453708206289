import authentication from './authentication'
import permission from './permission'
import user from './user'

const state = {
  ...authentication.state,
  ...permission.state,
  ...user.state,
}

const mutations = {
  ...authentication.mutations,
  ...permission.mutations,
  ...user.mutations,
}

const actions = {
  ...authentication.actions,
  ...permission.actions,
  ...user.actions,
}

const getters = {
  ...authentication.getters,
  ...permission.getters,
  ...user.getters,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
