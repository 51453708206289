<template>
  <div>
    <div class="filters-container">
      <v-container
        :class="{
          'container--fluid': true,
          'px-12': $vuetify.breakpoint.smAndUp,
          'grid-list-lg': true
        }"
      >
        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
            sm6
          >
            <v-text-field
              v-model="filters.name"
              label="Buscar por nome"
              filled
              clearable
              hide-details
              no-data-text="Digite para buscar"
              @input="getCareTeam(filters)"
            />
          </v-flex>
          <v-flex
            xs12
            sm6
          >
            <v-autocomplete
              v-model="filters.clinicId"
              :items="clinics"
              label="Buscar por clínica"
              filled
              clearable
              hide-details
              item-text="name"
              item-value="id"
              no-data-text="Digite para buscar"
              @change="getCareTeam(filters)"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div>
      <v-layout
        v-for="team in listCareTeams"
        :key="team.id"
        md12
        mx-12
        my-2
      >
        <v-card
          outlined
          class="list-item__card"
        >
          <v-card-text>
            <v-layout
              row
              class="font-weight-medium"
            >
              <v-flex
                md3
                column
              >
                <v-flex class="list-item__title mb-2">
                  {{ team.name }}
                </v-flex>
                <v-flex class="list-item__subtitle">
                  Unidade: {{ team.clinic.name }}
                </v-flex>
              </v-flex>
              <v-flex md7>
                <v-layout justify-space-around>
                  <v-flex md4>
                    {{ team.score/100 }} pontos
                  </v-flex>
                  <v-flex md4>
                    <v-layout column>
                      <v-flex
                        v-for="professional in team.professionals"
                        :key="professional.id"
                      >
                        {{ professional.name }} - {{ professional.profession.name }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex justify-end>
                <v-btn
                  icon
                  @click.stop="updateTeam(team)"
                >
                  <v-icon>mdi-account-edit</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click.stop="updateMigrationDialogShow(team)"
                >
                  <v-icon>mdi-swap-horizontal</v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="error"
                  @click.stop="setDeleteCareTeamDialog(team)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-layout>
    </div>
    <migrate-patients-care-team
      :editing-team-id="editingTeamId"
      :show="isShowingMigrationDialog"
      @close="updateMigrationDialogShow()"
    />
    <v-dialog
      v-model="showCareTeamDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1400"
    >
      <v-card>
        <v-card-title
          class="card-dialog__title mb-4"
        >
          Equipe de Saúde
          <v-spacer />
          <v-btn
            icon
            dark
            @click="changeStatusDialog()"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <create-care-team
            :value="showCareTeamDialog"
            :editing-team="editingTeam"
            @update="getCareTeam()"
            @close="changeStatusDialog()"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <snackbar />
    <v-dialog
      v-model="showDeleteCareTeamDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="594"
    >
      <v-card class="pa-6">
        <v-card-title
          class="card-dialog__title mb-4 font-weight-bold px-0"
        >
          <v-icon
            class="mr-2"
            size="20"
            color="error"
          >
            mdi-alert
          </v-icon>
          Excluir {{ editingTeam ? editingTeam.name : '' }}
          <v-btn
            class="ml-auto"
            icon
            dark
            @click="setDeleteCareTeamDialog()"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="card-dialog__text px-0">
          Você tem certeza que quer excluir essa equipe?
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="cancel__button font-weight-bold amparo-gray--text px-6"
            outlined
            @click="setDeleteCareTeamDialog()"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="font-weight-bold px-6 ml-4"
            color="error"
            @click="deleteTeam()"
          >
            confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showHasPatientDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="812"
    >
      <v-card class="pa-6">
        <v-card-title
          class="card-dialog__title mb-4 font-weight-bold px-0"
        >
          Atenção!
          <v-btn
            class="ml-auto"
            icon
            dark
            @click="setHasPatientDialog()"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="card-dialog__subtitle px-0 mb-4">
          Carteira possui pacientes!
        </v-card-subtitle>
        <v-card-text class="card-dialog__text px-0">
          Você está tentando excluir uma carteira que possui pacientes.
          Para realizar esta ação é preciso migrar os pacientes para outras carteiras
          <v-divider class="mt-6" />
        </v-card-text>
        <v-card-actions class="justify-end px-0">
          <v-btn
            class="font-weight-bold px-6"
            color="primary"
            outlined
            @click="setHasPatientDialog()"
          >
            voltar para equipes
          </v-btn>
          <v-btn
            class="font-weight-bold px-6 ml-4"
            color="primary"
            @click="updateMigrationDialogShow(editingTeam)"
          >
            migrar pacientes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Vue from 'vue'

import {
  not,
  reduce,
} from 'ramda'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CareTeamList',
  components: {
    CreateCareTeam: () => import('./CreateCareTeam'),
    MigratePatientsCareTeam: () => import('./MigratePatientsCareTeam'),
    Snackbar: () => import('@/components/UI/SnackbarAlert'),
  },
  data() {
    return {
      filters: {
        name: null,
        clinicId: null,
      },
      totalPatients: 0,
      professionalFilter: null,
      showCareTeamDialog: false,
      isShowingMigrationDialog: false,
      showDeleteCareTeamDialog: false,
      showHasPatientDialog: false,
      editingTeam: {},
      storedTeamList: [],
    }
  },
  computed: {
    ...mapGetters({
      careTeams: 'careTeam/careTeams',
      clinics: 'clinic/clinics',
    }),
    editingTeamId() {
      return this.editingTeam?.id
    },
    totalScore() {
      return reduce(
        (acc, { score }) => acc + score,
        0,
        this.careTeams,
      )
    },
    listCareTeams() {
      if (this.isShowingMigrationDialog) {
        return [this.editingTeam]
      }
      return this.careTeams
    },
  },
  activated() {
    this.listCareTeam()
    this.listClinic()
  },
  methods: {
    ...mapActions({
      listCareTeam: 'careTeam/listCareTeam',
      updateCareTeam: 'careTeam/updateCareTeam',
      listClinic: 'clinic/listClinic',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    changeStatusDialog() {
      this.showCareTeamDialog = not(this.showCareTeamDialog)
      this.editingTeam = null
    },
    updateTeam(team) {
      this.editingTeam = team
      Vue.nextTick(() => {
        this.showCareTeamDialog = true
      })
    },
    updateMigrationDialogShow(team = {}) {
      this.showHasPatientDialog = false

      if (!this.isShowingMigrationDialog) {
        this.editingTeam = team
      }

      Vue.nextTick(() => {
        this.isShowingMigrationDialog = !this.isShowingMigrationDialog
      })
    },
    getCareTeam(filters) {
      this.listCareTeam(filters)
    },
    async deleteTeam() {
      try {
        await this.updateCareTeam({
          id: this.editingTeam.id,
          attributes: {
            storedAt: new Date(),
          },
        })

        this.getCareTeam(this.filters)
        this.setDeleteCareTeamDialog()
      } catch (error) {
        // this.setHasPatientDialog()
        if (error.response.data.errorCode === 'archiving_unavailable') {
          this.setSnackbar({
            status: 'error',
            message: 'Equipe de saúde possui pacientes atrelados',
          })
          this.setDeleteCareTeamDialog(this.editingTeam)
        }
      }
    },
    setDeleteCareTeamDialog(team = {}) {
      this.editingTeam = team
      this.showDeleteCareTeamDialog = !this.showDeleteCareTeamDialog
    },
    setHasPatientDialog() {
      this.showHasPatientDialog = !this.showHasPatientDialog
    },
  },
}
</script>
<style lang="stylus" scoped>
  .list-item__card
    width 100%
    padding 20px

  .list-item__title
    font-size 24px
    color rgba(0, 0, 0, 1)

  .list-item__subtitle
    font-size 18px
    color rgba(0, 0, 0, 0.87)

  .result-professional_filter
    margin-left 4px

  .card-dialog__text
    font-size: 20px

  .card-dialog__subtitle
    font-size: 40px

  .cancel__button
    border: 1px solid #32333826

</style>
