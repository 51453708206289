<template>
  <v-layout class="patient-tags">
    <v-flex
      xs10
      class="patient-tags__chips"
    >
      <span v-if="isEmptyPatientTags">
        Paciente não possui tags
      </span>
      <template v-for="patientTag of patientTags">
        <list-care-line-tag-options
          :key="patientTag.id"
          :tag="patientTag"
          class="py-1"
          @clickTag="openEditTag"
        />
      </template>
    </v-flex>
    <v-spacer />
    <v-btn
      color="gray"
      small
      dark
      @click="openDialog"
    >
      <v-icon left>
        mdi-plus
      </v-icon>
      Adicionar tag
    </v-btn>
    <v-dialog
      v-model="isOpen"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          <v-icon v-if="!isEditing">
            mdi-plus
          </v-icon>
          {{ cardTitle }}
          <v-spacer />
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="isOpen">
          <v-autocomplete
            v-model="tag"
            label="Tag"
            item-text="name"
            :items="availableTags"
            return-object
            filled
            :disabled="isEditing"
            @change="setOptionId(null)"
          />
          <div v-if="tag.id">
            Tag pertencente à linha de cuidado
            <strong>{{ tag.careLine.name }}</strong>
          </div>
          <v-radio-group
            v-if="tag.id"
            v-model="optionId"
            row
          >
            <v-radio
              v-for="{id, label, color} of tag.careLineTagOptions"
              :key="id"
              :value="id"
              :color="color"
            >
              <template #label>
                <v-chip
                  :color="`${color} darken-2`"
                  outlined
                >
                  {{ label }}
                </v-chip>
              </template>
            </v-radio>
          </v-radio-group>
          <v-layout v-if="tag.instruction">
            <div>
              <v-icon>mdi-alert-circle-outline</v-icon>
            </div>
            <div class="ml-2">
              <p>
                Instruções da Tag: {{ tag.instruction }}
              </p>
            </div>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="btn btn-primary"
            block
            :disabled="shouldDisableSaveButton"
            @click="saveCareLineTagPatient"
          >
            {{ cardTitle }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import {
  or,
  equals,
  map,
  not,
  prop,
  find,
  propEq,
  path,
  isEmpty,
  head,
  defaultTo,
} from 'ramda'
import ListCareLineTagOptions from '@/components/CareLineTimeline/ListCareLineTagOptions'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ListCareLineTagOptions,
  },
  data() {
    return {
      isOpen: false,
      tag: {},
      optionId: null,
      isEditing: false,
    }
  },
  computed: {
    ...mapGetters({
      availableTags: 'careLine/careLineAvailableTags',
      patient: 'emr/patient',
    }),
    cardTitle() {
      return this.isEditing
        ? 'Editar tag'
        : 'Adicionar nova tag'
    },
    patientTags() {
      return map(
        tag => ({
          id: tag.id,
          name: tag.careLineTag.name,
          instruction: tag.careLineTag.instruction,
          careLine: tag.careLineTag.careLine,
          options: [tag.careLineTagOption],
        }),
        this.careLineTagsPatient,
      )
    },
    careLineTagsPatient() {
      return defaultTo([], this.patient.careLineTagsPatient)
    },
    isEmptyPatientTags() {
      return isEmpty(this.patientTags)
    },
    patientId() {
      return this.patient.id
    },
    shouldDisableSaveButton() {
      return or(not(this.tag.id), not(this.optionId))
    },
  },
  methods: {
    ...mapActions({
      listCareLineAvailableTags: 'careLine/listCareLineAvailableTags',
      createCareLineTagPatient: 'careLine/createCareLineTagPatient',
      updateCareLineTagPatientOption: 'careLine/updateCareLineTagPatientOption',
      setEmrPatient: 'emr/setEmrPatient',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    setTag(value) {
      this.tag = value
    },
    setOptionId(value) {
      this.optionId = value
    },
    setIsEditing(value) {
      this.isEditing = value
    },
    async openDialog() {
      this.isOpen = true
      return this.getAvailableTags()
    },
    getTagOptions(tagName) {
      return prop(
        'careLineTagOptions',
        find(propEq(tagName, 'name'), this.availableTags),
      )
    },
    async openEditTag(tag) {
      this.setIsEditing(true)
      await this.openDialog()
      this.setTag({
        ...tag,
        careLineTagOptions: this.getTagOptions(tag.name),
      })
      this.setOptionId(prop('id', head(this.tag.options)))
    },
    closeDialog() {
      this.isOpen = false
      this.setOptionId(null)
      this.setTag({})
      this.setIsEditing(false)
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    getAvailableTags() {
      return this.listCareLineAvailableTags(this.patientId)
    },
    getErrorMessage(errorCode) {
      return equals('patient_already_has_care_line_tag', errorCode)
        ? 'Tag já inserida ao paciente'
        : 'Erro ao salvar Tag'
    },
    async saveCareLineTagPatient() {
      try {
        if (this.isEditing) {
          await this.updateCareLineTagPatientOption({
            tagId: this.tag.id,
            careLineTagOptionId: this.optionId,
          })
        } else {
          await this.createCareLineTagPatient({
            patientId: this.patientId,
            careLineTagId: this.tag.id,
            careLineTagOptionId: this.optionId,
          })
        }
        this.closeDialog()
        this.buildSnackbar('success', 'Tag salva com sucesso')
      } catch (error) {
        this.buildSnackbar(
          'error',
          this.getErrorMessage(path(['response', 'data', 'errorCode'], error)),
        )
      }
      this.setEmrPatient(this.patientId)
    },
  },
}
</script>

<style lang="stylus" scoped>
.patient-tags
  align-items center
  padding 1rem 0

.patient-tags__chips
  flex-wrap wrap
</style>
