<template>
  <div>
    <div class="filters-container pt-4 pb-0">
      <v-container
        fluid
        class="px-11"
      >
        <v-row>
          <v-col class="pb-0">
            <v-autocomplete
              v-model="filters.hmos"
              :items="hmos"
              label="Operadora"
              item-value="id"
              item-text="name"
              hide-details
              filled
              multiple
              clearable
              :search-input.sync="searchHmoInput"
              @blur="onSelectHmoBlur()"
            />
          </v-col>
          <v-col class="pb-0">
            <vc-date-picker
              v-model="filters.closeDateInterval"
              locale="pt-br"
              color="green"
              is-range
            >
              <template v-slot="{ inputEvents, inputValue }">
                <v-text-field
                  :value="formattedDate(filters.closeDateInterval)"
                  readonly
                  label="Fechamento"
                  append-icon="mdi-calendar"
                  filled
                  hide-details
                  clearable
                  v-on="inputEvents.start"
                  @click:clear="filters.closeDateInterval = null"
                />
              </template>
            </vc-date-picker>
          </v-col>
          <v-col class="pb-0">
            <v-text-field
              v-model="filters.billNumber"
              label="Número da OS"
              hide-details
              type="number"
              filled
              @blur="onBillNumberBlur()"
            />
          </v-col>
          <v-col class="pb-0">
            <v-combobox
              v-model="filters.statuses"
              label="Status"
              multiple
              filled
              clearable
              item-text="label"
              item-value="value"
              :items="billStatuses"
              :return-object="true"
              @blur="onSelectStatusesBlur()"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="mt-8 mx-11">
      <v-data-table
        class="preferred-network-table table--striped"
        :headers="billHeaders"
        :items="searchedBills"
        :items-per-page="searchedBills.length"
        :loading="isLoadingResults"
        no-data-text="Sem faturas para exibir"
        hide-default-footer
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.hmo.name }}</td>
            <td>{{ item.closeDate | formatDate }}</td>
            <td>{{ formatStatus(item.status) }}</td>
            <td>{{ item.number }}</td>
            <td>{{ item.itemsPerTypeQuantity.appointments }}</td>
            <td>{{ item.itemsPerTypeQuantity.exams }}</td>
            <td>{{ item.orderQuantity }}</td>
            <td>{{ item.price | formatMoney }}</td>
            <td>
              <v-flex class="align-center justify-space-around">
                <v-btn
                  icon
                  fab
                  @click="openEditBillDialog(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn
                  class="btn mr-4"
                  color="primary"
                  outlined
                >
                  Relatório de OS
                </v-btn>
              </v-flex>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <edit-bill-dialog
      v-if="selectedBill"
      :show-dialog="showEditBillDialog"
      :bill-data="selectedBill"
      @update:showDialog="setShowEditBillDialog()"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import isNilOrEmpty from '@/utils/dataValidators'
import { BILL } from 'amparo-enums'
import EditBillDialog from './EditBillDialog'

export default {
  components: {
    EditBillDialog,
  },
  data() {
    return {
      showEditBillDialog: false,
      selectedBill: null,
      isLoadingResults: false,
      searchHmoInput: '',
      previousHmosValue: [],
      filters: {
        hmos: null,
        closeDateInterval: {
          start: null,
          end: null,
        },
        billNumber: null,
        statuses: null,
      },
      billHeaders: [
        {
          text: 'Operadora',
          value: 'hmo.name',
        },
        {
          text: 'Fechamento',
          value: 'closeDate',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Fatura',
          value: 'number',
        },
        {
          text: 'Itens de consulta',
          value: 'itemsPerTypeQuantity.appointments',
        },
        {
          text: 'Itens de exame',
          value: 'itemsPerTypeQuantity.exams',
        },
        {
          text: 'Qtd de OS',
          value: 'orderQuantity',
        },
        {
          text: 'Valor total',
          value: 'price',
        },
        {
          text: '',
          value: 'actions',
        },
      ],
      searchedBills: [],
    }
  },
  computed: {
    ...mapGetters({
      hmos: 'healthMaintenanceOrganization/hmos',
    }),
    billStatuses() {
      return Object.values(BILL.status)
    },
  },
  watch: {
    'filters.closeDateInterval': function closeDateInterval() {
      this.setFilters()
    },
  },
  async mounted() {
    this.$bus.$on('update:bill', () => this.setFilters())
    this.searchedBills = await this.listBills()
  },
  beforeDestroy() {
    this.$bus.$off('update:bill')
  },
  methods: {
    ...mapActions({
      listBills: 'billing/listBills',
    }),
    openEditBillDialog(bill) {
      this.selectedBill = bill
      this.showEditBillDialog = true
    },
    setShowEditBillDialog() {
      this.showEditBillDialog = !this.showEditBillDialog
    },
    formatStatus(status) {
      return BILL.status[status].label
    },
    formattedDate(dateRange) {
      if (isNilOrEmpty(dateRange?.start)) return null
      if (moment(dateRange.start).isSame(dateRange.end)) return moment(dateRange.start).format('DD/MM/YYYY')

      return `${moment(dateRange.start).format('DD/MM/YYYY')} a ${moment(dateRange.end).format('DD/MM/YYYY')}`
    },
    onSelectHmoBlur() {
      this.searchHmoInput = ''
      this.setFilters()
    },
    onBillNumberBlur() {
      this.setFilters()
    },
    onCloseDateIntervalChange() {
      this.setFilters()
    },
    onSelectStatusesBlur() {
      this.setFilters()
    },
    async setFilters() {
      this.isLoadingResults = true
      const params = {
        hmoIds: this.filters.hmos,
        billNumber: this.filters.billNumber,
        closeDateInterval: this.filters.closeDateInterval?.start
          ? [
            moment(this.filters.closeDateInterval.start).format('YYYY-MM-DD'),
            moment(this.filters.closeDateInterval.end).format('YYYY-MM-DD'),
          ]
          : null,
        statuses: this.filters?.statuses?.map(status => status.value),
      }

      this.searchedBills = await this.listBills(params)
      this.isLoadingResults = false
    },
  },
}
</script>

<style lang="stylus" scoped>

.preferred-network-table >>> thead > tr
  background-color #3233380D !important
  box-shadow 0px 3px 1px 0px #E0E0E0E5 !important
  font-size 0.9rem !important

.preferred-network-table >>> thead > tr > th
  color #707372 !important
  font-size 0.9rem !important
  padding 16px !important

.preferred-network-table >>> tbody > tr
  box-shadow 0px 0.5px 2px 0px #DBDBDB

.preferred-network-table >>> tbody > tr > td
  color #323338CC !important
  padding 16px !important
  font-weight 400 !important

</style>
