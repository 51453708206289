import axios from '@/lib/axios'

const actions = {
  createFormQuestion(_, formQuestion) {
    return new Promise((resolve, reject) => {
      axios.post('/emr/form_question', formQuestion)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  updateFormQuestion(_, { formQuestionId, formQuestionData }) {
    return new Promise((resolve, reject) => {
      axios.patch(`/emr/form_question/${formQuestionId}`, formQuestionData)
        .then(() => {
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  deleteFormQuestion(_, formQuestionId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/emr/form_question/${formQuestionId}`)
        .then(() => {
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
}

export default {
  actions,
}
