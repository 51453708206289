<template>
  <div>
    <div
      :class="{
        'filters-container': true,
        'filters-container--fixed': isInsideEncounter,
      }"
    >
      <v-layout class="filters">
        <v-flex class="filters__field mr-2">
          <v-autocomplete
            v-model="filters.professionalId"
            :items="patientsProfessionalList"
            item-text="name"
            item-value="id"
            label="Profissional"
            append-icon
            no-data-text="Não encontrado"
            attach
            clearable
            filled
            @change="filterEncountersByDoctor"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.name" />
                <v-list-item-subtitle>{{ item.professionName }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex class="filters__field ml-2">
          <v-menu
            v-model="menuDate"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            attach
            :nudge-right="40"
            max-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :value="formatFilterDateToDDMMYYYY"
                readonly
                label="Data da consulta"
                append-icon="mdi-calendar"
                clearable
                filled
                v-on="on"
                @click:clear="
                  filters.date = null;
                  filterEncountersByDate()
                "
              />
            </template>
            <v-date-picker
              v-model="filters.date"
              :events="daysWithEncounters"
              event-color="green"
              locale="pt-br"
              color="primary"
              no-title
              @change="filterEncountersByDate"
            />
          </v-menu>
        </v-flex>
      </v-layout>
    </div>
    <component
      :is="isInsideEncounter ? 'v-list' : 'emr-timeline-container'"
      :is-loading="!hasLoadEncounters"
    >
      <component
        :is="isInsideEncounter ? 'v-list-item' : 'div'"
        :class="{ 'list__item' : isInsideEncounter }"
      >
        <emr-timeline-header
          v-if="!isInsideEncounter"
        >
          <emr-new-encounter
            v-if="isUserDoctorOrNurse"
            :encounter-appointment-options="encounterAppointmentOptions"
          />
        </emr-timeline-header>
        <component
          :is="isInsideEncounter ? 'v-list-item-content' : 'v-timeline-item'"
          :class="{ 'list__item-content' : isInsideEncounter }"
          hide-dot
        >
          <div
            class="timeline__header-attendances"
            :class="{
              'pa-0 my-4 justify-end': isInsideEncounter,
              'mb-2': !isInsideEncounter,
            }"
          >
            <h2 v-if="!isInsideEncounter">
              Atendimentos
            </h2>
            <div>
              <emr-generate-report :patient-id="patient.id" />
              <v-select
                v-model="pagination.descending"
                :items="orderByTypes"
                hide-details
                item-text="label"
                item-value="value"
              />
            </div>
          </div>
        </component>
        <component
          :is="isInsideEncounter ? 'v-list-item-content' : 'v-timeline-item'"
          v-for="emr in encounters"
          :key="emr.id"
          :hide-dot="hideTimelineDots"
          :class="{ 'list__item-content' : isInsideEncounter }"
          small
        >
          <template
            v-if="!isInsideEncounter"
            v-slot:icon
          >
            <time-line-item-chip
              :label="emr.date | convertUtcDateToBrDate | formatDate"
            />
          </template>
          <v-card class="elevation-2 card__container">
            <v-card-title
              class="card__header px-6"
              :class="{ 'card__header___arrow': !isInsideEncounter }"
            >
              <emr-encounter-resume-header
                :emr="emr"
              />
            </v-card-title>
            <v-card-text class="font-weight-bold">
              <div v-if="hasResumeStruct(emr)">
                <emr-encounter-resume
                  :emr="emr"
                  :items-per-row="isInsideEncounter && !shouldShowSectionName ? 3 : 2"
                  :should-show-section-name="shouldShowSectionName(emr)"
                  :can-open-resume-modal="!isInsideEncounter"
                />
              </div>
              <div v-else>
                <emr-encounter-resume-legacy
                  v-if="emr.data.legacy"
                  :encounter-data="emr.data"
                  :column="true"
                />
                <emr-annotation-resume
                  v-else-if="hasEncounterName(emr.encounterFormName, 'ANNOTATION')"
                  :encounter-data="emr.data"
                  :encounter-id="emr.id"
                  :row="true"
                />
                <emr-immediate-care-resume
                  v-else-if="hasEncounterName(emr.encounterFormName, 'IMMEDIATE_CARE')"
                  :encounter-data="emr.data"
                  :encounter-id="emr.id"
                  :row="true"
                />
                <emr-old-encounter-resume
                  v-else
                  :encounter-data="emr.data"
                  :encounter-form-name="emr.encounterFormName"
                  :row="true"
                />
              </div>
              <input-reminder
                v-if="shouldShowReminder(emr.professional.id)"
                :can-add-reminder="false"
                :encounter-reminder="emr.data ? emr.data[reminderEncounterId] : ''"
              />
            </v-card-text>
          </v-card>
        </component>
        <component
          :is="isInsideEncounter ? 'v-list-item': 'v-timeline-item'"
          v-if="!patientHasEncounters"
          class="pb-0"
          hide-dot
        >
          <v-flex
            class="components__default-message"
            :class="[isInsideEncounter ? 'ml-n12': 'ml-8']"
          >
            {{ encountersEmptyMessage }}
          </v-flex>
        </component>
        <emr-history-pagination
          v-if="patientHasEncounters"
          :page="pagination.page"
          class="mb-6"
          @changePage="setPage"
        />
      </component>
    </component>
    <emr-new-care-line-patient-dialog
      v-if="newCareLinePatientModalIsOpen"
      @closeDialog="closeNewCareLinePatientModal"
    />
  </div>
</template>

<script>
import EmrTimelineHeader from '@/pages/Emr/EmrTimelineHeader'
import InputReminder from '@/components/emrFields/InputReminder'
import EmrPrintButton from '@/pages/Emr/EmrPrintButton'
import moment from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'
import {
  all,
  always,
  and,
  cond,
  defaultTo,
  equals,
  filter,
  includes,
  isEmpty,
  isNil,
  map,
  not,
  or,
  path,
  pathEq,
  propEq,
  T,
  toUpper,
  values,
  any,
} from 'ramda'
import { ENCOUNTER } from 'amparo-enums'
import encounterAnnotationReasonEnums from '@/enums/encounterAnnotationReason'
import EmrEncounterResume from './EmrEncounterResume'
import EmrOldEncounterResume from './EmrOldEncounterResume'
import EmrAnnotationResume from './EmrAnnotationResume'
import EmrImmediateCareResume from './EmrImmediateCareResume'
import EmrEncounterResumeLegacy from './EmrEncounterResumeLegacy'
import EmrGenerateReport from './EmrGenerateReport'
import EmrHistoryPagination from './EmrHistoryPagination'
import EmrNewEncounter from './EmrNewEncounter'
import EmrTimelineContainer from '../EmrTimelineContainer'
import EmrEncounterResumeHeader from './EmrEncounterResumeHeader'

const { ENCOUNTER_ANNOTATION_REASON } = encounterAnnotationReasonEnums

export default {
  components: {
    EmrTimelineHeader,
    InputReminder,
    EmrEncounterResume,
    EmrOldEncounterResume,
    EmrNewEncounter,
    EmrGenerateReport,
    EmrAnnotationResume,
    EmrPrintButton,
    EmrEncounterResumeLegacy,
    EmrHistoryPagination,
    EmrImmediateCareResume,
    EmrTimelineContainer,
    EmrEncounterResumeHeader,
    EmrNewCareLinePatientDialog: () => import('../EmrNewCareLinePatientDialog'),
    TimeLineItemChip: () => import('@/pages/Emr/TimeLineItemChip'),
  },
  props: {
    isInsideEncounter: {
      type: Boolean,
    },
    hideTimelineDots: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filters: {
        date: null,
        professionalId: null,
      },
      pagination: {
        page: 1,
        descending: true,
      },
      encounters: [],
      hasLoadEncounters: false,
      menuDate: false,
      dataToPrint: null,
      orderByTypes: values(ENCOUNTER.orderBy),
      newCareLinePatientModalIsOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      emrAllowedProfessions: 'emr/emrAllowedProfessions',
      emrEncounters: 'emr/encounters',
      emrEncounterAppointmentOptions: 'emr/encounterAppointmentOptions',
      patient: 'emr/patient',
      reminderEncounterId: 'emr/reminderEncounterId',
      user: 'authentication/user',
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
    }),
    ...mapState({
      showNewCareLinePatientModalAfterEncounter:
        ({ emr }) => emr.showNewCareLinePatientModalAfterEncounter,
    }),
    isUserDoctorOrNurse() {
      return includes(this.user.professionId, this.emrAllowedProfessions)
    },
    encounterAppointmentOptions() {
      if (not(isEmpty(this.emrEncounterAppointmentOptions))) {
        return this.emrEncounterAppointmentOptions
      }
      return JSON.parse(localStorage.getItem('_encounterOptions'))
    },
    formatFilterDateToDDMMYYYY() {
      return this.filters.date
        ? moment(this.filters.date).format('DD/MM/YYYY')
        : ''
    },
    daysWithEncounters() {
      return map(this.getFormatedDate, defaultTo([], this.encounters))
    },
    professionalDocument() {
      if (!this.dataToPrint) return ''
      return `
        ${this.dataToPrint.professional.documentType}
        ${this.dataToPrint.professional.documentNumber}
      `
    },
    encounterDate() {
      if (!this.dataToPrint) return ''
      return moment(this.dataToPrint.date).format('DD/MM/YYYY')
    },
    patientAge() {
      if (!this.dataToPrint) return ''
      return moment().diff(this.dataToPrint.patient.birthDate, 'years')
    },
    patientsProfessionalList() {
      const encountersProfessionals = map(
        encounter => ({
          id: encounter.professional.id,
          name: encounter.professional.name,
          professionName: encounter.professional.profession.name,
        }),
        defaultTo([], this.encounters),
      )

      return encountersProfessionals
    },
    patientHasEncounters() {
      return not(isEmpty(this.encounters))
    },
    filtersAreNull() {
      return all(isNil, values(this.filters))
    },
    encountersEmptyMessage() {
      return this.filtersAreNull
        ? 'Paciente não possui consultas'
        : 'Não há consultas para esta busca.'
    },
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        this.loadEncounters()
      },
    },
    showNewCareLinePatientModalAfterEncounter(newValue) {
      if (newValue) {
        this.newCareLinePatientModalIsOpen = true
      }
    },
  },
  created() {
    this.loadEncounters()
  },
  activated() {
    this.cleanFilters()
    this.loadEncounters()
  },
  deactivated() {
    this.cleanFilters()
    this.resetPagination()
    this.clearAutoSchedule()
  },
  methods: {
    ...mapActions({
      getEmrPaginateEncounters: 'emr/getEmrPaginateEncounters',
      setAutoSchedule: 'schedule/setAutoSchedule',
      setEncountersFilters: 'emr/setEncountersFilters',
      setEncountersPagination: 'emr/setEncountersPagination',
    }),
    closeNewCareLinePatientModal() {
      this.newCareLinePatientModalIsOpen = false
    },
    setPage(page) {
      this.pagination.page = page
    },
    hasResumeStruct(encounterEmr) {
      return encounterEmr?.encounterResumeStruct
        || encounterEmr?.encounterForm?.encounterResumeStruct
    },
    getChipAnnotationReasonClass(annotationReason) {
      return [
        'ma-2',
        `chip__annotation-reason--${annotationReason}`,
      ]
    },
    formatAnnotationReason(annotationReason) {
      return ENCOUNTER_ANNOTATION_REASON[toUpper(annotationReason)].label
    },
    formatEncounterFormName(encounterFormName) {
      return cond([
        [equals('ANNOTATION'), always('anotação')],
        [T, always('atendimento')],
      ])(encounterFormName)
    },
    clearAutoSchedule() {
      this.setAutoSchedule({
        encounter: {},
        isShowing: false,
      })
    },
    getFormatedDate({ date }) {
      return moment(date).format('YYYY-MM-DD')
    },
    shouldShowReminder(professionalId) {
      return equals(professionalId, this.user.professionalId)
    },
    isEmptyOrNil(value) {
      return isNil(value) || isEmpty(value)
    },
    resetEncountersList() {
      this.encountersToShow = [...this.encounters]
    },
    filterEncountersByDoctor() {
      this.setEncountersFilters(this.filters)
      this.loadEncounters()
    },
    filterEncountersByDate() {
      if (not(this.isEmptyOrNil(this.filters.date))) {
        this.filters.date = moment(this.filters.date).format('YYYY-MM-DD')
      }
      this.setEncountersFilters(this.filters)
      this.loadEncounters()
    },
    async loadEncounters() {
      this.setEncountersPagination(this.pagination)
      if (not(isEmpty(this.patient))) {
        this.hasLoadEncounters = false
        await this.getEmrPaginateEncounters(this.patient.id)
        this.encounters = filter(
          propEq('closed', 'status'),
          path(['rows'], this.emrEncounters),
        )
        this.resetEncountersList()
        this.hasLoadEncounters = true
      }
    },
    showAppropriatePronoun(emr) {
      if (includes('Técnico', emr.professional.profession.name)) return ''
      return emr.professional.biologicalSex === 'F' ? 'Dra.' : 'Dr.'
    },
    calculateEmrEndDate(startDate, durationEmr) {
      return moment(startDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
        .add(durationEmr, 'milliseconds')
        .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    },
    hasEncounterName(name, encounterFormName) {
      return equals(name, encounterFormName)
    },
    cleanFilters() {
      this.filters = {
        date: null,
        professionalId: null,
      }
      this.setEncountersFilters(this.filters)
    },
    resetPagination() {
      this.pagination = {
        page: 1,
        descending: true,
      }
      this.setEncountersPagination(this.pagination)
    },
    immediateCareHasAbsentPatient(emr) {
      return and(
        includes(emr.encounterFormName, ['IMMEDIATE_CARE', 'SOAP']),
        or(
          pathEq('missed', ['appointment', 'status'], emr),
          pathEq('missed', ['immediateCare', 'status'], emr),
        ),
      )
    },
    shouldShowSectionName(emr) {
      const sectionFormNames = ['SOAP']
      const encounterFormName = path(['encounterFormName'], emr)
      return any(equals(encounterFormName), sectionFormNames)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../../style/_core/colors.styl'

.timeline__header-attendances
  display flex
  justify-content space-between
  width 100%
  padding-left 2rem
  & div
    display flex
    flex-direction column
    align-items flex-end
    width 350px
  & h2
    color $evening-sea

.v-btn.v-btn--large
  margin-top 0px
  height 56px
  border-radius 8px

.filters-container
  width 100%
  height 120px
  background-color #e8f8e8

.filters-container--fixed
  position -webkit-sticky
  position sticky
  top 0
  z-index 3
  background-color white

.filters
  height 8rem
  padding 0 2rem

.filters__field
  margin-top 2rem

.card__container
  margin-left 20px

.card__header
  word-break: break-word
  background-color #eeeeee

.card__header___arrow
  &:after
    content ''
    position absolute
    left 0
    top 25px
    width 0
    height 0
    border 15px solid transparent
    border-right-color #eee
    border-left 0
    margin-top -15px
    margin-left -15px

div.card__container.v-card.v-sheet.theme--light::before,
div.card__container.v-card.v-sheet.theme--light::after
  display none

.legacy
  color $japanese-laurel !important
  text-transform uppercase
  margin-right 10px

.encounter__type--elective
  color #007200

.encounter__type--urgent
  color #c43640

.chip__annotation-reason--administrative_demand
  background-color #C0DFD3 !important

.chip__annotation-reason--erratic
  background-color #F29F9E !important

.chip__annotation-reason--telemonitoring
  background-color #fccc7e !important

.chip__annotation-reason--prescription
  background-color #C3E0E8 !important

.chip__annotation-reason--missing_patient
  background-color #F3C5A2 !important

.chip__annotation-reason--presential
  background-color #c8a2c8 !important

.list__item
  flex-direction column
  padding 0 32px

.list__item-content
  display contents
  width 100%
  .card__container
    width 100%
    margin 16px 0
</style>
