import moment from 'moment'

const currentDate = moment().format('YYYY-MM-DD')

export default [
  {
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      agendaOccupation: {
        cli_cjxyringi0000i2vog8g55l7q: 8,
        cli_ck9b7yjf3000195vohxz60nio: 0,
      },
      careTeams: [
        {
          id: 'car_tea_ckn7rbkez0000fpdqge9aa3hm',
          name: 'Equipe Lapa',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          telephone: '11111111111',
          isTele: false,
          agendaOccupation: 615,
        },
      ],
    },
    clinic: {
      id: 'cli_ck9b7yjf3000195vohxz60nio',
      name: 'Amparo Tele',
    },
    ranking: 0.15375,
    slots: [
      {
        id: 'slo_cl8tm4tqb03z80iefbp57g6a7',
        agendaId: 'age_cl8ta612j00140jludclv3dme',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '17:00:00',
        endTime: '17:14:59',
        date: `${currentDate}T20:00:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_ck9b7yjf3000195vohxz60nio',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl8ta612j00140jludclv3dme',
            startDate: '2022-10-07 00:00:00',
            endDate: null,
            startTime: '17:00:00',
            endTime: '18:30:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_ck9b7yjf3000195vohxz60nio',
            roomId: 'roo_cjy8zgpuk000zi3vo6tfjh3sa',
            storedAt: null,
          },
          startDate: '2022-10-07 00:00:00',
          endDate: null,
          id: 'age_cl8ta612j00140jludclv3dme',
          startTime: '17:00:00',
          endTime: '18:30:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_ck9b7yjf3000195vohxz60nio',
          roomId: 'roo_cjy8zgpuk000zi3vo6tfjh3sa',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-10-03T21:25:01.292Z',
          updatedAt: '2022-10-03T21:25:01.292Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl8tm4tqb03z90iefbjrpb6mw',
        agendaId: 'age_cl8ta612j00140jludclv3dme',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '17:15:00',
        endTime: '17:29:59',
        date: `${currentDate}T20:15:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_ck9b7yjf3000195vohxz60nio',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl8ta612j00140jludclv3dme',
            startDate: '2022-10-07 00:00:00',
            endDate: null,
            startTime: '17:00:00',
            endTime: '18:30:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_ck9b7yjf3000195vohxz60nio',
            roomId: 'roo_cjy8zgpuk000zi3vo6tfjh3sa',
            storedAt: null,
          },
          startDate: '2022-10-07 00:00:00',
          endDate: null,
          id: 'age_cl8ta612j00140jludclv3dme',
          startTime: '17:00:00',
          endTime: '18:30:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_ck9b7yjf3000195vohxz60nio',
          roomId: 'roo_cjy8zgpuk000zi3vo6tfjh3sa',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-10-03T21:25:01.292Z',
          updatedAt: '2022-10-03T21:25:01.292Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl8tm4tqb03za0ief0wwm3w9z',
        agendaId: 'age_cl8ta612j00140jludclv3dme',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '17:30:00',
        endTime: '17:44:59',
        date: `${currentDate}T20:30:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_ck9b7yjf3000195vohxz60nio',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl8ta612j00140jludclv3dme',
            startDate: '2022-10-07 00:00:00',
            endDate: null,
            startTime: '17:00:00',
            endTime: '18:30:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_ck9b7yjf3000195vohxz60nio',
            roomId: 'roo_cjy8zgpuk000zi3vo6tfjh3sa',
            storedAt: null,
          },
          startDate: '2022-10-07 00:00:00',
          endDate: null,
          id: 'age_cl8ta612j00140jludclv3dme',
          startTime: '17:00:00',
          endTime: '18:30:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_ck9b7yjf3000195vohxz60nio',
          roomId: 'roo_cjy8zgpuk000zi3vo6tfjh3sa',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-10-03T21:25:01.292Z',
          updatedAt: '2022-10-03T21:25:01.292Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl8tm4tqb03zb0ief9dzc3chr',
        agendaId: 'age_cl8ta612j00140jludclv3dme',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '17:45:00',
        endTime: '17:59:59',
        date: `${currentDate}T20:45:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_ck9b7yjf3000195vohxz60nio',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl8ta612j00140jludclv3dme',
            startDate: '2022-10-07 00:00:00',
            endDate: null,
            startTime: '17:00:00',
            endTime: '18:30:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_ck9b7yjf3000195vohxz60nio',
            roomId: 'roo_cjy8zgpuk000zi3vo6tfjh3sa',
            storedAt: null,
          },
          startDate: '2022-10-07 00:00:00',
          endDate: null,
          id: 'age_cl8ta612j00140jludclv3dme',
          startTime: '17:00:00',
          endTime: '18:30:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_ck9b7yjf3000195vohxz60nio',
          roomId: 'roo_cjy8zgpuk000zi3vo6tfjh3sa',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-10-03T21:25:01.292Z',
          updatedAt: '2022-10-03T21:25:01.292Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl8tm4tqb03zc0ief1zyq5xj5',
        agendaId: 'age_cl8ta612j00140jludclv3dme',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '18:00:00',
        endTime: '18:14:59',
        date: `${currentDate}T21:00:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_ck9b7yjf3000195vohxz60nio',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl8ta612j00140jludclv3dme',
            startDate: '2022-10-07 00:00:00',
            endDate: null,
            startTime: '17:00:00',
            endTime: '18:30:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_ck9b7yjf3000195vohxz60nio',
            roomId: 'roo_cjy8zgpuk000zi3vo6tfjh3sa',
            storedAt: null,
          },
          startDate: '2022-10-07 00:00:00',
          endDate: null,
          id: 'age_cl8ta612j00140jludclv3dme',
          startTime: '17:00:00',
          endTime: '18:30:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_ck9b7yjf3000195vohxz60nio',
          roomId: 'roo_cjy8zgpuk000zi3vo6tfjh3sa',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-10-03T21:25:01.292Z',
          updatedAt: '2022-10-03T21:25:01.292Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl8tm4tqb03zd0iefd5w699it',
        agendaId: 'age_cl8ta612j00140jludclv3dme',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '18:15:00',
        endTime: '18:29:59',
        date: `${currentDate}T21:15:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_ck9b7yjf3000195vohxz60nio',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl8ta612j00140jludclv3dme',
            startDate: '2022-10-07 00:00:00',
            endDate: null,
            startTime: '17:00:00',
            endTime: '18:30:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_ck9b7yjf3000195vohxz60nio',
            roomId: 'roo_cjy8zgpuk000zi3vo6tfjh3sa',
            storedAt: null,
          },
          startDate: '2022-10-07 00:00:00',
          endDate: null,
          id: 'age_cl8ta612j00140jludclv3dme',
          startTime: '17:00:00',
          endTime: '18:30:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_ck9b7yjf3000195vohxz60nio',
          roomId: 'roo_cjy8zgpuk000zi3vo6tfjh3sa',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-10-03T21:25:01.292Z',
          updatedAt: '2022-10-03T21:25:01.292Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
    ],
  },
  {
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      agendaOccupation: {
        cli_cjxyringi0000i2vog8g55l7q: 8,
        cli_ck9b7yjf3000195vohxz60nio: 0,
      },
      careTeams: [
        {
          id: 'car_tea_ckn7rbkez0000fpdqge9aa3hm',
          name: 'Equipe Lapa',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          telephone: '11111111111',
          isTele: false,
          agendaOccupation: 615,
        },
      ],
    },
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    ranking: 0.18075000000000002,
    slots: [
      {
        id: 'slo_cl83w7hgk00ld0icz7jap3fj6',
        agendaId: 'age_cl587ol4e00yj0jqa5xkcbsqr',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '07:15:00',
        endTime: '07:29:59',
        date: `${currentDate}T10:15:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
            startDate: '2022-07-08 00:00:00',
            endDate: null,
            startTime: '07:00:00',
            endTime: '12:00:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
            roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
            storedAt: null,
          },
          startDate: '2022-07-08 00:00:00',
          endDate: null,
          id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
          startTime: '07:00:00',
          endTime: '12:00:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-07-05T13:33:11.534Z',
          updatedAt: '2022-07-05T13:33:11.534Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl83w7hgk00le0icz1nr3emed',
        agendaId: 'age_cl587ol4e00yj0jqa5xkcbsqr',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '07:30:00',
        endTime: '07:44:59',
        date: `${currentDate}T10:30:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
            startDate: '2022-07-08 00:00:00',
            endDate: null,
            startTime: '07:00:00',
            endTime: '12:00:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
            roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
            storedAt: null,
          },
          startDate: '2022-07-08 00:00:00',
          endDate: null,
          id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
          startTime: '07:00:00',
          endTime: '12:00:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-07-05T13:33:11.534Z',
          updatedAt: '2022-07-05T13:33:11.534Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl83w7hgk00lf0icz4j7egzk2',
        agendaId: 'age_cl587ol4e00yj0jqa5xkcbsqr',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '07:45:00',
        endTime: '07:59:59',
        date: `${currentDate}T10:45:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
            startDate: '2022-07-08 00:00:00',
            endDate: null,
            startTime: '07:00:00',
            endTime: '12:00:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
            roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
            storedAt: null,
          },
          startDate: '2022-07-08 00:00:00',
          endDate: null,
          id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
          startTime: '07:00:00',
          endTime: '12:00:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-07-05T13:33:11.534Z',
          updatedAt: '2022-07-05T13:33:11.534Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl83w7hgk00lg0iczajm07jz5',
        agendaId: 'age_cl587ol4e00yj0jqa5xkcbsqr',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '08:00:00',
        endTime: '08:14:59',
        date: `${currentDate}T11:00:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
            startDate: '2022-07-08 00:00:00',
            endDate: null,
            startTime: '07:00:00',
            endTime: '12:00:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
            roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
            storedAt: null,
          },
          startDate: '2022-07-08 00:00:00',
          endDate: null,
          id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
          startTime: '07:00:00',
          endTime: '12:00:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-07-05T13:33:11.534Z',
          updatedAt: '2022-07-05T13:33:11.534Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl83w7hgk00lh0icz0yl9dwen',
        agendaId: 'age_cl587ol4e00yj0jqa5xkcbsqr',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '08:15:00',
        endTime: '08:29:59',
        date: `${currentDate}T11:15:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
            startDate: '2022-07-08 00:00:00',
            endDate: null,
            startTime: '07:00:00',
            endTime: '12:00:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
            roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
            storedAt: null,
          },
          startDate: '2022-07-08 00:00:00',
          endDate: null,
          id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
          startTime: '07:00:00',
          endTime: '12:00:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-07-05T13:33:11.534Z',
          updatedAt: '2022-07-05T13:33:11.534Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl83w7hgk00li0icz5g4m51a3',
        agendaId: 'age_cl587ol4e00yj0jqa5xkcbsqr',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '08:30:00',
        endTime: '08:44:59',
        date: `${currentDate}T11:30:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
            startDate: '2022-07-08 00:00:00',
            endDate: null,
            startTime: '07:00:00',
            endTime: '12:00:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
            roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
            storedAt: null,
          },
          startDate: '2022-07-08 00:00:00',
          endDate: null,
          id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
          startTime: '07:00:00',
          endTime: '12:00:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-07-05T13:33:11.534Z',
          updatedAt: '2022-07-05T13:33:11.534Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl83w7hgk00lj0icz52vq9k39',
        agendaId: 'age_cl587ol4e00yj0jqa5xkcbsqr',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '08:45:00',
        endTime: '08:59:59',
        date: `${currentDate}T11:45:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
            startDate: '2022-07-08 00:00:00',
            endDate: null,
            startTime: '07:00:00',
            endTime: '12:00:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
            roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
            storedAt: null,
          },
          startDate: '2022-07-08 00:00:00',
          endDate: null,
          id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
          startTime: '07:00:00',
          endTime: '12:00:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-07-05T13:33:11.534Z',
          updatedAt: '2022-07-05T13:33:11.534Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl83w7hgk00lk0iczg3hq4h5y',
        agendaId: 'age_cl587ol4e00yj0jqa5xkcbsqr',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '09:00:00',
        endTime: '09:14:59',
        date: `${currentDate}T12:00:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
            startDate: '2022-07-08 00:00:00',
            endDate: null,
            startTime: '07:00:00',
            endTime: '12:00:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
            roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
            storedAt: null,
          },
          startDate: '2022-07-08 00:00:00',
          endDate: null,
          id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
          startTime: '07:00:00',
          endTime: '12:00:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-07-05T13:33:11.534Z',
          updatedAt: '2022-07-05T13:33:11.534Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl83w7hgl00ll0icz8xas95k2',
        agendaId: 'age_cl587ol4e00yj0jqa5xkcbsqr',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '09:15:00',
        endTime: '09:29:59',
        date: `${currentDate}T12:15:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
            startDate: '2022-07-08 00:00:00',
            endDate: null,
            startTime: '07:00:00',
            endTime: '12:00:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
            roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
            storedAt: null,
          },
          startDate: '2022-07-08 00:00:00',
          endDate: null,
          id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
          startTime: '07:00:00',
          endTime: '12:00:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-07-05T13:33:11.534Z',
          updatedAt: '2022-07-05T13:33:11.534Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl83w7hgl00lm0icz5boo3qfr',
        agendaId: 'age_cl587ol4e00yj0jqa5xkcbsqr',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '09:30:00',
        endTime: '09:44:59',
        date: `${currentDate}T12:30:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
            startDate: '2022-07-08 00:00:00',
            endDate: null,
            startTime: '07:00:00',
            endTime: '12:00:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
            roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
            storedAt: null,
          },
          startDate: '2022-07-08 00:00:00',
          endDate: null,
          id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
          startTime: '07:00:00',
          endTime: '12:00:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-07-05T13:33:11.534Z',
          updatedAt: '2022-07-05T13:33:11.534Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl83w7hgl00ln0icz1zjphpb4',
        agendaId: 'age_cl587ol4e00yj0jqa5xkcbsqr',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '09:45:00',
        endTime: '09:59:59',
        date: `${currentDate}T12:45:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
            startDate: '2022-07-08 00:00:00',
            endDate: null,
            startTime: '07:00:00',
            endTime: '12:00:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
            roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
            storedAt: null,
          },
          startDate: '2022-07-08 00:00:00',
          endDate: null,
          id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
          startTime: '07:00:00',
          endTime: '12:00:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-07-05T13:33:11.534Z',
          updatedAt: '2022-07-05T13:33:11.534Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl83w7hgl00lo0icz3hk246b0',
        agendaId: 'age_cl587ol4e00yj0jqa5xkcbsqr',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '10:00:00',
        endTime: '10:14:59',
        date: `${currentDate}T13:00:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
            startDate: '2022-07-08 00:00:00',
            endDate: null,
            startTime: '07:00:00',
            endTime: '12:00:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
            roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
            storedAt: null,
          },
          startDate: '2022-07-08 00:00:00',
          endDate: null,
          id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
          startTime: '07:00:00',
          endTime: '12:00:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-07-05T13:33:11.534Z',
          updatedAt: '2022-07-05T13:33:11.534Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl83w7hgl00lp0icz8saj8vaq',
        agendaId: 'age_cl587ol4e00yj0jqa5xkcbsqr',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '10:15:00',
        endTime: '10:29:59',
        date: `${currentDate}T13:15:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
            startDate: '2022-07-08 00:00:00',
            endDate: null,
            startTime: '07:00:00',
            endTime: '12:00:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
            roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
            storedAt: null,
          },
          startDate: '2022-07-08 00:00:00',
          endDate: null,
          id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
          startTime: '07:00:00',
          endTime: '12:00:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-07-05T13:33:11.534Z',
          updatedAt: '2022-07-05T13:33:11.534Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl83w7hgl00lq0icz37p2ffzn',
        agendaId: 'age_cl587ol4e00yj0jqa5xkcbsqr',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '10:30:00',
        endTime: '10:44:59',
        date: `${currentDate}T13:30:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
            startDate: '2022-07-08 00:00:00',
            endDate: null,
            startTime: '07:00:00',
            endTime: '12:00:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
            roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
            storedAt: null,
          },
          startDate: '2022-07-08 00:00:00',
          endDate: null,
          id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
          startTime: '07:00:00',
          endTime: '12:00:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-07-05T13:33:11.534Z',
          updatedAt: '2022-07-05T13:33:11.534Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl83w7hgl00lr0icz38bi453i',
        agendaId: 'age_cl587ol4e00yj0jqa5xkcbsqr',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '10:45:00',
        endTime: '10:59:59',
        date: `${currentDate}T13:45:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
            startDate: '2022-07-08 00:00:00',
            endDate: null,
            startTime: '07:00:00',
            endTime: '12:00:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
            roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
            storedAt: null,
          },
          startDate: '2022-07-08 00:00:00',
          endDate: null,
          id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
          startTime: '07:00:00',
          endTime: '12:00:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-07-05T13:33:11.534Z',
          updatedAt: '2022-07-05T13:33:11.534Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl83w7hgn00ls0icz30j002ph',
        agendaId: 'age_cl587ol4e00yj0jqa5xkcbsqr',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '11:00:00',
        endTime: '11:14:59',
        date: `${currentDate}T14:00:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
            startDate: '2022-07-08 00:00:00',
            endDate: null,
            startTime: '07:00:00',
            endTime: '12:00:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
            roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
            storedAt: null,
          },
          startDate: '2022-07-08 00:00:00',
          endDate: null,
          id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
          startTime: '07:00:00',
          endTime: '12:00:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-07-05T13:33:11.534Z',
          updatedAt: '2022-07-05T13:33:11.534Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl83w7hgo00lt0iczg04u31dx',
        agendaId: 'age_cl587ol4e00yj0jqa5xkcbsqr',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '11:15:00',
        endTime: '11:29:59',
        date: `${currentDate}T14:15:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
            startDate: '2022-07-08 00:00:00',
            endDate: null,
            startTime: '07:00:00',
            endTime: '12:00:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
            roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
            storedAt: null,
          },
          startDate: '2022-07-08 00:00:00',
          endDate: null,
          id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
          startTime: '07:00:00',
          endTime: '12:00:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-07-05T13:33:11.534Z',
          updatedAt: '2022-07-05T13:33:11.534Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl83w7hgo00lu0iczb7p8dt6n',
        agendaId: 'age_cl587ol4e00yj0jqa5xkcbsqr',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '11:30:00',
        endTime: '11:44:59',
        date: `${currentDate}T14:30:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
            startDate: '2022-07-08 00:00:00',
            endDate: null,
            startTime: '07:00:00',
            endTime: '12:00:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
            roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
            storedAt: null,
          },
          startDate: '2022-07-08 00:00:00',
          endDate: null,
          id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
          startTime: '07:00:00',
          endTime: '12:00:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-07-05T13:33:11.534Z',
          updatedAt: '2022-07-05T13:33:11.534Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
      {
        id: 'slo_cl83w7hgo00lv0iczf6cwegyl',
        agendaId: 'age_cl587ol4e00yj0jqa5xkcbsqr',
        reserves: null,
        interval: 15,
        status: 'available',
        startTime: '11:45:00',
        endTime: '11:59:59',
        date: `${currentDate}T14:45:00.000Z`,
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
        specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
        reservedUntil: null,
        agenda: {
          formatted: {
            id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
            startDate: '2022-07-08 00:00:00',
            endDate: null,
            startTime: '07:00:00',
            endTime: '12:00:00',
            interval: 15,
            maxSlots: 2,
            dayOfTheWeek: 5,
            professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
            specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
            clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
            roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
            storedAt: null,
          },
          startDate: '2022-07-08 00:00:00',
          endDate: null,
          id: 'age_cl587ol4e00yj0jqa5xkcbsqr',
          startTime: '07:00:00',
          endTime: '12:00:00',
          interval: 15,
          maxSlots: 2,
          dayOfTheWeek: 5,
          professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
          specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
          clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
          roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
          itemsIds: [
            'ite_cl007kxw2000zlvzua7tk54s7',
            'ite_cl007kxw2000ylvzu9iv49bic',
            'ite_cl007kxw2000xlvzu78qe07q4',
            'ite_cl007kxw2000wlvzu54sd8hr8',
            'ite_cl44iauc7003h77zuc9bl6v24',
            'ite_cl44iauc7003g77zufos62h9e',
            'ite_cl44iauc7003f77zu25qo3ihb',
            'ite_cl007kxw2000tlvzuh8u98xui',
            'ite_cl44iauc7003d77zugibw8r9u',
            'ite_cl007kxw2000rlvzu7j9q4cft',
            'ite_cl007kxw2000qlvzu8gir8q0e',
            'ite_cl007kxw2000plvzu8qeddiz3',
            'ite_cl007kxw2000olvzu3kun87pa',
            'ite_cl007kxw2000nlvzub6c4gyf5',
            'ite_cl007kxw2000mlvzu4vb24fvc',
            'ite_cl007kxw2000llvzub4q7fx9s',
            'ite_cl007kxw1000jlvzu04bx77ou',
            'ite_cl007kxw1000ilvzuh486cfq9',
            'ite_cl44iauc6003277zu5fp2frrn',
            'ite_cl007kxw1000glvzu7vk9ayri',
            'ite_cl007kxw1000flvzu6wqzfxbm',
            'ite_cl007kxw1000dlvzu393s06nk',
            'ite_cl007kxw1000clvzu91t725kt',
            'ite_cl007kxw1000blvzu9mo306ra',
            'ite_cl007kxw1000alvzuayke6db2',
            'ite_cl007kxw10008lvzufvc31967',
            'ite_cl007kxw10007lvzucliw6cwc',
            'ite_cl007kxw10006lvzu4ni7cwke',
            'ite_cl007kxw00004lvzuc2ry1ead',
            'ite_cl007kxw00003lvzucdddgnt0',
            'ite_cl6nq0gnp000109jng46y98n0',
            'ite_cl45uskk20007t8szfdaq8w7o',
            'ite_cl44iaubz000277zu4tcq20kf',
            'ite_cl44iaubz000377zu6jy17vst',
          ],
          storedAt: null,
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          createdAt: '2022-07-05T13:33:11.534Z',
          updatedAt: '2022-07-05T13:33:11.534Z',
          deletedAt: null,
        },
        spotlight: null,
        restriction: null,
      },
    ],
  },
]
