<template>
  <div>
    <v-layout
      v-if="encounterData"
      :class="{
        'my-6 mx-2': row,
        'column': column,
      }"
    >
      <v-flex
        v-if="shouldShowTopic(hasSubjective)"
        :class="{
          'card__infos-container--partial-width': row,
          'card__infos-container--full-width': column,
        }"
        column
      >
        <v-flex class="card__group-title">
          Subjetivo
        </v-flex>
        <v-flex
          v-if="!hasSubjective"
          shrink
        >
          Nenhum campo de subjetivo foi preenchido.
        </v-flex>
        <v-flex
          v-if="encounterReason"
          column
        >
          <v-flex class="card__infos-sub-title">
            Motivo da consulta
          </v-flex>
          <v-flex
            v-for="(answer, index) of encounterReason"
            :key="index"
            class="card__infos-name"
          >
            <v-flex column>
              <v-flex>
                • {{ getName(answer) }}
              </v-flex>
              <v-flex
                v-if="answer.observation"
                class="card__infos-observation font-weight-medium"
              >
                - {{ answer.observation }}
              </v-flex>
            </v-flex>
          </v-flex>
        </v-flex>
        <v-flex column>
          <v-flex
            v-if="subjetiveAdditionalInformation"
            class="card__infos-additional-information"
          >
            <see-more
              v-if="row"
              :limit-length="limitAdditionalInformationLength"
              :text="subjetiveAdditionalInformation"
            />
            <template v-else>
              {{ subjetiveAdditionalInformation }}
            </template>
          </v-flex>
        </v-flex>
      </v-flex>

      <v-flex
        v-if="shouldShowTopic(hasObjective)"
        ml-6
        :class="{
          'card__infos-container--partial-width': row,
          'card__infos-container--full-width': column,
        }"
        column
      >
        <v-flex
          shrink
          class="card__group-title"
        >
          Objetivo
        </v-flex>
        <v-flex
          v-if="!hasObjective"
          shrink
        >
          Nenhum campo de objetivo foi preenchido.
        </v-flex>
        <v-flex
          v-if="objectiveAdditionalInformation"
          class="card__infos-additional-information my-0"
        >
          <see-more
            v-if="row"
            :limit-length="limitAdditionalInformationLength"
            :text="objectiveAdditionalInformation"
          />
          <template v-else>
            {{ objectiveAdditionalInformation }}
          </template>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-divider
      v-if="row"
      class="my-6 mx-2"
    />
    <v-layout
      v-if="encounterData"
      :class="{
        'mt-6 mb-2 mx-2': row,
        'column': column,
      }"
    >
      <v-flex
        v-if="shouldShowTopic(hasEvaluation)"
        :class="{
          'card__infos-container--partial-width': row,
          'card__infos-container--full-width': column,
        }"
        column
      >
        <v-flex class="card__group-title">
          Avaliação
        </v-flex>
        <v-flex
          v-if="!hasEvaluation"
          shrink
        >
          Nenhum campo de avaliação foi preenchido.
        </v-flex>
        <v-flex
          v-if="conditions"
          shrink
          column
        >
          <v-flex class="card__infos-sub-title">
            Condições
          </v-flex>
          <v-flex
            v-for="(condition, index) of conditions"
            :key="index"
            class="card__infos-name"
          >
            • {{ getName(condition) }} {{ getFormatedStatus(condition) }}
          </v-flex>
        </v-flex>
        <v-flex
          v-if="evaluationAdditionalInformation"
          class="card__infos-additional-information"
        >
          <see-more
            v-if="row"
            :limit-length="limitAdditionalInformationLength"
            :text="evaluationAdditionalInformation"
          />
          <template v-else>
            {{ evaluationAdditionalInformation }}
          </template>
        </v-flex>
      </v-flex>
      <v-flex
        v-if="shouldShowTopic(hasPlan)"
        ml-6
        :class="{
          'card__infos-container--partial-width': row,
          'card__infos-container--full-width': column,
        }"
        column
      >
        <v-flex class="card__group-title">
          Plano
        </v-flex>
        <v-flex
          v-if="!hasPlan"
          shrink
        >
          Nenhum campo de plano foi preenchido.
        </v-flex>
        <v-flex
          v-if="examRequests"
          column
        >
          <v-flex class="card__infos-sub-title">
            Solicitações de exames
          </v-flex>
          <v-flex
            v-for="(exam, index) of examRequests"
            :key="index"
            class="card__infos-name"
          >
            • {{ getName(exam) }}
          </v-flex>
        </v-flex>
        <v-flex
          v-if="medicalReferral"
          column
        >
          <v-flex class="card__infos-sub-title mt-4">
            Encaminhamentos
          </v-flex>
          <v-flex
            v-for="(referral, index) of medicalReferral"
            :key="index"
            class="card__infos-name"
          >
            • {{ referral.specialityName }}
          </v-flex>
        </v-flex>
        <v-flex
          v-if="interventions"
          column
        >
          <v-flex class="card__infos-sub-title mt-4">
            Intervenções
          </v-flex>
          <v-flex
            v-for="(intervention, index) of interventions"
            :key="index"
            class="card__infos-name"
          >
            • {{ intervention.name }}
          </v-flex>
        </v-flex>
        <v-flex
          v-if="treatments"
          column
        >
          <v-flex class="card__infos-sub-title mt-4">
            Novas prescrições
          </v-flex>
          <v-flex
            v-for="(treatment, index) in treatments"
            :key="index"
            class="card__infos-name"
          >
            • {{ treatment.name }} - {{ treatment.status }}
          </v-flex>
        </v-flex>
        <v-flex
          v-if="planAdditionalInformation"
          class="card__infos-additional-information"
        >
          <see-more
            v-if="row"
            :limit-length="limitAdditionalInformationLength"
            :text="planAdditionalInformation"
          />
          <template v-else>
            {{ planAdditionalInformation }}
          </template>
        </v-flex>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import SeeMore from '@/components/Common/SeeMore'
import moment from 'moment'

import {
  has,
  isNil,
} from 'ramda'
import { mapGetters } from 'vuex'

export default {
  components: {
    SeeMore,
  },
  props: [
    'encounterData',
    'row',
    'column',
    'encounterFormName',
  ],
  data() {
    return {
      additionalInformationO: 'que_ck612ucxe0000vl3a6vch2c7s',
      additionalInformationP: 'que_ck4d2i1rp0000qw3a1z83fvvp',
      limitAdditionalInformationLength: 850,
    }
  },
  computed: {
    ...mapGetters({
      notFoundMessage: 'emr/NotFoundMessage',
      questions: 'emr/questions',
    }),
    hasSubjective() {
      return this.encounterReason || this.subjetiveAdditionalInformation
    },
    hasObjective() {
      return this.objectiveAdditionalInformation
    },
    hasEvaluation() {
      return this.conditions
        || this.objectiveAdditionalInformation
    },
    hasPlan() {
      return this.examRequests
        || this.medicalReferral
        || this.planAdditionalInformation
        || this.interventions
    },
    isLegacy() {
      return has('legacy', this.encounterData)
    },
    encounterReason() {
      return this.getValue(this.encounterData[this.questions.encounterReason])
    },
    subjetiveAdditionalInformation() {
      return this.getValue(this.encounterData[this.questions.subjetiveAdditionalInformation])
    },
    conditions() {
      return this.encounterData[this.questions.conditions]
    },
    evaluationAdditionalInformation() {
      return this.getValue(this.encounterData[this.questions.evaluationAdditionalInformation])
    },
    objectiveAdditionalInformation() {
      return this.getValue(this.encounterData[this.additionalInformationO])
    },
    planAdditionalInformation() {
      return this.getValue(this.encounterData[this.additionalInformationP])
    },
    examRequests() {
      return this.getValue(this.encounterData[this.questions.examRequests])
    },
    medicalReferral() {
      return this.getValue(this.encounterData[this.questions.medicalReferral])
    },
    treatments() {
      return this.encounterData[this.questions.treatments]
    },
    interventions() {
      return this.getValue(this.encounterData[this.questions.interventions])
    },
  },
  methods: {
    getFormatedStatus({ status, deletedAt }) {
      return isNil(deletedAt) ? `- ${status}` : `- ${status} em: ${moment(deletedAt).format('D/MM/YYYY HH:mm:ss')}`
    },
    getValue(data) {
      if (isNil(data)) return data
      if (has('value', data)) {
        return data.value
      }
      return data
    },
    shouldShowTopic(data) {
      if (this.isLegacy) {
        return data
      }
      return true
    },
    getName({ name, code, version }) {
      if (this.isLegacy) {
        return `${name}`
      }
      return `${version || this.notFoundMessage.version} - ${code || this.notFoundMessage.code} - ${name}`
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../../style/_core/colors.styl'

info-container-position(directionIsRow = true)
  if directionIsRow
    width 20%
  else
    margin-top 5%
    width 100%

.card__infos-container--full-width
  info-container-position(false)

.card__infos-container--partial-width
  info-container-position()

.card__infos-name
  color rgba(#000, 0.6)
  margin-top 5px
  margin-bottom 5px

.card__infos-additional-information
  font-style italic
  color rgba(#000, 0.5)
  margin-top 1rem

.card__group-title
  flex 0
  margin-bottom 1rem
  color $evening-sea
  font-size 1.2em

.card__infos-sub-title
  color black
  margin-bottom .4rem

.card__infos-observation
  margin-left .6rem
</style>
