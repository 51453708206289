<template>
  <v-card v-if="isLoading">
    <v-container
      justify-center
      py-12
      class="fill-height"
    >
      <circular-loader />
    </v-container>
  </v-card>
  <v-card v-else>
    <v-card-title>
      <div class="ml-3 mt-4">
        <div class="primary--text text-h5 font-weight-lightbold">
          {{ order.patient.name }}
        </div>
        <div>{{ order.patient | getIdentifierDocument }}</div>
      </div>
      <v-spacer />
      <v-btn
        icon
        @click="handleCancel"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container grid-list-md>
        <v-layout
          row
          wrap
        >
          <v-container
            fluid
            class="pa-0"
          >
            <div
              v-if="order.hasReturnalRecommendation"
              class="text-h6 black--text font-weight-lightbold mb-4"
            >
              <v-icon color="primary">
                mdi-alert
              </v-icon>
              Este atendimento foi identificado como
              <span class="primary--text">Retorno</span> pelo sistema
            </div>
            <div>
              <v-checkbox
                v-model="order.isReturnal"
                label="Marcar como retorno"
                class="mt-0 pt-0"
                dense
              />
            </div>
            <v-card
              elevation="2"
              class="py-6 px-0"
            >
              <div
                class="d-flex justify-space-around"
              >
                <div>
                  <div
                    class="mb-1 dark-green--text font-weight-medium text-subtitle-2"
                  >
                    OS
                  </div>
                  <div class="text-body-2">
                    {{ order.number }}
                  </div>
                </div>
                <div>
                  <div
                    class="mb-1 dark-green--text font-weight-medium text-subtitle-2"
                  >
                    Agendamento
                  </div>
                  <div class="text-body-2">
                    {{ appointmentStatus }}
                  </div>
                </div>
                <div>
                  <div
                    class="mb-1 dark-green--text font-weight-medium text-subtitle-2"
                  >
                    Data
                  </div>
                  <div class="text-body-2">
                    {{ formatDate(order.date) }}
                  </div>
                </div>
                <div>
                  <div
                    class="mb-1 dark-green--text font-weight-medium text-subtitle-2"
                  >
                    Forma de Pagamento
                  </div>
                  <div class="text-body-2">
                    {{ paymentMethodsValue(order.paymentMethods) }}
                  </div>
                </div>
                <div>
                  <div
                    class="mb-1 dark-green--text font-weight-medium text-subtitle-2"
                  >
                    Valor Total
                  </div>
                  <div class="text-body-2">
                    {{ order.totalPrice | formatMoney }}
                  </div>
                </div>
                <div>
                  <div
                    class="mb-1 dark-green--text font-weight-medium text-subtitle-2"
                  >
                    Unidade
                  </div>
                  <div class="text-body-2">
                    {{ order.clinic ? order.clinic.name : 'N/A' }}
                  </div>
                </div>
                <div>
                  <div
                    class="mb-1 dark-green--text font-weight-medium text-subtitle-2"
                  >
                    Plano de saúde
                  </div>
                  <div class="order__hmo text-body-2">
                    {{ order.healthMaintenanceOrganization.name }}
                  </div>
                </div>
              </div>
              <v-layout
                v-if="hasItems"
                row
                wrap
                class="mx-6 mt-4 pb-0"
              >
                <v-container
                  v-for="(orderItem, index) in order.orderItems"
                  :key="orderItem.id"
                  grid-list-md
                  mb-2
                  class="lighter-green"
                >
                  <order-item
                    :order-item="{...orderItem, ...orderItem.contractItem}"
                    :index="index"
                  />
                </v-container>
              </v-layout>
              <h3
                v-else
                class="pb-6 text-center font-weight-medium"
              >
                Não foram solicitados itens nessa ordem
              </h3>
            </v-card>
            <v-layout mt-4>
              <v-flex xs6>
                <v-select
                  v-model="order.status"
                  :items="itemStatusValues"
                  item-text="name"
                  item-value="value"
                  filled
                  hide-details
                  label="Status da OS"
                  :error="$v.order.status.$error"
                  @blur="$v.order.status.$touch()"
                />
              </v-flex>
              <v-flex
                v-if="!patientHasPrivateHmo"
                xs6
              >
                <v-text-field
                  v-model="order.hmoGuideNumber"
                  label="Número da guia"
                  filled
                  hide-details
                  :error="$v.order.hmoGuideNumber.$error"
                  @blur="$v.order.hmoGuideNumber.$touch()"
                />
              </v-flex>
            </v-layout>
            <div>
              <v-checkbox
                v-model="order.isInvoiceEmitted"
                ml-2
                label="Nota Fiscal Emitida"
              />
            </div>
            <div>
              <v-btn
                text
                small
                color="japaneseLaurel"
                class="attach__button"
                @click="isDropzoneActive = !isDropzoneActive"
              >
                <v-icon
                  class="attach__button-icon"
                  left
                >
                  mdi-paperclip
                </v-icon>
                <span>Anexar arquivos</span>
              </v-btn>
            </div>
          </v-container>
          <v-container
            v-if="isDropzoneActive"
            class="px-0 pt-0"
            fluid
          >
            <vue-dropzone
              id="myDropzone"
              ref="myDropzone"
              class="card__dropzone"
              :loading="isSaving"
              :options="dropzoneOptions"
              @vdropzone-files-added="updateFiles"
              @vdropzone-removed-file="updateFiles"
              @vdropzone-drop="updateFiles"
            />
          </v-container>
          <list-attachments
            class="attachments__container"
            :attachments="attachmentList"
            :order-id="order.id"
            :route="'billing/order/invoice'"
            :limited-list="true"
            :update-data="updateData"
          />
          <v-flex class="align-end">
            <v-btn
              class="ml-auto btn-danger mr-3 text-capitalize font-weight-bold"
              @click="handleCancel"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="v-btn theme--light dark-green white-text text-capitalize font-weight-bold"
              @click="handleSave"
            >
              Salvar
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  addIndex,
  always,
  cond,
  equals,
  includes,
  isEmpty,
  isNil,
  not,
  path,
  pick,
  propEq,
  reduce,
  reject,
  T,
  values,
} from 'ramda'
import {
  required,
  requiredIf,
} from 'vuelidate/lib/validators'
import PAYMENT_METHODS from '@/enums/orderPaymentMethods'
import { APPOINTMENT, HMO, ORDER } from 'amparo-enums'
import ListAttachments from '@/components/Common/ListAttachments'
import vue2Dropzone from 'vue2-dropzone'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'EditOrderStatusDialog',
  components: {
    CircularLoader: () => import('@/components/UI/CircularLoader'),
    VueDropzone: vue2Dropzone,
    ListAttachments,
    OrderItem: () => import('@/components/Orders/OrderItem'),
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    order: null,
    isLoading: true,
    isSaving: false,
    hasItems: false,
    isDropzoneActive: false,
    files: [],
    itemStatusValues: values(ORDER.status),
  }),
  computed: {
    ...mapGetters({
      token: 'authentication/token',
    }),
    appointmentStatus() {
      const { appointment } = this.order
      if (isNil(appointment)) return 'N/A'

      return APPOINTMENT.status[appointment.status].label
    },
    attachmentList() {
      return this.order.attachments.filter(item => !item.deletedAt) || []
    },
    dropzoneOptions() {
      return {
        url: new URL('billing/order/invoice', process.env.VUE_APP_ROOT_API).href,
        acceptedFiles: 'application/pdf',
        addRemoveLinks: true,
        autoProcessQueue: false,
        uploadMultiple: true,
        dictDefaultMessage: 'Clique aqui ou arraste os PDFs',
        dictInvalidFileType: 'Este arquivo não é suportado',
        dictRemoveFile: 'X',
        headers: { Authorization: `Bearer ${this.token}` },
      }
    },
    patientHasPrivateHmo() {
      return includes(
        path(['healthMaintenanceOrganizationId'], this.order),
        [
          HMO.private,
          HMO.amparoPartner,
        ],
      )
    },
  },
  validations() {
    const orderValidations = {
      order: {
        status: { required },
        hmoGuideNumber: { requiredIf: requiredIf(() => !this.patientHasPrivateHmo) },
      },
    }
    return { ...orderValidations }
  },
  async activated() {
    await this.updateData()
    await this.getImportFileQueue()
    await this.listHmo()
    this.updateFilesInterval = setInterval(() => {
      this.getImportFileQueue()
    }, 1000)
  },
  async mounted() {
    this.isLoading = true
    await this.updateData()
    this.isLoading = false
    this.hasItems = not(isEmpty(this.order.orderItems))
  },
  methods: {
    ...mapActions({
      findOrder: 'billing/findOrder',
      listOrder: 'billing/listOrder',
      editOrder: 'billing/editOrder',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    paymentMethodsValue(paymentMethods) {
      if (isNil(paymentMethods)) {
        return 'Não Informado'
      }
      return addIndex(reduce)((acc, { paymentMethod }, index) => {
        if (index === 0) {
          return PAYMENT_METHODS[paymentMethod].name
        }
        return `${acc}, ${PAYMENT_METHODS[paymentMethod].name}`
      }, '', paymentMethods)
    },
    formatDate(date) {
      return moment(date).utc().format('DD/MM/YY')
    },
    async updateData() {
      const orderFound = await this.findOrder({
        id: this.orderId,
      })

      this.order = {
        ...orderFound,
        // eslint-disable-next-line no-nested-ternary
        isReturnal: orderFound.hasReturnalCancelled
          ? false
          : orderFound.isReturnal ? true : undefined,
      }
    },
    updateFiles() {
      this.$nextTick(() => {
        this.files = this.rejectErrorFiles(this.$refs.myDropzone.dropzone.files)
      })
    },
    async handleSaveAttachments() {
      if (!isEmpty(this.files)) {
        const attachment = new FormData()
        for (const file of this.files) {
          attachment.append('attachment', file)
        }
        this.files = []
        return this.$http.post(
          'billing/order/invoice',
          attachment,
          {
            params: {
              orderId: this.order.id,
            },
          },
        )
      }
      this.files = []
      return { data: { attachments: this.files } }
    },
    rejectErrorFiles(files) {
      return reject(
        propEq(false, 'accepted'),
        files,
      )
    },
    handleSaveOrderError(error) {
      const errorMessage = cond([
        [
          equals('order_with_attached_returnal_order_cannot_be_edited'),
          always(`
            A Ordem de serviço já tem uma ordem de retorno atrelada,
            portanto, não pode ser editada!
          `),
        ],
        [T, always('Erro ao salvar ordem de serviço!')],
      ])(error.response.data.errorCode)

      this.setSnackbar({
        status: 'error',
        message: errorMessage,
      })
    },
    async handleSave() {
      this.$v.$touch()
      if (this.$v.$error) {
        this.setSnackbar({
          status: 'error',
          message: 'Verifique os campos em vermelho',
        })
        return
      }
      this.isSaving = true
      try {
        const { data } = await this.handleSaveAttachments()
        await this.editOrder({
          id: this.orderId,
          attributes: {
            hasReturnalCancelled: equals(false, this.order.isReturnal),
            ...pick(
              [
                'isInvoiceEmitted',
                'status',
                'isReturnal',
                this.patientHasPrivateHmo ? '' : 'hmoGuideNumber',
              ],
              this.order,
            ),
            attachments: [...data.attachments],
          },
        })
        if (not(isEmpty(this.files))) {
          this.$refs.myDropzone.removeAllFiles(true)
          this.updateFiles()
        }
        await this.listOrder()
        this.isSaving = false
        this.$emit('close')
      } catch (error) {
        this.isSaving = false
        this.handleSaveOrderError(error)
      }
    },
    handleCancel() {
      if (not(isEmpty(this.files))) {
        this.$refs.myDropzone.removeAllFiles(true)
        this.updateFiles()
      }
      this.$emit('close')
    },
    async getImportFileQueue() {
      const queueList = await this.$http.get('importer/file_queue', { params: { importType: 'invoice' } })
      this.queueList = queueList.data.queues
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/elements.styl'
@import '../../style/_core/fonts.styl'

tittleFontSizes()
  @media only screen and (min-width: 601px)
    font-size 15.9px
  @media only screen and (min-width: 1201px)
    font-size 18px
  @media only screen and (min-width: 1601px)
    font-size 20px

.attachments__container >>> .attach__name
  max-width 700px !important
  margin-right 2rem !important


.dropzone
  border 2px dashed #6DD36E
  border-radius 20px
  background-color #F5FFF4
  transition .3 ease all
  color black !important
  >>> .dz-details
    background-color white !important
    border-radius 20px
    background-image url(../../assets/pdf.png) !important
    background-repeat: no-repeat;
    background-size: 100px;
    background-position: center top 10px;
  >>> .dz-size
    margin-top 4.7rem
    color black
    font-size 1rem !important
  >>> .dz-filename
    margin-top -1rem
    color black
    font-size 1rem !important
  >>> .dz-error-message
    margin-top 2rem
  >>> .dz-message
    margin-top 3rem
  >>> .dz-progress
    display none
  >>> .dz-image
    background-color red
    max-width 150px
    max-height 150px
  >>> .dz-preview
    max-width 150px
    max-height 150px
  >>> .dz-remove
    max-width 20px
    max-height 20px
    font-weight 600
    font-size 1.5rem
    text-decoration none !important
    display absolute
    top -10px
    right 15px
    color black

.order__hmo
  text-transform lowercase

.order__hmo:first-letter
  text-transform uppercase !important

</style>
