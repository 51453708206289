import { rest } from 'msw'
import baseUrl from '../../../../src/utils/baseUrlTest'
import roomListData from '../../fixtures/public/room/list'

export default [
  rest.get(baseUrl('/room'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(roomListData),
  )),
]
