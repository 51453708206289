import { rest } from 'msw'
import validContractItem from '../fixtures/validContractItemData'
import invalidContractItem from '../fixtures/invalidContractItemData'
import contractItemSearchData from '../fixtures/billing/contractItem/search'
import baseUrl from '../../../src/utils/baseUrlTest'

export default [
  rest.get(baseUrl('/billing/contract_item'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      contractItems: [],
      numberOfPages: 0,
      count: 0,
    }),
  )),
  rest.get(baseUrl('/billing/contract_item/search'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(contractItemSearchData),
  )),
  rest.post(baseUrl('/billing/contract_item'), (req, res, ctx) => {
    if (!req.body.data[0].name) {
      return res(
        ctx.status(400),
        ctx.json(invalidContractItem),
      )
    }

    return res(
      ctx.status(200),
      ctx.json(validContractItem),
    )
  }),
  rest.post(baseUrl('https://httpbin.org/post'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(validContractItem),
  )),
]
