<template>
  <div>
    <div class="filters-container">
      <v-container
        :class="{
          'container--fluid': true,
          'px-12': $vuetify.breakpoint.smAndUp,
          'grid-list-lg': true
        }"
      >
        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
            sm4
            md3
          >
            <v-text-field
              v-model="filters.title"
              filled
              label="Buscar por título"
              clearable
              no-data-text="Digite para buscar"
              @change="listFormResults"
              @click:clear="listFormResults"
            />
          </v-flex>
          <v-flex
            xs12
            sm3
            md3
          >
            <v-text-field
              v-model="filters.identifier"
              filled
              label="Buscar por código"
              clearable
              no-data-text="Digite para buscar"
              @change="listFormResults"
              @click:clear="listFormResults"
            />
          </v-flex>
          <v-flex
            xs12
            sm3
            md3
          >
            <v-autocomplete
              v-model="filters.categoryId"
              :items="categories"
              label="Categoria"
              item-text="name"
              item-value="id"
              :no-data-text="notFoundMessage"
              clearable
              filled
              @change="listFormResults"
              @click:clear="listFormResults"
            />
          </v-flex>
          <v-flex
            xs12
            sm3
            md3
          >
            <v-btn
              class="btn-filter--height font-weight-lightbold"
              color="dark-green"
              dark
              block
              large
              @click="listFormResults"
            >
              <v-icon left>
                mdi-magnify
              </v-icon>
              Buscar Formulário
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <v-container
      :class="{
        'container--fluid': true,
        'px-12': $vuetify.breakpoint.smAndUp,
      }"
    >
      <v-layout
        row
        wrap
      >
        <v-spacer />
        <v-flex
          xs12
          sm4
        >
          <v-btn
            class="font-weight-bold my-3"
            color="primary"
            block
            large
            @click="showCreateFormDialog = true"
          >
            Novo Formulário
          </v-btn>
        </v-flex>
        <v-spacer />
      </v-layout>
      <div class="data-spacer" />
      <v-data-table
        :headers="headers"
        :items="forms"
        hide-default-footer
        disable-pagination
        class="data-table__header"
        no-data-text="Sem itens para exibir, clique no botão acima para começar a criar"
      >
        <template v-slot:item="form">
          <tr>
            <td>{{ form.item.title }}</td>
            <td>{{ form.item.identifier }}</td>
            <td>{{ form.item.category.name }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ml-3"
                    text
                    icon
                    @click="viewFormDialog(form.item)"
                    v-on="on"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar Formulário</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ml-3"
                    text
                    icon
                    @click="listQuestionsDialog(form.item)"
                    v-on="on"
                  >
                    <v-icon>mdi-format-list-checkbox</v-icon>
                  </v-btn>
                </template>
                <span>Editar Perguntas</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ml-3"
                    text
                    icon
                    @click="editDialog(form.item)"
                    v-on="on"
                  >
                    <v-icon>mdi-account-edit</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ml-3"
                    text
                    icon
                    v-on="on"
                    @click="deleteDialog(form.item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Remover</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-container>

    <v-dialog
      v-model="showEditFormDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1400"
    >
      <v-card>
        <v-card-title
          class="card-dialog__title"
        >
          Editar Formulário
          <v-spacer />
          <v-btn
            icon
            @click="exitEditDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <create-form
          v-if="showEditFormDialog"
          edit-form
          :form-data="editedForm"
          @exitEditDialog="exitEditDialog"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showCreateFormDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
    >
      <v-card>
        <v-card-title class="card-dialog__title">
          Cadastrar Formulário
          <v-spacer />
          <v-btn
            icon
            @click="showCreateFormDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <create-form
          v-if="showCreateFormDialog"
          @closeCreateFormDialog="showCreateFormDialog = false"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showFormListQuestionDialog"
      transition="dialog-bottom-transition"
      fullscreen
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Editar Questões do Formulário</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="showFormListQuestionDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <form-question-list
          v-if="showFormListQuestionDialog"
          :form-id="editedForm.id"
          @closeCreateFormDialog="showFormListQuestionDialog = false"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showViewFormDialog"
      transition="dialog-bottom-transition"
      max-width="1200"
    >
      <v-card>
        <v-card-title class="card-dialog__title">
          Visualizar Formulário
          <v-spacer />
          <v-btn
            icon
            @click="exitViewFormDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <form-builder
            v-if="showViewFormDialog"
            :form="viewForm"
            preview
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showDeleteFormDialog"
      class="not-printable-area"
      max-width="450"
    >
      <v-card>
        <v-card-text>
          Deseja realmente excluir esse formulário?
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="btn-dialog font-weight-lightbold"
            color="green darken-1"
            text
            large
            @click="exitDeleteDialog"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            class="btn-dialog font-weight-lightbold"
            large
            color="error"
            @click="handleDeleteForm"
          >
            Remover
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  isEmpty,
  isNil,
  reject,
  either,
  cond,
  T,
  always,
} from 'ramda'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FormList',
  components: {
    CreateForm: () => import('./CreateForm'),
    FormQuestionList: () => import('./FormQuestionList'),
    FormBuilder: () => import('@/components/careFields/FormBuilder'),
  },
  data() {
    return {
      notFoundMessage: 'Não encontrado',
      showEditFormDialog: false,
      editedForm: {},
      filters: {},
      listLoaded: true,
      count: '',
      numberOfPages: null,
      page: 1,
      headers: [
        {
          text: 'Nome',
          value: 'title',
          width: '40%',
        },
        {
          text: 'Código',
          value: 'identifier',
          width: '20%',
        },
        {
          text: 'Categoria',
          value: 'category',
          width: '20%',
        },
        {
          text: 'Ações',
          value: 'actions',
          width: '20%',
          sortable: false,
        },
      ],
      showCreateFormDialog: false,
      showFormListQuestionDialog: false,
      showViewFormDialog: false,
      showDeleteFormDialog: false,
      deletedForm: {},
      viewForm: {},
    }
  },
  computed: {
    ...mapGetters({
      categories: 'careForm/categories',
      forms: 'careForm/forms',
    }),
    isFormListEmpty() {
      return isEmpty(this.forms)
    },
  },
  mounted() {
    this.listFormResults()
  },
  methods: {
    ...mapActions({
      listCategory: 'careForm/listCategory',
      listForm: 'careForm/listForm',
      deleteForm: 'careForm/deleteForm',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    async handleDeleteForm() {
      try {
        await this.deleteForm(this.deletedForm.id)
        this.buildSnackbar('success', 'Formulário deletado com sucesso!')
        this.isSaving = false
      } catch ({ response }) {
        this.handleError(response)
      }
      this.deletedForm = {}
      this.showDeleteFormDialog = false
      await this.listForm()
    },
    handleError(error) {
      const errorMessage = cond([
        [T, always('Erro interno')],
      ])(error.data.errorCode)

      this.buildSnackbar('error', errorMessage)
    },
    listFormResults() {
      this.$nextTick(async () => {
        const params = reject(either(isNil, isEmpty), this.filters)
        try {
          await this.listForm(params)
          this.listLoaded = false
        } catch (e) {
          this.buildSnackbar('error', 'Erro Interno!')
        }
      })
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    editDialog(formData) {
      this.editedForm = formData
      this.showEditFormDialog = true
    },
    listQuestionsDialog(formData) {
      this.editedForm = formData
      this.showFormListQuestionDialog = true
    },
    exitEditDialog() {
      this.editedForm = {}
      this.showEditFormDialog = false
    },
    exitListQuestionsDialog() {
      this.editedForm = {}
      this.showEditFormDialog = false
    },
    viewFormDialog(form) {
      this.viewForm = form
      this.showViewFormDialog = true
    },
    exitViewFormDialog() {
      this.viewForm = {}
      this.showViewFormDialog = false
    },
    deleteDialog(form) {
      this.deletedForm = form
      this.showDeleteFormDialog = true
    },
    exitDeleteDialog() {
      this.deletedForm = {}
      this.showDeleteFormDialog = false
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'
.filters-container
  width 100%
  height auto
  background-color #e8f8e8

  @media only screen and (min-width 601px)
    height 108px

.results-container
  margin-top -25px
  width 100%
  background-color #e8f8e8

.v-card
  padding 10px
  border-radius 6px

.v-btn
  letter-spacing 1px

.v-btn.v-btn--large
  margin-top 0px
  height 56px
  border-radius 8px

.v-btn.v-btn--text
  border solid 1px thin-gray
.v-btn.btn-dialog
  padding 15px
  @media all and (min-width 601px)
    width 190px
.data-spacer
  height 20px
  width 100%

</style>
