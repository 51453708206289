<template>
  <div>
    <component-title />
    <communication-list />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import CommunicationList from './CommunicationList'

export default {
  name: 'CommunicationSettingsPage',
  components: { ComponentTitle, CommunicationList },
}
</script>
