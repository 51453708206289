export default {
  id: 'ord_clpk2hvl200gtb8o423k9e0ns',
  number: 1,
  patientId: 'pat_ck3hev3i10000xwpq686e15vr',
  appointmentId: 'app_clpk2hvj300gqb8o4f51u8sui',
  status: 'opened',
  date: '2023-11-30T00:00:00.000Z',
  isInvoiceEmitted: false,
  encounterCids: [],
  totalPrice: 10000,
  isEligibleToReturnal: false,
  parentReturnalOrderId: null,
  isReturnal: false,
  hasReturnalCancelled: null,
  hasReturnalRecommendation: false,
  workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
}
