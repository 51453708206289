import axios from '@/lib/axios'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'
import {
  filter,
  isNil,
  path,
  propSatisfies,
} from 'ramda'

const initialState = {
  loadingBlock: false,
  blocks: [],
  blockFiltersData: {
    selectedClinic: null,
    selectedSpeciality: null,
    selectedProfessional: null,
  },
  blockFilters: {
    clinicId: null,
    specialityId: null,
    professionalId: null,
  },
  blockPage: 1,
  blockNumberOfPages: null,
  blockCount: null,
}

const mutations = {
  SET_BLOCKS(state, payload) {
    state.blocks = payload
  },
  SET_LOADING_BLOCK(state, payload) {
    state.loadingBlock = payload
  },
  SET_BLOCK_FILTERS_DATA(state, payload) {
    state.blockFiltersData = payload
  },
  SET_BLOCK_FILTERS(state, payload) {
    state.blockFilters = payload
  },
  SET_BLOCK_PAGE(state, page) {
    state.blockPage = page
  },
  SET_BLOCK_NUMBER_OF_PAGES(state, numberOfPages) {
    state.blockNumberOfPages = numberOfPages
  },
  SET_BLOCK_COUNT(state, count) {
    state.blockCount = count
  },
}

const actions = {
  createBlock({ commit }, attributes) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_BLOCK', true)
      axios.post('block', attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_BLOCK', false))
    })
  },
  updateBlock({ commit }, { id, attributes }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_BLOCK', true)
      axios.patch(`block/${id}`, attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_BLOCK', false))
    })
  },
  listBlock({ commit, state }) {
    const params = removeNilOrEmpty({
      ...state.blockFilters,
      page: state.blockPage,
    })
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_BLOCK', true)
      axios.get('block', { params })
        .then(({ data }) => {
          commit('SET_BLOCKS', data.blocks)
          commit('SET_BLOCK_NUMBER_OF_PAGES', data.numberOfPages)
          commit('SET_BLOCK_COUNT', data.count)
          resolve(data.block)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING_BLOCK', false))
    })
  },
  setBlockFiltersData({ commit }, data) {
    commit('SET_BLOCK_FILTERS_DATA', data)
    commit('SET_BLOCK_FILTERS', {
      clinicId: path(['id'], data.selectedClinic),
      specialityId: path(['id'], data.selectedSpeciality),
      professionalId: path(['id'], data.selectedProfessional),
    })
    commit('SET_BLOCK_PAGE', 1)
  },
  setBlockPage({ commit }, page) {
    commit('SET_BLOCK_PAGE', page)
  },
}

const getters = {
  blocks: state => state.blocks,
  availableBlocks: state => filter(propSatisfies(isNil, 'storedAt'), state.blocks),
  loadingBlock: state => state.loadingBlock,
  blockFilters: state => state.blockFilters,
  blockPage: state => state.blockPage,
  blockNumberOfPages: state => state.blockNumberOfPages,
  blockCount: state => state.blockCount,
}

export default {
  state: initialState,
  mutations,
  actions,
  getters,
}
