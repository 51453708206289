<template>
  <fragment>
    <v-btn
      large
      :block="block"
      class="btn btn-primary btn-filter--height"
      :loading="isLoadingCloseEncounterButton"
      :disabled="isLoadingSpecificEncounter"
      @click="handleCloseEncounter"
    >
      {{ label }}
    </v-btn>
    <v-dialog
      v-model="appointmentDialog"
      max-width="600"
      @click:outside="closeAppointmentDialog"
    >
      <v-card class="popup__container">
        <v-card-text>
          <v-flex class="text--bold popup__title mb-4">
            <v-flex column>
              <v-flex class="mb-2">
                Ops, não encontramos um atendimento válido vinculado ao prontuário.
              </v-flex>
            </v-flex>
          </v-flex>
          <v-container>
            <v-flex class="font-weight-bold">
              Selecione o atendimento que deve ser vinculado ao prontuário:
            </v-flex>
            <v-col
              justify-center
              class="mb-5 ml-2"
            >
              <v-row
                v-for="appointment in appointments"
                :key="appointment.id"
                class="my-2"
              >
                <appointment-card
                  :appointment-data="{ appointment, slot: {} }"
                  :should-show-extended="false"
                  :hide-details="true"
                  :show-time="true"
                  :show-clinic="true"
                  :is-selected="appointment.id === selectedAppointment?.id"
                  @selectedAppointment="setSelectedAppointment"
                />
              </v-row>
            </v-col>
          </v-container>
          <v-btn
            large
            block
            class="btn btn-primary mt-4"
            @click="handleAppointmentChosen()"
          >
            Salvar
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </fragment>
</template>

<script>
import moment from 'moment'
import { Fragment } from 'vue-fragment'
import specialityEnum from '@/enums/speciality'
import {
  APPOINTMENT,
  EMR_QUESTIONS,
} from 'amparo-enums'
import isNilOrEmpty from '@/utils/dataValidators'

import {
  all,
  always,
  equals,
  find,
  findIndex,
  forEach,
  ifElse,
  isEmpty,
  isNil,
  not,
  path,
  propEq,
  values,
  prop,
  head,
  includes,
  pluck,
  pick,
} from 'ramda'
import {
  mapActions,
  mapGetters,
} from 'vuex'

const {
  FAMILY_DOCTOR,
  NURSE,
} = specialityEnum
const { questions } = EMR_QUESTIONS

export default {
  components: {
    Fragment,
    AppointmentCard: () => import('@/components/Common/AppointmentCard'),
  },
  props: {
    block: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      autoSaveTimer: null,
      isLoadingCloseEncounterButton: false,
      specialityList: [],
      appointmentDialog: false,
      selectedAppointment: null,
      resolveAppointmentDialog: null,
      rejectAppointmentDialog: null,
    }
  },
  computed: {
    ...mapGetters({
      appointments: 'agenda/appointments',
      isLoadingSpecificEncounter: 'emr/isLoadingSpecificEncounter',
      encounter: 'emr/encounter',
      isWorkSpaceAmparo: 'authentication/isWorkSpaceAmparo',
      patient: 'emr/patient',
      questions: 'emr/questions',
      user: 'authentication/user',
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
      hasValidationErrorOnEncounter: 'emr/hasValidationErrorOnEncounter',
      encounterFormTouched: 'emr/encounterFormTouched',
      activationJourney: 'patient/activationJourney',
      shouldSendReferralSms: 'preferredNetwork/shouldSendReferralSms',
      preferredNetworkReferrals: 'preferredNetwork/preferredNetworkReferrals',
      preferredNetworkReferralProfessionals: 'preferredNetwork/preferredNetworkReferralProfessionals',
      userAccess: 'authentication/userHasAccessToFunctionality',
    }),
    label() {
      return ifElse(
        equals('ANNOTATION'),
        always('Finalizar anotação'),
        always('Finalizar atendimento'),
      )(this.encounter.encounterFormName)
    },
    expiredActiveProblemsList() {
      return this.encounter.expiredActiveProblems
    },
    encounterData() {
      return this.encounter.data
    },
    encounterId() {
      return this.encounter.id
    },
    isEncounterTypeAnnotation() {
      return equals('ANNOTATION', this.encounter.encounterFormName)
    },
    historyConditionsId() {
      return this.questions.historyConditions
    },
    patientId() {
      return this.patient.id
    },
    today() {
      return moment().format('YYYY-MM-DD')
    },
    lastWeek() {
      return moment().add(-1, 'week').format('YYYY-MM-DD')
    },
    hasValidAppointment() {
      if (!isNil(this.encounter.appointmentId)) {
        return true
      }
      if (!isNil(this.encounter.immediateCareId)) {
        return true
      }
      if (this.isEncounterTypeAnnotation) {
        return true
      }
      return false
    },
    medicalReferrals() {
      return this.encounter.data?.[questions.medicalReferral]?.value
    },
  },
  beforeDestroy() {
    this.$bus.$off('alert:end-encounter-with-failed-prescription')
  },
  inject: ['providerTurnOnAllQuestionsWarnings'],
  methods: {
    ...mapActions({
      clearEncounter: 'emr/clearEncounter',
      clearMedication: 'emr/clearMedication',
      clearHeightAndWeight: 'emr/clearHeightAndWeight',
      updateEncounter: 'emr/updateEncounter',
      setShowNewCareLinePatientModalAfterEncounter: 'emr/setShowNewCareLinePatientModalAfterEncounter',
      setEmrPatient: 'emr/setEmrPatient',
      setValidationErrorOnEncounter: 'emr/setValidationErrorOnEncounter',
      setEncounterFormTouched: 'emr/setEncounterFormTouched',
      setSnackbar: 'snackbar/setSnackbar',
      listCareLinePatient: 'careLine/listCareLinePatient',
      listProfessional: 'professional/listProfessional',
      listAppointment: 'agenda/listAppointment',
      getPrescriptionByReference: 'emr/getPrescriptionByReference',
      sendPreferredNetworkReferralSms: 'preferredNetwork/sendPreferredNetworkReferralSms',
      getPreferredNetworkReferralsAndProfessionals: 'preferredNetwork/getPreferredNetworkReferralsAndProfessionals',
      resetShouldSendReferralSms: 'preferredNetwork/resetShouldSendReferralSms',
      resetPreferredNetworkReferrals: 'preferredNetwork/resetPreferredNetworkReferrals',
      resetPreferredNetworkReferralProfessionals: 'preferredNetwork/resetPreferredNetworkReferralProfessionals',
      verifyQuestionRequired: 'emr/verifyQuestionRequired',
    }),
    setSelectedAppointment({ appointmentData }) {
      this.selectedAppointment = appointmentData
    },
    async getProfessionalSpeciality() {
      const params = {
        id: this.user.professionalId,
      }
      const professionals = await this.listProfessional(params)
      this.specialityList = prop('specialities', head(professionals))
    },
    openNewCareLinePatientModal() {
      if (this.isWorkSpaceAmparo) {
        this.setShowNewCareLinePatientModalAfterEncounter()
      }
    },
    verifyRequiredQuestionsOnStruct() {
      const parameters = {
        patientData: this.patient,
        appointments: this.appointments,
        user: this.user,
      }

      if (not(this.isEncounterTypeAnnotation)) this.providerTurnOnAllQuestionsWarnings()
      for (const structs of values(this.encounter.encounterFormStruct)) {
        for (const groups of structs.groups) {
          for (const question of groups.questions) {
            this.verifyQuestionRequired({
              question,
              parameters,
            })
          }
        }
      }
      return null
    },
    hasFamilyDoctorOrNurseSpeciality() {
      if (isNilOrEmpty(this.specialityList)) return false
      const specialitiesIds = pluck('id', this.specialityList)

      return includes(FAMILY_DOCTOR.id, specialitiesIds)
        || includes(NURSE.id, specialitiesIds)
    },
    closeAppointmentDialog() {
      this.appointmentDialog = false
      this.rejectAppointmentDialog(true)
    },
    handleAppointmentChosen() {
      this.appointmentDialog = false
      this.resolveAppointmentDialog(true)
    },
    async chooseAppointment() {
      await this.listAppointment({
        professional: { id: this.encounter.professionalId },
        patient: { id: this.encounter.patientId },
        startDate: this.lastWeek,
        endDate: this.today,
        status: [
          APPOINTMENT.status.scheduled.value,
          APPOINTMENT.status.confirmed.value,
          APPOINTMENT.status.received.value,
        ],
        includeClinic: true,
      })

      if (isNilOrEmpty(this.appointments)) return false

      this.appointmentDialog = true

      return new Promise((resolve, reject) => {
        this.resolveAppointmentDialog = resolve
        this.rejectAppointmentDialog = reject
      })
    },
    isPrescriptionDataEmpty(prescription) {
      return all(
        isEmpty,
        values(
          pick([
            'Atestados',
            'Encaminhamentos',
            'Exames',
            'Itens',
            'ItensManuais',
            'Relatorios',
          ])(prescription),
        ),
      )
    },
    async validatePrescriptions() {
      const prescriptions = this.encounterData?.prescriptionsManagement
      if (isNilOrEmpty(prescriptions)) return Promise.resolve()

      const isProcessingPrescription = find(propEq('processing', 'state'))(prescriptions)

      if (isProcessingPrescription) {
        this.setSnackbar({
          status: 'error',
          message: 'Aguarde o processamento da prescrição para finalizar o atendimento',
        })
        return Promise.reject()
      }

      const inProgressPrescription = find(propEq('in progress', 'state'))(prescriptions)

      if (inProgressPrescription) {
        const {
          id: prescriptionId,
          bearerToken,
          referenceId,
        } = inProgressPrescription

        const inProgressPrescriptionData = await this.getPrescriptionByReference({
          prescriptionId,
          referenceId,
          bearerToken,
        })

        if (!this.isPrescriptionDataEmpty(inProgressPrescriptionData)) {
          this.setSnackbar({
            status: 'error',
            message: `Não é possível finalizar o atendimento com prescrições em andamento.
              Caso já tenha emitido a prescrição, aguarde alguns segundos e tente novamente.`,
          })
          return Promise.reject()
        }
      }

      const failedPrescription = find(propEq('failed', 'state'))(prescriptions)

      if (failedPrescription) {
        this.$bus.$emit('alert:end-encounter-with-failed-prescription')
        this.setSnackbar({
          status: 'error',
          message: 'Um erro ocorreu e estamos reprocessando sua receita. Aguarde alguns segundos e tente novamente',
        })
        return Promise.reject()
      }

      return Promise.resolve()
    },
    async validateStruct() {
      this.setEncounterFormTouched(true)
      const structRequiredError = this.verifyRequiredQuestionsOnStruct()

      if (this.hasValidationErrorOnEncounter) {
        this.setSnackbar({
          status: 'error',
          message: 'Verifique os campos em vermelho',
        })
        return Promise.reject()
      }

      if (structRequiredError) {
        this.setSnackbar({
          status: 'error',
          message: structRequiredError,
        })
        return Promise.reject()
      }

      return Promise.resolve()
    },
    async validateAppointment() {
      if (!this.isWorkSpaceAmparo || this.hasValidAppointment) return Promise.resolve()

      await this.chooseAppointment()

      return Promise.resolve()
    },
    async handleCloseEncounter() {
      this.isLoadingCloseEncounterButton = true
      try {
        await this.validatePrescriptions()
        await this.validateStruct()

        if (this.isEncounterTypeAnnotation) {
          this.closeEncounter()
          return
        }

        await this.validateAppointment()

        this.closeEncounter()

        await this.sendReferralSms()
      } catch (error) {
        return
      } finally {
        this.isLoadingCloseEncounterButton = false
      }
    },
    async sendReferralSms() {
      if (!this.userAccess.preferredNetworkSms) return

      if (isNil(this.preferredNetworkReferrals)) {
        await this.getPreferredNetworkReferralsAndProfessionals()
      }

      if (!this.shouldSendReferralSms) return

      const preferredNetworkReferrals = this.preferredNetworkReferrals || []
      const medicalReferrals = this.medicalReferrals || []

      const specialityIds = pluck('specialityId', preferredNetworkReferrals)
      const recommendedProfessionalsByReferral = pluck('recommendedProfessionals', medicalReferrals)
      const recommendedProfessionalIds = recommendedProfessionalsByReferral.flat()

      const params = {
        patientId: this.patient.id,
        preferredNetworkId: this.patient?.healthProduct?.preferredNetwork?.[0]?.id || null,
        professionalIds: recommendedProfessionalIds,
        specialityIds,
      }

      await this.sendPreferredNetworkReferralSms(params)

      this.resetShouldSendReferralSms()
      this.resetPreferredNetworkReferrals()
      this.resetPreferredNetworkReferralProfessionals()
    },
    async closeEncounter() {
      this.isLoadingCloseEncounterButton = true
      if (not(isNil(this.expiredActiveProblemsList))) this.setExpiredActiveProblemsToData()
      this.setEncounterFormTouched(false)
      const attributes = {
        status: 'closed',
      }
      if (this.selectedAppointment) {
        attributes.appointmentId = this.selectedAppointment.id
      }

      return this.updateEncounter(attributes)
        .then(() => {
          this.setEmrPatient(this.patient.id)
          this.$router.push(`/emr/${this.patient.id}`)
          this.openNewCareLinePatientModal()
          this.clearEncounter()
          this.clearHeightAndWeight()
          this.clearMedication()
        })
        .then(() => delete window.MdHub)
        .catch(err => err)
        .finally(() => { this.isLoadingCloseEncounterButton = false })
    },
    setExpiredActiveProblemsToData() {
      forEach(this.setExpiredActiveProblem, this.expiredActiveProblemsList)
    },
    setExpiredActiveProblem({ id }) {
      const historyConditionIndex = findIndex(
        propEq(id, 'id'),
      )(this.encounterData[this.historyConditionsId])
      const historyCondItem = this.encounterData[this.historyConditionsId][historyConditionIndex]
      if (isNil(path(['activeProblemHighlightData', 'isHighlighted'], historyCondItem))) return
      historyCondItem.activeProblemHighlightData.isHighlighted = false
    },
  },
}
</script>

<style lang="stylus" scoped>
.card__actions
  display flex
  align-content center

.popup__container
  padding-top 15px

.popup__title
  font-size 17px !important

</style>
