import { rest } from 'msw'
import { equals } from 'ramda'
import baseUrl from '../../../src/utils/baseUrlTest'
import sessionData from '../fixtures/sessionData'

export default [
  rest.post(baseUrl('/session'), (req, res, ctx) => {
    const { email, password } = req.body

    if (!equals(email, 'devs+pro@ampa.ro') && !equals(password, 'password')) {
      return res(
        ctx.status(400),
        ctx.json({
          errorCode: 'credential_invalid',
        }),
      )
    }

    return res(
      ctx.status(200),
      ctx.json({
        ...sessionData,
      }),
    )
  }),
  rest.get(baseUrl('/session'), (req, res, ctx) => {
    const modifiedSessionData = { ...sessionData }
    modifiedSessionData.user.workSpaceId = req.body.workSpaceId

    return res(
      ctx.status(200),
      ctx.json(modifiedSessionData),
    )
  }),
  rest.delete(baseUrl('session'), (req, res, ctx) => res(ctx.status(200))),
]
