<template>
  <v-container
    :class="{
      'container--fluid': true,
      'px-12': $vuetify.breakpoint.smAndUp,
    }"
  >
    <v-row
      class="mx-2 mb-5 mt-0 justify-space-between"
    >
      <v-btn
        color="primary"
        :disabled="isSelectedOrdersEmpty"
        @click="toggleMultipleOrdersDialog"
      >
        Editar Ordens
      </v-btn>
      <v-btn
        text
        color="primary"
        class="text-capitalize"
        :loading="isDownloadingXslsx"
        @click="downloadXlsx"
      >
        <v-icon
          right
          plain
          class="mr-1"
          size="20"
        >
          mdi-download
        </v-icon>
        <span class="text-subtitle-1 font-weight-lightbold">Download Planilha</span>
      </v-btn>
    </v-row>
    <v-data-table
      v-model="selectedOrders"
      class="v-table--header"
      :headers="headers"
      :items="formattedOrdersList"
      :options.sync="pagination"
      :server-items-length="ordersList.count"
      :footer-props="{
        'items-per-page-options': [30],
        'items-per-page-text': '',
      }"
      item-key="id"
      show-select
      no-data-text="Sem itens para exibir, clique no botão acima para começar a criar"
    >
      <template #item="order">
        <tr>
          <td>
            <v-checkbox
              class="pt-2"
              :input-value="order.isSelected"
              @change="order.select($event)"
            />
          </td>
          <td class="text-caption font-weight-medium">
            {{ order.item.number }}
          </td>
          <td class="text-caption font-weight-medium">
            {{ order.item.appointmentStatus }}
          </td>
          <td class="text-caption font-weight-medium">
            {{ order.item.date }}
          </td>
          <td class="text-caption font-weight-medium">
            {{ order.item.paymentMethod }}
          </td>
          <td class="text-caption font-weight-medium">
            {{ order.item.orderStatus }}
          </td>
          <td class="text-caption font-weight-medium">
            {{ order.item.isInvoiceEmitted }}
          </td>
          <td class="text-caption font-weight-medium">
            {{ order.item.totalPrice | formatMoney }}
          </td>
          <td class="text-caption font-weight-medium">
            {{ order.item.clinic }}
          </td>
          <td>
            <div class="text-caption font-weight-medium">
              {{ order.item.patientName }}
            </div>
            <div class="text-caption font-weight-medium">
              {{ order.item.patientCPF }}
            </div>
          </td>
          <td class="text-caption font-weight-medium">
            {{ order.item.hmoName }}
          </td>
          <td class="text-caption font-weight-medium">
            {{ order.item.healthProductName }}
          </td>
          <td class="text-caption font-weight-medium">
            {{ order.item.professional }}
          </td>
          <td class="text-caption font-weight-medium">
            {{ order.item.speciality }}
          </td>
          <td class="text-caption font-weight-medium">
            {{ order.item.isReturnal }}
          </td>
          <td class="text-caption font-weight-medium">
            {{ order.item.hasReturnalRecommendation }}
          </td>
          <td class="text-caption font-weight-medium">
            <div v-if="hasAccessToContractEdit">
              <div
                v-for="cid in order.item.encounterCids"
                :key="cid"
              >
                {{ cid }}
                <br>
              </div>
            </div>
          </td>
          <td class="text-caption font-weight-medium">
            <v-btn
              v-if="shouldShowRelatedOsButton(order.item.parentReturnalOrderId)"
              text
              @click="openDetailsDialog(order.item.parentReturnalOrderId)"
            >
              {{ order.item.parentReturnalOrder }}
            </v-btn>
          </td>
          <td class="text-caption font-weight-medium">
            {{ order.item.updatedAt }}
          </td>
          <td class="text-caption font-weight-medium">
            {{ order.item.hmoGuideNumber }}
          </td>
          <td
            class="text-caption font-weight-medium"
          >
            {{ order.item.authorizationCode }}
          </td>
          <td>
            <v-btn
              icon
              @click="openDetailsDialog(order.item.id)"
            >
              <v-btn
                icon
                color="black"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showDetailsDialog"
      max-width="950px"
    >
      <edit-order-status-dialog
        v-if="showDetailsDialog"
        :order-id="orderId"
        @close="showDetailsDialog = false"
      />
    </v-dialog>
    <v-dialog
      v-model="editMultipleOrdersDialog"
      max-width="500"
    >
      <v-card
        class="pa-3"
      >
        <v-card-title class="title-h3">
          Editar OS selecionadas
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedOrderStatus"
            :items="orderStatusValues"
            label="Status"
            item-text="name"
            item-value="value"
            filled
            hide-details
            :menu-props="{ bottom: true, offsetY: true }"
          />
          <v-checkbox
            v-model="selectedIsInvoiceEmitted"
            label="Nota Fiscal Emitida"
            filled
            color="primary"
            hide-details
          />
        </v-card-text>
        <v-card-actions>
          <v-flex
            xs12
            mt-2
          >
            <v-btn
              class="btn-dialog btn-gray ml-auto"
              large
              @click="toggleMultipleOrdersDialog"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="btn-dialog ml-4"
              color="primary"
              large
              :disabled="!selectedOrderStatus"
              @click="bulkUpdate()"
            >
              Editar
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { APPOINTMENT, BILLING, ORDER } from 'amparo-enums'
import { mapGetters, mapActions } from 'vuex'
import PAYMENT_METHODS from '@/enums/orderPaymentMethods'
import moment from 'moment'
import createWebSheet from '@/utils/xlsx/downloader'
import { writeFile } from 'xlsx'

import {
  isNil,
  map,
  prop,
  values,
  not,
  isEmpty,
  filter,
  includes,
  join,
  gte,
  length,
  path,
  omit,
} from 'ramda'
import EditOrderStatusDialog from './EditOrderStatusDialog'

export default {
  name: 'OrderList',
  components: {
    EditOrderStatusDialog,
  },
  data: () => ({
    isInvoiceEmitted: {
      true: 'Sim',
      false: 'Não',
    },
    hasReturnalRecommendationValues: {
      true: 'Sim',
      false: 'Não',
    },
    isDownloadingXslsx: false,
    selectedIsInvoiceEmitted: false,
    selectedOrderStatus: null,
    orderId: null,
    pagination: {},
    orderStatusValues: values(ORDER.status),
    showDetailsDialog: false,
    excelHeaders: [
      'Ordem de Serviço',
      'Status do Agendamento',
      'Data/Hora',
      'Forma de Pagamento',
      'Status da Ordem de Serviço',
      'Nota Fiscal Emitida',
      'Valor',
      'Unidade',
      'Paciente',
      'CPF',
      'Operadora',
      'Plano',
      'Profissional',
      'Especialidade',
      'Retorno',
      'Indicativo de Retorno',
      'CIDS/CIAPS',
      'OS Relacionada',
      'Última Atualização',
      'Número da Guia',
      'Autorização',
    ],
    headers: [
      {
        text: 'OS',
        value: 'number',
        sortable: false,
        class: 'text-caption font-weight-lightbold dark-green--text py-5',
      },
      {
        text: 'Agendamento',
        value: 'appointment',
        sortable: false,
        class: 'text-caption font-weight-lightbold dark-green--text',
      },
      {
        text: 'Data/Hora',
        value: 'date',
        sortable: false,
        class: 'text-caption font-weight-lightbold dark-green--text',
      },
      {
        text: 'Forma de Pagamento',
        value: 'paymentMethods',
        sortable: false,
        class: 'text-caption font-weight-lightbold dark-green--text',
      },
      {
        text: 'Status da OS',
        value: 'status',
        sortable: false,
        class: 'text-caption font-weight-lightbold dark-green--text',
      },
      {
        text: 'NF emitida',
        value: 'isInvoiceEmitted',
        sortable: false,
        class: 'text-caption font-weight-lightbold dark-green--text',
      },
      {
        text: 'Valor',
        value: 'totalPrice',
        sortable: false,
        class: 'text-caption font-weight-lightbold dark-green--text',
      },
      {
        text: 'Unidade',
        value: 'clinic',
        sortable: false,
        class: 'text-caption font-weight-lightbold dark-green--text',
      },
      {
        text: 'Paciente',
        value: 'patient',
        sortable: false,
        class: 'text-caption font-weight-lightbold dark-green--text',
      },
      {
        text: 'Operadora',
        value: 'hmo',
        sortable: false,
        class: 'text-caption font-weight-lightbold dark-green--text',
      },
      {
        text: 'Plano',
        value: 'healthProduct',
        sortable: false,
        class: 'text-caption font-weight-lightbold dark-green--text',
      },
      {
        text: 'Professional',
        value: 'professional',
        sortable: false,
        class: 'text-caption font-weight-lightbold dark-green--text',
      },
      {
        text: 'Especialidade',
        value: 'speciality',
        sortable: false,
        class: 'text-caption font-weight-lightbold dark-green--text',
      },
      {
        text: 'Retorno',
        value: 'isReturnal',
        sortable: false,
        class: 'text-caption font-weight-lightbold dark-green--text',
      },
      {
        text: 'Indicativo de Retorno',
        value: 'hasReturnalRecommendation',
        sortable: false,
        class: 'text-caption font-weight-lightbold dark-green--text',
      },
      {
        text: 'CIDS/CIAPS',
        value: 'encounterCids',
        sortable: false,
        class: 'text-caption font-weight-lightbold dark-green--text',
      },
      {
        text: 'OS Relacionada',
        value: 'relatedOs',
        sortable: false,
        class: 'text-caption font-weight-lightbold dark-green--text',
      },
      {
        text: 'Última Atualização',
        value: 'updatedAt',
        sortable: false,
        class: 'text-caption font-weight-lightbold dark-green--text',
      },
      {
        text: 'Número da Guia',
        value: 'hmoGuideNumber',
        sortable: false,
        class: 'text-caption font-weight-lightbold dark-green--text',
      },
      {
        text: 'Autorização',
        value: 'authorizationCode',
        sortable: false,
        class: 'text-caption font-weight-lightbold dark-green--text',
      },
      {
        text: '',
        value: 'null',
        sortable: false,
      },
    ],
    selectedOrders: [],
    editMultipleOrdersDialog: false,
  }),
  computed: {
    ...mapGetters({
      clinics: 'clinic/clinics',
      ordersList: 'billing/orders',
      allOrders: 'billing/allOrders',
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
    }),
    selectedOrdersId() {
      return map(prop('id'), this.selectedOrders)
    },
    isSelectedOrdersEmpty() {
      return isEmpty(this.selectedOrders)
    },
    formattedOrdersList() {
      return isEmpty(this.ordersList)
        ? []
        : this.formatOrdersList(this.ordersList.rows)
    },
    hasAccessToContractEdit() {
      return this.userHasAccessToFunctionality.contractEdit
    },
  },
  watch: {
    pagination: {
      async handler() {
        await this.setOrderPagination(this.pagination)
        await this.listOrder()
      },
      deep: true,
    },
  },
  async activated() {
    await this.listOrder()
  },
  methods: {
    ...mapActions({
      setOrderPagination: 'billing/setOrderPagination',
      listOrder: 'billing/listOrder',
      listAllOrders: 'billing/listAllOrders',
      setSnackbar: 'snackbar/setSnackbar',
      bulkUpdateOrders: 'billing/bulkUpdateOrders',
    }),
    shouldShowRelatedOsButton(relatedOs) {
      return !isNil(relatedOs)
    },
    formatDate(date) {
      return moment(date).utc().format('DD/MM/YY')
    },
    async downloadXlsx() {
      this.isDownloadingXslsx = true
      await this.listAllOrders()
      const orders = this.allOrders.rows

      const rows = map(
        omit(['id', 'parentReturnalOrderId']),
        this.formatOrdersList(orders, { removeId: true }),
      )

      try {
        const websheet = createWebSheet(
          {
            rows,
            headers: this.excelHeaders,
            sheetName: 'Ordens de Serviço',
          },
        )

        writeFile(websheet, `RelatórioOS ${moment().format('DD-MM-YY')}.xlsx`)
      } catch (err) {
        this.setSnackbar({
          status: 'error',
          message: 'Erro ao baixar o Relatório.',
        })
      }
      this.isDownloadingXslsx = false
    },
    formatOrdersList(orders, { removeId } = {}) {
      return map(order => ({
        number: order.number,
        appointmentStatus: this.appointmentStatus(order.appointment),
        date: this.formatDate(order.date),
        paymentMethod: this.paymentMethodsValue(order.paymentMethods),
        orderStatus: BILLING.status[order.status],
        isInvoiceEmitted: this.isInvoiceEmitted[order.isInvoiceEmitted],
        totalPrice: order.totalPrice,
        clinic: order.clinic ? order.clinic.name : 'N/A',
        patientName: order.patient.name,
        patientCPF: this.$filters.getIdentifierDocument(order.patient),
        hmoName: order.healthMaintenanceOrganization?.name || 'N/A',
        healthProductName: order.healthProduct?.name || 'N/A',
        professional: order.professional ? order.professional.name : 'N/A',
        speciality: order.speciality.name,
        isReturnal: this.hasReturnalRecommendationValues[order.isReturnal],
        hasReturnalRecommendation: this.hasReturnalRecommendationValues[
          order.hasReturnalRecommendation
        ],
        ...(removeId ? {} : { id: order.id }),
        encounterCids: order.encounterCids ? order.encounterCids : [],
        parentReturnalOrder: path(['number'], order.parentReturnalOrder),
        parentReturnalOrderId: path(['id'], order.parentReturnalOrder),
        updatedAt: this.formatDate(order.updatedAt),
        hmoGuideNumber: order.hmoGuideNumber || 'N/A',
        authorizationCode: order.authorizationCode || 'N/A',
      }), orders)
    },
    toggleMultipleOrdersDialog() {
      this.editMultipleOrdersDialog = !this.editMultipleOrdersDialog
    },
    async bulkUpdate() {
      const idsErrors = await this.bulkUpdateOrders({
        id: this.selectedOrdersId,
        status: this.selectedOrderStatus,
        isInvoiceEmitted: this.selectedIsInvoiceEmitted,
      })
      if (not(isEmpty(idsErrors))) {
        const findOrdersObject = filter(({ id }) => includes(id, idsErrors), this.ordersList.rows)

        const ordersNumbers = map(prop('number'), findOrdersObject)

        const message = gte(length(ordersNumbers), 1)
          ? `As ordens ${join(', ', ordersNumbers)} não puderam ser alteradas`
          : `A ordem ${join(', ', ordersNumbers)} não pode ser alterada`

        this.setSnackbar({
          status: 'error',
          message,
        })
      }
      this.selectedOrderStatus = null
      this.selectedIsInvoiceEmitted = false
      this.selectedOrders = []
      this.listOrder()
      this.toggleMultipleOrdersDialog()
    },
    appointmentStatus(appointment) {
      if (not(appointment)) return 'N/A'
      return appointment
        ? APPOINTMENT.status[appointment.status].label
        : 'N/A'
    },
    openDetailsDialog(id) {
      this.orderId = id
      this.showDetailsDialog = true
    },
    paymentMethodsValue(paymentMethods) {
      if (isNil(paymentMethods)) {
        return 'Não Informado'
      }
      return paymentMethods.reduce((acc, { paymentMethod }, index) => {
        if (index === 0) {
          return PAYMENT_METHODS[paymentMethod].name
        }
        return `${acc}, ${PAYMENT_METHODS[paymentMethod].name}`
      }, '')
    },
  },
}
</script>

<style lang="stylus" scoped>
.v-table--header >>> .v-data-table-header
  background-color #f6f6f6 !important
  box-shadow 0px 3px 1px rgba(224, 224, 224, 0.9)
  padding 1rem 0 !important
</style>
