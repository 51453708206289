<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <span
        class="older-queues__filename"
        v-on="on"
      >
        {{ originalFilename }}
      </span>
    </template>
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>Profissionais Importados</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container
        :class="{
          'container--fluid': true,
          'px-12': $vuetify.breakpoint.smAndUp,
        }"
      >
        <v-layout
          v-if="importErrorCount"
          row
          wrap
        >
          <v-flex
            md12
            sm12
            xs12
            class="error-container"
            pt-4
            pl-4
          >
            <p class="error-container--text">
              Inserção | {{ importErrorCount }} profissionais não importados
              <a
                class="error-container--text__darker"
                :href="donwloadLogLink"
              >
                baixar arquivo de log
              </a>
            </p>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="isProfessionalsEmpty"
        >
          <h2 class="info-text font-weight-lighter">
            Nenhum resultado encontrado.
          </h2>
        </v-layout>
        <template v-else>
          <v-layout
            v-for="(professional, p) in professionals"
            :key="p"
            row
            wrap
            :class="{ 'list-item': true }"
          >
            <v-flex
              md3
              sm6
              xs12
            >
              <div class="truncate">
                <span
                  :class="{
                    'list-item__title': true
                  }"
                >
                  {{ professional.name }}
                </span>

                <div
                  v-for="speciality in professional.specialities"
                  :key="speciality.name"
                  class="list-item__detail"
                >
                  {{ speciality.name }}
                </div>
              </div>
            </v-flex>

            <v-flex
              md4
              sm6
              xs12
              column
              class="list-item__detail"
            >
              <div>
                Email: {{ professional.email }}
              </div>

              <div>
                Telefone: {{ professional.telphone | formatPhone }}
              </div>

              <div>
                Data de nascimento: {{ professional.birthDate | formatDate }}
              </div>

              <div>
                Nacionalidade: {{ professional.nationality }}
              </div>
              <v-divider class="hidden-sm-and-up" />
            </v-flex>

            <v-flex
              md2
              sm6
              xs12
              column
              class="list-item__detail"
            >
              <div>
                {{ professional.document_type }}:
                {{ professional.document_number }} -
                {{ professional.document_state }}
              </div>

              <div>
                CPF: {{ professional.cpf | formatCpf }}
              </div>

              <div>
                RG: {{ professional.rg }}
              </div>
              <v-divider class="hidden-sm-and-up" />
            </v-flex>

            <v-flex
              md2
              sm6
              xs12
              column
              class="list-item__detail"
            >
              <div
                v-for="clinic in professional.clinics"
                :key="clinic.name"
              >
                Unidade: {{ clinic.name }}
              </div>
              <v-divider class="hidden-sm-and-up" />
            </v-flex>
          </v-layout>
        </template>
        <v-flex v-if="numberOfPages > 1">
          <v-spacer />
          <v-pagination
            v-model="page"
            :length="numberOfPages"
            :total-visible="11"
          />
          <v-spacer />
          <span class="total-professionals">
            {{ count }} profission
            <span v-if="count > 1">ais</span>
            <span v-else>al</span>
          </span>
        </v-flex>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  isEmpty,
} from 'ramda'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProfessionalList',
  props: {
    importFileQueueId: {
      type: String,
      required: true,
    },
    originalFilename: {
      type: String,
      required: true,
    },
    logFileKey: {
      type: String,
      default: null,
    },
    importErrorCount: {
      type: Number,
      default: null,
    },
    donwloadLogLink: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      filters: {},
      count: '',
      numberOfPages: null,
      page: 1,
      dialog: false,
      professions: [
        'Médico',
        'Enfermeiro',
        'Técnico de Enfermagem',
        'Nutricionista',
        'Psicólogo',
      ],
    }
  },
  computed: {
    ...mapGetters({
      professionals: 'professional/professionals',
    }),
    isProfessionalsEmpty() {
      return isEmpty(this.professionals)
    },
  },
  mounted() {
    this.filters.importFileQueueId = this.importFileQueueId
    this.listProfessional(this.filters)
  },
  methods: {
    ...mapActions({
      listProfessional: 'professional/listProfessional',
    }),
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'

.list-item
  width 100%
  padding 20px
  border-radius 8px
  border solid 1px #e0e0e0
  margin-bottom 10px
.list-item__title
  font-size 24px
  color rgba(0, 0, 0, 0.87)

.list-item__detail
  font-size 16px
  line-height 2
  letter-spacing 0.5px
  color rgba(0, 0, 0, 0.6)

.v-card
  padding 10px
  border-radius 6px

.info-text
  letter-spacing -0.5px
  color rgba(0, 0, 0, 0.38)
  @media only screen and (min-width: 601px)
    font-size 32px
  @media only screen and (min-width: 1201px)
    font-size 48px
  @media only screen and (min-width: 1601px)
    font-size 64px

.total-professionals
  margin-top 10px

.older-queues__filename
  font-family: Montserrat
  font-size: 15.9px
  line-height: 1.76
  letter-spacing: 0.5px
  color: #00670c

.error-container
  background-color: #ffe6e6
.error-container--text
  font-family: Montserrat
  font-size: 16px
  line-height: 1.34
  letter-spacing: 0.4px
  color: #db4437
.error-container--text__darker
  font-family: Montserrat
  font-size: 16px
  line-height: 1.34
  letter-spacing: 0.4px
  color: #db4437
  cursor pointer
  text-decoration underline
</style>
