import { rest } from 'msw'
import baseUrl from '../../../../src/utils/baseUrlTest'
import slotListData from '../../fixtures/public/slots/list'
import slotDaysData from '../../fixtures/public/slots/days'
import slotAvailableData from '../../fixtures/public/slots/available'

export default [
  rest.get(baseUrl('/slot'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      slots: slotListData,
    }),
  )),
  rest.get(baseUrl('/slot/days'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      days: slotDaysData,
    }),
  )),
  rest.get(baseUrl('/slot/available'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(slotAvailableData),
  )),
]
