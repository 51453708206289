import Vue from 'vue'
import {
  APPOINTMENT,
  ACTIVATION_JOURNEY,
  BLOCK,
  IMMEDIATE_CARE,
  PROFESSION,
  SCHEDULE,
} from 'amparo-enums'
import patientEnum from '@/enums/patient'
import { format as CPFFormater } from '@fnando/cpf'
import { format as CNPJFormater } from '@fnando/cnpj'
import moment from 'moment'
import {
  always,
  cond,
  defaultTo,
  equals,
  find,
  gte,
  head,
  isEmpty,
  isNil,
  last,
  mergeRight,
  not,
  prop,
  propEq,
  propOr,
  replace,
  split,
  T,
  values,
} from 'ramda'
import { toUpper } from 'lodash'

Vue.filter('formatPhone', (phone) => {
  if (isNil(phone)) return ''
  if (isEmpty(replace(/[\D]/g, '', phone))) return phone
  return phone
  // const formattedPhone = parsePhoneNumber(phone, 'BR')
  // return formattedPhone.formatNational()
})

Vue.filter('formatCpf', (cpf) => {
  if (!cpf || isEmpty(cpf)) return '(Não há CPF cadastrado)'
  return CPFFormater(cpf)
})

Vue.filter('formatCnpj', (cnpj) => {
  if (!cnpj || isEmpty(cnpj)) return '(Não há CNPJ cadastrado)'
  return CNPJFormater(cnpj)
})

Vue.filter('formatMoney', (price) => {
  if (!price) return 'R$ 0,00'
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
    .format((price || 0) / 100)
})

Vue.filter('formatDate', (date) => {
  if (!date) return ''
  if (!moment(date).isValid()) return date
  return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
})

Vue.filter('formatDateWithSeconds', (date) => {
  if (!date) return ''
  return moment(date).format('DD/MM/YYYY HH:mm:ss')
})

Vue.filter('formatDatePtBrLL', (date) => {
  if (!date) return ''
  return moment(date)
    .locale('pt-br')
    .format('LL')
})

Vue.filter('dayOfTheWeek', (day) => {
  if (isNil(day)) return ''
  moment.locale('pt')
  return moment().day(day).format('dddd')
})

Vue.filter('dayOfMonth', (date) => {
  if (isNil(date)) return ''
  moment.locale('pt')

  return moment(date).format('DD')
})

Vue.filter('parseYear', (date) => {
  if (isNil(date)) return ''
  moment.locale('pt')

  return moment(date).year()
})

Vue.filter('parseAbbreviatedMonth', (date) => {
  if (isNil(date)) return ''
  moment.locale('pt')

  return moment(date).format('MMM')
})

Vue.filter('parseMonth', (month) => {
  if (isNil(month)) return ''
  moment.locale('pt')
  return moment().month(month).format('MMMM')
})

Vue.filter('formatZipCode', (zipCode) => {
  if (!zipCode) return ''
  return zipCode.replace(/(\d{5})(\d{3})/, '$1-$2')
})

Vue.filter('removeSecondsFromTime', (time) => {
  if (!time) return ''
  return moment(time, 'HH:mm:ss').format('HH:mm')
})

Vue.filter('removeSecondsWithBrTime', (time) => {
  if (!time) return ''
  return moment(time, 'YYYY-MM-DD HH:mm').subtract(3, 'hour').format('HH:mm')
})

Vue.filter('dateWithTime', (date) => {
  if (!date) return ''
  return moment(date, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY [às] HH:mm')
})

Vue.filter('dateWithBrTime', (date) => {
  if (!date) return ''
  return moment(date, 'YYYY-MM-DD HH:mm').subtract(3, 'hour').format('DD/MM/YYYY [às] HH:mm')
})

Vue.filter('convertUtcDateToBrDate', (date) => {
  if (!date) return ''
  return moment.utc(date, 'YYYY-MM-DD HH:mm:ss').local().format('YYYY-MM-DD HH:mm:ss')
})

Vue.filter('convertUtcToBrDate', (date) => {
  if (!date) return ''
  return moment(date).subtract(3, 'hour').format('DD/MM/YYYY')
})

Vue.filter('convertUtcToBrTime', (time) => {
  if (!time) return ''
  return moment(time, 'HH:mm:ss').subtract(3, 'hour').format('HH:mm:ss')
})

Vue.filter('trimStringBy', (string, maxLength) => {
  if (!string) return ''
  return string.length > maxLength
    ? `${string.substring(0, maxLength)}...`
    : string
})

Vue.filter('translateAppointmentStatus', (status) => {
  if (not(status)) return ''
  const translatedAppointmentStatus = find(propEq(status, 'value'))(values(APPOINTMENT.status))
  return translatedAppointmentStatus.label
})

Vue.filter('translateCareHistoryStatus', (status) => {
  if (!status) return ''
  const CARE_HISTORY_STATUS_LIST = values(mergeRight(APPOINTMENT.status, IMMEDIATE_CARE.status))
  const CareHistoryStatus = find(propEq(status, 'value'))(CARE_HISTORY_STATUS_LIST) || {}
  return CareHistoryStatus.label || ' - '
})

Vue.filter('onlyFirstName', (name) => {
  if (!name) return ''
  return head(split(' ', name))
})

Vue.filter('firstTwoNames', (name) => {
  if (!name) return ''
  const [firstName, lastName] = split(' ', name)
  return `${firstName} ${lastName}`
})

Vue.filter('toUpperCase', (word) => {
  if (word) return word.toUpperCase()
  return ''
})

Vue.filter('toLowerCase', (word) => {
  if (word) return word.toLowerCase()
  return ''
})

Vue.filter('biologicalSex', (sex) => {
  if (!sex) return ''
  return equals(sex, 'M') ? 'Masculino' : 'Feminino'
})

Vue.filter('initials', (name) => {
  if (!name) return ''
  const splitted = split(' ', name)

  return toUpper(`${head(head(splitted))}${head(last(splitted))}`)
})

Vue.filter('formatAge', (birthDate) => {
  const getDiffOfDate = diffBy => moment().diff(moment(birthDate), diffBy)
  const buildAgePhrase = (number, plural, singular) => `
    ${number}
    ${equals(number, 1) ? singular : plural}
  `
  const days = getDiffOfDate('days')

  return cond([
    [gte(31), always(buildAgePhrase(days, 'dias', 'dia'))],
    [
      gte(366),
      always(buildAgePhrase(getDiffOfDate('months'), 'meses', 'mês')),
    ],
    [T, always(buildAgePhrase(getDiffOfDate('years'), 'anos', 'ano'))],
  ])(days)
})

Vue.filter('formatDisplayName', ({ name, socialName }) => socialName || name)

Vue.filter('formatPatientRace', (race) => {
  if (isNil(race)) return '*'

  const { RACE } = patientEnum
  const raceInformation = find(
    propEq(race, 'value'),
  )(values(RACE))

  return raceInformation.label
})

Vue.filter('formatMaritalStatus', (maritalStatus) => {
  if (isNil(maritalStatus)) return '*'

  const { MARITAL_STATUS } = patientEnum
  const maritalStatusInformation = find(
    equals(maritalStatus),
  )(values(MARITAL_STATUS))

  return maritalStatusInformation
})

Vue.filter('blockReason', ({ reason, otherReason }) => defaultTo(
  propOr(
    '-',
    'label',
    find(propEq(reason, 'value'), values(BLOCK.reasons)),
  ),
  otherReason,
))

Vue.filter('formatScheduleType', ({ type }) => prop('title',
  find(propEq(type, 'value'))(values(SCHEDULE.types))))

Vue.filter('formatResponsibleType', ({ responsible }) => prop('title',
  find(propEq(responsible, 'value'))(values(PROFESSION.types))))

Vue.filter('customScheduleActionMessage', ({ action, actionDescription }) => {
  const translatedAction = prop('title', find(propEq(action, 'value'))(values(SCHEDULE.actions)))
  if (not(isNil(actionDescription))) return actionDescription
  return translatedAction
})

Vue.filter('getIdentifierDocumentLabel', ({ cpf, foreignDocument }) => {
  if (not(isEmpty(cpf))) return 'CPF'
  if (not(isEmpty(foreignDocument))) return 'Número do passaporte'
  return ''
})

Vue.filter('getIdentifierDocument', ({ cpf, foreignDocument }) => {
  if (not(isEmpty(cpf))) return CPFFormater(cpf)
  if (not(isEmpty(foreignDocument))) return foreignDocument
  return ''
})

Vue.filter('formatBoolean', (boolean) => {
  if (boolean) return 'Sim'
  return 'Não'
})

Vue.filter('formatActivationJourneyStatus', value => prop(
  'label',
  find(propEq(value, 'value'))(values(ACTIVATION_JOURNEY.journeyStatus)),
))
