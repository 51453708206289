import axios from '@/lib/axios'

export default {
  actions: {
    createItemMnemonic(_, attributes) {
      return new Promise((resolve, reject) => {
        axios.post('/billing/item_mnemonic', attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    removeItemMnemonic(_, attributes) {
      return new Promise((resolve, reject) => {
        axios.delete('/billing/item_mnemonic', { data: attributes })
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
  },
}
