import axios from '@/lib/axios'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'

export default {
  state: {
    loadingCareLineTag: false,
    careLineTags: [],
    careLineTagFilters: {},
  },
  mutations: {
    SET_LOADING_CARE_LINE_TAG(state, payload) {
      state.loadingCareLineTag = payload
    },
    SET_CARE_LINE_TAGS(state, payload) {
      state.careLineTags = payload
    },
    SET_CARE_LINE_TAG_FILTERS(state, payload) {
      state.careLineTagFilters = payload
    },
  },
  actions: {
    listCareLineTag({ state, commit }, params = {}) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CARE_LINE_TAG', true)
        const filters = removeNilOrEmpty(state.careLineTagFilters)
        axios.get('/emr/care_line_tag', {
          params: {
            careLineId: params.careLineId || state.careLine.id,
            ...filters,
            ...params,
          },
        })
          .then(({ data: { careLineTags } }) => {
            if (!params.careLineId) {
              commit('SET_CARE_LINE_TAGS', careLineTags)
            }
            resolve(careLineTags)
          })
          .catch((reject))
          .finally(() => commit('SET_LOADING_CARE_LINE_TAG', false))
      })
    },
    createCareLineTag({ state, commit }, attributes) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CARE_LINE_TAG', true)
        axios.post('/emr/care_line_tag', {
          careLineId: state.careLine.id,
          ...attributes,
        })
          .then(resolve)
          .catch(reject)
          .finally(() => commit('SET_LOADING_CARE_LINE_TAG', false))
      })
    },
    updateCareLineTag({ commit }, { tagId, attributes }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CARE_LINE_TAG', true)
        axios.put(`/emr/care_line_tag/${tagId}`, attributes)
          .then(resolve)
          .catch(reject)
          .finally(() => commit('SET_LOADING_CARE_LINE_TAG', false))
      })
    },
    updateCareLineTagStatus({ commit }, { tagId, status }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CARE_LINE_TAG', true)
        axios.patch(`/emr/care_line_tag/${tagId}`, { status })
          .then(resolve)
          .catch(reject)
          .finally(() => commit('SET_LOADING_CARE_LINE_TAG', false))
      })
    },
    setCareLineTags({ commit }, payload) {
      commit('SET_CARE_LINE_TAGS', payload)
    },
    setCareLineTagFilters({ commit }, params) {
      commit('SET_CARE_LINE_TAG_FILTERS', { ...params })
    },
  },
  getters: {
    isLoadingCareLineTag: state => state.loadingCareLineTag,
    careLineTags: state => state.careLineTags,
    careLineTagFilters: state => state.careLineTagFilters,
  },
}
