export default {
  token: '0e381d6a8a4e02dcab043470e127ad',
  user: {
    id: 'user_ck3ogz93f0001dvpqb5di3jtz',
    email: 'devs+pro@amparosaude.com',
    name: 'Devs + Pro',
    professionalId: 'pro_ck3ogpnob0003mhpq0jp47o2l',
    professionId: 'profession_cjz3an5zk00030nxbejbsg6wj',
    permission: {
      id: 'per_ck9wwqrkv0002d5w4gyoucamc',
      name: 'MASTER',
      termId: null,
      policy: {
        routes: [
          {
            route: '/care_team',
            methods: [
              'GET',
              'POST',
              'PUT',
            ],
          },
          {
            route: '/patient/radar',
            methods: [
              'GET',
            ],
          },
          {
            route: '/user/profile',
            methods: [
              'PATCH',
            ],
          },
          {
            route: '/agenda',
            methods: [
              'GET',
              'POST',
              'PATCH',
              'PUT',
            ],
          },
          {
            route: '/announcement',
            methods: [
              'GET',
              'POST',
              'PATCH',
            ],
          },
          {
            route: '/appointment',
            methods: [
              'GET',
              'POST',
              'PATCH',
            ],
          },
          {
            route: '/block',
            methods: [
              'GET',
              'POST',
              'PATCH',
            ],
          },
          {
            route: '/clinic',
            methods: [
              'GET',
              'PUT',
              'POST',
            ],
          },
          {
            route: '/enterprise',
            methods: [
              'GET',
            ],
          },
          {
            route: '/health_maintenance_organization',
            methods: [
              'GET',
              'POST',
              'PUT',
              'PATCH',
            ],
          },
          {
            route: '/health_product',
            methods: [
              'POST',
            ],
          },
          {
            route: '/notification',
            methods: [
              'PATCH',
            ],
          },
          {
            route: '/patient',
            methods: [
              'GET',
              'POST',
              'PUT',
            ],
          },
          {
            route: '/importer/patient',
            methods: [
              'POST',
            ],
          },
          {
            route: '/importer/professional',
            methods: [
              'POST',
            ],
          },
          {
            route: '/importer/file_queue',
            methods: [
              'GET',
              'POST',
              'PUT',
            ],
          },
          {
            route: '/professional',
            methods: [
              'GET',
              'POST',
              'DELETE',
            ],
          },
          {
            route: '/profession',
            methods: [
              'GET',
            ],
          },
          {
            route: '/referral',
            methods: [
              'GET',
            ],
          },
          {
            route: '/room',
            methods: [
              'GET',
              'POST',
              'PATCH',
            ],
          },
          {
            route: '/slot',
            methods: [
              'GET',
            ],
          },
          {
            route: '/speciality',
            methods: [
              'GET',
            ],
          },
          {
            route: '/spotlight',
            methods: [
              'GET',
              'POST',
              'PATCH',
            ],
          },
          {
            route: '/patient_registration',
            methods: [
              'GET',
              'POST',
              'PATCH',
            ],
          },
          {
            route: '/emr/allergy',
            methods: [
              'GET',
            ],
          },
          {
            route: '/emr/attachment',
            methods: [
              'GET',
              'POST',
              'DELETE',
            ],
          },
          {
            route: '/emr/category',
            methods: [
              'GET',
              'POST',
              'DELETE',
            ],
          },
          {
            route: '/emr/care_cycle',
            methods: [
              'GET',
              'PATCH',
            ],
          },
          {
            route: '/emr/care_line',
            methods: [
              'GET',
              'POST',
              'PUT',
              'DELETE',
            ],
          },
          {
            route: '/emr/digital_signature',
            methods: [
              'POST',
            ],
          },
          {
            route: '/emr/disease',
            methods: [
              'GET',
            ],
          },
          {
            route: '/emr/encounter',
            methods: [
              'GET',
              'POST',
              'PATCH',
            ],
          },
          {
            route: '/emr/encounter_form',
            methods: [
              'GET',
            ],
          },
          {
            route: '/emr/exam',
            methods: [
              'GET',
            ],
          },
          {
            route: '/emr/exam_result',
            methods: [
              'GET',
              'POST',
              'DELETE',
            ],
          },
          {
            route: '/emr/form',
            methods: [
              'GET',
              'POST',
              'PATCH',
              'DELETE',
            ],
          },
          {
            route: '/emr/form_question',
            methods: [
              'GET',
              'POST',
              'PATCH',
              'DELETE',
            ],
          },
          {
            route: '/emr/form_patient',
            methods: [
              'GET',
              'PATCH',
            ],
          },
          {
            route: '/emr/communication',
            methods: [
              'GET',
              'POST',
              'PUT',
              'DELETE',
            ],
          },
          {
            route: '/emr/habit',
            methods: [
              'GET',
            ],
          },
          {
            route: '/emr/patient_medical_resume',
            methods: [
              'GET',
              'POST',
              'PATCH',
            ],
          },
          {
            route: '/emr/professional_preferences',
            methods: [
              'GET',
            ],
          },
          {
            route: '/emr/question',
            methods: [
              'GET',
              'POST',
              'PUT',
              'DELETE',
            ],
          },
          {
            route: '/emr/surgery',
            methods: [
              'GET',
            ],
          },
          {
            route: '/emr/schedule',
            methods: [
              'GET',
              'POST',
              'PUT',
            ],
          },
          {
            route: '/emr/vaccine',
            methods: [
              'GET',
            ],
          },
          {
            route: '/term',
            methods: [
              'GET',
            ],
          },
          {
            route: '/term/signature',
            methods: [
              'GET',
              'POST',
            ],
          },
          {
            route: '/user',
            methods: [
              'DELETE',
              'GET',
              'PUT',
              'POST',
              'PATCH',
            ],
          },
          {
            route: '/virtual_reception',
            methods: [
              'GET',
              'PUT',
              'POST',
              'PATCH',
            ],
          },
          {
            route: '/report',
            methods: [
              'GET',
            ],
          },
          {
            route: '/tele/video_room',
            methods: [
              'POST',
              'DELETE',
            ],
          },
          {
            route: '/emr/care_cycle',
            methods: [
              'GET',
              'PATCH',
            ],
          },
          {
            route: '/emr/care_line',
            methods: [
              'GET',
              'POST',
              'PUT',
              'DELETE',
            ],
          },
          {
            route: '/emr/care_line_action',
            methods: [
              'GET',
              'POST',
              'PUT',
              'PATCH',
              'DELETE',
            ],
          },
          {
            route: '/emr/care_line_action_condition',
            methods: [
              'GET',
              'POST',
              'PATCH',
              'DELETE',
            ],
          },
          {
            route: '/emr/care_line_status',
            methods: [
              'GET',
              'POST',
              'PATCH',
              'DELETE',
            ],
          },
          {
            route: '/emr/care_line_patient',
            methods: [
              'GET',
              'POST',
              'PATCH',
              'DELETE',
            ],
          },
          {
            route: '/emr/care_line_disease',
            methods: [
              'GET',
              'POST',
              'PATCH',
              'DELETE',
            ],
          },
          {
            route: '/emr/care_line_age',
            methods: [
              'GET',
              'POST',
              'PATCH',
              'DELETE',
            ],
          },
          {
            route: '/emr/care_line_tag',
            methods: [
              'GET',
              'POST',
              'PUT',
              'PATCH',
            ],
          },
          {
            route: '/emr/care_line_tag_patient',
            methods: [
              'POST',
              'PATCH',
              'DELETE',
            ],
          },
          {
            route: '/aia/query',
            methods: [
              'POST',
            ],
          },
          {
            route: '/billing/contract',
            methods: [
              'GET',
              'POST',
              'PUT',
              'PATCH',
            ],
          },
          {
            route: '/billing/contract/pend',
            methods: [
              'PATCH',
            ],
          },
          {
            route: '/billing/contract/publish',
            methods: [
              'PATCH',
            ],
          },
          {
            route: '/billing/contract/archive',
            methods: [
              'PATCH',
            ],
          },
        ],
        dataAccess: {
          hmoId: [
            'hea_mai_org_cjy92rrfg002hi3vo8gra8asr',
            'hea_mai_org_cjy92ryzw002ii3voawzk0w4c',
            'hea_mai_org_cjyhpwbog0000apvo8yz7ht75',
            'hea_mai_org_cjyhpxra00001apvo551t6omf',
            'hea_mai_org_cjyhpzii00002apvogkkz6how',
            'hea_mai_org_cjyhq4mao0003apvo9sb814em',
            'hea_mai_org_cjyhq6mb4000uapvob7rw0lqw',
            'hea_mai_org_cjyhq6lqo000qapvogn6bfixj',
            'hea_mai_org_cjyhq6llb000papvo2xh5awwy',
            'hea_mai_org_cjyhq6kxb000kapvocqkib8z2',
            'hea_mai_org_cjyhq6jqf000bapvod6nf6wjb',
            'hea_mai_org_cjyhq6k9b000fapvoa6vteo6q',
            'hea_mai_org_ck08o9uxs0001n4vobi2r1kik',
            'hea_mai_org_ck38qxl7v0003qivo1cym0y4r',
            'hea_mai_org_ck41lpvy4000042vo5noq9dgb',
            'hea_mai_org_ckd96eb7j00022qo2g6mbdn1o',
            null,
          ],
          clinicId: [
            'cli_cjxyringi0000i2vog8g55l7q',
            'cli_ckn7qpqml0000d5dq3mdjhkzc',
            null,
          ],
          enterpriseId: [
            'ent_ck5n06g5v00003k3afurb4a7j',
            'ent_ck5n06g6300013k3a6u3b3trh',
            null,
          ],
          specialityId: [
            'spe_cjyrqbjr8006aapvohny3fqcw',
            'spe_cjyrqf835006bapvockcafr8i',
            'spe_cjyrq99x90069apvob7vi7bux',
            'spe_cjyrq45s30068apvo8yxwco1j',
            'spe_cjy92cswd001yi3voc4sjca8c',
            'spe_cjy92cx9g001zi3vo8un7e14f',
            'spe_cjy92d1j80020i3vof11demb2',
            'spe_cjyisn3y90016apvo3eu1bbas',
            'spe_cjz42yawa0002f0vo1ed0cy35',
            'spe_ck2f6ucpo0000yzpqaequ9vp5',
            'spe_ck2f6ucpp0001yzpq17sj9pss',
            'spe_ck2f6ucpp0002yzpq57ttg5d0',
            'spe_ck2f6ucpp0003yzpqctb69j13',
            'spe_ck2f6ucpp0004yzpq2ncpf475',
            'spe_ck2f6ucpp0005yzpq39edc8ci',
            'spe_ck2f6ucpp0007yzpqh6ts8acl',
            'spe_ck2f6ucpp0008yzpq29w1b31h',
            'spe_ck2f6ucpp0009yzpq8ozxbxgh',
            'spe_ck2f6ucpp000ayzpqa6ge2qre',
            'spe_ck2f6ucpq000byzpqeuuw7azi',
            'spe_ck2f6ucpq000cyzpq0js529g3',
            'spe_ck2f6ucpq000eyzpq401vd4mv',
            'spe_ck2f6ucpq000fyzpq3iqndauv',
            'spe_ck2f6ucpq000hyzpqboax1279',
            'spe_ck2f6ucpq000iyzpqepy18dhj',
            'spe_ck2f6ucpq000jyzpqg7sahtb0',
            'spe_ck2f6ucpq000kyzpq01lyezsq',
            'spe_ck2f6ucpq000lyzpq1ux3071t',
            'spe_ck2f6ucpq000myzpqb48o8uv5',
            'spe_ck2f6ucpq000oyzpq6qi711xx',
            'spe_ck2f6ucpr000pyzpq1uj686rg',
            'spe_ck2f6ucpr000qyzpq3gra8pu7',
            'spe_ck2f6ucpr000ryzpq3bjqeelt',
            'spe_ck2f6ucpr000syzpq2bmrbwdp',
            'spe_ck2f6ucpr000tyzpq1vlxcvku',
            'spe_ck2f6ucpr000uyzpq6f15h4a3',
            'spe_ck2f6ucpr000wyzpqhexfgtue',
            'spe_ck2f6ucpr000xyzpq6kmrec3r',
            'spe_ck2f6ucpr000yyzpq1rit99fj',
            'spe_ck2f6ucpr000zyzpqfewia30h',
            'spe_ck2f6ucpr0010yzpq26e74h6h',
            'spe_ck2f6ucpr0011yzpq3x2cc4f8',
            'spe_ck2f6ucps0012yzpq2pl40xq6',
            'spe_ck2f6ucps0013yzpq92pab02f',
            'spe_ck2f6ucps0014yzpqb5nh7ivn',
            'spe_ck2f6ucps0015yzpqhgqifzzf',
            'spe_ck2f6ucps0016yzpq6b1kc1z7',
            'spe_ck2f6ucps0018yzpqb1fb35mr',
            'spe_ck2f6ucps0019yzpq6v2h8fan',
            'spe_ck2f6ucps001ayzpqar5mfp57',
            'spe_ck2f6ucps001byzpq21sv43oq',
            'spe_ck2f6ucps001cyzpqefy85xtc',
            'spe_ck2f6ucps001dyzpqgird51e5',
            'spe_ck2f6ucps001eyzpqdyqd145o',
            'spe_ck43083f600004epq1wo36oxn',
            'spe_ck5wxe2lj00006hpq553nhl59',
            'spe_ck5wxe2ll00016hpqay2s854i',
            'spe_ck5wxe2ll00026hpq1h5rdegc',
            'spe_ck5wxe2ll00036hpqanhdgj06',
            'spe_ck5wxe2ll00046hpq6cvc29yu',
            'spe_ck5wxe2ll00056hpq8qhw6c95',
            'spe_ck5wxe2ll00066hpq8ytkegto',
            'spe_ckqjvonvl00009yzuel2i08if',
          ],
        },
        userInformation: {
          isExternalUser: false,
        },
      },
    },
    professional: {
      id: 'pro_ck3ogpnob0003mhpq0jp47o2l',
      name: 'Devs + Pro (.com)',
      cpf: '54616442797',
      cnpj: null,
      externalIds: {
        prontmed: 'xxxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxx10',
      },
      workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
      documentType: 'CRP',
      documentNumber: '000010',
      documentState: 'SP',
    },
    workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
    userWorkSpaces: [
      {
        id: 'usr_wor_spa_ckcp1zaqa0000j2w4fubfebak',
        userId: 'user_ck3ogz93d0000dvpq3c6gh540',
        professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
        permissionId: 'per_ck9yu3wyr0000frw48mmhhh9m',
        workSpace: {
          id: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          name: 'Amparo Saúde',
        },
      },
      {
        id: 'usr_wor_spa_ckcp6n8x20000vmw40inr0ffn',
        userId: 'user_ck3ogz93d0000dvpq3c6gh540',
        professionalId: 'pro_cknajgdpj0000gopqbhj0h496',
        workSpaceId: 'wor_spa_ckcp6kpzy0000sfw4ci8xa5jl',
        permissionId: 'per_clbglohqd00180jmz9cic7obu',
        workSpace: {
          id: 'wor_spa_ckcp6kpzy0000sfw4ci8xa5jl',
          name: 'Amparo Tech',
        },
      },
    ],
  },
}
