import axios from '@/lib/axios'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'
import moment from 'moment'
import {
  filter,
  isNil,
  path,
  propSatisfies,
} from 'ramda'
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter'
import { getDayByIndex, buildRecurrenceDates } from './utils'

const initialState = {
  loadingAgenda: false,
  agendas: [],
  loadingAgendaItems: false,
  loadingCreateAgendas: false,
  loadingSuccess: [],
  loadingError: [],
  agendaFiltersData: {
    selectedClinic: null,
    selectedSpeciality: null,
    selectedProfessional: null,
    selectedStatus: null,
  },
  agendaFilters: {
    clinicId: null,
    specialityId: null,
    professionalId: null,
    status: null,
  },
  agendaPage: 1,
  agendaNumberOfPages: null,
  agendaCount: null,
}

const mutations = {
  SET_AGENDAS(state, payload) {
    state.agendas = payload
  },
  SET_LOADING_AGENDA(state, payload) {
    state.loadingAgenda = payload
  },
  PUSH_ITEM_LOADING_SUCCESS(state, payload) {
    state.loadingSuccess.push(payload)
  },
  PUSH_ITEM_LOADING_ERROR(state, payload) {
    state.loadingError.push(payload)
  },
  SET_LOADING_SUCCESS(state, payload) {
    state.loadingSuccess = payload
  },
  SET_LOADING_ERROR(state, payload) {
    state.loadingError = payload
  },
  SET_LOADING_AGENDA_ITEMS(state, payload) {
    state.loadingAgendaItems = payload
  },
  SET_LOADING_CREATE_AGENDAS(state, payload) {
    state.loadingCreateAgendas = payload
  },
  SET_AGENDA_FILTERS_DATA(state, payload) {
    state.agendaFiltersData = payload
  },
  SET_AGENDA_FILTERS(state, payload) {
    state.agendaFilters = payload
  },
  SET_AGENDA_PAGE(state, page) {
    state.agendaPage = page
  },
  SET_AGENDA_NUMBER_OF_PAGES(state, numberOfPages) {
    state.agendaNumberOfPages = numberOfPages
  },
  SET_AGENDA_COUNT(state, count) {
    state.agendaCount = count
  },
}

const actions = {
  async createAgendas({ commit }, { attributes, daysOfTheWeek, recurrence }) {
    commit('SET_LOADING_CREATE_AGENDAS', true)

    if (recurrence === 1) {
      for (const day of daysOfTheWeek) {
        try {
          // eslint-disable-next-line no-await-in-loop
          await axios.post('/agenda', {
            ...attributes,
            dayOfTheWeek: day,
          })

          commit('PUSH_ITEM_LOADING_SUCCESS', getDayByIndex(day))
        } catch (error) {
          commit(
            'PUSH_ITEM_LOADING_ERROR',
            {
              date: capitalizeFirstLetter(moment().day(day).locale('pt-br').format('dddd')),
              error: error?.response?.data,
            },
          )
        }
      }

      commit('SET_LOADING_CREATE_AGENDAS', false)
      return
    }

    const dates = buildRecurrenceDates({
      startDate: attributes.startDate,
      endDate: attributes.endDate,
      daysOfTheWeek,
      recurrence,
    })

    for (const date of dates) {
      const formattedDate = moment(date.startDate).format('DD/MM')
      try {
        // eslint-disable-next-line no-await-in-loop
        await axios.post('/agenda', {
          ...attributes,
          ...date,
        })

        commit('PUSH_ITEM_LOADING_SUCCESS', formattedDate)
      } catch (error) {
        commit(
          'PUSH_ITEM_LOADING_ERROR',
          {
            date: formattedDate,
            error: error?.response?.data,
          },
        )
      }
    }
    commit('SET_LOADING_CREATE_AGENDAS', false)
  },
  setLoadingCreateAgendas({ commit }, data) {
    commit('SET_LOADING_CREATE_AGENDAS', data)
  },
  setLoadingSuccess({ commit }, data) {
    commit('SET_LOADING_SUCCESS', data)
  },
  setLoadingError({ commit }, data) {
    commit('SET_LOADING_ERROR', data)
  },
  updateAgenda({ commit }, { id, attributes }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_AGENDA', true)
      axios.put(`agenda/${id}`, attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_AGENDA', false))
    })
  },
  storeAgenda({ commit }, { id, attributes }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_AGENDA', true)
      axios.patch(`agenda/${id}`, attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_AGENDA', false))
    })
  },
  listAgenda({ commit, state }, params) {
    const formattedParams = removeNilOrEmpty({
      ...state.agendaFilters,
      ...params,
      page: state.agendaPage,
    })

    return new Promise((resolve, reject) => {
      commit('SET_LOADING_AGENDA', true)
      axios.get('agenda', { params: formattedParams })
        .then(({ data }) => {
          commit('SET_AGENDAS', data.agendas)
          commit('SET_AGENDA_NUMBER_OF_PAGES', data.numberOfPages)
          commit('SET_AGENDA_COUNT', data.count)
          resolve(data)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING_AGENDA', false))
    })
  },
  listAgendaItems({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_AGENDA_ITEMS', true)
      axios.get('/billing/agenda_item', { params })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING_AGENDA_ITEMS', false))
    })
  },
  listSpecialityItems({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_AGENDA_ITEMS', true)
      axios.get('/billing/speciality_item', { params })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING_AGENDA_ITEMS', false))
    })
  },
  setAgendaFiltersData({ commit }, data) {
    commit('SET_AGENDA_FILTERS_DATA', data)
    commit('SET_AGENDA_FILTERS', {
      clinicId: path(['id'], data.selectedClinic),
      specialityId: path(['id'], data.selectedSpeciality),
      professionalId: path(['id'], data.selectedProfessional),
      status: data.selectedStatus,
    })
    commit('SET_AGENDA_PAGE', 1)
  },
  setAgendaPage({ commit }, page) {
    commit('SET_AGENDA_PAGE', page)
  },
  async sendPrescriptionSms(_, { patient, prescriptionId }) {
    const params = {
      patient: {
        telephone: patient.telephone,
        id: patient.id,
      },
      prescriptionId,
    }
    return axios.post('appointment/send_prescription_sms', params)
  },
}

const getters = {
  agendas: state => state.agendas,
  availableAgendas: state => filter(propSatisfies(isNil, 'storedAt'), state.agendas),
  loadingAgendaItems: state => state.loadingAgendaItems,
  loadingAgenda: state => state.loadingAgenda,
  loadingSuccess: state => state.loadingSuccess,
  loadingError: state => state.loadingError,
  loadingCreateAgendas: state => state.loadingCreateAgendas,
  agendaFilters: state => state.agendaFilters,
  agendaPage: state => state.agendaPage,
  agendaNumberOfPages: state => state.agendaNumberOfPages,
  agendaCount: state => state.agendaCount,
}

export default {
  state: initialState,
  mutations,
  actions,
  getters,
}
