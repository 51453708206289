<template>
  <v-navigation-drawer
    :value="open"
    class="side-menu"
    width="400px"
    fixed
    temporary
  >
    <div
      class="menu__logo"
    >
      <img
        class="menu__logo"
        src="@/assets/amparo-grey-icon.svg"
        @click="close"
      >
    </div>
    <template
      v-for="(fatherComponent) in siteMap"
    >
      <div
        :key="fatherComponent.path"
        class="list-tile"
      >
        <div>
          <router-link
            class="list-title a-link"
            :to="fatherComponent.path"
          >
            {{ fatherComponent.meta.description }}
          </router-link>
        </div>
        <v-divider />
        <div
          v-for="(childComponent, c) in fatherComponent.children"
          :key="c"
        >
          <router-link
            class="list-child font-weight-medium a-link"
            :to="childComponent.path"
          >
            {{ childComponent.meta.description }}
          </router-link>
        </div>
      </div>
    </template>
    <v-list class="hidden-sm-and-up">
      <v-list-item
        v-for="(itemMenu, i) in mobileMenu"
        :key="i"
        class="user-dropdown-tile"
        @click="itemMenu.method"
      >
        <v-list-item-content>
          {{ itemMenu.description }}
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>
            {{ itemMenu.icon }}
          </v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions } from 'vuex'
import siteMap from '@/mixins/siteMap'

export default {
  name: 'SideMenu',
  mixins: [siteMap],
  props: {
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      mobileMenu: [{ description: 'Sair', icon: 'mdi-logout-variant', method: this.userLogout }],
    }
  },
  methods: {
    ...mapActions({
      userLogout: 'authentication/userLogout',
    }),
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="stylus" scoped>
.side-menu
  padding 40px
  padding-right: 0px
  z-index 5

.menu__logo
  height 40px
  cursor pointer
  margin-bottom -10px

.list-tile
  margin 30px 0px

.list-title
  font-size 20px
  line-height 3
  color #4e5559

.list-child
  font-size 16px
  line-height 3

</style>
