import { rest } from 'msw'
import cepData from '../fixtures/cepData'

export default [
  rest.get('https://cdn.apicep.com/file/apicep/04651-075.json', (req, res, ctx) => res(
    ctx.status(200),
  )),
  rest.get('https://viacep.com.br/ws/04651075/json/', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(
      cepData.viaCep,
    ),
  )),
  rest.get('https://brasilapi.com.br/api/cep/v1/04651075', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(
      cepData.brasilApi,
    ),
  )),
]
