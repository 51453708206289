<template>
  <div>
    <component-title />
    <agenda-settings-container />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import AgendaSettingsContainer from './AgendaSettingsContainer'

export default {
  name: 'AgendaSettingsPage',
  components: { ComponentTitle, AgendaSettingsContainer },
}
</script>
