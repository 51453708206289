const openPrint = (elementId, sheetTitle = 'Folha de impressão') => {
  const printWindow = window.open('', 'Imprimir', 'height=700, width=900, top=400, left=500')
  for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
    printWindow.document.write(node.outerHTML)
  }
  printWindow.document.write(`<html><head><title>${sheetTitle}</title>`)
  printWindow.document.write('</head><body >')
  const docs = document.querySelectorAll(`#${elementId}`)
  const doc = docs.length > 1 ? docs[1] : docs[0]
  printWindow.document.write(doc.innerHTML)
  printWindow.document.write('</body></html>')
  printWindow.onload = () => {
    setTimeout(() => {
      printWindow.focus()
      printWindow.print()
      printWindow.close()
    }, 2000)
  }
  printWindow.document.close()
}

export default openPrint
