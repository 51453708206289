<template>
  <v-dialog
    v-model="show"
    content-class="bill__dialog"
    max-width="850"
    @click:outside="closeDialog()"
  >
    <v-card>
      <v-card-title class="card-dialog__title font-weight-bold medium-gray-text">
        Editar fatura
        <v-spacer />
        <v-btn
          icon
          @click="closeDialog()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle class="pt-4 font-weight-medium">
        Operadora: {{ billData.hmo.name }}
      </v-card-subtitle>
      <v-card-text class="pb-0">
        <v-autocomplete
          v-model="selectedStatus"
          :items="billStatuses"
          label="Status"
          clearable
          filled
          item-text="label"
          item-value="value"
          :item-disabled="isStatusDisabled"
          :return-object="true"
          :error="$v.selectedStatus.$error"
          :error-messages="$v.selectedStatus.$error ? 'Campo Obrigatório' : null"
          @blur="$v.selectedStatus.$touch()"
        />
        <vc-date-picker
          v-model="selectedCloseDate"
          locale="pt-br"
          color="green"
        >
          <template v-slot="{ inputEvents }">
            <v-text-field
              :value="formattedDate(selectedCloseDate)"
              readonly
              label="Fechamento"
              append-icon="mdi-calendar"
              filled
              clearable
              :error="$v.selectedCloseDate.$error"
              :error-messages="$v.selectedCloseDate.$error ? 'Campo Obrigatório' : null"
              @click:clear="selectedCloseDate = null"
              @blur="$v.selectedCloseDate.$touch()"
              v-on="inputEvents"
            />
          </template>
        </vc-date-picker>

        <v-expansion-panels
          class="mb-4"
          flat
        >
          <v-expansion-panel>
            <v-expansion-panel-header
              class="pa-0 text-uppercase font-weight-lightbold text-decoration-underline
                amparo-light-green--text"
              transparent
            >
              Histórico de alterações
              <template
                v-slot:actions
                class="d-inline"
              >
                <div>
                  <v-icon
                    right
                    color="amparo-light-green"
                  >
                    mdi-chevron-down
                  </v-icon>
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="expansion-panel-content">
              <timeline-card
                :items-log="billLogs"
                :property-label="logPropertyLabel"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions class="px-6 pb-6">
        <v-spacer />
        <v-btn
          class="mr-2 font-weight-lightbold"
          color="gray darken-2"
          outlined
          @click="closeDialog()"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="font-weight-lightbold"
          color="primary"
          :loading="isLoading"
          :disabled="!hasChanges"
          @click="saveBill()"
        >
          Salvar alterações
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import isNilOrEmpty from '@/utils/dataValidators'
import { BILL } from 'amparo-enums'
import { mapActions, mapGetters } from 'vuex'
import {
  always,
  cond,
  equals,
  isEmpty,
  isNil,
  reject,
  T,
} from 'ramda'

export default {
  components: {
    TimelineCard: () => import('@/components/Common/TimelineCard'),
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    billData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      selectedStatus: null,
      selectedCloseDate: null,
      logPropertyLabel: {
        status: 'Status',
        closeDate: 'Fechamento',
      },
    }
  },
  validations() {
    return {
      selectedStatus: { required },
      selectedCloseDate: { required },
    }
  },
  computed: {
    ...mapGetters({
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
    }),
    show: {
      get() {
        return this.showDialog
      },
      set(value) {
        this.$emit('update:showDialog', value)
      },
    },
    modulesRules() {
      return [
        {
          hasAccess: this.userHasAccessToFunctionality.defaultEditBill,
          rules: [
            { from: BILL.status.toBill.value, to: BILL.status.closed.value },
            { from: BILL.status.closed.value, to: BILL.status.delivered.value },
          ],
        },
        {
          hasAccess: this.userHasAccessToFunctionality.adminEditBill,
          rules: [
            {
              from: [
                BILL.status.toBill.value,
                BILL.status.delivered.value,
              ],
              to: BILL.status.closed.value,
            },
            {
              from: [
                BILL.status.toBill.value,
                BILL.status.closed.value,
                BILL.status.delivered.value,
              ],
              to: BILL.status.canceled.value,
            },
            { from: BILL.status.closed.value, to: BILL.status.delivered.value },
            { from: BILL.status.closed.value, to: BILL.status.toBill.value },
          ],
        },
        {
          hasAccess: this.userHasAccessToFunctionality.financeEditBill,
          rules: [
            { from: BILL.status.delivered.value, to: BILL.status.received.value },
            { from: BILL.status.received.value, to: BILL.status.delivered.value },
          ],
        },
      ]
    },
    billStatuses() {
      return Object.values(BILL.status)
    },
    hasChanges() {
      return this.selectedStatus?.value !== this.billData.status
        || !moment(this.selectedCloseDate).isSame(moment(this.billData.closeDate))
    },
    billLogs() {
      const billLogs = this.billData.historyLog?.logs

      if (isEmpty(billLogs)) return []

      return billLogs.map(log => ({
        title: 'Editado',
        titleClass: 'list__log-title--blue',
        date: log.date,
        by: log.userName,
        diffList: this.buildDiffList({ log }),
      }))
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.fillData()
      }
    },
  },
  mounted() {
    this.fillData()
  },
  methods: {
    ...mapActions({
      updateBill: 'billing/updateBill',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    closeDialog() {
      this.show = false
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    fillData() {
      this.selectedCloseDate = this.billData.closeDate
      this.selectedStatus = BILL.status[this.billData.status]
    },
    formattedDate(date) {
      return isNilOrEmpty(date) ? null : moment(date).format('DD/MM/YYYY')
    },
    canChangeStatus(fromStatus, toStatus) {
      const filteredModules = this.modulesRules.filter(module => module.hasAccess)

      if (isEmpty(filteredModules)) return false

      return filteredModules.some(module => module.rules.some(
        (rule) => {
          if (Array.isArray(rule.from)) {
            return rule.from.includes(fromStatus) && rule.to === toStatus
          }
          return rule.from === fromStatus && rule.to === toStatus
        },
      ))
    },
    isStatusDisabled(status) {
      const previousStatus = this.billData.status

      return !this.canChangeStatus(previousStatus, status.value)
    },
    buildDiffList({ log }) {
      return [
        {
          property: 'status',
          value: BILL.status[log.newStatus]?.label,
          previousValue: BILL.status[log.oldStatus]?.label,
        },
        {
          property: 'closeDate',
          value: moment(log.newCloseDate).format('DD/MM/YYYY'),
          previousValue: log.oldCloseDate
            ? moment(log.oldCloseDate).format('DD/MM/YYYY')
            : null,
        },
      ]
    },
    async saveBill() {
      this.$v.$touch()
      if (this.$v.$error) return

      this.isLoading = true

      try {
        const params = {
          status: this.selectedStatus.value !== this.billData.status
            ? this.selectedStatus.value
            : null,
          closeDate: moment(this.selectedCloseDate).format('YYYY-MM-DD'),
        }

        await this.updateBill({
          id: this.billData.id,
          attributes: reject(isNil, params),
        })

        this.$bus.$emit('update:bill')
        this.buildSnackbar('success', 'Fatura atualizada com sucesso!')
        this.closeDialog()
      } catch (error) {
        const errorMessage = cond([
          [
            equals('status_update_not_allowed'),
            always('Você não possui permissão para essa mudança de status'),
          ],
          [
            equals('close_date_already_in_use'),
            always('Já existe uma fatura com essa data de fechamento'),
          ],
          [T, always('Erro ao atualizar fatura')],
        ])(error?.response?.data?.errorCode)
        this.buildSnackbar('error', errorMessage)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="stylus" scoped>

.v-expansion-panel-header
  >>> .v-expansion-panel-header__icon
    margin-left: 0 !important

.expansion-panel-content
  max-height: 250px
  overflow: auto

</style>
