import careLine from './careLine'
import careLineDisease from './careLineDisease'
import careLineAction from './careLineAction'
import careLineTag from './careLineTag'
import careLineActionCondition from './careLineActionCondition'
import careLineTagPatient from './careLineTagPatient'
import careLinePatient from './careLinePatient'
import careLineAge from './careLineAge'
import careLineActionReminderCommunication from './careLineActionReminderCommunication'
import careLineExam from './careLineExam'

const state = {
  ...careLine.state,
  ...careLineDisease.state,
  ...careLineAction.state,
  ...careLineTag.state,
  ...careLineActionCondition.state,
  ...careLineTagPatient.state,
  ...careLinePatient.state,
  ...careLineAge.state,
  ...careLineActionReminderCommunication.state,
  ...careLineExam.state,
}

const mutations = {
  ...careLine.mutations,
  ...careLineDisease.mutations,
  ...careLineAction.mutations,
  ...careLineTag.mutations,
  ...careLineActionCondition.mutations,
  ...careLineTagPatient.mutations,
  ...careLinePatient.mutations,
  ...careLineAge.mutations,
  ...careLineActionReminderCommunication.mutations,
  ...careLineExam.mutations,
}

const actions = {
  ...careLine.actions,
  ...careLineDisease.actions,
  ...careLineAction.actions,
  ...careLineTag.actions,
  ...careLineActionCondition.actions,
  ...careLineTagPatient.actions,
  ...careLinePatient.actions,
  ...careLineAge.actions,
  ...careLineActionReminderCommunication.actions,
  ...careLineExam.actions,
}

const getters = {
  ...careLine.getters,
  ...careLineDisease.getters,
  ...careLineAction.getters,
  ...careLineTag.getters,
  ...careLineActionCondition.getters,
  ...careLineTagPatient.getters,
  ...careLinePatient.getters,
  ...careLineAge.getters,
  ...careLineActionReminderCommunication.getters,
  ...careLineExam.getters,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
