import axios from '@/lib/axios'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'
import {
  filter,
  isNil,
  path,
  propSatisfies,
} from 'ramda'

const initialState = {
  loadingSpotlight: false,
  spotlights: [],
  spotlightFiltersData: {
    selectedClinic: null,
    selectedSpeciality: null,
    selectedProfessional: null,
  },
  spotlightFilters: {
    clinicId: null,
    specialityId: null,
    professionalId: null,
  },
  spotlightPage: 1,
  spotlightNumberOfPages: null,
  spotlightCount: null,
}

const mutations = {
  SET_SPOTLIGHTS(state, payload) {
    state.spotlights = payload
  },
  SET_LOADING_SPOTLIGHT(state, payload) {
    state.loadingSpotlight = payload
  },
  SET_SPOTLIGHT_FILTERS_DATA(state, payload) {
    state.spotlightFiltersData = payload
  },
  SET_SPOTLIGHT_FILTERS(state, payload) {
    state.spotlightFilters = payload
  },
  SET_SPOTLIGHT_PAGE(state, page) {
    state.spotlightPage = page
  },
  SET_SPOTLIGHT_NUMBER_OF_PAGES(state, numberOfPages) {
    state.spotlightNumberOfPages = numberOfPages
  },
  SET_SPOTLIGHT_COUNT(state, count) {
    state.spotlightCount = count
  },
}

const actions = {
  createSpotlight({ commit }, attributes) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_SPOTLIGHT', true)
      axios.post('spotlight', attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_SPOTLIGHT', false))
    })
  },
  updateSpotlight({ commit }, { id, attributes }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_SPOTLIGHT', true)
      axios.patch(`spotlight/${id}`, attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_SPOTLIGHT', false))
    })
  },
  listSpotlight({ commit, state }) {
    const params = removeNilOrEmpty({
      ...state.spotlightFilters,
      page: state.spotlightPage,
    })
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_SPOTLIGHT', true)
      axios.get('spotlight', { params })
        .then(({ data }) => {
          commit('SET_SPOTLIGHTS', data.spotlights)
          commit('SET_SPOTLIGHT_NUMBER_OF_PAGES', data.numberOfPages)
          commit('SET_SPOTLIGHT_COUNT', data.count)
          resolve(data)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING_SPOTLIGHT', false))
    })
  },
  setSpotlightFiltersData({ commit }, data) {
    commit('SET_SPOTLIGHT_FILTERS_DATA', data)
    commit('SET_SPOTLIGHT_FILTERS', {
      clinicId: path(['id'], data.selectedClinic),
      specialityId: path(['id'], data.selectedSpeciality),
      professionalId: path(['id'], data.selectedProfessional),
    })
    commit('SET_SPOTLIGHT_PAGE', 1)
  },
  setSpotlightPage({ commit }, page) {
    commit('SET_SPOTLIGHT_PAGE', page)
  },
}

const getters = {
  spotlights: state => state.spotlights,
  availableSpotlights: state => filter(propSatisfies(isNil, 'storedAt'), state.spotlights),
  loadingSpotlight: state => state.loadingSpotlight,
  spotlightFilters: state => state.spotlightFilters,
  spotlightPage: state => state.spotlightPage,
  spotlightNumberOfPages: state => state.spotlightNumberOfPages,
  spotlightCount: state => state.spotlightCount,
}

export default {
  state: initialState,
  mutations,
  actions,
  getters,
}
