<template>
  <div class="filters-container">
    <v-layout
      row
      wrap
      class="filters"
    >
      <v-flex
        xs12
        sm2
        md2
        class="filters__field"
      >
        <v-select
          v-model="filters.version"
          label="Buscar por Tipo"
          :items="careCycleDiseaseTypes"
          clearable
          filled
          @change="emitFilters"
          @click:clear="emitFilters"
        />
      </v-flex>
      <v-flex
        xs12
        sm2
        md2
        class="filters__field"
      >
        <v-text-field
          v-model="filters.code"
          filled
          label="Buscar por Código"
          clearable
          no-data-text
          @change="emitFilters"
          @click:clear="emitFilters"
        />
      </v-flex>
      <v-flex
        xs12
        sm3
        md3
        class="filters__field"
      >
        <v-text-field
          v-model="filters.name"
          filled
          label="Buscar por Nome"
          clearable
          no-data-text="Digite para buscar"
          @change="emitFilters"
          @click:clear="emitFilters"
        />
      </v-flex>
      <v-flex
        xs12
        sm3
        md3
        class="filters__field"
      >
        <v-select
          v-model="filters.highlightType"
          label="Buscar por Tipo de Destaque"
          item-text="label"
          item-value="value"
          :items="careCycleHightlightTypes"
          clearable
          filled
          @change="emitFilters"
          @click:clear="emitFilters"
        />
      </v-flex>
      <v-flex
        xs12
        sm2
        md2
        class="filters__field"
      >
        <v-select
          v-model="filters.displayOrder"
          label="Buscar por Ordem"
          :items="careCycleDisplayOrder"
          clearable
          filled
          @change="emitFilters"
          @click:clear="emitFilters"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import {
  converge,
  isEmpty,
  isNil,
  or,
  reject,
  values,
} from 'ramda'
import careCycleOptions from '@/enums/careCycle'

const { HIGHLIGHT_TYPE } = careCycleOptions

const { DISEASE_TYPE } = careCycleOptions

const { DISPLAY_ORDER } = careCycleOptions

export default {
  data() {
    return {
      filters: {
        page: 1,
      },
      careCycleHightlightTypes: values(HIGHLIGHT_TYPE),
      careCycleDiseaseTypes: values(DISEASE_TYPE),
      careCycleDisplayOrder: values(DISPLAY_ORDER),
    }
  },
  created() {
    this.emitFilters()
  },
  activated() {
    this.emitFilters()
  },
  methods: {
    emitFilters() {
      this.$nextTick(() => {
        this.$emit('filterUpdate', reject(converge(or, [isNil, isEmpty]), this.filters))
        return true
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
paddingX()
  padding-left 1.5rem
  padding-right @padding-left
.filters
  height 8rem
  paddingX()
.filters__field
  display flex
  align-items center
  justify-content center
  paddingX()
.field__date
  width 100%
</style>
