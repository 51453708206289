<template>
  <div>
    <v-text-field
      v-if="canAddReminder"
      v-model="reminderText"
      append-icon="mdi-plus"
      prepend-inner-icon="mdi-star"
      label="Adicionar lembrete"
      background-color="#e8f8e8"
      clearable
      filled
      @change="addEncounterReminder(reminderText)"
      @click:append="addEncounterReminder(reminderText)"
    />
    <v-alert
      v-if="newReminder || encounterReminder"
      color="dark-green white--text"
      :value="true"
      dismissible
    >
      <v-icon color="#f5a623">
        mdi-star
      </v-icon>
      {{ newReminder ? newReminder : encounterReminder[0].text }}
    </v-alert>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  not,
  isNil,
} from 'ramda'

export default {
  props: [
    'canAddReminder',
    'encounterReminder',
    'encounterData',
    'shouldCleanField',
  ],
  data() {
    return {
      newReminder: null,
      reminderText: '',
    }
  },
  computed: {
    ...mapGetters({
      encounter: 'emr/encounter',
      reminderId: 'emr/reminderEncounterId',
      user: 'authentication/user',
    }),
    reminderData() {
      return this.encounterData ? this.encounterData[this.reminderId] : null
    },
  },
  watch: {
    encounterData() {
      this.fillFieldWithEncounterData()
    },
  },
  activated() {
    if (this.shouldCleanField) {
      this.newReminder = null
      this.reminderText = ''
    }
  },
  methods: {
    ...mapActions({
      setEncounterResponse: 'emr/setEncounterResponse',
    }),
    fillFieldWithEncounterData() {
      if (
        this.encounterData
        && not(isNil(this.encounterData[this.reminderId]))
      ) {
        this.addEncounterReminder(this.encounterData[this.reminderId])
        this.newReminder = this.encounterData[this.reminderId][0].text
      }
    },
    clearReminderTextField() {
      this.reminderText = null
    },
    addEncounterReminder(text) {
      if (!text || text.length <= 1) return
      this.newReminder = text
      this.updateEncounterReminder(this.newReminder)
      this.clearReminderTextField()
    },
    updateEncounterReminder(reminderText) {
      if (this.encounter.data && isNil(reminderText)) {
        delete this.encounter.data[this.reminderId]
        return
      }
      this.setEncounterResponse({
        questionId: this.reminderId,
        res: [
          {
            id: this.user.professionalId,
            text: reminderText,
          },
        ],
      })
    },
  },
}
</script>
