import axios from '@/lib/axios'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'

export default {
  state: {
    loadingContract: false,
    contract: {},
  },
  mutations: {
    SET_LOADING_CONTRACT(state, payload) {
      state.loadingContract = payload
    },
    SET_CONTRACT(state, payload) {
      state.contract = payload
    },
  },
  actions: {
    getContract({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CONTRACT', true)
        axios.get(`/billing/contract/${id}`)
          .then(({ data }) => {
            commit('SET_CONTRACT', data)
            resolve(data)
          })
          .catch(reject)
          .finally(() => commit('SET_LOADING_CONTRACT', false))
      })
    },
    getActualContract(_, healthProductId) {
      return new Promise((resolve, reject) => {
        axios.get('/billing/contract/actual', {
          params: {
            healthProductId,
          },
        })
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    createContract(_, attributes) {
      return new Promise((resolve, reject) => {
        axios.post('/billing/contract', attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    cloneContract(_, attributes) {
      return new Promise((resolve, reject) => {
        axios.post('/billing/contract/clone', attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    publishMultipleContracts(_, attributes) {
      return new Promise((resolve, reject) => {
        axios.patch('/billing/contract/publish/multiple', attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    updateContract(_, { id, attributes }) {
      const updatedContract = removeNilOrEmpty(attributes)
      return new Promise((resolve, reject) => {
        axios.put(`/billing/contract/${id}`, updatedContract)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    pendContract(_, { id, attributes }) {
      return new Promise((resolve, reject) => {
        axios.patch(`/billing/contract/pend/${id}`, attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    approveContract(_, id) {
      return new Promise((resolve, reject) => {
        axios.patch(`/billing/contract/approve/${id}`)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    rejectContract(_, { id, attributes }) {
      return new Promise((resolve, reject) => {
        axios.patch(`/billing/contract/reject/${id}`, attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    publishContract(_, { id, attributes }) {
      return new Promise((resolve, reject) => {
        axios.patch(`/billing/contract/publish/${id}`, attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    archiveContract(_, id) {
      return new Promise((resolve, reject) => {
        axios.patch(`/billing/contract/archive/${id}`)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
  },
  getters: {
    isLoadingContract: state => state.loadingContract,
    contract: state => state.contract,
  },
}
