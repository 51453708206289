import communication from './communication'

const state = {
  ...communication.state,
}

const mutations = {
  ...communication.mutations,
}

const actions = {
  ...communication.actions,
}

const getters = {
  ...communication.getters,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
