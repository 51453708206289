import { equals, toPairs } from 'ramda'

const excelCoordinates = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V']

const createWebSheetHeaders = (headers) => {
  const webSheetHeader = {}
  headers.forEach((header, index) => {
    const headerCoordinate = `${excelCoordinates[index]}1`
    webSheetHeader[headerCoordinate] = { t: 's', v: header }
  })
  return webSheetHeader
}

const handleWebSheetValue = (columnKey, columnValue) => (
  equals(columnKey, 'totalPrice') || equals(columnKey, 'price')
    ? { t: 'n', v: columnValue / 100, z: '#,##0.00' }
    : { t: 's', v: columnValue })

const handleWebSheetWidth = ({
  webSheetValues,
  headers,
  columnValue,
  columnIndex,
}) => {
  const currWidth = webSheetValues['!cols'][columnIndex] ? webSheetValues['!cols'][columnIndex].wch : 8
  const maxWidth = columnValue
    ? Math.max(String(columnValue).length, headers[columnIndex].length, currWidth)
    : currWidth
  return { wch: Math.min(maxWidth, 40) }
}

const createRefs = (rows, headers) => `A1:${excelCoordinates[headers.length - 1]}${rows.length + 1}`

const createWebSheetValues = (rows, headers) => {
  const webSheetValues = { '!cols': [] }
  rows.forEach((row, rowIndex) => {
    const columns = toPairs(row)

    columns.forEach(([columnKey, columnValue], columnIndex) => {
      const coordinate = String(excelCoordinates[columnIndex] + (rowIndex + 2))

      webSheetValues['!cols'][columnIndex] = handleWebSheetWidth({
        webSheetValues,
        columnValue,
        columnIndex,
        headers,
      })

      webSheetValues[coordinate] = handleWebSheetValue(columnKey, columnValue)
    })
  })
  return webSheetValues
}

const createWebSheet = ({ rows, headers, sheetName }) => {
  const ref = createRefs(rows, headers)

  const webSheetHeader = createWebSheetHeaders(headers)

  const webSheetValues = createWebSheetValues(rows, headers)

  const webSheet = { '!ref': ref, ...webSheetHeader, ...webSheetValues }

  return {
    SheetNames: [sheetName],
    Sheets: {
      [sheetName]: webSheet,
    },
  }
}

export default createWebSheet
