<template>
  <v-select
    v-model="model"
    class="dropzone__select-input"
    hide-details
    dense
    :label="data.label"
    :items="data.options"
    :error="$v.model.$error"
    @blur="$v.model.$touch()"
    @change="updateValue()"
  />
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    fileId: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      model: null,
    }
  },
  validations() {
    return {
      model: { required },
    }
  },
  methods: {
    updateValue() {
      this.$emit('update:inputValue', {
        fileId: this.fileId,
        property: this.data.name,
        value: this.model,
      })
      this.$v.model.$touch()
    },
  },
}
</script>

<style lang="stylus" scoped>
.dropzone__select-input >>> .v-input__slot::before
  border-style none

</style>
