import { rest } from 'msw'
import clinicsListData from '../fixtures/public/clinic/list'
import baseUrl from '../../../src/utils/baseUrlTest'

export default [
  rest.get(baseUrl('/clinic'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(clinicsListData),
  )),
]
