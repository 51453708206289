<template>
  <v-flex class="pagination">
    <v-pagination
      :value="page"
      :length="numberOfPages"
      :total-visible="5"
      @input="changePage($event)"
    />
    <span class="pagination__total">
      Total: {{ count }}
    </span>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AgendaSettingsItemsPagination',
  computed: {
    ...mapGetters({
      page: 'agenda/agendaPage',
      numberOfPages: 'agenda/agendaNumberOfPages',
      count: 'agenda/agendaCount',
    }),
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page)
    },
  },
}
</script>

<style lang="stylus" scoped>
.pagination
  display flex
  justify-content center
  align-items center

.pagination__total
  position absolute
  right 10%
</style>
