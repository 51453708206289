import axios from '@/lib/axios'

export default {
  state: {
    loadingCareLineActionCondition: false,
    careLineActionConditions: [],
  },
  mutations: {
    SET_LOADING_CARE_LINE_ACTION_CONDITION(state, payload) {
      state.loadingCareLineActionCondition = payload
    },
    SET_CARE_LINE_ACTION_CONDITIONS(state, payload) {
      state.careLineActionConditions = payload
    },
  },
  actions: {
    listCareLineActionCondition({ commit }, careLineActionId) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CARE_LINE_ACTION_CONDITION', true)
        axios.get('/emr/care_line_action_condition', {
          params: { careLineActionId },
        })
          .then(({ data: { careLineActionConditions } }) => {
            commit('SET_CARE_LINE_ACTION_CONDITIONS', careLineActionConditions)
            resolve(careLineActionConditions)
          })
          .catch((reject))
          .finally(() => commit('SET_LOADING_CARE_LINE_ACTION_CONDITION', false))
      })
    },
    createCareLineActionCondition({ commit }, attributes) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CARE_LINE_ACTION_CONDITION', true)
        axios.post('/emr/care_line_action_condition', attributes)
          .then(resolve)
          .catch(reject)
          .finally(() => commit('SET_LOADING_CARE_LINE_ACTION_CONDITION', false))
      })
    },
    updateCareLineActionCondition({ commit }, { id, attributes }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CARE_LINE_ACTION_CONDITION', true)
        axios.patch(`/emr/care_line_action_condition/${id}`, attributes)
          .then(resolve)
          .catch(reject)
          .finally(() => commit('SET_LOADING_CARE_LINE_ACTION_CONDITION', false))
      })
    },
    deleteCareLineActionCondition({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CARE_LINE_ACTION_CONDITION', true)
        axios.delete(`/emr/care_line_action_condition/${id}`)
          .then(resolve)
          .catch(reject)
          .finally(() => commit('SET_LOADING_CARE_LINE_ACTION_CONDITION', false))
      })
    },
  },
  getters: {
    isLoadingCareLineActionConditions: state => state.loadingCareLineActionCondition,
    careLineActionConditions: state => state.careLineActionConditions,
  },
}
