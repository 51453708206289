<template>
  <div>
    <v-container
      fluid
      class="px-12 py-5 filters-container"
    >
      <v-row>
        <v-col>
          <v-text-field
            v-model="filters.name"
            filled
            label="Buscar por nome"
            clearable
            no-data-text="Digite para buscar"
            hide-details
            @change="() => listUserResults()"
            @click:clear="() => listUserResults()"
          />
        </v-col>

        <v-col>
          <v-text-field
            v-model="filters.email"
            filled
            label="Buscar por E-mail"
            clearable
            no-data-text="Digite para buscar"
            hide-details
            @change="() => listUserResults()"
            @click:clear="() => listUserResults()"
          />
        </v-col>

        <v-col>
          <v-autocomplete
            v-model="filters.professionId"
            :items="professions"
            item-text="name"
            item-value="id"
            clearable
            filled
            label="Profissão"
            hide-details
            @change="() => listUserResults()"
            @click:clear="() => listUserResults()"
          />
        </v-col>

        <v-col>
          <v-select
            v-model="filters.isActive"
            :items="isActiveItems"
            item-text="label"
            item-value="value"
            clearable
            filled
            label="Status"
            hide-details
            @change="() => listUserResults()"
            @click:clear="() => listUserResults()"
          />
        </v-col>

        <v-col>
          <v-btn
            color="dark-green btn-filter--height font-weight-lightbold"
            dark
            block
            large
          >
            <v-icon left>
              mdi-magnify
            </v-icon>
            Buscar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container
      v-if="isLoading"
      class="mt-12"
    >
      <v-row
        justify="center"
      >
        <v-col
          cols="auto"
        >
          <circular-loader />
        </v-col>
      </v-row>
    </v-container>

    <v-container
      v-else
      fluid
      class="mt-12 px-12"
    >
      <v-row
        v-if="isUsersEmpty"
      >
        <v-col>
          <h2
            class="info-text font-weight-lighter"
          >
            Nenhum resultado encontrado.
          </h2>
        </v-col>
      </v-row>

      <v-row
        v-for="(userWorkSpace, index) in users"
        v-else
        :key="index"
        class="list-item pa-5"
      >
        <v-col
          cols="6"
          class="pa-0"
        >
          <p class="list-item__title mb-0">
            {{ userWorkSpace.user.name }}
          </p>

          <p
            class="list-item__detail mb-0"
          >
            E-mail: {{ userWorkSpace.user.email }}
          </p>
        </v-col>

        <v-col
          class="d-flex align-center pa-0"
        >
          <p class="list-item__detail mb-0">
            Profissão: {{ userWorkSpace.user.profession.name }}
          </p>
        </v-col>

        <v-col
          cols="auto"
          class="d-flex align-center justify-center pa-0"
        >
          <div
            v-if="!userWorkSpace.user.deletedAt"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mr-2"
                  text
                  icon
                  @click="editDialog(userWorkSpace)"
                  v-on="on"
                >
                  <v-icon>mdi-account-edit</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  icon
                  @click="deleteDialog(userWorkSpace)"
                  v-on="on"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </div>

          <div
            v-else
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  flat
                  icon
                  @click="restoreDialog(userWorkSpace)"
                  v-on="on"
                >
                  <v-icon>mdi-recycle</v-icon>
                </v-btn>
              </template>
              <span>Restaurar</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>

      <v-row
        v-if="userNumberOfPages > 1"
        justify="center"
      >
        <v-col
          cols="11"
        >
          <v-pagination
            v-model="page"
            :length="userNumberOfPages"
            :total-visible="11"
            @input="listUserResults(page)"
          />
        </v-col>

        <v-col>
          <span class="total-patients">{{ userCount }} profissionais</span>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-if="showEditUserDialog"
      :value="true"
      transition="dialog-bottom-transition"
      fullscreen
    >
      <v-card
        class="pa-3"
      >
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Editar Usuário</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="closeCreateUserDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <create-user
          v-if="showEditUserDialog"
          edit-user
          :user-data="editedUser"
          @closeCreateUserDialog="closeCreateUserDialog"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="showDeleteUserDialog"
      :value="showDeleteUserDialog"
      max-width="650"
    >
      <v-card
        class="pa-3"
      >
        <v-card-title class="font-weight-bold">
          <h2>
            Atenção!
          </h2>
        </v-card-title>

        <v-card-text class="text-body-1 black--text">
          <p>
            Ao concluir esta ação, serão excluidas <strong>todas</strong>
            as agendas deste profissional. Esta ação <strong>não</strong> é reversível!
          </p>

          <p class="mb-0">
            Deseja realmente continuar com a exclusão?
          </p>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn
                class="font-weight-lightbold"
                color="green darken-1"
                text
                block
                @click="exitDeleteDialog"
              >
                Cancelar
              </v-btn>
            </v-col>

            <v-col>
              <v-btn
                class="font-weight-lightbold"
                block
                color="error"
                :disabled="shouldDisableDeleteDialogButton"
                @click="deleteUser"
              >
                Deletar
                <span v-if="shouldDisableDeleteDialogButton">
                  <strong>({{ deleteDialogButtonTimer }})</strong>
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="showRestoreUserDialog"
      :value="true"
      max-width="500"
    >
      <v-card
        class="pa-3"
      >
        <v-card-text class="text-h5">
          <p class="mb-0">
            Deseja realmente restaurar o perfil de {{ restoredUser.name }}?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-row
            justify="space-between"
          >
            <v-col
              cols="auto"
            >
              <v-btn
                class="font-weight-lightbold pa-4"
                color="green darken-1"
                text
                large
                @click="exitRestoreDialog"
              >
                Cancelar
              </v-btn>
            </v-col>

            <v-col
              cols="auto"
            >
              <v-btn
                class="font-weight-lightbold pa-4"
                large
                color="success"
                @click="handleRestoreUser()"
              >
                Restaurar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  always,
  cond,
  equals,
  isEmpty,
  T,
  values,
} from 'ramda'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'UserList',
  components: {
    CreateUser: () => import('./CreateUser'),
    CircularLoader: () => import('@/components/UI/CircularLoader'),
  },
  data() {
    return {
      showEditUserDialog: false,
      editedUser: {},
      filters: {
        isActive: true,
      },
      showDeleteUserDialog: false,
      shouldDisableDeleteDialogButton: false,
      deleteDialogButtonTimer: 10,
      showRestoreUserDialog: false,
      restoredUser: {},
      deletedUser: {},
      page: 1,
      isActiveItems: values({
        TRUE: {
          label: 'Ativo',
          value: true,
        },
        FALSE: {
          label: 'Inativo',
          value: false,
        },
      }),
    }
  },
  computed: {
    ...mapGetters({
      professions: 'profession/professions',
      isLoading: 'authentication/loadingUser',
      users: 'authentication/users',
      userCount: 'authentication/userCount',
      userNumberOfPages: 'authentication/userNumberOfPages',
    }),
    isUsersEmpty() {
      return isEmpty(this.users)
    },
  },
  async mounted() {
    await this.listProfession()
    this.listUserResults()
  },
  methods: {
    ...mapActions({
      listProfession: 'profession/listProfession',
      setSnackbar: 'snackbar/setSnackbar',
      listUser: 'authentication/listUser',
      removeUser: 'authentication/deleteUser',
      restoreUser: 'authentication/restoreUser',
    }),
    listUserResults(page = 1) {
      return this.listUser({
        ...this.filters,
        page,
      })
    },
    async handleRestoreUser() {
      try {
        await this.restoreUser(this.restoredUser.id)
        this.buildSnackbar('success', 'Usuário restaurado com sucesso!')
        this.restoredUser = {}
        this.showRestoreUserDialog = false
        this.listUserResults()
      } catch (error) {
        this.buildSnackbar('error', 'Erro ao restaurar usuário!')
      }
    },
    handleDeleteUserError({ response }) {
      return cond([
        [
          equals('Professional has appointments in future'), always('Erro ao inativar usuário. Primeiro cancele as consultas agendadas'),
        ],
        [
          T, always('Erro ao deletar usuário!'),
        ],
      ])(response?.data?.errorMessage)
    },
    async deleteUser() {
      try {
        await this.removeUser(this.deletedUser.id)
        this.buildSnackbar('success', 'Usuário deletado com sucesso!')
        this.deletedUser = {}
        this.showDeleteUserDialog = false
        this.listUserResults()
      } catch (error) {
        this.buildSnackbar('error', this.handleDeleteUserError(error))
      }
    },
    deleteDialog(userData) {
      this.deletedUser = userData.user
      this.showDeleteUserDialog = true
      this.showDeleteUserDialogDeleteButtonTimer = 0
      this.shouldDisableDeleteDialogButton = true
      this.setDeleteDialogButtonTimmer()
    },
    setDeleteDialogButtonTimmer() {
      const deleteDialogButtonInterval = setInterval(() => {
        this.deleteDialogButtonTimer = this.deleteDialogButtonTimer - 1
      }, 1000)

      setTimeout(() => {
        clearInterval(deleteDialogButtonInterval)
        this.deleteDialogButtonTimer = 10
        this.shouldDisableDeleteDialogButton = false
      }, 10000)
    },
    restoreDialog(userData) {
      this.restoredUser = userData.user
      this.showRestoreUserDialog = true
    },
    exitDeleteDialog() {
      this.deletedUser = {}
      this.showDeleteUserDialog = false
    },
    exitRestoreDialog() {
      this.restoredUser = {}
      this.showRestoreUserDialog = false
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    editDialog(userData) {
      this.editedUser = userData
      this.showEditUserDialog = true
    },
    closeCreateUserDialog() {
      this.editedUser = {}
      this.showEditUserDialog = false
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'

.list-item
  border-radius 8px
  border solid 1px #e0e0e0
  margin-bottom 10px

.list-item__title
  font-size 1.5rem
  color rgba(0, 0, 0, 0.87)

.list-item__detail
  font-size 1rem
  line-height 2
  letter-spacing 0.5px
  color rgba(0, 0, 0, 0.6)

.info-text
  color rgba(0, 0, 0, 0.38)

  @media only screen and (min-width 601px)
    font-size 32px

  @media only screen and (min-width 1201px)
    font-size 48px

.v-btn
  letter-spacing 1px

.v-btn.v-btn--large
  margin-top 0px
  height 56px
  border-radius 8px

.v-btn.v-btn--text
  border solid 1px thin-gray
</style>
