import axios from '@/lib/axios'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'
import {
  keys,
  length,
  gte,
  groupWith,
  equals,
} from 'ramda'
import { parseAgendaFilters } from './utils'

const hasAllFilters = filters => gte(
  length(keys(filters)),
  4,
)

const groupSlotsWithSameProperties = groupWith(
  (slot, anotherSlot) => (
    equals(slot.clinic.id, anotherSlot.clinic.id)
    && equals(slot.professional.id, anotherSlot.professional.id)
    && equals(slot.speciality.id, anotherSlot.speciality.id)
  ),
)

const initialState = {
  loadingSlot: false,
  slots: [],
}

const mutations = {
  SET_SLOTS(state, { slots, params }) {
    if (params !== state.agendaParams) return
    state.slots = slots
  },
  SET_LOADING_SLOT(state, payload) {
    state.loadingSlot = payload
  },
  SET_AGENDA_PARAMS(state, payload) {
    state.agendaParams = payload
  },
}

const actions = {
  createSlot({ commit }, attributes) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_SLOT', true)
      axios.post('slot', attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_SLOT', false))
    })
  },
  updateSlot({ commit }, { id, attributes }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_SLOT', true)
      axios.put(`slot/${id}`, attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_SLOT', false))
    })
  },
  listSlot({ commit }, filters = {}) {
    const params = parseAgendaFilters(removeNilOrEmpty(filters))
    commit('SET_AGENDA_PARAMS', params)
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_SLOT', true)
      axios.get('slot', { params })
        .then(({ data: { slots } }) => {
          const slotsToList = hasAllFilters(params)
            ? slots
            : groupSlotsWithSameProperties(slots)

          if (!equals(false, filters.saveDataOnStore)) {
            commit('SET_SLOTS', { slots: slotsToList, params })
          }
          resolve(slotsToList)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING_SLOT', false))
    })
  },
  getSlotById(_, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/slot/${id}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
}

const getters = {
  slots: state => state.slots,
  loadingSlot: state => state.loadingSlot,
}

export default {
  state: initialState,
  mutations,
  actions,
  getters,
}
