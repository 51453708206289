<template>
  <v-expansion-panels
    :value="0"
    accordion
    flat
  >
    <emr-resume-item-panel title="Linhas de Cuidado">
      <template v-slot:actions>
        <v-btn
          class="ml-auto"
          text
          small
          @click.stop="isOpenCareLinePatientList = true"
        >
          <v-icon
            small
            left
          >
            mdi-pencil
          </v-icon>
          Editar
        </v-btn>
      </template>
      <div v-if="careLinesPatient.length === 0">
        <p
          class="my-2 font-weight-light mb-4"
        >
          Paciente não possui Linhas de Cuidado
        </p>
      </div>
      <div
        v-for="careLineType in careLineTypes"
        v-else
        :key="careLineType.title"
      >
        <v-tooltip
          v-for="careLine in getCareLineTypeData(careLineType.dataFieldName)"
          :key="careLine.id"
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              class="care-line__item"
              v-bind="attrs"
              v-on="on"
            >
              <v-btn
                class="care-line-type__label mr-1"
                fab
                dark
                elevation="0"
                color="primary"
              >
                {{ careLineType.label }}
              </v-btn>
              {{ careLine.careLine.name | toUpperCase }}
            </div>
          </template>
          <span>{{ careLineType.description }}</span>
          <ul v-if="hasDiseasesLog(careLine)">
            <v-flex
              v-for="careLineDisease in careLine.diseases.logs"
              :key="careLineDisease.diseaseId"
              class="row no-gutters my-2"
            >
              <v-col>
                <li>
                  {{ careLineDisease.version }} -
                  {{ careLineDisease.code }} -
                  {{ careLineDisease.name }}
                </li>
              </v-col>
            </v-flex>
          </ul>
          <div v-else>
            <li>Não há CIDs atrelados</li>
          </div>
        </v-tooltip>
      </div>

      <div
        class="care-line-add mt-4"
        @click="openNewCareLinePatient"
      >
        <v-btn
          class="care-line-add__button mr-2"
          fab
          dark
          elevation="0"
          color="dark-green"
        >
          <v-icon
            dark
            small
          >
            mdi-plus
          </v-icon>
        </v-btn>
        <span class="mt-2 text-uppercase">
          Adicionar linha de cuidado
        </span>
      </div>

      <emr-care-line-patient-list-dialog
        v-if="isOpenCareLinePatientList"
        :is-open-care-line-patient-list="isOpenCareLinePatientList"
        @toggleCreateCareLinePatient="openNewCareLinePatient()"
        @closeCareLinePatientListDialog="isOpenCareLinePatientList = false"
      />

      <emr-new-care-line-patient-dialog
        v-if="isOpenNewCareLinePatient"
        @closeDialog="closeNewCareLinePatient"
      />
    </emr-resume-item-panel>
  </v-expansion-panels>
</template>

<script>

import { mapGetters } from 'vuex'
import {
  defaultTo,
  equals,
  filter,
  path,
} from 'ramda'
import { CARE_LINE } from 'amparo-enums'

export default {
  name: 'EmrCareLinePatient',
  components: {
    EmrResumeItemPanel: () => import('@/components/Common/EmrResumeItemPanel'),
    EmrNewCareLinePatientDialog: () => import('@/pages/Emr/EmrNewCareLinePatientDialog'),
    EmrCareLinePatientListDialog: () => import('@/pages/Emr/EmrCareLinePatientListDialog'),
  },
  data() {
    return {
      isOpenNewCareLinePatient: false,
      isOpenCareLinePatientList: false,
      careLineTypes: [
        {
          title: 'Linha Primária',
          dataFieldName: 'primaryLines',
          label: '1º',
          description: 'Linha de cuidado primária',
        },
        {
          title: 'Linha Secundária',
          dataFieldName: 'secondLines',
          label: '2º',
          description: 'Linha de cuidado secundária',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      careLinesPatient: 'careLine/careLinesPatient',
      patient: 'emr/patient',
    }),
    careLinesPatient() {
      return defaultTo([], this.patient.careLinesPatient)
    },
    primaryLines() {
      return filter(
        careLine => careLine.careLine.isPrimary
        && !(equals(careLine.careLineId, CARE_LINE.activationJourney)),
        this.careLinesPatient,
      )
    },
    secondLines() {
      return filter(
        careLine => !(careLine.careLine.isPrimary)
        && !(equals(careLine.careLineId, CARE_LINE.activationJourney)),
        this.careLinesPatient,
      )
    },
  },
  methods: {
    openNewCareLinePatient() {
      this.isOpenNewCareLinePatient = true
    },
    closeNewCareLinePatient() {
      this.isOpenNewCareLinePatient = false
    },
    getCareLineTypeData(dataFieldName) {
      return this[dataFieldName]
    },
    hasDiseasesLog(careLinePatient) {
      return path(['diseases', 'logs'], careLinePatient)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.list__header
  background-color transparent !important

.list-item__title
  font-size 0.9rem
  white-space normal

.list-item__text
  font-size 0.9rem
  white-space normal
  color #777

.list-item__empty-text
  font-weight 300
  font-size 0.9rem
  white-space normal

.care-line-type__label
  width 21px !important
  height 21px !important
  font-size 12px !important
  cursor default

.care-line__item
  font-weight 500
  line-height 16px
  margin-bottom 8px
  cursor default

.care-line-add__button
  width 21px !important
  height 21px !important

.care-line-add
  color dark-green
  cursor pointer
  border-radius 5px !important
  padding 5px 5px 5px 10px
  line-height 21px
  font-weight 500

.care-line-add:hover
  background-color rgba(0, 100, 0, 0.1)

</style>
