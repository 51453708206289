<template>
  <v-timeline-item
    fill-dot
    color="eveningSea"
    large
    class="timeline-container"
    :class="{
      'timeline__patient-initals-fixed': isInsideEncounter,
    }"
  >
    <template v-slot:icon>
      <span class="white-text">{{ patientName | initials }}</span>
    </template>
    <v-layout
      class="mx-8"
    >
      <v-flex xs6>
        <v-flex
          v-if="isInsideEncounter"
          mb-2
          class="timeline__patient-name font-weight-medium"
        >
          {{ patient | formatDisplayName }}
          <v-switch
            v-if="shouldShowImmediateCareVoucher"
            v-model="immediateCareVoucherSwitch"
            class="mt-3 ml-2"
            label="Amparo Agora"
            color="green"
            @change="handleUpdateImmediateCareVoucher"
          />
        </v-flex>
        <span v-else>
          <router-link
            class="timeline__patient-name patient-profile-link font-weight-medium"
            :to="{ name: 'patientProfile', params: { id: patient.id }}"
          >
            {{ patient | formatDisplayName }}
          </router-link>
        </span>
      </v-flex>
      <v-flex xs6>
        <v-flex
          justify-end
          align-end
          column
        >
          <slot />
        </v-flex>
        <v-flex
          v-if="isInsideEncounter"
          justify-end
          align-end
          mr-6
          class="timeline__info font-weight-bold"
        >
          <span class="timeline__info--label">Tempo da consulta:</span>
          <span class="timeline__info--value">{{ encounterTime }}</span>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-layout class="mx-8">
      <v-flex
        xs12
        class="justify-end mr-2 mt-4"
      >
        <v-btn
          text
          :color="sensitiveDataButtonColor"
          @click="openUpdatePatientSensitiveDataDialog"
        >
          <v-icon left>
            mdi-pencil
          </v-icon>
          <span class="sensitive-data__underline">
            {{ sensitiveDataButtonText }}
          </span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout class="mx-8 flex-column">
      <v-flex
        xs12
        class="timeline__info-container"
      >
        <div class="timeline__info font-weight-bold">
          <span class="timeline__info--label">{{ patient | getIdentifierDocumentLabel }}:</span>
          <span class="timeline__info--value">{{ patient | getIdentifierDocument }}</span>
        </div>
        <div class="timeline__info font-weight-bold">
          <span class="timeline__info--label">Sexo biológico:</span>
          <span class="timeline__info--value">{{ patient.biologicalSex | biologicalSex }}</span>
        </div>
        <div class="timeline__info font-weight-bold">
          <span class="timeline__info--label">Idade:</span>
          <span class="timeline__info--value">{{ patient.birthDate | formatAge }}</span>
        </div>
        <div class="timeline__info font-weight-bold">
          <span class="timeline__info--label">Raça/Cor autodeclarada:</span>
          <span class="timeline__info--value">{{ patient.race | formatPatientRace }}</span>
        </div>
        <div class="timeline__info font-weight-bold">
          <span class="timeline__info--label">Religião:</span>
          <span class="timeline__info--value">{{ patient.religion || '*' }}</span>
        </div>
        <div class="timeline__info font-weight-bold">
          <span class="timeline__info--label">Estado Civil:</span>
          <span class="timeline__info--value">
            {{ patient.maritalStatus | formatMaritalStatus }}
          </span>
        </div>
        <div
          v-if="patient.isForeign"
          class="timeline__info font-weight-bold"
        >
          <span class="timeline__info--label">Nacionalidade:</span>
          <span class="timeline__info--value">{{ patient.nationality || '*' }}</span>
        </div>
        <div
          v-if="patient.score"
          class="timeline__info font-weight-bold"
        >
          <span class="timeline__info--label">Pontuação</span>
          <span class="timeline__info--value">{{ patient.score/100 }}</span>
        </div>
        <div
          v-if="patient.careTeam"
          class="timeline__info font-weight-bold"
        >
          <span class="timeline__info--label">Equipe Assistencial:</span>
          <span class="timeline__info--value">{{ patient.careTeam.name }}</span>
        </div>
        <div class="timeline__info font-weight-bold">
          <span class="timeline__info--label">Código do Prontuário:</span>
          <span class="timeline__info--value">{{ patient.id }}</span>
        </div>
        <div
          v-if="hasActivationJourney"
          class="timeline__info font-weight-bold"
        >
          <span class="timeline__info--label green--text text--accent-4">
            Jornada de Ativação
          </span>
          <span class="timeline__info--value">
            <v-tooltip top>
              Status Atual - Início em
              {{ activationJourney.createdAt | convertUtcToBrDate }}
              <template v-slot:activator="{ on }">
                <span
                  class="text-decoration-underline cursor--pointer"
                  v-on="on"
                  @click="openActivationJourneyDialog"
                >
                  {{ activationJourney.journeyStatus | formatActivationJourneyStatus }}
                </span>
              </template>
            </v-tooltip>
          </span>
        </div>
      </v-flex>
      <v-flex
        v-if="shouldShowPatientDoctorNotes"
        class="mb-4"
        column
      >
        <div class="font-weight-bold timeline__info--label mb-2">
          Sobre o paciente
          <a
            class="primary--text text-decoration-underline ml-1 font-weight-medium"
            @click="handlePatientDoctorNotes"
          >
            <span v-if="isEditingPatientDoctorNotes">(salvar)</span>
            <span v-else>(editar)</span>
          </a>
        </div>
        <v-textarea
          v-if="isEditingPatientDoctorNotes"
          v-model="patientDoctorNotes"
          rows="1"
          counter
          filled
          auto-grow
          :rules="doctorNotesFieldRules"
        >
          <template v-slot:counter>
            <span class="text-caption">
              {{ patientDoctorNotes.length }} / 1000
            </span>
          </template>
        </v-textarea>
        <div
          v-else
          class="font-weight-regular dark-gray-text"
        >
          {{ patientDoctorNotes || 'Não há informações sobre o paciente' }}
        </div>
      </v-flex>
      <div v-if="suggestedExamsData.length > 0">
        <span class="timeline__info--label font-weight-bold">
          Coleta de exames:
        </span>
        <v-flex
          v-if="isInsideEncounter"
          class="mb-4"
        >
          <a
            v-if="justificationForEmptyRequiredExams"
            class="d-flex align-items-end error--text"
            @click="setJustificationForEmptyRequiredExams(null)"
          >
            <v-icon
              size="16"
              color="error"
            >
              mdi-message-bulleted-off
            </v-icon>
            <span class="text-decoration-underline text-caption ml-2">
              Cancelar justificativa
            </span>
          </a>
          <a
            v-else
            class="d-flex align-items-end"
            @click="openJustifyMenu($event)"
          >
            <v-icon
              size="16"
              color="medium-grey"
            >
              mdi-message-bulleted-off
            </v-icon>
            <span class="timeline__info--label text-decoration-underline text-caption ml-2">
              Não preencher campos
            </span>
          </a>
          <emr-field-justify-menu
            title="Por quê os campos não podem ser preenchidos?"
            :show-justify-menu="showJustifyEmptyEmrFieldsMenu"
            :mouse-position-x="mousePositionX"
            :mouse-position-y="mousePositionY"
            @close="closeJustifyEmptyEmrFieldsMenu"
            @save-justification="saveJustificationForEmptyFields"
          />
        </v-flex>
        <v-flex
          xs12
          class="timeline__info-container mt-2"
        >
          <div
            v-for="(exam, index) of suggestedExamsData"
            :key="index"
          >
            <span class="timeline__info--label font-weight-bold text-capitalize">
              {{ exam.label }}:
              <span :class="getExamDateColorClass(exam)">
                {{ exam.requiredDate }}
              </span>
            </span>
          </div>
        </v-flex>
      </div>
    </v-layout>
    <div class="mx-8">
      <v-layout>
        <patient-medical-follow-up-accept-info-chip
          :patient="patient"
        />
      </v-layout>
      <v-divider />
    </div>
    <div
      v-if="shouldShowPatientTags"
      class="mx-8"
    >
      <v-layout class="py-4">
        <emr-patient-tags v-if="shouldShowPatientTags" />
      </v-layout>
      <v-divider />
    </div>

    <emr-form-warning />

    <emr-update-patient-sensitive-data
      v-if="isOpenUpdatePatientSensitiveDataDialog"
      @close="closeUpdatePatientSensitiveDataDialog"
    />

    <activation-journey-modal
      v-if="isOpenActivationJourneyModal"
      @close="closeActivationJourneyModal"
    />

    <emr-add-on-activation-journey
      v-if="isOpenAddOnActivationJourney"
      @closeDialog="closeAddOnActivationJourneyDialog"
    />
  </v-timeline-item>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import {
  any,
  equals,
  isEmpty,
  isNil,
  not,
  path,
  pick,
  values,
  cond,
  always,
  gte,
  T,
} from 'ramda'
import { HMO } from 'amparo-enums'

import PatientMedicalFollowUpAcceptInfoChip from '@/components/Common/PatientMedicalFollowUpAcceptInfoChip'
import EmrFormWarning from './EmrFormWarning'
import EmrPatientTags from './EmrPatientTags'

export default {
  components: {
    EmrPatientTags,
    EmrFormWarning,
    PatientMedicalFollowUpAcceptInfoChip,
    EmrUpdatePatientSensitiveData: () => import('@/pages/Emr/EmrUpdatePatientSensitiveData'),
    EmrAddOnActivationJourney: () => import('@/pages/Emr/EmrAddOnActivationJourney'),
    EmrFieldJustifyMenu: () => import('@/components/Common/EmrFieldJustifyMenu'),
    ActivationJourneyModal: () => import(
      '@/pages/PatientProfile/ActivationJourneyModal'
    ),
  },
  props: {
    isInsideEncounter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      immediateCareVoucherData: null,
      immediateCareVoucherSwitch: false,
      isOpenUpdatePatientSensitiveDataDialog: false,
      isOpenActivationJourneyModal: false,
      isOpenAddOnActivationJourney: false,
      showJustifyEmptyEmrFieldsMenu: false,
      mousePositionX: 0,
      mousePositionY: 0,
      isEditingPatientDoctorNotes: false,
      patientDoctorNotes: '',
      doctorNotesFieldRules: [v => v.length <= 1000 || 'Max 1000 caracteres'],
    }
  },
  computed: {
    ...mapState({
      justificationForEmptyRequiredExams: ({ emr }) => emr.justificationForEmptyRequiredExams,
    }),
    ...mapGetters({
      encounterTime: 'emr/encounterTime',
      patient: 'emr/patient',
      user: 'authentication/user',
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
      suggestedExamsData: 'emr/suggestedExamsData',
      activationJourney: 'patient/activationJourney',
      hasActivationJourney: 'patient/hasActivationJourney',
    }),
    shouldShowPatientTags() {
      return this.userHasAccessToFunctionality.careLineTags
    },
    shouldShowPatientDoctorNotes() {
      return this.userHasAccessToFunctionality.emrPatientDoctorNotes
    },
    patientName() {
      return this.$filters.formatDisplayName(this.patient)
    },
    shouldShowImmediateCareVoucher() {
      return equals(path(['healthMaintenanceOrganization', 'id'], this.patient), HMO.rita)
        && not(equals('done', path(['status'], this.immediateCareVoucherData)))
    },
    sensitiveDataIsFullfiled() {
      const sensitiveData = pick(
        [
          'race',
          'religion',
          'maritalStatus',
        ],
        this.patient,
      )

      return !(
        any(isNil, values(sensitiveData))
        || (this.patient.isForeign && isNil(this.patient.nationality))
      )
    },
    sensitiveDataButtonColor() {
      return this.sensitiveDataIsFullfiled ? 'primary' : 'danger'
    },
    sensitiveDataButtonText() {
      return this.sensitiveDataIsFullfiled
        ? 'Editar dados do Paciente'
        : 'Preencher Campos Obrigatórios'
    },
  },
  watch: {
    'patient.resume': function setDoctorNotes() {
      this.patientDoctorNotes = path(['patient', 'resume', 'doctorNotes'], this) || ''
    },
  },
  async created() {
    await this.loadPatient()
    this.patientDoctorNotes = path(['patient', 'resume', 'doctorNotes'], this) || ''
    await this.getCareLineSuggestedExams({ patientId: this.$route.params.id })
  },
  async activated() {
    await this.loadPatient()
    if (this.isInsideEncounter) {
      this.getEncounterTime()
      this.setImmediateCareVoucher(this.patient.id)
    }
  },
  mounted() {
    this.updatePatientSpecialConditions({
      id: this.patient.id,
    })
  },
  methods: {
    ...mapActions({
      getEncounterTime: 'emr/getEncounterTime',
      getImmediateCareVoucher: 'patientImmediateCareVoucher/getPatientImmediateCareVoucher',
      createImmediateCareVoucher: 'patientImmediateCareVoucher/createPatientImmediateCareVoucher',
      updateImmediateCareVoucher: 'patientImmediateCareVoucher/updatePatientImmediateCareVoucher',
      updatePatientSpecialConditions: 'patient/updatePatientSpecialConditions',
      setJustificationForEmptyRequiredExams: 'emr/setJustificationForEmptyRequiredExams',
      setEmrPatient: 'emr/setEmrPatient',
      setSnackbar: 'snackbar/setSnackbar',
      getCareLineSuggestedExams: 'emr/getCareLineSuggestedExams',
    }),
    getExamDateColorClass({ daysLeftToRequired }) {
      return cond([
        [gte(0), always('tech-red--text')],
        [gte(10), always('tech-yellow--text')],
        [T, always('amparo-gray--text')],
      ])(daysLeftToRequired)
    },
    closeJustifyEmptyEmrFieldsMenu() {
      this.showJustifyEmptyEmrFieldsMenu = false
    },
    openJustifyMenu(event) {
      this.mousePositionX = event.clientX
      this.mousePositionY = event.clientY
      this.showJustifyEmptyEmrFieldsMenu = true
    },
    saveJustificationForEmptyFields(justification) {
      this.setJustificationForEmptyRequiredExams(justification)
      this.closeJustifyEmptyEmrFieldsMenu()
    },
    async setImmediateCareVoucher() {
      this.immediateCareVoucherData = await this.getImmediateCareVoucher(this.patient.id)
      const immediateCareVoucherStatus = path(['status'], this.immediateCareVoucherData)
      this.immediateCareVoucherSwitch = not(
        isNil(immediateCareVoucherStatus)
        || equals('canceled', immediateCareVoucherStatus),
      )
    },
    async handleUpdateImmediateCareVoucher() {
      if (this.immediateCareVoucherSwitch && isNil(this.immediateCareVoucherData)) {
        await this.createImmediateCareVoucher(this.patient.id)
      } else {
        await this.updateImmediateCareVoucher({
          patientId: this.patient.id,
          status: this.immediateCareVoucherSwitch ? 'pending' : 'canceled',
        })
      }
      await this.setImmediateCareVoucher()
    },
    async loadPatient() {
      if (isEmpty(this.patient)) {
        await this.setEmrPatient(this.$route.params.id)
      }
    },
    openUpdatePatientSensitiveDataDialog() {
      this.isOpenUpdatePatientSensitiveDataDialog = true
    },
    closeUpdatePatientSensitiveDataDialog() {
      this.isOpenUpdatePatientSensitiveDataDialog = false
    },
    openActivationJourneyModal() {
      this.isOpenActivationJourneyModal = true
    },
    closeActivationJourneyModal() {
      this.isOpenActivationJourneyModal = false
    },
    openAddOnActivationJourneyDialog() {
      this.isOpenAddOnActivationJourney = true
    },
    closeAddOnActivationJourneyDialog() {
      this.isOpenAddOnActivationJourney = false
    },
    openActivationJourneyDialog() {
      if (!equals(this.activationJourney.status, 'finished')) {
        this.openActivationJourneyModal()
      } else {
        this.openAddOnActivationJourneyDialog()
      }
    },
    handlePatientDoctorNotes() {
      if (this.isEditingPatientDoctorNotes) {
        this.$http.patch(`emr/patient_medical_resume/doctor_notes/${this.patient.id}`, {
          doctorNotes: this.patientDoctorNotes,
        })
          .then(() => {
            this.isEditingPatientDoctorNotes = false
          })
          .catch(() => {
            this.setSnackbar({
              status: 'error',
              message: 'Não foi possível concluir a solicitação, tente novamente mais tarde',
            })
          })

        return
      }

      this.isEditingPatientDoctorNotes = true
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../../style/_core/colors.styl'

.timeline__patient-name
  font-size 33.8px
  letter-spacing 0.25px
  color primary-color

.timeline__patient-medical-team
  margin-right 10px

.timeline__patient-initals-fixed >>> .v-timeline-item__inner-dot
  position fixed

.timeline-container >>> .v-timeline-item__divider > .v-timeline-item__dot
  width 53px

.timeline__info-container
  display flex
  flex-wrap wrap
  column-gap 4rem
  row-gap 1rem
  margin-top 2rem
  margin-bottom 1rem

.timeline__info
  &--label
    color $gray
  &--value
    color $mine-shaft
    margin-left .7rem

.patient-profile-link
  text-decoration none
  color primary-color

.sensitive-data__underline
  text-decoration underline

</style>
