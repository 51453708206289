import axios from '@/lib/axios'

export default {
  state: {
    loadingProfessionalItem: false,
    professionalItems: [],
  },
  mutations: {
    SET_LOADING_PROFESSIONAL_ITEM(state, payload) {
      state.loadingProfessionalItem = payload
    },
    SET_PROFESSIONAL_ITEMS(state, payload) {
      state.professionalItems = payload
    },
  },
  actions: {
    listProfessionalItem({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_PROFESSIONAL_ITEM', true)
        axios.get('/billing/professional_item', { params })
          .then(({ data }) => {
            commit('SET_PROFESSIONAL_ITEMS', data)
            resolve(data)
          })
          .catch(reject)
          .finally(() => commit('SET_LOADING_PROFESSIONAL_ITEM', false))
      })
    },
    createProfessionalItem(_, attributes) {
      return new Promise((resolve, reject) => {
        axios.post('/billing/professional_item', attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },

  },
  getters: {
    isLoadingProfessionalItem: state => state.loadingProfessionalItem,
    professionalItems: state => state.professionalItems,
  },
}
