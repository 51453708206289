import axios from '@/lib/axios'

const initialState = {
  loading: false,
  exams: [],
}

const mutations = {
  SET_EXAMS(state, payload) {
    state.exams = payload
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
}

const actions = {
  listExam({ commit }, filters = {}) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.get('emr/exam', { params: filters })
        .then(({ data: { exams } }) => {
          commit('SET_EXAMS', exams)
          resolve(exams)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
}

const getters = {
  exams: state => state.exams,
  loading: state => state.loading,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
