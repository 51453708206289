export default {
  contracts: [
    {
      id: 'hea_pro_cl0mitq7r0001jus931dp3w1i',
      name: 'AEROPORTO - SBBR',
      nickName: 'AEROPORTO - SBBR',
      healthMaintenanceOrganizationId: 'hea_mai_org_cl0mitq7r0001jus931dp3w1i',
      contracts: [
        {
          id: 'con_clp1a0ou5000008l2aemp5mgw',
          name: 'Aeoroport v0',
          description: null,
          startDate: '2022-02-25T00:00:00.000Z',
          endDate: '2023-10-05T02:53:15.000Z',
          version: 1,
          status: 'approved',
          createdAt: '2022-03-11T14:46:28.513Z',
          storedAt: '2023-10-05T02:53:15.000Z',
          baseContract: null,
          createdBy: null,
        },
      ],
    },
    {
      id: 'hea_pro_cjyhpwbog0000apvo8yz7ht75',
      name: 'PARTICULAR',
      nickName: 'PARTICULAR',
      healthMaintenanceOrganizationId: 'hea_mai_org_cjyhpwbog0000apvo8yz7ht75',
      contracts: [
        {
          id: 'con_cl1wivpec00002czugemmh0i9',
          name: 'particular v0',
          description: null,
          startDate: '2022-02-25T00:00:00.000Z',
          endDate: '2023-10-05T02:53:15.000Z',
          version: 1,
          status: 'approved',
          createdAt: '2022-03-11T14:46:28.513Z',
          storedAt: '2023-10-05T02:53:15.000Z',
          baseContract: null,
          createdBy: null,
        },
      ],
    },
    {
      id: 'hea_pro_clndfqshc0001t5pb0xbv82kr',
      name: 'PLANO NOVO 1',
      nickName: 'PLANO NOVO 1',
      healthMaintenanceOrganizationId: 'hea_mai_org_cjyhpwbog0000apvo8yz7ht75',
      contracts: [
        {
          id: 'con_clneqixnx0696rbpbcoi41fma',
          name: 'PLANO NOVO 1',
          description: '???',
          startDate: null,
          endDate: null,
          version: 8,
          status: 'approved',
          createdAt: '2023-10-06T15:01:59.661Z',
          storedAt: null,
          baseContract: {
            id: 'con_clndfh9dr0001sopbdylbgqwt',
            name: 'particular v0',
          },
          createdBy: {
            id: 'user_ck3ogz93d0000dvpq3c6gh540',
            name: 'Devs + Pro',
          },
        },
      ],
    },
    {
      id: 'hea_pro_clndfqxhw0002t5pb6uk6ddtf',
      name: 'PLANO NOVO 2',
      nickName: 'PLANO NOVO 2',
      healthMaintenanceOrganizationId: 'hea_mai_org_cjyhpwbog0000apvo8yz7ht75',
      contracts: [],
    },
  ],
}
