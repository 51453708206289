<template>
  <code class="medical-certificate__message font-weight-medium">{{ message }}</code>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: [
    'message',
  ],
  computed: {
    ...mapGetters({
      patient: 'emr/patient',
    }),
  },
}
</script>

<style lang="stylus" scoped>
.medical-certificate__message
  color #222222
  border none
  box-shadow unset
  font-family 'Montserrat'
</style>
