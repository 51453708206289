<template>
  <v-container
    class="px-16 mt-8"
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-badge
          class="badge"
          :content="openEncounters.length"
          left
          inline
        >
          <span
            class="text-h4"
          >
            Consultas Não Finalizadas
          </span>
        </v-badge>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p
          class="warning-text"
        >
          <strong>Atenção</strong>: Prontuários abertos a <strong>mais de 30 dias</strong> serão
          <strong>cancelados</strong> automaticamente. Não se esqueça de finalizar todas as
          consultas registradas no sistema após o atendimento aos pacientes.
        </p>
      </v-col>
    </v-row>

    <v-container
      v-if="isLoading"
      class="mt-12"
    >
      <v-row
        justify="center"
      >
        <v-col
          cols="auto"
        >
          <circular-loader />
        </v-col>
      </v-row>
    </v-container>

    <v-container
      v-else
      class="px-0"
      fluid
    >
      <v-row
        v-if="isOpenEncountersEmpty"
      >
        <v-col>
          <h2
            class="info-text font-weight-lighter"
          >
            Nenhum Prontuário aberto.
          </h2>
        </v-col>
      </v-row>

      <v-container
        v-for="(encounter, index) in openEncounters"
        :key="index"
        class="list-item px-6 py-8 mb-8"
        fluid
      >
        <v-row
          justify="space-between"
        >
          <v-col
            cols="auto"
          >
            <v-row>
              <v-col
                cols="12"
              >
                <p class="list-item__title mb-0">
                  {{ encounter.patient.name }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="auto"
              >
                <v-icon
                  class="mr-2"
                >
                  mdi-calendar
                </v-icon>
                <span>{{ encounter.date | formatDate }}</span>
              </v-col>
              <v-col
                cols="auto"
              >
                <v-icon
                  class="mr-2"
                >
                  mdi-clock-outline
                </v-icon>
                <span>{{ encounter.startTime | convertUtcToBrTime | removeSecondsFromTime }}</span>
              </v-col>
              <v-col
                cols="auto"
              >
                <v-icon
                  class="mr-1"
                >
                  mdi-map-marker
                </v-icon>
                <span>{{ encounter.clinic?.name }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="auto"
          >
            <v-btn
              large
              class="btn btn-primary mt-4"
              @click="redirectToEncounter(encounter)"
            >
              Ir para o Prontuário
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import {
  isEmpty,
} from 'ramda'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'OpenEncountersListContainer',
  components: {
    CircularLoader: () => import('@/components/UI/CircularLoader'),
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      openEncounters: 'emr/openEncounters',
    }),
    isOpenEncountersEmpty() {
      return isEmpty(this.openEncounters)
    },
  },
  async mounted() {
    if (this.isOpenEncountersEmpty) {
      this.isLoading = true
      await this.getOpenEncounters()
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getOpenEncounters: 'emr/getOpenEncounters',
    }),
    redirectToEncounter({ id, patientId }) {
      window.open(`/emr/${patientId}/encounter/${id}`, '_blank')
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'

.list-item
  border-radius 8px
  border solid 1px #e0e0e0
  margin-bottom 10px

.list-item__title
  font-size 1.5rem
  color rgba(0, 0, 0, 0.87)

.badge >>> .v-badge__wrapper > .v-badge__badge
  font-size 1.2rem
  height 27.5px !important
  min-width 27.5px !important
  background-color error-color !important
  margin-right 0.5rem

.warning-text
  font-weight 400
</style>
