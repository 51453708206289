import hmo from './hmo'
import healthProduct from './healthProduct'
import bill from './bill'
import contractAttachment from './contractAttachment'
import contract from './contract'
import contractItem from './contractItem'
import groupStore from './group'
import item from './item'
import itemMnemonic from './itemMnemonic'
import order from './order'
import orderSabinShift from './orderSabinShift'
import professionalItem from './professionalItem'

const state = {
  ...hmo.state,
  ...healthProduct.state,
  ...contract.state,
  ...contractItem.state,
  ...order.state,
  ...item.state,
  ...professionalItem.state,
  ...contractAttachment.state,
  ...groupStore.state,
}

const mutations = {
  ...hmo.mutations,
  ...healthProduct.mutations,
  ...contract.mutations,
  ...contractItem.mutations,
  ...order.mutations,
  ...item.mutations,
  ...professionalItem.mutations,
  ...contractAttachment.mutations,
  ...groupStore.mutations,
}

const actions = {
  ...hmo.actions,
  ...healthProduct.actions,
  ...contract.actions,
  ...contractItem.actions,
  ...order.actions,
  ...orderSabinShift.actions,
  ...item.actions,
  ...itemMnemonic.actions,
  ...professionalItem.actions,
  ...contractAttachment.actions,
  ...groupStore.actions,
  ...bill.actions,
}

const getters = {
  ...hmo.getters,
  ...healthProduct.getters,
  ...contract.getters,
  ...contractItem.getters,
  ...order.getters,
  ...item.getters,
  ...professionalItem.getters,
  ...contractAttachment.getters,
  ...groupStore.getters,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
