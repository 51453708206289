<template>
  <div>
    <apex-chart
      ref="chartLine"
      type="line"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
import { extendMoment } from 'moment-range'
import {
  addIndex,
  and,
  equals,
  filter,
  gt,
  isNil,
  keys,
  lte,
  map,
  reject,
  union,
} from 'ramda'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ApexChart: VueApexCharts,
  },
  props: [
    'patient',
    'metric',
    'type',
    'yTitle',
    'filterExam',
    'exams',
    'examId',
    'minAge',
    'maxAge',
  ],
  data() {
    return {
      series: [],
      chartOptions: {
        legend: {
          itemMargin: {
            horizontal: 3,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
          position: 'right',
          inverseOrder: true,
          offsetY: 50,
          markers: {
            radius: 2,
          },
        },
        tooltip: {
          enabled: true,
          theme: 'dark',
          x: {
            formatter(value) {
              return moment(value).format('DD/MM/YYYY')
            },
          },
        },
        yaxis: {
          show: true,
          title: {
            text: this.yTitle,
          },
          labels: {
            formatter(value) {
              return parseInt(value, 10)
            },
          },
        },
        colors: [
          '#55D058',
          '#B71C1C',
          '#8C9EFF',
          '#9c9c9c',
          '#e88300',
          '#071dad',
        ],
        title: {
          text: this.type,
          align: 'center',
        },
        chart: {
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0],
          style: {
            fontSize: '15px',
            colors: ['#55D058'],
          },
        },
        grid: {
          row: {
            colors: this.chartColor(),
            opacity: 0.1,
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          type: 'datetime',
          tickAmount: this.buildTickAmount(),
          labels: {
            formatter: (value, _, { i: index }) => {
              if (this.isChartSeparattedByMonth()) {
                return index
              }
              return this.getPatientAgeBy(moment(value).add(1, 'year'))
            },
          },
          title: {
            offsetY: 10,
            text: this.xaxisTitle(),
          },
          categories: this.chartDateRangeBy('year'),
        },
        stroke: {
          width: [5, 2, 2, 2, 2, 2],
        },
        annotations: {},
      },
    }
  },
  computed: {
    ...mapGetters({
      emrPatient: 'emr/patient',
    }),
    patientExamResults() {
      return this.emrPatient.examResults
    },
  },
  async mounted() {
    const BUILD_ANNOTATION_TIMER = 1000
    const BUILD_GRAPHIC_URIS_TIMER = 3000
    await this.verifyPatientExamResults()
    await this.setMetrics()
    this.insertPatientExamMetric()
    if (this.isChartSeparattedByMonth()) {
      setTimeout(() => {
        this.buildYearsAnnotation()
      }, BUILD_ANNOTATION_TIMER)
    }
    setTimeout(() => {
      this.setGraphicURI()
    }, BUILD_GRAPHIC_URIS_TIMER)
  },
  methods: {
    ...mapActions({
      getPatientExamResult: 'emr/getPatientExamResult',
      setChartsURI: 'emr/setChartsURI',
    }),
    verifyPatientExamResults() {
      if (isNil(this.emrPatient.examResults)) {
        return this.getPatientExamResult(this.emrPatient.id)
      }
      return null
    },
    setGraphicURI() {
      this.$refs.chartLine.chart.dataURI().then((uri) => {
        this.setChartsURI(uri)
      })
    },
    xaxisTitle() {
      return `${this.isChartSeparattedByMonth() ? 'Meses' : 'Anos'} de idade`
    },
    buildTickAmount() {
      if (this.isChartSeparattedByMonth()) {
        return (this.maxAge - this.minAge) * 12
      }
      return this.maxAge - this.minAge
    },
    buildYearsAnnotation() {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          annotations: {
            xaxis: map(
              (year) => {
                const patientAge = this.getPatientAgeBy(year)
                return {
                  x: new Date(year).getTime(),
                  strokeDashArray: 0,
                  borderColor: '#775DD0',
                  label: {
                    position: 'right',
                    offsetX: equals(patientAge, this.maxAge) ? 0 : 15,
                    borderColor: '#775DD0',
                    style: {
                      color: '#fff',
                      background: '#775DD0',
                    },
                    text: equals(patientAge, 0)
                      ? 'Nasc'
                      : `${patientAge} ano${gt(patientAge, 1) ? 's' : ''}`,
                  },
                }
              },
              this.chartDateRangeBy('year'),
            ),
          },
        },
      }
    },
    async insertPatientExamMetric() {
      const examData = {
        name: this.filterExam,
        data: union(this.getExamFromData(), map(
          exam => ({
            x: moment(exam.date).format('YYYY-MM-DD'),
            y: exam.value,
          }),
          this.getExams(),
        )),
      }
      this.series = [
        examData,
        ...(await this.getMetricsComparison()),
      ]
    },
    verifyIfExamFitsRange(examDate) {
      const minPatientYear = this.getPatientYearBy(this.minAge)
      const maxPatientYear = this.getPatientYearBy(this.maxAge)

      return and(
        moment(examDate).isSameOrAfter(minPatientYear, 'day'),
        moment(examDate).isSameOrBefore(maxPatientYear, 'day'),
      )
    },
    getExams() {
      return filter(
        exam => and(
          equals(exam.exam.id, this.examId),
          this.verifyIfExamFitsRange(exam.date),
        ),
        this.patientExamResults,
      )
    },
    getPatientAgeBy(date) {
      return moment(date).diff(this.getPatientYearBy(0), 'years')
    },
    getExamFromData() {
      return reject(isNil, map(
        (id) => {
          if (and(
            equals(id, this.examId),
            this.verifyIfExamFitsRange(this.exams[id].date),
          )) {
            return {
              x: moment(this.exams[id].date).format('YYYY-MM-DD'),
              y: this.exams[id].value,
            }
          }
          return null
        },
        keys(this.exams),
      ))
    },
    async getMetricsComparison() {
      const biologicalSexNormalized = {
        M: 'male',
        F: 'female',
      }

      const metrics = (await import(
        // eslint-disable-next-line prefer-template
        '../../../utils/graphicSources/WorldHealthOrganization/' + this.metric
      )).default
      const comparisons = metrics[this.type][biologicalSexNormalized[this.patient.biologicalSex]]
      const dateRange = this.chartDateRangeBy('month')
      const mapIndexed = addIndex(map)

      return map(
        type => ({
          name: type.name,
          data: mapIndexed(
            (metric, index) => ({
              x: dateRange[index],
              y: metric,
            }),
            type.data,
          ),
        }),
        comparisons,
      )
    },
    async setMetrics() {
      this.series = await this.getMetricsComparison()
    },
    getPatientYearBy(age) {
      return moment(this.patient.birthDate).add(age, 'years').format('YYYY-MM-DD')
    },
    getDateRange() {
      const momentRange = extendMoment(moment)
      const start = this.getPatientYearBy(this.minAge)
      const end = this.getPatientYearBy(this.maxAge)
      return momentRange.range(start, end)
    },
    chartDateRangeBy(type) {
      const range = this.getDateRange()
      const rangeFormatted = []
      for (const actualMonth of range.by(type)) {
        rangeFormatted.push(actualMonth.format('YYYY-MM-DD'))
      }
      return rangeFormatted
    },
    chartColor() {
      return equals(this.patient.biologicalSex, 'M') ? ['#a2c5ec'] : ['#fcb7e1']
    },
    isChartSeparattedByMonth() {
      return lte(this.maxAge, 5)
    },
  },
}
</script>
