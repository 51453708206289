<template>
  <div>
    <v-layout
      v-if="encounterData"
      :class="{
        'my-6 mx-2': row,
        'column': column,
      }"
    >
      <v-flex
        :class="{
          'card__infos-container--partial-width': row,
          'card__infos-container--full-width': column,
        }"
        column
      >
        <v-flex class="card__group-title">
          Motivo da Anotação
        </v-flex>
        <v-flex
          v-if="!hasEvaluation"
          shrink
        >
          Nenhum campo de avaliação foi preenchido.
        </v-flex>
        <v-flex
          v-for="(questionItem) of questionsItems"
          :key="questionItem.fieldName"
          shrink
          column
        >
          <div v-if="getHasQuestionItems(questionItem)">
            <v-flex class="card__infos-sub-title">
              {{ questionItem.label }}
            </v-flex>
            <v-flex
              v-for="(questionItemData, index) of questionItem.data"
              :key="index"
              class="card__infos-name"
            >
              • {{ questionItemData.name }} - {{ formatStatus(questionItemData) }}
            </v-flex>
          </div>
        </v-flex>
      </v-flex>
      <v-flex
        ml-6
        :class="{
          'card__infos-container--partial-width': row,
          'card__infos-container--full-width': column,
        }"
        column
      >
        <v-flex class="card__group-title">
          Prescrição
        </v-flex>
        <v-flex
          v-if="!treatments"
          shrink
        >
          Nenhuma prescrição.
        </v-flex>
        <v-flex
          v-if="treatments"
          column
        >
          <v-flex class="card__infos-sub-title">
            Novas prescrições
          </v-flex>
          <v-flex
            v-for="(treatment, index) in treatments"
            :key="index"
            class="card__infos-name"
          >
            • {{ treatment.name }} - {{ treatment.status }}
          </v-flex>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-divider
      v-if="row"
      class="mx-2"
    />
    <v-layout
      v-if="encounterData"
      :class="{
        'my-6 mb-2 mx-2': row,
        'column': column,
      }"
    >
      <v-flex
        :class="{
          'card__infos-container--partial-width': row,
          'card__infos-container--full-width': column,
        }"
        column
      >
        <v-flex class="card__group-title">
          Anotação
        </v-flex>
        <v-flex
          v-if="!evaluationAdditionalInformation"
          shrink
        >
          Nenhuma anotação.
        </v-flex>
        <v-flex class="card__infos-additional-information">
          <see-more
            :limit-length="limitAdditionalInformationLength"
            :text="evaluationAdditionalInformation"
          />
        </v-flex>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import SeeMore from '@/components/Common/SeeMore'
import {
  all,
  always,
  equals,
  filter,
  has,
  ifElse,
  isEmpty,
  isNil,
  not,
  pathOr,
  pipe,
  pluck,
  prop,
  propEq,
  propIs,
  toPairs,
  values,
} from 'ramda'
import { mapGetters } from 'vuex'

export default {
  components: {
    SeeMore,
  },
  props: [
    'encounterId',
    'encounterData',
    'row',
    'column',
  ],
  data() {
    return {
      limitAdditionalInformationLength: 850,
      questionsItems: {
        conditions: {
          label: 'Condições',
          data: [],
          hasValue: false,
        },
        clinicalBackground: {
          label: 'Antecedentes Clínicos',
          data: [],
          hasValue: true,
        },
        surgeries: {
          label: 'Antecedentes Cirúgicos',
          data: [],
          hasValue: true,
        },
        habits: {
          label: 'Hábitos',
          data: [],
          hasValue: true,
        },
        allergies: {
          label: 'Alergias',
          data: [],
          hasValue: true,
        },
        familyHistory: {
          label: 'Histórico Familiar',
          data: [],
          hasValue: true,
        },
        vaccines: {
          label: 'Vacinas',
          data: [],
          hasValue: true,
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      questions: 'emr/questions',
    }),
    hasConditions() {
      return not(isEmpty(this.conditions))
    },
    conditions() {
      return [
        ...pathOr([], [this.questions.conditions], this.encounterData),
        ...pipe(
          pathOr([], [this.questions.historyConditions]),
          filter(propEq(this.encounterId, 'endEncounterId')),
        )(this.encounterData),
      ]
    },
    hasEvaluation() {
      const questionsItemsData = pluck('data', values(this.questionsItems))
      return not(all(isEmpty)(questionsItemsData))
    },
    evaluationAdditionalInformation() {
      return this.getValue(this.encounterData[this.questions.evaluationAdditionalInformation])
    },
    treatments() {
      return this.encounterData[this.questions.treatments]
    },
  },
  mounted() {
    this.setQuestionsItemsData()
  },
  methods: {
    getConditions() {
      return [
        ...pathOr([], [this.questions.conditions], this.encounterData),
        ...pipe(
          pathOr([], [this.questions.historyConditions]),
          filter(propEq(this.encounterId, 'endEncounterId')),
        )(this.encounterData),
      ]
    },
    getHasQuestionItems(questionItem) {
      return not(isEmpty(questionItem.data))
    },
    getQuestionsItemData(item, fieldName) {
      if (equals('conditions', fieldName)) return this.getConditions()
      const questionId = this.questions[fieldName]
      const path = item.hasValue
        ? [questionId, 'value']
        : [questionId]

      return filter(
        propEq(this.encounterId, 'endEncounterId'),
        pathOr([], path, this.encounterData),
      )
    },
    getValue(data) {
      if (isNil(data)) return data
      if (has('value', data)) {
        return data.value
      }
      return data
    },
    formatStatus(condition) {
      return ifElse(
        propIs(String, 'deletedAt'),
        always('Excluído'),
        prop('status'),
      )(condition)
    },
    setQuestionsItemsData() {
      const questionsItemsValues = this.questionsItems
      for (const [key, questionItem] of toPairs(questionsItemsValues)) {
        this.questionsItems[key].data = this.getQuestionsItemData(questionItem, key)
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../../style/_core/colors.styl'

info-container-position(directionIsRow = true)
  if directionIsRow
    width 20%
  else
    margin-top 5%
    width 100%

.card__infos-container--full-width
  info-container-position(false)

.card__infos-container--partial-width
  info-container-position()

.card__infos-name
  color rgba(#000, 0.6)
  margin-top 5px
  margin-bottom 5px

.card__infos-additional-information
  font-style italic
  color rgba(#000, 0.5)

.card__group-title
  flex 0
  margin-bottom 1.2rem
  color $evening-sea
  font-size 1.2em

.card__infos-sub-title
  color black
  margin-bottom .4rem
</style>
