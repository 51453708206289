<template>
  <div>
    <router-view />
    <component-title />
    <patient-import-container />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import PatientImportContainer from './PatientImportContainer'

export default {
  name: 'PatientImportPage',
  components: {
    ComponentTitle,
    PatientImportContainer,
  },
}
</script>
