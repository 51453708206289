import axios from '@/lib/axios'

export default {
  state: {
    loadingHmo: false,
    hmos: [],
    hmoFilters: {},
  },
  mutations: {
    SET_LOADING_HMO(state, payload) {
      state.loadingHmo = payload
    },
    SET_HMOS(state, payload) {
      state.hmos = payload
    },
    SET_HMO_FILTERS(state, payload) {
      state.hmoFilters = payload
    },
  },
  actions: {
    listHmo({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_HMO', true)
        axios.get('/billing/contract', { params })
          .then(({ data: { contracts } }) => {
            commit('SET_HMOS', contracts)
            resolve(contracts)
          })
          .catch(reject)
          .finally(() => commit('SET_LOADING_HMO', false))
      })
    },
    setHmoFilters({ commit }, filters) {
      commit('SET_HMO_FILTERS', { ...filters })
    },
  },
  getters: {
    isLoadingHmo: state => state.loadingHmo,
    hmos: state => state.hmos,
    hmoFilters: state => state.hmoFilters,
  },
}
