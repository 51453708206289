export default {
  PRESENTIAL_TYPE: [
    'Consulta Presencial',
    'Demanda Espontânea',
  ],
  REMOTE_TYPE: [
    'Consulta Remota',
  ],
  NO_APPOINTMENT: [
    'Sem Agendamento',
  ],
  CORE_ORIGIN: [
    'telephoneReceptive',
    'uraReceptive',
    'whatsAppReceptive',
    'typeformReceptive',
    'telephoneActive',
    'whatsAppActive',
    'supportActive',
    'supportReceptive',
    'presentialActive',
    'agendaMirroring',
    'agenda',
    'smartAgenda',
  ],
  PORTAL_ORIGIN: [
    'portal',
    'portalMobile',
  ],
  PREGNANT_WARNING_CONDITIONS: [
    'O209',
    'O21',
    'O23',
    'O233',
    'O244',
    'O249',
    'O26',
    'O260',
    'W01',
    'W05',
    'W27',
    'W29',
    'W80',
    'W84',
    'Z32',
    'Z321',
    'Z34',
    'Z340',
    'Z349',
    'Z35',
    'Z359',
    'W78',
  ],
}
