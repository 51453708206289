<template>
  <div>
    <div class="filters-container">
      <v-container
        fluid
        class="px-5"
      >
        <v-row>
          <v-col
            cols="auto"
          >
            <v-autocomplete
              v-model="filters.hmoId"
              label="Operadora"
              clearable
              filled
              :items="hmosWithContracts"
              item-text="name"
              item-value="id"
            />
          </v-col>

          <v-col
            cols="auto"
          >
            <v-autocomplete
              v-model="filters.healthProductId"
              label="Plano"
              clearable
              filled
              :items="healthProducts"
              item-text="name"
              item-value="id"
            />
          </v-col>

          <v-col
            cols="auto"
          >
            <v-select
              v-model="filters.status"
              label="Selecione o Status do Contrato"
              :items="status"
              item-text="label"
              item-value="value"
              clearable
              filled
              multiple
              :menu-props="{ bottom: true, offsetY: true }"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-flex
      v-if="isLoading"
      class="contracts__loading"
    >
      <circular-loader />
    </v-flex>

    <v-container
      v-else
      fluid
      class="px-5"
    >
      <v-row
        v-if="!isActivePublishMultiple && hasAccessToEditContract"
        justify="end"
      >
        <v-col
          sm="6"
          lg="3"
        >
          <v-btn
            color="primary"
            small
            block
            data-testid="contract-list-container__publish-multiple--button"
            @click="activePublishMultiple"
          >
            Publicar Múltiplos Contratos
          </v-btn>
        </v-col>
      </v-row>
      <div v-else-if="isActivePublishMultiple && hasAccessToEditContract">
        <v-row justify="end">
          <v-col cols="2">
            <v-btn
              outlined
              small
              color="grey"
              block
              data-testid="contract-list-container__cancel-publish-multiple--button"
              @click="resetPublishMultiple"
            >
              Cancelar
            </v-btn>
          </v-col>

          <v-col cols="3">
            <v-btn
              color="primary"
              small
              block
              :loading="isLoading"
              data-testid="contract-list-container__handle-publish-multiple--button"
              @click="handlePublishMultipleContracts"
            >
              Publicar contratos
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          class="d-flex justify-end px-3 pb-2 pt-0"
        >
          <span
            data-testid="contract-list-container__publish-multiple-length--span"
            class="text-subtitle-2"
          >
            {{ lengthPublishMultipleModel }} contratos selecionados
          </span>
        </v-row>
      </div>
    </v-container>

    <v-expansion-panels
      v-for="hmo in filteredHmoToPublishMultiple"
      :key="hmo.name"
      fluid
      class="px-11 mb-4"
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          :data-testid="`hmo__${hmo.id}--panel`"
        >
          <v-row
            class="hmo-title__container"
          >
            <v-col
              class="d-flex align-center"
            >
              <h3>{{ hmo.name }}</h3>
            </v-col>

            <div class="d-flex mr-4">
              <v-col
                v-if="hasAccessToCreateAndEditHmoAndHealthProduct"
                cols="1"
                class="hmo-button"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      elevation="1"
                      v-on="on"
                      @click.stop="openCreateHealthProductDialog(hmo.id)"
                    >
                      <v-icon>mdi-package-variant-closed-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Cadastrar Novo Plano</span>
                </v-tooltip>
              </v-col>

              <v-col
                v-if="isWorkSpaceAmparo && hasAccessToEligibilityEdit"
                cols="1"
                class="hmo-button"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      elevation="1"
                      :data-testid="`configure-eligibility__${hmo.name}--button`"
                      v-on="on"
                      @click.stop="openEditHmoIntegrationDialog(hmo.id)"
                    >
                      <v-icon>mdi-clipboard-text</v-icon>
                    </v-btn>
                  </template>
                  <span>Elegibilidade</span>
                </v-tooltip>
              </v-col>
              <v-col
                v-if="hasAccessToCreateAndEditHmoAndHealthProduct"
                cols="1"
                class="hmo-button"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      elevation="1"
                      :data-testid="`edit-hmo__${hmo.name}--button`"
                      v-on="on"
                      @click.stop="openCreateHmoDialog(hmo.id)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar Operadora</span>
                </v-tooltip>
              </v-col>
            </div>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-expansion-panels
            v-model="selectedPanels[hmo.name]"
            accordion
            tile
          >
            <v-expansion-panel
              v-for="healthProduct in hmo.healthProducts"
              :key="healthProduct.id"
              class="mb-2"
              :data-testid="`contract-list-container__health-product-${healthProduct.id}--panel`"
              :readonly="isActivePublishMultiple"
            >
              <v-expansion-panel-header
                :hide-actions="isActivePublishMultiple"
              >
                <v-row>
                  <v-col
                    v-if="isActivePublishMultiple"
                    cols="1"
                    class="panel-header__checkbox d-flex align-center"
                  >
                    <v-checkbox
                      v-model="publishMultiple.model[healthProduct.id]"
                      hide-details
                      :data-testid="
                        `contract-list-container__health-product-${healthProduct.id}--checkbox`
                      "
                      class="pa-0 ma-0"
                    />
                  </v-col>

                  <v-col
                    class="d-flex pl-0 align-center"
                  >
                    <h3
                      class="panel-header-title my-1"
                    >
                      {{ healthProduct.name }}
                    </h3>
                  </v-col>

                  <v-col
                    v-if="hasAccessToCreateAndEditHmoAndHealthProduct"
                    cols="1"
                    class="panel-header__button__container mr-4"
                  >
                    <v-btn
                      class="mr-2 my-1"
                      small
                      :data-testid="`edit-health-product__${healthProduct.name}--button`"
                      @click.stop="openEditHealthProductDialog(
                        healthProduct.id
                      )"
                    >
                      Editar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>

              <v-expansion-panel-content
                class="panel-content"
              >
                <contract-list-item
                  :health-product="healthProduct"
                  :is-active-publish-multiple="isActivePublishMultiple"
                  @openCloneContractModal="openCloneContractModal"
                  @handleHealthProductContract="handleHealthProductContract"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <create-contract-options-modal
      v-if="showCreateContractOptionsModal"
      :model-contract="modelContract"
      @handleCreateContract="handleCreateContract"
      @closeCreateContractOptionsDialog="closeCreateContractOptionsDialog"
    />

    <clone-contract-modal
      v-if="clone.showModal"
      :contract-id="clone.contractId"
      :hmo-id="clone.hmoId"
      :health-product-id="clone.healthProductId"
      @handleCloneContract="handleCloneContract"
      @closeCloneContractModal="closeCloneContractModal"
    />

    <create-health-product
      v-if="showCreateHealthProductDialog"
      :hmo-id="hmoToEdit.id"
      :is-edit="isEditingHealthProduct"
      :health-product-data="healthProductToEdit "
      @closeDialog="closeCreateHealthProductDialog"
      @updateHmoList="updateHmoList"
    />

    <edit-hmo-integrations
      v-if="showEditHmoIntegrationDialog"
      edit-hmo
      :hmo-data="hmoToEdit"
      :hmo-list-filter="editHmoIntegrationsFilters"
      @closeDialog="closeEditHmoIntegrationDialog"
    />

    <create-hmo
      v-if="showCreateHmoDialog"
      edit-hmo
      :hmo-data="hmoToEdit"
      @closeCreateHmoDialog="closeCreateHmoDialog"
    />
  </div>
</template>

<script>
import CircularLoader from '@/components/UI/CircularLoader'
import {
  always,
  any,
  cond,
  equals,
  filter,
  find,
  flatten,
  groupBy,
  head,
  isNil,
  keys,
  length,
  map,
  pipe,
  prop,
  propEq,
  T,
  values,
} from 'ramda'
import { CONTRACT } from 'amparo-enums'
import { mapActions, mapGetters } from 'vuex'
import isNilOrEmpty from '@/utils/dataValidators'

export default {
  name: 'ContractList',
  components: {
    CircularLoader,
    CloneContractModal: () => import('./CloneContractModal'),
    ContractListItem: () => import('./ContractListItem'),
    CreateContractOptionsModal: () => import('./CreateContractOptionsModal'),
    CreateHealthProduct: () => import('@/pages/HmoList/CreateHealthProduct'),
    CreateHmo: () => import('@/pages/HmoList/CreateHmo'),
    EditHmoIntegrations: () => import('@/pages/HmoList/EditHmoIntegrations'),
  },
  data() {
    return {
      filters: {},
      healthProductFilter: {},
      healthProductToEdit: {},
      editHmoIntegrationsFilters: {},
      hmoToEdit: {},
      filteredHmos: {},
      isEditingHealthProduct: false,
      modelContract: null,
      notFoundMessage: 'Não encontrado',
      selectedPanels: {},
      showCreateContractOptionsModal: false,
      showCreateHealthProductDialog: false,
      showCreateHmoDialog: false,
      showEditHmoIntegrationDialog: false,
      status: values(CONTRACT.status),
      publishMultiple: {
        active: false,
        model: {},
      },
      clone: {
        contractId: null,
        hmoId: null,
        showModal: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      hmosWithContracts: 'healthMaintenanceOrganization/hmosWithContracts',
      isLoading: 'billing/isLoadingHealthProduct',
      isWorkSpaceAmparo: 'authentication/isWorkSpaceAmparo',
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
    }),
    hasAccessToEditContract() {
      return this.userHasAccessToFunctionality.contractEdit
    },
    hasAccessToCreateAndEditHmoAndHealthProduct() {
      return this.userHasAccessToFunctionality.listHmo
    },
    hasAccessToEligibilityEdit() {
      return this.userHasAccessToFunctionality.editHmoEligibility
    },
    isActivePublishMultiple() {
      return this.publishMultiple.active
    },
    lengthPublishMultipleModel() {
      return pipe(
        values,
        filter(equals(true)),
        length,
      )(this.publishMultiple.model)
    },
    selectedContractIds() {
      const healthProductIds = pipe(
        filter(equals(true)),
        keys,
      )(this.publishMultiple.model)

      return map(
        (id) => {
          const healthProduct = find(
            item => propEq(id, 'id', item),
            this.healthProducts,
          )

          const contract = find(
            item => propEq('approved', 'status', item),
            healthProduct.contracts,
          )

          return contract.id
        },
        healthProductIds,
      )
    },
    filteredHmoToPublishMultiple() {
      if (this.isActivePublishMultiple) {
        const hasApprovedHealthProduct = any(
          ({ contracts }) => any(
            propEq('approved', 'status'),
            contracts,
          ),
        )

        const filterApprovedHMOs = filter(
          ({ healthProducts }) => hasApprovedHealthProduct(healthProducts),
        )

        const filterApprovedHealthProducts = map(
          hmo => ({
            ...hmo,
            healthProducts: hasApprovedHealthProduct(hmo.healthProducts)
              ? hmo.healthProducts
              : null,
          }),
        )

        return pipe(
          filterApprovedHMOs,
          filterApprovedHealthProducts,
        )(this.hmosWithContracts)
      }

      return this.hmosWithContracts
    },
    healthProducts() {
      return flatten(
        map(
          prop('healthProducts'),
          this.hmosWithContracts,
        ),
      )
    },
  },
  watch: {
    filters: {
      deep: true,
      async handler() {
        this.setHmoFilters(this.filters)

        await this.listHmosWithContracts({
          hmoId: this.filters.hmoId,
          contractStatus: this.filters.status,
          healthProductId: this.filters.healthProductId,
        })

        if (!isNil(this.hmosWithContracts[0]?.name)) {
          this.editHmoIntegrationsFilters = {
            name: this.hmosWithContracts[0].name,
            contractStatus: this.filters.status,
          }
        }
      },
    },
  },
  async mounted() {
    await this.listHmosWithContracts()
  },
  methods: {
    ...mapActions({
      cloneContract: 'billing/cloneContract',
      createContract: 'billing/createContract',
      listHmosWithContracts: 'healthMaintenanceOrganization/listHmosWithContracts',
      publishMultipleContracts: 'billing/publishMultipleContracts',
      setHmoFilters: 'billing/setHmoFilters',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    closeCreateContractOptionsDialog() {
      this.modelContract = null
      this.showCreateContractOptionsModal = false
    },
    openCloneContractModal({ contractId, hmoId, healthProductId }) {
      this.clone = {
        contractId,
        hmoId,
        healthProductId,
        showModal: true,
      }
    },
    closeCloneContractModal() {
      this.clone = {
        contractId: null,
        hmoId: null,
        healthProductId: null,
        showModal: false,
      }
    },
    async handleCloneContract(attributes) {
      try {
        await this.cloneContract(attributes)

        this.setSnackbar({
          status: 'success',
          message: 'Os contratos serão criados em alguns instantes',
        })
      } catch (error) {
        this.setSnackbar({
          status: 'error',
          message: 'Não foi possível criar os contratos',
        })
      }

      this.resetPublishMultiple()
    },
    activePublishMultiple() {
      this.selectedPanels = {}
      this.publishMultiple.active = true
    },
    resetPublishMultiple() {
      this.publishMultiple = {
        active: false,
        model: {},
      }
    },
    async handlePublishMultipleContracts() {
      try {
        await this.publishMultipleContracts({
          contractIds: this.selectedContractIds,
        })

        this.setSnackbar({
          status: 'success',
          message: 'Os contratos serão publicados em alguns instantes',
        })
      } catch (error) {
        this.setSnackbar({
          status: 'error',
          message: 'Não foi possível publicar os contratos',
        })
      }

      this.resetPublishMultiple()
    },
    async handleCreateContract(newContractOption) {
      const modelContractObject = equals('modelContract', newContractOption)
        ? { contractId: this.modelContract.id }
        : {}

      const contract = await this.createContract({
        hmoId: this.modelContract.hmoId,
        healthProductId: this.modelContract.healthProductId,
        ...modelContractObject,
      })
      this.$router.push(`/billing/edit-contract/${contract.id}`)
      this.modelContract = null
      this.showCreateContractOptionsModal = false
    },
    async handleHealthProductContract(healthProduct) {
      const { actual, draft } = this.groupByContracts(healthProduct.contracts)

      if (!isNilOrEmpty(draft)) {
        const draftContract = head(draft)
        return this.$router.push(`/billing/edit-contract/${draftContract.id}`)
      }

      if (!isNilOrEmpty(actual)) {
        const actualContract = head(actual)
        this.modelContract = {
          ...actualContract,
          hmoId: healthProduct.healthMaintenanceOrganizationId,
          healthProductId: healthProduct.id,
        }
        this.showCreateContractOptionsModal = true
        // eslint-disable-next-line consistent-return
        return
      }

      const contract = await this.createContract({
        hmoId: healthProduct.healthMaintenanceOrganizationId,
        healthProductId: healthProduct.id,
      })
      return this.$router.push(`/billing/edit-contract/${contract.id}`)
    },
    groupByContracts(contracts) {
      return groupBy(
        cond([
          [
            propEq(CONTRACT.status.actual.value, 'status'),
            always(CONTRACT.status.actual.value),
          ],
          [
            propEq(CONTRACT.status.pending.value, 'status'),
            always(CONTRACT.status.pending.value),
          ],
          [
            propEq(CONTRACT.status.approved.value, 'status'),
            always(CONTRACT.status.approved.value),
          ],
          [
            propEq(CONTRACT.status.rejected.value, 'status'),
            always(CONTRACT.status.rejected.value),
          ],
          [
            propEq(CONTRACT.status.draft.value, 'status'),
            always(CONTRACT.status.draft.value),
          ],
          [
            propEq(CONTRACT.status.archived.value, 'status'),
            always(CONTRACT.status.archived.value),
          ],
          [T, always('rest')],
        ]),
        contracts,
      )
    },
    findHmoById(hmoId) {
      return find(propEq(hmoId, 'id'), this.hmosWithContracts)
    },
    async openCreateHealthProductDialog(hmoId) {
      this.hmoToEdit = this.findHmoById(hmoId)

      this.showCreateHealthProductDialog = true
    },
    async openEditHealthProductDialog(healthProductId) {
      this.healthProductToEdit = find(
        propEq(healthProductId, 'id'),
        this.healthProducts,
      )

      this.hmoToEdit.id = this.healthProductToEdit.healthMaintenanceOrganizationId

      this.isEditingHealthProduct = true
      this.showCreateHealthProductDialog = true
    },
    closeCreateHealthProductDialog() {
      this.isEditingHealthProduct = false
      this.showCreateHealthProductDialog = false
    },
    openEditHmoIntegrationDialog(hmoId) {
      this.hmoToEdit = this.findHmoById(hmoId)
      this.showEditHmoIntegrationDialog = true
    },
    closeEditHmoIntegrationDialog() {
      this.showEditHmoIntegrationDialog = false
    },
    openCreateHmoDialog(hmoId) {
      this.hmoToEdit = this.findHmoById(hmoId)
      this.showCreateHmoDialog = true
    },
    closeCreateHmoDialog(params) {
      this.showCreateHmoDialog = false
      if (params?.updateHmoData) {
        this.listHmosWithContracts()
      }
    },
    updateHmoList() {
      this.listHmosWithContracts()
    },
  },
}
</script>

<style lang='stylus' scoped>
.filters-container
  width 100%
  height auto
  background-color #e8f8e8
  height 108px

.contracts__loading
  margin-top 10px
  display flex
  justify-content center

.hmo-button
  max-width min-content !important

.panel-header__checkbox
  max-width 5.3%

.panel-header__button__container
  max-width 11.67% !important

.panel-header-title
  font-size 1.125rem
  font-weight 500

.hmo-title__container >>> h3
  font-size 1.125rem
  font-weight 600

.panel-content >>> .v-expansion-panel-content__wrap
  padding 0 0 16px !important
</style>
