import axios from '@/lib/axios'

export default {
  state: {
    loadingHealthProduct: false,
    healthProducts: [],
    hmoFilters: {},
  },
  mutations: {
    SET_LOADING_HEALTH_PRODUCT(state, payload) {
      state.loadingHealthProduct = payload
    },
    SET_HEALTH_PRODUCTS(state, payload) {
      state.healthProducts = payload
    },
    SET_HMO_FILTERS(state, payload) {
      state.hmoFilters = payload
    },
  },
  actions: {
    listHealthProduct({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_HEALTH_PRODUCT', true)
        axios.get('/billing/contract', { params })
          .then(({ data: { contracts } }) => {
            commit('SET_HEALTH_PRODUCTS', contracts)
            resolve(contracts)
          })
          .catch(reject)
          .finally(() => commit('SET_LOADING_HEALTH_PRODUCT', false))
      })
    },
    setHmoFilters({ commit }, filters) {
      commit('SET_HMO_FILTERS', { ...filters })
    },
  },
  getters: {
    isLoadingHealthProduct: state => state.loadingHealthProduct,
    healthProducts: state => state.healthProducts,
    hmoFilters: state => state.hmoFilters,
  },
}
