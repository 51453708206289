import axios from '@/lib/axios'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'
import { path } from 'ramda'

export default {
  state: {
    loadingItem: false,
    loadingItemAvailability: false,
    items: [],
    itemCount: 0,
    itemNumberOfPages: 0,
    itemPage: 1,
  },
  mutations: {
    SET_LOADING_ITEM(state, payload) {
      state.loadingItem = payload
    },
    SET_LOADING_ITEM_AVAILABILITY(state, payload) {
      state.loadingItemAvailability = payload
    },
    SET_ITEMS(state, payload) {
      state.items = payload
    },
    SET_ITEM_COUNT(state, payload) {
      state.itemCount = payload
    },
    SET_ITEM_NUMBER_OF_PAGES(state, payload) {
      state.itemNumberOfPages = payload
    },
    SET_ITEM_PAGE(state, payload) {
      state.itemPage = payload
    },
  },
  actions: {
    listItem({ commit }, params) {
      return new Promise((resolve, reject) => {
        const formattedParams = removeNilOrEmpty(params)
        commit('SET_LOADING_ITEM', true)
        axios.get('/billing/item', { params: formattedParams })
          .then(({ data }) => {
            if (path(['page'], params)) {
              commit('SET_ITEMS', data.itemList)
              commit('SET_ITEM_COUNT', data.count)
              commit('SET_ITEM_NUMBER_OF_PAGES', data.numberOfPages)
            }
            resolve(data)
          })
          .catch(reject)
          .finally(() => commit('SET_LOADING_ITEM', false))
      })
    },
    getItemAvailability({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_ITEM_AVAILABILITY', true)
        axios.get('/billing/item/availability', { params })
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
          .finally(() => commit('SET_LOADING_ITEM_AVAILABILITY', false))
      })
    },
    createItem({ commit }, attributes) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_ITEM', true)
        axios.post('/billing/item', attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
          .finally(() => commit('SET_LOADING_ITEM', false))
      })
    },
    updateItem({ commit }, { id, attributes }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_ITEM', true)
        axios.patch(`/billing/item/${id}`, attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
          .finally(() => commit('SET_LOADING_ITEM', false))
      })
    },
    restoreItem({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_ITEM', true)
        axios.patch(`/billing/item/${id}/restore`)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
          .finally(() => commit('SET_LOADING_ITEM', false))
      })
    },
    removeItem({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_ITEM', true)
        axios.delete(`/billing/item/${id}`)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
          .finally(() => commit('SET_LOADING_ITEM', false))
      })
    },
    createAgendaItemBulkUpdate({ commit }, attributes) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_ITEM', true)
        axios.post('/billing/agenda_item/bulk_update', attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
          .finally(() => commit('SET_LOADING_ITEM', false))
      })
    },
    setItemPage({ commit }, page) {
      commit('SET_ITEM_PAGE', page)
    },
  },
  getters: {
    isLoadingItem: state => state.loadingItem,
    items: state => state.items,
    itemCount: state => state.itemCount,
    itemNumberOfPages: state => state.itemNumberOfPages,
    itemPage: state => state.itemPage,
  },
}
