<template>
  <div>
    <component-title
      new-clinic
    />
    <clinic-list-container />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import ClinicListContainer from './ClinicListContainer'

export default {
  components: { ComponentTitle, ClinicListContainer },
}
</script>

<style>
</style>
