import axios from '@/lib/axios'

const initialState = {
  loading: false,
  patientRegistrations: {},
  patientRegistrationLength: 0,
}

const mutations = {
  SET_PATIENT_REGISTRATIONS(state, payload) {
    state.patientRegistrations = payload
  },
  SET_PATIENT_REGISTRATION_LENGTH(state, payload) {
    state.patientRegistrationLength = payload
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
}

const actions = {
  listPatientRegistration({ commit }, filters = {}) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.get('patient_registration', { params: filters })
        .then(({ data: patientRegistrations }) => {
          commit('SET_PATIENT_REGISTRATIONS', patientRegistrations)
          resolve(patientRegistrations)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  listPatientRegistrationLength({ commit }, filters = {}) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.get('patient_registration/length', { params: filters })
        .then(({ data: { count } }) => {
          commit('SET_PATIENT_REGISTRATION_LENGTH', count)
          resolve(count)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  updatePatientRegistration({ commit }, { id, attributes }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.patch(`patient_registration/${id}`, attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
}

const getters = {
  patientRegistrations: state => state.patientRegistrations,
  patientRegistrationLength: state => state.patientRegistrationLength,
  loading: state => state.loading,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
