<template>
  <div>
    <component-title immediate-care />
    <v-tabs
      v-model="activeTab"
      grow
      background-color="light-green"
      hide-slider
    >
      <v-tab
        v-for="({ title, disabled, badge }, index) in tabs"
        :key="index"
        :disabled="disabledTab({ disabled, badge }, index)"
        active-class="color: primary"
      >
        <span
          class="tab__title"
          :class="`${disabledTab({ disabled, badge }, index) ? 'tab__title--disabled' : ''}`"
        >
          {{ title }}
        </span>
        <div
          v-if="badge > 0"
          class="badge__content ml-2 d-flex justify-center"
        >
          {{ badge }}
        </div>
      </v-tab>
      <v-tab-item
        v-for="({ component }, index) in tabs"
        :key="index"
      >
        <component
          :is="component"
          :active-tab="activeTab"
          @changeTab="activeTab = $event"
        />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import {
  isEmpty,
  isNil,
} from 'ramda'
import {
  mapActions,
  mapGetters,
} from 'vuex'
import { useSound } from '@vueuse/sound'
import notifySound from '../../assets/notify-sound.mp3'

export default {
  name: 'ImmediateCarePage',
  components: {
    ComponentTitle: () => import('@/components/ComponentTitle'),
    PatientsQueueImmediateCare: () => import('./PatientsQueueImmediateCare'),
    PatientsActiveImmediateCare: () => import('./PatientsActiveImmediateCare'),
    PatientsHistoryImmediateCare: () => import('./PatientsHistoryImmediateCare'),
  },
  setup() {
    const { play } = useSound(notifySound)

    return {
      notify: play,
    }
  },
  data() {
    return {
      activeTab: 0,
      lastQueueLength: null,
      soundName: 'purr',
      tabs: [
        {
          title: 'Atender',
          component: 'PatientsQueueImmediateCare',
          badge: 0,
        },
        {
          title: 'Atendimento ativo',
          component: 'PatientsActiveImmediateCare',
          badge: 0,
          disabled: true,
        },
        {
          title: 'Histórico de pacientes',
          component: 'PatientsHistoryImmediateCare',
          badge: 0,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: 'authentication/user',
      queue: 'immediateCare/queue',
      activePatient: 'immediateCare/activePatient',
    }),
  },
  watch: {
    activePatient() {
      const activePatient = !isEmpty(this.activePatient) && !isNil(this.activePatient)
        ? 1
        : 0

      this.tabs[1].badge = activePatient
      if (activePatient === 0 && this.activeTab === 1) this.activeTab = 0
    },
    queue() {
      const { length } = this.queue
      this.tabs[0].badge = length
      if (length > this.lastQueueLength && !isNil(this.lastQueueLength)) this.notify()
      this.lastQueueLength = length
      console.log('immediate-care-queue-log', {
        immediateCareQueue: this.queue,
        user: this.user,
      })
    },
    activeTab() {
      this.getData()
    },
  },
  mounted() {
    this.init()
  },
  activated() {
    this.init()
  },
  deactivated() {
    clearInterval(this.getQueueActivePatientInterval)
    clearInterval(this.getProfessionalCareInterval)
  },
  methods: {
    ...mapActions({
      getQueue: 'immediateCare/getQueue',
      getActivePatient: 'immediateCare/getActivePatient',
      getStats: 'immediateCare/getStats',
    }),
    init() {
      this.getData()

      this.getProfessionalCareInterval = setInterval(() => {
        this.getQueue({ status: 'active' })
        this.getStats()
      }, 10000)

      this.getQueueActivePatientInterval = setInterval(() => {
        this.getActivePatient({ professionalId: this.user.professionalId })
      }, 30000)
    },
    getData() {
      this.getQueue({ status: 'active' })
      this.getActivePatient({ professionalId: this.user.professionalId })
      this.getStats()
    },
    disabledTab({ disabled, badge }, index) {
      return disabled && index !== this.activeTab && badge === 0
    },
  },
}
</script>

<style lang="stylus" scoped>
.tab__title
  color: #fff

.tab__title--disabled
  font-weight: bold
  color: rgba(255, 255, 255, 0.3)

.badge__content
  border-radius: 100%
  font-weight: bold
  color: #fff
  height: 16px
  width: 16px
  font-size: 12px
  background: red
</style>
