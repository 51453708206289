import { rest } from 'msw'
import baseUrl from '../../../src/utils/baseUrlTest'

export default [
  rest.get(baseUrl('/patient_registration/length'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      count: 0,
    }),
  )),
]
