<template>
  <div>
    <component-title>
      <v-btn
        class="btn mr-4"
        color="primary"
        outlined
      >
        Visualizar OS avulsas
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </v-btn>
      <v-btn
        color="primary"
        class="btn"
        @click="showCreateBillDialog = true"
      >
        Nova fatura
      </v-btn>
    </component-title>

    <bill-list />

    <create-bill-dialog
      :show-dialog="showCreateBillDialog"
      @update:showDialog="setShowCreateBillDialog()"
    />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import { mapActions } from 'vuex'
import CreateBillDialog from './CreateBillDialog'
import BillList from './BillList'

export default {
  components: {
    BillList,
    ComponentTitle,
    CreateBillDialog,
  },
  data() {
    return {
      showCreateBillDialog: false,
    }
  },
  async mounted() {
    await this.listHmo()
  },
  methods: {
    ...mapActions({
      listHmo: 'healthMaintenanceOrganization/listHmo',
    }),
    setShowCreateBillDialog() {
      this.showCreateBillDialog = !this.showCreateBillDialog
    },
  },
}
</script>
