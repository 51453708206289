import axios from '@/lib/axios'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'

const initialState = {
  loadingClinic: false,
  clinics: [],
}

const mutations = {
  SET_CLINICS(state, payload) {
    state.clinics = payload
  },
  SET_LOADING_CLINIC(state, payload) {
    state.loadingClinic = payload
  },
}

const actions = {
  createClinic({ commit }, attributes) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_CLINIC', true)
      axios.post('clinic', attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_CLINIC', false))
    })
  },
  updateClinic({ commit }, { id, attributes }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_CLINIC', true)
      axios.put(`clinic/${id}`, attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_CLINIC', false))
    })
  },
  listClinic({ commit }, filters = {}) {
    const params = removeNilOrEmpty(filters)
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_CLINIC', true)
      axios.get('clinic', { params })
        .then(({ data: { clinics } }) => {
          commit('SET_CLINICS', clinics)
          resolve(clinics)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING_CLINIC', false))
    })
  },
}

const getters = {
  clinics: state => state.clinics,
  loadingClinic: state => state.loadingClinic,
}

export default {
  state: initialState,
  mutations,
  actions,
  getters,
}
