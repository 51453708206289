import axios from '@/lib/axios'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'

const initialState = {
  queues: [],
  numberOfPages: 0,
  loadingErrors: false,
}

const mutations = {
  SET_QUEUES(state, payload) {
    state.queues = payload
  },
  SET_NUMBER_OF_PAGES(state, payload) {
    state.numberOfPages = payload
  },
  SET_LOADING_ERRORS(state, payload) {
    state.loadingErrors = payload
  },
}

const actions = {
  listQueues({ commit }, params) {
    return new Promise((resolve, rejectPromise) => {
      axios.get('importer/file_queue', {
        params: {
          importType: 'patient',
          ...removeNilOrEmpty(params),
        },
      })
        .then(({ data: queues }) => {
          commit('SET_QUEUES', queues.queues.rows)
          commit('SET_NUMBER_OF_PAGES', queues.queues.numberOfPages)
          resolve(queues)
        })
        .catch(rejectPromise)
    })
  },
  listErrors({ commit }, { importFileQueueId }) {
    commit('SET_LOADING_ERRORS', true)
    return new Promise((resolve, rejectPromise) => {
      const errors = []

      axios.get(`importer/file_queue/${importFileQueueId}/error`)
        .then(async ({ data }) => {
          errors.push(...data.errors)

          if (data.numberOfPages > 1) {
            for (let i = 2; i <= data.numberOfPages; i += 1) {
              // eslint-disable-next-line no-await-in-loop
              const { data: partialData } = await axios.get(`importer/file_queue/${importFileQueueId}/error`)
              errors.push(...partialData.errors)
            }
          }

          resolve(errors)
        })
        .catch(rejectPromise)
        .finally(() => commit('SET_LOADING_ERRORS', false))
    })
  },
  getExampleFile() {
    return new Promise((resolve, rejectPromise) => {
      axios.get('importer/patient/example_file')
        .then(({ data }) => {
          resolve(data)
        })
        .catch(rejectPromise)
    })
  },
}

const getters = {
  queues: state => state.queues,
  numberOfPages: state => state.numberOfPages,
  loadingErrors: state => state.loadingErrors,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
