<template>
  <div>
    <v-tabs
      grow
      height="58"
      background-color="light-green"
      hide-slider
    >
      <v-tab active-class="color: primary">
        <span class="tab__title font-weight-bold">agenda</span>
      </v-tab>
      <v-tab active-class="color: primary">
        <span class="tab__title font-weight-bold">bloqueio</span>
      </v-tab>
      <v-tab active-class="color: primary">
        <span class="tab__title font-weight-bold">destaque</span>
      </v-tab>
      <v-tab active-class="color: primary">
        <span class="tab__title font-weight-bold">restrições</span>
      </v-tab>
      <v-tab-item>
        <agenda-settings-items />
      </v-tab-item>
      <v-tab-item>
        <agenda-settings-blocks />
      </v-tab-item>
      <v-tab-item>
        <agenda-settings-spotlight />
      </v-tab-item>
      <v-tab-item>
        <agenda-settings-restrictions />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AgendaSettingsItems from './AgendaSettingsItems'
import AgendaSettingsBlocks from './AgendaSettingsBlocks'
import AgendaSettingsSpotlight from './AgendaSettingsSpotlight'
import AgendaSettingsRestrictions from './AgendaSettingsRestrictions'

export default {
  name: 'AgendaSettings',
  components: {
    AgendaSettingsItems,
    AgendaSettingsBlocks,
    AgendaSettingsSpotlight,
    AgendaSettingsRestrictions,
  },
  mounted() {
    this.listClinic()
    this.listHmo()
    this.listSpeciality()
  },
  methods: {
    ...mapActions({
      listClinic: 'clinic/listClinic',
      listHmo: 'healthMaintenanceOrganization/listHmo',
      listSpeciality: 'speciality/listSpeciality',
    }),
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.tab__title
  color #ffffff
</style>
