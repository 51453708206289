import { rest } from 'msw'
import baseUrl from '../../../../src/utils/baseUrlTest'
import specialityItemListData from '../../fixtures/billing/specialityItem/list'

export default [
  rest.get(baseUrl('/billing/speciality_item'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(specialityItemListData),
  )),
]
