<template>
  <div>
    <component-title
      new-permission
    />
    <permission-list-container />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import PermissionListContainer from './PermissionListContainer'

export default {
  components: { ComponentTitle, PermissionListContainer },
}
</script>

<style>

</style>
