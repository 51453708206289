import axios from '@/lib/axios'

export default {
  actions: {
    listOrderSabinShift(_, params) {
      return new Promise((resolve, reject) => {
        axios.get('/billing/order_sabin_shift', { params })
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
  },
}
