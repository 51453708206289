import axios from '@/lib/axios'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'
import {
  filter,
  isNil,
  path,
  propSatisfies,
} from 'ramda'

const initialState = {
  loadingRestriction: false,
  restrictions: [],
  restrictionFiltersData: {
    selectedClinic: null,
    selectedSpeciality: null,
    selectedProfessional: null,
  },
  restrictionFilters: {
    clinicId: null,
    specialityId: null,
    professionalId: null,
  },
  restrictionPage: 1,
  restrictionNumberOfPages: null,
  restrictionCount: null,
}

const mutations = {
  SET_RESTRICTIONS(state, payload) {
    state.restrictions = payload
  },
  SET_LOADING_RESTRICTION(state, payload) {
    state.loadingRestriction = payload
  },
  SET_RESTRICTION_FILTERS_DATA(state, payload) {
    state.restrictionFiltersData = payload
  },
  SET_RESTRICTION_FILTERS(state, payload) {
    state.restrictionFilters = payload
  },
  SET_RESTRICTION_PAGE(state, page) {
    state.restrictionPage = page
  },
  SET_RESTRICTION_NUMBER_OF_PAGES(state, numberOfPages) {
    state.restrictionNumberOfPages = numberOfPages
  },
  SET_RESTRICTION_COUNT(state, count) {
    state.restrictionCount = count
  },
}

const actions = {
  createRestriction({ commit }, attributes) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_RESTRICTION', true)
      axios.post('restriction', attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_RESTRICTION', false))
    })
  },
  updateRestriction({ commit }, { id, attributes }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_RESTRICTION', true)
      axios.patch(`restriction/${id}`, attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_RESTRICTION', false))
    })
  },
  listRestriction({ commit, state }) {
    const params = removeNilOrEmpty({
      ...state.restrictionFilters,
      page: state.restrictionPage,
    })
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_RESTRICTION', true)
      axios.get('restriction', { params })
        .then(({ data }) => {
          commit('SET_RESTRICTIONS', data.restrictions)
          commit('SET_RESTRICTION_NUMBER_OF_PAGES', data.numberOfPages)
          commit('SET_RESTRICTION_COUNT', data.count)
          resolve(data)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING_RESTRICTION', false))
    })
  },
  setRestrictionFiltersData({ commit }, data) {
    commit('SET_RESTRICTION_FILTERS_DATA', data)
    commit('SET_RESTRICTION_FILTERS', {
      clinicId: path(['id'], data.selectedClinic),
      specialityId: path(['id'], data.selectedSpeciality),
      professionalId: path(['id'], data.selectedProfessional),
    })
    commit('SET_RESTRICTION_PAGE', 1)
  },
  setRestrictionPage({ commit }, page) {
    commit('SET_RESTRICTION_PAGE', page)
  },
}

const getters = {
  restrictions: state => state.restrictions,
  availableRestrictions: state => filter(propSatisfies(isNil, 'storedAt'), state.restrictions),
  loadingRestriction: state => state.loadingRestriction,
  restrictionFilters: state => state.restrictionFilters,
  restrictionPage: state => state.restrictionPage,
  restrictionNumberOfPages: state => state.restrictionNumberOfPages,
  restrictionCount: state => state.restrictionCount,
}

export default {
  state: initialState,
  mutations,
  actions,
  getters,
}
