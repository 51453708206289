import axios from '@/lib/axios'
import { equals } from 'ramda'

const initialState = {
  attendancePatient: null,
  attendanceId: null,
  isInAttendance: null,
  showVirtualReceptionDialog: false,
  attendancesList: [],
  pages: 0,
  count: 0,
  openAttendances: 0,
}

const mutations = {
  SET_IS_IN_ATTENDANCE(state, value) {
    localStorage.setItem('isInAttendance', value)
    state.isInAttendance = value
  },
  SET_ATTENDANCE_PATIENT(state, value) {
    localStorage.setItem('attendancePatient', JSON.stringify(value))
    state.attendancePatient = value
  },
  SET_ATTENDANCE_ID(state, value) {
    localStorage.setItem('attendanceId', value)
    state.attendanceId = value
  },
  TOGGLE_VIRTUAL_RECEPTION_DIALOG(state) {
    state.showVirtualReceptionDialog = !state.showVirtualReceptionDialog
  },
  SET_ATTENDANCES_LIST(state, value) {
    state.attendancesList = value
  },
  SET_PAGES(state, value) {
    state.pages = value
  },
  SET_COUNT(state, value) {
    state.count = value
  },
  SET_OPEN_ATTENDANCES(state, value) {
    state.openAttendances = value
  },
}

const actions = {
  async getAttendancesList({ commit }, params) {
    const {
      data: {
        numberOfPages,
        count,
        virtualReceptions,
        openAttendances,
      },
    } = await axios.get('/virtual_reception', { params })
    commit('SET_ATTENDANCES_LIST', virtualReceptions)
    commit('SET_PAGES', numberOfPages)
    commit('SET_COUNT', count)
    commit('SET_OPEN_ATTENDANCES', openAttendances)
  },
  async updateAttendance(_, { data, attendanceId }) {
    return axios.put(`/virtual_reception/${attendanceId}`, data)
  },
  async reopenAttendance(_, { attendanceId }) {
    return axios.patch(`/virtual_reception/${attendanceId}/reopen`)
  },
  eraseAttendanceData({ commit }) {
    commit('SET_ATTENDANCE_PATIENT', null)
    commit('SET_IS_IN_ATTENDANCE', false)
    commit('SET_ATTENDANCE_ID', null)
    localStorage.removeItem('attendancePatient')
    localStorage.removeItem('isInAttendance')
    localStorage.removeItem('attendanceId')
  },
  goToAppointment({ commit }, { attendanceId, selectedPatient }) {
    commit('SET_IS_IN_ATTENDANCE', true)
    commit('TOGGLE_VIRTUAL_RECEPTION_DIALOG')
    commit('SET_ATTENDANCE_ID', attendanceId)
    commit('SET_ATTENDANCE_PATIENT', selectedPatient)
  },
  toggleVirtualReceptionDialog({ commit }) {
    commit('TOGGLE_VIRTUAL_RECEPTION_DIALOG')
  },
  setAttendancePatient({ commit }, payload) {
    commit('SET_ATTENDANCE_PATIENT', payload)
  },
  setIsInAttendance({ commit }, payload) {
    commit('SET_IS_IN_ATTENDANCE', payload)
  },
  setAttendanceId({ commit }, payload) {
    commit('SET_ATTENDANCE_ID', payload)
  },
}

const getters = {
  isUserInAttendance: state => (equals(state.isInAttendance, null)
    ? JSON.parse(localStorage.getItem('isInAttendance'))
    : state.isInAttendance),
  attendanceId: state => (equals(state.attendanceId, null)
    ? localStorage.getItem('attendanceId')
    : state.attendanceId),
  attendancePatient: state => (equals(state.attendancePatient, null)
    ? JSON.parse(localStorage.getItem('attendancePatient'))
    : state.attendancePatient),
  showVirtualReceptionDialog: state => state.showVirtualReceptionDialog,
  openAttendances: state => state.openAttendances,
  attendancesList: state => state.attendancesList,
  pages: state => state.pages,
  count: state => state.count,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
