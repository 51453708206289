import agenda from './routes/public/agenda'
import announcement from './routes/announcement'
import apiCep from './routes/cepApi'
import appointment from './routes/public/appointment'
import careHistory from './routes/careHistory'
import careTeam from './routes/careTeam'
import clinics from './routes/clinics'
import contract from './routes/contract'
import contractItem from './routes/contractItem'
import disease from './routes/disease'
import encounterPrescriptionHistory from './routes/encounterPrescriptionHistory'
import groups from './routes/group'
import healthMaintenanceOrganization from './routes/public/healthMaintenanceOrganization'
import healthProduct from './routes/public/healthProduct'
import patient from './routes/patient'
import patientRegistration from './routes/patientRegistration'
import professional from './routes/public/professional'
import referral from './routes/referral'
import room from './routes/public/room'
import session from './routes/session'
import slot from './routes/public/slot'
import speciality from './routes/public/speciality'
import order from './routes/billing/order'
import specialityItem from './routes/billing/specialityItem'
import user from './routes/user'

export default [
  ...agenda,
  ...announcement,
  ...apiCep,
  ...appointment,
  ...careHistory,
  ...careTeam,
  ...clinics,
  ...contract,
  ...contractItem,
  ...order,
  ...disease,
  ...encounterPrescriptionHistory,
  ...groups,
  ...healthMaintenanceOrganization,
  ...healthProduct,
  ...patient,
  ...patientRegistration,
  ...professional,
  ...referral,
  ...room,
  ...session,
  ...slot,
  ...speciality,
  ...speciality,
  ...specialityItem,
  ...user,
]
