import axios from '@/lib/axios'
import {
  find,
  isNil,
  omit,
  propEq,
} from 'ramda'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'
import isNilOrEmpty from '@/utils/dataValidators'

const initialState = {
  appointmentLogs: {},
  availableSlotsList: [],
  daysList: [],
  loadingDaysList: false,
  loadingDoctorSlots: false,
  selectedSlot: {},
  selectedSpeciliaty: null,
  slotsFilters: {},
}

const mutations = {
  SET_DAYS_LIST(state, payload) {
    state.daysList = payload
  },
  SET_AVAILABLE_SLOTS_LIST(state, payload) {
    state.availableSlotsList = payload
  },
  SET_SELECTED_SPECIALITY(state, payload) {
    state.selectedSpeciliaty = payload
  },
  SET_SLOTS_FILTER(state, payload) {
    state.slotsFilters = payload
  },
  SET_LOADING_DOCTOR_SLOTS(state, payload) {
    state.loadingDoctorSlots = payload
  },
  SET_LOADING_DAYS_LIST(state, payload) {
    state.loadingDaysList = payload
  },
  SET_APPOINTMENT_LOGS(state, payload) {
    state.appointmentLogs = payload
  },
  SET_SELECTED_SLOT(state, payload) {
    state.selectedSlot = payload
  },
}

const journeyIsExtended = (clinicId, journeys) => {
  if (isNilOrEmpty(journeys)) return true

  const journey = find(propEq(clinicId, 'clinicId'), journeys)

  const journeyOfClinic = isNil(journey)
    ? find(propEq(null, 'clinicId'), journeys)
    : journey

  return propEq(2, 'doctorSlots', journeyOfClinic)
}

const actions = {
  listDays({ commit }, params) {
    commit('SET_LOADING_DAYS_LIST', true)
    const queryParams = omit(['isRelatedToActivationJourney'], params)
    return new Promise((resolve, reject) => {
      axios.get('/slot/days', { params: queryParams })
        .then(({ data }) => {
          commit('SET_DAYS_LIST', data.days)
          resolve(data)
        })
        .catch(reject)
        .finally(() => {
          commit('SET_LOADING_DAYS_LIST', false)
        })
    })
  },
  listAvailableSlots({ commit }, params) {
    commit('SET_LOADING_DOCTOR_SLOTS', true)
    const { patientId } = params
    return new Promise((resolve, reject) => {
      const queryParams = removeNilOrEmpty(omit(['isRelatedToActivationJourney'], {
        ...params,
        patientId,
      }))

      axios.get('/slot/available', { params: queryParams })
        .then(({ data }) => {
          commit('SET_SLOTS_FILTER', params)
          commit('SET_AVAILABLE_SLOTS_LIST', data)
          resolve(data)
        })
        .catch(reject)
        .finally(() => {
          commit('SET_LOADING_DOCTOR_SLOTS', false)
        })
    })
  },
  verifyPostalActivation(_, patientId) {
    return new Promise((resolve, reject) => {
      axios.get(`/patient/${patientId}/verify_postal_activation`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
  setSelectedSpeciality({ commit }, name) {
    commit('SET_SELECTED_SPECIALITY', name)
  },
  setAppointmentLogs({ commit }, data) {
    commit('SET_APPOINTMENT_LOGS', data)
  },
  setSelectedSlot({ commit, state }, data) {
    const { appointmentLogs } = state

    if (
      appointmentLogs.isPatientOnActivationJourney
      && appointmentLogs.isFirstAppointmentAfterActivationJourney
      && appointmentLogs.isRelatedToActivationJourney
    ) {
      const shouldExtendedAppointment = journeyIsExtended(
        data.slot?.clinicId,
        appointmentLogs?.contract?.journeys,
      )
      commit('SET_APPOINTMENT_LOGS', {
        ...appointmentLogs,
        isExtendedAppointment: shouldExtendedAppointment,
        isExtendedAppointmentNotByItems: shouldExtendedAppointment,
      })
    }

    commit('SET_SELECTED_SLOT', data)
  },
  resetSearchValues({ commit }) {
    commit('SET_APPOINTMENT_LOGS', {})
    commit('SET_ORDER_AUTHORIZATION_CODE', null)
    commit('SET_AVAILABLE_SLOTS_LIST', null)
    commit('SET_DAYS_LIST', [])
    commit('SET_APPOINTMENT_LOGS', {})
    commit('SET_SELECTED_SLOT', {})
    commit('SET_SELECTED_SPECIALITY', null)
    commit('SET_SLOTS_FILTER', {})
  },
}

const getters = {
  availableSlotsList: state => state.availableSlotsList,
  cardsFilters: state => state.cardsFilters,
  daysList: state => state.daysList,
  isLoadingDaysList: state => state.loadingDaysList,
  isLoadingDoctorSlots: state => state.loadingDoctorSlots,
  selectedSpeciliaty: state => state.selectedSpeciliaty,
  slotsFilters: state => state.slotsFilters,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
