import axios from '@/lib/axios'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'

const initialState = {
  loadingRoom: false,
  rooms: [],
}

const mutations = {
  SET_ROOMS(state, payload) {
    state.rooms = payload
  },
  SET_LOADING_ROOM(state, payload) {
    state.loadingRoom = payload
  },
}

const actions = {
  createRoom({ commit }, attributes) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_ROOM', true)
      axios.post('room', attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_ROOM', false))
    })
  },
  updateRoom({ commit }, { id, attributes }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_ROOM', true)
      axios.patch(`room/${id}`, attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_ROOM', false))
    })
  },
  listRoom({ commit }, filters = {}) {
    const params = removeNilOrEmpty(filters)
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_ROOM', true)
      axios.get('room', { params })
        .then(({ data: { rooms } }) => {
          commit('SET_ROOMS', rooms)
          resolve(rooms)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING_ROOM', false))
    })
  },
}

const getters = {
  rooms: state => state.rooms,
  loadingRoom: state => state.loadingRoom,
}

export default {
  state: initialState,
  mutations,
  actions,
  getters,
}
