export default {
  assistanceTypes: ['otherAssistance', 'followup', 'telemonitoring'],
  administrativeTypes: [
    'coachingSession',
    'otherAdministrative',
    'medicalAppointment',
    'nursingAppointment',
    'nutritionistAppointment',
    'pharmaceuticalAppointment',
    'psychologistAppointment',
    'specialistAppointment',
    'teamMeeting',
  ],
  PRIORITY_LIST: {
    1: {
      number: 1,
      title: 'Verde',
    },
    2: {
      number: 2,
      title: 'Amarela',
    },
    3: {
      number: 3,
      title: 'Vermelha',
    },
  },
}
