import Vue from 'vue'
import axios from '@/lib/axios'
import router from '@/router'
import {
  clone,
  defaultTo,
  equals,
  head,
  includes,
  isNil,
  last,
  map,
  not,
  path,
  prop,
  split,
} from 'ramda'
import {
  isWorkSpaceAmparo,
  isWorkSpaceSabin,
  hasAccessToRoute,
  hasAccessToFormSettings,
  hasAccessToCommunicationSettings,
  hasAccessToCareLineAssign,
  hasAccessToModule,
  MODULE as PERMISSION_MODULE,
} from '@/utils/routesAccess'
import { PROFESSION } from 'amparo-enums'

export default {
  state: {
    user: {},
    hasAcceptedTerms: false,
    token: null,
    isLogining: false,
    isSubmitting: false,
    permissionAdmin: 'MASTER',
    externalRoutes: ['/login', '/new-password'],
  },
  mutations: {
    clearBearerToken() {
      if (axios.defaults.headers.common.Authorization) {
        delete axios.defaults.headers.common.Authorization
      }
    },
    clearAuthentication(state) {
      state.user = {}
      state.token = null
      localStorage.clear()
      document.cookie = '_session=; Max-Age=0'
    },
    setHasAcceptedTerms(state) {
      if (isNil(path(['permission', 'termId'], state.user))) {
        state.hasAcceptedTerms = true
        return state.hasAcceptedTerms
      }
      if (state.user.termsLog) {
        state.hasAcceptedTerms = equals(
          state.user.termsLog.current.id,
          state.user.permission.termId,
        )
      } else {
        state.hasAcceptedTerms = false
      }
      return state.hasAcceptedTerms
    },
    setBearerToken(state) {
      axios.defaults.headers.common.Authorization = `Bearer ${state.token}`
    },
    setLocalStorageToState(state, { user, token }) {
      Vue.set(state, 'user', user)
      if (token) state.token = token
    },
    setIsSubmitting(state) {
      state.isSubmitting = !state.isSubmitting
    },
  },
  actions: {
    clearAuthentication({ commit }) {
      commit('clearAuthentication')
      commit('clearBearerToken')
    },
    setDataToLocalStorage(_, data) {
      localStorage.setItem('_user', JSON.stringify(data.user))
      if (data.token) {
        localStorage.setItem('_session', JSON.stringify(data.token))
        document.cookie = `_session=${
          data.token
        }; expires=Thu, 16-Jul-2023 21:25:05 GMT;domain=.amparo.tech;path=/;`
      }
    },
    setLocalStorageToState({ commit }, data) {
      commit('setLocalStorageToState', data)
    },
    setHasAcceptedTerms({ commit }) {
      commit('setHasAcceptedTerms')
    },
    switchUserWorkSpace({ dispatch }, workSpaceId) {
      axios
        .patch('/user/profile/work_space', { workSpaceId })
        .then(res => dispatch('setDataToLocalStorage', res.data))
        .then(() => dispatch('getLocalStorageData'))
        .then(() => router.go(0))
        .catch(err => err)
    },
    checkTokenValidation({ dispatch, commit }) {
      axios
        .get('/session')
        .then(res => dispatch('setDataToLocalStorage', res.data))
        .then(() => dispatch('getLocalStorageData'))
        .then(() => commit('setBearerToken'))
        .catch(err => err)
    },
    getLocalStorageData({ dispatch }) {
      const user = JSON.parse(localStorage.getItem('_user'))
      const token = JSON.parse(localStorage.getItem('_session'))
      dispatch('setLocalStorageToState', { user, token })
    },
    userLogin({ dispatch }, userData) {
      this.state.isLogining = true
      return new Promise((resolve, reject) => {
        axios
          .post('/session', {
            email: userData.email,
            password: userData.password,
          })
          .then((res) => {
            dispatch('setDataToLogin', res.data)
            dispatch(
              'advanced/setImpersonation',
              prop('impersonation', res.data),
              { root: true },
            )
            resolve(res.data)
          })
          .catch(reject)
          .finally(() => {
            this.state.isLogining = false
          })
      })
    },
    async setDataToLogin({ dispatch, commit }, session) {
      await dispatch('setDataToLocalStorage', session)
      await dispatch('getLocalStorageData')
      await commit('setBearerToken')
      await commit('setHasAcceptedTerms')
      router.push('/')
    },
    userLogout({ state, dispatch }) {
      const { token } = state
      return axios
        .delete('/session', { data: { token } })
        .then(() => {
          dispatch('clearAuthentication')
          router.push('/login')
        })
        .catch(err => err)
    },
    trySetDataOnReload({ commit, dispatch }) {
      dispatch('getLocalStorageData')
      commit('clearBearerToken')
      commit('setBearerToken')
    },
    userNewPassword({ commit }, { email, token, password }) {
      commit('setIsSubmitting')
      return new Promise((resolve, reject) => {
        axios
          .patch('/recovery_password', { email, token, password })
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
          .finally(() => {
            commit('setIsSubmitting')
          })
      })
    },
    requestNewPassword({ commit }, { email }) {
      commit('setIsSubmitting')
      return new Promise((resolve, reject) => {
        axios
          .post('/recovery_password', { email })
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
          .finally(() => {
            commit('setIsSubmitting')
          })
      })
    },
    changePassword({ commit }, { currentPassword, newPassword }) {
      commit('setIsSubmitting')
      return new Promise((resolve, reject) => {
        axios
          .patch('user/profile/password', {
            currentPassword,
            newPassword,
          })
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
          .finally(() => {
            commit('setIsSubmitting')
          })
      })
    },
  },
  getters: {
    userAllowedModules({ user }) {
      const modules = clone(path(['permission', 'modules'], user)) || []

      return modules.map(({ id }) => id)
    },
    userAllowedRoutes({ user }) {
      const legacy = clone(path(['permission', 'policy', 'routes'], user)) || []
      const modules = clone(path(['permission', 'modules'], user)) || []
      const routes = [].concat(legacy, ...map(prop('routes'), modules))

      const userAllowedRoutes = {}
      for (const { route, methods } of routes) {
        if (userAllowedRoutes[route]) {
          userAllowedRoutes[route].methods.push(...methods)
        } else {
          userAllowedRoutes[route] = { route, methods }
        }
      }

      return userAllowedRoutes
    },
    userHasAccessToFunctionality({ user }, getters) {
      const isExternalUser = defaultTo(true, path(['permission', 'policy', 'userInformation', 'isExternalUser'], user))

      const isNurseUser = () => {
        if (isNil(user.professionId)) return false

        return equals(user.professionId, PROFESSION.nurse)
      }

      const isDoctorUser = () => {
        if (isNil(user.professionId)) return false

        return equals(user.professionId, PROFESSION.doctor)
      }

      const {
        userAllowedRoutes,
        userAllowedModules,
      } = getters
      return {
        agenda: hasAccessToRoute(userAllowedRoutes, '/slot', ['GET']),
        attachment: hasAccessToRoute(userAllowedRoutes, '/emr/attachment', ['POST']),
        listPatient: hasAccessToRoute(userAllowedRoutes, '/patient', ['GET']),
        createPatient: hasAccessToRoute(userAllowedRoutes, '/patient', ['POST']),
        editPatient: hasAccessToRoute(userAllowedRoutes, '/patient', ['PUT']),
        agendaSettings: hasAccessToRoute(userAllowedRoutes, '/agenda', ['POST']),
        importPatient: hasAccessToRoute(userAllowedRoutes, '/importer/patient', ['POST']),
        importProfessional: hasAccessToRoute(userAllowedRoutes, '/importer/professional', [
          'POST',
        ]),
        scheduleList: hasAccessToRoute(userAllowedRoutes, '/emr/schedule', ['GET']),
        newAnnouncement: hasAccessToRoute(userAllowedRoutes, '/announcement', ['POST']),
        listProfessionals: hasAccessToRoute(userAllowedRoutes, '/professional', ['POST']),
        listUsers: hasAccessToRoute(userAllowedRoutes, '/user', ['POST']),
        listClinics: hasAccessToRoute(userAllowedRoutes, '/clinic', ['POST']),
        listHmo:
          hasAccessToRoute(userAllowedRoutes, '/health_maintenance_organization', ['POST'])
          && hasAccessToRoute(userAllowedRoutes, '/health_product', ['POST']),
        editHmo:
          hasAccessToRoute(userAllowedRoutes, '/health_maintenance_organization', ['POST'])
          && hasAccessToRoute(userAllowedRoutes, '/health_product', ['POST'])
          && !isWorkSpaceAmparo(user.workSpaceId),
        editHmoEligibility:
          hasAccessToRoute(userAllowedRoutes, '/health_maintenance_organization', ['PATCH']),
        listPermission: hasAccessToRoute(userAllowedRoutes, '/permission', ['POST']),
        listPermissionUserCreation: hasAccessToRoute(userAllowedRoutes, '/permission', [
          'GET',
        ]),
        attendanceReport: hasAccessToRoute(userAllowedRoutes, '/report', ['GET']),
        virtualReception:
          hasAccessToRoute(userAllowedRoutes, '/virtual_reception', [
            'GET',
            'PUT',
            'POST',
            'PATCH',
          ]) && isWorkSpaceAmparo(user.workSpaceId),
        patientAudit:
          hasAccessToRoute(userAllowedRoutes, '/patient_registration', ['GET', 'PATCH'])
          && isWorkSpaceAmparo(user.workSpaceId),
        careTeam:
          hasAccessToRoute(userAllowedRoutes, '/care_team', ['GET'])
          && isWorkSpaceAmparo(user.workSpaceId),
        careTeamSettings:
          hasAccessToRoute(userAllowedRoutes, '/care_team', ['GET', 'POST'])
          && isWorkSpaceAmparo(user.workSpaceId),
        careTeamScore: hasAccessToRoute(
          userAllowedRoutes,
          '/care_team/max_score',
          ['PUT'],
        ),
        formSettings: hasAccessToFormSettings(
          user,
          userAllowedRoutes,
        ),
        emr: hasAccessToRoute(userAllowedRoutes, '/emr/encounter', ['GET']),
        emrForm: hasAccessToRoute(userAllowedRoutes, '/emr/form_patient', [
          'GET',
          'PATCH',
        ]),
        emrPatientDoctorNotes: hasAccessToRoute(
          userAllowedRoutes,
          '/emr/patient_medical_resume',
          ['PATCH'],
        ),
        communicationSettings: hasAccessToCommunicationSettings(
          user,
          userAllowedRoutes,
        ),
        careLineSettings: hasAccessToRoute(
          userAllowedRoutes,
          '/emr/care_line',
          ['GET', 'POST', 'PUT'],
        ),
        careLineAssigns: hasAccessToCareLineAssign(
          user,
          userAllowedRoutes,
        ),
        careLinePatientAssign: hasAccessToRoute(
          userAllowedRoutes,
          '/emr/care_line_patient',
          ['POST'],
        ),
        careCycleSettings: hasAccessToRoute(
          userAllowedRoutes,
          '/emr/care_cycle',
          ['GET', 'PATCH'],
        ),
        patientRadar:
          hasAccessToRoute(userAllowedRoutes, '/patient/radar', ['GET'])
          && isWorkSpaceAmparo(user.workSpaceId),
        performanceDashboard: hasAccessToRoute(
          userAllowedRoutes,
          '/dashboard/performance_panel',
          ['POST'],
        ),
        auditPanel: hasAccessToRoute(
          userAllowedRoutes,
          '/dashboard/audit_panel',
          ['POST'],
        ),
        auditPanelAsManager: hasAccessToRoute(
          userAllowedRoutes,
          '/dashboard/audit_panel_manager',
          ['POST'],
        ),
        outcomePanel: hasAccessToRoute(
          userAllowedRoutes,
          '/dashboard/outcome_panel',
          ['POST'],
        ),
        outcomePanelAsManager: hasAccessToRoute(
          userAllowedRoutes,
          '/dashboard/outcome_panel_manager',
          ['POST'],
        ),
        walletPanel: hasAccessToRoute(userAllowedRoutes, '/dashboard/wallet_panel', [
          'POST',
        ]),
        attendancePanel: hasAccessToRoute(userAllowedRoutes, '/dashboard/attendance_panel', [
          'POST',
        ]),
        investigationPanel: hasAccessToRoute(userAllowedRoutes, '/dashboard/investigation_panel', [
          'POST',
        ]),
        clinicalDashboard:
          hasAccessToRoute(userAllowedRoutes, '/dashboard/clinical_panel', ['POST'])
          && isWorkSpaceAmparo(user.workSpaceId),
        populationPanel:
          hasAccessToRoute(userAllowedRoutes, '/dashboard/population_panel', ['POST'])
          && isWorkSpaceAmparo(user.workSpaceId),
        operationalPanel:
          hasAccessToRoute(userAllowedRoutes, '/dashboard/operational_panel', ['POST'])
          && isWorkSpaceAmparo(user.workSpaceId),
        patientJourneyPanel:
          hasAccessToRoute(userAllowedRoutes, '/dashboard/patient_journey_panel', [
            'POST',
          ]) && isWorkSpaceAmparo(user.workSpaceId),
        appointmentPanel: hasAccessToRoute(userAllowedRoutes, '/dashboard/appointment_panel', ['POST']),
        careLineTags: hasAccessToRoute(userAllowedRoutes, '/emr/care_line_tag_patient', [
          'POST',
          'PATCH',
        ]),
        careLineAvailableTag: hasAccessToRoute(
          userAllowedRoutes,
          '/emr/care_line_tag/available',
          ['GET'],
        ),
        doctorImmediateCare: hasAccessToRoute(userAllowedRoutes, '/tele/immediate_care', [
          'PATCH',
          'GET',
        ]) && isDoctorUser(),
        nurseImmediateCare: hasAccessToRoute(userAllowedRoutes, '/tele/immediate_care', [
          'PATCH',
          'GET',
        ]) && isNurseUser(),
        getContract: hasAccessToRoute(userAllowedRoutes, '/billing/contract', ['GET']),
        financesPage: hasAccessToRoute(userAllowedRoutes, '/billing/order', [
          'GET',
          'POST',
          'PUT',
          'PATCH',
          'DELETE',
        ]) && isExternalUser === false,
        contractList: hasAccessToModule(userAllowedModules, PERMISSION_MODULE.contractVisualization)
          || hasAccessToModule(userAllowedModules, PERMISSION_MODULE.contractRevision)
          || hasAccessToModule(userAllowedModules, PERMISSION_MODULE.contractEdition),
        diseaseGroupPage: hasAccessToRoute(userAllowedRoutes, '/billing/group', [
          'GET',
          'POST',
          'PUT',
          'DELETE',
        ]) && hasAccessToRoute(userAllowedRoutes, '/billing/disease_group', [
          'DELETE',
        ]) && hasAccessToRoute(userAllowedRoutes, '/billing/contract_item_group', [
          'GET',
        ]),
        contractEdit:
          hasAccessToRoute(userAllowedRoutes, '/billing/contract', ['POST', 'PUT'])
          && hasAccessToRoute(userAllowedRoutes, '/billing/contract/pend', ['PATCH'])
          && hasAccessToRoute(userAllowedRoutes, '/billing/contract/publish', ['PATCH'])
          && hasAccessToRoute(userAllowedRoutes, '/billing/contract/archive', ['PATCH']),
        contractReview:
          hasAccessToRoute(userAllowedRoutes, '/billing/contract/approve', ['PATCH'])
          && hasAccessToRoute(userAllowedRoutes, '/billing/contract/reject', ['PATCH']),
        itemList:
          hasAccessToRoute(userAllowedRoutes, '/billing/item', ['POST'])
          || hasAccessToRoute(userAllowedRoutes, '/billing/item_mnemonic', ['POST']),
        appointmentList: hasAccessToRoute(
          userAllowedRoutes,
          '/appointment',
          ['GET'],
        ),
        prescriptionList: hasAccessToRoute(
          userAllowedRoutes,
          '/emr/encounter_prescription_history',
          ['GET'],
        ),
        medicalFollowUp: !getters.isWorkSpaceSabin,
        manageUser: hasAccessToRoute(userAllowedRoutes, '/advanced/user', ['GET', 'PATCH']),
        userFeedbackList: hasAccessToRoute(userAllowedRoutes, '/user_feedback', ['GET']),
        openEncounters: hasAccessToRoute(userAllowedRoutes, '/emr/encounter', ['GET', 'POST']),
        preferredNetwork: hasAccessToRoute(userAllowedRoutes, '/preferred_network', ['GET']),
        editPreferredNetwork:
          hasAccessToRoute(userAllowedRoutes, '/preferred_network', ['GET', 'PUT', 'POST', 'DELETE'])
          && hasAccessToRoute(userAllowedRoutes, '/preferred_network_professional', ['GET', 'PUT', 'POST', 'DELETE']),
        preferredNetworkSms: hasAccessToRoute(userAllowedRoutes, '/preferred_network_referral', ['POST'])
          && hasAccessToRoute(userAllowedRoutes, '/preferred_network_professional', ['GET']),
        activationJourneyEdit: hasAccessToRoute(userAllowedRoutes, '/emr/activation_journey', ['PUT']),
        billList: hasAccessToRoute(userAllowedRoutes, '/billing/bill', ['GET', 'POST']),
        defaultEditBill: hasAccessToRoute(userAllowedRoutes, '/billing/bill', ['GET', 'POST', 'PUT', 'DELETE']),
        adminEditBill: hasAccessToRoute(userAllowedRoutes, '/billing/bill/admin', ['GET', 'POST', 'PUT', 'DELETE']),
        financeEditBill: hasAccessToRoute(userAllowedRoutes, '/billing/bill/finance', ['GET', 'POST', 'PUT', 'DELETE']),
      }
    },
    isUserLogged: state => not(isNil(state.token)),
    isAnExternalRoute: state => route => includes(route, state.externalRoutes),
    user: (state) => {
      const names = split(' ', state.user.name)
      const firstAndLastName = names.length > 1
        ? `${head(names)} ${last(names)}`
        : state.user.name

      return {
        firstAndLastName,
        ...state.user,
      }
    },
    isLogining: state => state.isLogining,
    isSubmitting: state => state.isSubmitting,
    token: state => state.token,
    hasAcceptedTerms: state => state.hasAcceptedTerms,
    isWorkSpaceAmparo: state => isWorkSpaceAmparo(path(['user', 'workSpaceId'], state)),
    isWorkSpaceSabin: state => isWorkSpaceSabin(path(['user', 'workSpaceId'], state)),
  },
}
