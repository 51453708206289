import axios from '@/lib/axios'

const actions = {
  listDiseases(__, params = {}) {
    return new Promise((resolve, reject) => {
      axios.get('/emr/disease', {
        params,
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
}

export default {
  namespaced: true,
  actions,
}
