<template>
  <div
    v-if="shouldShowFollowUpRefuseInfo"
  >
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-chip
          disabled
          class="medical-follow-up-info-chip"
          color="error"
          outlined
          v-on="on"
        >
          Acompanhamento Recusado
        </v-chip>
      </template>
      <span>Motivo da recusa: {{ patient.medicalFollowUpRefuseReason }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import {
  prop,
  find,
  propEq,
} from 'ramda'
import patientEnuns from '@/enums/patient'
import { mapGetters } from 'vuex'

const { FOLLOWING_ACCEPTATION_TERM } = patientEnuns

export default {
  props: {
    patient: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
    }),
    shouldShowFollowUpRefuseInfo() {
      const refusedTerms = [
        prop('REFUSED_APP', FOLLOWING_ACCEPTATION_TERM),
        prop('REFUSED_PORTAL', FOLLOWING_ACCEPTATION_TERM),
      ]
      return this.userHasAccessToFunctionality.medicalFollowUp
        && find(propEq(this.patient.medicalFollowUpStatus, 'value'))(refusedTerms)
    },
  },
}

</script>

<style lang="stylus" scoped>

.medical-follow-up-info-text
  text-align center
  padding-top 10px
  padding-bottom 10px

.medical-follow-up-info-chip
  margin-left 0.25rem
  margin-top 0.25rem
  height 45px
</style>
