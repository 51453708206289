<template>
  <div>
    <v-form
      ref="form"
      class="login-form"
      @submit.prevent="submitRequestPassword"
    >
      <v-text-field
        v-model="email"
        label="E-mail"
        clearable
        required
        filled
        :error-messages="$v.email.$error ? 'Email inválido' : ''"
        :error="$v.email.$error"
        @input="$v.email.$touch()"
        @change="closeSnackbar"
      />
      <v-flex>
        <v-btn
          :disabled="$v.$error || !email || isSubmitting"
          :loading="isSubmitting"
          type="submit"
          color="primary"
          class="font-weight-lightbold"
          large
          block
        >
          Enviar
        </v-btn>
      </v-flex>
      <v-flex
        justify-center
        mt-5
      >
        <v-btn
          class="btn"
          to="/login"
          color="primary"
          text
        >
          Voltar
        </v-btn>
      </v-flex>
    </v-form>
    <v-dialog
      v-model="emailDialog"
      max-width="420"
    >
      <v-card>
        <v-card-title class="card-dialog__title">
          Solicitação enviada!
          <v-spacer />
          <v-btn
            icon
            @click="emailDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="card-dialog__content">
          <p>
            Em alguns instantes chegará um e-mail para o endereço
            <strong>{{ email.toLowerCase() }}</strong>.
          </p>
          <p>
            Esta requisição de senha irá expirar em <strong>24 horas.</strong>
          </p>
          <p>
            Caso não tenha recebido, verifique se inseriu o e-mail correto.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-flex justify-center>
            <v-btn
              class="btn btn-primary"
              to="/login"
            >
              Ok!
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'RequestNewPasswordForm',
  data() {
    return {
      email: '',
      emailDialog: false,
    }
  },
  validations() {
    const emailValidation = {
      email: { required, email },
    }
    return { ...emailValidation }
  },
  computed: {
    ...mapGetters({
      isSubmitting: 'authentication/isSubmitting',
    }),
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
      closeSnackbar: 'snackbar/closeSnackbar',
      requestNewPassword: 'authentication/requestNewPassword',
    }),
    submitRequestPassword() {
      this.$v.$touch()
      if (this.$v.$error) return
      this.requestNewPassword({
        email: this.email,
      })
        .then(() => {
          this.emailDialog = true
        })
        .catch(() => {
          this.setSnackbar({
            status: 'error',
            message: 'Erro interno',
          })
        })
    },
  },
}
</script>

<style lang="stylus" scoped>
.card-dialog__content
  display flex
  flex-direction column
  text-align center
  justify-content center
  align-items center

.login-form
  background-color: #fff
  width: 98%
  margin: auto
  margin-bottom: 20px
  display: flex
  flex-direction: column
  justify-content: center
  box-shadow: 0 0 20px -4px rgba(78, 85, 89, 0.3)
  padding: 35px
  border-radius: 6px
  @media only screen and (min-width: 600px)
    width: 500px

.v-btn--large
  height 56px
  border-radius 8px
  letter-spacing 1px
</style>
