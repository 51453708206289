export default {
  referrals: [
    {
      id: 'ref_cjyhqeooo000vapvo4c4berqb',
      name: 'Outros',
    },
    {
      id: 'ref_cjyhqfo07000xapvohqbj5emi',
      name: 'Internet',
    },
    {
      id: 'ref_cjyhqfod40010apvo1hry1fig',
      name: 'Operadora',
    },
  ],
}
