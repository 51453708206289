<template>
  <div>
    <component-title
      emr
      is-new-encounter
    />
    <div class="d-flex">
      <v-flex sm9>
        <emr-form-builder
          :should-show-all-warnings="shouldShowAllWarnings"
        />
      </v-flex>
      <v-flex sm3>
        <emr-resume :is-inside-encounter="true" />
      </v-flex>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ComponentTitle from '@/components/ComponentTitle'
import EmrResume from '@/components/Common/EmrResume'
import EmrFormBuilder from './EmrFormBuilder'

export default {
  components: {
    ComponentTitle,
    EmrResume,
    EmrFormBuilder,
  },
  data() {
    return {
      shouldShowAllWarnings: false,
    }
  },
  provide() {
    return {
      providerTurnOnAllQuestionsWarnings: this.showAllWarnings,
    }
  },
  async activated() {
    await this.setOpenedEncountersBroadcastChannel(() => {
      const { id: patientId } = this.$route.params
      this.$router.push(`/emr/${patientId}`)
    })
  },
  methods: {
    ...mapActions({
      setOpenedEncountersBroadcastChannel: 'emr/setOpenedEncountersBroadcastChannel',
    }),
    showAllWarnings() {
      this.shouldShowAllWarnings = !this.shouldShowAllWarnings
    },
  },
}
</script>
