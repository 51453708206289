<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <span
        class="activator-title"
        v-on="on"
      >
        Códigos das Clinicas
      </span>
    </template>
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>Códigos das Clinicas</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container
        :class="{
          'container--fluid': true,
          'px-12': $vuetify.breakpoint.smAndUp,
        }"
      >
        <template>
          <v-data-table
            :headers="headers"
            :items="clinics"
            :footer-props="{
              'items-per-page-options': [30],
              'items-per-page-text': '',
            }"
            item-key="id"
            no-data-text="Nenhuma profissão encontrada"
          >
            <template v-slot:item="clinic">
              <tr>
                <td>
                  {{ clinic.item.id }}
                </td>
                <td>
                  {{ clinic.item.name }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProfessionCodeList',
  data() {
    return {
      headers: [
        { text: 'Código', value: 'id' },
        { text: 'Nome', value: 'name' },
      ],
      dialog: false,
    }
  },
  computed: {
    ...mapGetters({
      clinics: 'clinic/clinics',
    }),
  },
  mounted() {
    this.listClinic()
  },
  methods: {
    ...mapActions({
      listClinic: 'clinic/listClinic',
    }),
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.activator-title
  font-family Montserrat
  font-size 14px
  line-height 1.5
  letter-spacing 0.5px
  color #55d058
  cursor pointer
</style>
