import axios from '@/lib/axios'

export default {
  state: {
    careLineDiseases: [],
  },
  mutations: {
    SET_CARE_LINE_DISEASES(state, payload) {
      state.careLineDiseases = payload
    },
  },
  actions: {
    getCareLineByDisease(_, diseaseId) {
      return new Promise((resolve, reject) => {
        axios.get('emr/care_line_disease', {
          params: { diseaseId },
        })
          .then(({ data: { careLineDiseases } }) => resolve(careLineDiseases))
          .catch(reject)
      })
    },
    listCareLineDisease({ commit, state }, params = {}) {
      return new Promise((resolve, reject) => {
        axios.get('emr/care_line_disease', {
          params: {
            ...params,
            careLineId: state.careLine.id,
          },
        })
          .then(({ data: { careLineDiseases } }) => {
            commit('SET_CARE_LINE_DISEASES', careLineDiseases)
            resolve(careLineDiseases)
          })
          .catch(reject)
      })
    },
    createCareLineDisease({ state }, attributes) {
      return new Promise((resolve, reject) => {
        axios.post('emr/care_line_disease', {
          diseases: attributes,
          careLineId: state.careLine.id,
        })
          .then(({ data }) => resolve(data))
          .catch(reject)
      })
    },
    deleteCareLineDisease(_, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`emr/care_line_disease/${id}`)
          .then(({ data }) => resolve(data))
          .catch(reject)
      })
    },
  },
  getters: {
    careLineDiseases: state => state.careLineDiseases,
  },
}
