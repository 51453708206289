import { rest } from 'msw'
import baseUrl from '../../../src/utils/baseUrlTest'
import careTeamData from '../fixtures/careTeamData'

export default [
  rest.get(baseUrl('/care_team'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(careTeamData),
  )),
]
