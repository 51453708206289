import axios from '@/lib/axios'
import {
  isNil,
  isEmpty,
  either,
  reject,
  append,
} from 'ramda'

const initialState = {
  loading: false,
  careTeams: [],
  patients: [],
  count: 0,
  numberOfPages: 0,
}

const mutations = {
  SET_CARE_TEAMS(state, payload) {
    state.careTeams = payload
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_PATIENTS(state, payload) {
    state.patients = payload
  },
  SET_COUNT(state, payload) {
    state.count = payload
  },
  SET_NUMBER_OF_PAGES(state, payload) {
    state.numberOfPages = payload
  },
}

const actions = {
  createCareTeam({ commit }, attributes) {
    return new Promise((resolve, rejectPromise) => {
      commit('SET_LOADING', true)
      axios.post('care_team', attributes)
        .then(resolve)
        .catch(rejectPromise)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  updateCareTeam({ commit }, { id, attributes }) {
    return new Promise((resolve, rejectPromise) => {
      commit('SET_LOADING', true)
      axios.put(`care_team/${id}`, attributes)
        .then(resolve)
        .catch(rejectPromise)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  updateCareTeamScores({ commit }, { careTeamIds }) {
    return new Promise((resolve, rejectPromise) => {
      commit('SET_LOADING', true)
      axios.patch('care_team/scores/', { careTeamIds })
        .then(resolve)
        .catch(rejectPromise)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  updateMaxScoreCareTeam({ commit }, { id, maxScore }) {
    return new Promise((resolve, rejectPromise) => {
      commit('SET_LOADING', true)
      axios.put(`care_team/max_score/${id}`, { maxScore })
        .then(resolve)
        .catch(rejectPromise)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  migratePatientsCareTeam({ commit }, { id, attributes }) {
    return new Promise((resolve, rejectPromise) => {
      commit('SET_LOADING', true)
      axios.post(`care_team/migrate_patients/${id}`, attributes)
        .then(resolve)
        .catch(rejectPromise)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  listCareTeam({ commit }, filters = {}) {
    const params = reject(either(isNil, isEmpty), filters)
    return new Promise((resolve, rejectPromise) => {
      commit('SET_LOADING', true)
      axios.get('care_team', { params })
        .then(({ data: careTeamList }) => {
          commit('SET_CARE_TEAMS', careTeamList)
          resolve(careTeamList)
        })
        .catch(rejectPromise)
        .finally(() => commit('SET_LOADING', false))
    })
  },
}

const getters = {
  careTeams: state => state.careTeams,
  careTeamsWithNone: state => append({
    name: 'Sem Equipe',
    id: '',
  }, state.careTeams),
  loading: state => state.loading,
  patients: state => state.patients,
  count: state => state.count,
  numberOfPages: state => state.numberOfPages,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
