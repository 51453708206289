import axios from '@/lib/axios'

export default {
  actions: {
    async listBills(_, params) {
      return new Promise((resolve, reject) => {
        axios.get('/billing/bill', { params })
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    async createBill(_, attributes) {
      return new Promise((resolve, reject) => {
        axios.post('/billing/bill', attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    async updateBill(_, { id, attributes }) {
      return new Promise((resolve, reject) => {
        axios.put(`/billing/bill/${id}`, attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
  },
}
