<template>
  <div
    v-if="shouldAppearBadge"
    class="badge"
    :style="settings"
  >
    <span
      v-if="number"
      class="badge__content"
    >
      {{ handleNumber }}
    </span>
    <v-icon
      v-if="icon"
      class="badge__content"
    >
      {{ icon }}
    </v-icon>
  </div>
</template>
<script>
import {
  gt,
  or,
} from 'ramda'

export default {
  props: {
    number: {
      type: Number,
      required: false,
      default: 0,
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '#c42e00',
    },
    color: {
      type: String,
      required: false,
      default: '#fff',
    },
    size: {
      type: String,
      required: false,
      default: '2.7rem',
    },
  },
  computed: {
    shouldAppearBadge() {
      return or(this.icon, gt(this.number, 0))
    },
    handleNumber() {
      const MAX_BADGE_NUMBER = 99
      return gt(this.number, MAX_BADGE_NUMBER)
        ? `${MAX_BADGE_NUMBER}+`
        : this.number
    },
    settings() {
      return {
        backgroundColor: this.backgroundColor,
        color: this.color,
        width: this.size,
        height: this.size,
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.badge
  width 2.7rem
  height @width
  display flex
  justify-content center
  align-items center
  font-size 100%
  border-radius 100%
  border-width 3px

.badge__content
  width 90%
</style>
