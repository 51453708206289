export default {
  DOCTOR: {
    name: 'Médico',
    id: 'profession_cjz3an5zk00030nxbejbsg6wj',
    itemId: {
      presencial: 'ite_cl44iaubz000377zu6jy17vst',
      remote: 'ite_cl44iaubz000277zu4tcq20kf',
    },
  },
  PSYCHOLOGIST: {
    name: 'Psicologo',
    id: 'profession_cjzbz9pgk000hb0vogejnajuo',
    itemId: {
      presencial: 'ite_cl44iauc0000777zu10hm71d7',
      remote: 'ite_cl44iauc0000677zu03lxalcz',
    },
  },
  NUTRITIONIST: {
    name: 'Nutricionista',
    id: 'profession_cjzbz1g7t000gb0vo907k3kv3',
    itemId: {
      presencial: 'ite_cl44iaubz000577zu3ry48vxl',
      remote: 'ite_cl44iaubz000477zubc6364mb',
    },
  },
  NURSE: {
    name: 'Enfermeira',
    id: 'profession_cjz3an5zj00020nxbgcea9qh5',
    itemId: {
      presencial: 'ite_cl44iaubz000177zuaqb0dkpk',
      remote: 'ite_cl44iaubu000077zu8hcodjgm',
    },
  },
}
