<template>
  <v-flex class="pagination">
    <v-pagination
      :value="page"
      :length="pages"
      :total-visible="VISIBLE_PAGES"
      @input="changePage($event)"
    />
    <span class="pagination__total">
      Total: {{ count }}
    </span>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ItemListContainerPagination',
  data() {
    return {
      VISIBLE_PAGES: 5,
    }
  },
  computed: {
    ...mapGetters({
      page: 'billing/itemPage',
      pages: 'billing/itemNumberOfPages',
      count: 'billing/itemCount',
    }),
  },
  methods: {
    ...mapActions({
      setItemPage: 'billing/setItemPage',
    }),
    changePage(page) {
      this.setItemPage(page)
      this.$emit('changePage', page)
    },
  },
}
</script>

<style lang="stylus" scoped>
.pagination
  display flex
  justify-content center
  align-items center

.pagination__total
  position absolute
  right 10%
</style>
