export default
[
  {
    id: 'gro_clmkmebbn00022inr8d4merj7',
    name: 'Grupo-1',
    diseaseGroups: [
      {
        id: 'dis_gro_clmkmebbq00032inr9zfi84kk',
        groupId: 'gro_clmkmebbn00022inr8d4merj7',
        diseaseId: 'dis_ck1tmzbri005nl0pqbxww13qn',
        disease: {
          id: 'dis_ck1tmzbri005nl0pqbxww13qn',
          name: 'Neoplasia maligna do timo',
          version: 'CID10',
          code: 'C37',
        },
      },
      {
        id: 'dis_gro_clmkmebbr00042inr245e1fa7',
        groupId: 'gro_clmkmebbn00022inr8d4merj7',
        diseaseId: 'dis_ck1tmzbtv01d2l0pqdfkkgvzm',
        disease: {
          id: 'dis_ck1tmzbtv01d2l0pqdfkkgvzm',
          name: 'Exposição a outros fatores ambientais artificiais e aos não especificados',
          version: 'CID10',
          code: 'W99',
        },
      },
    ],
  },
  {
    id: 'gro_clmkmel6500052inr1e3623cc',
    name: 'Grupo-2',
    diseaseGroups: [
      {
        id: 'dis_gro_clmkmel6800062inr22l72lma',
        groupId: 'gro_clmkmel6500052inr1e3623cc',
        diseaseId: 'dis_ck1tmzbsy0126l0pq8zveae5z',
        disease: {
          id: 'dis_ck1tmzbsy0126l0pq8zveae5z',
          name: 'Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal',
          version: 'CID10',
          code: 'R85',
        },
      },
      {
        id: 'dis_gro_clmkmel6800072inrdhguear7',
        groupId: 'gro_clmkmel6500052inr1e3623cc',
        diseaseId: 'dis_ck1tmzbse00ncl0pq2ae59nq4',
        disease: {
          id: 'dis_ck1tmzbse00ncl0pq2ae59nq4',
          name: 'Outras doenças do aparelho digestivo',
          version: 'CID10',
          code: 'K92',
        },
      },
    ],
  },
]
