<template>
  <div>
    <router-view />
    <component-title />
    <professional-import-container />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import ProfessionalImportContainer from './ProfessionalImportContainer'

export default {
  name: 'ProfessionalImportPage',
  components: {
    ComponentTitle,
    ProfessionalImportContainer,
  },
}
</script>
