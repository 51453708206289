import category from './category'
import form from './form'
import question from './question'
import formQuestion from './formQuestion'
import calculatorCondition from './calculatorCondition'
import calculatorConditionItem from './calculatorConditionItem'
import calculatorEquation from './calculatorEquation'

const state = {
  ...category.state,
  ...form.state,
  ...question.state,
}

const mutations = {
  ...category.mutations,
  ...form.mutations,
  ...question.mutations,
}

const actions = {
  ...category.actions,
  ...form.actions,
  ...question.actions,
  ...formQuestion.actions,
  ...calculatorCondition.actions,
  ...calculatorConditionItem.actions,
  ...calculatorEquation.actions,
}

const getters = {
  ...category.getters,
  ...form.getters,
  ...question.getters,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
