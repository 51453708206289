<template>
  <v-container
    fluid
    class="px-12 filters-container"
  >
    <v-row
      class="py-4"
    >
      <v-col
        cols="2"
      >
        <v-text-field
          v-model="professionalName"
          disabled
          filled
          clearable
          hide-details
          label="Profissional"
        />
      </v-col>

      <v-col
        cols="2"
      >
        <vc-date-picker
          v-model="filter.range"
          locale="pt-PT"
          is-range
          color="green"
        >
          <template v-slot="{ inputEvents }">
            <v-text-field
              :value="dateRangeText"
              readonly
              label="Data"
              append-icon="mdi-calendar"
              filled
              clearable
              hide-details
              v-on="inputEvents.start"
              @click:clear="filter.range = {}"
            />
          </template>
        </vc-date-picker>
      </v-col>

      <v-col
        cols="2"
        offset="6"
      >
        <v-btn
          class="white-text font-weight-bold btn-filter--height"
          color="dark-green"
          block
          large
          @click="updateFilter"
        >
          <v-icon left>
            mdi-magnify
          </v-icon>
          Filtrar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  equals,
  isEmpty,
  isNil,
  join,
  mapObjIndexed,
  reject,
  values,
} from 'ramda'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'AttendanceFilterItens',
  data: () => ({
    professionalName: null,
    filter: {
      professionalId: null,
      range: {
        start: moment().startOf('day'),
        end: moment().endOf('day'),
      },
    },
  }),
  computed: {
    ...mapGetters({
      user: 'authentication/user',
    }),
    dateRangeText() {
      if (isNil(this.formatedDatesToShow)) return ''

      if (equals(
        this.formatedDatesToShow.start,
        this.formatedDatesToShow.end,
      )) {
        return this.formatedDatesToShow.start
      }

      return join(' a ')(values(this.formatedDatesToShow))
    },
    formatedDatesToShow() {
      if (isNil(this.filter.range)) return ''

      return mapObjIndexed(
        (date) => {
          if (isNil(date)) return ''
          return moment(date).format('DD/MM/YYYY')
        },
        this.filter.range,
      )
    },
  },
  created() {
    this.loadFilters()
  },
  methods: {
    loadFilters() {
      const { id, name } = this.user.professional
      this.professionalName = name
      this.filter.professionalId = id
    },
    updateFilter() {
      this.filter = reject(isEmpty, this.filter)
      this.filter.range = this.formatDateToSend()
      this.$emit('filter', this.filter)
    },
    formatDateToSend() {
      return mapObjIndexed(
        date => moment(date).format('YYYY-MM-DD'),
        this.filter.range,
      )
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.v-btn.v-btn--large
  margin-top 0px
  height 56px
  border-radius 8px

.filters-container
  background-color #e8f8e8
</style>
