export default [
  {
    id: 'spe_ite_cll3t6rd1002zw4zu7b6pby9x',
    itemId: 'ite_cl007kxw00003lvzucdddgnt0',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw00003lvzucdddgnt0',
      name: 'ABSCESSO DE UNHA (DRENAGEM) - TRATAMENTO CIRÚRGICO',
      amparoCode: 4,
    },
  },
  {
    id: 'spe_ite_cll3t6rd10030w4zu0kgi24p0',
    itemId: 'ite_cl007kxw00004lvzuc2ry1ead',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw00004lvzuc2ry1ead',
      name: 'AGULHAMENTO DE PONTOS GATILHO',
      amparoCode: 5,
    },
  },
  {
    id: 'spe_ite_cll3t6rd10032w4zu34i1hymu',
    itemId: 'ite_cl007kxw10006lvzu4ni7cwke',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw10006lvzu4ni7cwke',
      name: 'CALOSIDADE E/OU MAL PERFURANTE - DESBASTAMENTO (POR LESÃO)',
      amparoCode: 7,
    },
  },
  {
    id: 'spe_ite_cll3t6rd10033w4zuhgl5gux3',
    itemId: 'ite_cl007kxw10007lvzucliw6cwc',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw10007lvzucliw6cwc',
      name: 'CANTOPLASTIA LATERAL',
      amparoCode: 8,
    },
  },
  {
    id: 'spe_ite_cll3t6rd10034w4zu37qn0ota',
    itemId: 'ite_cl007kxw10008lvzufvc31967',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw10008lvzufvc31967',
      name: 'CANTOPLASTIA UNGUEAL',
      amparoCode: 9,
    },
  },
  {
    id: 'spe_ite_cll3t6rd10036w4zuc9vb2x98',
    itemId: 'ite_cl007kxw1000alvzuayke6db2',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw1000alvzuayke6db2',
      name: 'CAUTERIZAÇÃO QUÍMICA (POR GRUPO DE ATÉ 5 LESÕES)',
      amparoCode: 11,
    },
  },
  {
    id: 'spe_ite_cll3t6rd20038w4zu9mrj3fa9',
    itemId: 'ite_cl007kxw1000blvzu9mo306ra',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw1000blvzu9mo306ra',
      name: 'CERUMEN - REMOÇÃO (BILATERAL)',
      amparoCode: 12,
    },
  },
  {
    id: 'spe_ite_cll3t6rd20039w4zucnb405za',
    itemId: 'ite_cl007kxw1000clvzu91t725kt',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw1000clvzu91t725kt',
      name: 'COLETA DE CITOLOGIA ONCÓTICA',
      amparoCode: 13,
    },
  },
  {
    id: 'spe_ite_cll3t6rd2003cw4zu2yeega1p',
    itemId: 'ite_cl007kxw1000dlvzu393s06nk',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw1000dlvzu393s06nk',
      name: 'COLETA DE MATERIAL CÉRVICO-VAGINAL',
      amparoCode: 14,
    },
  },
  {
    id: 'spe_ite_cll3t6rd2003gw4zuee8c3r74',
    itemId: 'ite_cl007kxw1000flvzu6wqzfxbm',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw1000flvzu6wqzfxbm',
      name: 'CURATIVO DE OUVIDO (CADA)',
      amparoCode: 16,
    },
  },
  {
    id: 'spe_ite_cll3t6rd2003hw4zud898b7df',
    itemId: 'ite_cl007kxw1000glvzu7vk9ayri',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw1000glvzu7vk9ayri',
      name: 'CURATIVO DE QUEIMADURAS - POR UNIDADE TOPOGRÁFICA (UT) AMBULATORIAL',
      amparoCode: 17,
    },
  },
  {
    id: 'spe_ite_cll3t6rd2003lw4zuekdyc723',
    itemId: 'ite_cl007kxw1000ilvzuh486cfq9',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw1000ilvzuh486cfq9',
      name: 'DESBRIDAMENTO CIRÚRGICO DE FERIDAS OU EXTREMIDADES',
      amparoCode: 19,
    },
  },
  {
    id: 'spe_ite_cll3t6rd2003nw4zu8hhu1niu',
    itemId: 'ite_cl007kxw1000jlvzu04bx77ou',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw1000jlvzu04bx77ou',
      name: 'DRENAGEM DE ABSCESSO',
      amparoCode: 20,
    },
  },
  {
    id: 'spe_ite_cll3t6rd3003qw4zu3m9t34bj',
    itemId: 'ite_cl007kxw2000llvzub4q7fx9s',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw2000llvzub4q7fx9s',
      name: 'EPISTAXE - TAMPONAMENTO ANTERIOR',
      amparoCode: 22,
    },
  },
  {
    id: 'spe_ite_cll3t6rd3003rw4zu1a77guke',
    itemId: 'ite_cl007kxw2000mlvzu4vb24fvc',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw2000mlvzu4vb24fvc',
      name: 'EPISTAXE - TAMPONAMENTO ANTERO-POSTERIOR',
      amparoCode: 23,
    },
  },
  {
    id: 'spe_ite_cll3t6rd3003sw4zugkvs6p12',
    itemId: 'ite_cl007kxw2000nlvzub6c4gyf5',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw2000nlvzub6c4gyf5',
      name: 'EXÉRESE DE LESÃO / TUMOR DE PELE E MUCOSAS',
      amparoCode: 24,
    },
  },
  {
    id: 'spe_ite_cll3t6rd3003uw4zu4vl29857',
    itemId: 'ite_cl007kxw2000olvzu3kun87pa',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw2000olvzu3kun87pa',
      name: 'EXERESE DE LIPOMAS',
      amparoCode: 25,
    },
  },
  {
    id: 'spe_ite_cll3t6rd3003ww4zuf5ioar5l',
    itemId: 'ite_cl007kxw2000plvzu8qeddiz3',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw2000plvzu8qeddiz3',
      name: 'EXÉRESE DE UNHA',
      amparoCode: 26,
    },
  },
  {
    id: 'spe_ite_cll3t6rd3003yw4zu7dpkh1kl',
    itemId: 'ite_cl007kxw2000qlvzu8gir8q0e',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw2000qlvzu8gir8q0e',
      name: 'EXÉRESE E SUTURA SIMPLES DE PEQUENAS LESÕES (POR GRUPO DE ATÉ 5 LESÕES)',
      amparoCode: 27,
    },
  },
  {
    id: 'spe_ite_cll3t6rd30040w4zuh8vxa68l',
    itemId: 'ite_cl007kxw2000rlvzu7j9q4cft',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw2000rlvzu7j9q4cft',
      name: 'EXÉRESE UNGUEAL',
      amparoCode: 28,
    },
  },
  {
    id: 'spe_ite_cll3t6rd30044w4zu1au936u5',
    itemId: 'ite_cl007kxw2000tlvzuh8u98xui',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw2000tlvzuh8u98xui',
      name: 'INCISÃO E DRENAGEM DE FLEGMÃO',
      amparoCode: 30,
    },
  },
  {
    id: 'spe_ite_cll3t6rd4004cw4zue1i70sn7',
    itemId: 'ite_cl007kxw2000wlvzu54sd8hr8',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw2000wlvzu54sd8hr8',
      name: 'RETIRADA DE CORPO ESTRANHO SUBCUTÂNEO',
      amparoCode: 33,
    },
  },
  {
    id: 'spe_ite_cll3t6rd4004dw4zu61vy1nxd',
    itemId: 'ite_cl007kxw2000xlvzu78qe07q4',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw2000xlvzu78qe07q4',
      name: 'RETIRADA DE DIU',
      amparoCode: 34,
    },
  },
  {
    id: 'spe_ite_cll3t6rd4004fw4zugn2w4pww',
    itemId: 'ite_cl007kxw2000ylvzu9iv49bic',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw2000ylvzu9iv49bic',
      name: 'SUTURA DE PEQUENOS FERIMENTOS COM OU SEM DESBRIDAMENTO',
      amparoCode: 35,
    },
  },
  {
    id: 'spe_ite_cll3t6rd4004hw4zuc3ma9d3b',
    itemId: 'ite_cl007kxw2000zlvzua7tk54s7',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl007kxw2000zlvzua7tk54s7',
      name: 'TRATAMENTO DE MIÍASE FURINCULÓIDE (POR LESÃO)',
      amparoCode: 36,
    },
  },
  {
    id: 'spe_ite_cll3t6rcv0005w4zu1a03blf2',
    itemId: 'ite_cl44iaubz000377zu6jy17vst',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl44iaubz000377zu6jy17vst',
      name: 'CONSULTA MÉDICA MFC PRESENCIAL',
      amparoCode: 53,
    },
  },
  {
    id: 'spe_ite_cll3t6rcv0006w4zu2qnd6s25',
    itemId: 'ite_cl44iaubz000277zu4tcq20kf',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl44iaubz000277zu4tcq20kf',
      name: 'CONSULTA MÉDICA MFC REMOTA',
      amparoCode: 167,
    },
  },
  {
    id: 'spe_ite_cll3t6rcw000fw4zud8r839jb',
    itemId: 'ite_cl45uskk20007t8szfdaq8w7o',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl45uskk20007t8szfdaq8w7o',
      name: 'CONSULTA MÉDICA PA REMOTA',
      amparoCode: 174,
    },
  },
  {
    id: 'spe_ite_cll3t6rd2003jw4zueq573az4',
    itemId: 'ite_cl44iauc6003277zu5fp2frrn',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl44iauc6003277zu5fp2frrn',
      name: 'CURATIVOS EM GERAL SEM ANESTESIA EXCETO QUEIMADOS',
      amparoCode: 147,
    },
  },
  {
    id: 'spe_ite_cll3t6rd30042w4zubsus35xi',
    itemId: 'ite_cl44iauc7003d77zugibw8r9u',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl44iauc7003d77zugibw8r9u',
      name: 'INCISÃO E DRENAGEM DE ABSCESSO HEMATOMA OU PANARÍCIO',
      amparoCode: 158,
    },
  },
  {
    id: 'spe_ite_cll3t6rd30046w4zu8bvd3b3c',
    itemId: 'ite_cl44iauc7003f77zu25qo3ihb',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl44iauc7003f77zu25qo3ihb',
      name: 'INSERCAÇÃO DE DIU HORMONAL*',
      amparoCode: 160,
    },
  },
  {
    id: 'spe_ite_cll3t6rd30048w4zu1xswftfx',
    itemId: 'ite_cl44iauc7003g77zufos62h9e',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl44iauc7003g77zufos62h9e',
      name: 'INSERCAÇÃO DE DIU NÃO HORMONAL*',
      amparoCode: 161,
    },
  },
  {
    id: 'spe_ite_cll3t6rd4004aw4zu9mimcz7g',
    itemId: 'ite_cl44iauc7003h77zuc9bl6v24',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl44iauc7003h77zuc9bl6v24',
      name: 'PROVA DO LACO',
      amparoCode: 162,
    },
  },
  {
    id: 'spe_ite_cll3t6rcw000hw4zuaqei1dgf',
    itemId: 'ite_cl6nq0gnp000109jng46y98n0',
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    item: {
      id: 'ite_cl6nq0gnp000109jng46y98n0',
      name: 'CONSULTA MÉDICA PA REMOTA VOUCHER',
      amparoCode: 175,
    },
  },
  {
    id: 'spe_it,e_cll3t6rcw000kw4zuepil9ypr',
    itemId: 'ite_cl7533w2o0000wfvoflvc6uqq',
    specialityId: null,
    item: {
      id: 'ite_cl7533w2o0000wfvoflvc6uqq',
      name: 'PACOTE ESF CONSULTA - CONTINUIDADE ENGAJAMENTO',
      amparoCode: 176,
    },
  },
  {
    id: 'spe_ite_cll3t6rcw000jw4zubzmhhsv7',
    itemId: 'ite_cl752w43y0000s2vod9wq8scl',
    specialityId: null,
    item: {
      id: 'ite_cl752w43y0000s2vod9wq8scl',
      name: 'PACOTE ESF CONSULTA ADSCRIÇÃO DE BENEFICIÁRIO',
      amparoCode: 177,
    },
  },
]
