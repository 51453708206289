import { rest } from 'msw'
import {
  clone,
  equals,
  filter,
  propEq,
} from 'ramda'
import professionalEnum from '../../../enums/professional'
import baseUrl from '../../../../src/utils/baseUrlTest'
import professionalListData from '../../fixtures/public/professionals/list'
import professionalListAllData from '../../fixtures/public/professionals/listAll'

const professionalListDataIncomplete = clone(professionalListData).splice(25, 30)

export default [
  rest.get(baseUrl('/professional'), (req, res, ctx) => {
    const professionalId = req.url.searchParams.get('id')

    if (equals(professionalId, professionalEnum.SIOBAN_ARCHER)) {
      return res(
        ctx.status(200),
        ctx.json({
          professionals: filter(propEq(professionalEnum.SIOBAN_ARCHER, 'id'), professionalListData),
        }),
      )
    }

    return res(
      ctx.status(200),
      ctx.json({
        professionals: professionalListDataIncomplete,
      }),
    )
  }),
  rest.get(baseUrl('/professional/list_all'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      professionals: professionalListAllData,
    }),
  )),
  rest.get(baseUrl('/professional/agenda_days'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      agendaWeekdays: [],
    }),
  )),
]
