<template>
  <v-container
    fluid
    fill-height
  >
    <v-layout
      align-center
      justify-center
      column
    >
      <div class="watermark hidden-mobile">
        <img src="@/assets/logo-watermark.png">
      </div>
      <v-flex
        d-flex
        text-xs-center
      >
        <img
          src="@/assets/logo-small.svg"
          style="width: 400px"
        >
      </v-flex>
      <v-flex
        class="login-form"
        d-flex
        mt-12
        text-xs-center
      >
        <v-layout column>
          <h1 class="title-h1 mb-12">
            Faça seu login para entrar
          </h1>
          <login-form />
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import LoginForm from './LoginForm'

export default {
  components: { LoginForm },
}
</script>
<style scoped lang="stylus">
  .login-form
    z-index 2
</style>
