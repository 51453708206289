import axios from '@/lib/axios'

const initialState = {
  loadingContractAttachment: false,
}

const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
}

const actions = {
  createContractAttachment({ commit }, { file, params }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.post('/billing/contract_attachment', file, { params })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
}

const getters = {
  isLoadingContractAttachment: state => state.loadingContractAttachment,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
