import { rest } from 'msw'
import baseUrl from '../../../../src/utils/baseUrlTest'
import healthMaintenanceOrganizationData from '../../fixtures/public/healthMaintenanceOrganization/list'
import healthMaintenanceOrganizationByIdData from '../../fixtures/public/healthMaintenanceOrganization/getById'

export default [
  rest.get(baseUrl('/health_maintenance_organization/contract'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(healthMaintenanceOrganizationData),
  )),
  rest.get(baseUrl('/health_maintenance_organization/:id'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(healthMaintenanceOrganizationByIdData),
  )),
  rest.get(baseUrl('/health_maintenance_organization'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(healthMaintenanceOrganizationData),
  )),
  rest.post(baseUrl('/health_maintenance_organization'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      id: 'hea_mai_org_fake_id_01',
      ...req.body,
    }),
  )),
  rest.put(baseUrl('/health_maintenance_organization/:id'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(req.body),
  )),
  rest.patch(baseUrl('/health_maintenance_organization/:id/external_ids'), (req, res, ctx) => res(
    ctx.status(200),
  )),
]
