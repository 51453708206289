<template>
  <v-dialog
    id="carePlanId"
    :value="true"
    max-width="1000"
    persistent
  >
    <v-card>
      <v-card-title class="mb-2">
        <v-container>
          <v-row
            justify="end"
          >
            <v-col>
              <img
                class="printable-area__logo"
                src="@/assets/logo.png"
                alt="logo"
              >
            </v-col>

            <v-col
              cols="1"
            >
              <v-btn
                icon
                @click="closeOpenCarePlan()"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <h2
                    class="mt-3"
                  >
                    Plano de Cuidado
                  </h2>
                  <p
                    class="mb-0 text-subtitle-2 mt-2"
                  >
                    Esse plano de cuidado é anual.
                  </p>
                </v-col>
              </v-row>

              <v-row
                class="mt-0"
              >
                <v-col
                  class="pb-0"
                >
                  <h3
                    class="text-subtitle-2"
                  >
                    Interações:
                  </h3>
                </v-col>
              </v-row>

              <v-row
                class="mt-0"
              >
                <v-col
                  class="pb-0"
                >
                  <p
                    class="text-subtitle-2 mb-0"
                  >
                    - <span class="blue--text">Tarefas Internas</span>:
                    É a programação de cuidados que sua equipe de saúde está preparando para você.
                  </p>
                </v-col>
              </v-row>

              <v-row
                class="mt-0"
              >
                <v-col
                  class="pb-0"
                >
                  <p
                    class="text-subtitle-2 mb-0"
                  >
                    - <span class="blue--text">Formulários</span>:
                    Se refere ao preenchimento de um formulário que você ou
                    a sua equipe especializada irá responder.
                  </p>
                </v-col>
              </v-row>

              <v-row
                class="mt-0"
              >
                <v-col
                  class="pb-0"
                >
                  <p
                    class="text-subtitle-2 mb-0"
                  >
                    - <span class="blue--text">Comunicados</span>:
                    É o contato que iremos realizar por email com orientações e dicas de saúde.
                  </p>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="1"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    @click="sendCarePlanToPatient"
                  >
                    <v-icon>mdi-message-processing</v-icon>
                  </v-btn>
                </template>
                <span>Enviar por SMS</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>

        <care-plan-card
          v-if="carePlan.schedules"
          :care-items="carePlan.schedules"
          care-item="schedule"
        />

        <care-plan-card
          v-if="carePlan.forms"
          :care-items="carePlan.forms"
          care-item="form"
        />

        <care-plan-card
          v-if="carePlan.communications"
          :care-items="carePlan.communications"
          care-item="communication"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  components: {
    CarePlanCard: () => import('./CarePlanCard'),
  },
  props: {
    patientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      carePlan: {},
    }
  },
  async mounted() {
    await this.getCarePlanData()
  },
  methods: {
    ...mapActions({
      getCarePlan: 'emr/getCarePlan',
      sendCarePlanLink: 'emr/sendCarePlanLink',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    async getCarePlanData() {
      this.carePlan = await this.getCarePlan(this.patientId)
    },
    async sendCarePlanToPatient() {
      try {
        await this.sendCarePlanLink(this.patientId)
        this.setSnackbar({ status: 'success', message: 'Plano de Cuidado enviado com sucesso!' })
      } catch (error) {
        this.setSnackbar({ status: 'error', message: 'Erro ao enviar Plano de Cuidado!' })
      }
    },
    closeOpenCarePlan() {
      this.$emit('closeDialog')
    },
  },
}
</script>
