export default {
  RACE: {
    WHITE: {
      label: 'Branca',
      value: 'white',
    },
    BLACK: {
      label: 'Preta',
      value: 'black',
    },
    BROWN: {
      label: 'Parda',
      value: 'brown',
    },
    ASIAN: {
      label: 'Amarela',
      value: 'asian',
    },
    NATIVE: {
      label: 'Indígena',
      value: 'native',
    },
    UNINFORMED: {
      label: 'Prefiro não declarar',
      value: 'uninformed',
    },
    UNKNOWN: {
      label: 'Não informada',
      value: null,
    },
  },
  YES_OR_NO: {
    NO: {
      title: 'Não',
      value: false,
    },
    YES: {
      title: 'Sim',
      value: true,
    },
  },
  BIOLOGICAL_SEX: {
    MALE: {
      title: 'Masculino',
      value: 'M',
    },
    FEMALE: {
      title: 'Feminino',
      value: 'F',
    },
  },
  RESPONSIBLE_RELATIONSHIP: {
    FATHER: 'Pai',
    MOTHER: 'Mãe',
    UNCLE: 'Tio',
    AUNT: 'Tia',
    GRANDFATHER: 'Avô',
    GRANDMOTHER: 'Avó',
    CAREGIVER: 'Cuidador(a)',
    OTHER: 'Outros',
  },
  SPECIAL_NEEDS_TYPE: {
    VISUAL: 'Visual',
    HEARING: 'Auditiva',
    PHYSICAL: 'Física',
    MENTAL: 'Mental',
    MULTIPLE: 'Múltipla',
  },
  MARITAL_STATUS: {
    SINGLE: 'Solteiro',
    MARRIED: 'Casado',
    DIVORCED: 'Divorciado',
    WIDOWER: 'Viúvo',
  },
  BLOOD_TYPES: {
    UNKNOWN: 'Não sabe',
    PLUS_A: 'A+',
    MINUS_A: 'A-',
    PLUS_B: 'B+',
    MINUS_B: 'B-',
    PLUS_AB: 'AB+',
    MINUS_AB: 'AB-',
    PLUS_O: 'O+',
    MINUS_O: 'O-',
  },
  STATES: {
    AC: 'AC',
    AL: 'AL',
    AM: 'AM',
    AP: 'AP',
    BA: 'BA',
    CE: 'CE',
    DF: 'DF',
    ES: 'ES',
    GO: 'GO',
    MA: 'MA',
    MG: 'MG',
    MS: 'MS',
    MT: 'MT',
    PA: 'PA',
    PB: 'PB',
    PE: 'PE',
    PI: 'PI',
    PR: 'PR',
    RJ: 'RJ',
    RN: 'RN',
    RO: 'RO',
    RR: 'RR',
    RS: 'RS',
    SC: 'SC',
    SE: 'SE',
    SP: 'SP',
    TO: 'TO',
  },
  FOLLOWING_ACCEPTATION_TERM: {
    ACCEPTED_APP: {
      title: 'Aceitou acompanhamento',
      value: 'acceptedApp',
    },
    ACCEPTED_PORTAL: {
      title: 'Aceitou acompanhamento',
      value: 'acceptedPortal',
    },
    REFUSED_APP: {
      title: 'Não aceitou acompanhamento',
      value: 'refusedApp',
    },
    REFUSED_PORTAL: {
      title: 'Não aceitou acompanhamento',
      value: 'refusedPortal',
    },
    WITHOUT_ACCEPTATION: {
      title: 'Sem informações de aceite',
      value: 'withoutAcceptation',
    },
  },
  DOCUMENT_TYPES: {
    CPF: {
      title: 'CPF',
      value: 'cpf',
    },
    FOREIGN_DOCUMENT: {
      title: 'Passaporte',
      value: 'foreignDocument',
    },
  },
  RESERVATION_PATIENTS: [
    'pat_ckkybxi6y00bp0n1248pefv2z',
    'pat_cl36cdokn00s80jos9vr8eohd',
  ],
}
