<template>
  <div>
    <v-flex
      class="font-weight-bold"
    >
      Solicito
    </v-flex>
    <v-flex
      v-for="(actualExamList, index) in exam"
      :key="index"
      column
    >
      <v-flex
        v-for="(actualExam, examListIndex) of actualExamList"
        :key="examListIndex"
        column
        mt-1
      >
        <v-flex>
          • {{ actualExam.exam.name }} -
          <span class="text--uppercase">
            {{ actualExam.exam.version || notFoundMessage.version }}
          </span>: {{ actualExam.exam.code || notFoundMessage.code }}
        </v-flex>
        <v-flex
          v-if="actualExam.exam.observation"
          class="exam__observation mt-1"
        >
          - {{ actualExam.exam.observation }}
        </v-flex>
      </v-flex>
    </v-flex>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: [
    'exam',
  ],
  computed: {
    ...mapGetters({
      notFoundMessage: 'emr/NotFoundMessage',
    }),
  },
}
</script>

<style lang="stylus" scoped>
.exam__observation
  font-style italic
  color rgba(#000, 0.5)
</style>
