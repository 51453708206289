<template>
  <div>
    <component-title />
    <form-settings-container />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import { mapActions } from 'vuex'
import FormSettingsContainer from './FormSettingsContainer'

export default {
  name: 'FormSettingsPage',
  components: { ComponentTitle, FormSettingsContainer },
  mounted() {
    this.listCategory()
  },
  methods: {
    ...mapActions({
      listCategory: 'careForm/listCategory',
    }),
  },
}
</script>
