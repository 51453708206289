<template>
  <div>
    <div class="header-info lighter-green">
      <v-container
        fluid
        fill-height
      >
        <v-layout
          align-center
          fill-height
          ml-2
          row
        >
          <span class="header-info__items font-weight-medium">{{ clinic.name }}</span>
          <span class="header-info__items font-weight-medium">{{ speciality.name }}</span>
          <span class="header-info__items font-weight-medium">{{ professional.name }}</span>
        </v-layout>
      </v-container>
    </div>
    <v-container
      fluid
      grid-list-xl
    >
      <h2 class="info-text font-weight-lighter">
        Defina as configurações do destaque
      </h2>
      <v-layout
        row
        wrap
      >
        <v-flex sm3>
          <v-menu
            v-model="menuStartDay"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formatedStartDate"
                label="Data inicial"
                append-icon="mdi-calendar"
                filled
                readonly
                :error="$v.startDate.$error"
                @blur="$v.startDate.$touch()"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="startDate"
              locale="pt-br"
              no-title
              scrollable
              @input="menu = false"
            />
          </v-menu>
        </v-flex>
        <v-flex sm3>
          <v-menu
            v-model="menuEndDay"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formatedEndDate"
                label="Data final"
                append-icon="mdi-calendar"
                hint="Em branco para infinito"
                persistent-hint
                filled
                readonly
                clearable
                :error="$v.endDate.$error"
                @blur="$v.endDate.$touch()"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="endDate"
              locale="pt-br"
              no-title
              scrollable
              @input="menu = false"
            />
          </v-menu>
        </v-flex>
        <v-flex sm2>
          <v-select
            v-model="startTime"
            :items="startWorkHours"
            label="Hora início"
            filled
            :error="$v.startTime.$error"
            @focus="buildstartWorkHoursList"
            @blur="$v.startTime.$touch()"
          />
        </v-flex>
        <v-flex sm2>
          <v-select
            v-model="slotInterval"
            :items="slotIntervalItems"
            label="Intervalo de slots"
            filled
            :error="$v.slotInterval.$error"
            @blur="$v.slotInterval.$touch()"
          >
            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="interval-tooltip"
                    v-on="on"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                  Define o intervalo de slots em que aparecerão os destaques
                </span>
              </v-tooltip>
            </template>
          </v-select>
        </v-flex>
        <v-flex sm2>
          <v-select
            v-model="endTime"
            :items="buildEndWorkHourList"
            label="Hora fim"
            no-data-text="Preencha os campos anteriores"
            filled
            :error="$v.endTime.$error"
            @blur="$v.endTime.$touch()"
          />
        </v-flex>
        <v-flex>
          <v-text-field
            v-model="reason"
            return-object
            label="Razão"
            filled
            counter
            maxlength="50"
            :error="$v.reason.$error"
            @blur="$v.reason.$touch()"
          />
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex
          md2
          class="cancel-button--fixed"
        >
          <v-btn
            color="primary"
            class="font-weight-bold"
            text
            large
            block
            @click="closeSpotlightDialog()"
          >
            Cancelar
          </v-btn>
        </v-flex>
        <v-flex
          md2
          class="save-button--fixed"
        >
          <v-btn
            color="primary"
            class="font-weight-bold"
            large
            block
            :loading="isLoading"
            @click="saveAgendaSpotlightPattern()"
          >
            Salvar
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapActions } from 'vuex'
import AgendaRegisterMixin from '@/mixins/AgendaRegisterMixin'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  mixins: [AgendaRegisterMixin],
  props: ['clinic', 'speciality', 'professional'],
  data() {
    return {
      slotIntervalItems: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
      ],
      isLoading: false,
    }
  },
  validations() {
    const agendaPatternValidations = {
      startDate: {
        required,
        isSameOrAfterToday: () => this.isSameOrAfterToday(),
      },
      endDate: {
        isSameOrAfterStartDate: () => this.isSameOrAfterStartDate(),
      },
      startTime: { required },
      endTime: { required },
      slotInterval: { required },
      reason: { required, maxLength: maxLength(50) },
    }
    return { ...agendaPatternValidations }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
      createSpotlight: 'agenda/createSpotlight',
    }),
    getSpotlights() {
      this.$emit('getSpotlights')
    },
    closeSpotlightDialog() {
      this.resetFields()
      this.$emit('closeSpotlightDialog')
    },
    buildAgendaSpotlight() {
      const spotlight = {
        startDate: this.startDate,
        endDate: this.endDate,
        startTime: this.startTime,
        endTime: this.endTime || null,
        professionalId: this.professional.id,
        specialityId: this.speciality.id,
        clinicId: this.clinic.id,
        reason: this.reason,
        slotInterval: this.slotInterval,
      }
      return spotlight
    },
    saveAgendaSpotlightPattern: debounce(async function saveAgendaSpotlightPattern() {
      this.$v.$touch()
      if (this.$v.$error) return
      const spotlight = this.buildAgendaSpotlight()
      try {
        await this.createSpotlight(spotlight)
        await this.getSpotlights()
        await this.resetFields()
        await this.closeSpotlightDialog()
      } catch (error) {
        const status = 'error'
        const message = this.setErrorMessage(error.response.data)
        this.setSnackbar({ status, message })
      } finally {
        this.isLoading = false
      }
    }, 500),
  },
}
</script>

<style lang="stylus" scoped>
@import '../../../style/_core/utils/helpers.styl'

.info-text
  letter-spacing -0.5px
  color rgba(0, 0, 0, 0.38)
  margin-bottom 50px
  @media only screen and (min-width: 601px)
    font-size 32px
  @media only screen and (min-width: 1201px)
    font-size 48px
  @media only screen and (min-width: 1601px)
    font-size 64px

.header-info
  height 120px

.header-info__items
  margin-right 95px
  font-size 20px
  letter-spacing 1px
  color rgba(0, 0, 0, 0.6)

.v-btn--text
  border 1px solid #ccc

.v-btn--large
  height 56px
  width 220px
  border-radius 8px
  letter-spacing 1px

@media only screen and (min-width: 601px)
  .cancel-button--fixed
    position fixed
    bottom 0
    right 130px

  .save-button--fixed
    position fixed
    bottom 0
    right 10px

</style>
