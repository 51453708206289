<template>
  <div class="d-block">
    <v-flex
      column
    >
      <v-flex
        v-if="attachmentList.length === 0"
        :class="emptyListClasses"
      >
        Não há anexos.
      </v-flex>
      <v-flex
        v-for="attachment of attachmentList"
        :key="attachment.fileName"
        class="attach__item"
      >
        <v-flex
          class="attach__text pr-4 align-center font-weight-bold attach__name cursor--pointer"
          @click="showAttachment(attachment)"
        >
          <v-tooltip
            bottom
            max-width="450"
          >
            <template
              #activator="{ on }"
            >
              <span
                class="d-block text-truncate"
                v-on="shouldShowTextTooltip(attachment) && on"
              >
                {{ attachment.originalName }}
              </span>
            </template>
            <span>
              {{ buildAttachmentName(attachment) }}
            </span>
          </v-tooltip>
          <span>
            &nbsp;- {{ buildAttachmentDate(attachment) }}
          </span>
        </v-flex>
        <v-flex
          justify-end
        >
          <v-icon
            class="attach__icon cursor--pointer mr-4"
            @click="showAttachment(attachment)"
          >
            mdi-eye
          </v-icon>
          <v-icon
            class="attach__icon cursor--pointer"
            @click="openConfirmDeletion(attachment)"
          >
            mdi-close-circle
          </v-icon>
        </v-flex>
      </v-flex>
      <v-flex
        v-if="seeMoreMessage"
        justify-center
        mt-2
        class="attach__text font-weight-bold attach__item cursor--pointer text--underline"
        @click="seeMoreDialog = true"
      >
        Ver mais...
      </v-flex>
    </v-flex>
    <v-dialog
      v-if="seeMoreDialog"
      v-model="seeMoreDialog"
      max-width="600"
    >
      <v-card>
        <v-card-title
          class="card-dialog__title font-weight-medium pb-0"
        >
          <v-flex>
            Anexos gerais
          </v-flex>
          <v-spacer />
          <v-flex shrink>
            <v-btn
              icon
              @click="seeMoreDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-card-title>
        <v-card-text class="pa-4">
          <list-attachments
            v-if="seeMoreDialog"
            :patient="patient"
            :attachments="attachments"
            :order-id="orderId"
            :limited-list="false"
            :route="route"
            :update-data="updateData"
            type="general"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDeletion"
      max-width="420"
    >
      <v-card
        class="confirm-deletion__confirm-alert"
      >
        <v-flex justify-center>
          <v-card-title
            v-if="attachmentToDelete"
            class="confirm-deletion__confirm-alert-title"
          >
            Deseja realmente apagar o arquivo {{ attachmentToDelete.originalName }}?
          </v-card-title>
        </v-flex>
        <v-flex
          justify-center
        >
          <v-btn
            large
            text
            class="btn btn-secondary mr-2"
            @click="confirmDeletion = false"
          >
            Não
          </v-btn>
          <v-btn
            large
            class="btn btn-danger"
            @click="deleteAttachment(attachmentToDelete)"
          >
            Sim
          </v-btn>
        </v-flex>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  not,
  isNil,
  filter,
  equals,
  includes,
  prop,
  slice,
  length,
  gt,
  reverse,
  cond,
  path,
  always,
  T,
} from 'ramda'
import { mapActions } from 'vuex'

export default {
  name: 'ListAttachments',
  props: [
    'patient',
    'attachments',
    'fileNameList',
    'type',
    'limitedList',
    'route',
    'orderId',
    'updateData',
    'contractId',
    'emptyListClasses',
  ],
  data() {
    return {
      uploadProgress: null,
      shouldShowProgressBar: false,
      confirmDeletion: false,
      attachmentToDelete: null,
      seeMoreMessage: false,
      seeMoreDialog: false,
    }
  },
  computed: {
    attachmentList() {
      const filteredList = reverse(this.removeTypeExam(
        this.filterAttachments(
          this.attachments,
        ),
      ))
      if (this.limitedList && gt(length(filteredList), 4)) {
        this.showSeeMoreMessage(true)
        return slice(0, 3, filteredList)
      }
      this.showSeeMoreMessage(false)
      return filteredList
    },
  },
  mounted() {
    this.$emit('attachmentLength', this.attachmentList.length)
  },
  methods: {
    ...mapActions({
      listAttachments: 'emr/listAttachments',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    buildAttachmentName({ originalName, date }) {
      return `${originalName} ${date ? ' - ' : ''} ${this.$options.filters.formatDate(date)}`
    },
    buildAttachmentDate({ date }) {
      return this.$options.filters.formatDate(date)
    },
    showSeeMoreMessage(status) {
      this.seeMoreMessage = status
    },
    removeTypeExam(attachmentList) {
      if (equals(this.type, 'exam')) return attachmentList
      return filter(
        ({ fileName }) => not(includes('_exa_', fileName)),
        attachmentList,
      )
    },
    filterAttachments(attachmentList) {
      if (isNil(this.fileNameList)) return attachmentList

      return filter(
        ({ fileName }) => includes(fileName, this.fileNameList),
        attachmentList,
      )
    },
    openConfirmDeletion(attachment) {
      this.confirmDeletion = true
      this.attachmentToDelete = attachment
    },
    generateCurrentIdObject({ patient, orderId, contractId }) {
      return cond([
        [
          path(['patient', 'resume']),
          always({
            patientMedicalResumeId: path(['resume', 'id'], patient),
          }),
        ],
        [
          path(['orderId']),
          always({ orderId }),
        ],
        [
          path(['contractId']),
          always({ contractId }),
        ],
        [
          T,
          always({
            patientId: path(['id'], patient),
          }),
        ],
      ])({
        patient,
        orderId,
        contractId,
      })
    },
    deleteAttachment({ id, fileName, originalName }) {
      const currentId = this.generateCurrentIdObject({
        patient: this.patient,
        orderId: this.orderId,
        contractId: this.contractId,
      })

      const params = {
        ...currentId,
        fileName,
      }

      const deleteRoute = prop('patientId', currentId)
        ? `${this.route}/${id}`
        : this.route

      this.$http.delete(deleteRoute, { params })
        .then(() => {
          this.showSnackbar('success', `${originalName} Apagado com sucesso!`)
          this.confirmDeletion = false
          this.attachmentToDelete = false
          if (prop('patientMedicalResumeId', currentId)) {
            this.listAttachments({ patientId: this.patient.id })
          } else {
            this.updateData()
          }
        })
        .catch((error) => {
          if (error?.response?.data?.errorCode === 'instance_invalid') {
            this.showSnackbar('error', 'Arquivo não encontrado.')
            return
          }
          this.showSnackbar('error', 'Não foi possível deletar o arquivo.')
        })
    },
    showAttachment({ fileName, originalName, extension }) {
      const currentId = this.generateCurrentIdObject({
        patient: this.patient,
        orderId: this.orderId,
        contractId: this.contractId,
      })

      const showRoute = prop('patientId', currentId)
        ? `${this.route}/url`
        : this.route

      const params = {
        ...currentId,
        fileName,
        originalName,
        extension,
      }
      this.$http.get(showRoute, { params })
        .then((res) => {
          window.open(res.data.signedUrlToDownload)
        })
        .catch(() => {
          this.showSnackbar('error', 'Erro ao fazer download.')
        })
    },
    showSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    shouldShowTextTooltip(attachment) {
      const text = this.buildAttachmentName(attachment)
      if (text.length >= 26) return true
      return false
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.attach__item
  padding-top 4px
  padding-bottom @padding-top
  margin-top 3px
  margin-bottom @margin-top
  transition all .15s linear

.attach__item:hover
  background-color lighten($japanese-laurel, 95%)
  transition all .15s linear

.attach__name
  max-width 300px
  padding-left 5px

.attach__text
  color $japanese-laurel
  text-transform uppercase
  font-size 12px
  white-space nowrap

.attach__icon
  color $japanese-laurel !important
  margin-right 7px
  font-size 18px

.confirm-deletion__confirm-alert
  padding 15px

.confirm-deletion__confirm-alert-title
  font-size 16px
  word-break break-word

.card-dialog__title
  font-size 19.9px !important
  letter-spacing 0.3px

</style>
