import axios from '@/lib/axios'

const initialState = {
  forms: [],
}

const mutations = {
  SET_FORMS(state, payload) {
    state.forms = payload
  },
}

const actions = {
  listForm({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      axios.get('/emr/form', { params })
        .then(({ data: { forms } }) => {
          commit('SET_FORMS', forms)
          resolve(forms)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  createForm(_, form) {
    return new Promise((resolve, reject) => {
      axios.post('/emr/form', form)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  updateForm(_, { formId, formData }) {
    return new Promise((resolve, reject) => {
      axios.patch(`/emr/form/${formId}`, formData)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  deleteForm(_, formId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/emr/form/${formId}`)
        .then(() => {
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
}

const getters = {
  forms: state => state.forms,
}

export default {
  state: initialState,
  mutations,
  actions,
  getters,
}
