import { rest } from 'msw'
import baseUrl from '../../../src/utils/baseUrlTest'
import referralData from '../fixtures/referralData'

export default [
  rest.get(baseUrl('/referral'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(referralData),
  )),
]
