import axios from '@/lib/axios'

const initialState = {
  categories: [],
}

const mutations = {
  SET_CATEGORIES(state, payload) {
    state.categories = payload
  },
}

const actions = {
  createCategory(_, category) {
    return new Promise((resolve, reject) => {
      axios.post('/emr/category', category)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  listCategory({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      axios.get('/emr/category', { params })
        .then(({ data: { categories } }) => {
          commit('SET_CATEGORIES', categories)
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
}

const getters = {
  categories: state => state.categories,
}

export default {
  state: initialState,
  mutations,
  actions,
  getters,
}
