<template>
  <div>
    <v-container
      v-if="isLoading"
      justify-center
      mt-5
      class="fill-height"
    >
      <circular-loader />
    </v-container>
    <div v-else-if="hmoData">
      <v-container
        class="px-12 py-8"
        fluid
      >
        <v-row>
          <v-col
            cols="5"
          >
            <v-row>
              <v-col
                cols="12"
                class="pb-0"
              >
                <h2 class="mb-0">
                  {{ hmoData.name }}
                </h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="auto"
              >
                <v-btn
                  color="primary"
                  class="text-capitalize text-subtitle-1 font-weight-lightbold"
                  outlined
                  small
                  @click="downloadExampleFile"
                >
                  Download do arquivo exemplo
                  <v-icon right>
                    mdi-download
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col>
                <span class="font-weight-medium pb-2">
                  Selecione e a ação a ser realizada para completar a configuração:
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="py-0"
              >
                <span
                  class="d-block mb-2"
                >
                  Ação a ser realizada:
                </span>
                <v-radio-group
                  v-model="selectedAction"
                  class="mt-0"
                  row
                  hide-details
                >
                  <v-radio
                    v-for="action in actionList"
                    :key="action.id"
                    :label="action.name"
                    :value="action.id"
                    color="primary"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="mt-6">
              <v-col>
                <a
                  href="https://analytics.amparo.tech/question/2354-operadoras-e-planos"
                  target="_blank"
                >
                  Lista de operadoras e planos
                </a>
                <a
                  href="https://analytics.amparo.tech/question/2353-especialidades"
                  target="_blank"
                  class="ml-4"
                >
                  Lista de especialidades
                </a>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="7"
          >
            <dropzone
              ref="dropzone"
              icon="mdi-file-upload-outline"
              dashed
              color="#6DD36E"
              border-radius="1rem"
              default-message="Preencha os campos e escolha ou arraste um arquivo para importar"
              :accepted-files="[
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'text/csv'
              ]"
              :min-lines-limit="2"
              :max-lines-limit="101"
              :accept-multiple="false"
              @update:files="updateFiles"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="hasItemValues"
          class="mt-6 mb-4"
        >
          <v-col cols="12">
            <h2>Visualização do arquivo</h2>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items"
              :items-per-page="items.length"
              class="preferred-network-table table--striped"
              no-data-text="O plano não possui rede preferencial configurada"
              disable-sort
              hide-default-footer
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-end"
          >
            <v-btn
              class="mr-6 text-uppercase font-weight-lightbold"
              outlined
              color="primary"
              @click="goToPreferredNetworkListPage()"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="text-uppercase font-weight-lightbold"
              color="primary"
              :disabled="hasAnyError || !hasItemValues"
              :loading="isSavePreferredNetworkLoading"
              @click="savePreferredNetwork()"
            >
              Salvar rede preferencial
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <contract-item-worksheet-error-modal
        v-if="shouldOpenContractItemWorksheetErrorModal"
        :sheet-errors="sheetErrors"
        @closeModal="closeContractItemWorksheetErrorModal"
      />
    </div>
  </div>
</template>

<script>
import { read, utils } from 'xlsx'
import {
  all,
  clone,
  groupBy,
  isEmpty,
  isNil,
  map,
  mapObjIndexed,
  not,
  pick,
  pipe,
  prop,
  reduce,
  toPairs,
} from 'ramda'
import { mapActions, mapGetters } from 'vuex'
import CircularLoader from '@/components/UI/CircularLoader'
import Dropzone from '../../components/Common/Dropzone'


export default {
  components: {
    CircularLoader,
    ContractItemWorksheetErrorModal: () => import('../EditContract/Modals/ContractItemWorksheetErrorModal'),
    Dropzone,
  },
  data() {
    return {
      isLoading: false,
      isSavePreferredNetworkLoading: false,
      hmoData: null,
      hasAnyError: false,
      selectedAction: 'insert',
      sheetErrors: {},
      shouldOpenContractItemWorksheetErrorModal: false,
      actionList: [
        {
          name: 'Adicionar',
          id: 'insert',
        },
        {
          name: 'Remover',
          id: 'delete',
        },
        {
          name: 'Substituir',
          id: 'reset',
        },
      ],
      headers: [
        {
          text: 'Plano',
          value: 'healthProductName',
        },
        {
          text: 'Nome',
          value: 'name',
        },
        {
          text: 'Especialidade',
          value: 'specialityName',
        },
        {
          text: 'Telefone',
          value: 'telephone',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Estado',
          value: 'address.state',
        },
        {
          text: 'Cidade',
          value: 'address.city',
        },
        {
          text: 'Bairro',
          value: 'address.neighborhood',
        },
        {
          text: 'Logradouro',
          value: 'address.street',
        },
        {
          text: 'Número',
          value: 'address.number',
        },
        {
          text: 'CEP',
          value: 'address.zipCode',
        },
      ],
      items: [],
    }
  },
  computed: {
    ...mapGetters({
      token: 'authentication/token',
      hmos: 'healthMaintenanceOrganization/hmos',
    }),
    hasItemValues() {
      return !isEmpty(this.items)
    },
    formattedItems() {
      return this.items.map(({
        healthProductName,
        specialityName,
        state,
        city,
        neighborhood,
        street,
        number,
        zipCode,
        ...items
      }) => ({
        ...items,
        healthProductName: healthProductName || null,
        specialityName: specialityName || null,
        address: {
          state,
          city,
          neighborhood,
          street,
          number,
          zipCode,
        },
      }))
    },
  },
  mounted() {
    this.setHmoData()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
      getHmoById: 'healthMaintenanceOrganization/getById',
      getPreferredNetworkExampleFile: 'preferredNetwork/getPreferredNetworkExampleFile',
      addPreferredNetworkProfessionals: 'preferredNetwork/addPreferredNetworkProfessionals',
      removePreferredNetworkProfessionals: 'preferredNetwork/removePreferredNetworkProfessionals',
      resetPreferredNetworkProfessionals: 'preferredNetwork/resetPreferredNetworkProfessionals',
      validatePreferredNetworkFile: 'preferredNetwork/validatePreferredNetworkFile',
    }),
    buidlSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    goToPreferredNetworkListPage() {
      this.$router.push('/preferred-network')
    },
    async downloadExampleFile() {
      const data = await this.getPreferredNetworkExampleFile()

      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'rede-preferencial-modelo.xlsx'
      link.click()
    },
    async setHmoData() {
      this.isLoading = true
      const hmoId = this.$route.params.id

      if (!isEmpty(this.hmos)) {
        const selectedHmo = this.hmos.find(hmo => hmo.id === hmoId)

        if (selectedHmo) {
          this.hmoData = selectedHmo
          this.isLoading = false
          return
        }
      }

      this.hmoData = await this.getHmoById(hmoId)
      this.isLoading = false
    },
    closeContractItemWorksheetErrorModal() {
      this.shouldOpenContractItemWorksheetErrorModal = false
    },
    async handleFileItems(file) {
      const workbook = read(await file.arrayBuffer())

      const sheetName = workbook.SheetNames[0]

      const worksheet = workbook.Sheets[sheetName]

      const itemsList = utils.sheet_to_json(worksheet, { header: 1 })

      const items = reduce((acc, item) => {
        const isLineEmpty = all(isNil, item)

        const [
          healthProductName,
          name,
          specialityName,
          telephone,
          email,
          state,
          city,
          neighborhood,
          street,
          number,
          zipCode,
        ] = item

        if (not(isLineEmpty)) {
          acc.push({
            healthProductName: healthProductName ? String(healthProductName) : undefined,
            name: name ? String(name) : undefined,
            specialityName: specialityName ? String(specialityName) : undefined,
            telephone: telephone ? String(telephone) : undefined,
            email: email ? String(email) : undefined,
            state: state ? String(state) : undefined,
            city: city ? String(city) : undefined,
            neighborhood: neighborhood ? String(neighborhood) : undefined,
            street: street ? String(street) : undefined,
            number: number ? String(number) : undefined,
            zipCode: zipCode ? String(zipCode) : undefined,
          })
        }

        return acc
      }, [], itemsList)

      return items
    },
    buildObjError(errors) {
      return pipe(
        toPairs,
        map(([key, value]) => {
          const attributeKey = key.split(',')[2]
          return {
            key: attributeKey,
            header: this.headers.find(header => header.value === attributeKey)?.text,
            text: value,
            row: Number(key.split(',')[1]),
          }
        }),
        groupBy(prop('key')),
        mapObjIndexed(value => map(pick(['text', 'row', 'header']), value)),
      )(errors)
    },
    async updateFiles(files) {
      if (isEmpty(files)) {
        this.items = []
        return
      }

      const fileData = await this.handleFileItems(files[0])
      this.items = clone(fileData)
      this.items.shift()
      this.items = this.formattedItems

      try {
        await this.validatePreferredNetworkFile({
          xlsxData: fileData,
          healthMaintenanceOrganizationId: this.hmoData.id,
        })
        this.hasAnyError = false
      } catch (error) {
        this.sheetErrors = error?.response?.data?.errors
        this.shouldOpenContractItemWorksheetErrorModal = true
        this.hasAnyError = true
      }
    },
    async savePreferredNetwork() {
      this.isSavePreferredNetworkLoading = true
      try {
        switch (this.selectedAction) {
          case 'insert':
            await this.addPreferredNetworkProfessionals({
              professionalsData: this.items,
              hmoId: this.hmoData.id,
            })
            break

          case 'delete':
            await this.removePreferredNetworkProfessionals({
              professionalsData: this.items,
              hmoId: this.hmoData.id,
            })
            break

          case 'reset':
            await this.resetPreferredNetworkProfessionals({
              professionalsData: this.items,
              hmoId: this.hmoData.id,
            })
            break

          default:
            break
        }

        this.buidlSnackbar('success', 'A rede preferencial foi atualizada com sucesso!')
        this.goToPreferredNetworkListPage()
      } catch (error) {
        this.buidlSnackbar('error', 'Erro ao salvar rede preferencial')
      } finally {
        this.isSavePreferredNetworkLoading = false
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.dropzone
  min-height 200px
  border 1px dashed primary-color
  border-radius 1rem
  >>> .dz-preview
    z-index 1
  >>> .dz-message
    margin 0

.dropzone-custom-message
  color amparo-green-tech
  font-weight 600

.preferred-network-table >>> thead > tr
  background-color #3233380D !important
  box-shadow 0px 3px 1px 0px #E0E0E0E5 !important
  font-size 0.9rem !important

.preferred-network-table >>> thead > tr > th
  color #707372 !important
  font-size 0.9rem !important
  padding 16px !important

.preferred-network-table >>> tbody > tr
  box-shadow 0px 0.5px 2px 0px #DBDBDB

.preferred-network-table >>> tbody > tr > td
  color #323338CC !important
  padding 16px !important
  font-weight 400 !important

</style>
