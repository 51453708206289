import Vue from 'vue'
import {
  and,
  isNil,
} from 'ramda'

Vue.directive('default-patient', {
  bind(el, _, vnode) {
    el.querySelector('input').addEventListener('keydown', () => {
      const searchComponent = vnode.child
      searchComponent.isShowingDefault = false
    })
  },
  update(_, binding, vnode) {
    const searchComponent = vnode.child
    const hasLoadedDefaultValue = and(
      isNil(binding.oldValue),
      binding.value,
    )
    const hasSetNullValue = and(
      isNil(binding.value),
      binding.oldValue,
    )

    if (hasLoadedDefaultValue) {
      searchComponent.isShowingDefault = true
      searchComponent.searchPatientField = binding.value.name
    } else if (hasSetNullValue) {
      searchComponent.searchPatientField = null
    } else {
      searchComponent.isShowingDefault = false
    }
  },
})
