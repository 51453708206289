<template>
  <v-dialog
    v-model="show"
    max-width="600"
    @click:outside="closeDialog()"
  >
    <v-card class="popup__container">
      <v-card-text>
        <v-flex class="text--bold popup__title mb-4">
          <v-flex column>
            <v-flex class="mb-2">
              Ops, não conseguimos achar todas as informações para o prontuário.
            </v-flex>
          </v-flex>
        </v-flex>
        <v-container v-if="hasAppointments">
          <v-flex class="font-weight-bold">
            Selecione o atendimento que deve ser vinculado ao prontuário:
          </v-flex>
          <v-col
            justify-center
            class="mb-5 ml-2"
          >
            <v-row
              v-for="appointment in appointments"
              :key="appointment.id"
              class="my-2"
            >
              <appointment-card
                :appointment-data="{ appointment, slot: {} }"
                :should-show-extended="false"
                :hide-details="true"
                :show-time="true"
                :show-clinic="true"
                :is-selected="appointment.id === selectedAppointment?.id"
                @selectedAppointment="fillFiltersByAppointment"
              />
            </v-row>
          </v-col>
          <v-flex class="font-weight-light">
            Ou selecione os atributos para continuar:
          </v-flex>
        </v-container>
        <v-container>
          <v-flex
            v-if="!hasAppointments"
            class="mb-2"
          >
            Selecione os atributos para continuar:
          </v-flex>
          <v-col>
            <v-row class="mb-1">
              <v-select
                v-model="selectedClinic"
                :disabled="disableClinicSelect"
                :items="clinics"
                label="Clínica"
                item-text="name"
                item-value="id"
                hide-details
                filled
                :menu-props="{ bottom: true, offsetY: true }"
                class="popup__fields"
              />
            </v-row>
            <v-row>
              <v-select
                v-model="selectedSpeciality"
                :disabled="disableSpecialitySelect"
                :items="specialityList"
                label="Especialidade"
                :menu-props="{ bottom: true, offsetY: true }"
                item-text="name"
                item-value="id"
                hide-details
                filled
                class="popup__fields"
              />
            </v-row>
            <v-row v-if="shouldShowEncounterFormSelect">
              <v-flex class="font-weight-bold mt-4">
                Selecione qual template de prontuário deve ser utilizado:
              </v-flex>
              <v-select
                v-model="selectedEncounterForm"
                :items="encounterFormsFromSpecialitySelected"
                label="Template de prontuário"
                :menu-props="{ bottom: true, offsetY: true }"
                item-text="label"
                item-value="id"
                hide-details
                filled
                class="popup__fields"
              />
            </v-row>
          </v-col>
        </v-container>
        <v-btn
          large
          block
          class="btn btn-primary mt-4"
          :loading="isLoadingCreateEncounter"
          :disabled="shouldDisableEncounterButton"
          @click="emitEncounterOptions()"
        >
          Novo atendimento
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  find,
  isEmpty,
  isNil,
  map,
  prop,
  propEq,
  reject,
} from 'ramda'

const IMMEDIATE_CARE_CLINIC_ID = 'cli_ckqvd10nh07gb0m039u5s7qs5'

export default {
  emits: [
    'update:showDialog',
    'update:encounterOptions',
  ],
  components: {
    AppointmentCard: () => import('@/components/Common/AppointmentCard'),
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    isAnnotation: {
      type: Boolean,
      required: false,
      default: false,
    },
    isImmediateCare: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedClinic: null,
      selectedAppointment: null,
      specialityList: [],
      selectedSpeciality: null,
      disableClinicSelect: false,
      disableSpecialitySelect: false,
      specialitiesWithEncounterForms: [],
      shouldShowEncounterFormSelect: true,
      selectedEncounterForm: null,
      isLoadingCreateEncounter: false,
    }
  },
  computed: {
    ...mapGetters({
      appointments: 'agenda/appointments',
      clinics: 'clinic/clinics',
      user: 'authentication/user',
    }),
    show: {
      get() {
        return this.showDialog
      },
      set(value) {
        this.$emit('update:showDialog', value)
      },
    },
    hasAppointments() {
      return this.appointments.length > 0
    },
    encounterFormsFromSpecialitySelected() {
      if (!this.specialitiesWithEncounterForms) return []
      const speciality = find(
        propEq(this.selectedSpeciality, 'id'),
        this.specialitiesWithEncounterForms,
      )
      if (!speciality) return []
      return speciality.encounterForms
    },
    shouldDisableEncounterButton() {
      if (this.selectedAppointment) return false
      return !this.selectedSpeciality || !this.selectedClinic
    },
  },
  watch: {
    async show(value) {
      this.isLoadingCreateEncounter = false
      if (value) {
        await this.listClinic()
        await this.getProfessionalSpeciality()
        await this.getSpecialitiesEncounterForms()
      }

      if (value && this.isImmediateCare) {
        this.selectedClinic = IMMEDIATE_CARE_CLINIC_ID
        this.disableClinicSelect = true
      } else {
        this.updateList(
          'disableClinicSelect',
          this.clinics,
          'selectedClinic',
        )
      }
      this.updateList(
        'disableSpecialitySelect',
        this.specialityList,
        'selectedSpeciality',
      )

      if (
        value
        && this.isImmediateCare
        && this.disableClinicSelect
        && this.disableSpecialitySelect
      ) {
        this.emitEncounterOptions()
      }
    },
    encounterFormsFromSpecialitySelected(newValue) {
      this.shouldShowEncounterFormSelect = true
      if (
        isNil(newValue)
        || isEmpty(newValue)
        || this.isAnnotation
        || this.isImmediateCare
      ) {
        this.selectedEncounterForm = null
        this.shouldShowEncounterFormSelect = false
      } else if (newValue.length === 1 && !this.isAnnotation) {
        this.selectedEncounterForm = newValue[0].id
        this.shouldShowEncounterFormSelect = false
      }
    },
    clinics(newValue) {
      this.updateList(
        'disableClinicSelect',
        newValue,
        'selectedClinic',
      )
    },
    specialityList(newValue) {
      this.updateList(
        'disableSpecialitySelect',
        newValue,
        'selectedSpeciality',
      )
    },
  },
  methods: {
    ...mapActions({
      listClinic: 'clinic/listClinic',
      listSpeciality: 'speciality/listSpeciality',
      listProfessional: 'professional/listProfessional',
    }),
    closeDialog() {
      this.show = false
    },
    updateList(selectToDisabled, value, selected) {
      this[selectToDisabled] = false
      if (!value) return
      if (value.length === 1) {
        this[selected] = value[0].id
        this[selectToDisabled] = true
      }
    },
    async getProfessionalSpeciality() {
      if (isEmpty(this.specialityList)) {
        const params = {
          id: this.user.professionalId,
        }
        const professionals = await this.listProfessional(params)
        this.specialityList = professionals[0]?.specialities
      }
    },
    async getSpecialitiesEncounterForms() {
      if (isEmpty(this.specialityList)) return

      const params = {
        id: map(prop('id'), this.specialityList),
        withEncounterForms: true,
      }

      this.specialitiesWithEncounterForms = await this.listSpeciality(params)
    },
    fillFiltersByAppointment({ appointmentData }) {
      if (appointmentData) {
        this.selectedAppointment = appointmentData
        this.selectedClinic = this.selectedAppointment.clinicId
        this.selectedSpeciality = this.selectedAppointment.specialityId
      }
    },
    emitEncounterOptions() {
      this.isLoadingCreateEncounter = true
      const options = {
        appointmentData: this.selectedAppointment,
        clinicId: this.selectedClinic,
        specialityId: this.selectedSpeciality,
        encounterFormId: this.selectedEncounterForm,
      }

      this.$emit('update:encounterOptions', reject(isNil, options))
    },
  },
}
</script>


<style lang="stylus" scoped>
.popup__container
  padding-top 15px

.popup__title
  font-size 17px !important

.popup__fields
  width 250px

</style>
