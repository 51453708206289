<template>
  <div>
    <care-cycle-filters
      @filterUpdate="updateParams"
    />

    <v-container
      :class="{
        'container--fluid': true,
        'px-12': $vuetify.breakpoint.smAndUp,
      }"
    >
      <div class="data-spacer" />
      <v-data-table
        :headers="headers"
        :items="careCycleList"
        hide-default-footer
        class="data-table__header"
        no-data-text="Sem itens para exibir"
        @change="listCareCycleResults"
        @click:clear="listCareCycleResults"
      >
        <template v-slot:item="{item}">
          <tr>
            <td>{{ item.disease.version }}</td>
            <td>{{ item.disease.code }}</td>
            <td>{{ item.disease.name }}</td>
            <td>
              {{ verifyHighlight(item).label }}
              <span v-if="item.highlightExpirationTimeByDays">
                {{ showHighlightTimeByOptions(item.highlightExpirationTimeByDays) }}
              </span>
            </td>
            <td>{{ item.displayOrder }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="button-spacer font-weight-lightbold"
                    text
                    icon
                    @click="editDialog(item)"
                    v-on="on"
                  >
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </template>
                <span>Configurar</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
      <care-cycle-pagination
        :page="filters.page"
        @changePage="setPage"
      />
    </v-container>

    <v-dialog
      v-model="showEditcareCycleDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
    >
      <v-card>
        <v-card-title
          class="card-dialog__title"
        >
          Editar Ciclo de Cuidado
          <v-spacer />
          <v-btn
            icon
            @click="exitEditDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <edit-care-cycle-settings
            v-if="showEditcareCycleDialog"
            :care-cycle-data="editedCareCycle"
            @exitEditDialog="exitEditDialog"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  always,
  cond,
  find,
  propEq,
  propIs,
  values,
} from 'ramda'
import { mapActions, mapGetters } from 'vuex'
import careCycleOptions from '@/enums/careCycle'
import CareCycleFilters from './CareCycleFilters'

const { TIME_OPTIONS } = careCycleOptions

export default {
  name: 'CareCycleList',
  components: {
    CareCycleFilters,
    EditCareCycleSettings: () => import('./EditCareCycleSettings'),
    CareCyclePagination: () => import('./CareCyclePagination'),
  },
  data() {
    return {
      notFoundMessage: 'Não encontrado',
      showEditcareCycleDialog: false,
      editedCareCycle: {},
      deletedcareCycle: {},
      filters: {
        page: 1,
      },
      listLoaded: true,
      count: '',
      numberOfPages: null,
      headers: [
        {
          text: 'Tipo',
          value: 'disease.version',
          width: '5%',
        },
        {
          text: 'Código',
          value: 'disease.code',
          width: '5%',
        },
        {
          text: 'Nome',
          value: 'disease.name',
          width: '40%',
        },
        {
          text: 'Tempo de Destaque do formulário',
          value: '',
          width: '40%',
        },
        {
          text: 'Ordem',
          value: 'displayOrder',
          width: '5%',
        },
        {
          text: 'Ação',
          value: '',
          width: '5%',
          sortable: false,
        },
      ],
      careCycleTimeOptions: values(TIME_OPTIONS),
    }
  },
  computed: {
    ...mapGetters({
      careCycleFilters: 'careCycle/careCycleFilters',
      careCycleList: 'careCycle/careCycleList',
    }),
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.listCareCycleResults()
      },
    },
  },
  mounted() {
    this.listCareCycleResults()
  },
  methods: {
    ...mapActions({
      setCareCycleFilters: 'careCycle/setCareCycleFilters',
      listCareCycle: 'careCycle/listCareCycle',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    setPage(page) {
      this.filters.page = page
      this.setCareCycleFilters(this.filters)
    },
    updateParams(filters) {
      this.setCareCycleFilters(this.filters)
      this.filters = filters
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    listCareCycleResults() {
      this.$nextTick(async () => {
        try {
          await this.listCareCycle(this.careCycleFilters)
          this.listLoaded = false
        } catch (e) {
          this.buildSnackbar('error', 'Erro Interno!')
        }
      })
    },
    showHighlightTimeByOptions(highlightExpirationTimeByDays) {
      const expirationTime = find(
        propEq(highlightExpirationTimeByDays, 'value'),
      )(this.careCycleTimeOptions)
      return expirationTime.label
    },
    editDialog(careCycleData) {
      this.editedCareCycle = careCycleData
      this.editedCareCycle.highlightType = this
        .verifyHighlight(careCycleData).value

      this.showEditcareCycleDialog = true
    },
    async exitEditDialog() {
      this.careCycle = {}
      this.editedCareCycle = {}
      this.showEditcareCycleDialog = false
      this.listCareCycleResults()
    },
    verifyHighlight(careCycle) {
      return cond([
        [
          propIs(Number, 'highlightExpirationTimeByDays'),
          always({ label: 'Destacado por período', value: 'period' }),
        ], [
          propEq(false, 'isHighlighted'),
          always({ label: 'Não destacado', value: 'never' }),
        ], [
          propEq(null, 'highlightExpirationTimeByDays'),
          always({ label: 'Sempre em destaque', value: 'always' })],
      ])(careCycle)
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'
.v-card
  padding 10px
  border-radius 6px
.v-btn
  letter-spacing 1px
.v-btn.v-btn--large
  margin-top 0px
  height 56px
  border-radius 8px
.v-btn.v-btn--text
  border solid 1px thin-gray

.v-btn.btn-dialog
  padding 15px
  @media all and (min-width 601px)
    width 190px

.data-spacer
  height 20px
  width 100%
.button-spacer
  margin 0.1em !important
</style>
