import axios from '@/lib/axios'
import { EMR_QUESTIONS } from 'amparo-enums'
import {
  isEmpty,
  isNil,
  pluck,
} from 'ramda'
import isNilOrEmpty from '@/utils/dataValidators'

const { questions } = EMR_QUESTIONS

const initialState = {
  shouldSendReferralSms: false,
  preferredNetworkReferrals: null,
  preferredNetworkReferralProfessionals: null,
}

const mutations = {
  SET_SHOULD_SEND_REFERRAL_SMS(state, shouldSendReferralSms) {
    state.shouldSendReferralSms = shouldSendReferralSms
  },
  SET_PREFERRED_NETWORK_REFERRALS(state, preferredNetworkReferrals) {
    state.preferredNetworkReferrals = preferredNetworkReferrals
  },
  SET_PREFERRED_NETWORK_REFERRAL_PROFESSIONALS(state, preferredNetworkReferralProfessionals) {
    state.preferredNetworkReferralProfessionals = preferredNetworkReferralProfessionals
  },
}

const setReferrals = (dispatch, commit, { referrals, professionals, shouldSendSms }) => {
  dispatch('setPreferredNetworkReferrals', referrals)
  dispatch('setPreferredNetworkReferralProfessionals', professionals)
  commit('SET_SHOULD_SEND_REFERRAL_SMS', shouldSendSms)
  return {
    preferredNetworkReferrals: referrals,
    preferredNetworkReferralProfessionals: professionals,
  }
}

const getProfessionalsFilteredBySpeciality = async (
  dispatch,
  { preferredNetworkId, medicalReferrals },
) => {
  const {
    data: professionals,
  } = await dispatch('getPreferredNetworkProfessionals', preferredNetworkId)

  const referralSpecialities = pluck('specialityId', medicalReferrals)

  return professionals.filter(
    professional => referralSpecialities.includes(professional.specialityId),
  )
}

const getReferralsFilteredBySpeciality = ({ referralProfessionals, medicalReferrals }) => {
  const filteredProfessionalSpecialities = pluck('specialityId', referralProfessionals)
  return medicalReferrals.filter(
    referral => filteredProfessionalSpecialities.includes(referral.specialityId),
  )
}

const actions = {
  setShouldSendReferralSms({ commit }, shouldSendReferralSms) {
    commit('SET_SHOULD_SEND_REFERRAL_SMS', shouldSendReferralSms)
  },
  resetShouldSendReferralSms({ commit }) {
    commit('SET_SHOULD_SEND_REFERRAL_SMS', false)
  },
  setPreferredNetworkReferrals({ commit }, preferredNetworkReferrals) {
    commit('SET_PREFERRED_NETWORK_REFERRALS', preferredNetworkReferrals)
  },
  resetPreferredNetworkReferrals({ commit }) {
    commit('SET_PREFERRED_NETWORK_REFERRALS', null)
  },
  setPreferredNetworkReferralProfessionals({ commit }, preferredNetworkReferralProfessionals) {
    commit('SET_PREFERRED_NETWORK_REFERRAL_PROFESSIONALS', preferredNetworkReferralProfessionals)
  },
  resetPreferredNetworkReferralProfessionals({ commit }) {
    commit('SET_PREFERRED_NETWORK_REFERRAL_PROFESSIONALS', null)
  },
  validatePreferredNetworkFile(_, { xlsxData, healthMaintenanceOrganizationId }) {
    const params = {
      xlsxData: JSON.stringify(xlsxData),
      healthMaintenanceOrganizationId,
    }

    return new Promise((resolve, reject) => {
      axios.post('/preferred_network/validate_file', params)
        .then(resolve)
        .catch(reject)
    })
  },
  getPreferredNetworkExampleFile() {
    return new Promise((resolve, reject) => {
      axios.get('/preferred_network/example_file', {
        responseType: 'blob',
      })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
  getPreferredNetworkProfessionals(_, preferredNetworkId) {
    return new Promise((resolve, reject) => {
      axios.get(`/preferred_network_professional/${preferredNetworkId}`)
        .then(resolve)
        .catch(reject)
    })
  },
  addPreferredNetworkProfessionals(_, attributes) {
    return new Promise((resolve, reject) => {
      axios.post('/preferred_network_professional', attributes)
        .then(resolve)
        .catch(reject)
    })
  },
  removePreferredNetworkProfessionals(_, attributes) {
    return new Promise((resolve, reject) => {
      axios.delete('/preferred_network_professional', { data: attributes })
        .then(resolve)
        .catch(reject)
    })
  },
  resetPreferredNetworkProfessionals(_, attributes) {
    return new Promise((resolve, reject) => {
      axios.put('/preferred_network_professional', attributes)
        .then(resolve)
        .catch(reject)
    })
  },
  sendPreferredNetworkReferralSms({ rootGetters }, attributes) {
    const userAccess = rootGetters['authentication/userHasAccessToFunctionality']
    if (!userAccess.preferredNetworkSms) return

    axios.post('/preferred_network_referral/send_referral_sms', attributes)
  },
  getPreferredNetworkReferralsAndProfessionals: async ({ rootGetters, dispatch, commit }) => {
    const encounter = rootGetters['emr/encounter']
    const patient = await dispatch('patient/getPatient', encounter.patientId, { root: true })

    const medicalReferrals = encounter?.data?.[questions.medicalReferral]?.value
    const preferredNetworkId = patient?.healthProduct?.preferredNetwork?.[0]?.id

    if (isNilOrEmpty(medicalReferrals) || isNil(preferredNetworkId)) {
      return setReferrals(dispatch, commit, {
        referrals: null,
        professionals: null,
        shouldSendSms: !isNilOrEmpty(medicalReferrals),
      })
    }

    const filteredProfessionals = await getProfessionalsFilteredBySpeciality(dispatch, {
      preferredNetworkId,
      medicalReferrals,
    })

    if (isEmpty(filteredProfessionals)) {
      return setReferrals(dispatch, commit, {
        referrals: [],
        professionals: [],
        shouldSendSms: true,
      })
    }

    const filteredReferrals = getReferralsFilteredBySpeciality({
      referralProfessionals: filteredProfessionals,
      medicalReferrals,
    })

    return setReferrals(dispatch, commit, {
      referrals: filteredReferrals,
      professionals: filteredProfessionals,
      shouldSendSms: true,
    })
  },
}

const getters = {
  shouldSendReferralSms: state => state.shouldSendReferralSms,
  preferredNetworkReferrals: state => state.preferredNetworkReferrals,
  preferredNetworkReferralProfessionals: state => state.preferredNetworkReferralProfessionals,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
