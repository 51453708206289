<template>
  <div>
    <router-view />
    <component-title />
    <preferred-network />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import PreferredNetwork from './PreferredNetworkContainer'

export default {
  name: 'EditPreferredNetwork',
  components: {
    ComponentTitle,
    PreferredNetwork,
  },
}
</script>
