<template>
  <div>
    <div
      class="user-dropdown"
    >
      <v-menu
        transition="slide-y-transition"
        open-on-click
        bottom
        right
        attach
        offset-y
      >
        <template
          v-slot:activator="{ on }"
          class="user-button"
        >
          <div v-on="on">
            <span
              v-if="user"
              class="a-link"
            >
              {{ user.firstAndLastName }}
            </span>
            <v-icon>mdi-menu-down</v-icon>
          </div>
        </template>
        <v-list>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            class="user-dropdown-tile"
            @click="item.method"
          >
            <v-list-item-title class="user-dropdown-itens">
              <v-icon class="user-dropdown-icon">
                {{ item.icon }}
              </v-icon>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-dialog
      v-model="changePassword"
      transition="dialog-bottom-transition"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title
          class="card-dialog__title"
        >
          Alterar Senha
          <v-spacer />
          <v-btn
            icon
            @click="togglePasswordDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <change-password
          :user-data="user"
          @exitDialog="togglePasswordDialog"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  not,
} from 'ramda'

export default {
  name: 'UserDropdown',
  components: {
    ChangePassword: () => import('./ChangePassword'),
  },
  data() {
    return {
      changePassword: false,
      items: [
        { title: 'Alterar senha', icon: 'mdi-lock', method: this.togglePasswordDialog },
        { title: 'Sair', icon: 'mdi-logout-variant', method: this.logout },
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: 'authentication/user',
    }),
  },
  methods: {
    ...mapActions({
      userLogout: 'authentication/userLogout',
    }),
    togglePasswordDialog() {
      this.changePassword = not(this.changePassword)
    },
    logout() {
      if (this.$socket) {
        this.$socket.emit('force_disconnection', {})
      }
      return this.userLogout()
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../../style/_core/fonts.styl'

.user-dropdown-tile:hover
  background-color: rgba(0, 0, 0, 0.1);
  cursor pointer

.user-dropdown
  height 100%

.user-button
  display flex
  align-items center
  height 100%
  font-size 16px

.user-dropdown-itens
  display flex
  align-items center

.user-dropdown-icon
  margin-right 15px
</style>
