export default [
  {
    id: 'car_tea_ckn7rbkez0000fpdqge9aa3hm',
    name: 'Equipe Lapa',
    telephone: '11111111111',
    score: 3969,
    isTele: false,
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    professionals: [
      {
        id: 'pro_cl6wq3aow001h0jol4xge53oo',
        name: 'Enf. Jacó',
        biologicalSex: 'M',
        telphone: '21111111111',
        documentType: 'ABQ',
        documentNumber: '1111111',
        documentState: 'RJ',
        rg: '408316032',
        cpf: '84514207292',
        cnpj: null,
        email: 'joareisjpk@gmail.com',
        profession: {
          id: 'profession_cjz3an5zj00020nxbgcea9qh5',
          name: 'Enfermeiro',
        },
        specialities: [
          {
            id: 'spe_cjyisn3y90016apvo3eu1bbas',
            name: 'Enfermeiro de família',
            isAps: true,
          },
        ],
      },
      {
        id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
        name: 'Devs + Pro (.ro)',
        biologicalSex: 'M',
        telphone: '(11) 99999-9999',
        documentType: 'CRP',
        documentNumber: '000009',
        documentState: 'SP',
        rg: '175575009',
        cpf: '79462651400',
        cnpj: null,
        email: 'brutus@amparosaude.com',
        profession: {
          id: 'profession_cjz3an5zk00030nxbejbsg6wj',
          name: 'Médico',
        },
        specialities: [
          {
            id: 'spe_cjy92cswd001yi3voc4sjca8c',
            name: 'Médico de família',
            isAps: true,
          },
        ],
      },
    ],
  },
  {
    id: 'car_tea_ckn7rbkf00001fpdq60vk9pse',
    name: 'Equipe Pinheiros',
    telephone: '19122220222',
    score: 525,
    isTele: false,
    clinic: {
      id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
      name: 'Pinheiros',
    },
    professionals: [
      {
        id: 'pro_ck3ogpnob0003mhpq0jp47o2l',
        name: 'Devs + Pro',
        biologicalSex: 'M',
        telphone: '11999999999',
        documentType: 'CRP',
        documentNumber: '000010',
        documentState: 'SP',
        rg: '175575009',
        cpf: '54616442797',
        cnpj: null,
        email: 'devs+pro@amparosaude.com',
        profession: {
          id: 'profession_cjz3an5zk00030nxbejbsg6wj',
          name: 'Médico',
        },
        specialities: [
          {
            id: 'spe_cjy92cswd001yi3voc4sjca8c',
            name: 'Médico de família',
            isAps: true,
          },
        ],
      },
      {
        id: 'pro_ck3ogpnob0001mhpq5of978kw',
        name: 'Brutus',
        biologicalSex: 'M',
        telphone: '11999999999',
        documentType: 'CRP',
        documentNumber: '000008',
        documentState: 'SP',
        rg: '175575009',
        cpf: '54218044457',
        cnpj: null,
        email: 'brutus@amparosaude.com',
        profession: {
          id: 'profession_cjz3an5zj00020nxbgcea9qh5',
          name: 'Enfermeiro',
        },
        specialities: [
          {
            id: 'spe_cjyisn3y90016apvo3eu1bbas',
            name: 'Enfermeiro de família',
            isAps: true,
          },
        ],
      },
    ],
  },
  {
    id: 'car_tea_1f2d5a2311424b6f9877d10cb',
    name: 'Equipe Teste',
    telephone: '11111111111',
    score: 0,
    isTele: false,
  },
]
