import axios from '@/lib/axios'

const initialState = {
  loading: false,
  specialities: [],
}

const mutations = {
  SET_SPECIALITIES(state, payload) {
    state.specialities = payload
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
}

const actions = {
  listSpeciality({ commit }, filters = {}) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.get('speciality', { params: filters })
        .then(({ data: { specialities } }) => {
          commit('SET_SPECIALITIES', specialities)
          resolve(specialities)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
}

const getters = {
  specialities: state => state.specialities,
  loading: state => state.loading,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
