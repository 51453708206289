import axios from '@/lib/axios'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'

const initialState = {
  loading: false,
  enterprises: [],
}

const mutations = {
  SET_ENTERPRISES(state, payload) {
    state.enterprises = payload
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
}

const actions = {
  listEnterprise({ commit }, filters = {}) {
    const params = removeNilOrEmpty(filters)
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.get('enterprise', { params })
        .then(({ data: { enterprises } }) => {
          commit('SET_ENTERPRISES', enterprises)
          resolve(enterprises)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
}

const getters = {
  enterprises: state => state.enterprises,
  loading: state => state.loading,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
