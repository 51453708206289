export default {
  clinics: [
    {
      id: 'cli_cl1e0chpy0000zpdq7jidc388',
      name: 'Aeroporto - SBBR',
      startTime: '00:00:00',
      endTime: '23:59:00',
      storedAt: null,
      telephone: '1140201766',
      cnpj: null,
      companyName: 'Rosebud Serviços administrativos e Clinica Médica LTDA',
      eplPrinterAddress: null,
      workSpace: {
        id: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
        name: 'Amparo Saúde',
      },
      address: {
        state: 'DF',
        city: 'Brasília',
        zipCode: '71608900',
        neighborhood: 'Lago Sul',
        street: '',
        number: '',
        complement: null,
      },
    },
    {
      id: 'cli_ck9b7yjf3000195vohxz60nio',
      name: 'Amparo Tele',
      startTime: '07:00:00',
      endTime: '19:00:00',
      storedAt: null,
      telephone: '1140201766',
      cnpj: null,
      companyName: 'Rosebud Serviços administrativos e Clinica Médica LTDA',
      eplPrinterAddress: null,
      workSpace: {
        id: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
        name: 'Amparo Saúde',
      },
      address: {
        state: 'SP',
        city: 'São Paulo',
        zipCode: '05074050',
        neighborhood: 'Lapa',
        street: 'Rua Clemente Álvares',
        number: '31',
        complement: null,
      },
    },
    {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
      startTime: '07:00:00',
      endTime: '19:00:00',
      storedAt: null,
      telephone: '1140201766',
      cnpj: '28732903000186',
      companyName: 'Rosebud Serviços administrativos e Clinica Médica LTDA',
      eplPrinterAddress: null,
      workSpace: {
        id: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
        name: 'Amparo Saúde',
      },
      address: {
        state: 'SP',
        city: 'São Paulo',
        zipCode: '05074050',
        neighborhood: 'Lapa',
        street: 'Rua Clemente Álvares',
        number: '31',
        complement: null,
      },
    },
    {
      id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
      name: 'Pinheiros',
      startTime: '07:00:00',
      endTime: '19:00:00',
      storedAt: null,
      telephone: '1140201766',
      cnpj: '28732903000429',
      companyName: 'Rosebud Serviços administrativos e Clinica Médica LTDA',
      eplPrinterAddress: null,
      workSpace: {
        id: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
        name: 'Amparo Saúde',
      },
      address: {
        state: 'SP',
        city: 'São Paulo',
        zipCode: '05074050',
        neighborhood: 'Lapa',
        street: 'Rua Clemente Álvares',
        number: '31',
        complement: null,
      },
    },
    {
      id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
      name: 'Pinheiros',
      startTime: '07:00:00',
      endTime: '19:00:00',
      storedAt: null,
      telephone: '1140201766',
      cnpj: '28732903000429',
      companyName: 'Rosebud Serviços administrativos e Clinica Médica LTDA',
      eplPrinterAddress: null,
      workSpace: {
        id: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
        name: 'Amparo Saúde',
      },
      address: {
        state: 'SP',
        city: 'São Paulo',
        zipCode: '05074050',
        neighborhood: 'Lapa',
        street: 'Rua Clemente Álvares',
        number: '31',
        complement: null,
      },
    },
  ],
}
