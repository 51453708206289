<template>
  <div>
    <component-title />
    <user-feedback-list-container />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import UserFeedbackListContainer from './UserFeedbackListContainer'

export default {
  name: 'UserFeedbackList',
  components: {
    ComponentTitle,
    UserFeedbackListContainer,
  },
}
</script>
