var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.encounterData)?_c('v-layout',{class:{
      'my-6 mx-2': _vm.row,
      'column': _vm.column,
    }},[_c('v-flex',{class:{
        'card__infos-container--partial-width': _vm.row,
        'card__infos-container--full-width': _vm.column,
      },attrs:{"column":""}},[_c('v-flex',{attrs:{"column":""}},[_c('v-flex',{staticClass:"card__infos-sub-title"},[_vm._v(" HPMA ")]),_c('v-flex',{staticClass:"card__infos-name"},[_vm._v(" • "+_vm._s(_vm.hpma)+" ")])],1),_c('v-flex',{attrs:{"column":""}},[_c('v-flex',{staticClass:"card__infos-sub-title mt-4"},[_vm._v(" Queixa Principal ")]),_c('v-flex',{staticClass:"card__infos-name"},[_vm._v(" • "+_vm._s(_vm.principalComplaint)+" ")])],1)],1),_c('v-flex',{class:{
        'card__infos-container--partial-width': _vm.row,
        'card__infos-container--full-width': _vm.column,
      },attrs:{"ml-6":"","column":""}},[_c('v-flex',{attrs:{"column":""}},[_c('v-flex',{staticClass:"card__infos-sub-title"},[_vm._v(" Sinais de Alerta ")]),_c('v-flex',{staticClass:"card__infos-name"},[_vm._v(" • "+_vm._s(_vm.medicalCertificate.alertSignals ? 'Sim' : 'Não')+" ")])],1),_c('v-flex',{attrs:{"column":""}},[_c('v-flex',{staticClass:"card__infos-sub-title mt-4"},[_vm._v(" Orientações ")]),_c('v-flex',{staticClass:"card__infos-name"},[_vm._v(" • "+_vm._s(_vm.orientation)+" ")])],1)],1)],1):_vm._e(),(_vm.row)?_c('v-divider',{staticClass:"mx-2"}):_vm._e(),(_vm.encounterData)?_c('v-layout',{class:{
      'my-6 mb-2 mx-2': _vm.row,
      'column': _vm.column,
    }},[_c('v-flex',{class:{
        'card__infos-container--partial-width': _vm.row,
        'card__infos-container--full-width': _vm.column,
      },attrs:{"column":""}},[_c('v-flex',{staticClass:"card__group-title"},[_vm._v(" Conduta ")]),(_vm.conditions)?_c('v-flex',{attrs:{"shrink":"","column":""}},[_c('v-flex',{staticClass:"card__infos-sub-title"},[_vm._v(" Hipoteses diagnósticas ")]),_vm._l((_vm.conditions),function(condition,index){return _c('v-flex',{key:index,staticClass:"card__infos-name"},[_vm._v(" • "+_vm._s(_vm.getName(condition))+" "+_vm._s(condition.status ? `- ${condition.status}` : '')+" ")])})],2):_vm._e(),_c('v-flex',{attrs:{"column":""}},[_c('v-flex',{staticClass:"card__infos-sub-title mt-4"},[_vm._v(" Novas prescrições ")]),(_vm.treatments)?_c('v-flex',_vm._l((_vm.treatments),function(treatment,index){return _c('v-flex',{key:index,staticClass:"card__infos-name"},[_vm._v(" • "+_vm._s(treatment.name)+" - "+_vm._s(treatment.status)+" ")])}),1):_c('v-flex',{staticClass:"card__infos-name"},[_vm._v(" • Nenhuma ")])],1)],1),_c('v-flex',{class:{
        'card__infos-container--partial-width': _vm.row,
        'card__infos-container--full-width': _vm.column,
      },attrs:{"ml-6":"","column":""}},[_c('v-flex',{attrs:{"column":""}},[_c('v-flex',{staticClass:"card__infos-sub-title"},[_vm._v(" Atestado ")]),_c('v-flex',{staticClass:"card__infos-name"},[_vm._v(" • "+_vm._s(_vm.medicalCertificate.conditionalValue ? 'Sim' : 'Não')+" ")])],1),_c('v-flex',{attrs:{"column":""}},[_c('v-flex',{staticClass:"card__infos-sub-title mt-4"},[_vm._v(" Solicitações de exames ")]),(_vm.examRequests)?_c('v-flex',_vm._l((_vm.examRequests),function(exam,index){return _c('v-flex',{key:index,staticClass:"card__infos-name"},[_vm._v(" • "+_vm._s(_vm.getName(exam))+" ")])}),1):_c('v-flex',{staticClass:"card__infos-name"},[_vm._v(" • Nenhum ")])],1),(_vm.medicalReferral)?_c('v-flex',{attrs:{"column":""}},[_c('v-flex',{staticClass:"card__infos-sub-title mt-4"},[_vm._v(" Encaminhamentos ")]),(_vm.medicalReferral)?_c('v-flex',_vm._l((_vm.medicalReferral),function(referral,index){return _c('v-flex',{key:index,staticClass:"card__infos-name"},[_vm._v(" • "+_vm._s(referral.specialityName)+" ")])}),1):_c('v-flex',{staticClass:"card__infos-name"},[_vm._v(" • Nenhum ")])],1):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }