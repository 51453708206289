<template>
  <div>
    <component-title />
    <open-encounters-list-container />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import OpenEncountersListContainer from './OpenEncountersListContainer'

export default {
  name: 'OpenEncountersListPage',
  components: {
    ComponentTitle,
    OpenEncountersListContainer,
  },
}
</script>
