import { mergeAll } from 'ramda'
import moment from 'moment'

export const parseAgendaFilters = (filters) => {
  const usedFilters = []
  if (filters.clinic) usedFilters.push({ clinicId: filters.clinic.id })
  if (filters.speciality) usedFilters.push({ specialityId: filters.speciality.id })
  if (filters.professional) usedFilters.push({ professionalId: filters.professional.id })
  if (filters.date) usedFilters.push({ date: filters.date })
  if (filters.startDate) usedFilters.push({ startDate: filters.startDate })
  if (filters.startTime) usedFilters.push({ startTime: filters.startTime })
  if (filters.endDate) usedFilters.push({ endDate: filters.endDate })
  if (filters.patient) usedFilters.push({ patientId: filters.patient.id })
  if (filters.status) usedFilters.push({ status: filters.status })
  if (filters.id) usedFilters.push({ id: filters.id })
  if (filters.includeClinic) usedFilters.push({ includeClinic: filters.includeClinic })
  if (filters.type) usedFilters.push({ type: filters.type })

  return mergeAll(usedFilters)
}

const days = {
  0: 'Domingo',
  1: 'Segunda-feira',
  2: 'Terça-feira',
  3: 'Quarta-feira',
  4: 'Quinta-feira',
  5: 'Sexta-feira',
  6: 'Sábado',
}

export const getDayByIndex = index => days[index]

export const buildRecurrenceDates = ({
  startDate,
  endDate,
  daysOfTheWeek,
  recurrence,
}) => {
  const current = moment(startDate)
  const end = moment(endDate)

  const dates = []

  while (!current.isAfter(end)) {
    const currentWeek = current.diff(moment(startDate), 'weeks') + 1
    if (daysOfTheWeek.includes(current.day()) && currentWeek % recurrence === 1) {
      dates.push({
        startDate: current.format('YYYY-MM-DD'),
        endDate: current.format('YYYY-MM-DD'),
        dayOfTheWeek: current.day(),
      })
    }

    current.add(1, 'day')
  }

  return dates
}

export default {
  parseAgendaFilters,
  buildRecurrenceDates,
  getDayByIndex,
}
