<template>
  <div>
    <component-title />
    <care-line-list />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import { mapActions } from 'vuex'
import CareLineList from './CareLineList'

export default {
  name: 'CareLineSettings',
  components: { ComponentTitle, CareLineList },
  async mounted() {
    await this.listCareLine()
  },
  methods: {
    ...mapActions({
      listCareLine: 'careLine/listCareLine',
    }),
  },
}
</script>
