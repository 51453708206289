import axios from '@/lib/axios'

const initialState = {
  loadingCareLineExam: false,
  exams: [],
}
const mutations = {
  SET_CARE_LINE_EXAMS(state, payload) {
    state.careLineExams = payload
  },
  SET_LOADING(state, payload) {
    state.loadingCareLineExam = payload
  },
}

const actions = {
  listCareLineExams({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.get('/emr/care_line_exam', {
        params,
      })
        .then(({ data: { careLineExams } }) => {
          commit('SET_CARE_LINE_EXAMS', careLineExams)
          resolve(careLineExams)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  createCareLineExam({ commit }, attributes) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.post('/emr/care_line_exam', attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  updateCareLineExam({ commit }, { id, attributes }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.patch(`/emr/care_line_exam/${id}`, attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  deleteCareLineExam(_, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/emr/care_line_exam/${id}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
  deleteCareLineExamTagOption(_, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/emr/care_line_exam_tag_option/${id}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
}

const getters = {
  careLineExams: state => state.careLineExams,
  loadingCareLineExam: state => state.loadingCareLineExam,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
