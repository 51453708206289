import {
  debounce,
} from 'lodash'
import {
  isEmpty,
  isNil,
  length,
} from 'ramda'

const getPatient = {
  data() {
    return {
      patientList: [],
      isLoadingPatient: false,
      searchPatientField: null,
      allowToClear: true,
    }
  },
  methods: {
    clearSearchResults() {
      this.patientList = []
    },
    buildSearchAnythingParams(keyword) {
      const params = { name: keyword }
      if (parseInt(keyword, 10)) {
        delete params.name
        params.cpf = keyword.replace(/[^0-9]/g, '')
      }
      return params
    },
    searchAnythingByKeyword: debounce(function searchAnythingByKeyword(keyword) {
      if (this.allowToClear) {
        this.clearSearchResults()
      }
      if (isNil(keyword) || isEmpty(keyword) || length(keyword) < 3) return
      this.isLoadingPatient = true
      const params = this.buildSearchAnythingParams(keyword)
      this.$http.get('patient', { params })
        .then((res) => {
          this.patientList = res.data.patients
        })
        .catch(err => err)
        .finally(() => { this.isLoadingPatient = false })
    }, 500),
  },
  watch: {
    searchPatientField(keyword) {
      if (this.selectedResponsible) return
      if (keyword) this.searchAnythingByKeyword(keyword)
    },
  },
}


export default getPatient
