import {
  add,
  and,
  not,
  isNil,
  prop,
  equals,
  path,
} from 'ramda'

const checkHmoHasProduct = (hmo) => {
  if (hmo) {
    const { validationRules } = hmo
    return and(validationRules, not(isNil(prop('healthInsuranceProductNumberLength', validationRules))))
  }
  return false
}

const isValidHealthInsurancePlanNumber = (hmo, hmoNumber) => {
  if (and(hmo, hmoNumber)) {
    const { validationRules } = hmo
    const healthInsurancePlanNumberLength = path(['healthInsurancePlanNumberLength'], validationRules)

    if (not(isNil(healthInsurancePlanNumberLength))) {
      return equals(hmoNumber.replace(/[-.]/g, '').length, validationRules.healthInsurancePlanNumberLength)
    }
  }
  return true
}

const isValidProductCode = (hmo, productNumber) => {
  if (and(hmo, productNumber)) {
    const { validationRules } = hmo
    const healthInsuranceProductNumberLength = path(['healthInsuranceProductNumberLength'], validationRules)

    if (and(validationRules, checkHmoHasProduct(hmo))) {
      return equals(productNumber.replace(/[-.]/g, '').length, healthInsuranceProductNumberLength)
    }
  }
  return true
}

const formatInsurancePlanNumberLength = validationRules => add(
  validationRules.healthInsurancePlanNumberLength,
  validationRules.healthInsuranceProductNumberLength,
)

const isValidInsurancePlanNumberLength = (healthInsurancePlanNumber, validationRules) => {
  if (and(healthInsurancePlanNumber, validationRules)) {
    const healthInsurancePlanNumberLength = formatInsurancePlanNumberLength(validationRules)
    return equals(healthInsurancePlanNumber.length, healthInsurancePlanNumberLength)
  }
  return false
}

export default {
  checkHmoHasProduct,
  isValidHealthInsurancePlanNumber,
  isValidProductCode,
  isValidInsurancePlanNumberLength,
}
