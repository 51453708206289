var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.encounterData)?_c('v-layout',{class:{
      'my-6 mx-2': _vm.row,
      'column': _vm.column,
    }},[(_vm.shouldShowTopic(_vm.hasSubjective))?_c('v-flex',{class:{
        'card__infos-container--partial-width': _vm.row,
        'card__infos-container--full-width': _vm.column,
      },attrs:{"column":""}},[_c('v-flex',{staticClass:"card__group-title"},[_vm._v(" Subjetivo ")]),(!_vm.hasSubjective)?_c('v-flex',{attrs:{"shrink":""}},[_vm._v(" Nenhum campo de subjetivo foi preenchido. ")]):_vm._e(),(_vm.encounterReason)?_c('v-flex',{attrs:{"column":""}},[_c('v-flex',{staticClass:"card__infos-sub-title"},[_vm._v(" Motivo da consulta ")]),_vm._l((_vm.encounterReason),function(answer,index){return _c('v-flex',{key:index,staticClass:"card__infos-name"},[_c('v-flex',{attrs:{"column":""}},[_c('v-flex',[_vm._v(" • "+_vm._s(_vm.getName(answer))+" ")]),(answer.observation)?_c('v-flex',{staticClass:"card__infos-observation font-weight-medium"},[_vm._v(" - "+_vm._s(answer.observation)+" ")]):_vm._e()],1)],1)})],2):_vm._e(),_c('v-flex',{attrs:{"column":""}},[(_vm.subjetiveAdditionalInformation)?_c('v-flex',{staticClass:"card__infos-additional-information"},[(_vm.row)?_c('see-more',{attrs:{"limit-length":_vm.limitAdditionalInformationLength,"text":_vm.subjetiveAdditionalInformation}}):[_vm._v(" "+_vm._s(_vm.subjetiveAdditionalInformation)+" ")]],2):_vm._e()],1)],1):_vm._e(),(_vm.shouldShowTopic(_vm.hasObjective))?_c('v-flex',{class:{
        'card__infos-container--partial-width': _vm.row,
        'card__infos-container--full-width': _vm.column,
      },attrs:{"ml-6":"","column":""}},[_c('v-flex',{staticClass:"card__group-title",attrs:{"shrink":""}},[_vm._v(" Objetivo ")]),(!_vm.hasObjective)?_c('v-flex',{attrs:{"shrink":""}},[_vm._v(" Nenhum campo de objetivo foi preenchido. ")]):_vm._e(),(_vm.objectiveAdditionalInformation)?_c('v-flex',{staticClass:"card__infos-additional-information my-0"},[(_vm.row)?_c('see-more',{attrs:{"limit-length":_vm.limitAdditionalInformationLength,"text":_vm.objectiveAdditionalInformation}}):[_vm._v(" "+_vm._s(_vm.objectiveAdditionalInformation)+" ")]],2):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.row)?_c('v-divider',{staticClass:"my-6 mx-2"}):_vm._e(),(_vm.encounterData)?_c('v-layout',{class:{
      'mt-6 mb-2 mx-2': _vm.row,
      'column': _vm.column,
    }},[(_vm.shouldShowTopic(_vm.hasEvaluation))?_c('v-flex',{class:{
        'card__infos-container--partial-width': _vm.row,
        'card__infos-container--full-width': _vm.column,
      },attrs:{"column":""}},[_c('v-flex',{staticClass:"card__group-title"},[_vm._v(" Avaliação ")]),(!_vm.hasEvaluation)?_c('v-flex',{attrs:{"shrink":""}},[_vm._v(" Nenhum campo de avaliação foi preenchido. ")]):_vm._e(),(_vm.conditions)?_c('v-flex',{attrs:{"shrink":"","column":""}},[_c('v-flex',{staticClass:"card__infos-sub-title"},[_vm._v(" Condições ")]),_vm._l((_vm.conditions),function(condition,index){return _c('v-flex',{key:index,staticClass:"card__infos-name"},[_vm._v(" • "+_vm._s(_vm.getName(condition))+" "+_vm._s(_vm.getFormatedStatus(condition))+" ")])})],2):_vm._e(),(_vm.evaluationAdditionalInformation)?_c('v-flex',{staticClass:"card__infos-additional-information"},[(_vm.row)?_c('see-more',{attrs:{"limit-length":_vm.limitAdditionalInformationLength,"text":_vm.evaluationAdditionalInformation}}):[_vm._v(" "+_vm._s(_vm.evaluationAdditionalInformation)+" ")]],2):_vm._e()],1):_vm._e(),(_vm.shouldShowTopic(_vm.hasPlan))?_c('v-flex',{class:{
        'card__infos-container--partial-width': _vm.row,
        'card__infos-container--full-width': _vm.column,
      },attrs:{"ml-6":"","column":""}},[_c('v-flex',{staticClass:"card__group-title"},[_vm._v(" Plano ")]),(!_vm.hasPlan)?_c('v-flex',{attrs:{"shrink":""}},[_vm._v(" Nenhum campo de plano foi preenchido. ")]):_vm._e(),(_vm.examRequests)?_c('v-flex',{attrs:{"column":""}},[_c('v-flex',{staticClass:"card__infos-sub-title"},[_vm._v(" Solicitações de exames ")]),_vm._l((_vm.examRequests),function(exam,index){return _c('v-flex',{key:index,staticClass:"card__infos-name"},[_vm._v(" • "+_vm._s(_vm.getName(exam))+" ")])})],2):_vm._e(),(_vm.medicalReferral)?_c('v-flex',{attrs:{"column":""}},[_c('v-flex',{staticClass:"card__infos-sub-title mt-4"},[_vm._v(" Encaminhamentos ")]),_vm._l((_vm.medicalReferral),function(referral,index){return _c('v-flex',{key:index,staticClass:"card__infos-name"},[_vm._v(" • "+_vm._s(referral.specialityName)+" ")])})],2):_vm._e(),(_vm.interventions)?_c('v-flex',{attrs:{"column":""}},[_c('v-flex',{staticClass:"card__infos-sub-title mt-4"},[_vm._v(" Intervenções ")]),_vm._l((_vm.interventions),function(intervention,index){return _c('v-flex',{key:index,staticClass:"card__infos-name"},[_vm._v(" • "+_vm._s(intervention.name)+" ")])})],2):_vm._e(),(_vm.treatments)?_c('v-flex',{attrs:{"column":""}},[_c('v-flex',{staticClass:"card__infos-sub-title mt-4"},[_vm._v(" Novas prescrições ")]),_vm._l((_vm.treatments),function(treatment,index){return _c('v-flex',{key:index,staticClass:"card__infos-name"},[_vm._v(" • "+_vm._s(treatment.name)+" - "+_vm._s(treatment.status)+" ")])})],2):_vm._e(),(_vm.planAdditionalInformation)?_c('v-flex',{staticClass:"card__infos-additional-information"},[(_vm.row)?_c('see-more',{attrs:{"limit-length":_vm.limitAdditionalInformationLength,"text":_vm.planAdditionalInformation}}):[_vm._v(" "+_vm._s(_vm.planAdditionalInformation)+" ")]],2):_vm._e()],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }