<template>
  <v-container
    fluid
    class="px-12"
  >
    <schedule-list-loading v-if="isLoading" />

    <v-data-table
      v-else
      class="schedule-table"
      :headers="headers"
      :items="scheduleList.rows"
      :server-items-length="scheduleList.count"
      :items-per-page="30"
      :page.sync="page"
      item-key="id"
      no-data-text="Sem itens para exibir"
      hide-default-footer
    >
      <template #item="schedule">
        <tr>
          <td>
            <v-layout
              column
            >
              <span
                :class="generatateIsLateStatusClass(schedule.item.status)"
                class="list__patientLinkName"
                @click="goToMedicalRecords(schedule.item.patient)"
              >
                {{ schedule.item.patient | formatDisplayName }}
              </span>
              <v-flex>
                <patient-medical-follow-up-accept-info-chip
                  :patient="schedule.item.patient"
                />
              </v-flex>
              <span
                v-if="schedule.item.patient.cpf || schedule.item.patient.foreignDocument"
                class="list__patient-field"
              >
                {{ schedule.item.patient | getIdentifierDocument }}
              </span>
              <span
                v-if="schedule.item.patient.telphone"
                class="list__patient-field"
              >
                {{ schedule.item.patient.telphone | formatPhone }}
              </span>
              <span
                v-if="schedule.item.patient.email"
                class="list__patient-field"
              >
                {{ schedule.item.patient.email }}
              </span>
              <span
                v-if="schedule.item.patient.birthDate"
                class="list__patient-field"
              >
                {{ patientAge(schedule.item.patient) }}
              </span>
              <span
                v-if="schedule.item.patient.healthInsurancePlan"
                class="list__patient-field"
              >
                {{ schedule.item.patient.healthInsurancePlan }}
              </span>
            </v-layout>
          </td>
          <td :class="generatateIsLateStatusClass(schedule.item.status)">
            {{ schedule.item.patient.careTeam ? schedule.item.patient.careTeam.clinic.name : '-' }}
          </td>
          <td :class="generatateIsLateStatusClass(schedule.item.status)">
            {{ schedule.item.patient.careTeam ? schedule.item.patient.careTeam.name : '-' }}
          </td>
          <td :class="generatateIsLateStatusClass(schedule.item.status)">
            {{ schedule.item.date | formatDate }}
          </td>
          <td>
            <span
              :class="{
                'list__schedule-priority--green': (schedule.item.priority === 1),
                'list__schedule-priority--yellow': (schedule.item.priority === 2),
                'list__schedule-priority--red': (schedule.item.priority === 3),
              }"
            >
              {{ priorityList[schedule.item.priority].title }}
            </span>
          </td>
          <td :class="generatateIsLateStatusClass(schedule.item.status)">
            {{ schedule.item | formatScheduleType }}
            <span
              v-if="schedule.item.responsible"
            >
              <br>
              Prof. Sugerido: {{ schedule.item | formatResponsibleType }}
            </span>
          </td>
          <td
            :class="generatateIsLateStatusClass(schedule.item.status)"
          >
            <span
              v-if="schedule.item.action"
              class="font-weight-bold"
            >
              {{ schedule.item | customScheduleActionMessage }} <br>
            </span>
            <span
              v-if="schedule.item.message.length > 35"
            >
              {{ shortMessage(schedule.item.message) }} <br>
              <span
                class="list__schedule-message"
                @click="openScheduleMessageModal(schedule.item.message)"
              >
                Ver Tudo
              </span>
            </span>
            <span v-else>
              {{ schedule.item.message }}
            </span>
          </td>
          <td>
            <v-flex mt-4>
              <v-select
                v-model="schedule.item.status"
                :items="filterStatusList(schedule.item)"
                :class="generatateIsLateStatusClass(schedule.item.status)"
                class="schedule__filter__status"
                label=""
                filled
                :disabled="!shouldUpdateSchedule(schedule.item)"
                @change="updateScheduleStatus(schedule.item)"
              />
            </v-flex>
          </td>
          <td :class="generatateIsLateStatusClass(schedule.item.status)">
            {{ schedule.item.careLineActionPatient
              ? schedule.item.careLineActionPatient.careLine.identifier
              : '-'
            }}
          </td>
          <td>
            <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  class="history-button"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="openScheduleHistoryDialog(schedule.item)"
                >
                  <v-icon>
                    mdi-history
                  </v-icon>
                </v-btn>
              </template>
              <span>Histórico</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-row>
      <v-col>
        <v-pagination
          v-model="page"
          :length="scheduleList.numberOfPages"
          :total-visible="10"
          @input="setPagination"
        />
      </v-col>

      <v-col
        cols="2"
        class="d-flex align-center"
      >
        <p
          class="mb-0"
        >
          Total: {{ scheduleList.count }}
        </p>
      </v-col>
    </v-row>

    <schedule-message-modal
      :modal-message="scheduleModalMessage"
      :is-modal-open="shouldShowScheduleModal"
      @close="closeScheduleMessageModal"
    />

    <v-dialog
      v-if="showScheduleHistoryDialog"
      :value="true"
      transition="dialog-bottom-transition"
      persistent
      max-width="800"
    >
      <v-card>
        <v-card-title
          class="card-dialog__title font-weight-medium"
        >
          <v-container>
            <v-row>
              <v-col
                cols="11"
              >
                <h2>
                  Histórico de Atribuições
                </h2>
              </v-col>

              <v-col
                cols="1"
              >
                <v-btn
                  icon
                  @click="showScheduleHistoryDialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <schedule-timeline
                  :schedule-activity-log="selectedScheduleLog"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import {
  always,
  cond,
  equals,
  gt,
  gte,
  isEmpty,
  isNil,
  path,
  pick,
  reject,
  T,
  values,
} from 'ramda'
import { SCHEDULE } from 'amparo-enums'
import scheduleEnums from '@/enums/schedule'
import {
  isInvalidScheduleStatus,
} from '@/utils/scheduleValidators'

const { PRIORITY_LIST } = scheduleEnums

export default {
  name: 'ScheluleList',
  components: {
    ScheduleTimeline: () => import('@/components/Common/ScheduleTimeline'),
    PatientMedicalFollowUpAcceptInfoChip: () => import('@/components/Common/PatientMedicalFollowUpAcceptInfoChip'),
    ScheduleMessageModal: () => import('./ScheduleMessageModal'),
    ScheduleListLoading: () => import('./ScheduleListLoading'),
  },
  data: () => ({
    page: 1,
    pagination: {},
    headers: [
      {
        text: 'Paciente',
        value: 'patient.name',
        sortable: false,
      },
      {
        text: 'Clínica',
        value: 'patient.clinc.name',
        sortable: false,
      },
      {
        text: 'Equipe',
        value: 'careTeam.name',
        sortable: false,
      },
      {
        text: 'Data',
        value: 'date',
        sortable: false,
      },
      {
        text: 'Prioridade',
        value: 'priority',
        sortable: false,
      },
      {
        text: 'Tipo',
        value: 'type',
        sortable: false,
      },
      {
        text: 'Mensagem',
        value: 'message',
        sortable: false,
      },
      {
        text: 'Status',
        value: 'status',
        sortable: false,
      },
      {
        text: 'Linha de cuidado',
        value: 'careLineActionPatient.careLine.identifier',
        sortable: false,
      },
      {
        text: '',
        value: '',
        sortable: false,
      },
    ],
    scheduleModalMessage: '',
    shouldShowScheduleModal: false,
    priorityList: PRIORITY_LIST,
    statusList: values(
      pick(
        [
          'open',
          'inProgress',
          'finished',
          'late',
          'canceled',
          'rejected',
          'canceledByStored',
          'unsuccessfulContact',
          'autoFinished',
        ],
        SCHEDULE.status,
      ),
    ),
    showScheduleHistoryDialog: false,
    selectedScheduleLog: null,
    refreshScheduleInterval: null,
  }),
  computed: {
    ...mapGetters({
      isLoading: 'schedule/isLoading',
      careTeams: 'careTeam/careTeams',
      scheduleList: 'schedule/scheduleList',
      user: 'authentication/user',
      schedulePage: 'schedule/page',
    }),
    userProfessionalId() {
      return path(['professional', 'id'], this.user) || null
    },
  },
  watch: {
    schedulePage: {
      handler(page) {
        this.page = page
      },
    },
  },
  async activated() {
    this.listCareTeam()
  },
  created() {
    this.listCareTeam()
  },
  methods: {
    ...mapActions({
      setEmrPatient: 'emr/setEmrPatient',
      setSchedulePagination: 'schedule/setSchedulePagination',
      getSchedules: 'schedule/getSchedules',
      updateSchedule: 'schedule/updateSchedule',
      listCareTeam: 'careTeam/listCareTeam',
    }),
    generatateIsLateStatusClass(status) {
      return {
        'list--late-status': this.isScheduleTaskLate(status),
      }
    },
    closeFullMessage() {
      this.openFullMessage = false
    },
    openScheduleMessageModal(message) {
      this.scheduleModalMessage = message
      this.shouldShowScheduleModal = true
    },
    closeScheduleMessageModal() {
      this.shouldShowScheduleModal = false
    },
    shortMessage(scheduleCustomMessage) {
      return (`${scheduleCustomMessage.substring(0, 35)} ...`)
    },
    isScheduleTaskLate(scheduleStatus) {
      return equals(scheduleStatus, 'Atrasado')
    },
    shouldUpdateSchedule({ status }) {
      if (isInvalidScheduleStatus(status)) return false
      return true
    },
    shouldShowScheduleHistory({ activityLog }) {
      return !isNil(activityLog)
    },
    openScheduleHistoryDialog({ activityLog }) {
      this.showScheduleHistoryDialog = true
      this.selectedScheduleLog = activityLog
    },
    async updateScheduleStatus({ id, status }) {
      await this.updateSchedule({ id, attributes: { status } })
      await this.getSchedules()
    },
    async goToMedicalRecords({ id: patientId }) {
      await this.setEmrPatient(patientId)
      const routerData = this.$router.resolve(`/emr/${patientId}`)
      window.open(routerData.href, '_blank')
    },
    patientAge(patient) {
      if (isEmpty(patient)) return null
      const {
        birthDate,
      } = patient
      const getDiffOfDate = diffBy => moment().diff(moment(birthDate), diffBy)
      const buildAgePhrase = (number, plural, singular) => `
        ${number}
        ${gt(number, 1) ? plural : singular}
      `
      const days = getDiffOfDate('days')
      return cond([
        [gte(31), always(buildAgePhrase(days, 'dias', 'dia'))],
        [
          gte(366),
          always(buildAgePhrase(getDiffOfDate('months'), 'meses', 'mês')),
        ],
        [T, always(buildAgePhrase(getDiffOfDate('years'), 'anos', 'ano'))],
      ])(days)
    },
    filterStatusList(scheduleData) {
      const statusListWithoutReject = isNil(scheduleData.careLineActionPatient)
        ? reject(equals(SCHEDULE.status.rejected), this.statusList)
        : this.statusList

      const statusListWithoutAutoFinish = !equals(scheduleData.status, SCHEDULE.status.autoFinished)
        ? reject(equals(SCHEDULE.status.autoFinished), statusListWithoutReject)
        : statusListWithoutReject

      return !(equals(SCHEDULE.types.teamMeeting.value, scheduleData.type)
      || equals(SCHEDULE.types.otherAdministrative.value, scheduleData.type))
        ? [...statusListWithoutAutoFinish, SCHEDULE.status.unsuccessfulContact]
        : statusListWithoutAutoFinish
    },
    setPagination() {
      this.setSchedulePagination({ page: this.page })
      this.getSchedules()
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/elements.styl'

.card-dialog__title
  font-size 1.5rem !important
  letter-spacing 0.3px
  padding-left 40px

.list__patient-field
  color #00000099
  font-size .9rem
  letter-spacing 0.5px
  margin-left 4px

.list--late-status
  color #dd564a !important
  letter-spacing 0.5px

.list--late-status >>> .v-input__control > .v-input__slot >
  .v-select__slot > .v-select__selections > .v-select__selection
    color #dd564a !important
    font-size 1rem !important

.list__patientLinkName
  cursor pointer
  font-size 1rem
  margin-left 4px

.list__schedule-message
  color #55d058
  cursor pointer
  margin-top .2rem
  text-decoration underline

.list__schedule-priority--green
  color #67dd4a
  cursor pointer
  font-size 1rem
  font-weight bold

.list__schedule-priority--yellow
  color #dddb4a
  cursor pointer
  font-size 1rem
  font-weight bold

.list__schedule-priority--red
  color #dd564a
  cursor pointer
  font-size 1rem
  font-weight bold

.schedule-table >>> thead > tr
  background-color #f5f5f5
  box-shadow 0px 1px 8px rgba(0,0,0, .12)

.schedule-table >>> thead > tr > th
  border none !important
  font-size 1.1rem !important
  font-weight 500
  padding 8px 16px !important

.schedule-table >>> tbody > tr
  margin-top 16px
  box-shadow 0px 1px 2px rgba(0,0,0,.12) !important

.schedule-table >>> tbody > tr > td
  font-size 1rem !important
  font-weight 500
  padding 16px !important

.schedule__filter__status
  font-weight 600

.schedule__filter__status >>> .v-input__control > .v-input__slot:before
  border-width 1px !important

</style>
