import moment from 'moment'

export const dateFormat = (date) => {
  if (!date) return false
  return moment(date, 'DD/MM/YYYY').isValid()
}

export const cardDateFormat = (date) => {
  if (!date) return false
  return moment(date, 'MM/YYYY').isValid()
}

export const formatDateToISO8601 = (date) => {
  if (!date) return null
  return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
}

export const validateDueDate = (date) => {
  if (!date) return false
  return date.length === 7 && moment(date, 'MM/YYYY').add(1, 'months') > moment()
}

export default {
  dateFormat,
  cardDateFormat,
  formatDateToISO8601,
  validateDueDate,
}
