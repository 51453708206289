export default {
  credit_card: {
    value: 'credit_card',
    name: 'Cartão de Crédito',
    shouldHaveReceipt: true,
  },
  debit_card: {
    value: 'debit_card',
    name: 'Cartão de Débito',
    shouldHaveReceipt: true,
  },
  money: {
    value: 'money',
    name: 'Dinheiro',
    shouldHaveReceipt: false,
  },
}
