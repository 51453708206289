import { rest } from 'msw'
import baseUrl from '../../../src/utils/baseUrlTest'
import patientData from '../fixtures/patientData'
import specialConditionsData from '../fixtures/public/patient/specialConditions'

export default [
  rest.post(baseUrl('/patient'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      id: patientData.id,
      ...req.body,
    }),
  )),
  rest.get(baseUrl('/patient'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      patients: [],
      numberOfPages: 0,
      count: 0,
    }),
  )),
  rest.get(baseUrl(`/patient/${patientData.id}`), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      patient: patientData,
    }),
  )),
  rest.get(baseUrl('/patient/search'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      patients: [
        patientData,
      ],
    }),
  )),
  rest.get(baseUrl('/patient/:patientId/special_conditions'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(specialConditionsData),
  )),
]
