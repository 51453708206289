<template>
  <div>
    <div class="filters-container">
      <v-container
        :class="{
          'container--fluid': true,
          'px-12': $vuetify.breakpoint.smAndUp,
          'grid-list-lg': true
        }"
      >
        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
            sm4
          >
            <v-text-field
              v-model="filters.name"
              filled
              label="Buscar por nome"
              clearable
              no-data-text="Digite para buscar"
            />
          </v-flex>
          <v-flex
            xs12
            sm4
          >
            <v-autocomplete
              v-model="filters.type"
              :items="communicationTypes"
              label="Tipo"
              item-text="label"
              item-value="value"
              :no-data-text="notFoundMessage"
              clearable
              filled
            />
          </v-flex>
          <v-flex
            xs12
            sm4
          >
            <v-btn
              class="btn-filter--height"
              color="dark-green"
              dark
              block
              large
              @click="searchCommunications"
            >
              <v-icon left>
                mdi-magnify
              </v-icon>
              Buscar Comunicado
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <v-container
      :class="{
        'container--fluid': true,
        'px-12': $vuetify.breakpoint.smAndUp,
      }"
    >
      <v-layout
        row
        wrap
      >
        <v-spacer />
        <v-flex
          xs12
          sm4
        >
          <v-btn
            class="font-weight-bold my-4"
            color="primary"
            block
            large
            @click="showEditorTypeSelectorDialog = true"
          >
            Nova Comunicação
          </v-btn>
        </v-flex>
        <v-spacer />
      </v-layout>
      <div class="data-spacer" />
      <v-data-table
        :headers="headers"
        :items="communications"
        hide-default-footer
        disable-pagination
        class="data-table__header"
        no-data-text="Sem itens para exibir, clique no botão acima para começar a criar"
      >
        <template v-slot:item="communication">
          <tr>
            <td>{{ communication.item.name }}</td>
            <td>{{ communication.item.emailTitle }}</td>
            <td>{{ showCommunicationTypeValue(communication.item.type) }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-3"
                    text
                    icon
                    @click="editDialog(communication.item)"
                    v-on="on"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    icon
                    v-on="on"
                    @click="deleteDialog(communication.item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Remover</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-container>

    <v-dialog
      v-model="showEditorTypeSelectorDialog"
      max-width="650"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <editor-type-selector
          @receiveEditorType="receiveEditorType"
          @closeEditorTypeSelectorDialog="showEditorTypeSelectorDialog = false"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showCreateCommunicationDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Dados do Comunicado</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="showCreateCommunicationDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <create-communication
          v-if="showCreateCommunicationDialog"
          :editor-type="editorTypeSelected"
          @exitCreateDialog="showCreateCommunicationDialog = false"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showEditCommunicationDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Dados do Comunicado</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="exitEditDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <create-communication
          v-if="showEditCommunicationDialog"
          edit-communication
          :communication-data="editedCommunication"
          :editor-type="editedCommunication.editorType"
          @exitEditDialog="exitEditDialog"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showDeleteCommunicationDialog"
      class="not-printable-area"
      max-width="450"
    >
      <v-card>
        <v-card-text>
          Deseja realmente excluir esse comunicado?
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="btn-dialog font-weight-lightbold"
            color="green darken-1"
            text
            large
            @click="exitDeleteDialog"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            class="btn-dialog font-weight-lightbold"
            large
            color="error"
            @click="handleDeleteCommunication"
          >
            Remover
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  isEmpty,
  cond,
  T,
  always,
  values,
  find,
  propEq,
} from 'ramda'
import { mapActions, mapGetters } from 'vuex'
import { COMMUNICATION } from 'amparo-enums'

export default {
  name: 'CommunicationList',
  components: {
    CreateCommunication: () => import('./CreateCommunication'),
    EditorTypeSelector: () => import('./EditorTypeSelector'),
  },
  data() {
    return {
      notFoundMessage: 'Não encontrado',
      editedCommunication: {},
      filters: {},
      headers: [
        {
          text: 'Nome',
          value: 'name',
          width: '40%',
        },
        {
          text: 'Título',
          value: 'emailTitle',
          width: '30%',
        },
        {
          text: 'Tipo',
          value: 'type',
          width: '20%',
        },
        {
          text: 'Ações',
          value: 'actions',
          width: '10%',
          sortable: false,
        },
      ],
      showCreateCommunicationDialog: false,
      showEditCommunicationDialog: false,
      showDeleteCommunicationDialog: false,
      showEditorTypeSelectorDialog: false,
      deletedCommunication: {},
      communicationTypes: values(COMMUNICATION.types),
      editorTypeSelected: '',
    }
  },
  computed: {
    ...mapGetters({
      communications: 'communication/communications',
    }),
    isCommunicationListEmpty() {
      return isEmpty(this.communications)
    },
  },
  watch: {
    filters: {
      deep: true,
      async handler() {
        await this.searchCommunications()
      },
    },
  },
  mounted() {
    this.listCommunication()
  },
  methods: {
    ...mapActions({
      setCommunicationFilters: 'communication/setCommunicationFilters',
      listCommunication: 'communication/listCommunication',
      deleteCommunication: 'communication/deleteCommunication',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    showCommunicationTypeValue(communicationType) {
      const communicationTypeValue = find(propEq(communicationType, 'value'), this.communicationTypes)
      return communicationTypeValue.label
    },
    async handleDeleteCommunication() {
      try {
        await this.deleteCommunication(this.deletedCommunication.id)
        this.buildSnackbar('success', 'Comunicado deletado com sucesso!')
        this.isSaving = false
      } catch ({ response }) {
        this.handleError(response)
      }
      this.deletedCommunication = {}
      this.showDeleteCommunicationDialog = false
      await this.listCommunication()
    },
    handleError(error) {
      const errorMessage = cond([
        [T, always('Erro interno')],
      ])(error.data.errorCode)

      this.buildSnackbar('error', errorMessage)
    },
    async setFilters() {
      await this.setCommunicationFilters(this.filters)
    },
    async searchCommunications() {
      await this.setFilters()
      await this.listCommunication()
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    receiveEditorType(editorType) {
      this.editorTypeSelected = editorType
      this.showEditorTypeSelectorDialog = false
      this.showCreateCommunicationDialog = true
    },
    editDialog(communicationData) {
      this.editedCommunication = communicationData
      this.showEditCommunicationDialog = true
    },
    exitEditDialog() {
      this.editedCommunication = {}
      this.showEditCommunicationDialog = false
    },
    deleteDialog(communication) {
      this.deletedCommunication = communication
      this.showDeleteCommunicationDialog = true
    },
    exitDeleteDialog() {
      this.deletedCommunication = {}
      this.showDeleteCommunicationDialog = false
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'

.filters-container
  width 100%
  height auto
  background-color #e8f8e8

  @media only screen and (min-width 601px)
    height 108px

.v-card
  padding 10px
  border-radius 6px

.v-btn
  letter-spacing 1px

.v-btn.v-btn--large
  margin-top 0px
  height 56px
  border-radius 8px

.v-btn.v-btn--text
  border solid 1px thin-gray

.v-btn.btn-dialog
  padding 15px
  @media all and (min-width 601px)
    width 190px

.data-spacer
  height 20px
  width 100%

</style>
