import axios from '@/lib/axios'
import {
  WORK_SPACE,
} from 'amparo-enums'
import {
  any,
  head,
  prop,
  propEq,
} from 'ramda'

const initialState = {
}

const mutations = {
}

const actions = {
  listUser(_, filter) {
    return axios.get('/advanced/user', {
      params: {
        filter,
      },
    })
      .then(({ data }) => data)
      .catch(err => err)
  },
  impersonateUser({ dispatch }, { userId, workSpaceId }) {
    return axios.patch('/advanced/user/impersonate', {
      workSpaceId,
      userId,
    })
      .then(({ data }) => dispatch('setImpersonation', data))
      .catch(err => err)
  },
  setImpersonation(_, value) {
    localStorage.setItem('impersonation', JSON.stringify(value))
  },
  getImpersonation() {
    return JSON.parse(localStorage.getItem('impersonation'))
  },
  async stopImpersonate({ dispatch, rootGetters }) {
    const user = rootGetters['authentication/user']

    const workSpaceId = any(propEq(WORK_SPACE.amparoSaude, 'workSpaceId'), user.userWorkSpaces)
      ? WORK_SPACE.amparoSaude : prop('workSpaceId', head(user.userWorkSpaces))

    await dispatch(
      'authentication/switchUserWorkSpace',
      workSpaceId,
      { root: true },
    )
    dispatch('setImpersonation', null)
  },
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
}
