export default {
  TIME_OPTIONS: {
    FIFTEEN_DAYS: {
      label: '15 dias',
      value: 15,
    },
    TIRTY_DAYS: {
      label: '30 dias',
      value: 30,
    },
    SIXTY_DAYS: {
      label: '60 dias',
      value: 60,
    },
    NINETY_DAYS: {
      label: '90 dias',
      value: 90,
    },
    HUNDRED_TWENTY_DAYS: {
      label: '120 dias',
      value: 120,
    },
    HUNDRED_FIFTY_DAYS: {
      label: '150 dias',
      value: 150,
    },
    HUNDRED_EIGHTY_DAYS: {
      label: '180 dias',
      value: 180,
    },
    ONE_YEAR: {
      label: '1 ano',
      value: 365,
    },
    TWO_YEARS: {
      label: '2 anos',
      value: 730,
    },
    THREE_YEARS: {
      label: '3 anos',
      value: 1095,
    },
    FIVE_YEARS: {
      label: '5 anos',
      value: 1895,
    },
    TEN_YEARS: {
      label: '10 anos',
      value: 3650,
    },
  },
  DISPLAY_ORDER: {
    ONE: '1',
    TWO: '2',
    THREE: '3',
    FOUR: '4',
    FIVE: '5',
  },
  DISEASE_TYPE: {
    CID: 'CID',
    CIAP: 'CIAP',
  },
  HIGHLIGHT_TYPE: {
    ALWAYS: {
      label: 'Sempre em destaque',
      value: 'always',
    },
    PERIOD: {
      label: 'Destacado por período',
      value: 'period',
    },
    NEVER: {
      label: 'Não destacado',
      value: 'never',
    },
  },
}
