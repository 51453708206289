var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticClass:"activator-title"},on),[_vm._v(" Códigos Profissões ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Códigos Profissões")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-container',{class:{
        'container--fluid': true,
        'px-12': _vm.$vuetify.breakpoint.smAndUp,
      }},[[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.professions,"footer-props":{
            'items-per-page-options': [30],
            'items-per-page-text': '',
          },"item-key":"id","no-data-text":"Nenhuma profissão encontrada"},scopedSlots:_vm._u([{key:"item",fn:function(profession){return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(profession.item.id)+" ")]),_c('td',[_vm._v(" "+_vm._s(profession.item.name)+" ")])])]}}])})]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }