<template>
  <div>
    <component-title
      new-hmo
    />
    <hmo-list-container />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import HmoListContainer from './HmoListContainer'

export default {
  components: { ComponentTitle, HmoListContainer },
}
</script>
