import axios from 'axios'
import store from '@/store/index'
import router from '@/router'

const axiosSettings = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  timeout: 30000,
})

axiosSettings.interceptors.response.use(response => response,
  (error) => {
    const status = 'error'
    const urlAPIRequested = error.response.config.url.replace(error.response.config.baseURL, '')
    const isInternalServerError = (error.response.status === 500 && urlAPIRequested === '/session')
    let message

    if (error.response.status === 401) {
      message = 'Sessão expirada. Faça login novamente.'
    }
    if (isInternalServerError) {
      message = 'Erro interno. Faça login novamente. Caso o erro persista, contate o suporte.'
    }
    if (error.response.status === 401 || isInternalServerError) {
      store.dispatch('authentication/clearAuthentication')
      router.push('/login')
      store.dispatch('snackbar/setSnackbar', {
        status,
        message,
      })
    }
    return Promise.reject(error)
  })

export default axiosSettings
