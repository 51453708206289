import axios from '@/lib/axios'

const initialState = {
  questions: [],
}

const mutations = {
  SET_QUESTIONS(state, payload) {
    state.questions = payload
  },
}

const actions = {
  listQuestion({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      axios.get('/emr/question', { params })
        .then(({ data: { questions } }) => {
          commit('SET_QUESTIONS', questions)
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  createQuestion(_, question) {
    return new Promise((resolve, reject) => {
      axios.post('/emr/question', question)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  updateQuestion(_, { questionId, questionData }) {
    return new Promise((resolve, reject) => {
      axios.put(`/emr/question/${questionId}`, questionData)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  deleteQuestion(_, questionId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/emr/question/${questionId}`)
        .then(() => {
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
}

const getters = {
  questions: state => state.questions,
}

export default {
  state: initialState,
  mutations,
  actions,
  getters,
}
