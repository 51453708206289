import {
  clone,
  equals,
  filter,
  find,
  length,
  map,
  not,
  propEq,
} from 'ramda'
import { FORM } from 'amparo-enums'
import axios from '@/lib/axios'

const initialState = {
  patientForms: [],
  editingForms: [],
  editingFormId: null,
  isLoading: false,
  filters: {},
}

const mutations = {
  SET_PATIENT_FORMS(state, payload) {
    state.patientForms = payload
  },
  SET_EDITING_FORMS(state, payload) {
    state.editingForms = payload
  },
  SET_IS_LOADING(state, payload) {
    state.isLoading = payload
  },
  SET_FILTERS(state, payload) {
    state.filters = payload
  },
  SET_EDITING_FORM_ID(state, payload) {
    state.editingFormId = payload
  },
}

const actions = {
  getPatientForms({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      commit('SET_IS_LOADING', true)
      axios
        .get('/emr/form_patient', {
          params: {
            ...params,
            ...state.filters,
          },
        })
        .then(({ data: { formsPatient } }) => {
          commit('SET_PATIENT_FORMS', formsPatient)
          resolve(formsPatient)
        })
        .catch(reject)
        .finally(() => {
          commit('SET_IS_LOADING', false)
        })
    })
  },
  async getPatientFormById({ commit, state }, formPatientId) {
    return new Promise((resolve, reject) => {
      commit('SET_IS_LOADING', true)
      axios
        .get(`/emr/form_patient/${formPatientId}`)
        .then(({ data: formPatient }) => {
          commit('SET_EDITING_FORMS', [
            ...state.editingForms,
            formPatient,
          ])
          resolve(formPatient)
        })
        .catch(reject)
        .finally(() => {
          commit('SET_IS_LOADING', false)
        })
    })
  },
  async updateForm({ commit, state }, status) {
    commit('SET_IS_LOADING', true)
    return new Promise((resolve, reject) => {
      const form = find(
        propEq(state.editingFormId, 'id'),
        state.editingForms,
      )
      axios
        .patch(`/emr/form_patient/${state.editingFormId}`, {
          questions: form.questions,
          status,
        })
        .then(({ data: formPatient }) => {
          const forms = filter(
            edditingForm => not(propEq(state.editingFormId, 'id', edditingForm)),
            state.editingForms,
          )
          commit('SET_EDITING_FORMS', forms)
          resolve(formPatient)
        })
        .catch(reject)
        .finally(() => {
          commit('SET_IS_LOADING', false)
        })
    })
  },
  setFilters({ commit }, attributes) {
    commit('SET_FILTERS', { ...attributes })
  },
  setEditingFormId({ commit }, formId) {
    commit('SET_EDITING_FORM_ID', formId)
  },
  changeEditingFormQuestion({ commit, state }, { formPatientId, questionToEdit }) {
    const forms = map(
      (editingForm) => {
        const form = clone(editingForm)
        if (equals(formPatientId, form.id)) {
          form.questions = map(
            (question) => {
              const selectedQuestion = clone(question)
              if (equals(questionToEdit.id, question.id)) {
                selectedQuestion.answer = questionToEdit.answer
              }
              return selectedQuestion
            },
            form.questions,
          )
        }
        return form
      },
      state.editingForms,
    )
    commit('SET_EDITING_FORMS', forms)
  },
  cleanEditingForms({ commit }) {
    commit('SET_EDITING_FORMS', [])
  },
}

const getters = {
  patientForms: state => state.patientForms,
  editingForms: state => state.editingForms,
  isLoading: state => state.isLoading,
  uncompletedFormsLength: state => length(filter(propEq(FORM.status.uncompleted.value, 'status'), state.patientForms)),
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
