import axios from '@/lib/axios'
import moment from 'moment'
import { path } from 'ramda'

const initialState = {
  isLoading: false,
  scheduleList: {
    rows: [],
  },
  scheduleFilters: {
    end: moment().endOf('day').format('YYYY-MM-DD'),
    start: moment().startOf('day').format('YYYY-MM-DD'),
  },
  schedulePagination: {},
  autoSchedule: {
    encounter: {},
    isShowing: false,
  },
}

const mutations = {
  SET_SCHEDULE_FILTERS(state, scheduleFilters) {
    state.scheduleFilters = scheduleFilters
  },
  SET_SCHEDULE_PAGINATION(state, schedulePagination) {
    state.schedulePagination = {
      page: schedulePagination.page,
      isDesc: schedulePagination.descending,
      orderBy: schedulePagination.sortBy,
    }
  },
  SET_SCHEDULE_LIST(state, scheduleList) {
    state.scheduleList = scheduleList
  },
  SET_AUTO_SCHEDULE(state, payload) {
    state.autoSchedule = payload
  },
  SET_IS_LOADING(state, payload) {
    state.isLoading = payload
  },
}

const actions = {
  async createSchedule(_, attributes) {
    return axios.post('emr/schedule', attributes)
  },
  async updateSchedule({ dispatch }, { id, attributes }) {
    return axios.put(`emr/schedule/${id}`, attributes)
      .catch((err) => {
        if (path(['response', 'data', 'errors', 'status'], err)) {
          dispatch('snackbar/setSnackbar', {
            status: 'error',
            message: 'Status Inválido',
          }, { root: true })
        }
      })
  },
  async getSchedules({ commit, state }, filters) {
    commit('SET_IS_LOADING', true)
    const params = filters || {
      ...state.scheduleFilters,
      ...state.schedulePagination,
    }
    const { data } = await axios.get('emr/schedule', { params })
    commit('SET_SCHEDULE_LIST', data)
    commit('SET_IS_LOADING', false)
  },
  setScheduleFilters({ commit }, scheduleFilters) {
    commit('SET_SCHEDULE_FILTERS', scheduleFilters)
  },
  setSchedulePagination({ commit }, schedulePagination) {
    commit('SET_SCHEDULE_PAGINATION', schedulePagination)
  },
  setAutoSchedule({ commit }, value) {
    commit('SET_AUTO_SCHEDULE', { ...value })
  },
}

const getters = {
  scheduleList: state => state.scheduleList,
  isLoading: state => state.isLoading,
  autoSchedule: state => state.autoSchedule,
  page: state => state.schedulePagination.page,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
