export default [
  {
    id: 'pro_cjy91uu6m001ri3voafz99aww',
    name: 'Aristóteles',
    clinics: [
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
    ],
    specialities: [
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
      },
    ],
  },
  {
    id: 'pro_ck3ogpnob0001mhpq5of978kw',
    name: 'Brutus (.com)',
    clinics: [
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
      },
    ],
  },
  {
    id: 'pro_ck3ogpno90000mhpq2e5oa7dz',
    name: 'Brutus (.ro)',
    clinics: [
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
    ],
    specialities: [
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
      },
    ],
  },
  {
    id: 'pro_ck3ogpnob0003mhpq0jp47o2l',
    name: 'Devs + Pro (.com)',
    clinics: [
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    ],
    specialities: [
      {
        id: 'spe_ck2f6ucps0012yzpq2pl40xq6',
      },
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
      },
      {
        id: 'spe_cjyisn3y90016apvo3eu1bbas',
      },
    ],
  },
  {
    id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
    name: 'Devs + Pro (.ro)',
    clinics: [
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
    ],
    specialities: [
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
      },
    ],
  },
  {
    id: 'pro_cjy8zrbn1001fi3vo66ys842k',
    name: 'Friedrich Netzsche',
    clinics: [
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
    ],
    specialities: [
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
      },
    ],
  },
  {
    id: 'pro_cjy912cb7001pi3vo6noc2q14',
    name: 'Georg Hegel',
    clinics: [
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
    ],
    specialities: [
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
      },
    ],
  },
  {
    id: 'pro_cjy90nbi6001oi3vofij4dz3q',
    name: 'Harriet Taylor',
    clinics: [
      {
        id: 'cli_ck9b7yjf3000195vohxz60nio',
        name: 'Amparo Tele',
      },
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
    ],
    specialities: [
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
      },
    ],
  },
  {
    id: 'pro_cjy91hkji001qi3vobp2c0fh8',
    name: 'Immanuel Kant',
    clinics: [
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
    ],
    specialities: [
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
      },
    ],
  },
  {
    id: 'pro_cjy909srw001ni3vo5dxjffqd',
    name: 'Santo Agostinho',
    clinics: [
      {
        id: 'cli_cjxyringi0000i2vog8g55l7q',
        name: 'Lapa',
      },
    ],
    specialities: [
      {
        id: 'spe_cjy92cswd001yi3voc4sjca8c',
      },
    ],
  },
]
