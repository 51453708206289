import moment from 'moment'

const currentDate = moment().format('YYYY-MM-DD')

export default [
  {
    id: 'slo_cl811bs9w00330ja6hic6b7t8',
    agendaId: 'age_ck3iv6xq4000246pq180qa5df',
    reserves: null,
    interval: 15,
    status: 'available',
    startTime: '07:00:00',
    endTime: '07:14:59',
    date: `${currentDate}T10:00:00.000Z`,
    reservedUntil: null,
    slotAppointments: [],
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    room: {
      id: 'roo_cjy8zgpuk000zi3vo6tfjh3sx',
      name: 'Consultório 1',
    },
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      profession: {
        id: 'profession_cjz3an5zk00030nxbejbsg6wj',
        name: 'Médico',
      },
    },
    speciality: {
      id: 'spe_cjy92cswd001yi3voc4sjca8c',
      name: 'Médico de família',
    },
    spotlight: null,
    block: null,
    restriction: null,
  },
  {
    id: 'slo_cl811bs9w00340ja65gze5ur8',
    agendaId: 'age_ck3iv6xq4000246pq180qa5df',
    reserves: null,
    interval: 15,
    status: 'available',
    startTime: '07:15:00',
    endTime: '07:29:59',
    date: `${currentDate}T10:15:00.000Z`,
    reservedUntil: null,
    slotAppointments: [],
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    room: {
      id: 'roo_cjy8zgpuk000zi3vo6tfjh3sx',
      name: 'Consultório 1',
    },
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      profession: {
        id: 'profession_cjz3an5zk00030nxbejbsg6wj',
        name: 'Médico',
      },
    },
    speciality: {
      id: 'spe_cjy92cswd001yi3voc4sjca8c',
      name: 'Médico de família',
    },
    spotlight: null,
    block: null,
    restriction: null,
  },
  {
    id: 'slo_cl811bs9w00350ja6en1w25um',
    agendaId: 'age_ck3iv6xq4000246pq180qa5df',
    reserves: null,
    interval: 15,
    status: 'available',
    startTime: '07:30:00',
    endTime: '07:44:59',
    date: `${currentDate}T10:30:00.000Z`,
    reservedUntil: null,
    slotAppointments: [],
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    room: {
      id: 'roo_cjy8zgpuk000zi3vo6tfjh3sx',
      name: 'Consultório 1',
    },
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      profession: {
        id: 'profession_cjz3an5zk00030nxbejbsg6wj',
        name: 'Médico',
      },
    },
    speciality: {
      id: 'spe_cjy92cswd001yi3voc4sjca8c',
      name: 'Médico de família',
    },
    spotlight: null,
    block: null,
    restriction: null,
  },
  {
    id: 'slo_cl811bs9x00360ja6bva206d5',
    agendaId: 'age_ck3iv6xq4000246pq180qa5df',
    reserves: null,
    interval: 15,
    status: 'available',
    startTime: '07:45:00',
    endTime: '07:59:59',
    date: `${currentDate}T10:45:00.000Z`,
    reservedUntil: null,
    slotAppointments: [],
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    room: {
      id: 'roo_cjy8zgpuk000zi3vo6tfjh3sx',
      name: 'Consultório 1',
    },
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      profession: {
        id: 'profession_cjz3an5zk00030nxbejbsg6wj',
        name: 'Médico',
      },
    },
    speciality: {
      id: 'spe_cjy92cswd001yi3voc4sjca8c',
      name: 'Médico de família',
    },
    spotlight: null,
    block: null,
    restriction: null,
  },
  {
    id: 'slo_cl811bs9x00370ja6e2rzbeic',
    agendaId: 'age_ck3iv6xq4000246pq180qa5df',
    reserves: null,
    interval: 15,
    status: 'occupied',
    startTime: '08:00:00',
    endTime: '08:14:59',
    date: `${currentDate}T11:00:00.000Z`,
    reservedUntil: null,
    slotAppointments: [
      {
        id: 'slo_app_clmkxavei02ga0jqq3gurdj2g',
        appointment: {
          id: 'app_clmjmn5gs00830jrl04c8392w',
        },
      },
    ],
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    room: {
      id: 'roo_cjy8zgpuk000zi3vo6tfjh3sx',
      name: 'Consultório 1',
    },
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      profession: {
        id: 'profession_cjz3an5zk00030nxbejbsg6wj',
        name: 'Médico',
      },
    },
    speciality: {
      id: 'spe_cjy92cswd001yi3voc4sjca8c',
      name: 'Médico de família',
    },
    spotlight: null,
    block: null,
    restriction: null,
  },
  {
    id: 'slo_cl811bs9x00380ja60932hvbi',
    agendaId: 'age_ck3iv6xq4000246pq180qa5df',
    reserves: null,
    interval: 15,
    status: 'available',
    startTime: '08:15:00',
    endTime: '08:29:59',
    date: `${currentDate}T11:15:00.000Z`,
    reservedUntil: null,
    slotAppointments: [],
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    room: {
      id: 'roo_cjy8zgpuk000zi3vo6tfjh3sx',
      name: 'Consultório 1',
    },
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      profession: {
        id: 'profession_cjz3an5zk00030nxbejbsg6wj',
        name: 'Médico',
      },
    },
    speciality: {
      id: 'spe_cjy92cswd001yi3voc4sjca8c',
      name: 'Médico de família',
    },
    spotlight: null,
    block: null,
    restriction: null,
  },
  {
    id: 'slo_cl811bs9x00390ja6h3y6f19k',
    agendaId: 'age_ck3iv6xq4000246pq180qa5df',
    reserves: null,
    interval: 15,
    status: 'available',
    startTime: '08:30:00',
    endTime: '08:44:59',
    date: `${currentDate}T11:30:00.000Z`,
    reservedUntil: null,
    slotAppointments: [],
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    room: {
      id: 'roo_cjy8zgpuk000zi3vo6tfjh3sx',
      name: 'Consultório 1',
    },
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      profession: {
        id: 'profession_cjz3an5zk00030nxbejbsg6wj',
        name: 'Médico',
      },
    },
    speciality: {
      id: 'spe_cjy92cswd001yi3voc4sjca8c',
      name: 'Médico de família',
    },
    spotlight: null,
    block: null,
    restriction: null,
  },
  {
    id: 'slo_cl811bs9x003a0ja649t02t7v',
    agendaId: 'age_ck3iv6xq4000246pq180qa5df',
    reserves: null,
    interval: 15,
    status: 'available',
    startTime: '08:45:00',
    endTime: '08:59:59',
    date: `${currentDate}T11:45:00.000Z`,
    reservedUntil: null,
    slotAppointments: [],
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    room: {
      id: 'roo_cjy8zgpuk000zi3vo6tfjh3sx',
      name: 'Consultório 1',
    },
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      profession: {
        id: 'profession_cjz3an5zk00030nxbejbsg6wj',
        name: 'Médico',
      },
    },
    speciality: {
      id: 'spe_cjy92cswd001yi3voc4sjca8c',
      name: 'Médico de família',
    },
    spotlight: null,
    block: null,
    restriction: null,
  },
  {
    id: 'slo_cl811bs9x003b0ja67dpbai0s',
    agendaId: 'age_ck3iv6xq4000246pq180qa5df',
    reserves: null,
    interval: 15,
    status: 'available',
    startTime: '09:00:00',
    endTime: '09:14:59',
    date: `${currentDate}T12:00:00.000Z`,
    reservedUntil: null,
    slotAppointments: [],
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    room: {
      id: 'roo_cjy8zgpuk000zi3vo6tfjh3sx',
      name: 'Consultório 1',
    },
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      profession: {
        id: 'profession_cjz3an5zk00030nxbejbsg6wj',
        name: 'Médico',
      },
    },
    speciality: {
      id: 'spe_cjy92cswd001yi3voc4sjca8c',
      name: 'Médico de família',
    },
    spotlight: null,
    block: null,
    restriction: null,
  },
  {
    id: 'slo_cl811bs9x003c0ja63cn11x7o',
    agendaId: 'age_ck3iv6xq4000246pq180qa5df',
    reserves: null,
    interval: 15,
    status: 'available',
    startTime: '09:15:00',
    endTime: '09:29:59',
    date: `${currentDate}T12:15:00.000Z`,
    reservedUntil: null,
    slotAppointments: [],
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    room: {
      id: 'roo_cjy8zgpuk000zi3vo6tfjh3sx',
      name: 'Consultório 1',
    },
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      profession: {
        id: 'profession_cjz3an5zk00030nxbejbsg6wj',
        name: 'Médico',
      },
    },
    speciality: {
      id: 'spe_cjy92cswd001yi3voc4sjca8c',
      name: 'Médico de família',
    },
    spotlight: null,
    block: null,
    restriction: null,
  },
  {
    id: 'slo_cl811bs9x003d0ja6hcs5gxp1',
    agendaId: 'age_ck3iv6xq4000246pq180qa5df',
    reserves: null,
    interval: 15,
    status: 'available',
    startTime: '09:30:00',
    endTime: '09:44:59',
    date: `${currentDate}T12:30:00.000Z`,
    reservedUntil: null,
    slotAppointments: [],
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    room: {
      id: 'roo_cjy8zgpuk000zi3vo6tfjh3sx',
      name: 'Consultório 1',
    },
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      profession: {
        id: 'profession_cjz3an5zk00030nxbejbsg6wj',
        name: 'Médico',
      },
    },
    speciality: {
      id: 'spe_cjy92cswd001yi3voc4sjca8c',
      name: 'Médico de família',
    },
    spotlight: null,
    block: null,
    restriction: null,
  },
  {
    id: 'slo_cl811bs9x003e0ja62ns7c4d0',
    agendaId: 'age_ck3iv6xq4000246pq180qa5df',
    reserves: null,
    interval: 15,
    status: 'available',
    startTime: '09:45:00',
    endTime: '09:59:59',
    date: `${currentDate}T12:45:00.000Z`,
    reservedUntil: null,
    slotAppointments: [],
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    room: {
      id: 'roo_cjy8zgpuk000zi3vo6tfjh3sx',
      name: 'Consultório 1',
    },
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      profession: {
        id: 'profession_cjz3an5zk00030nxbejbsg6wj',
        name: 'Médico',
      },
    },
    speciality: {
      id: 'spe_cjy92cswd001yi3voc4sjca8c',
      name: 'Médico de família',
    },
    spotlight: null,
    block: null,
    restriction: null,
  },
  {
    id: 'slo_cl811bs9x003f0ja6aw8a68qn',
    agendaId: 'age_ck3iv6xq4000246pq180qa5df',
    reserves: null,
    interval: 15,
    status: 'available',
    startTime: '10:00:00',
    endTime: '10:14:59',
    date: `${currentDate}T13:00:00.000Z`,
    reservedUntil: null,
    slotAppointments: [],
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    room: {
      id: 'roo_cjy8zgpuk000zi3vo6tfjh3sx',
      name: 'Consultório 1',
    },
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      profession: {
        id: 'profession_cjz3an5zk00030nxbejbsg6wj',
        name: 'Médico',
      },
    },
    speciality: {
      id: 'spe_cjy92cswd001yi3voc4sjca8c',
      name: 'Médico de família',
    },
    spotlight: null,
    block: null,
    restriction: null,
  },
  {
    id: 'slo_cl811bs9x003g0ja61cd10qhx',
    agendaId: 'age_ck3iv6xq4000246pq180qa5df',
    reserves: null,
    interval: 15,
    status: 'available',
    startTime: '10:15:00',
    endTime: '10:29:59',
    date: `${currentDate}T13:15:00.000Z`,
    reservedUntil: null,
    slotAppointments: [],
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    room: {
      id: 'roo_cjy8zgpuk000zi3vo6tfjh3sx',
      name: 'Consultório 1',
    },
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      profession: {
        id: 'profession_cjz3an5zk00030nxbejbsg6wj',
        name: 'Médico',
      },
    },
    speciality: {
      id: 'spe_cjy92cswd001yi3voc4sjca8c',
      name: 'Médico de família',
    },
    spotlight: null,
    block: null,
    restriction: null,
  },
  {
    id: 'slo_cl811bs9x003h0ja6d1ctfrl5',
    agendaId: 'age_ck3iv6xq4000246pq180qa5df',
    reserves: null,
    interval: 15,
    status: 'available',
    startTime: '10:30:00',
    endTime: '10:44:59',
    date: `${currentDate}T13:30:00.000Z`,
    reservedUntil: null,
    slotAppointments: [],
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    room: {
      id: 'roo_cjy8zgpuk000zi3vo6tfjh3sx',
      name: 'Consultório 1',
    },
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      profession: {
        id: 'profession_cjz3an5zk00030nxbejbsg6wj',
        name: 'Médico',
      },
    },
    speciality: {
      id: 'spe_cjy92cswd001yi3voc4sjca8c',
      name: 'Médico de família',
    },
    spotlight: null,
    block: null,
    restriction: null,
  },
  {
    id: 'slo_cl811bs9x003i0ja6cphah8z6',
    agendaId: 'age_ck3iv6xq4000246pq180qa5df',
    reserves: null,
    interval: 15,
    status: 'available',
    startTime: '10:45:00',
    endTime: '10:59:59',
    date: `${currentDate}T13:45:00.000Z`,
    reservedUntil: null,
    slotAppointments: [],
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    room: {
      id: 'roo_cjy8zgpuk000zi3vo6tfjh3sx',
      name: 'Consultório 1',
    },
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      profession: {
        id: 'profession_cjz3an5zk00030nxbejbsg6wj',
        name: 'Médico',
      },
    },
    speciality: {
      id: 'spe_cjy92cswd001yi3voc4sjca8c',
      name: 'Médico de família',
    },
    spotlight: null,
    block: null,
    restriction: null,
  },
  {
    id: 'slo_cl811bs9x003j0ja66bot0rpo',
    agendaId: 'age_ck3iv6xq4000246pq180qa5df',
    reserves: null,
    interval: 15,
    status: 'occupied',
    startTime: '11:00:00',
    endTime: '11:14:59',
    date: `${currentDate}T14:00:00.000Z`,
    reservedUntil: null,
    slotAppointments: [
      {
        id: 'slo_app_clmj8m1qt000w0jlf7lk457b7',
        appointment: {
          id: 'app_clmj8m1qg000v0jlf865xaehu',
        },
      },
    ],
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    room: {
      id: 'roo_cjy8zgpuk000zi3vo6tfjh3sx',
      name: 'Consultório 1',
    },
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      profession: {
        id: 'profession_cjz3an5zk00030nxbejbsg6wj',
        name: 'Médico',
      },
    },
    speciality: {
      id: 'spe_cjy92cswd001yi3voc4sjca8c',
      name: 'Médico de família',
    },
    spotlight: null,
    block: null,
    restriction: null,
  },
  {
    id: 'slo_cl811bs9x003k0ja6e3873q2n',
    agendaId: 'age_ck3iv6xq4000246pq180qa5df',
    reserves: null,
    interval: 15,
    status: 'available',
    startTime: '11:15:00',
    endTime: '11:29:59',
    date: `${currentDate}T14:15:00.000Z`,
    reservedUntil: null,
    slotAppointments: [],
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    room: {
      id: 'roo_cjy8zgpuk000zi3vo6tfjh3sx',
      name: 'Consultório 1',
    },
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      profession: {
        id: 'profession_cjz3an5zk00030nxbejbsg6wj',
        name: 'Médico',
      },
    },
    speciality: {
      id: 'spe_cjy92cswd001yi3voc4sjca8c',
      name: 'Médico de família',
    },
    spotlight: null,
    block: null,
    restriction: null,
  },
  {
    id: 'slo_cl811bs9x003l0ja643m9ces2',
    agendaId: 'age_ck3iv6xq4000246pq180qa5df',
    reserves: null,
    interval: 15,
    status: 'available',
    startTime: '11:30:00',
    endTime: '11:44:59',
    date: `${currentDate}T14:30:00.000Z`,
    reservedUntil: null,
    slotAppointments: [],
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    room: {
      id: 'roo_cjy8zgpuk000zi3vo6tfjh3sx',
      name: 'Consultório 1',
    },
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      profession: {
        id: 'profession_cjz3an5zk00030nxbejbsg6wj',
        name: 'Médico',
      },
    },
    speciality: {
      id: 'spe_cjy92cswd001yi3voc4sjca8c',
      name: 'Médico de família',
    },
    spotlight: null,
    block: null,
    restriction: null,
  },
  {
    id: 'slo_cl811bs9x003m0ja60duoehu2',
    agendaId: 'age_ck3iv6xq4000246pq180qa5df',
    reserves: null,
    interval: 15,
    status: 'available',
    startTime: '11:45:00',
    endTime: '11:59:59',
    date: `${currentDate}T14:45:00.000Z`,
    reservedUntil: null,
    slotAppointments: [],
    clinic: {
      id: 'cli_cjxyringi0000i2vog8g55l7q',
      name: 'Lapa',
    },
    room: {
      id: 'roo_cjy8zgpuk000zi3vo6tfjh3sx',
      name: 'Consultório 1',
    },
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
      profession: {
        id: 'profession_cjz3an5zk00030nxbejbsg6wj',
        name: 'Médico',
      },
    },
    speciality: {
      id: 'spe_cjy92cswd001yi3voc4sjca8c',
      name: 'Médico de família',
    },
    spotlight: null,
    block: null,
    restriction: null,
  },
]
