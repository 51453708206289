const initialState = {
  activated: false,
  status: '',
  message: '',
  button: null,
}

const mutations = {
  SET_ACTIVATED(state, payload) {
    state.activated = payload
  },
  SET_STATUS(state, payload) {
    state.status = payload
  },
  SET_MESSAGE(state, payload) {
    state.message = payload
  },
  SET_BUTTON(state, payload) {
    state.button = payload
  },
}

const actions = {
  setSnackbar({ commit }, { status, message, button }) {
    commit('SET_STATUS', status)
    commit('SET_MESSAGE', message)
    commit('SET_BUTTON', button)
    commit('SET_ACTIVATED', true)

    const SNACKBAR_CLOSE_TIMEOUT = 7000
    setTimeout(() => {
      commit('SET_ACTIVATED', false)
    }, SNACKBAR_CLOSE_TIMEOUT)
  },
  closeSnackbar({ commit }) {
    commit('SET_ACTIVATED', false)
    commit('SET_MESSAGE', '')
  },
}

const getters = {
  activated: state => state.activated,
  status: state => state.status,
  message: state => state.message,
  button: state => state.button,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
