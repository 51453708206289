export default {
  brasilApi: {
    cep: '04651075',
    state: 'SP',
    city: 'São Paulo',
    neighborhood: 'Vila São Paulo',
    street: 'Travessa São Silvestre',
    service: 'correios',
  },
  viaCep: {
    cep: '04651-075',
    logradouro: 'Travessa São Silvestre',
    complemento: '',
    bairro: 'Vila São Paulo',
    localidade: 'São Paulo',
    uf: 'SP',
    ibge: '3550308',
    gia: '1004',
    ddd: '11',
    siafi: '7107',
  },
}
