import { includes } from 'ramda'
import { SCHEDULE } from 'amparo-enums'

export const isInvalidScheduleStatus = status => includes(
  status, [SCHEDULE.status.finished, SCHEDULE.status.canceled],
)

export default {
  isInvalidScheduleStatus,
}
