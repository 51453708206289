export default {
  id: 'con_clm6rmjrg0002xlml1g171k28',
  name: null,
  description: null,
  version: 1,
  status: 'draft',
  requiredRqe: null,
  startDate: null,
  endDate: null,
  createdAt: '2023-09-05T20:30:56.140Z',
  storedAt: null,
  log: null,
  contractAttachments: [],
  journeys: [],
  hmo: {
    id: 'hea_mai_org_cl0mitq7r0001jus931dp3w1i',
    name: 'AEROPORTO - SBBR',
  },
  healthProduct: {
    id: 'hea_pro_cl0mitq7r0001jus931dp3w1i',
    name: 'AEROPORTO - SBBR',
  },
  createdBy: {
    id: 'user_ck3ogz93d0000dvpq3c6gh540',
    name: 'Devs + Pro',
  },
}
