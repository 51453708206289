export default {
  id: 'hea_mai_org_cjy92rrfg002hi3vo8gra8asr',
  name: 'AMIL',
  nickName: 'AMIL',
  allowOwner: true,
  type: 'Tele+Físico',
  attendanceTelephone: null,
  administrativeTelephone: null,
  validationRules: {
    healthInsurancePlanNumberLength: 9,
  },
  portalAccreditation: {
    clinics: null,
  },
  coreAccreditation: {
    clinics: [
      'cli_cjxyringi0000i2vog8g55l7q',
      'cli_ck9b7yjf3000195vohxz60nio',
      'cli_ckn7qpqml0000d5dq3mdjhkzc',
      'cli_cknainh750000z9pqd3et8ixm',
      'cli_cknainh750000z9pqd3et8ixn',
    ],
    professionals: null,
  },
  packageType: 'amparoTotal',
  selfRegistration: true,
  isCommunicationAllowed: false,
  hasEligibilityCheck: true,
  externalIds: {
    cm: {
      id: 'a76fc4ad-767d-45c9-8a73-ef55468b2e0e',
      site: null,
    },
  },
  cmExtraFields: null,
  isUnderAgeImmediateCareAllowed: false,
  hasAps: false,
  healthProducts: [
    {
      id: 'hea_pro_cjy92rrfg002hi3vo8gra8asr',
      healthMaintenanceOrganizationId: 'hea_mai_org_cjy92rrfg002hi3vo8gra8asr',
      name: 'AMIL EXECUTIVO',
      nickName: 'AMIL EXECUTIVO',
      workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
    },
    {
      id: 'hea_pro_clgfk2e5n00030jlobzfa9e6o',
      healthMaintenanceOrganizationId: 'hea_mai_org_cjy92rrfg002hi3vo8gra8asr',
      name: 'AMIL PRIME',
      nickName: 'AMIL PRIME',
      workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
    },
  ],
}
