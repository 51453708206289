import axios from '@/lib/axios'
import moment from 'moment'

export default {
  state: {
    allOrders: [],
    loadingOrder: false,
    loadingOrderList: false,
    loadingSummary: false,
    orderPagination: {},
    orders: [],
    savingOrder: false,
    summary: {},
    orderFilters: {
      startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    },
  },
  mutations: {
    SET_LOADING_ORDER(state, payload) {
      state.loadingOrder = payload
    },
    SET_LOADING_ORDER_LIST(state, payload) {
      state.loadingOrderList = payload
    },
    SET_SAVING_ORDER(state, payload) {
      state.savingOrder = payload
    },
    SET_LOADING_SUMMARY(state, payload) {
      state.loadingSummary = payload
    },
    SET_ORDERS(state, payload) {
      state.orders = payload
    },
    SET_ALL_ORDERS(state, payload) {
      state.allOrders = payload
    },
    SET_SUMMARY(state, payload) {
      state.summary = payload
    },
    SET_ORDER_FILTERS(state, payload) {
      state.orderFilters = payload
      state.orderPagination.page = 1
    },
    SET_ORDER_PAGINATION(state, payload) {
      state.orderPagination = {
        page: payload.page,
        isDesc: payload.descending,
        orderBy: payload.sortBy,
      }
    },
  },
  actions: {
    listOrder({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_ORDER_LIST', true)
        axios.get('/billing/order', {
          params: {
            ...state.orderFilters,
            ...state.orderPagination,
          },
        })
          .then(({ data }) => {
            commit('SET_ORDERS', data)
            resolve(data)
          })
          .catch(reject)
          .finally(() => commit('SET_LOADING_ORDER_LIST', false))
      })
    },
    listAllOrders({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.get('/billing/order', {
          params: {
            ...state.orderFilters,
            listAll: true,
          },
        })
          .then(({ data }) => {
            commit('SET_ALL_ORDERS', data)
            resolve(data)
          })
          .catch(reject)
      })
    },
    async findOrder({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_ORDER', true)
        axios.get('/billing/order/find', { params })
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
          .finally(() => commit('SET_LOADING_ORDER', false))
      })
    },
    getSabinShift(_, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/billing/order/${id}/sabin_shift`)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    getSummary({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_SUMMARY', true)
        axios.get('billing/order/summary', {
          params: {
            ...state.orderFilters,
            ...state.orderPagination,
          },
        })
          .then(({ data }) => {
            commit('SET_SUMMARY', data)
            resolve(data)
          })
          .catch(reject)
          .finally(() => commit('SET_LOADING_SUMMARY', false))
      })
    },
    createOrder(_, attributes) {
      return new Promise((resolve, reject) => {
        axios.post('/billing/order', attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    createOrderSabinShift(_, attributes) {
      return new Promise((resolve, reject) => {
        axios.post('/billing/order/sabin_shift', attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    updateOrder({ commit }, { id, attributes }) {
      return new Promise((resolve, reject) => {
        commit('SET_SAVING_ORDER', true)
        axios.put(`/billing/order/${id}`, attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
          .finally(() => commit('SET_SAVING_ORDER', false))
      })
    },
    bulkUpdateOrders(_, attributes) {
      return new Promise((resolve, reject) => {
        axios.put('/billing/order/bulk_update', attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    editOrder({ commit }, { id, attributes }) {
      return new Promise((resolve, reject) => {
        commit('SET_SAVING_ORDER', true)
        axios.patch(`/billing/order/${id}`, attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
          .finally(() => commit('SET_SAVING_ORDER', false))
      })
    },
    setOrderFilters({ commit }, orderFilters) {
      commit('SET_ORDER_FILTERS', orderFilters)
    },
    setOrderPagination({ commit }, orderPagination) {
      commit('SET_ORDER_PAGINATION', orderPagination)
    },
  },
  getters: {
    allOrders: state => state.allOrders,
    isLoadingOrder: state => state.loadingOrder,
    isLoadingOrderList: state => state.loadingOrderList,
    isLoadingSummary: state => state.loadingSummary,
    isSavingOrder: state => state.savingOrder,
    orderPagination: state => state.orderPagination,
    orders: state => state.orders,
    summary: state => state.summary,
  },
}
