<template>
  <div>
    <template v-if="shouldShowShortText">
      {{ text | trimStringBy(limitLength) }}
    </template>
    <template v-else>
      {{ text }}
    </template>
    <v-btn
      v-if="isTextHigherThanLimit"
      text
      block
      class="btn"
      color="japaneseLaurel"
      @click="toggleIsExpanded"
    >
      {{ toggleButtonText }}
    </v-btn>
  </div>
</template>

<script>
import {
  and,
  gte,
  length,
  not,
} from 'ramda'

export default {
  props: {
    limitLength: {
      type: Number,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      isExpanded: false,
    }
  },
  computed: {
    shouldShowShortText() {
      return and(this.isTextHigherThanLimit, not(this.isExpanded))
    },
    isTextHigherThanLimit() {
      return gte(length(this.text), this.limitLength)
    },
    toggleButtonText() {
      return this.isExpanded ? 'Encolher' : 'Ver mais...'
    },
  },
  methods: {
    toggleIsExpanded() {
      this.isExpanded = !this.isExpanded
    },
  },
}
</script>
