export default {
  ENCOUNTER_ANNOTATION_REASON: {
    ADMINISTRATIVE_DEMAND: {
      label: 'Demanda Administrativa',
      value: 'administrative_demand',
    },
    ERRATIC: {
      label: 'Errata',
      value: 'erratic',
    },
    TELEMONITORING: {
      label: 'Telemonitoramento',
      value: 'telemonitoring',
    },
    PRESCRIPTION: {
      label: 'Emissão de Prescrição',
      value: 'prescription',
    },
    MISSING_PATIENT: {
      label: 'Paciente faltou',
      value: 'missing_patient',
    },
    PRESENTIAL: {
      label: 'Presencial',
      value: 'presential',
    },
  },
}
