import axios from '@/lib/axios'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'
import { parseAgendaFilters } from './utils'

const initialState = {
  loadingAppointment: false,
  appointments: [],
}

const mutations = {
  SET_APPOINTMENTS(state, payload) {
    state.appointments = payload
  },
  SET_LOADING_APPOINTMENT(state, payload) {
    state.loadingAppointment = payload
  },
}

const actions = {
  createAppointment({ commit }, attributes) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_APPOINTMENT', true)
      axios.post('appointment', attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_APPOINTMENT', false))
    })
  },
  updateAppointment({ commit }, { id, attributes }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_APPOINTMENT', true)
      axios.patch(`appointment/${id}`, attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_APPOINTMENT', false))
    })
  },
  rescheduleAppointment(_, { id, attributes }) {
    return new Promise((resolve, reject) => {
      axios.patch(`appointment/reschedule/${id}`, attributes)
        .then((data) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
  updateIsHmoWarningMessageReadAppointment(_, id) {
    return new Promise((resolve, reject) => {
      axios.patch(`appointment/hmo_warning_message/${id}`)
        .then((data) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
  listAppointment({ commit }, filters = {}) {
    const params = parseAgendaFilters(removeNilOrEmpty(filters))
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_APPOINTMENT', true)
      axios.get('appointment', { params })
        .then(({ data: { appointments } }) => {
          if (filters.omitValuesOnStore !== true) {
            commit('SET_APPOINTMENTS', appointments)
          }
          resolve(appointments)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING_APPOINTMENT', false))
    })
  },
  getAppointmentLogs(__, { id }) {
    return new Promise((resolve, reject) => {
      axios.get(`appointment/${id}/logs`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
}

const getters = {
  appointments: state => state.appointments,
  loadingAppointment: state => state.loadingAppointment,
}

export default {
  state: initialState,
  mutations,
  actions,
  getters,
}
