export default {
  agendaWithoutStatus: {
    agendas: [
      {
        distinctProfessionalSpecialityClinic: 1,
        id: 'age_ckn91albg00005opq51up157p',
        dayOfTheWeek: 3,
        startTime: '09:00:00',
        endTime: '12:00:00',
        clinic: {
          id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
          name: 'Pinheiros',
          startTime: '07:00:00',
          endTime: '19:00:00',
        },
        professional: {
          id: 'pro_ck3ogpnob0001mhpq5of978kw',
          name: 'Brutus (.com)',
        },
        speciality: {
          id: 'spe_cjy92cswd001yi3voc4sjca8c',
          name: 'Médico de família',
        },
      },
      {
        distinctProfessionalSpecialityClinic: 1,
        id: 'age_cln3hwfla00522iqk6cvqgl6n',
        dayOfTheWeek: 0,
        startTime: '07:00:00',
        endTime: '07:05:00',
        clinic: {
          id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
          name: 'Pinheiros',
          startTime: '07:00:00',
          endTime: '19:00:00',
        },
        professional: {
          id: 'pro_cln3hqwn700422iqkfocuhser',
          name: 'Siobhan Archer',
        },
        speciality: {
          id: 'spe_cjy92cswd001yi3voc4sjca8c',
          name: 'Médico de família',
        },
      },
    ],
    numberOfPages: 1,
    count: '2',
  },
  agendaWithStatusOpen: {
    agendas: [
      {
        startDate: '2023-08-05 00:00:00',
        endDate: '2023-10-05 00:00:00',
        id: 'age_ckn91albg00005opq51up157p',
        startTime: '09:00:00',
        endTime: '12:00:00',
        interval: 15,
        maxSlots: 2,
        dayOfTheWeek: 3,
        storedAt: null,
        clinic: {
          id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
          name: 'Pinheiros',
          startTime: '07:00:00',
          endTime: '19:00:00',
        },
        professional: {
          id: 'pro_ck3ogpnob0001mhpq5of978kw',
          name: 'Brutus (.com)',
        },
        speciality: {
          id: 'spe_cjy92cswd001yi3voc4sjca8c',
          name: 'Médico de família',
        },
        room: {
          id: 'roo_ckn919a180000yfpq8abx3q06',
          name: 'Consultório 2',
        },
        reserves: [],
      },
    ],
    numberOfPages: 1,
    count: 1,
  },
}
