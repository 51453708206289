<template>
  <div>
    <component-title
      new-hmo
    />
    <contract-list-container />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import ContractListContainer from './ContractListContainer'

export default {
  components: {
    ComponentTitle,
    ContractListContainer,
  },
}
</script>
