<template>
  <div>
    <v-layout
      v-if="encounterData"
      :class="{
        'my-6 mx-2': row,
        'column': column,
      }"
    >
      <v-flex
        :class="{
          'card__infos-container--partial-width': row,
          'card__infos-container--full-width': column,
        }"
        column
      >
        <v-flex
          column
        >
          <v-flex class="card__infos-sub-title">
            HPMA
          </v-flex>
          <v-flex
            class="card__infos-name"
          >
            • {{ hpma }}
          </v-flex>
        </v-flex>
        <v-flex
          column
        >
          <v-flex class="card__infos-sub-title mt-4">
            Queixa Principal
          </v-flex>
          <v-flex
            class="card__infos-name"
          >
            • {{ principalComplaint }}
          </v-flex>
        </v-flex>
      </v-flex>
      <v-flex
        ml-6
        :class="{
          'card__infos-container--partial-width': row,
          'card__infos-container--full-width': column,
        }"
        column
      >
        <v-flex
          column
        >
          <v-flex class="card__infos-sub-title">
            Sinais de Alerta
          </v-flex>
          <v-flex
            class="card__infos-name"
          >
            • {{ medicalCertificate.alertSignals ? 'Sim' : 'Não' }}
          </v-flex>
        </v-flex>
        <v-flex
          column
        >
          <v-flex class="card__infos-sub-title mt-4">
            Orientações
          </v-flex>
          <v-flex
            class="card__infos-name"
          >
            • {{ orientation }}
          </v-flex>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-divider
      v-if="row"
      class="mx-2"
    />
    <v-layout
      v-if="encounterData"
      :class="{
        'my-6 mb-2 mx-2': row,
        'column': column,
      }"
    >
      <v-flex
        :class="{
          'card__infos-container--partial-width': row,
          'card__infos-container--full-width': column,
        }"
        column
      >
        <v-flex class="card__group-title">
          Conduta
        </v-flex>
        <v-flex
          v-if="conditions"
          shrink
          column
        >
          <v-flex class="card__infos-sub-title">
            Hipoteses diagnósticas
          </v-flex>
          <v-flex
            v-for="(condition, index) of conditions"
            :key="index"
            class="card__infos-name"
          >
            • {{ getName(condition) }} {{ condition.status ? `- ${condition.status}` : '' }}
          </v-flex>
        </v-flex>
        <v-flex
          column
        >
          <v-flex class="card__infos-sub-title mt-4">
            Novas prescrições
          </v-flex>
          <v-flex
            v-if="treatments"
          >
            <v-flex
              v-for="(treatment, index) in treatments"
              :key="index"
              class="card__infos-name"
            >
              • {{ treatment.name }} - {{ treatment.status }}
            </v-flex>
          </v-flex>
          <v-flex
            v-else
            class="card__infos-name"
          >
            • Nenhuma
          </v-flex>
        </v-flex>
      </v-flex>
      <v-flex
        ml-6
        :class="{
          'card__infos-container--partial-width': row,
          'card__infos-container--full-width': column,
        }"
        column
      >
        <v-flex
          column
        >
          <v-flex class="card__infos-sub-title">
            Atestado
          </v-flex>
          <v-flex
            class="card__infos-name"
          >
            • {{ medicalCertificate.conditionalValue ? 'Sim' : 'Não' }}
          </v-flex>
        </v-flex>
        <v-flex
          column
        >
          <v-flex class="card__infos-sub-title mt-4">
            Solicitações de exames
          </v-flex>
          <v-flex
            v-if="examRequests"
          >
            <v-flex
              v-for="(exam, index) of examRequests"
              :key="index"
              class="card__infos-name"
            >
              • {{ getName(exam) }}
            </v-flex>
          </v-flex>
          <v-flex
            v-else
            class="card__infos-name"
          >
            • Nenhum
          </v-flex>
        </v-flex>
        <v-flex
          v-if="medicalReferral"
          column
        >
          <v-flex class="card__infos-sub-title mt-4">
            Encaminhamentos
          </v-flex>
          <v-flex
            v-if="medicalReferral"
          >
            <v-flex
              v-for="(referral, index) of medicalReferral"
              :key="index"
              class="card__infos-name"
            >
              • {{ referral.specialityName }}
            </v-flex>
          </v-flex>
          <v-flex
            v-else
            class="card__infos-name"
          >
            • Nenhum
          </v-flex>
        </v-flex>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import {
  has,
  isNil,
} from 'ramda'
import { mapGetters } from 'vuex'

export default {
  props: [
    'encounterData',
    'row',
    'column',
    'encounterFormName',
  ],
  data() {
    return {
      additionalInformationO: 'que_ck612ucxe0000vl3a6vch2c7s',
      additionalInformationP: 'que_ck4d2i1rp0000qw3a1z83fvvp',
      limitAdditionalInformationLength: 850,
    }
  },
  computed: {
    ...mapGetters({
      questions: 'emr/questions',
      notFoundMessage: 'emr/NotFoundMessage',
    }),
    conditions() {
      return this.encounterData[this.questions.conditions]
    },
    examRequests() {
      return this.getValue(this.encounterData[this.questions.examRequests])
    },
    medicalReferral() {
      return this.getValue(this.encounterData[this.questions.medicalReferral])
    },
    treatments() {
      return this.encounterData[this.questions.treatments]
    },
    interventions() {
      return this.getValue(this.encounterData[this.questions.interventions])
    },
    hpma() {
      return this.getValue(this.encounterData[this.questions.hpma])
    },
    principalComplaint() {
      return this.getValue(this.encounterData[this.questions.principalComplaint])
    },
    medicalCertificate() {
      return this.encounterData[this.questions.medicalCertificate] || {}
    },
    alertSignals() {
      return this.encounterData[this.questions.alertSignals] || {}
    },
    orientation() {
      return this.getValue(this.encounterData[this.questions.orientations] || {})
    },
  },
  methods: {
    getValue(data) {
      if (isNil(data)) return data
      if (has('value', data)) {
        return data.value
      }
      return data
    },
    getName({ name, code, version }) {
      if (this.isLegacy) {
        return `${name}`
      }
      return `${version || this.notFoundMessage.version} - ${code || this.notFoundMessage.code} - ${name}`
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../../style/_core/colors.styl'

info-container-position(directionIsRow = true)
  if directionIsRow
    width 20%
  else
    margin-top 5%
    width 100%

.card__infos-container--full-width
  info-container-position(false)

.card__infos-container--partial-width
  info-container-position()

.card__infos-name
  color rgba(#000, 0.6)
  margin-top 5px
  margin-bottom 5px

.card__group-title
  flex 0
  margin-bottom 1.2rem
  color $evening-sea
  font-size 1.2em

.card__infos-sub-title
  color black
  margin-bottom .4rem
</style>
