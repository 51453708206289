import agenda from './agenda'
import appointment from './appointment'
import slot from './slot'
import videoRoom from './videoRoom'
import block from './block'
import spotlight from './spotlight'
import restriction from './restriction'

const state = {
  ...agenda.state,
  ...appointment.state,
  ...slot.state,
  ...videoRoom.state,
  ...block.state,
  ...spotlight.state,
  ...restriction.state,
}

const mutations = {
  ...agenda.mutations,
  ...appointment.mutations,
  ...slot.mutations,
  ...videoRoom.mutations,
  ...block.mutations,
  ...spotlight.mutations,
  ...restriction.mutations,
}

const actions = {
  ...agenda.actions,
  ...appointment.actions,
  ...slot.actions,
  ...videoRoom.actions,
  ...block.actions,
  ...spotlight.actions,
  ...restriction.actions,
}

const getters = {
  ...agenda.getters,
  ...appointment.getters,
  ...slot.getters,
  ...videoRoom.getters,
  ...block.getters,
  ...spotlight.getters,
  ...restriction.getters,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
