<template>
  <div>
    <router-view />
    <component-title />
    <preferred-network-list />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import PreferredNetworkList from './PreferredNetworkList'

export default {
  name: 'PreferredNetworkPage',
  components: {
    ComponentTitle,
    PreferredNetworkList,
  },
}
</script>
