export default {
  IS_PAID: {
    TRUE: {
      LABEL: 'Sim',
      VALUE: true,
    },
    FALSE: {
      LABEL: 'Não',
      VALUE: false,
    },
  },
}
