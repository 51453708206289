import {
  cond,
  always,
  T,
} from 'ramda'

const createMessage = ({
  extendAppointment,
  isFirstAppointment,
}) => cond([
  [
    () => isFirstAppointment,
    always('O horário será estendido a depender da configuração do plano de saúde'),
  ],
  [
    () => extendAppointment,
    always('Recomendado por estar em linha de cuidado especial'),
  ],
  [T, always(null)],
])()

const generateAppointmentExtendedCondition = ({
  extendAppointment,
  isFirstAppointment,
  isPatientOnActivationJourney,
  journeys,
}) => {
  const message = createMessage({
    extendAppointment,
    isFirstAppointment,
  })

  return {
    isFirstAppointmentAfterActivationJourney: isFirstAppointment,
    message,
    isPatientOnActivationJourney,
    journeys,
  }
}

export default generateAppointmentExtendedCondition
