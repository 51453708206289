<template>
  <div>
    <template v-for="option of tag.options || tag.careLineTagOptions">
      <v-chip
        v-if="shouldShowTag(option)"
        :key="option.id"
        class="mx-1"
        :color="`${option.color} darken-3`"
        outlined
        @click="emitTagClicked"
      >
        <div class="option">
          <span class="option__name">{{ tag.name }}</span>
          -
          <span>{{ option.label }}</span>
        </div>
        <div v-if="shouldShowDeleteButton">
          <v-btn
            text
            icon
            color="error"
            @click.stop="openDeleteCareLineTagPatient"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </div>
      </v-chip>
    </template>
    <div v-if="shouldShowStatusLog()">
      <span
        class="careline__status-log"
        @click.stop="openCareLineTimeline"
      >
        Ver Histórico de Alterações
      </span>
    </div>
    <v-dialog
      v-model="isOpenDeleteCareLineTagPatient"
      class="not-printable-area"
      max-width="450"
    >
      <v-card>
        <v-card-title>
          Deseja realmente remover essa tag do paciente ?
        </v-card-title>
        <v-card-actions>
          <v-btn
            class="btn-dialog"
            color="green darken-1"
            text
            large
            @click="closeDeleteCareLineTagPatient"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            class="btn-dialog"
            large
            color="error"
            @click="handleDeleteCareLinePatient"
          >
            Remover
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showCareLineTimeline"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
    >
      <v-card>
        <v-card-title class="card-dialog__title">
          Histórico de Alterações
          <v-spacer />
          <v-btn
            icon
            @click="closeCareLineTimeline"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <care-line-timeline
          v-if="showCareLineTimeline"
          :care-line-activity-log="tag.activityLog"
          care-line-instance="careLineTag"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  and,
  not,
  isNil,
} from 'ramda'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    CareLineTimeline: () => import('./index'),
  },
  props: {
    tag: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    preview: {
      type: Boolean,
      required: false,
      default: false,
    },
    showLog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpenDeleteCareLineTagPatient: false,
      deletedCareLineTagPatientId: '',
      showCareLineTimeline: false,
    }
  },
  computed: {
    ...mapGetters({
      patient: 'emr/patient',
    }),
    patientId() {
      return this.patient.id
    },
    shouldShowDeleteButton() {
      return not(this.preview)
    },
  },
  methods: {
    ...mapActions({
      deleteCareLineTagPatient: 'careLine/deleteCareLineTagPatient',
      setEmrPatient: 'emr/setEmrPatient',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    shouldShowTag({ label, color }) {
      return and(label, color)
    },
    emitTagClicked() {
      this.$emit('clickTag', this.tag)
    },
    openDeleteCareLineTagPatient() {
      this.deletedCareLineTagPatientId = this.tag.id
      this.isOpenDeleteCareLineTagPatient = true
    },
    closeDeleteCareLineTagPatient() {
      this.deletedCareLineTagPatientId = ''
      this.isOpenDeleteCareLineTagPatient = false
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    async handleDeleteCareLinePatient() {
      try {
        await this.deleteCareLineTagPatient(this.deletedCareLineTagPatientId)
        this.closeDeleteCareLineTagPatient()
        await this.setEmrPatient(this.patientId)
        this.buildSnackbar('success', 'Tag removida do paciente com sucesso!')
      } catch (error) {
        this.buildSnackbar('error', 'Erro interno!')
      }
    },
    openCareLineTimeline() {
      this.showCareLineTimeline = true
    },
    closeCareLineTimeline() {
      this.showCareLineTimeline = false
    },
    shouldShowStatusLog() {
      return and(
        this.showLog,
        not(isNil(this.tag.activityLog)),
      )
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.option
  display flex
  align-items center
  gap .4rem

.option__name
  color secondary-color

.careline__status-log
  cursor pointer
  text-decoration underline
  color #55d058

</style>
