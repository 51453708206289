import axios from '@/lib/axios'

const initialState = {
  loading: false,
  careCycleList: [],
  careCycleFilters: {
    page: 1,
  },
  pages: 0,
  count: 0,
}

const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_CARE_CYCLE_LIST(state, payload) {
    state.careCycleList = payload
  },
  SET_CARE_CYCLE(state, payload) {
    state.careCycle = payload
  },
  SET_PAGES(state, value) {
    state.pages = value
  },
  SET_COUNT(state, value) {
    state.count = value
  },
  SET_CARE_CYCLE_FILTERS(state, payload) {
    state.careCycleFilters = payload
  },
}

const actions = {
  setCareCycleFilters({ commit }, params) {
    commit('SET_CARE_CYCLE_FILTERS', params)
  },
  listCareCycle({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      axios.get('/emr/care_cycle', { params })
        .then(
          ({
            data: {
              careCycles: {
                careCycleList,
                numberOfPages,
                count,
              },
            },
          }) => {
            commit('SET_CARE_CYCLE_LIST', careCycleList)
            commit('SET_PAGES', numberOfPages)
            commit('SET_COUNT', count)
            resolve()
          },
        )
        .catch((e) => {
          reject(e)
        })
    })
  },
  updateCareCycle({ commit }, { careCycleId, careCycleData }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.patch(`emr/care_cycle/${careCycleId}`, careCycleData)
        .then(({ data }) => resolve(data))
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  getCareCycleByDisease({ commit }, { diseaseId }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.get(`emr/care_cycle/disease/${diseaseId}`)
        .then(({ data: { careCycle } }) => {
          resolve(careCycle)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
}

const getters = {
  isLoading: state => state.loading,
  careCycle: state => state.careCycle,
  careCycleFilters: state => state.careCycleFilters,
  careCycleList: state => state.careCycleList,
  pages: state => state.pages,
  count: state => state.count,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
