import axios from '@/lib/axios'

const initialState = {
  loadingVideoRoom: false,
}

const mutations = {
  SET_LOADING_VIDEO_ROOM(state, payload) {
    state.loadingVideoRoom = payload
  },
}

const actions = {
  createVideoRoom({ commit }, attributes) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_VIDEO_ROOM', true)
      axios.post('/tele/video_room/', attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_VIDEO_ROOM', false))
    })
  },
  deleteVideoRoom({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_VIDEO_ROOM', true)
      axios.delete(`/tele/video_room/${id}`)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_VIDEO_ROOM', false))
    })
  },
}

const getters = {
  loadingVideoRoom: state => state.loadingVideoRoom,
}

export default {
  state: initialState,
  mutations,
  actions,
  getters,
}
