<template>
  <div>
    <component-title />
    <v-flex
      v-if="isLoading"
      class="contract__loading"
    >
      <circular-loader />
    </v-flex>
    <edit-contract-container
      v-else
    />
  </div>
</template>

<script>
import CircularLoader from '@/components/UI/CircularLoader'
import ComponentTitle from '@/components/ComponentTitle'
import { mapActions, mapGetters } from 'vuex'
import EditContractContainer from './EditContractContainer'

export default {
  name: 'EditContractPage',
  components: {
    CircularLoader,
    ComponentTitle,
    EditContractContainer,
  },
  computed: {
    ...mapGetters({
      isLoading: 'billing/isLoadingContract',
    }),
  },
  async mounted() {
    const { contractId } = this.$route.params
    await this.getContract(contractId)
  },
  methods: {
    ...mapActions({
      getContract: 'billing/getContract',
    }),
  },
}
</script>

<style lang="stylus" scoped>
.contract__loading
  margin-top 20px
  display flex
  justify-content center
</style>
