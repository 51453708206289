<template>
  <div>
    <v-flex
      column
      justify-space-around
    >
      <v-flex>
        Prezado (a),
      </v-flex>
      <v-flex mt-4>
        Encaminho o paciente {{ patient | formatDisplayName }}
        para {{ referral.objective }}
        com especialista em {{ referral.specialityName }}
        {{ referral.professionalName ? `com profissional ${referral.professionalName}` : '' }}.
      </v-flex>
      <v-flex
        v-if="referral.observations"
        mt-6
        column
      >
        <v-flex class="font-weight-bold">
          Observações:
        </v-flex>
        <v-flex class="referral__observations">
          {{ referral.observations }}
        </v-flex>
      </v-flex>
    </v-flex>
    <v-flex mt-12>
      Estou à disposição para qualquer esclarecimento.
    </v-flex>
    <v-flex
      column
      mt-4
    >
      <v-flex>Att,</v-flex>
      <v-flex>{{ doctorName }}</v-flex>
    </v-flex>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: [
    'referral',
    'doctorName',
  ],
  data() {
    return {
      diagnosis: true,
    }
  },
  computed: {
    ...mapGetters({
      patient: 'emr/patient',
    }),
  },
}
</script>

<style lang="stylus" scoped>
.referral__observations
  white-space pre-line
  margin-top .5cm
</style>
