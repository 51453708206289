import { rest } from 'msw'
import baseUrl from '../../../../src/utils/baseUrlTest'
import orderListData from '../../fixtures/billing/order/list'

export default [
  rest.post(baseUrl('/billing/order'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(orderListData),
  )),
]
