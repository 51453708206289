<template>
  <div>
    <v-menu
      transition="slide-y-transition"
      open-on-click
      bottom
      right
      attach
      offset-y
    >
      <template
        #activator="{ on }"
        class="user-button"
      >
        <div v-on="on">
          <span class="a-link">
            Relatórios
          </span>
          <v-icon>mdi-menu-down</v-icon>
        </div>
      </template>
      <v-list>
        <v-list-item
          v-for="item in allowedItems"
          :key="item.title"
          class="user-dropdown-tile"
          @click="item.method"
        >
          <v-list-item-title class="user-dropdown-itens">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  filter,
} from 'ramda'

export default {
  name: 'UserDropdown',
  data() {
    return {
      items: [
        {
          title: 'Populacional',
          routeName: 'populationPanel',
          method: () => this.goToDashboard('population_panel'),
        },
        {
          title: 'Operacional',
          routeName: 'operationalPanel',
          method: () => this.goToDashboard('operational_panel'),
        },
        {
          title: 'Jornada do Paciente',
          routeName: 'patientJourneyPanel',
          method: () => this.goToDashboard('patient_journey_panel'),
        },
        {
          title: 'Carteiras',
          routeName: 'walletPanel',
          method: () => this.goToDashboard('wallet_panel'),
        },
        {
          title: 'Atendimentos Faturados',
          routeName: 'attendancePanel',
          method: () => this.goToDashboard('attendance_panel'),
        },
        {
          title: 'Pacientes em Investigação',
          routeName: 'investigationPanel',
          method: () => this.goToDashboard('investigation_panel'),
        },
        {
          title: 'Relatório de Atendimentos',
          routeName: 'attendanceReport',
          method: this.goToAttendanceReport,
        },
        {
          title: 'Relatório Performance',
          routeName: 'performanceDashboard',
          method: () => this.goToDashboard('performance_panel'),
        },
        {
          title: 'Relatório Clínico',
          routeName: 'clinicalDashboard',
          method: () => this.goToDashboard('clinical_panel'),
        },
        {
          title: 'Radar de Pacientes',
          routeName: 'patientRadar',
          method: () => this.goToDashboard('patient_radar'),
        },
        {
          title: 'Relatório de Agendamentos',
          routeName: 'appointmentPanel',
          method: () => this.goToDashboard('appointment_panel'),
        },
        {
          title: 'Painel de Auditoria',
          routeName: 'auditPanel',
          method: () => this.goToDashboard('audit_panel'),
        },
        {
          title: 'Relatório de Desfecho',
          routeName: 'outcomePanel',
          method: () => this.goToDashboard('outcome_panel'),
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
    }),
    allowedItems() {
      return filter(
        ({ routeName }) => this.userHasAccessToFunctionality[routeName],
        this.items,
      )
    },
  },
  methods: {
    goToAttendanceReport() {
      return this.$router.push('/report/attendance')
    },
    goToEngagementReport() {
      return this.$router.push('/care-team/patients')
    },
    goToDashboard(dashboard) {
      return this.$router.push(`/dashboard/${dashboard}`)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../../style/_core/fonts.styl'

.user-dropdown-tile:hover
  background-color: rgba(0, 0, 0, 0.1);
  cursor pointer

.user-button
  display flex
  align-items center
  height 100%
  font-size 16px

.user-dropdown-itens
  display flex
  align-items center

.user-dropdown-icon
  margin-right 15px
</style>
