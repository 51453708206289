import { rest } from 'msw'
import baseUrl from '../../../../src/utils/baseUrlTest'
import specialityListData from '../../fixtures/public/speciality/list'

export default [
  rest.get(baseUrl('/speciality'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      specialities: specialityListData,
    }),
  )),
]
