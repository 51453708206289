<template>
  <v-flex v-if="shouldShowDialogButton">
    <v-btn
      outlined
      large
      block
      class="btn btn-bold font-weight-medium mt-2"
      color="japaneseLaurel"
      @click="showDialog = true"
    >
      <v-icon
        class="font-weight-medium mx-2 mr-4"
        left
        size="25px"
      >
        mdi-folder-download
      </v-icon>
      Resumo importado
    </v-btn>
    <v-dialog
      v-model="showDialog"
      max-width="1000"
    >
      <v-card>
        <v-card-title class="card-dialog__title">
          Resumo importado
          <v-spacer />
          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="column">
          <v-flex
            v-for="(property, index) of filteredLegacy"
            :key="index"
            column
            class="mb-1"
          >
            <v-flex
              v-if="property.length > 0"
              class="font-weight-bold resume__property-title font-weight-medium mb-1"
            >
              {{ translatedProperties[index] }}
            </v-flex>
            <v-flex
              v-for="item in property"
              :key="item.id"
              class="resume__property-values ml-1"
            >
              <span v-if="item.name">
                <span class="mr-2">•</span>
                {{ item.name }} {{ item.status ? `- ${item.status}` : '' }}
              </span>
              <v-flex
                v-if="item.specialityName"
                column
              >
                <span>
                  <span class="mr-2">•</span>
                  {{ item.specialityName }}
                </span>
                <v-flex column>
                  <span class="my-1">Observações:</span>
                  <span>{{ item.observations }}</span>
                  <v-divider class="my-2" />
                </v-flex>
              </v-flex>
            </v-flex>
          </v-flex>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import {
  not,
  isEmpty,
  values,
  flatten,
  isNil,
  pickAll,
  keys,
} from 'ramda'

export default {
  props: ['resume'],
  data() {
    return {
      showDialog: false,
      translatedProperties: {
        habits: 'Hábitos',
        vaccines: 'Vacinas',
        allergies: 'Alergias',
        careLines: 'Linhas de cuidado',
        reminders: 'Lembretes',
        surgeries: 'Cirurgias',
        conditions: 'Condições',
        examRequests: 'Exames solicitados',
        familyHistory: 'Histórico familiar',
        treatments: 'Tratamentos',
        medicalReferrals: 'Encaminhamentos',
        clinicalBackground: 'Antecedentes clínicos',
      },
    }
  },
  computed: {
    shouldShowDialogButton() {
      return not(isEmpty(flatten(values(this.filteredLegacy))))
    },
    filteredLegacy() {
      if (not(isNil(this.resume))) return pickAll(keys(this.translatedProperties), this.resume)
      return []
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.resume__property-title
  font-size 1.5rem

.resume__property-values
  color rgba(#000, 0.7)
</style>
