<template>
  <v-data-table
    v-if="shouldShowFreeSlots"
    class="pa-6"
    :headers="tableHeaders"
    :items="slots"
    hide-default-footer
    disable-pagination
  >
    <template #item="{ item }">
      <tr>
        <td class="table__data">
          {{ item[0].clinic.name }}
        </td>
        <td class="table__data">
          {{ item[0].speciality.name }}
        </td>
        <td class="table__data">
          {{ item[0].professional.name }}
        </td>
        <td>
          <template v-for="(slot, index) in item">
            <v-btn
              :key="index"
              class="btn btn-secondary ml-3"
              @click="sendSlotDataToFilters(slot)"
            >
              {{ slot.startTime | removeSecondsFromTime }} |
              {{ slot.date | formatDate }}
              {{ slot.reserves ? '| ' + slot.reserves[0] : '' }}
            </v-btn>
          </template>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  and,
  not,
  isEmpty,
  head,
} from 'ramda'

export default {
  props: ['agendaFilters'],
  data() {
    return {
      tableHeaders: [
        {
          text: 'Unidade',
          value: 'clinic.name',
          sortable: false,
        },
        {
          text: 'Especialidade',
          value: 'speciality.name',
          sortable: false,
        },
        {
          text: 'Profissional',
          value: 'professional.name',
          sortable: false,
        },
        {
          text: 'Próximos horários disponíveis',
          value: 'date',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      slots: 'agenda/slots',
      isLoadingSlot: 'agenda/loadingSlot',
    }),
    shouldShowFreeSlots() {
      return and(
        not(this.isLoadingSlot),
        not(isEmpty(head(this.slots))),
      )
    },
  },
  methods: {
    sendSlotDataToFilters(slot) {
      this.$emit('goToAgenda', slot)
    },
  },
}
</script>
