<template>
  <div>
    <component-title />
    <div class="px-16 py-6">
      <v-text-field
        v-model="userFilter"
        class="mb-10"
        label="Buscar usuário"
        placeholder="ID, E-mail ou nome"
        persistent-placeholder
        hide-details
        outlined
        @input="search()"
      />
      <div
        v-if="hasUser"
      >
        <advanced-user-list
          :users="users"
          @click:impersonate-user="changeConfirmImpersonateModalValue"
        />
      </div>
      <div
        v-else
        class="text-h4"
      >
        <span v-if="filterIsEmpty">
          Digite para buscar
        </span>
        <span v-else-if="!awaitingToSearch">
          Nenhum usuário encontrado
        </span>
        <span v-else>
          Buscando...
        </span>
      </div>
    </div>
    <confirm-impersonate-user-modal
      v-if="selectedUser !== null"
      v-model="confirmImpersonateModalValue"
      :user="selectedUser"
      @confirm:impersonate-user="confirmImpersonateUser"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import {
  isEmpty,
} from 'ramda'

export default {
  components: {
    ComponentTitle: () => import('@/components/ComponentTitle'),
    AdvancedUserList: () => import('@/components/Advanced/User/List'),
    ConfirmImpersonateUserModal: () => import('@/components/Advanced/User/ConfirmImpersonateUserModal'),
  },
  data() {
    return {
      userFilter: '',
      users: [],
      awaitingToSearch: false,
      confirmImpersonateModalValue: false,
      selectedUser: null,
    }
  },
  computed: {
    hasUser() {
      return !isEmpty(this.users)
    },
    filterIsEmpty() {
      return isEmpty(this.userFilter)
    },
  },
  methods: {
    ...mapActions({
      listUser: 'advanced/listUser',
      impersonateUser: 'advanced/impersonateUser',
    }),
    async search() {
      this.users = []
      if (isEmpty(this.userFilter)) {
        this.awaitingToSearch = false
        return
      }
      if (!this.awaitingToSearch) {
        setTimeout(async () => {
          this.users = await this.listUser(this.userFilter)
          this.awaitingToSearch = false
        }, 1000)
      }
      this.awaitingToSearch = true
    },
    async confirmImpersonateUser(workSpaceId) {
      await this.impersonateUser({
        userId: this.selectedUser.id,
        workSpaceId,
      })
      this.$router.push('/')
      this.selectedUser = null
    },
    changeConfirmImpersonateModalValue(user) {
      this.selectedUser = user
      this.confirmImpersonateModalValue = !this.confirmImpersonateModalValue
    },
  },
}
</script>
