export default [
  {
    formatted: {
      id: 'con_ite_clmf1y8ga00012jmld7sccpk2',
      externalCode: '50000470',
      externalName: 'SESSÃO PSICOLOGIA PRESENCIAL',
      price: 10601,
      clinicId: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
      storedAt: null,
      itemId: 'ite_cl44iauc0000977zu2jif6vfs',
      contractId: 'con_clm6rmjrg0002xlml1g171k28',
      returnalDays: 52,
      hasReturnalByCid: false,
      returnalWithSameProfessional: false,
      defaultSlots: 2,
      workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
    },
    id: 'con_ite_clmf1y8ga00012jmld7sccpk2',
    externalCode: '50000470',
    externalName: 'SESSÃO PSICOLOGIA PRESENCIAL',
    price: 10601,
    itemId: 'ite_cl44iauc0000977zu2jif6vfs',
    clinicId: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
    contractId: 'con_clm6rmjrg0002xlml1g171k28',
    returnalDays: 52,
    hasReturnalByCid: false,
    returnalWithSameProfessional: false,
    defaultSlots: 2,
    workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
    storedAt: null,
    createdAt: '2023-09-11T03:42:06.000Z',
    updatedAt: '2023-09-11T03:42:06.000Z',
    deletedAt: null,
  },
  {
    formatted: {
      id: 'con_ite_clmf1y8gb00022jml8b16197t',
      externalCode: '50000705',
      externalName: 'CONSULTA ENFERMAGEM REMOTA',
      price: 10000,
      clinicId: null,
      storedAt: null,
      itemId: 'ite_cl44iaubu000077zu8hcodjgm',
      contractId: 'con_clm6rmjrg0002xlml1g171k28',
      returnalDays: 10,
      hasReturnalByCid: false,
      returnalWithSameProfessional: false,
      defaultSlots: 1,
      workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
    },
    id: 'con_ite_clmf1y8gb00022jml8b16197t',
    externalCode: '50000705',
    externalName: 'CONSULTA ENFERMAGEM REMOTA',
    price: 10000,
    itemId: 'ite_cl44iaubu000077zu8hcodjgm',
    clinicId: null,
    contractId: 'con_clm6rmjrg0002xlml1g171k28',
    returnalDays: 10,
    hasReturnalByCid: false,
    returnalWithSameProfessional: false,
    defaultSlots: 1,
    workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
    storedAt: null,
    createdAt: '2023-09-11T03:42:06.000Z',
    updatedAt: '2023-09-11T03:42:06.000Z',
    deletedAt: null,
  },
  {
    formatted: {
      id: 'con_ite_clmf1y8gb00032jml2jo782wy',
      externalCode: '50000705',
      externalName: 'CONSULTA ENFERMAGEM PRESENCIAL',
      price: 10100,
      clinicId: null,
      storedAt: null,
      itemId: 'ite_cl44iaubz000177zuaqb0dkpk',
      contractId: 'con_clm6rmjrg0002xlml1g171k28',
      returnalDays: 25,
      hasReturnalByCid: false,
      returnalWithSameProfessional: false,
      defaultSlots: 1,
      workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
    },
    id: 'con_ite_clmf1y8gb00032jml2jo782wy',
    externalCode: '50000705',
    externalName: 'CONSULTA ENFERMAGEM PRESENCIAL',
    price: 10100,
    itemId: 'ite_cl44iaubz000177zuaqb0dkpk',
    clinicId: null,
    contractId: 'con_clm6rmjrg0002xlml1g171k28',
    returnalDays: 25,
    hasReturnalByCid: false,
    returnalWithSameProfessional: false,
    defaultSlots: 1,
    workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
    storedAt: null,
    createdAt: '2023-09-11T03:42:06.000Z',
    updatedAt: '2023-09-11T03:42:06.000Z',
    deletedAt: null,
  },
  {
    formatted: {
      id: 'con_ite_clmf1y8gb00042jmlcyas2zo1',
      externalCode: '50000560',
      externalName: 'CONSULTA NUTRICIONISTA REMOTA',
      price: 10200,
      clinicId: null,
      storedAt: null,
      itemId: 'ite_cl44iaubz000477zubc6364mb',
      contractId: 'con_clm6rmjrg0002xlml1g171k28',
      returnalDays: 15,
      hasReturnalByCid: false,
      returnalWithSameProfessional: false,
      defaultSlots: 1,
      workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
    },
    id: 'con_ite_clmf1y8gb00042jmlcyas2zo1',
    externalCode: '50000560',
    externalName: 'CONSULTA NUTRICIONISTA REMOTA',
    price: 10200,
    itemId: 'ite_cl44iaubz000477zubc6364mb',
    clinicId: null,
    contractId: 'con_clm6rmjrg0002xlml1g171k28',
    returnalDays: 15,
    hasReturnalByCid: false,
    returnalWithSameProfessional: false,
    defaultSlots: 1,
    workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
    storedAt: null,
    createdAt: '2023-09-11T03:42:06.000Z',
    updatedAt: '2023-09-11T03:42:06.000Z',
    deletedAt: null,
  },
  {
    formatted: {
      id: 'con_ite_clmf1y8gb00052jml75bj2jzy',
      externalCode: '50000560',
      externalName: 'CONSULTA NUTRICIONISTA PRESENCIAL',
      price: 10300,
      clinicId: null,
      storedAt: null,
      itemId: 'ite_cl44iaubz000577zu3ry48vxl',
      contractId: 'con_clm6rmjrg0002xlml1g171k28',
      returnalDays: 5,
      hasReturnalByCid: false,
      returnalWithSameProfessional: false,
      defaultSlots: 1,
      workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
    },
    id: 'con_ite_clmf1y8gb00052jml75bj2jzy',
    externalCode: '50000560',
    externalName: 'CONSULTA NUTRICIONISTA PRESENCIAL',
    price: 10300,
    itemId: 'ite_cl44iaubz000577zu3ry48vxl',
    clinicId: null,
    contractId: 'con_clm6rmjrg0002xlml1g171k28',
    returnalDays: 5,
    hasReturnalByCid: false,
    returnalWithSameProfessional: false,
    defaultSlots: 1,
    workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
    storedAt: null,
    createdAt: '2023-09-11T03:42:06.000Z',
    updatedAt: '2023-09-11T03:42:06.000Z',
    deletedAt: null,
  },
  {
    formatted: {
      id: 'con_ite_clmf1y8gc00062jml83750ric',
      externalCode: '50000462',
      externalName: 'CONSULTA PSICOLOGIA REMOTA',
      price: 10400,
      clinicId: null,
      storedAt: null,
      itemId: 'ite_cl44iauc0000677zu03lxalcz',
      contractId: 'con_clm6rmjrg0002xlml1g171k28',
      returnalDays: 2,
      hasReturnalByCid: false,
      returnalWithSameProfessional: false,
      defaultSlots: 1,
      workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
    },
    id: 'con_ite_clmf1y8gc00062jml83750ric',
    externalCode: '50000462',
    externalName: 'CONSULTA PSICOLOGIA REMOTA',
    price: 10400,
    itemId: 'ite_cl44iauc0000677zu03lxalcz',
    clinicId: null,
    contractId: 'con_clm6rmjrg0002xlml1g171k28',
    returnalDays: 2,
    hasReturnalByCid: false,
    returnalWithSameProfessional: false,
    defaultSlots: 1,
    workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
    storedAt: null,
    createdAt: '2023-09-11T03:42:06.000Z',
    updatedAt: '2023-09-11T03:42:06.000Z',
    deletedAt: null,
  },
  {
    formatted: {
      id: 'con_ite_clmf1y8gc00072jml26q9dm69',
      externalCode: '50000462',
      externalName: 'CONSULTA PSICOLOGIA PRESENCIAL',
      price: 10500,
      clinicId: null,
      storedAt: null,
      itemId: 'ite_cl44iauc0000777zu10hm71d7',
      contractId: 'con_clm6rmjrg0002xlml1g171k28',
      returnalDays: 3,
      hasReturnalByCid: false,
      returnalWithSameProfessional: false,
      defaultSlots: 1,
      workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
    },
    id: 'con_ite_clmf1y8gc00072jml26q9dm69',
    externalCode: '50000462',
    externalName: 'CONSULTA PSICOLOGIA PRESENCIAL',
    price: 10500,
    itemId: 'ite_cl44iauc0000777zu10hm71d7',
    clinicId: null,
    contractId: 'con_clm6rmjrg0002xlml1g171k28',
    returnalDays: 3,
    hasReturnalByCid: false,
    returnalWithSameProfessional: false,
    defaultSlots: 1,
    workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
    storedAt: null,
    createdAt: '2023-09-11T03:42:06.000Z',
    updatedAt: '2023-09-11T03:42:06.000Z',
    deletedAt: null,
  },
  {
    formatted: {
      id: 'con_ite_clmf1y8gc00082jmlfytzf6v2',
      externalCode: '50000470',
      externalName: 'SESSÃO PSICOLOGIA REMOTA',
      price: 10600,
      clinicId: null,
      storedAt: null,
      itemId: 'ite_cl44iauc0000877zu1w6c264k',
      contractId: 'con_clm6rmjrg0002xlml1g171k28',
      returnalDays: 2,
      hasReturnalByCid: false,
      returnalWithSameProfessional: false,
      defaultSlots: 1,
      workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
    },
    id: 'con_ite_clmf1y8gc00082jmlfytzf6v2',
    externalCode: '50000470',
    externalName: 'SESSÃO PSICOLOGIA REMOTA',
    price: 10600,
    itemId: 'ite_cl44iauc0000877zu1w6c264k',
    clinicId: null,
    contractId: 'con_clm6rmjrg0002xlml1g171k28',
    returnalDays: 2,
    hasReturnalByCid: false,
    returnalWithSameProfessional: false,
    defaultSlots: 1,
    workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
    storedAt: null,
    createdAt: '2023-09-11T03:42:06.000Z',
    updatedAt: '2023-09-11T03:42:06.000Z',
    deletedAt: null,
  },
]
