import router from '@/router'
import {
  defaultTo,
  path,
  filter,
  isNil,
  clone,
  isEmpty,
} from 'ramda'
import { mapActions, mapGetters } from 'vuex'
import OpenEncountersListPage from '@/pages/OpenEncountersList'

export default {
  name: 'SiteMap',
  data() {
    return {
      siteMap: [],
    }
  },
  watch: {
    user: {
      handler() {
        this.createSiteMap()
      },
      immediate: true,
    },
    openEncounters: {
      handler() {
        this.createSiteMap()
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      user: 'authentication/user',
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
      openEncounters: 'emr/openEncounters',
    }),
  },
  activated() {
    this.handleGetOpenEncounters()
  },
  mounted() {
    this.handleGetOpenEncounters()
  },
  methods: {
    ...mapActions({
      getOpenEncounters: 'emr/getOpenEncounters',
    }),
    handleGetOpenEncounters() {
      if (this.userHasAccessToFunctionality.openEncounters) {
        this.getOpenEncounters()
      }
    },
    createSiteMap() {
      if (!this.user) return

      const clonedRoute = clone(router.options.routes)

      if (
        this.userHasAccessToFunctionality.openEncounters
        && this.openEncounters?.length
      ) {
        clonedRoute.unshift({
          path: '/open-encounters',
          redirect: '/',
          name: 'openEncounters',
          meta: { description: 'Prontuários Abertos' },
          children: [
            {
              path: '/open-encounters-list',
              name: 'openEncounters',
              component: OpenEncountersListPage,
              meta: {
                description: 'Consultas não finalizadas',
                badgeValue: this.openEncounters.length,
                isSiteMapVisible: true,
              },
            },
          ],
        })
      }

      const routes = filter(({ children }) => (
        !isNil(children) && !isEmpty(children)
      ), clonedRoute)

      for (const route of routes) {
        route.children = this.getVisibleChildren(route)
      }

      this.siteMap = filter(
        ({ children }) => !isNil(children) && !isEmpty(children),
        routes,
      )
    },
    getVisibleChildren({ children }) {
      return filter((child) => {
        const userHasAccess = this.userHasAccessToFunctionality[child.name]
        const isSiteMapVisible = defaultTo(true, path(['meta', 'isSiteMapVisible'], child))
        return userHasAccess && isSiteMapVisible
      }, children)
    },
  },
}
