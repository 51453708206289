<template>
  <div>
    <component-title />
    <care-cycle-list />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import { mapActions } from 'vuex'
import CareCycleList from './CareCycleList'

export default {
  name: 'CareCycleSettings',
  components: { ComponentTitle, CareCycleList },
  async mounted() {
    await this.listCareCycle()
  },
  methods: {
    ...mapActions({
      listCareCycle: 'careCycle/listCareCycle',
    }),
  },
}
</script>
