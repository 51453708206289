<template>
  <div>
    <component-title />
    <finances-container />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import FinancesContainer from './FinancesContainer'

export default {
  name: 'FinancesPage',
  components: {
    ComponentTitle,
    FinancesContainer,
  },
}
</script>
