<template>
  <div class="printable-area appear-only-in-print">
    <v-layout
      class="emr-print__container font-weight-medium"
    >
      <v-flex class="emr-print__header">
        <v-flex class="emr-print__header___title">
          {{ title }}
        </v-flex>
        <v-flex
          shrink
          class="emr-print__header___image"
        >
          <img src="@/assets/logo-amparo-black.png">
        </v-flex>
      </v-flex>
      <v-flex
        class="emr-print__doctor-info"
      >
        <v-flex
          column
          shrink
          class="font-weight-bold"
        >
          <v-flex>
            {{ doctorName }}
          </v-flex>
          <v-flex>
            {{ doctorDocument }}
          </v-flex>
        </v-flex>
      </v-flex>
      <v-flex
        column
        class="emr-print__patient-info"
      >
        <v-flex>
          Paciente {{ patientName }}
        </v-flex>
        <v-flex>
          {{ patientAge }} ano{{ patientAge > 1 ? 's' : '' }}
        </v-flex>
      </v-flex>
      <v-flex class="emr-print__main-data">
        <slot />
      </v-flex>
      <v-flex
        class="emr-print__signature-container"
      >
        <v-flex shrink>
          ____ /____ /_____
        </v-flex>
        <v-flex
          column
          shrink
          class="emr-print__signature font-weight-bold text--small"
        >
          <v-flex>
            {{ doctorName }}
          </v-flex>
          <v-flex>
            {{ doctorDocument }}
          </v-flex>
        </v-flex>
      </v-flex>
      <v-flex
        class="emr-print__footer text--small"
      >
        <v-flex shrink>
          Amparo Saúde - Unidade {{ clinicName }}
        </v-flex>
        <v-flex shrink>
          {{ clinicCity }}
        </v-flex>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: [
    'title',
    'doctorName',
    'doctorDocument',
    'patientName',
    'patientAge',
    'clinicName',
    'clinicCity',
  ],
}
</script>
<style lang="stylus" scoped>

alignAndJustifyItems()
  align-items center
  justify-content space-between

paddingX(value = 1.5cm)
  padding-left value !important
  padding-right value !important

dividerOn(side)
  border-{side} 1px solid #777

@page
  size A4
  margin 0

.emr-print__container
  height 100vh
  display grid
  grid-template-areas 'header'
  'doctorInfo'
  'patientInfo'
  'mainData'
  'signature'
  'footer'
  grid-template-columns 100vw
  grid-template-rows 2cm 3cm 2cm 1fr 3cm 1.5cm
  margin 0 !important
  padding 0 !important
  font-size .9rem

.text--small
  font-size .8em

.emr-print__header
  grid-area 'header'
  alignAndJustifyItems()
  paddingX()
  dividerOn('bottom')
  margin-top -1cm

.emr-print__header___title
  font-size 1.4rem

.emr-print__header___image
  width 3.2cm
  & img
    width 100%

.emr-print__doctor-info
  grid-area 'doctorInfo'
  alignAndJustifyItems()
  paddingX()
  dividerOn('bottom')

.emr-print__patient-info
  grid-area 'patientInfo'
  paddingX()
  margin-top 1cm

.emr-print__main-data
  grid-area 'mainData'
  paddingX()
  margin 1cm 0

.emr-print__signature-container
  grid-area 'signature'
  alignAndJustifyItems()
  paddingX()

.emr-print__signature
  padding-top .5cm
  paddingX(.7cm)
  border-top 1px solid #000

.emr-print__footer
  grid-area 'footer'
  alignAndJustifyItems()
  paddingX()
  dividerOn('top')

</style>
