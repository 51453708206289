<template>
  <v-flex justify-end>
    <v-btn
      text
      small
      color="amparo-medium-green"
      class="attach__button"
      @click="openAttachmentDialog"
    >
      <v-icon
        class="attach__button-icon"
        left
      >
        mdi-paperclip
      </v-icon>
      <span>Anexar arquivos</span>
    </v-btn>
    <attachment-dialog
      :show-dialog="showAttachmentDialog"
      :patient-id="patient.id"
      :type="type"
      @update:showDialog="setShowAttachmentDialog()"
      @update:attachmentDone="handleAttachmentDone()"
      @newAttachment="handleNewAttachment"
    />
  </v-flex>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {
    AttachmentDialog: () => import('@/components/Common/AttachmentDialog'),
  },
  props: {
    patient: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showAttachmentDialog: false,
    }
  },
  methods: {
    ...mapActions({
      getPatientResume: 'emr/getPatientResume',
    }),
    openAttachmentDialog() {
      this.setShowAttachmentDialog()
    },
    setShowAttachmentDialog() {
      this.showAttachmentDialog = !this.showAttachmentDialog
    },
    handleAttachmentDone() {
      this.getPatientResume(this.patient.id)
    },
    handleNewAttachment(data) {
      this.$emit('newAttachment', data)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.attach__button
  & span
    text-decoration underline

.attach__button-icon
  transform rotate(45deg)

</style>
