<template>
  <div class="ma-10 container_site-map">
    <v-card
      v-for="(fatherComponent, index) in siteMap"
      :key="index"
      class="card mb-5"
      style="break-inside: avoid-column"
    >
      <v-card-title class="card-title a-link font-weight-bold pt-6">
        {{ fatherComponent.meta.description }}
      </v-card-title>
      <v-card-text>
        <router-link
          v-for="(childComponent, ind) in fatherComponent.children"
          :key="ind"
          :to="childComponent.path"
          class="d-flex justify-space-between a-link mb-8"
        >
          <div class="d-flex">
            <v-badge
              v-if="childComponent.meta.badgeValue"
              class="badge"
              :content="childComponent.meta.badgeValue"
              inline
            />
            <v-icon
              v-else
              color="primary"
            >
              {{ childComponent.meta.icon || 'mdi-circle-medium' }}
            </v-icon>
            <div class="card-content font-weight-bold ml-4">
              {{ childComponent.meta.description }}
            </div>
          </div>
          <v-icon>mdi-chevron-right</v-icon>
        </router-link>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import siteMap from '@/mixins/siteMap'

export default {
  name: 'SiteMap',
  mixins: [siteMap],
}
</script>

<style lang="stylus">
@import '../../style/_core/colors.styl'

.card-title
  font-size: 24px
  color: #4e5559
  margin-bottom: 32px !important
  padding-bottom: 0px !important
  @media only screen and (min-width 600px)
    margin-bottom: 30px

.card
  box-shadow: 0px 2px 2px rgba(104, 105, 122, 0.1), 0px 4px 16px rgba(104, 105, 122, 0.2) !important
  border-radius: 8px !important
  background: white
  break-inside: avoid-column
  overflow: hidden

.container_site-map
  margin: 0 auto
  column-count: 4

.badge > .v-badge__wrapper > .v-badge__badge
  background-color error-color !important
</style>
