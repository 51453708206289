<template>
  <div>
    <v-menu
      offset-y
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          :class="classes"
          fab
          dark
          text
          justify-end
          v-on="on"
        >
          <v-icon :color="buttonColor">
            mdi-printer
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-if="!isEncounterOpen"
          :disabled="disableMedicalButton"
          @click="printSheetEncounter()"
        >
          <v-list-item-title>Consulta</v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="!hasPrescriptionOfType(prescriptionCategories.medication)"
          @click="printPrescription(prescriptionCategories.medication)"
        >
          <v-list-item-title>Receita</v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="!hasPrescriptionOfType(prescriptionCategories.report)"
          @click="printPrescription(prescriptionCategories.report)"
        >
          <v-list-item-title>Laudo</v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="!hasPrescriptionOfType(prescriptionCategories.referral)"
          @click="printPrescription(prescriptionCategories.referral)"
        >
          <v-list-item-title>Encaminhamento</v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="!hasPrescriptionOfType(prescriptionCategories.exam)"
          @click="printPrescription(prescriptionCategories.exam)"
        >
          <v-list-item-title>Pedidos de Exames</v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="!hasPrescriptionOfType(prescriptionCategories.certificate)"
          @click="printPrescription(prescriptionCategories.certificate)"
        >
          <v-list-item-title>Atestado</v-list-item-title>
        </v-list-item>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item class="list-group-item__title">
              <v-list-item-title>Curva de crescimento</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item
            @click="openChartDialog({
              title: 'curva de crescimento',
              metric: 'height0To2',
              yTitle: 'Altura (cm)',
              types: ['zScore', 'percentil'],
              examId: 'exa_ck1jepl560001ttpqdbc7azyj',
              minAge: 0,
              maxAge: 2,
            })"
          >
            <v-list-item-title>0 à 2 anos</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="openChartDialog({
              title: 'curva de crescimento',
              metric: 'height2To5',
              yTitle: 'Altura (cm)',
              types: ['zScore', 'percentil'],
              examId: 'exa_ck1jepl560001ttpqdbc7azyj',
              minAge: 2,
              maxAge: 5,
            })"
          >
            <v-list-item-title>2 à 5 anos</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="openChartDialog({
              title: 'curva de crescimento',
              metric: 'height5To19',
              yTitle: 'Altura (cm)',
              types: ['zScore', 'percentil'],
              examId: 'exa_ck1jepl560001ttpqdbc7azyj',
              minAge: 5,
              maxAge: 19,
            })"
          >
            <v-list-item-title>5 à 19 anos</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item class="list-group-item__title">
              <v-list-item-title>Curva de peso</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item
            @click="openChartDialog({
              title: 'curva de peso',
              metric: 'weight0To2',
              yTitle: 'Peso (kg)',
              types: ['zScore', 'percentil'],
              examId: 'exa_ck1jepl540000ttpq364x54mr',
              minAge: 0,
              maxAge: 2,
            })"
          >
            <v-list-item-title>0 à 2 anos</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="openChartDialog({
              title: 'curva de peso',
              metric: 'weight2To5',
              yTitle: 'Peso (kg)',
              types: ['zScore', 'percentil'],
              examId: 'exa_ck1jepl540000ttpq364x54mr',
              minAge: 2,
              maxAge: 5,
            })"
          >
            <v-list-item-title>2 à 5 anos</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="openChartDialog({
              title: 'curva de peso',
              metric: 'weight5To10',
              yTitle: 'Peso (kg)',
              types: ['zScore', 'percentil'],
              examId: 'exa_ck1jepl540000ttpq364x54mr',
              minAge: 5,
              maxAge: 10,
            })"
          >
            <v-list-item-title>5 à 10 anos</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item
          @click="openChartDialog({
            title: 'perímetro cefálico',
            metric: 'cephalicPerimeter',
            yTitle: 'Circunferência da cabeça(cm)',
            types: ['zScore', 'percentil'],
            examId: 'exa_ck48ogbn100009f3a8vwk37zd',
            minAge: 0,
            maxAge: 2,
          })"
        >
          <v-list-item-title>Perímetro cefálico</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <div
      v-if="doctor && clinic"
      :id="emrData.id"
    >
      <emr-print-sheet
        v-for="(printItem, index) in printList"
        :key="index"
        :title="sheetTitle"
        :doctor-name="doctor.name"
        :doctor-document="`${doctor.documentType} ${doctor.documentNumber}`"
        :patient-name="patient | formatDisplayName"
        :patient-age="patientAge"
        :clinic-name="clinic.name"
        :clinic-city="clinic.address.city"
      >
        <v-layout
          :key="reloadContentToPrint"
          column
        >
          <v-component
            :is="componentToPrint"
            :emr="emrData"
            :encounter="emrData.data"
            :encounter-data="emrData.data"
            :encounter-id="emrData.id"
            :column="true"
            :referral="printItem"
            :exam="[printItem]"
            :doctor-name="doctor.name"
            :date="emrData.date"
            :patient="patient"
            :exams="emrData.data.exams"
            :chart-config="chart"
            :uri-list="chartsURI"
            :message="medicalCertificateMessage"
          />
        </v-layout>
      </emr-print-sheet>
    </div>
    <v-dialog
      v-model="chartDialog"
      max-width="1100"
    >
      <v-card class="card-dialog__overflow--disabled pb-12">
        <v-card-title class="card-dialog__title align-start">
          <v-flex class="pl-6 pt-1">
            Gráfico de {{ chart.title }}
          </v-flex>
          <v-spacer />
          <v-flex
            shrink
            column
            align-center
          >
            <v-btn
              icon
              @click="chartDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn
              icon
              :disabled="!chartsURI.length"
              @click="printSheet(`Gráfico ${chart.title}`, 'PrintWhoChart')"
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </v-flex>
        </v-card-title>
        <v-card-text
          v-if="patient && chartDialog"
          class="d-flex column align-center"
        >
          <loader-who-chart
            :patient="patient"
            :exams="emrData.data.exams"
            :chart-config="chart"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="groupExamDialog"
      max-width="800"
    >
      <v-card
        v-if="groupExamDialog"
      >
        <v-card-title class="card-dialog__title">
          Grupo de exames
          <v-spacer />
          <v-btn
            icon
            @click="groupExamDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-flex
            v-if="
              examRequests
                && examRequests.length > 0
            "
            column
          >
            <v-flex
              v-for="(exam, index) in examRequests"
              :key="(index)"
              justify-space-between
              align-center
              class="card-dialog__exam___container"
            >
              <v-flex shrink>
                {{ exam.name }}
              </v-flex>
              <v-flex shrink>
                <v-select
                  v-model="selectedPages[index]"
                  :items="pageItems"
                  filled
                  hide-details
                  label="Grupo de impressão"
                  item-text="title"
                  item-value="value"
                  class="card-dialog__exam___select"
                  @change="handlePageToExam(exam, index)"
                />
              </v-flex>
            </v-flex>
            <v-flex justify-center>
              <v-btn
                large
                class="btn btn-primary"
                @click="printExamsGroups"
              >
                Imprimir
              </v-btn>
            </v-flex>
          </v-flex>
          <v-flex v-else>
            Não há exames inseridos.
          </v-flex>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import print from '@/utils/print'
import moment from 'moment'
import {
  addIndex,
  equals,
  path,
  forEach,
  groupBy,
  has,
  identity,
  inc,
  isEmpty,
  isNil,
  length,
  map,
  not,
  prop,
  propEq,
  reject,
  times,
  values,
  filter,
  any,
  pipe,
  cond,
  always,
  T,
} from 'ramda'
import isNilOrEmpty from '@/utils/dataValidators'
import EmrImmediateCareResume from '@/pages/Emr/EmrHistory/EmrImmediateCareResume'
import EmrAnnotationResume from '@/pages/Emr/EmrHistory/EmrAnnotationResume'
import EmrEncounterResume from '@/pages/Emr/EmrHistory/EmrEncounterResume'
import { mapActions, mapGetters } from 'vuex'
import EmrEncounterResumePrint from './EmrEncounterResumePrint'
import EmrPrintSheet from './EmrPrintSheet'
import EmrMedicalReferral from './EmrMedicalReferral'
import EmrPrintMedicalCertificate from './EmrPrintMedicalCertificate'
import PrintEmrExams from './PrintEmrExams'
import LoaderWhoChart from './LoaderWhoChart'
import WhoChart from './WhoChart'
import PrintWhoChart from './PrintWhoChart'
import PrintEmrExamsGroups from './PrintEmrExamsGroups'

export default {
  components: {
    EmrAnnotationResume,
    EmrEncounterResume,
    EmrEncounterResumePrint,
    EmrImmediateCareResume,
    EmrMedicalReferral,
    EmrPrintMedicalCertificate,
    EmrPrintSheet,
    LoaderWhoChart,
    PrintEmrExams,
    PrintEmrExamsGroups,
    PrintWhoChart,
    WhoChart,
  },
  props: [
    'emrData',
    'buttonColor',
    'classes',
  ],
  data() {
    return {
      chart: {},
      chartDialog: false,
      sheetTitle: '',
      componentToPrint: null,
      startTimeCertificate: null,
      endTimeCertificate: null,
      recomendationCertificate: null,
      reloadContentToPrint: 1,
      medicalCertificateMessage: null,
      printList: [],
      groupExamDialog: false,
      pageItems: [],
      selectedPages: [],
      examsToPrint: [],
    }
  },
  computed: {
    ...mapGetters({
      chartsURI: 'emr/chartsURI',
      encounter: 'emr/encounter',
      medicalReferralId: 'emr/medicalReferralId',
      requestExamsId: 'emr/requestExamsId',
      questions: 'emr/questions',
      prescriptionCategories: 'emr/prescriptionCategories',
    }),
    prescriptions() {
      const allPrescriptions = path(['data', 'prescriptionsManagement'], this.emrData)
      if (isNilOrEmpty(allPrescriptions)) return null

      return filter(propEq('done', 'state'))(allPrescriptions)
    },
    examRequestsId() {
      return this.questions.examRequests
    },
    examRequests() {
      const examRequestQuestion = this.emrData.data[this.examRequestsId]
      return has('value', examRequestQuestion)
        ? examRequestQuestion.value
        : examRequestQuestion
    },
    isEncounterOpen() {
      return equals('open', this.emrData.status)
    },
    disableMedicalButton() {
      return equals('ANNOTATION', this.encounter.encounterFormName)
    },
    patientAge() {
      return moment().diff(this.patient.birthDate, 'years')
    },
    doctor() {
      return this.emrData.professional
    },
    clinic() {
      return this.emrData.clinic
    },
    patient() {
      return this.emrData.patient
    },
  },
  methods: {
    ...mapActions({
      resetChartsURI: 'emr/resetChartsURI',
      getPrescriptionToDownload: 'emr/getPrescriptionToDownload',
    }),
    printExamsGroups() {
      const pagesWithExams = values(groupBy(prop('page'))(reject(
        propEq(0, 'page'),
        this.examsToPrint,
      )))
      this.printSheetList({
        list: pagesWithExams,
        title: 'Exames',
        component: 'PrintEmrExamsGroups',
      })
    },
    handlePageToExam(exam, index) {
      this.examsToPrint[index] = {
        page: this.selectedPages[index],
        exam,
      }
    },
    startExamsPrintGroups() {
      this.groupExamDialog = true

      if (
        isNil(this.examRequests)
        || isEmpty(this.examRequests)
      ) return

      this.pageItems = map(
        item => ({
          title: equals(item, 0) ? 'Não imprimir' : `Página ${item}`,
          value: item,
        }),
        times(identity, inc(length(this.examRequests))),
      )
      this.selectedPages = map(
        () => this.pageItems[1],
        this.examRequests,
      )
      const mapIndexed = addIndex(map)
      this.examsToPrint = mapIndexed(
        (page, index) => ({
          page: page.value,
          exam: this.examRequests[index],
        }),
        this.selectedPages,
      )
    },
    printSheetList({
      itemId,
      title,
      component,
      list,
    }) {
      this.printList = list || this.emrData.data[itemId]
      if (has('value', this.printList)) {
        this.printList = this.printList.value
      }
      this.printSheet(title, component, true)
    },
    openChartDialog({
      title,
      metric,
      yTitle,
      types,
      examId,
      minAge,
      maxAge,
    }) {
      this.resetChartsURI()
      this.chart = {
        title,
        metric,
        yTitle,
        types,
        examId,
        minAge,
        maxAge,
      }
      this.chartDialog = true
    },
    printSheetEncounter() {
      const title = cond([
        [equals('ANNOTATION'), always('Anotação')],
        [equals('IMMEDIATE_CARE'), always('Atendimento Imediato')],
        [T, always('Consulta')],
      ])(this.emrData.encounterFormName)

      this.printSheet(title, EmrEncounterResumePrint)
    },
    printSheet(title, component, isList) {
      if (!isList) this.printList = ['']
      this.reloadContentToPrint += 12
      this.componentToPrint = component
      this.sheetTitle = title
      this.$nextTick(() => {
        print(this.emrData.id, this.sheetTitle)
      })
      this.medicalCertificateDialog = false
    },
    hasPrescriptionOfType(category) {
      return not(isNilOrEmpty(this.getPrescriptionsOfType(category)))
    },
    getPrescriptionsOfType(category) {
      if (isNilOrEmpty(this.prescriptions)) return null
      return filter(
        pipe(
          path(['rawData', 'Documentos']),
          any(propEq(category, 'Categoria')),
        ),
      )(this.prescriptions)
    },
    async printPrescription(category) {
      if (not(this.hasPrescriptionOfType(category))) return

      const prescriptions = this.getPrescriptionsOfType(category)

      forEach(async (prescription) => {
        const params = {
          encounterId: this.emrData.id,
          prescriptionId: prescription.id,
          fileData: {
            date: this.emrData.date,
            category,
          },
        }
        const fileUrl = await this.getPrescriptionToDownload(params)
        window.open(fileUrl, '_blank')
      })(prescriptions)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../../style/_core/colors.styl'

.card-dialog__content
  background-color $frostee !important
  padding 15px
  line-height 3rem
  color dark-gray

.card-dialog__field-time
  width 120px

.card-dialog__overflow--disabled
  overflow-x hidden

.card-dialog__chart-container
  width 90%

.card-dialog__exam___container
  background-color $frostee !important
  margin-top 10px
  margin-bottom @margin-top
  height 5.5rem
  padding-left 10px
  padding-right @padding-left

.card-dialog__exam___select
  width 250px

.v-list-group
  padding: 0
  .v-list-item:not(.list-group-item__title)
    padding-left 32px

.list-group-item__title
  padding-left 0
</style>
