<template>
  <div>
    <component-title
      new-item
    />
    <item-list-container />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import ItemListContainer from './ItemListContainer'

export default {
  components: { ComponentTitle, ItemListContainer },
}
</script>

<style>
</style>
