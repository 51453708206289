import axios from '@/lib/axios'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'

const initialState = {
  loadingPermission: false,
  permissions: [],
  loadingModules: false,
  modules: [],
}

const mutations = {
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload
  },
  SET_LOADING_PERMISSION(state, payload) {
    state.loadingPermission = payload
  },
  SET_MODULES(state, payload) {
    state.modules = payload
  },
  SET_LOADING_MODULES(state, payload) {
    state.loadingModules = payload
  },
}

const actions = {
  createPermission({ commit }, attributes) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_PERMISSION', true)
      axios.post('permission', attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_PERMISSION', false))
    })
  },
  updatePermission({ commit }, { id, attributes }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_PERMISSION', true)
      axios.put(`permission/${id}`, attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_PERMISSION', false))
    })
  },
  listPermission({ commit }, filters = {}) {
    const params = removeNilOrEmpty(filters)
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_PERMISSION', true)
      axios.get('permission', { params })
        .then(({ data: { permissions } }) => {
          commit('SET_PERMISSIONS', permissions)
          resolve(permissions)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING_PERMISSION', false))
    })
  },
  listModules({ commit }, filters = {}) {
    const params = removeNilOrEmpty(filters)
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_MODULES', true)
      axios.get('permission/module', { params })
        .then(({ data: { modules } }) => {
          commit('SET_MODULES', modules)
          resolve(modules)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING_MODULES', false))
    })
  },
  deletePermission({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_PERMISSION', true)
      axios.delete(`permission/${id}`)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_PERMISSION', false))
    })
  },
  restorePermission({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_PERMISSION', true)
      axios.patch(`permission/restore/${id}`)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_PERMISSION', false))
    })
  },
  migratePermission({ commit }, { oldPermissionId, newPermissionId }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_PERMISSION', true)
      axios.patch('permission/migrate', { oldPermissionId, newPermissionId })
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_PERMISSION', false))
    })
  },
}

const getters = {
  permissions: state => state.permissions,
  loadingPermission: state => state.loadingPermission,
  modules: state => state.modules,
  loadingModules: state => state.loadingModules,
}

export default {
  state: initialState,
  mutations,
  actions,
  getters,
}
