<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div>
    <div
      v-if="encounterData"
      class="mt-6 mx-2"
    >
      <v-flex
        v-if="shouldShowSpecialityName || shouldShowPrintButton"
        class="mb-6 mt-n4 justify-space-between"
        align-center
      >
        <v-chip
          v-if="shouldShowSpecialityName"
          color="japaneseLaurel"
          outlined
          disabled
        >
          {{ emr.speciality.name }}
        </v-chip>
        <emr-print-button
          v-if="shouldShowPrintButton"
          :emr-data="emr"
          button-color="dark-green"
          classes="justify-end"
        />
      </v-flex>
      <v-row
        v-for="(rowItems, rowIndex) of rows"
        :key="rowIndex"
        no-gutters
      >
        <v-col
          v-for="(item, colIndex) of rowItems"
          :key="colIndex"
          :cols="getColumns(item)"
          :class="{
            'mb-4': !isLastItem(rowItems, colIndex),
            'pr-4': true
          }"
        >
          <div>
            <v-flex
              class="card__group-title"
              :class="getDynamicOptionsClass('titleFontWeight', item)"
            >
              <span>
                {{ item.label }}
                <v-icon
                  v-if="canOpenResumeModal && isScoreQuestion(item.id)"
                  class="amparo-light-green--text ml-1 mb-1"
                  size="18"
                  @click="openScoreInfoDialog(item)"
                >
                  mdi-information-outline
                </v-icon>
              </span>
            </v-flex>
            <div
              v-if="!shouldShowSectionName"
              :class="{
                'row no-gutters': getProp(item, 'rowAnswers'),
                'card__answer-container': isPrintMode
              }"
            >
              <item-builder
                v-if="item.component"
                :component-name="item.component"
                :data="getQuestionAnswers(item)"
              />
              <v-flex
                v-for="(answer, answerIndex) of getQuestionAnswers(item)"
                v-else
                :key="answerIndex"
                class="card__infos-name font-weight-medium"
                :class="getDynamicOptionsClass('answerFontWeight', answer)"
              >
                <v-flex column>
                  <v-flex
                    class="card__answer align-center"
                    :class="{'text--disabled': getProp(answer, 'deletedAt')}"
                  >
                    <span
                      v-if="getProp(answer, 'label') && !getProp(answer, 'name')"
                      class="font-weight-bold"
                    >
                      {{ answer.label }}:&nbsp;
                    </span>
                    <span>
                      <span v-html="getFormattedAnswer(answer)" />
                      <v-chip
                        v-if="shouldShowChip(answer)"
                        class="ml-1"
                        label
                        x-small
                        disabled
                        :color="getChipColor(answer)"
                        :text-color="getChipTextColor(answer)"
                      >
                        {{ getChipText(answer) }}
                      </v-chip>
                    </span>
                  </v-flex>
                  <v-flex
                    v-if="getProp(answer, 'observation')"
                    class="card__infos-observation font-weight-medium"
                    :class="{'text--disabled': getProp(answer, 'deletedAt')}"
                  >
                    - {{ answer.observation }}
                  </v-flex>
                </v-flex>
              </v-flex>
            </div>
            <div v-else>
              <v-flex
                v-for="(question, questionIndex) of item.questions"
                :key="questionIndex"
                :class="{ 'd-inline': !question.id }"
                style="margin-bottom: 1rem"
                column
              >
                <v-flex
                  v-if="hasLabel(question, 'label')"
                  class="card__infos-sub-title"
                  :class="getDynamicOptionsClass('titleFontWeight', item)"
                >
                  <span>
                    {{ question.label }}
                    <v-icon
                      v-if="canOpenResumeModal && isScoreQuestion(question.id)"
                      class="amparo-light-green--text ml-1 mb-1"
                      size="18"
                      @click="openScoreInfoDialog(question)"
                    >
                      mdi-information-outline
                    </v-icon>
                  </span>
                </v-flex>
                <v-flex
                  v-for="(answer, answerIndex) of getQuestionAnswers(question)"
                  :key="answerIndex"
                  class="card__infos-name"
                  :class="getDynamicOptionsClass('answerFontWeight', answer)"
                >
                  <v-flex column>
                    <v-flex
                      class="card__answer align-center"
                      :class="{'text--disabled': getProp(answer, 'deletedAt')}"
                    >
                      <span>
                        <span v-html="getFormattedAnswer(answer)" />
                        <v-chip
                          v-if="shouldShowChip(answer)"
                          class="ml-1"
                          label
                          x-small
                          disabled
                          :color="getChipColor(answer)"
                          :text-color="getChipTextColor(answer)"
                        >
                          {{ getChipText(answer) }}
                        </v-chip>
                      </span>
                    </v-flex>
                    <v-flex
                      v-if="getProp(answer, 'observation')"
                      class="card__answer card__infos-observation font-weight-medium"
                      :class="{'text--disabled': getProp(answer, 'deletedAt')}"
                    >
                      <span>- {{ answer.observation }}</span>
                    </v-flex>
                  </v-flex>
                </v-flex>
              </v-flex>
            </div>
          </div>
        </v-col>
        <v-col
          v-if="shouldDisplayDivider(rowIndex)"
          cols="12"
        >
          <v-divider class="my-6" />
        </v-col>
      </v-row>
    </div>
    <div
      v-if="shouldShowExams"
      class="mx-2"
    >
      <v-row no-gutters>
        <v-col
          v-if="!isPrintMode"
          cols="12"
          class="py-0"
        >
          <v-divider class="divider__spacement" />
        </v-col>
        <v-col :cols="12">
          <v-flex
            class="card__group-title"
            column
          >
            Exames Físicos
          </v-flex>
        </v-col>
        <v-col
          v-for="(section, sectionIndex) of examsStruct.sections"
          v-if="hasExamInSection(section)"
          :key="sectionIndex"
          :cols="3"
          class="card__infos-name no-margin-y no-padding-y"
        >
          <v-flex
            v-if="section.name"
            class="pb-2"
            :class="getDynamicOptionsClass('fontWeight', section)"
          >
            {{ section.name }}
          </v-flex>
          <v-col
            v-for="(examGroup, examGroupIndex) of section.examGroups"
            v-if="hasExamsInGroup(examGroup)"
            :key="examGroupIndex"
            :cols="12"
            class="exam-group__spacement"
            :class="getDynamicOptionsClass('fontWeight', examGroup)"
          >
            <v-flex
              v-if="examGroup.name"
              style="margin-bottom: 0.5rem"
            >
              {{ examGroup.name }}:
            </v-flex>
            <v-col
              v-for="(exam, examIndex) of examGroup.exams"
              :key="examIndex"
              :cols="12"
              class="no-padding"
              :class="getDynamicOptionsClass('fontWeight', exam)"
            >
              <div
                v-if="shouldShowExamData(examGroup, exam, examIndex)"
                style="margin-bottom: 0.5rem"
              >
                {{ getExamDataText(exam) }}
                <span v-if="shouldShowNextExamData(examGroup, exam, examIndex)">
                  &nbsp;|
                  {{ getNextExamData('value', examGroup, examIndex) }}
                  {{ getNextExamData('measure', examGroup, examIndex) }}
                </span>
              </div>
            </v-col>
          </v-col>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="shouldShowSuggestedExams"
      class="mx-2"
    >
      <v-row no-gutters>
        <v-col
          v-if="!isPrintMode"
          cols="12"
          class="no-padding"
        >
          <v-divider class="divider__spacement" />
        </v-col>
        <v-col
          cols="12"
          class="no-padding"
        >
          <v-flex
            class="card__group-title"
            column
          >
            Exames Linha de Cuidado
          </v-flex>
        </v-col>
        <div
          :style="{ 'column-count': isPrintMode ? 3 : 4 }"
        >
          <v-col
            v-for="({ exam, examGroup }, index) of suggestedExamsData"
            :key="index"
            class="card__infos-name suggested-exam-group__spacement"
          >
            <v-flex
              v-if="exam"
              style="break-before: avoid"
            >
              <v-col
                cols="auto"
                class="no-padding font-weight-regular text-capitalize"
              >
                {{ getExamDataText(exam) }}
              </v-col>
            </v-flex>
            <v-col
              v-else-if="examGroup"
              class="font-weight-lightbold pa-0"
              cols="auto"
              style="break-inside: avoid-column"
            >
              {{ examGroup.name }}
              <v-col
                v-for="(examGroupExam, examIndex) of examGroup.exams"
                :key="examIndex"
                :cols="12"
                class="no-padding pb-1 font-weight-regular text-capitalize"
              >
                {{ getExamDataText(examGroupExam) }}
              </v-col>
            </v-col>
          </v-col>
        </div>
      </v-row>
    </div>
    <v-row
      v-if="canShowAllFields && !isPrintMode"
      no-gutters
      justify="end"
    >
      <v-btn
        text
        class="font-weight-bold"
        color="primary"
        @click="showAllFields()"
      >
        {{ showAllFieldsButtonText }}
        <v-icon>{{ showAllFieldsButtonIcon }}</v-icon>
      </v-btn>
    </v-row>
    <emr-encounter-resume-modal
      :show-dialog="showEncounterResumeDialog"
      :emr="emr"
      @update:showDialog="setShowEncounterResumeDialog"
    />
    <score-dialog
      v-if="canOpenResumeModal"
      :question="scoreDialogQuestion"
      :show-dialog="showScoreInfoDialog"
      @update:showDialog="handleShowDialogUpdate"
    />
  </div>
</template>

<script>
import {
  any,
  compose,
  equals,
  filter,
  forEach,
  has,
  hasPath,
  inc,
  isEmpty,
  includes,
  isNil,
  last,
  length,
  map,
  path,
  pipe,
  prop,
  sortBy,
} from 'ramda'
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter'
import { mapGetters } from 'vuex'
import isNilOrEmpty from '@/utils/dataValidators'
import { EMR_QUESTIONS } from 'amparo-enums'
import appearConditions from '@/appearConditions'

const { questions: emrQuestions } = EMR_QUESTIONS

export default {
  name: 'EmrEncounterResume',
  components: {
    EmrEncounterResumeModal: () => import('@/pages/Emr/EmrHistory/EmrEncounterResumeModal'),
    EmrPrintButton: () => import('@/pages/Emr/EmrPrintButton'),
    ScoreDialog: () => import('@/components/Common/ScoreDialog'),
    ItemBuilder: () => import('./ItemBuilder'),
  },
  props: {
    emr: {
      type: Object,
      required: true,
    },
    itemsPerRow: {
      type: Number,
      required: false,
      default: 2,
    },
    shouldShowEmptyAsnwers: {
      type: Boolean,
      required: false,
      default: false,
    },
    shouldShowMinimizedQuestions: {
      type: Boolean,
      required: false,
      default: false,
    },
    shouldShowSectionName: {
      type: Boolean,
      required: false,
      default: false,
    },
    shouldShowSpecialityName: {
      type: Boolean,
      required: false,
      default: false,
    },
    shouldShowPrintButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    canOpenResumeModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    canShowAllFields: {
      type: Boolean,
      required: false,
      default: true,
    },
    isPrintMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      rawResumeStruct: null,
      resumeStruct: null,
      resumeFields: null,
      examsStruct: null,
      showMinimizedQuestions: false,
      showEncounterResumeDialog: false,
      showScoreInfoDialog: false,
      scoreDialogQuestion: {},
    }
  },
  computed: {
    ...mapGetters({
      notFoundMessage: 'emr/NotFoundMessage',
      questions: 'emr/questions',
      patient: 'emr/patient',
    }),
    suggestedExamsData() {
      const suggestedExams = path(['emr', 'suggestedExams'], this)
      if (isNil(suggestedExams)) return []
      const examsData = map(
        (suggestedExam) => {
          const { examGroup, exam } = suggestedExam

          if (this.shouldShowExamData(null, exam)) return suggestedExam

          const showableExamsInGroup = filter(
            e => this.shouldShowExamData(null, e),
          )(path(['exams'], examGroup) || [])

          if (isEmpty(showableExamsInGroup)) return null

          return {
            ...suggestedExam,
            examGroup: {
              ...examGroup,
              exams: sortBy(prop('name'), showableExamsInGroup),
            },
          }
        },
        suggestedExams,
      )

      return compose(
        filter(exam => !isNil(exam)),
        sortBy(pipe(prop('exam'), isNil)),
        sortBy(pipe(path(['exam', 'name']))),
      )(examsData)
    },
    encounterData() {
      return path(['data'], this.emr)
    },
    encounterFormName() {
      return path(['encounterFormName'], this.emr)
    },
    showAllFieldsButtonText() {
      return !this.showMinimizedQuestions || this.canOpenResumeModal
        ? 'Ver todos os campos'
        : 'Minimizar campos'
    },
    showAllFieldsButtonIcon() {
      if (this.canOpenResumeModal) return 'mdi-chevron-right'
      return !this.showMinimizedQuestions
        ? 'mdi-chevron-down'
        : 'mdi-chevron-up'
    },
    rows() {
      if (isNil(this.resumeFields) || isEmpty(this.resumeFields)) return []

      const rows = [[]]
      let totalRowColumns = 0

      forEach((field) => {
        totalRowColumns += this.getColumns(field)

        if (totalRowColumns > 12) {
          rows.push([])
          totalRowColumns = 0
        }

        last(rows).push(field)
      })(this.resumeFields)

      return rows
    },
    shouldShowExams() {
      const canShowExams = this.showMinimizedQuestions || path(['minimized'], this.examsStruct) === false
      const hasExams = !isNilOrEmpty(this.encounterData.exams) && !isNilOrEmpty(this.examsStruct)

      if (!hasExams) return false

      const filteredSections = filter(this.hasExamInSection, this.examsStruct.sections)
      const hasExamsData = any(
        section => any(this.hasExamsInGroup, section.examGroups),
        filteredSections,
      )

      return hasExamsData && canShowExams
    },
    shouldShowSuggestedExams() {
      return this.showMinimizedQuestions && !isEmpty(this.suggestedExamsData)
    },
  },
  created() {
    this.showMinimizedQuestions = this.shouldShowMinimizedQuestions
    this.setInitialResumeStruct()
    this.setResumeFields(this.showMinimizedQuestions)
  },
  methods: {
    hasLabel(question) {
      return has('label', question)
    },
    isLastItem(array, index) {
      return equals(length(array), inc(index))
    },
    openScoreInfoDialog(question) {
      this.scoreDialogQuestion = question
      this.showScoreInfoDialog = true
    },
    handleShowDialogUpdate(value) {
      this.showScoreInfoDialog = value
    },
    shouldShowChip(answer) {
      return this.getProp(answer, 'denied') === true || this.getProp(answer, 'deletedAt')
    },
    getChipText(answer) {
      return this.getProp(answer, 'deletedAt') ? 'excluído' : 'negado'
    },
    getChipColor(answer) {
      return this.getProp(answer, 'deletedAt') ? 'thin-gray' : 'red'
    },
    getChipTextColor(answer) {
      return this.getProp(answer, 'deletedAt') ? 'medium-gray' : 'white'
    },
    getColumns(item) {
      const defaultColumns = 12 / this.itemsPerRow
      return path(['columns'], item) || defaultColumns
    },
    getDynamicOptionsClass(propertyName, object) {
      return path(['options', propertyName], object)
    },
    getValue(data) {
      if (isNil(data) || isEmpty(data)) return null
      if (has('value', data)) {
        return Array.isArray(data.value) ? data.value : [data.value]
      }
      return Array.isArray(data) ? data : [data]
    },
    formatAnswer(answer) {
      const lowerCasedAnswer = answer.toLowerCase()
      return lowerCasedAnswer.charAt(0).toUpperCase() + lowerCasedAnswer.slice(1)
    },
    getFormattedAnswer(answer) {
      if (isNil(answer)) return 'Campo não preenchido'
      if (answer === 'true') return 'Sim'
      if (answer === 'false') return 'Não'
      if (isNil(answer?.name)) {
        if (answer?.specialityName) return `• ${this.formatAnswer(answer.specialityName)}`
        if (answer?.value) return answer.value
        if (answer?.content) return `<span class="font-weight-lightblack">${answer.type}:</span> ${answer.content}`
        return answer
      }
      if (answer?.message) return `• ${answer.message}`

      return `• ${this.formatAnswer(answer.name)} ${answer.status ? `- ${this.formatAnswer(answer.status)}` : ''}`
    },
    shouldDisplayDivider(rowIndex) {
      const isLastRow = rowIndex + 1 === this.rows.length
      return !isLastRow && !this.isPrintMode
    },
    getProp(object, propertyName) {
      return path([propertyName], object)
    },
    setDefaultResumeStruct() {
      this.resumeStruct = Object.fromEntries(
        Object.entries(this.resumeStruct)
          .filter(([key]) => this.hasAnyMainQuestionAnswered([key]))
          .map(([key, value]) => [
            key,
            {
              ...value,
              questions: filter(
                question => path(['minimized'], question) === false || isNil(path(['id'], question)),
                value.questions,
              ),
            },
          ]),
      )
      return this.resumeStruct
    },
    hasAnyMainQuestionAnswered(structKeys) {
      let hasMainQuestionAnswered = false
      return any((key) => {
        const section = this.rawResumeStruct[key]
        hasMainQuestionAnswered = any((question) => {
          const hasAnswer = !isNilOrEmpty(this.getQuestionAnswers(question)[0])
          const isMainQuestion = question.minimized === false
          return hasAnswer && isMainQuestion
        }, section.questions)
        return hasMainQuestionAnswered
      }, structKeys)
    },
    isExistingQuestion(question) {
      const conditions = path(['appearConditions'], question)
      if (!conditions) return true

      const professional = { professionId: path(['professional', 'profession', 'id'], this.emr) }
      for (const condition of conditions) {
        if (appearConditions[condition]({ professional })) {
          return true
        }
      }

      return false
    },
    filterQuestions(questions) {
      const filtered = filter((question) => {
        if (!this.isExistingQuestion(question)) return false

        const hasAnswer = !isNilOrEmpty(this.getQuestionAnswers(question)[0])
        const mustShowQuestion = path(['mustShow'], question)
        return hasAnswer || mustShowQuestion
      }, questions)
      return filtered
    },
    setFullResumeStruct() {
      const structKeys = Object.keys(this.rawResumeStruct)
      const shouldFilterUnanswered = this.hasAnyMainQuestionAnswered(structKeys)
      Object.keys(this.rawResumeStruct).forEach((key) => {
        const section = this.rawResumeStruct[key]
        const questions = shouldFilterUnanswered
          ? this.filterQuestions(section.questions)
          : section.questions
        if (this.shouldShowSectionName) {
          if (isEmpty(questions)) questions.push({})
        }

        this.resumeStruct[key] = { ...section, questions }
      })
      return this.resumeStruct
    },
    setInitialResumeStruct() {
      const rawStruct = this.emr?.encounterResumeStruct
        || this.emr?.encounterForm?.encounterResumeStruct
      const { examsStruct, ...rawResumeStruct } = rawStruct
      this.rawResumeStruct = rawResumeStruct

      if (!isNilOrEmpty(examsStruct)) this.examsStruct = examsStruct
      if (this.shouldShowEmptyAsnwers) {
        this.resumeStruct = rawResumeStruct
        return
      }
      this.resumeStruct = {}
      this.setFullResumeStruct()
    },
    setResumeFields(shouldShowMinimized) {
      const struct = shouldShowMinimized
        ? this.setFullResumeStruct()
        : this.setDefaultResumeStruct()
      const structArray = Object.values(struct)
      const sortArrayByIndex = sortBy(prop('index'))
      this.resumeFields = this.shouldShowSectionName
        ? sortArrayByIndex(structArray)
        : this.mergeSectionQuestions(struct)
    },
    mergeSectionQuestions(sections) {
      return Object.keys(sections)
        .map(key => path([key, 'questions'], sections))
        .reduce((acc, cur) => [...acc, ...cur], [])
    },
    isHistoryQuestion(questionId) {
      const historyQuestionIds = [
        emrQuestions.historyExamRequests,
        emrQuestions.historyMedicalReferrals,
      ]
      return includes(questionId, historyQuestionIds)
    },
    getHistoryQuestionAnswers(question) {
      const { resumeDataField } = question
      const historyAnswers = path(['resume', 'data', resumeDataField], this.patient)

      if (isNilOrEmpty(historyAnswers)) return [null]

      return this.getValue(historyAnswers)
    },
    isScoreQuestion(questionId) {
      return hasPath([questionId, 'classification'], this.encounterData)
    },
    getScoreQuestionAnswer(question) {
      const score = path([question.id, 'value'], this.encounterData)
      if (isNilOrEmpty(score)) return [null]

      const zeroNonApplicableQuestions = [emrQuestions.nicotineTest, emrQuestions.alcoholTest]
      const classification = includes(question.id, zeroNonApplicableQuestions) && score === '0'
        ? 'Não se aplica'
        : this.encounterData[question.id].classification

      return [`${score} - ${classification.toUpperCase()}`]
    },
    getQuestionAnswers(question) {
      if (this.isHistoryQuestion(question.id)) return this.getHistoryQuestionAnswers(question)
      if (this.isScoreQuestion(question.id)) return this.getScoreQuestionAnswer(question)

      const allAnswers = this.getValue(this.encounterData[question.id])

      if (isNilOrEmpty(allAnswers)) return [null]
      if (path(['showPreviousAnswers'], question)) return allAnswers

      const currentAnswers = filter(
        (answer) => {
          if (path(['isEdited'], answer)) return true
          if (path(['deletedAt'], answer)) return true
          const startEncounterId = path(['startEncounterId'], answer)
          if (startEncounterId) return startEncounterId === this.emr.id
          return true
        },
        allAnswers,
      )

      if (isNilOrEmpty(currentAnswers)) return [null]
      return currentAnswers
    },
    toggleMinimizedQuestions() {
      this.showMinimizedQuestions = !this.showMinimizedQuestions
      this.setResumeFields(this.showMinimizedQuestions)
    },
    showAllFields() {
      if (this.canOpenResumeModal) this.showEncounterResumeDialog = true
      else this.toggleMinimizedQuestions()
    },
    setShowEncounterResumeDialog(value) {
      this.showEncounterResumeDialog = value
    },
    hasExamInSection(section) {
      const examGroups = path(['examGroups'], section)
      if (isNilOrEmpty(examGroups)) return false

      return any(this.hasExamsInGroup, examGroups)
    },
    hasExamsInGroup(examGroup) {
      const { exams } = this.encounterData

      const hasValue = exam => !isNil(path(['value'], exams[exam.id]))
      const hasJustification = exam => !isNil(path(['justificationForEmptyField'], exams[exam.id]))

      return any(exam => hasValue(exam) || hasJustification(exam), examGroup.exams)
    },
    hasSameNameAsPreviousExam(examGroup, exam, examIndex) {
      if (isNil(examGroup)) return false
      const previousExam = examGroup.exams[examIndex - 1]
      if (!previousExam) return false

      return exam.name === previousExam.name
    },
    hasPreviousExamData(examGroup, exam, examIndex) {
      if (isNil(examGroup)) return false
      const previousExam = examGroup.exams[examIndex - 1]
      if (!previousExam) return false

      const previousExamData = this.encounterData.exams[previousExam.id]
      if (isNil(previousExamData)) return false

      return !isNil(previousExamData.value)
    },
    shouldShowExamData(examGroup, exam, examIndex) {
      if (isNil(exam)) return false
      const examData = this.encounterData.exams[exam.id]
      if (isNil(examData)) return false

      const hasValue = !isNil(path(['value'], examData))
      const hasJustification = !isNil(path(['justificationForEmptyField'], examData))

      if (!hasValue && !hasJustification) return false

      const isFirstExamAppearance = examIndex === 0
        || isNil(examIndex)
        || !this.hasSameNameAsPreviousExam(examGroup, exam, examIndex)
        || !this.hasPreviousExamData(examGroup, exam, examIndex)

      return examData && isFirstExamAppearance
    },
    shouldShowNextExamData(examGroup, exam, examIndex) {
      const hasNextExam = examGroup.exams[examIndex + 1]
      if (!hasNextExam) return false

      const isSecondExamAppearence = exam.name === examGroup.exams[examIndex + 1].name
      const hasNextExamData = this.getNextExamData('value', examGroup, examIndex)

      return isSecondExamAppearence && hasNextExamData
    },
    formattedOriginType(value) {
      if (value === 'obtained-by-professional') return 'Auferido'
      if (value === 'obtained-by-patient') return 'Referido'
      return null
    },
    getExamDataText(exam) {
      const examData = this.encounterData.exams[exam.id]
      const justifiedExam = examData.justificationForEmptyField
      const filledExam = `${examData.value}
        ${examData.measure ? examData.measure : ''}
        ${examData.originType ? `| Dado ${this.formattedOriginType(examData.originType)}` : ''}`
      const examResponse = this.getProp(examData, 'justificationForEmptyField')
        ? justifiedExam
        : filledExam
      return `${capitalizeFirstLetter(exam.name.toLowerCase())}: ${examResponse}`
    },
    getNextExamData(property, examGroup, examIndex) {
      const hasNextExam = examGroup.exams[examIndex + 1]
      if (!hasNextExam) return null

      const nextExamId = examGroup.exams[examIndex + 1].id
      const nextExamData = path(['exams', nextExamId, property], this.encounterData)
      return nextExamData
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../../style/_core/colors.styl'

info-container-position(directionIsRow = true)
  if directionIsRow
    width 20%
  else
    margin-top 5%
    width 100%

.v-chip--disabled
  opacity 1
.card__infos-container--full-width
  info-container-position(false)

.card__infos-container--partial-width
  info-container-position()

.card__infos-name
  color rgba(#000, 0.6)
  margin-top 5px
  margin-bottom 5px

.card__infos-additional-information
  font-style italic
  color rgba(#000, 0.5)
  margin-top 1rem

.card__group-title
  flex 0
  margin-bottom 1rem
  color $evening-sea
  font-size 1.2em

.card__infos-sub-title
  color black
  margin-bottom .4rem

.card__answer-container
  margin-bottom 2rem

.card__answer
  white-space pre-line
  max-width 85%

.card__infos-observation
  margin-left .6rem

.card-item__spacement
  padding-right 1rem

.card-item__print-spacement
  margin-bottom 1rem

.divider__spacement
  margin 1.5rem 0

.no-margin-y
  margin-top 0
  margin-bottom 0

.no-padding
  padding 0

.no-padding-y
  padding-top 0
  padding-bottom 0

.exams-card-container__spacement
  margin 0 0.5rem
  padding-top 0
  padding-bottom 0

.exam-group__spacement
  padding 0 1rem 1rem 0

.suggested-exam-group__spacement
  padding 0 1rem 0 0

</style>
