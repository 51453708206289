export default {
  cpf: '90103599053',
  birthDate: '1990-01-01',
  id: 'pat_cllmnnybt00290jlyelda39gf',
  name: 'Paciente Teste',
  socialName: null,
  foreignDocument: '',
  rg: null,
  ddiTelphone: null,
  telphone: '11111111111',
  ddiTelphoneSecondary: null,
  telphoneSecondary: null,
  referralId: null,
  referralOther: null,
  email: 'teste@gmai.com',
  emailSecondary: null,
  biologicalSex: 'M',
  maritalStatus: null,
  nationality: null,
  placeOfBirth: null,
  profession: null,
  company: null,
  religion: null,
  bloodType: null,
  healthInsurancePlan: null,
  healthInsurancePlanNumber: '123456789',
  healthInsurancePlanExpiration: null,
  hmoOwnerId: null,
  notes: null,
  isForeign: false,
  specialNeedsType: null,
  isSpecialNeeds: false,
  isFirstAppointment: true,
  race: null,
  responsibleId: null,
  responsibleRelationship: null,
  externalIds: {
    portal: 'user_cllmnnyqj000e0jonfkavf8l3',
    portalError: null,
  },
  storedAt: null,
  needUpdate: false,
  createdBy: 'user_ck3ogz93d0000dvpq3c6gh540',
  score: 105,
  virtualReceptionId: null,
  termsLog: {
    log: [],
    current: {
      tele: {},
      privacy: {},
      sharing: {},
      marketing: {},
    },
  },
  workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
  lastAppointmentDate: null,
  storedLog: [],
  lastCheckEligibility: null,
  medicalFollowUpStatus: 'acceptedApp',
  medicalFollowUpRefuseReason: null,
  healthProductId: 'hea_pro_cjy92rrfg002hi3vo8gra8asr',
  isEmailValidatedByPatient: false,
  address: {
    state: 'SP',
    city: 'São Paulo',
    zipCode: '02045970',
    neighborhood: 'Jardim São Paulo(Zona Norte)',
    street: 'Rua Guajurus, 69',
    number: '1',
    complement: null,
  },
  careLinesPatient: [
    {
      id: 'car_lin_pat_cllmnnydv002a0jlyd18m58i8',
      careLineId: 'care_lin_cl34fyhv0028y0jnu37t9av8w',
      diseases: null,
      careLine: {
        id: 'care_lin_cl34fyhv0028y0jnu37t9av8w',
        name: 'Jornada de ativação',
        isPrimary: true,
      },
    },
  ],
  careLineTagsPatient: [],
  referral: null,
  responsible: null,
  hmoDependents: [],
  hmoOwner: null,
  healthProduct: {
    id: 'hea_pro_cjy92rrfg002hi3vo8gra8asr',
    healthMaintenanceOrganizationId: 'hea_mai_org_cjy92rrfg002hi3vo8gra8asr',
    name: 'AMIL EXECUTIVO',
    nickName: 'AMIL EXECUTIVO',
  },
  healthMaintenanceOrganization: {
    id: 'hea_mai_org_cjy92rrfg002hi3vo8gra8asr',
    name: 'AMIL',
    nickName: 'AMIL',
    externalIds: {
      cm: {
        id: 'a76fc4ad-767d-45c9-8a73-ef55468b2e0e',
        site: null,
      },
    },
    allowOwner: true,
    validationRules: {
      healthInsurancePlanNumberLength: 9,
    },
    coreAccreditation: {
      clinics: [
        'cli_cjxyringi0000i2vog8g55l7q',
        'cli_ck9b7yjf3000195vohxz60nio',
        'cli_ckn7qpqml0000d5dq3mdjhkzc',
        'cli_cknainh750000z9pqd3et8ixm',
        'cli_cknainh750000z9pqd3et8ixn',
      ],
      professionals: null,
    },
    hasEligibilityCheck: false,
  },
  careTeam: null,
  activationJourneys: [],
  patientJourney: null,
}
