import axios from '@/lib/axios'

export default {
  state: {
    loading: false,
    careLine: null,
    careLines: [],
    careLineFilters: {},
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_CARE_LINE(state, payload) {
      state.careLine = payload
    },
    SET_CARE_LINES(state, payload) {
      state.careLines = payload
    },
  },
  actions: {
    listCareLine({ commit }, params = {}) {
      return new Promise((resolve, reject) => {
        axios.get('/emr/care_line', {
          params,
        })
          .then(({ data: { careLines } }) => {
            commit('SET_CARE_LINES', careLines)
            resolve(careLines)
          })
          .catch(reject)
      })
    },
    getCareLine({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios.get(`emr/care_line/${id}`)
          .then(({ data: { careLine } }) => {
            commit('SET_CARE_LINE', careLine)
            resolve(careLine)
          })
          .catch(reject)
      })
    },
    createCareLine({ commit }, careLine) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios.post('/emr/care_line', careLine)
          .then(({ data }) => resolve(data))
          .catch(reject)
          .finally(() => commit('SET_LOADING', false))
      })
    },
    updateCareLine({ commit }, { careLineId, careLineData }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios.put(`/emr/care_line/${careLineId}`, careLineData)
          .then(({ data }) => resolve(data))
          .catch(reject)
          .finally(() => commit('SET_LOADING', false))
      })
    },
    setCareLine({ commit }, payload) {
      commit('SET_CARE_LINE', { ...payload })
    },
  },
  getters: {
    isLoadingCareLine: state => state.loading,
    careLine: state => state.careLine,
    careLines: state => state.careLines,
  },
}
