<template>
  <v-dialog
    v-model="show"
    content-class="bill__dialog"
    max-width="850"
    @click:outside="closeDialog()"
  >
    <v-card>
      <v-card-title class="card-dialog__title font-weight-bold medium-gray-text">
        Nova fatura
        <v-spacer />
        <v-btn
          icon
          @click="closeDialog()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-autocomplete
          v-model="selectedHmoId"
          :items="hmos"
          label="Operadora"
          clearable
          filled
          item-text="name"
          item-value="id"
          :error="$v.selectedHmoId.$error"
          :error-messages="$v.selectedHmoId.$error ? 'Campo Obrigatório' : null"
          @blur="$v.selectedHmoId.$touch()"
        />
        <vc-date-picker
          v-model="billDay"
          locale="pt-br"
          color="green"
          :min-date="todaysDate"
        >
          <template v-slot="{ inputEvents }">
            <v-text-field
              :value="formattedDate(billDay)"
              readonly
              label="Fechamento"
              append-icon="mdi-calendar"
              filled
              clearable
              :error="$v.billDay.$error"
              :error-messages="$v.billDay.$error ? 'Campo Obrigatório' : null"
              @click:clear="billDay = null"
              @blur="$v.billDay.$touch()"
              v-on="inputEvents"
            />
          </template>
        </vc-date-picker>
      </v-card-text>
      <v-card-actions class="px-6 pb-6">
        <v-spacer />
        <v-btn
          class="mr-2 font-weight-lightbold"
          color="gray darken-2"
          outlined
          @click="closeDialog()"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="font-weight-lightbold"
          color="primary"
          :loading="isLoading"
          @click="handleCreateBill()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import isNilOrEmpty from '@/utils/dataValidators'
import { required } from 'vuelidate/lib/validators'
import {
  always,
  cond,
  equals,
  T,
} from 'ramda'

export default {
  props: {
    showDialog: {
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: false,
      selectedHmoId: null,
      billDay: null,
      todaysDate: moment().toDate(),
    }
  },
  validations() {
    return {
      selectedHmoId: { required },
      billDay: { required },
    }
  },
  computed: {
    ...mapGetters({
      hmos: 'healthMaintenanceOrganization/hmos',
    }),
    show: {
      get() {
        return this.showDialog
      },
      set(value) {
        this.$emit('update:showDialog', value)
      },
    },
  },
  methods: {
    ...mapActions({
      createBill: 'billing/createBill',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    closeDialog() {
      this.show = false
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    formattedDate(date) {
      return isNilOrEmpty(date) ? null : moment(date).format('DD/MM/YYYY')
    },
    clearData() {
      this.selectedHmoId = null
      this.billDay = null
      this.$v.$reset()
    },
    async handleCreateBill() {
      this.$v.$touch()
      if (this.$v.$error) return

      this.isLoading = true
      try {
        await this.createBill({
          healthMaintenanceOrganizationId: this.selectedHmoId,
          closeDate: moment(this.billDay).format('YYYY-MM-DD'),
        })

        this.$bus.$emit('update:bill')
        this.buildSnackbar('success', 'Fatura criada com sucesso!')

        this.clearData()
        this.closeDialog()
      } catch (error) {
        const errorMessage = cond([
          [
            equals('close_date_already_in_use'),
            always('Já existe uma fatura com essa data de fechamento'),
          ],
          [T, always('Erro ao criar fatura')],
        ])(error?.response?.data?.errorCode)

        this.buildSnackbar('error', errorMessage)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="stylus">

.bill__dialog
  overflow visible !important

</style>
