<template>
  <div>
    <component-title
      new-user
    />
    <user-list-container />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import UserListContainer from './UserListContainer'

export default {
  components: {
    ComponentTitle,
    UserListContainer,
  },
}
</script>
