<template>
  <v-tooltip bottom>
    <template
      v-slot:activator="{ on }"
    >
      <v-icon
        class="pa-1 thin-gray rounded-circle"
        :size="iconSize"
        v-on="on"
        @click.stop.prevent="emitPin"
      >
        {{ pinned ? 'mdi-pin-off' : 'mdi-pin' }}
      </v-icon>
    </template>
    <span>{{ pinned ? 'Ocultar' : 'Destacar' }}</span>
  </v-tooltip>
</template>

<script>
import { always, cond, prop } from 'ramda'
import { mapActions } from 'vuex'

export default {
  props: {
    pinned: {
      type: Boolean,
      default: false,
      required: true,
    },
    condition: {
      type: Object,
      required: true,
    },
    iconSize: {
      type: String,
      required: false,
      default: '20',
    },
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
    }),
    async emitPin() {
      const conditionHighlightType = cond([
        [(prop('activeProblemHighlightData')), always('activeProblem')],
        [(prop('familyHighlightData')), always('family')],
        [(prop('personalHighlightData')), always('personal')],
      ])(this.condition)
      if (this.pinned) {
        this.$emit('togglePin', { conditionHighlightType, condition: this.condition, action: 'unpin' })
      } else {
        this.$emit('togglePin', { conditionHighlightType, condition: this.condition, action: 'pin' })
      }
      this.buildSnackbar('success', this.pinned
        ? 'Este item não será destacado na lista de problemas em atendimentos futuros'
        : 'Este item será destacado na lista de problemas em atendimentos futuros')
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
  },
}
</script>
