<template>
  <div>
    <div class="filters-container">
      <v-container
        :class="{
          'container--fluid': true,
          'px-12': $vuetify.breakpoint.smAndUp,
          'grid-list-lg': true
        }"
      >
        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
            sm4
            md9
          >
            <v-text-field
              v-model="filters.name"
              filled
              label="Buscar por nome"
              clearable
              no-data-text="Digite para buscar"
              @change="listHmo({ name: filters.name })"
              @click:clear="listHmo"
            />
          </v-flex>
          <v-flex
            xs12
            sm8
            md3
          >
            <v-btn
              color="dark-green"
              class="btn-filter--height font-weight-lightbold"
              dark
              block
              large
            >
              <v-icon left>
                mdi-magnify
              </v-icon>
              Buscar Plano de Saúde
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <v-container
      :class="{
        'container--fluid': true,
        'px-12': $vuetify.breakpoint.smAndUp,
      }"
    >
      <v-layout
        v-if="isHmosEmpty"
      >
        <h2 class="info-text font-weight-lighter">
          Nenhum resultado encontrado.
        </h2>
      </v-layout>
      <template v-else>
        <v-layout
          v-for="(hmo, index) in hmos"
          :key="index"
          class="list-item"
        >
          <v-flex
            md11
            sm11
            xs12
          >
            <div class="truncate">
              <span class="list-item__title">
                {{ hmo.name }}
              </span>

              <div
                class="list-item__detail"
              >
                Apelido: {{ hmo.nickName }}
              </div>

              <div
                class="list-item__detail"
              >
                Tipo: {{ hmo.type }}
              </div>
            </div>
          </v-flex>
          <v-flex
            md1
            justify-end
            class="list-item__icon"
          >
            <v-tooltip
              v-if="isWorkSpaceAmparo"
              top
            >
              <template #activator="{ on }">
                <v-btn
                  class="mr-2 font-weight-lightbold"
                  text
                  icon
                  @click="editIntegrationsDialog(hmo)"
                  v-on="on"
                >
                  <v-icon>mdi-clipboard-text-multiple</v-icon>
                </v-btn>
              </template>
              <span>Editar Integrações</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  class="mr-2 font-weight-lightbold"
                  text
                  icon
                  @click="openProductListDialog(hmo)"
                  v-on="on"
                >
                  <v-icon>mdi-package-variant-closed</v-icon>
                </v-btn>
              </template>
              <span>Plano</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  class="mr-2 font-weight-lightbold"
                  text
                  icon
                  :data-testid="`hmo__edit-${hmo.name}--button`"
                  @click="editDialog(hmo)"
                  v-on="on"
                >
                  <v-icon>mdi-account-edit</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </template>
    </v-container>

    <create-hmo
      v-if="showEditHmoDialog"
      edit-hmo
      :hmo-data="editedHmo"
      :hmo-list-filter="filters"
      @closeCreateHmoDialog="closeDialog"
    />

    <edit-hmo-integrations
      v-if="showEditHmoIntegrationsDialog"
      edit-hmo
      :hmo-data="editedHmo"
      :hmo-list-filter="filters"
      @closeDialog="closeDialog"
    />

    <health-product-list
      v-if="showProductListDialog"
      :hmo-id="editedHmo.id"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import {
  isEmpty,
} from 'ramda'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HmoList',
  components: {
    CreateHmo: () => import('./CreateHmo'),
    EditHmoIntegrations: () => import('./EditHmoIntegrations'),
    HealthProductList: () => import('./HealthProductList'),
  },
  data() {
    return {
      showProductListDialog: false,
      showEditHmoDialog: false,
      showEditHmoIntegrationsDialog: false,
      editedHmo: {},
      filters: {},
      count: '',
      numberOfPages: null,
      page: 1,
    }
  },
  computed: {
    ...mapGetters({
      hmos: 'healthMaintenanceOrganization/hmos',
      loading: 'healthMaintenanceOrganization/loading',
      isWorkSpaceAmparo: 'authentication/isWorkSpaceAmparo',
    }),
    isHmosEmpty() {
      return isEmpty(this.hmos)
    },
  },
  mounted() {
    this.listHmo()
  },
  methods: {
    ...mapActions({
      listHmo: 'healthMaintenanceOrganization/listHmo',
    }),
    openProductListDialog(hmoData) {
      this.editedHmo = hmoData
      this.showProductListDialog = true
    },
    editDialog(hmoData) {
      this.editedHmo = hmoData
      this.showEditHmoDialog = true
    },
    editIntegrationsDialog(hmoData) {
      this.editedHmo = hmoData
      this.showEditHmoIntegrationsDialog = true
    },
    closeDialog() {
      this.editedHmo = {}
      this.showEditHmoDialog = false
      this.showProductListDialog = false
      this.showEditHmoIntegrationsDialog = false
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'
.list-item
  width 100%
  padding 20px
  border-radius 8px
  border solid 1px #e0e0e0
  margin-bottom 10px
.list-item__title
  font-size 24px
  color rgba(0, 0, 0, 0.87)
.list-item__detail
  font-size 16px
  line-height 2
  letter-spacing 0.5px
  color rgba(0, 0, 0, 0.6)
.list-item__icon
  margin-top -8px
.truncate
  @media only screen and (min-width: 601px)
    width 60vw
.info-text
  letter-spacing -0.5px
  color rgba(0, 0, 0, 0.38)
  @media only screen and (min-width 601px)
    font-size 32px
  @media only screen and (min-width 1201px)
    font-size 48px
  @media only screen and (min-width 1601px)
    font-size 64px
.filters-container
  width 100%
  height auto
  background-color #e8f8e8
  @media only screen and (min-width 601px)
    height 108px
.v-card
  padding 10px
  border-radius 6px
.v-btn
  letter-spacing 1px
.v-btn.v-btn--large
  margin-top 0px
  height 56px
  border-radius 8px
.v-btn.v-btn--text
  border solid 1px thin-gray
.v-btn.btn-dialog
  padding 15px
  @media all and (min-width 601px)
    width 190px
</style>
