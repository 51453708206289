import { render, staticRenderFns } from "./EmrEncounterResumeLegacy.vue?vue&type=template&id=bb0dc240&scoped=true&"
import script from "./EmrEncounterResumeLegacy.vue?vue&type=script&lang=js&"
export * from "./EmrEncounterResumeLegacy.vue?vue&type=script&lang=js&"
import style0 from "./EmrEncounterResumeLegacy.vue?vue&type=style&index=0&id=bb0dc240&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb0dc240",
  null
  
)

export default component.exports