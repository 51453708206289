<template>
  <div>
    <div class="filters-container py-4">
      <v-container
        fluid
        class="px-11"
      >
        <v-row>
          <v-col
            cols="auto"
          >
            <v-autocomplete
              v-model="filters.hmoId"
              :items="allHmosList"
              label="Operadora"
              clearable
              filled
              hide-details
              item-text="name"
              item-value="id"
              @change="handleHmoFilterChange"
            />
          </v-col>

          <v-col
            cols="auto"
          >
            <v-autocomplete
              v-model="filters.healthProductId"
              :items="healthProductsList"
              label="Plano"
              clearable
              filled
              hide-details
              item-text="name"
              item-value="id"
              @change="handleHealthProductFilterChange"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-list>
      <v-list-group
        v-for="hmo in hmoList"
        :key="hmo.name"
        :value="shouldOpenList"
        fluid
        class="px-11 my-6 hmo-title__container"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="py-2">
              <v-flex
                class="mr-2"
                justify-end
              >
                <v-flex align-center>
                  <h3 class="text-uppercase amparo-gray--text">
                    {{ hmo.name }} operadora
                  </h3>
                </v-flex>
                <v-spacer />
                <v-btn
                  v-if="hasAccessToEditPreferredNetwork"
                  color="primary"
                  @click="goToEditPreferredNetwork(hmo.id)"
                >
                  <v-icon
                    left
                  >
                    mdi-plus
                  </v-icon>
                  <span>Rede Preferencial</span>
                </v-btn>
              </v-flex>
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="healthProduct in getHealthProductsWithPreferredNetwork(hmo.healthProducts)"
          :key="healthProduct.name"
          class="health-product__container"
        >
          <v-list-item-content class="py-6 font-weight-medium">
            {{ healthProduct.name }}
          </v-list-item-content>

          <v-list-item-action class="mr-2">
            <v-flex align-center>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    class="mr-4"
                    fab
                    x-small
                    color="thin-gray"
                    :loading="downloadPreferredNetworkisLoadingMap[healthProduct.id]"
                    v-on="on"
                    @click="handlePreferredNetworkXlsxDownload(healthProduct)"
                  >
                    <v-icon color="dark-gray">
                      mdi-download
                    </v-icon>
                  </v-btn>
                </template>
                <span>Fazer download da rede preferencial</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    fab
                    x-small
                    color="thin-gray"
                    :loading="preferredNetworkDataisLoadingMap[healthProduct.id]"
                    v-on="on"
                    @click="handlePreferredNetworkModal(hmo, healthProduct)"
                  >
                    <v-icon color="dark-gray">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Visualizar rede preferencial</span>
              </v-tooltip>
            </v-flex>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          v-if="!hasHealthProductsWithPreferredNetwork(hmo.healthProducts)"
          class="health-product__container"
        >
          <v-list-item-content class="py-6 font-weight-medium">
            Operadora não possui rede preferencial configurada
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>

    <preferred-network-modal
      v-if="hasSelectedHmo && hasSelectedHealthProduct"
      :show-dialog="showPreferredNetworkModal"
      :hmo="selectedHmo"
      :health-product="selectedHealthProduct"
      :formatted-preferred-network-data="formattedPreferredNetworkData"
      @update:showDialog="handleShowPreferredNetworkModal"
    />
  </div>
</template>

<script>
/* eslint-disable no-continue */
import {
  clone,
  flatten,
  isEmpty,
} from 'ramda'
import { mapGetters, mapActions } from 'vuex'
import { write, utils } from 'xlsx'

export default {
  components: {
    PreferredNetworkModal: () => import('./PreferredNetworkModal'),
  },
  data() {
    return {
      shouldOpenList: false,
      filters: {
        hmoId: null,
        healthProductId: null,
      },
      preferredNetworkDataisLoadingMap: {},
      downloadPreferredNetworkisLoadingMap: {},
      hmoList: [],
      allHmosList: [],
      healthProductsList: [],
      allHealthProductsList: [],
      showPreferredNetworkModal: false,
      selectedHmo: {},
      selectedHealthProduct: {},
      formattedPreferredNetworkData: [],
    }
  },
  computed: {
    ...mapGetters({
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
    }),
    hasAccessToEditPreferredNetwork() {
      return this.userHasAccessToFunctionality.editPreferredNetwork
    },
    hasSelectedHmo() {
      return !isEmpty(this.selectedHmo)
    },
    hasSelectedHealthProduct() {
      return !isEmpty(this.selectedHealthProduct)
    },
  },
  async mounted() {
    this.allHmosList = await this.listHmo()
    this.allHealthProductsList = flatten(this.allHmosList.map(hmo => hmo.healthProducts))

    this.hmoList = await this.listHmo()
    this.healthProductsList = clone(this.allHealthProductsList)
  },
  methods: {
    ...mapActions({
      getHmoById: 'healthMaintenanceOrganization/getById',
      listHmo: 'healthMaintenanceOrganization/listHmo',
      getPreferredNetworkProfessionals: 'preferredNetwork/getPreferredNetworkProfessionals',
    }),
    goToEditPreferredNetwork(hmoId) {
      this.$router.push(`/preferred-network/edit/${hmoId}`)
    },
    filterHmosByHealthProductId(selectedHmoId, selectedHealthProductId) {
      const filteredHmos = []
      for (const hmo of this.allHmosList) {
        if (selectedHmoId && selectedHmoId !== hmo.id) continue

        if (!hmo.healthProducts) continue

        if (!selectedHealthProductId) {
          filteredHmos.push(hmo)
          continue
        }

        const filteredHealthProducts = hmo.healthProducts.filter(
          healthProduct => healthProduct.id === selectedHealthProductId,
        )

        if (filteredHealthProducts.length > 0) {
          filteredHmos.push({
            ...hmo,
            healthProducts: filteredHealthProducts,
          })
        }
      }
      return filteredHmos
    },
    async handleHmoFilterChange(selectedHmoId) {
      if (!selectedHmoId) {
        if (!this.filters.healthProductId) {
          this.hmoList = clone(this.allHmosList)
        }

        this.healthProductsList = clone(this.allHealthProductsList)
        this.shouldOpenList = this.hmoList.length <= 1
        return
      }

      const filteredHmo = await this.getHmoById(selectedHmoId)
      this.hmoList = [filteredHmo]
      this.healthProductsList = flatten(this.hmoList.map(hmo => hmo.healthProducts))
      this.filters.healthProductId = null
      this.shouldOpenList = true
    },
    async handleHealthProductFilterChange(selectedHealthProductId) {
      if (!selectedHealthProductId && !this.filters.hmoId) {
        this.healthProductsList = clone(this.allHealthProductsList)
        this.hmoList = clone(this.allHmosList)
        this.shouldOpenList = false
        return
      }

      const hmosWithFilteredHealthProducts = this.filterHmosByHealthProductId(
        this.filters.hmoId,
        selectedHealthProductId,
      )
      this.hmoList = hmosWithFilteredHealthProducts

      this.shouldOpenList = this.hmoList.length <= 1
    },
    async getPreferredNetworkData(healthProduct) {
      const preferredNetworkId = healthProduct.preferredNetwork?.[0]?.id
      if (!preferredNetworkId) return null

      const { data } = await this.getPreferredNetworkProfessionals(preferredNetworkId)

      const formattedPreferredNetworkData = data.map(professional => ({
        Plano: healthProduct.name,
        Nome: professional.name,
        Especialidade: professional.speciality.name,
        Telefone: professional.telephone,
        Email: professional.email,
        Estado: professional.address?.state,
        Cidade: professional.address?.city,
        Bairro: professional.address?.neighborhood,
        Logradouro: professional.address?.street,
        Número: professional.address?.number,
        CEP: professional.address?.zipCode,
      }))

      return formattedPreferredNetworkData
    },
    async handlePreferredNetworkModal(hmo, healthProduct) {
      this.preferredNetworkDataisLoadingMap[healthProduct.id] = true

      this.selectedHmo = hmo
      this.selectedHealthProduct = healthProduct
      this.formattedPreferredNetworkData = await this.getPreferredNetworkData(healthProduct)

      this.preferredNetworkDataisLoadingMap[healthProduct.id] = false

      this.showPreferredNetworkModal = true
    },
    handleShowPreferredNetworkModal(value) {
      this.showPreferredNetworkModal = value
    },
    hasHealthProductsWithPreferredNetwork(healthProducts) {
      const filteredHealthProducts = this.getHealthProductsWithPreferredNetwork(healthProducts)

      return !isEmpty(filteredHealthProducts)
    },
    getHealthProductsWithPreferredNetwork(healthProducts) {
      if (!healthProducts) return []

      return healthProducts.filter(
        (healthProduct) => {
          const hasPreferredNetwork = !isEmpty(healthProduct.preferredNetwork)
          if (!hasPreferredNetwork) return false

          const hasProfessionals = !isEmpty(
            healthProduct.preferredNetwork[0]?.professionals,
          )

          return hasProfessionals
        },
      )
    },
    async handlePreferredNetworkXlsxDownload(healthProduct) {
      this.downloadPreferredNetworkisLoadingMap[healthProduct.id] = true

      this.formattedPreferredNetworkData = await this.getPreferredNetworkData(healthProduct)

      const worksheet = utils.json_to_sheet(this.formattedPreferredNetworkData)
      const workbook = utils.book_new()
      utils.book_append_sheet(workbook, worksheet, 'Sheet1')

      const workbookOut = write(workbook, { bookType: 'xlsx', type: 'array' })

      const blob = new Blob([workbookOut], { type: 'application/octet-stream' })

      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `rede-preferencial-${healthProduct.name.toLowerCase()}.xlsx`

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      this.downloadPreferredNetworkisLoadingMap[healthProduct.id] = false
    },
  },
}

</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.hmo-title__container
  >>> h3
    font-size 1.125rem
    font-weight 600

  >>> .v-list-group__header
    background-color #f5f5f5
    box-shadow: 0px 2px 2px 0px rgba(219, 219, 219, 0.50)

.health-product__container
  box-shadow: 0px 2px 2px 0px rgba(219, 219, 219, 0.50)

.v-list-group--active
  >>> h3
    color primary-color !important

</style>
