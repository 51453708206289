<template>
  <div>
    <v-fab-transition>
      <v-btn
        v-show="scrollY > 60"
        color="amparo-medium-green"
        class="mb-16"
        dark
        fab
        fixed
        bottom
        right
        @click="scrollTop"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import {
  throttle,
} from 'lodash'

export default {
  data() {
    return {
      scrollY: 0,
    }
  },
  created() {
    window.addEventListener('scroll', throttle(this.getScrollY, 300))
  },
  destroyed() {
    window.removeEventListener('scroll', this.getScrollY)
  },
  methods: {
    getScrollY() {
      this.scrollY = window.scrollY
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
}
</script>
