import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: 'http://localhost:29100',
  timeout: 5000,
  headers: {
    Accept: 'application/json',
  },
})

export default axiosInstance
