export default {
  FAMILY_DOCTOR: {
    name: 'Médico de família',
    id: 'spe_cjy92cswd001yi3voc4sjca8c',
    itemId: {
      presential: 'ite_cl44iaubz000377zu6jy17vst',
      remote: 'ite_cl44iaubz000277zu4tcq20kf',
    },
  },
  DERMATOLOGIST: {
    name: 'Médico dermatologista',
    id: 'spe_cjz42yawa0002f0vo1ed0cy35',
    itemId: {
      presential: 'ite_cl44l669u0001t8szdod1fa9e',
      remote: 'ite_cl44l5yw80000t8sz617aeids',
    },
  },
  PSYCHOLOGIST: {
    name: 'Psicólogo clínico',
    id: 'spe_cjy92cx9g001zi3vo8un7e14f',
    itemId: {
      presential: 'ite_cl44iauc0000777zu10hm71d7',
      remote: 'ite_cl44iauc0000677zu03lxalcz',
    },
  },
  GYNECOLOGIST: {
    name: 'Ginecologista',
    id: 'spe_cjyrq45s30068apvo8yxwco1j',
    itemId: {
      presential: 'ite_cl44l80u70003t8szbyhbei6i',
      remote: 'ite_cl44l7kim0002t8sz063bf0f2',
    },
  },
  OPHTHALMOLOGIST: {
    name: 'Médico oftalmologista',
    id: 'spe_cjyrqbjr8006aapvohny3fqcw',
    itemId: {
      presential: 'ite_cl44l9gvd0006t8sz9bgfbr79',
      remote: 'ite_cl44l998a0005t8szajgkavrj',
    },
  },
  NUTRITIONIST: {
    name: 'Nutricionista',
    id: 'spe_cjyrqf835006bapvockcafr8i',
    itemId: {
      presential: 'ite_cl44iaubz000577zu3ry48vxl',
      remote: 'ite_cl44iaubz000477zubc6364mb',
    },
  },
  NURSE: {
    name: 'Enfermeira',
    id: 'spe_cjyisn3y90016apvo3eu1bbas',
    itemId: {
      presential: 'ite_cl44iaubz000177zuaqb0dkpk',
      remote: 'ite_cl44iaubu000077zu8hcodjgm',
    },
  },
  PRATICAL_NURSE: {
    name: 'Enfermeira',
    id: 'spe_ck43083f600004epq1wo36oxn',
  },
}
