import axios from '@/lib/axios'

const actions = {
  createCalculatorCondition(_, attributes) {
    return new Promise((resolve, reject) => {
      axios.post('/emr/calculator_condition', attributes)
        .then(({ data }) => resolve(data))
        .catch(reject)
    })
  },
  updateCalculatorCondition(_, { calculatorConditionId, attributes }) {
    return new Promise((resolve, reject) => {
      axios.put(`/emr/calculator_condition/${calculatorConditionId}`, attributes)
        .then(resolve)
        .catch(reject)
    })
  },
  deleteCalculatorCondition(_, calculatorConditionId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/emr/calculator_condition/${calculatorConditionId}`)
        .then(resolve)
        .catch(reject)
    })
  },
}

export default {
  actions,
}
