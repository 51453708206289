<template>
  <div>
    <v-layout>
      <v-flex sm12>
        <v-tabs
          v-model="tab"
          grow
          height="55"
          background-color="light-green"
          hide-slider
          class="tabs--full-width"
        >
          <v-tab active-class="color: primary">
            <span class="tab__title">Prontuário</span>
          </v-tab>
          <v-tab active-class="color: primary">
            <span class="tab__title">Exames</span>
          </v-tab>
          <v-tab
            v-if="userHasAccessToEmrForm"
            active-class="color: primary"
          >
            <v-flex class="tab__forms">
              <span class="tab__title">
                Formulários
              </span>
              <badge :number="uncompletedFormsLength" />
            </v-flex>
          </v-tab>
          <v-tab
            v-if="isWorkSpaceAmparo"
            active-class="color: primary"
          >
            <span class="tab__title">Tarefas</span>
          </v-tab>
        </v-tabs>
      </v-flex>
    </v-layout>
    <v-layout v-if="shouldLoadComponents">
      <v-flex sm9>
        <v-tabs-items
          v-model="tab"
          class="tabs-items--full-width"
        >
          <v-tab-item>
            <emr-history />
          </v-tab-item>
          <v-tab-item>
            <emr-exams />
          </v-tab-item>
          <v-tab-item v-if="userHasAccessToEmrForm">
            <patient-forms-list />
          </v-tab-item>
          <v-tab-item v-if="isWorkSpaceAmparo">
            <emr-schedule />
          </v-tab-item>
        </v-tabs-items>
      </v-flex>
      <v-flex sm3>
        <emr-resume />
      </v-flex>
    </v-layout>
    <emr-duplicated-encounter-warning-modal
      v-if="shouldShowDuplicatedEncounterWarningModal"
    />
  </div>
</template>

<script>
import EmrResume from '@/components/Common/EmrResume'
import { mapActions, mapGetters } from 'vuex'
import {
  not,
  isEmpty,
} from 'ramda'
import EmrHistory from './EmrHistory'

export default {
  components: {
    EmrHistory,
    EmrResume,
    EmrExams: () => import('./EmrExams'),
    EmrSchedule: () => import('./EmrSchedule'),
    PatientFormsList: () => import('./PatientFormsList'),
    Badge: () => import('@/components/UI/Badge'),
    EmrDuplicatedEncounterWarningModal: () => import('./EmrDuplicatedEncounterWarningModal'),
  },
  data() {
    return {
      tab: null,
      shouldLoadComponents: false,
    }
  },
  computed: {
    ...mapGetters({
      patient: 'emr/patient',
      shouldShowDuplicatedEncounterWarningModal: 'emr/shouldShowDuplicatedEncounterWarningModal',
      uncompletedFormsLength: 'patientForm/uncompletedFormsLength',
      isWorkSpaceAmparo: 'authentication/isWorkSpaceAmparo',
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
    }),
    userHasAccessToEmrForm() {
      return this.userHasAccessToFunctionality.emrForm
    },
  },
  watch: {
    '$route.params.id': function clearOldEncounter() {
      this.clearEncounter()
    },
  },
  async activated() {
    if (isEmpty(this.patient)) {
      await this.setEmrPatient(this.$route.params.id)
    }
    if (not(isEmpty(this.patient))) {
      this.shouldLoadComponents = true
    }
    this.patientForms = this.getPatientForms({
      patientId: this.patient.id,
      destiny: ['emr', 'patientAndEmr'],
    })
  },
  methods: {
    ...mapActions({
      getPatientForms: 'patientForm/getPatientForms',
      setEmrPatient: 'emr/setEmrPatient',
      clearEncounter: 'emr/clearEncounter',
    }),
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.tab__forms
  display flex
  gap 1rem
  justify-content center
  align-items center

</style>
