<template>
  <div>
    <v-navigation-drawer
      v-model="isMenuOpen"
      width="35%"
      class="px-6 py-3"
      fixed
      right
      temporary
      @input="isMenuOpen = $event"
    >
      <template>
        <div class="d-flex">
          <v-icon
            class="align-self-end ml-auto cursor--pointer"
            @click="close"
          >
            mdi-close
          </v-icon>
        </div>
        <div
          v-if="!shouldShowUserFeedback"
        >
          <div>
            <div class="text-h6 font-weight-bold d-flex info--text mb-4">
              <v-icon
                class="mr-2"
                color="info"
              >
                mdi-lifebuoy
              </v-icon>
              <span>Suporte</span>
            </div>
            <div
              class="d-flex text--disabled cursor--pointer mb-8"
              @click="openSupportPage()"
            >
              <span class="text-decoration-underline align-self-end mr-2">
                Consulte aqui nossa documentação
              </span>
              <v-icon>mdi-open-in-new</v-icon>
            </div>
          </div>
          <div class="text-h6 font-weight-bold d-flex info--text">
            <v-icon
              class="mr-2"
              color="info"
            >
              mdi-update
            </v-icon>
            <span>Atualizações do sistema</span>
          </div>
          <div
            v-if="hasAnnouncement"
            class="mb-8"
          >
            <v-card
              v-for="announcement in announcementList"
              :key="announcement.id"
              class="my-2"
              outlined
              @click="openAnnouncement(announcement)"
            >
              <v-card-text class="d-flex flex-column">
                <span class="text-h6">{{ announcement.title }}</span>
                <div>
                  <announcement-tag
                    v-if="hasTag(announcement)"
                    :tag="announcement.tag"
                  />
                </div>
                <span class="text-caption">{{ formatDate(announcement.createdAt) }}</span>
              </v-card-text>
            </v-card>
          </div>
          <div
            v-else
            class="text-caption mt-2 mb-8"
          >
            Nenhuma atualização publicada
          </div>
          <div>
            <div class="text-h6 font-weight-bold d-flex info--text mb-4">
              <v-icon
                class="mr-2"
                color="info"
              >
                mdi-comment-quote-outline
              </v-icon>
              <span>Envie um feedback</span>
            </div>
            <div
              class="d-flex text--disabled cursor--pointer mb-8"
              @click="shouldShowUserFeedback = true"
            >
              <span class="text-decoration-underline align-self-end mr-2">
                Envie um feedback sobre o Amparo Tech
              </span>
              <v-icon>mdi-open-in-new</v-icon>
            </div>
          </div>
        </div>
        <div
          v-else
        >
          <side-menu-support-user-feedback />
        </div>
      </template>
    </v-navigation-drawer>
    <v-dialog
      v-model="announcementDialog"
      max-width="700"
    >
      <v-card>
        <v-icon
          class="float-right ma-4"
          @click="announcementDialog = false"
        >
          mdi-close
        </v-icon>
        <announcement-content
          :announcement="selectedAnnouncement"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import siteMap from '@/mixins/siteMap'
import moment from 'moment'
import { isEmpty, isNil } from 'ramda'

export default {
  name: 'SideMenu',
  components: {
    AnnouncementTag: () => import('@/components/Announcement/AnnouncementTag'),
    AnnouncementContent: () => import('@/components/Announcement/AnnouncementContent'),
    SideMenuSupportUserFeedback: () => import('./SideMenuSupportUserFeedback'),
  },
  mixins: [siteMap],
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      mobileMenu: [{ description: 'Sair', icon: 'mdi-logout-variant', method: this.userLogout }],
      announcementList: [],
      selectedAnnouncement: {},
      announcementDialog: false,
      isMenuOpen: false,
      shouldShowUserFeedback: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'authentication/user',
    }),
    hasAnnouncement() {
      return !isEmpty(this.announcementList)
    },
  },
  watch: {
    value(newValue) {
      this.isMenuOpen = newValue
    },
    isMenuOpen(newValue) {
      this.$emit('input', newValue)
    },
  },
  async mounted() {
    this.announcementList = await this.getAnnouncementList()
  },
  methods: {
    ...mapActions({
      userLogout: 'authentication/userLogout',
      getAnnouncementList: 'announcement/getAnnouncementList',
    }),
    openSupportPage() {
      window.open('https://gruposabin.atlassian.net/l/cp/6fZ9T0GN', '_blank')
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    hasTag(announcement) {
      return !isNil(announcement.tag)
    },
    openAnnouncement(announcement) {
      this.selectedAnnouncement = announcement
      this.announcementDialog = !this.announcementDialog
    },
    close() {
      if (this.shouldShowUserFeedback) {
        this.shouldShowUserFeedback = false
      } else {
        this.isMenuOpen = false
      }
    },
  },
}
</script>
