import axios from '@/lib/axios'

const actions = {
  createCalculatorEquation(_, attributes) {
    return new Promise((resolve, reject) => {
      axios.post('/emr/calculator_equation', attributes)
        .then(({ data }) => resolve(data))
        .catch(reject)
    })
  },
  updateCalculatorEquation(_, { calculatorEquationId, attributes }) {
    return new Promise((resolve, reject) => {
      axios.put(`/emr/calculator_equation/${calculatorEquationId}`, attributes)
        .then(resolve)
        .catch(reject)
    })
  },
  deleteCalculatorEquation(_, calculatorEquationId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/emr/calculator_equation/${calculatorEquationId}`)
        .then(resolve)
        .catch(reject)
    })
  },
}

export default {
  actions,
}
