export default [
  {
    id: 'spe_cjyisn3y90016apvo3eu1bbas',
    name: 'Enfermeiro de família',
    cboCode: '223565',
    externalIds: null,
  },
  {
    id: 'spe_cjy92cswd001yi3voc4sjca8c',
    name: 'Médico de família',
    cboCode: '225130',
    externalIds: null,
  },
]
