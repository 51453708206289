<template>
  <div>
    <v-flex
      class="font-weight-bold"
    >
      {{
        examsToPrint
          ? 'Solicito'
          : 'Não foram solicitados exames nesta consulta.'
      }}
    </v-flex>
    <v-flex
      v-for="actualExam of examsToPrint"
      :key="actualExam.id"
      column
      mt-1
    >
      <v-flex>
        • {{ actualExam.name }} -
        <span class="text--uppercase">
          {{ actualExam.version || notFoundMessage.version }}
        </span>: {{ actualExam.code || notFoundMessage.code }}
      </v-flex>
      <v-flex
        v-if="actualExam.observation"
        class="exam__observation mt-1"
      >
        - {{ actualExam.observation }}
      </v-flex>
    </v-flex>
  </div>
</template>

<script>
import {
  has,
  head,
  isEmpty,
} from 'ramda'
import { mapGetters } from 'vuex'

export default {
  props: [
    'encounterData',
    'exam',
  ],
  computed: {
    ...mapGetters({
      notFoundMessage: 'emr/NotFoundMessage',
      requestExamsId: 'emr/requestExamsId',
    }),
    examsToPrint() {
      if (!isEmpty(head(this.exam))) {
        return this.exam
      }
      const examRequests = this.encounterData[this.requestExamsId]
      if (has('value', examRequests)) return examRequests.value
      return examRequests
    },
  },
}
</script>

<style lang="stylus" scoped>
.exam__observation
  font-style italic
  color rgba(#000, 0.5)
</style>
