<template>
  <div>
    <v-btn
      text
      large
      class="btn no-lateral-padding"
      @click="downloadPdfReport"
    >
      <v-icon
        color="japaneseLaurel"
        left
      >
        mdi-printer
      </v-icon>
      <span class="button-text-color">
        Imprimir Atendimentos
      </span>
    </v-btn>
  </div>
</template>

<script>
import axios from '@/lib/axios'
import { mapActions } from 'vuex'

export default {
  props: ['patientId'],
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
    }),
    downloadPdfReport() {
      axios
        .get(
          '/emr/encounter/pdf',
          {
            params: { patient_id: this.patientId },
            responseType: 'arraybuffer',
          },
        ).then((res) => {
          const blob = new Blob(
            [res.data],
            { type: 'application/pdf' },
          )
          const url = window.URL.createObjectURL(blob)
          const pdfTab = window.open(url, '_blank')
          pdfTab.document.write('Carregando...')
          pdfTab.location.href = url
        })
        .catch((error) => {
          const status = 'error'
          const message = this.errorMessage(error)
          this.setSnackbar({ status, message })
        })
    },
    errorMessage(error) {
      return error.response.status === 404
        ? 'Não é possível imprimir atendimentos se não houver consultas'
        : 'Ocorreu um erro'
    },
  },
}
</script>

<style lang="stylus" scoped>
.button-text-color
  color #007200
  text-decoration underline

.no-lateral-padding
  padding-left 0
  padding-right 0
</style>
