<template>
  <div class="dashboard-container">
    <div
      v-if="!isFilterEditable"
      class="filters-container lighter-green"
    >
      <v-container
        :class="{
          'container--fluid': true,
          'px-12': $vuetify.breakpoint.smAndUp,
          'grid-list-lg': true
        }"
      >
        <internal-filter
          v-if="useInternalFilter"
          :dashboard-name="dashboardName"
          @update="setAuditPanelFilters"
        />
        <v-row
          v-else-if="!isAppointmentPanelDashbord"
          justify="space-between"
        >
          <v-col
            cols="5"
          >
            <v-autocomplete
              v-model="selectedHmos"
              :items="hmos"
              item-text="name"
              item-value="name"
              label="HMOS"
              :no-data-text="notFoundMessage"
              multiple
              filled
            />
          </v-col>
          <v-col
            cols="5"
          >
            <v-btn
              color="dark-green"
              class="font-weight-lightbold ml-4"
              dark
              large
              :loading="isLoading"
              :disabled="isLoading"
              @click="getDashboardUrl()"
            >
              <v-icon left>
                mdi-magnify
              </v-icon>
              Buscar Dados
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <p
      v-if="isAppointmentPanelDashbord"
      class="pl-3 pt-5 mb-1"
    >
      Obrigatório selecionar uma Data de Agendamento.
    </p>
    <iframe
      v-if="url"
      :src="url"
      frameborder="0"
      width="100%"
      height="100%"
      allowtransparency
    />
    <h1
      v-if="!hideSelectHmoMessage"
      class="pl-12 pt-12"
    >
      Selecione uma operadora.
    </h1>
  </div>
</template>

<script>
import {
  path,
  split,
  isEmpty,
  last,
  pipe,
  find,
  propEq,
  ifElse,
  equals,
  always,
  includes,
  isNil,
} from 'ramda'
import { mapGetters, mapActions } from 'vuex'

const metabaseDashboardIds = {
  population_panel: { dashboardId: 156, isFilterEditable: false },
  operational_panel: { dashboardId: 159, isFilterEditable: false },
  patient_journey_panel: { dashboardId: 142, isFilterEditable: false },
  clinical_panel: { dashboardId: 66, isFilterEditable: false },
  performance_panel: { dashboardId: 65, isFilterEditable: false },
  wallet_panel: { dashboardId: 170, isFilterEditable: true },
  attendance_panel: { dashboardId: 171, isFilterEditable: true },
  investigation_panel: { dashboardId: 178, isFilterEditable: true },
  appointment_panel: { dashboardId: 269, isFilterEditable: true },
  audit_panel: { dashboardId: 216, isFilterEditable: false },
  outcome_panel: { dashboardId: null, isFilterEditable: false },
}

export default {
  components: {
    InternalFilter: () => import('./InternalFilter'),
  },
  data() {
    return {
      selectedHmos: [],
      notFoundMessage: 'Não encontrado',
      url: null,
      isLoading: false,
      isFilterEditable: false,
      auditPanelFilters: null,
      filters: {},
    }
  },
  computed: {
    ...mapGetters({
      user: 'authentication/user',
      hmos: 'healthMaintenanceOrganization/hmos',
    }),
    useInternalFilter() {
      return includes(this.dashboardName, ['audit_panel', 'outcome_panel'])
    },
    dashboardName() {
      return pipe(
        path(['path']),
        split('/'),
        last,
      )(this.$route)
    },
    shouldLoadMetabase() {
      return !isEmpty(this.selectedHmos)
    },
    hideSelectHmoMessage() {
      return (!this.shouldLoadMetabase && !this.isFilterEditable)
        || this.useInternalFilter
        || this.isAppointmentPanelDashbord
    },
    isAppointmentPanelDashbord() {
      return equals(this.dashboardName, 'appointment_panel')
    },
  },
  watch: {
    dashboardName: {
      handler() {
        this.getDashboardUrl()
      },
    },
  },
  async created() {
    await this.listHmo()
    if (this.isAppointmentPanelDashbord) this.getDashboardUrl()
  },
  methods: {
    ...mapActions({
      listHmo: 'healthMaintenanceOrganization/listHmo',
    }),
    setAuditPanelFilters({ filters, dashboardId }) {
      this.filters = filters
      if (!isNil(dashboardId)) {
        metabaseDashboardIds[this.dashboardName].dashboardId = dashboardId
      }
      this.getDashboardUrl()
    },
    async getDashboardUrl() {
      const { userWorkSpaces, workSpaceId } = this.user
      const { dashboardId, isFilterEditable } = metabaseDashboardIds[this.dashboardName]

      this.isFilterEditable = isFilterEditable
      this.url = null

      const { workSpace: userCurrentWorkSpace } = find(
        propEq(workSpaceId, 'workSpaceId'), userWorkSpaces,
      )

      if (this.shouldLoadMetabase || isFilterEditable || this.useInternalFilter) {
        if (!this.useInternalFilter) {
          this.filters = {
            operadora: this.selectedHmos,
            workspace: userCurrentWorkSpace.name,
          }
        }
        if (isNil(dashboardId)) return
        const { data } = await this.$http.post(
          `/dashboard/${this.dashboardName}`,
          {
            id: dashboardId,
            filters: ifElse(
              equals(true),
              always({}),
              always(this.filters),
            )(isFilterEditable),
          },
        )
        this.url = data.url
      }
    },
  },
}
</script>


<style lang='stylus' scoped>
.filters-container
  padding-top: 15px
  width 100%
  height auto

.v-btn.v-btn--large
  margin-top 0px
  height 56px
  border-radius 8px

.v-btn.v-btn--text
  border solid 1px thin-gray

.v-btn.btn-dialog
  padding 15px
  @media all and (min-width 601px)
    width 190px

.dashboard-container
  width 100%
  height 100%
</style>
