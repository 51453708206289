import axios from '@/lib/axios'

const actions = {
  getPatientImmediateCareVoucher(_, patientId) {
    return new Promise((resolve, rejectPromise) => {
      axios.get(`/patient_immediate_care_voucher/${patientId}`)
        .then(({ data: patientImmediateCareVoucher }) => {
          resolve(patientImmediateCareVoucher)
        })
        .catch(rejectPromise)
    })
  },
  createPatientImmediateCareVoucher(_, patientId) {
    return new Promise((resolve, rejectPromise) => {
      axios.post('/patient_immediate_care_voucher', { patientId })
        .then(resolve)
        .catch(rejectPromise)
    })
  },
  updatePatientImmediateCareVoucher(_, { patientId, status }) {
    return new Promise((resolve, rejectPromise) => {
      axios.patch(`/patient_immediate_care_voucher/${patientId}`, { status })
        .then(resolve)
        .catch(rejectPromise)
    })
  },
}

export default {
  namespaced: true,
  actions,
}
