import axios from '@/lib/axios'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'
import { isNil } from 'ramda'

const initialState = {
  userClinics: [],
  userSpecialities: [],
  loadingUser: false,
  userCount: 0,
  userNumberOfPages: 0,
  users: [],
}

const mutations = {
  SET_USERS(state, payload) {
    state.users = payload
  },
  SET_USER_COUNT(state, payload) {
    state.userCount = payload
  },
  SET_USER_NUMBER_OF_PAGES(state, payload) {
    state.userNumberOfPages = payload
  },
  SET_LOADING_USER(state, payload) {
    state.loadingUser = payload
  },
  SET_USER_CLINICS(state, payload) {
    state.userClinics = payload
  },
  SET_USER_SPECIALITIES(state, payload) {
    state.userSpecialities = payload
  },
}

const actions = {
  createUser({ commit }, attributes) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_USER', true)
      axios.post('user', attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_USER', false))
    })
  },
  updateUser({ commit }, { id, attributes }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_USER', true)
      axios.put(`user/${id}`, attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_USER', false))
    })
  },
  listUser({ commit }, filters = {}) {
    const params = removeNilOrEmpty(filters)
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_USER', true)
      axios.get('user', { params })
        .then(({ data: { users, count, numberOfPages } }) => {
          commit('SET_USERS', users)
          commit('SET_USER_COUNT', count)
          commit('SET_USER_NUMBER_OF_PAGES', numberOfPages)
          resolve(users)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING_USER', false))
    })
  },
  deleteUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_USER', true)
      axios.delete(`user/${id}`)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_USER', false))
    })
  },
  restoreUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_USER', true)
      axios.patch(`user/${id}/restore`)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING_USER', false))
    })
  },
  getUserById({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_USER', true)
      axios.get(`/user/${id}`)
        .then(({ data: { user } }) => {
          if (!isNil(user?.professional)) {
            commit('SET_USER_CLINICS', user.professional.clinics)
            commit('SET_USER_SPECIALITIES', user.professional.specialities)
          } else {
            commit('SET_USER_CLINICS', [])
            commit('SET_USER_SPECIALITIES', [])
          }

          resolve(user)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING_USER', false))
    })
  },
}

const getters = {
  users: state => state.users,
  userCount: state => state.userCount,
  userNumberOfPages: state => state.userNumberOfPages,
  loadingUser: state => state.loadingUser,
  userClinics: state => state.userClinics,
  userSpecialities: state => state.userSpecialities,
}

export default {
  state: initialState,
  mutations,
  actions,
  getters,
}
