<template>
  <div>
    <div class="filters-container">
      <v-container
        :class="{
          'container--fluid': true,
          'px-12': $vuetify.breakpoint.smAndUp,
          'grid-list-lg': true
        }"
      >
        <v-layout
          row
          wrap
          class="care-line__filters"
        >
          <v-flex
            xs12
            sm3
            md3
          >
            <v-text-field
              v-model="filters.identifier"
              filled
              label="Buscar por Código"
              clearable
              no-data-text="Digite para buscar"
            />
          </v-flex>
          <v-flex
            xs12
            sm3
            md3
          >
            <v-text-field
              v-model="filters.name"
              filled
              label="Buscar por Nome"
              clearable
              no-data-text
            />
          </v-flex>
          <v-flex
            xs12
            sm3
            md3
          >
            <v-autocomplete
              v-model="filters.type"
              label="Buscar por Tipo"
              clearable
              filled
              item-text="label"
              item-value="value"
              :items="careLineTypeItems"
            />
          </v-flex>
          <v-flex
            xs12
            sm3
            md3
          >
            <v-autocomplete
              v-model="filters.status"
              label="Buscar por Status"
              clearable
              filled
              :items="careLineStatusItems"
              item-text="label"
              item-value="value"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <v-container
      :class="{
        'container--fluid': true,
        'px-12': $vuetify.breakpoint.smAndUp,
      }"
    >
      <v-layout
        row
        wrap
        justify-center
      >
        <v-flex
          xs12
          sm4
        >
          <v-btn
            class="text-border my-4 font-weight-lightbold"
            color="primary"
            block
            large
            @click="showCreateCareLineDialog = true"
          >
            Nova Linha de Cuidado
          </v-btn>
        </v-flex>
      </v-layout>
      <v-data-table
        :headers="headers"
        :items="restOfCareLines"
        hide-default-footer
        disable-pagination
        class="data-table__header"
        no-data-text="Sem itens para exibir, clique no botão acima para começar a criar"
        @change="listCareLine"
        @click:clear="listCareLine"
      >
        <template #item="{ item }">
          <care-line-list-item
            :care-line="item"
            @edit="editDialog"
            @disable="disableDialog"
            @reactivate="reactivateCareLine"
          />
        </template>
      </v-data-table>
      <v-expansion-panels
        v-model="panels"
        popout
        class="mt-4"
      >
        <template v-for="[key, list] in careLinesByStatus">
          <v-expansion-panel
            v-if="itHas(list) && getPanelTitleBy(key)"
            :key="key"
            class="panel-header"
          >
            <v-expansion-panel-header>
              <h3>{{ getPanelTitleBy(key) }}</h3>
            </v-expansion-panel-header>
            <template #actions>
              <v-icon color="japaneseLaurel">
                $vuetify.icons.expand
              </v-icon>
            </template>
            <v-expansion-panel-content>
              <v-card>
                <v-card-text>
                  <v-data-table
                    :headers="headers"
                    :items="list"
                    hide-default-footer
                    disable-pagination
                    class="data-table__header"
                    no-data-text="Sem itens para exibir, clique no botão acima para começar a criar"
                  >
                    <template #item="{ item }">
                      <care-line-list-item
                        :care-line="item"
                        @edit="editDialog"
                        @disable="disableDialog"
                        @reactivate="reactivateCareLine"
                      />
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>
      </v-expansion-panels>
    </v-container>

    <v-dialog
      v-model="showEditCareLineDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
    >
      <v-card>
        <v-card-title
          class="card-dialog__title"
        >
          Editar Linha de Cuidado
          <v-spacer />
          <v-btn
            icon
            @click="showEditCareLineDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <create-care-line
            v-if="showEditCareLineDialog"
            edit-care-line
            :care-line-data="editedCareLine"
            @exitEditDialog="exitEditDialog"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showCreateCareLineDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
    >
      <v-card>
        <v-card-title class="card-dialog__title">
          Cadastrar Linha de Cuidado
          <v-spacer />
          <v-btn
            icon
            @click="showCreateCareLineDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <create-care-line
          v-if="showCreateCareLineDialog"
          @closeCreateCareLineDialog="exitCreateDialog"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showDisableCareLineDialog"
      class="not-printable-area"
      max-width="450"
    >
      <v-card>
        <v-card-text>
          Essa ação irá desativar a Linha de Cuidado
          e não permitirá atribuição da mesma a um diagnóstico.
          <p>
            Deseja Continuar?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="btn-dialog font-weight-lightbold"
            color="japaneseLaurel"
            text
            large
            @click="exitDisableDialog"
          >
            Não
          </v-btn>
          <v-spacer />
          <v-btn
            class="btn-dialog font-weight-lightbold"
            large
            color="error"
            @click="disableCareLine"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  always,
  cond,
  descend,
  fromPairs,
  groupBy,
  isNil,
  not,
  prop,
  propEq,
  sort,
  T,
  toPairs,
  values,
} from 'ramda'
import { CARE_LINE } from 'amparo-enums'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CareLineList',
  components: {
    CreateCareLine: () => import('./CreateCareLine'),
    CareLineListItem: () => import('./CareLineListItem'),
  },
  data() {
    return {
      notFoundMessage: 'Não encontrado',
      showEditCareLineDialog: false,
      editedCareLine: {},
      disabledCareline: {},
      filters: {},
      listLoaded: true,
      count: '',
      numberOfPages: null,
      page: 1,
      panels: [true, true],
      headers: [
        {
          text: 'Código',
          value: 'identifier',
          width: '20%',
        },
        {
          text: 'Nome',
          value: 'name',
          width: '30%',
        },
        {
          text: 'Tipo',
          value: 'type',
          width: '20%',
        },
        {
          text: 'Status',
          value: 'status',
          width: '18%',
        },
        {
          text: 'Prioridade',
          value: 'isPrimary',
          width: '18%',
        },
        {
          text: 'Ações',
          value: 'action',
          width: '12%',
          sortable: false,
        },
      ],
      careLineTypeItems: values(CARE_LINE.types),
      careLineStatusItems: values(CARE_LINE.status),
      showCreateCareLineDialog: false,
      showDisableCareLineDialog: false,
    }
  },
  computed: {
    ...mapGetters({
      careLineList: 'careLine/careLines',
    }),
    careLinesByStatus() {
      const groupedList = groupBy(
        cond([
          [propEq(CARE_LINE.status.model.value, 'status'), always('model')],
          [propEq(CARE_LINE.status.inactive.value, 'status'), always('inactive')],
          [T, always('rest')],
        ]),
        this.careLineList,
      )

      return sort(descend(prop(0)), toPairs(groupedList))
    },
    restOfCareLines() {
      return prop('rest', fromPairs(this.careLinesByStatus))
    },
  },
  watch: {
    filters: {
      deep: true,
      async handler() {
        await this.searchCareLine()
      },
    },
  },
  mounted() {
    this.listCareLine()
  },
  methods: {
    ...mapActions({
      listCareLine: 'careLine/listCareLine',
      updateCareLine: 'careLine/updateCareLine',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    itHas(list) {
      return not(isNil(list))
    },
    getPanelTitleBy(key) {
      const titles = {
        model: 'Linhas modelo',
        inactive: 'Linhas inativas',
      }

      return titles[key]
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    async disableCareLine() {
      try {
        await this.updateCareLine({
          careLineId: this.disabledCareline.id,
          careLineData: {
            status: 'inactive',
          },
        })
        this.buildSnackbar('success', 'Linha de Cuidado desativada com sucesso!')
        this.listCareLine()
      } catch ({ response }) {
        this.handleError(response)
      }
      this.disabledCareline = {}
      this.showDisableCareLineDialog = false
    },
    async reactivateCareLine(careLineData) {
      try {
        await this.updateCareLine({
          careLineId: careLineData.id,
          careLineData: {
            status: 'active',
          },
        })
        this.buildSnackbar('success', 'Linha de Cuidado reativada com sucesso!')
        this.listCareLine()
      } catch ({ response }) {
        this.handleError(response)
      }
    },
    handleError(error) {
      const errorMessage = cond([
        [T, always('Erro Interno')],
      ])(error.data.errorCode)
      this.buildSnackbar('error', errorMessage)
    },
    async searchCareLine() {
      this.listCareLine(this.filters)
    },
    editDialog(careLineData) {
      this.editedCareLine = careLineData
      this.showEditCareLineDialog = true
    },
    async exitEditDialog() {
      this.editedCareLine = {}
      this.showEditCareLineDialog = false
    },
    async exitCreateDialog() {
      this.showCreateCareLineDialog = false
    },
    disableDialog(careLine) {
      this.disabledCareline = careLine
      this.showDisableCareLineDialog = true
    },
    exitDisableDialog() {
      this.disabledCareline = {}
      this.showDisableCareLineDialog = false
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'
.filters-container
  width 100%
  height auto
  background-color #e8f8e8
  @media only screen and (min-width 601px)
    height 108px
.results-container
  margin-top -25px
  width 100%
  background-color #e8f8e8
.v-card
  padding 10px
  border-radius 6px
.v-btn
  letter-spacing 1px
.v-btn.v-btn--large
  margin-top 0px
  height 56px
  border-radius 8px
.v-btn.v-btn--text
  border solid 1px thin-gray
.v-btn.btn-dialog
  padding 15px
  @media all and (min-width 601px)
    width 190px
.panel-header
  background-color rgba(light-gray, .6) !important
  color $japanese-laurel !important
.care-line__filters
  margin-top 5px !important
</style>
