<template>
  <div>
    <v-layout
      d-flex
      column
    >
      <v-flex
        v-for="({ imgURI }, index) of uriList"
        :key="index"
        class="chart-image"
      >
        <img :src="imgURI">
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: [
    'uriList',
  ],
}
</script>

<style lang="stylus" scoped>

@media print
  .chart-image
    margin-top 5%
    & img
      max-width 20cm
    &:not(:first-child)
      margin-top 55%

</style>
