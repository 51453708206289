import axios from '@/lib/axios'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'
import {
  propEq,
  filter,
  reject,
  not,
  isNil,
} from 'ramda'

export default {
  state: {
    loadingCareLineAction: false,
    careLineActions: [],
    careLineActionsFiltered: [],
    careLineActionFilters: {
      storedAt: false,
    },
  },
  mutations: {
    SET_LOADING_CARE_LINE_ACTION(state, payload) {
      state.loadingCareLineAction = payload
    },
    SET_CARE_LINE_ACTIONS(state, payload) {
      state.careLineActions = payload
    },
    SET_CARE_LINE_ACTIONS_FILTERED(state, payload) {
      state.careLineActionsFiltered = payload
    },
    SET_CARE_LINE_ACTION_FILTERS(state, payload) {
      state.careLineActionFilters = payload
    },
  },
  actions: {
    listCareLineAction({ commit, dispatch, state }) {
      return new Promise((resolve, rejected) => {
        commit('SET_LOADING_CARE_LINE_ACTION', true)
        axios.get('emr/care_line_action', {
          params: {
            careLineId: state.careLine.id,
          },
        })
          .then(({ data: { careLineActions } }) => {
            commit('SET_CARE_LINE_ACTIONS', careLineActions)
            dispatch('listCareLineActionFiltered')
            resolve(careLineActions)
          })
          .catch(rejected)
          .finally(() => commit('SET_LOADING_CARE_LINE_ACTION', false))
      })
    },
    createCareLineAction({ commit, state }, attributes) {
      return new Promise((resolve, rejected) => {
        commit('SET_LOADING_CARE_LINE_ACTION', true)
        axios.post('emr/care_line_action', {
          ...attributes,
          careLineId: state.careLine.id,
        })
          .then(({ data }) => resolve(data))
          .catch(rejected)
          .finally(() => commit('SET_LOADING_CARE_LINE_ACTION', false))
      })
    },
    updateCareLineAction({ commit }, { id, attributes }) {
      return new Promise((resolve, rejected) => {
        commit('SET_LOADING_CARE_LINE_ACTION', true)
        axios.put(`emr/care_line_action/${id}`, attributes)
          .then(({ data }) => resolve(data))
          .catch(rejected)
          .finally(() => commit('SET_LOADING_CARE_LINE_ACTION', false))
      })
    },
    editCareLineAction({ commit }, { id, attributes }) {
      return new Promise((resolve, rejected) => {
        commit('SET_LOADING_CARE_LINE_ACTION', true)
        axios.patch(`emr/care_line_action/${id}`, attributes)
          .then(({ data }) => resolve(data))
          .catch(rejected)
          .finally(() => commit('SET_LOADING_CARE_LINE_ACTION', false))
      })
    },
    setCareLineActionFilters({ commit }, filters) {
      commit('SET_CARE_LINE_ACTION_FILTERS', { ...filters })
    },
    listCareLineActionFiltered({ commit, state }) {
      const filters = removeNilOrEmpty(state.careLineActionFilters)
      const actions = state.careLineActions

      const filteredActions = propEq(false, 'storedAt', filters)
        ? filter(propEq(null, 'storedAt'), actions)
        : reject(propEq(null, 'storedAt'), actions)

      if (not(isNil(filters.action))) {
        const newFilteredActions = filter(propEq(filters.action, 'action'), filteredActions)
        commit('SET_CARE_LINE_ACTIONS_FILTERED', newFilteredActions)
        return
      }

      commit('SET_CARE_LINE_ACTIONS_FILTERED', filteredActions)
    },
  },
  getters: {
    isLoadingCareLineAction: state => state.loadingCareLineAction,
    careLineActions: state => state.careLineActions,
    careLineActionsFiltered: state => state.careLineActionsFiltered,
    careLineActionConditions: state => state.careLineActionConditions,
    careLineActionFilters: state => state.careLineActionFilters,
  },
}
