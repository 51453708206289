import { read, utils } from 'xlsx'
import {
  all,
  isNil,
  not,
  reduce,
} from 'ramda'

const uploadContractItems = async (file) => {
  const workbook = read(await file[0].arrayBuffer())

  const sheetName = workbook.SheetNames[0]

  const worksheet = workbook.Sheets[sheetName]

  const itemsList = utils.sheet_to_json(worksheet, { header: 1 })

  itemsList.shift()

  return reduce((acc, item) => {
    const isLineEmpty = all(isNil, item)

    const [
      name,
      externalName,
      tussCode,
      amparoCode,
      price,
      externalCode,
      clinicName,
      returnalDays,
      hasReturnalByCid,
      returnalWithSameProfessional,
      defaultSlots,
      diseaseGroupNames,
      ageRangesExtendedSlot,
    ] = item

    if (not(isLineEmpty)) {
      acc.push({
        name: name ? String(name) : undefined,
        externalName: externalName ? String(externalName) : undefined,
        tussCode: tussCode ? String(tussCode) : undefined,
        amparoCode: amparoCode ? String(amparoCode) : undefined,
        price: Number((price * 100).toFixed(0)),
        externalCode: externalCode ? String(externalCode) : undefined,
        clinicName: clinicName ? String(clinicName) : undefined,
        returnalDays: Number(returnalDays),
        hasReturnalByCid: hasReturnalByCid ? String(hasReturnalByCid) : undefined,
        returnalWithSameProfessional: returnalWithSameProfessional
          ? String(returnalWithSameProfessional)
          : undefined,
        defaultSlots: Number(defaultSlots),
        diseaseGroupNames: diseaseGroupNames ? String(diseaseGroupNames) : undefined,
        ageRangesExtendedSlot: ageRangesExtendedSlot ? String(ageRangesExtendedSlot) : undefined,
      })
    }

    return acc
  }, [], itemsList)
}

export default uploadContractItems
