import axios from '@/lib/axios'

const actions = {
  updateAnnouncement(_, id) {
    return axios.patch(`/announcement/${id}`)
  },
  createAnnouncement(_, announcement) {
    return axios.post('/announcement', announcement)
  },
  async getAnnouncementList() {
    const { data } = await axios.get('/announcement')
    return data
  },
}

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions,
}
