<template>
  <div>
    <div class="filters-container lighter-green">
      <v-container
        :class="{
          'container--fluid': true,
          'px-12': $vuetify.breakpoint.smAndUp,
          'grid-list-lg': true
        }"
      >
        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
            sm4
            md9
          >
            <v-text-field
              v-model="filters.name"
              filled
              label="Buscar por nome"
              clearable
              no-data-text="Digite para buscar"
              @change="listPermission(filters)"
              @click:clear="listPermission"
            />
          </v-flex>
          <v-flex
            xs12
            sm8
            md3
          >
            <v-btn
              color="dark-green"
              class="btn-filter--height font-weight-lightbold"
              dark
              block
              large
            >
              <v-icon left>
                mdi-magnify
              </v-icon>
              Buscar Permissão
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <v-container
      :class="{
        'container--fluid': true,
        'px-12': $vuetify.breakpoint.smAndUp,
      }"
    >
      <v-layout
        v-if="isPermissionsEmpty"
      >
        <h2 class="info-text font-weight-lighter">
          Nenhum resultado encontrado.
        </h2>
      </v-layout>
      <template v-else>
        <v-row
          v-for="permission of permissions"
          :key="permission.id"
          :class="{
            'list-item': true,
            'list-item__deleted': isPermissionDeleted(permission),
          }"
        >
          <v-col
            md11
            sm11
            xs12
          >
            <div class="truncate">
              <span class="list-item__title">
                {{ permission.name }}
              </span>
            </div>
          </v-col>

          <v-col
            v-if="!isPermissionDeleted(permission)"
            cols="auto"
            class="d-flex align-center justify-center pa-0"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mr-2"
                  text
                  icon
                  :disabled="canEditPermission(permission)"
                  @click="editDialog(permission)"
                  v-on="on"
                >
                  <v-icon>mdi-account-edit</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mr-2"
                  text
                  icon
                  @click="migrateDialog(permission)"
                  v-on="on"
                >
                  <v-icon>mdi-swap-horizontal</v-icon>
                </v-btn>
              </template>
              <span>Migrar usuários dessa permissão</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  icon
                  color="error"
                  @click="deleteDialog(permission)"
                  v-on="on"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </v-col>
          <v-col
            v-else
            cols="auto"
            class="d-flex align-center justify-center pa-0"
          >
            <v-chip
              class="mr-2"
              color="error"
              disabled
              text-color="white"
            >
              Arquivada
            </v-chip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  icon
                  @click="handleRestorePermission(permission)"
                  v-on="on"
                >
                  <v-icon>mdi-restore</v-icon>
                </v-btn>
              </template>
              <span>Restaurar</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
    </v-container>
    <v-dialog
      v-model="showEditPermissionDialog"
      transition="dialog-bottom-transition"
      fullscreen
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Editar Permissão</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="exitEditDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <create-permission
          v-if="showEditPermissionDialog"
          edit-permission
          :permission-data="editedPermission"
          @exitEditDialog="exitEditDialog"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showDeletePermissionDialog"
      max-width="650"
    >
      <v-card
        class="pa-3"
      >
        <v-card-title class="font-weight-bold">
          <h2>
            Atenção!
          </h2>
        </v-card-title>

        <v-card-text class="text-body-1 black--text">
          <p>
            Ao concluir esta ação, a permissão <strong>{{ deletedPermission.name }}</strong>
            será deletada. Esta ação é reversível.
          </p>

          <p class="mb-0">
            Deseja realmente continuar com a exclusão?
          </p>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn
                class="font-weight-lightbold"
                color="green darken-1"
                text
                block
                @click="exitDeleteDialog"
              >
                Cancelar
              </v-btn>
            </v-col>

            <v-col>
              <v-btn
                class="font-weight-lightbold"
                block
                color="error"
                @click="handleDeletePermission"
              >
                Deletar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showMigratePermissionDialog"
      max-width="650"
    >
      <v-card
        class="pa-3"
      >
        <v-card-title class="font-weight-bold">
          <h2>
            Atenção!
          </h2>
        </v-card-title>

        <v-card-text class="text-body-1 black--text">
          <p>
            Ao concluir esta ação, <strong>todos</strong> os usuários da permissão
            <strong>{{ migratedPermission.name }}</strong>
            seram migrados para a permissão de destino.
          </p>

          <v-select
            v-model="destinationPermissionId"
            :items="permissions"
            item-text="name"
            item-value="id"
            dense
            clearable
          />

          <p class="mb-0">
            Deseja realmente continuar com a migração?
          </p>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn
                class="font-weight-lightbold"
                color="green darken-1"
                text
                block
                @click="exitMigrateDialog"
              >
                Cancelar
              </v-btn>
            </v-col>

            <v-col>
              <v-btn
                class="font-weight-lightbold"
                block
                color="error"
                :disabled="!destinationPermissionId"
                @click="handleMigratePermission"
              >
                Migrar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  isEmpty,
  equals,
  clone,
} from 'ramda'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PermissionList',
  components: {
    CreatePermission: () => import('./CreatePermission'),
  },
  data() {
    return {
      showEditPermissionDialog: false,
      showDeletePermissionDialog: false,
      showMigratePermissionDialog: false,
      migratedPermission: {},
      destinationPermissionId: null,
      editedPermission: {},
      deletedPermission: {},
      filters: {},
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'authentication/permissions',
      user: 'authentication/user',
    }),
    isPermissionsEmpty() {
      return isEmpty(this.permissions)
    },
  },
  mounted() {
    this.listPermission({ returnDeleted: true })
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
      listPermission: 'authentication/listPermission',
      deletePermission: 'authentication/deletePermission',
      restorePermission: 'authentication/restorePermission',
      migratePermission: 'authentication/migratePermission',
    }),
    isPermissionDeleted(permission) {
      return permission.deletedAt
    },
    canEditPermission({ id }) {
      return equals(id, this.user.permission.id)
    },
    editDialog(permissionData) {
      this.editedPermission = clone(permissionData)
      this.showEditPermissionDialog = true
    },
    deleteDialog(permissionData) {
      this.deletedPermission = clone(permissionData)
      this.showDeletePermissionDialog = true
    },
    migrateDialog(permissionData) {
      this.migratedPermission = clone(permissionData)
      this.showMigratePermissionDialog = true
    },
    exitEditDialog() {
      this.editedPermission = {}
      this.showEditPermissionDialog = false
    },
    exitDeleteDialog() {
      this.deletedPermission = {}
      this.showDeletePermissionDialog = false
    },
    exitMigrateDialog() {
      this.migratedPermission = {}
      this.showMigratePermissionDialog = false
    },
    async handleDeletePermission() {
      try {
        await this.deletePermission(this.deletedPermission.id)
        this.listPermission({ returnDeleted: true })
        this.exitDeleteDialog()
      } catch (error) {
        if (error?.response?.data?.errorCode === 'permission_in_use') {
          this.setSnackbar({
            status: 'error',
            message: 'Permissão em uso, não é possível deletar',
          })
          return
        }
        this.setSnackbar({
          status: 'error',
          message: 'Erro ao deletar permissão',
        })
      }
    },
    async handleRestorePermission(permission) {
      try {
        await this.restorePermission(permission.id)
        this.listPermission({ returnDeleted: true })
      } catch (error) {
        this.setSnackbar({
          status: 'error',
          message: 'Erro ao restaurar permissão',
        })
      }
    },
    async handleMigratePermission() {
      try {
        const res = await this.migratePermission({
          oldPermissionId: this.migratedPermission.id,
          newPermissionId: this.destinationPermissionId,
        })
        this.setSnackbar({
          status: 'success',
          message: `${res.data.totalUpdated} usuários migrados com sucesso`,
        })
        this.listPermission({ returnDeleted: true })
        this.exitMigrateDialog()
      } catch (error) {
        this.setSnackbar({
          status: 'error',
          message: 'Erro ao migrar permissão',
        })
      }
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'

.list-item
  width 100%
  padding 20px
  border-radius 8px
  border solid 1px #e0e0e0
  margin-bottom 10px

.list-item__deleted
  padding 2px 20px

.list-item__title
  font-size 24px
  color rgba(0, 0, 0, 0.87)
.list-item__icon
  margin-top -8px

.truncate
  @media only screen and (min-width: 601px)
    width 60vw

.info-text
  letter-spacing -0.5px
  color rgba(0, 0, 0, 0.38)

  @media only screen and (min-width 601px)
    font-size 32px

  @media only screen and (min-width 1201px)
    font-size 48px

  @media only screen and (min-width 1601px)
    font-size 64px

.filters-container
  width 100%
  height auto
  margin-bottom 20px

  @media only screen and (min-width 601px)
    height 108px
.v-card
  padding 10px
  border-radius 6px

.v-btn
  letter-spacing 1px

.v-btn.v-btn--large
  margin-top 0px
  height 56px
  border-radius 8px

.v-btn.v-btn--text
  border solid 1px thin-gray

.v-btn.btn-dialog
  padding 15px
  @media all and (min-width 601px)
    width 190px
</style>
