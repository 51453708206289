export default [
  {
    itemId: 'ite_cl44iaubu000077zu8hcodjgm',
    id: 'con_ite_cl48vhoqe0000zlzu2ny29db0',
    externalName: 'CONSULTA ENFERMAGEM REMOTA',
    externalCode: '50000705',
    price: 10000,
    clinicId: null,
    defaultSlots: 1,
    ageRangesExtendedSlot: [],
    item: {
      id: 'ite_cl44iaubu000077zu8hcodjgm',
      name: 'CONSULTA ENFERMAGEM REMOTA',
      tussCode: '50000705',
      amparoCode: 38,
      itemMnemonics: [],
    },
    clinic: null,
    contractItemGroups: [],
  },
  {
    itemId: 'ite_cl44iaubz000177zuaqb0dkpk',
    id: 'con_ite_cl48vhoqj0001zlzugo1nfvff',
    externalName: 'CONSULTA ENFERMAGEM PRESENCIAL',
    externalCode: '50000705',
    price: 10000,
    clinicId: null,
    defaultSlots: 1,
    ageRangesExtendedSlot: [],
    item: {
      id: 'ite_cl44iaubz000177zuaqb0dkpk',
      name: 'CONSULTA ENFERMAGEM PRESENCIAL',
      tussCode: '50000705',
      amparoCode: 39,
      itemMnemonics: [],
    },
    clinic: null,
    contractItemGroups: [],
  },
  {
    itemId: 'ite_cl44iaubz000377zu6jy17vst',
    id: 'con_ite_cl48vhoqk000fzlzu7usl9zf8',
    externalName: 'CONSULTA MÉDICA MFC PRESENCIAL',
    externalCode: '10101012',
    price: 10000,
    clinicId: null,
    defaultSlots: 2,
    ageRangesExtendedSlot: [],
    item: {
      id: 'ite_cl44iaubz000377zu6jy17vst',
      name: 'CONSULTA MÉDICA MFC PRESENCIAL',
      tussCode: '10101012',
      amparoCode: 53,
      itemMnemonics: [],
    },
    clinic: null,
    contractItemGroups: [],
  },
  {
    itemId: 'ite_cl44iaubz000277zu4tcq20kf',
    id: 'con_ite_cl48vhoqq003lzlzucic7g1g6',
    externalName: 'CONSULTA MÉDICA MFC REMOTA',
    externalCode: '10101012',
    price: 10000,
    clinicId: null,
    defaultSlots: 1,
    ageRangesExtendedSlot: [],
    item: {
      id: 'ite_cl44iaubz000277zu4tcq20kf',
      name: 'CONSULTA MÉDICA MFC REMOTA',
      tussCode: '10101012',
      amparoCode: 167,
      itemMnemonics: [],
    },
    clinic: null,
    contractItemGroups: [],
  },
  {
    itemId: 'ite_cl45uskk20007t8szfdaq8w7o',
    id: 'con_ite_cl48vimrt000604zu9ctj8gls',
    externalName: 'CONSULTA MÉDICA PA REMOTA',
    externalCode: '10101012',
    price: 10000,
    clinicId: null,
    defaultSlots: 1,
    ageRangesExtendedSlot: [],
    item: {
      id: 'ite_cl45uskk20007t8szfdaq8w7o',
      name: 'CONSULTA MÉDICA PA REMOTA',
      tussCode: '10101012',
      amparoCode: 174,
      itemMnemonics: [],
    },
    clinic: null,
    contractItemGroups: [],
  },
  {
    itemId: 'ite_cl44iauc6003277zu5fp2frrn',
    id: 'con_ite_cl48vhoqp0031zlzue3adb2sd',
    externalName: 'CURATIVOS EM GERAL SEM ANESTESIA EXCETO QUEIMADOS',
    externalCode: '20104103',
    price: 10000,
    clinicId: null,
    defaultSlots: 1,
    ageRangesExtendedSlot: [],
    item: {
      id: 'ite_cl44iauc6003277zu5fp2frrn',
      name: 'CURATIVOS EM GERAL SEM ANESTESIA EXCETO QUEIMADOS',
      tussCode: '20104103',
      amparoCode: 147,
      itemMnemonics: [],
    },
    clinic: null,
    contractItemGroups: [],
  },
  {
    itemId: 'ite_cl44iauc7003d77zugibw8r9u',
    id: 'con_ite_cl48vhoqq003czlzugy2v9qhw',
    externalName: 'INCISÃO E DRENAGEM DE ABSCESSO HEMATOMA OU PANARÍCIO',
    externalCode: '30101620',
    price: 10000,
    clinicId: null,
    defaultSlots: 1,
    ageRangesExtendedSlot: [],
    item: {
      id: 'ite_cl44iauc7003d77zugibw8r9u',
      name: 'INCISÃO E DRENAGEM DE ABSCESSO HEMATOMA OU PANARÍCIO',
      tussCode: '30101620',
      amparoCode: 158,
      itemMnemonics: [],
    },
    clinic: null,
    contractItemGroups: [],
  },
  {
    itemId: 'ite_cl44iauc7003f77zu25qo3ihb',
    id: 'con_ite_cl48vhoqq003ezlzu789x4aj0',
    externalName: 'INSERCAÇÃO DE DIU HORMONAL*',
    externalCode: '31303293',
    price: 10000,
    clinicId: null,
    defaultSlots: 1,
    ageRangesExtendedSlot: [],
    item: {
      id: 'ite_cl44iauc7003f77zu25qo3ihb',
      name: 'INSERCAÇÃO DE DIU HORMONAL*',
      tussCode: '31303293',
      amparoCode: 160,
      itemMnemonics: [],
    },
    clinic: null,
    contractItemGroups: [],
  },
  {
    itemId: 'ite_cl44iauc7003g77zufos62h9e',
    id: 'con_ite_cl48vhoqq003fzlzu9xb0enqo',
    externalName: 'INSERCAÇÃO DE DIU NÃO HORMONAL*',
    externalCode: '31303269',
    price: 10000,
    clinicId: null,
    defaultSlots: 1,
    ageRangesExtendedSlot: [],
    item: {
      id: 'ite_cl44iauc7003g77zufos62h9e',
      name: 'INSERCAÇÃO DE DIU NÃO HORMONAL*',
      tussCode: '31303269',
      amparoCode: 161,
      itemMnemonics: [],
    },
    clinic: null,
    contractItemGroups: [],
  },
  {
    itemId: 'ite_cl44iauc7003h77zuc9bl6v24',
    id: 'con_ite_cl48vhoqq003gzlzuheyw3rv2',
    externalName: 'PROVA DO LACO',
    externalCode: '40304531',
    price: 10000,
    clinicId: null,
    defaultSlots: 1,
    ageRangesExtendedSlot: [],
    item: {
      id: 'ite_cl44iauc7003h77zuc9bl6v24',
      name: 'PROVA DO LACO',
      tussCode: '40304531',
      amparoCode: 162,
      itemMnemonics: [],
    },
    clinic: null,
    contractItemGroups: [],
  },
]
