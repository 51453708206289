import {
  reject,
  or,
  converge,
  isNil,
  isEmpty,
} from 'ramda'

export default function removeNilOrEmpty(list) {
  return reject(converge(or, [isNil, isEmpty]), list)
}
