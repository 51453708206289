<template>
  <v-autocomplete
    v-model="selectedPatient"
    :loading="isLoadingPatient"
    :items="patientList"
    :search-input.sync="searchPatientField"
    :no-filter="true"
    item-text="name"
    item-value="id"
    v-bind="$props"
    no-data-text="Digite para buscar"
    data-testid="search-patient__select--autocomplete"
    @blur="$emit('blur')"
    @click:clear="clearField()"
  >
    <template v-slot:prepend-item>
      <v-flex
        class="container-filter"
      >
        <v-btn
          v-for=" { name, filterLabel } of Filter"
          :key="name"
          class="mx-2"
          depressed
          small
          :color="getButtonColor(name)"
          @click="changeFilter(name)"
        >
          {{ filterLabel }}
        </v-btn>
      </v-flex>
    </template>
    <template v-slot:item="{ item, on }">
      <v-list-item
        :data-testid="`search-patient__patient-${item.id}--button`"
        v-on="on"
        @click="emitPatient(item)"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ item | formatDisplayName }}
          </v-list-item-title>
          <v-list-item-subtitle class="patient-data">
            {{ item.birthDate | formatDate }} -
            <span v-if="isEmptyCpf(item.cpf)">
              {{ item.foreignDocument }} (Passaporte)
            </span>
            <span v-else>
              {{ item.cpf | formatCpf }} (CPF)
            </span>
          </v-list-item-subtitle>
          <v-list-item-subtitle class="patient-data">
            {{ hasHealthInsurancePlan(item) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
<script>
import {
  debounce,
} from 'lodash'
import {
  equals,
  find,
  isEmpty,
  isNil,
  join,
  length,
  path,
  pipe,
  propEq,
  reject,
  replace,
  startsWith,
} from 'ramda'

export default {
  props: {
    label: {
      type: String,
      default: 'Buscar paciente',
    },
    noDataText: {
      type: String,
      default: 'Digite para buscar',
    },
    solo: {
      type: Boolean,
    },
    flat: {
      type: Boolean,
    },
    appendIcon: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    filled: {
      type: Boolean,
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: undefined,
    },
    errorMessages: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedPatient: {},
      patientList: [],
      selectedFilter: null,
      isLoadingPatient: false,
      searchPatientField: null,
      isShowingDefault: false,
      Filter: [
        { name: 'name', filterLabel: 'NOME' },
        { name: 'healthInsurancePlan', filterLabel: 'CARTEIRA' },
        { name: 'cpf', filterLabel: 'CPF' },
        { name: 'email', filterLabel: 'EMAIL' },
        { name: 'foreignDocument', filterLabel: 'PASSAPORTE' },
        { name: 'id', filterLabel: 'ID PRONTUÁRIO' },
      ],
    }
  },
  watch: {
    searchPatientField(keyword) {
      if (isNil(this.searchPatientField) && this.clearable) {
        this.$emit('input', null)
      }
      if (this.isShowingDefault) return
      if (this.isKeywordInvalid()) {
        this.patientList = []
      }

      this.searchAnythingByKeyword(keyword)
    },
  },
  methods: {
    clearField() {
      this.searchPatientField = null
    },
    hasHealthInsurancePlan(patient) {
      const planNickName = path(['healthMaintenanceOrganization', 'nickName'], patient)
      const planNumber = patient.healthInsurancePlanNumber
      return pipe(reject(isNil), join(' - '))([planNickName, planNumber]) || '-'
    },
    clearSearchResults() {
      this.patientList = []
    },
    setFilter(searchFilter) {
      this.selectedFilter = searchFilter
    },
    isEmailAddress(str) {
      const pattern = /^\w+[@]+?/
      return str.match(pattern)
    },
    buildSearchAnythingParams(keyword) {
      if (equals('foreignDocument', this.selectedFilter)) {
        const cleanKeyword = keyword ? replace(/[/.-]/g, '', keyword) : ''
        return { foreignDocument: cleanKeyword }
      }
      if (parseInt(keyword, 10)) {
        const cleanKeyword = keyword ? replace(/[/.-]/g, '', keyword) : ''
        if (equals(this.selectedFilter, 'healthInsurancePlan')) return { healthInsurancePlanNumber: cleanKeyword }
        this.selectedFilter = find(propEq('cpf', 'name'))(this.Filter).name
        return { cpf: cleanKeyword }
      }
      if (this.isEmailAddress(keyword)) {
        this.selectedFilter = find(propEq('email', 'name'))(this.Filter).name
        return { email: keyword }
      }
      if (startsWith('pat_', keyword)) {
        this.selectedFilter = find(propEq('id', 'name'))(this.Filter).name
        return { id: keyword }
      }
      this.selectedFilter = find(propEq('name', 'name'))(this.Filter).name
      return {
        name: keyword,
        socialName: keyword,
      }
    },
    searchAnythingByKeyword: debounce(function searchAnythingByKeyword(keyword) {
      if (this.isKeywordInvalid(keyword)) return
      this.clearSearchResults()
      this.isLoadingPatient = true
      const params = this.buildSearchAnythingParams(keyword)
      this.$http.get('patient/search', { params })
        .then((res) => {
          this.patientList = res.data.patients
        })
        .catch(err => err)
        .finally(() => { this.isLoadingPatient = false })
    }, 500),
    emitPatient(event) {
      this.$emit('input', event)
    },
    changeFilter(searchFilter) {
      this.selectedFilter = searchFilter
      this.searchAnythingByKeyword(this.searchPatientField)
    },
    getButtonColor(nameButton) {
      if (equals(nameButton, this.selectedFilter)) return 'primary'
      return ''
    },
    isEmptyCpf(cpf) {
      return isEmpty(cpf)
    },
    isKeywordInvalid(keyword) {
      return isNil(keyword)
        || isEmpty(keyword)
        || length(keyword) < 3
        || equals(keyword.search(/[A-Za-z1-9]/g), -1)
    },
  },
}
</script>
<style lang="stylus" scoped>
  .patient-data
    font-size 13px
  .container-filter
    max-width 800px
    margin 5px 0px
    flex-wrap wrap
</style>
