<template>
  <div>
    <component-title />
    <patient-radar-container />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import PatientRadarContainer from './PatientRadarContainer'

export default {
  components: {
    ComponentTitle,
    PatientRadarContainer,
  },
}
</script>
