<template>
  <div>
    <div class="filters-container">
      <v-container
        :class="{
          'container--fluid': true,
          'px-12': $vuetify.breakpoint.smAndUp,
          'grid-list-lg': true
        }"
      >
        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
            sm3
            md3
          >
            <v-text-field
              v-model="filters.label"
              filled
              label="Buscar por título"
              clearable
              no-data-text="Digite para buscar"
              @change="listQuestionResults"
              @click:clear="listQuestionResults"
            />
          </v-flex>
          <v-flex
            xs12
            sm3
            md3
          >
            <v-text-field
              v-model="filters.identifier"
              filled
              label="Buscar por código"
              clearable
              no-data-text="Digite para buscar"
              @change="listQuestionResults"
              @click:clear="listQuestionResults"
            />
          </v-flex>
          <v-flex
            xs12
            sm3
            md3
          >
            <v-autocomplete
              v-model="filters.categoryId"
              :items="categories"
              label="Categoria"
              item-text="name"
              item-value="id"
              :no-data-text="notFoundMessage"
              clearable
              filled
              @change="listQuestionResults"
              @click:clear="listQuestionResults"
            />
          </v-flex>
          <v-flex
            xs12
            sm3
            md3
          >
            <v-btn
              class="btn-filter--height font-weight-lightbold"
              color="dark-green"
              dark
              block
              large
              @click="listQuestionResults"
            >
              <v-icon left>
                mdi-magnify
              </v-icon>
              Buscar Questão
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <v-container
      :class="{
        'container--fluid': true,
        'px-12': $vuetify.breakpoint.smAndUp,
      }"
    >
      <v-layout
        row
        wrap
      >
        <v-spacer />
        <v-flex
          xs12
          sm4
        >
          <v-btn
            class="font-weight-bold my-4"
            color="primary"
            block
            large
            @click="showCreateQuestionDialog = true"
          >
            Nova Questão
          </v-btn>
        </v-flex>
        <v-spacer />
      </v-layout>
      <div class="data-spacer" />
      <v-data-table
        :headers="headers"
        :items="questions"
        hide-default-footer
        disable-pagination
        class="table--striped"
        no-data-text="Sem itens para exibir, clique no botão acima para começar a criar"
      >
        <template v-slot:item="question">
          <tr>
            <td>{{ question.item.label }}</td>
            <td>{{ question.item.identifier }}</td>
            <td>{{ question.item.category.name }}</td>
            <td>{{ question.item.type }}</td>
            <td>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    class="ml-3"
                    text
                    icon
                    @click="viewQuestionDialog(question.item)"
                    v-on="on"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar Pergunta</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    class="ml-3"
                    text
                    icon
                    @click="editDialog(question.item)"
                    v-on="on"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ml-3"
                    text
                    icon
                    v-on="on"
                    @click="deleteDialog(question.item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Remover</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-container>

    <v-dialog
      v-model="showEditQuestionDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1400"
    >
      <v-card>
        <v-card-title
          class="card-dialog__title"
        >
          Editar Questão
          <v-spacer />
          <v-btn
            icon
            @click="exitEditDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <create-question
          v-if="showEditQuestionDialog"
          edit-question
          :question-data="editedQuestion"
          @exitEditDialog="exitEditDialog"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showCreateQuestionDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1200"
    >
      <v-card>
        <v-card-title class="card-dialog__title">
          Cadastrar Questão
          <v-spacer />
          <v-btn
            icon
            @click="showCreateQuestionDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <create-question
          v-if="showCreateQuestionDialog"
          @closeCreateQuestionDialog="showCreateQuestionDialog = false"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showViewQuestionDialog"
      transition="dialog-bottom-transition"
      max-width="1200"
    >
      <v-card>
        <v-card-title class="card-dialog__title font-weight-lightbold">
          Visualizar Questão
          <v-spacer />
          <v-btn
            icon
            @click="exitViewQuestionDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mb-4" />
        <v-card-text>
          <question-builder
            v-if="showViewQuestionDialog"
            :question="viewQuestion"
            preview
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showDeleteQuestionDialog"
      class="not-printable-area"
      max-width="450"
    >
      <v-card>
        <v-card-text>
          Deseja realmente excluir essa questão?
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="btn-dialog font-weight-lightbold"
            color="green darken-1"
            text
            large
            @click="exitDeleteDialog"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            class="btn-dialog font-weight-lightbold"
            large
            color="error"
            @click="handleDeleteQuestion"
          >
            Remover
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  isEmpty,
  isNil,
  reject,
  either,
  cond,
  equals,
  always,
  T,
} from 'ramda'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'QuestionList',
  components: {
    CreateQuestion: () => import('./CreateQuestion'),
    QuestionBuilder: () => import('@/components/careFields/QuestionBuilder'),
  },
  data() {
    return {
      notFoundMessage: 'Não encontrado',
      showEditQuestionDialog: false,
      showCreateQuestionDialog: false,
      showViewQuestionDialog: false,
      showDeleteQuestionDialog: false,
      editedQuestion: {},
      deletedQuestion: {},
      viewQuestion: {},
      filters: {},
      listLoaded: true,
      count: '',
      numberOfPages: null,
      page: 1,
      headers: [
        {
          text: 'Nome',
          value: 'label',
          width: '40%',
        },
        {
          text: 'Código',
          value: 'identifier',
          width: '20%',
        },
        {
          text: 'Categoria',
          value: 'category',
          width: '10%',
        },
        {
          text: 'Tipo',
          value: 'type',
          width: '10%',
        },
        {
          text: 'Ações',
          value: 'actions',
          width: '20%',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      categories: 'careForm/categories',
      questions: 'careForm/questions',
    }),
    isQuestionListEmpty() {
      return isEmpty(this.questions)
    },
  },
  mounted() {
    this.listQuestionResults()
  },
  methods: {
    ...mapActions({
      listForm: 'careForm/listForm',
      listQuestion: 'careForm/listQuestion',
      deleteQuestion: 'careForm/deleteQuestion',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    async handleDeleteQuestion() {
      try {
        await this.deleteQuestion(this.deletedQuestion.id)
        this.buildSnackbar('success', 'Questão deletada com sucesso!')
        this.isSaving = false
      } catch ({ response }) {
        this.handleError(response)
      }
      this.deletedQuestion = {}
      this.showDeleteQuestionDialog = false
      await this.listQuestion()
    },
    handleError(error) {
      const errorMessage = cond([
        [equals('question_delete_invalid'), always('A questão não pode ser excluida, pois está atrelada a um formulário')],
        [T, always('Erro interno')],
      ])(error.data.errorCode)

      this.buildSnackbar('error', errorMessage)
    },
    listQuestionResults() {
      this.$nextTick(async () => {
        const params = reject(either(isNil, isEmpty), this.filters)
        try {
          await this.listQuestion(params)
          this.listLoaded = false
        } catch (e) {
          this.buildSnackbar('error', 'Erro Interno!')
        }
      })
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    editDialog(questionData) {
      this.editedQuestion = questionData
      this.showEditQuestionDialog = true
    },
    exitEditDialog() {
      this.editedQuestion = {}
      this.showEditQuestionDialog = false
      this.listForm()
    },
    viewQuestionDialog(question) {
      this.viewQuestion = question
      this.showViewQuestionDialog = true
    },
    exitViewQuestionDialog() {
      this.viewQuestion = {}
      this.showViewQuestionDialog = false
    },
    deleteDialog(question) {
      this.deletedQuestion = question
      this.showDeleteQuestionDialog = true
    },
    exitDeleteDialog() {
      this.deletedQuestion = {}
      this.showDeleteQuestionDialog = false
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'
.filters-container
  width 100%
  height auto
  background-color #e8f8e8

  @media only screen and (min-width 601px)
    height 108px
.v-card
  padding 10px
  border-radius 6px

.v-btn
  letter-spacing 1px

.v-btn.v-btn--large
  margin-top 0px
  height 56px
  border-radius 8px

.v-btn.v-btn--text
  border solid 1px thin-gray

.v-btn.btn-dialog
  padding 15px
  @media all and (min-width 601px)
    width 190px
</style>
