import axios from '@/lib/axios'

export default {
  state: {
    careLineAges: [],
  },
  mutations: {
    SET_LOADING_CARE_LINE_AGE(state, payload) {
      state.loadingCareLineAge = payload
    },
    SET_CARE_LINE_AGES(state, payload) {
      state.careLineAges = payload
    },
  },
  actions: {
    listCareLineAge({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CARE_LINE_AGE', true)
        axios.get('emr/care_line_age', {
          params: {
            careLineId: state.careLine.id,
          },
        })
          .then(({ data: { careLineAges } }) => {
            commit('SET_CARE_LINE_AGES', careLineAges)
            resolve(careLineAges)
          })
          .catch(reject)
          .finally(() => commit('SET_LOADING_CARE_LINE_AGE', false))
      })
    },
    createCareLineAge({ commit, state }, attributes) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CARE_LINE_AGE', true)
        axios.post('emr/care_line_age', {
          ...attributes,
          careLineId: state.careLine.id,
        })
          .then(({ data }) => resolve(data))
          .catch(reject)
          .finally(() => commit('SET_LOADING_CARE_LINE_AGE', false))
      })
    },
    deleteCareLineAge(_, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`emr/care_line_age/${id}`)
          .then(({ data }) => resolve(data))
          .catch(reject)
      })
    },
  },
  getters: {
    isLoadingCareLineAge: state => state.loadingCareLineAge,
    careLineAges: state => state.careLineAges,
  },
}
