<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <span>{{ item.patientName }}</span>
          </td>
          <td>{{ item.attendanceDate }}</td>
          <td>{{ item.healthInsurancePlanNumber }}</td>
          <td>{{ item.birthDate }}</td>
          <td>{{ item.biologicalSex }}</td>
          <td>{{ item.professionalName }}</td>
          <td>{{ item.specialityName }}</td>
          <td>{{ item.healthInsurancePlan }}</td>
          <td>{{ item.clinicName }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>
