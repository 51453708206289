export default {
  healthMaintenanceOrganizations: [
    {
      id: 'hea_mai_org_cl0mitq7r0001jus931dp3w1i',
      name: 'AEROPORTO - SBBR',
      nickName: 'AEROPORTO - SBBR',
      allowOwner: true,
      type: 'Sem agendamento',
      attendanceTelephone: null,
      administrativeTelephone: null,
      validationRules: null,
      portalAccreditation: {
        clinics: null,
      },
      coreAccreditation: {
        clinics: null,
        professionals: null,
      },
      warningMessage: null,
      packageType: 'amparoTotal',
      selfRegistration: true,
      isCommunicationAllowed: false,
      hasEligibilityCheck: false,
      externalIds: {},
      cmExtraFields: null,
      isUnderAgeImmediateCareAllowed: false,
      hasAps: false,
      healthProducts: [
        {
          id: 'hea_pro_clox33bd1000k2hl87nrd3ofk',
          healthMaintenanceOrganizationId: 'hea_mai_org_cl0mitq7r0001jus931dp3w1i',
          name: 'AEROPORTO 2',
          nickName: 'AEROPORTO 2',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [
            {
              id: 'con_cloyf3rns000q2zl8dwumbrl5',
              status: 'approved',
            },
            {
              id: 'con_cloymjsba000r2jl89i726zik',
              status: 'approved',
            },
          ],
        },
        {
          id: 'hea_pro_cl0mitq7r0001jus931dp3w1i',
          healthMaintenanceOrganizationId: 'hea_mai_org_cl0mitq7r0001jus931dp3w1i',
          name: 'AEROPORTO - SBBR',
          nickName: 'AEROPORTO - SBBR',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [
            {
              id: 'con_clopufvju000y2il82p5v1jle',
              status: 'approved',
            },
          ],
        },
      ],
      hasauthorization: false,
    },
    {
      id: 'hea_mai_org_cjy92rrfg002hi3vo8gra8asr',
      name: 'AMIL',
      nickName: 'AMIL',
      allowOwner: true,
      type: 'Tele+Físico',
      attendanceTelephone: null,
      administrativeTelephone: null,
      validationRules: {
        healthInsurancePlanNumberLength: 9,
      },
      portalAccreditation: {
        clinics: null,
      },
      coreAccreditation: {
        clinics: ['cli_cjxyringi0000i2vog8g55l7q'],
        professionals: null,
      },
      warningMessage: null,
      packageType: 'amparoTotal',
      selfRegistration: true,
      isCommunicationAllowed: true,
      hasEligibilityCheck: false,
      externalIds: {},
      cmExtraFields: null,
      isUnderAgeImmediateCareAllowed: false,
      hasAps: true,
      healthProducts: [
        {
          id: 'hea_pro_cjy92rrfg002hi3vo8gra8asr',
          healthMaintenanceOrganizationId: 'hea_mai_org_cjy92rrfg002hi3vo8gra8asr',
          name: 'AMIL',
          nickName: 'AMIL',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [
            {
              id: 'con_clopuh5qp001c2il8dgx9axx9',
              status: 'approved',
            },
          ],
        },
        {
          id: 'hea_pro_cloyegm7x001p2jl81yob80v8',
          healthMaintenanceOrganizationId: 'hea_mai_org_cjy92rrfg002hi3vo8gra8asr',
          name: 'AMIL 2',
          nickName: 'AMIL 2',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [],
        },
        {
          id: 'hea_pro_clp1bt0no000008l7e68k21ul',
          healthMaintenanceOrganizationId: 'hea_mai_org_cjy92rrfg002hi3vo8gra8asr',
          name: 'AMIL EXECUTIVO',
          nickName: 'AMIL EXECUTIVO',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [],
        },
      ],
      hasauthorization: false,
    },
    {
      id: 'hea_mai_org_cjyhq6mb4000uapvob7rw0lqw',
      name: 'AMPARO BLUE',
      nickName: 'AMPARO BLUE',
      allowOwner: false,
      type: 'Tele+Físico',
      attendanceTelephone: null,
      administrativeTelephone: null,
      validationRules: null,
      portalAccreditation: {
        clinics: null,
      },
      coreAccreditation: {
        clinics: null,
        professionals: null,
      },
      warningMessage: null,
      packageType: 'amparoTotal',
      selfRegistration: true,
      isCommunicationAllowed: true,
      hasEligibilityCheck: false,
      externalIds: {},
      cmExtraFields: null,
      isUnderAgeImmediateCareAllowed: false,
      hasAps: true,
      healthProducts: [
        {
          id: 'hea_pro_cjyhq6mb4000uapvob7rw0lqw',
          healthMaintenanceOrganizationId: 'hea_mai_org_cjyhq6mb4000uapvob7rw0lqw',
          name: 'AMPARO BLUE',
          nickName: 'AMPARO BLUE',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [],
        },
      ],
      hasauthorization: false,
    },
    {
      id: 'hea_mai_org_cjyhq6llb000papvo2xh5awwy',
      name: 'AMPARO DIAMOND',
      nickName: 'AMPARO DIAMOND',
      allowOwner: false,
      type: 'Tele+Físico',
      attendanceTelephone: null,
      administrativeTelephone: null,
      validationRules: null,
      portalAccreditation: {
        clinics: null,
      },
      coreAccreditation: {
        clinics: null,
        professionals: null,
      },
      warningMessage: null,
      packageType: 'amparoTotal',
      selfRegistration: true,
      isCommunicationAllowed: true,
      hasEligibilityCheck: false,
      externalIds: {},
      cmExtraFields: null,
      isUnderAgeImmediateCareAllowed: false,
      hasAps: true,
      healthProducts: [
        {
          id: 'hea_pro_cjyhq6llb000papvo2xh5awwy',
          healthMaintenanceOrganizationId: 'hea_mai_org_cjyhq6llb000papvo2xh5awwy',
          name: 'AMPARO DIAMOND',
          nickName: 'AMPARO DIAMOND',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [],
        },
      ],
      hasauthorization: false,
    },
    {
      id: 'hea_mai_org_cjyhq6k9b000fapvoa6vteo6q',
      name: 'AMPARO EMPRESARIAL',
      nickName: 'AMPARO EMPRESARIAL',
      allowOwner: false,
      type: 'Tele+Físico',
      attendanceTelephone: null,
      administrativeTelephone: null,
      validationRules: null,
      portalAccreditation: {
        clinics: null,
      },
      coreAccreditation: {
        clinics: null,
        professionals: null,
      },
      warningMessage: null,
      packageType: 'amparoTotal',
      selfRegistration: true,
      isCommunicationAllowed: true,
      hasEligibilityCheck: false,
      externalIds: {},
      cmExtraFields: null,
      isUnderAgeImmediateCareAllowed: false,
      hasAps: true,
      healthProducts: [
        {
          id: 'hea_pro_cjyhq6k9b000fapvoa6vteo6q',
          healthMaintenanceOrganizationId: 'hea_mai_org_cjyhq6k9b000fapvoa6vteo6q',
          name: 'AMPARO EMPRESARIAL',
          nickName: 'AMPARO EMPRESARIAL',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [],
        },
      ],
      hasauthorization: false,
    },
    {
      id: 'hea_mai_org_cjyhq6lqo000qapvogn6bfixj',
      name: 'AMPARO ONE',
      nickName: 'AMPARO ONE',
      allowOwner: false,
      type: 'Tele+Físico',
      attendanceTelephone: null,
      administrativeTelephone: null,
      validationRules: null,
      portalAccreditation: {
        clinics: null,
      },
      coreAccreditation: {
        clinics: null,
        professionals: null,
      },
      warningMessage: null,
      packageType: 'amparoTotal',
      selfRegistration: true,
      isCommunicationAllowed: true,
      hasEligibilityCheck: false,
      externalIds: {},
      cmExtraFields: null,
      isUnderAgeImmediateCareAllowed: false,
      hasAps: true,
      healthProducts: [
        {
          id: 'hea_pro_cjyhq6lqo000qapvogn6bfixj',
          healthMaintenanceOrganizationId: 'hea_mai_org_cjyhq6lqo000qapvogn6bfixj',
          name: 'AMPARO ONE',
          nickName: 'AMPARO ONE',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [],
        },
      ],
      hasauthorization: false,
    },
    {
      id: 'hea_mai_org_cjyhq6kxb000kapvocqkib8z2',
      name: 'AMPARO PRIME',
      nickName: 'AMPARO PRIME',
      allowOwner: false,
      type: 'Tele+Físico',
      attendanceTelephone: null,
      administrativeTelephone: null,
      validationRules: null,
      portalAccreditation: {
        clinics: null,
      },
      coreAccreditation: {
        clinics: null,
        professionals: null,
      },
      warningMessage: null,
      packageType: 'amparoTotal',
      selfRegistration: true,
      isCommunicationAllowed: true,
      hasEligibilityCheck: false,
      externalIds: {},
      cmExtraFields: null,
      isUnderAgeImmediateCareAllowed: false,
      hasAps: true,
      healthProducts: [
        {
          id: 'hea_pro_cjyhq6kxb000kapvocqkib8z2',
          healthMaintenanceOrganizationId: 'hea_mai_org_cjyhq6kxb000kapvocqkib8z2',
          name: 'AMPARO PRIME',
          nickName: 'AMPARO PRIME',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [],
        },
      ],
      hasauthorization: false,
    },
    {
      id: 'hea_mai_org_cjyhq6jqf000bapvod6nf6wjb',
      name: 'AMPARO PRIME STAFF',
      nickName: 'AMPARO PRIME STAFF',
      allowOwner: false,
      type: 'Tele+Físico',
      attendanceTelephone: null,
      administrativeTelephone: null,
      validationRules: null,
      portalAccreditation: {
        clinics: null,
      },
      coreAccreditation: {
        clinics: null,
        professionals: null,
      },
      warningMessage: null,
      packageType: 'amparoTotal',
      selfRegistration: true,
      isCommunicationAllowed: true,
      hasEligibilityCheck: false,
      externalIds: {},
      cmExtraFields: null,
      isUnderAgeImmediateCareAllowed: false,
      hasAps: true,
      healthProducts: [
        {
          id: 'hea_pro_cjyhq6jqf000bapvod6nf6wjb',
          healthMaintenanceOrganizationId: 'hea_mai_org_cjyhq6jqf000bapvod6nf6wjb',
          name: 'AMPARO PRIME STAFF',
          nickName: 'AMPARO PRIME STAFF',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [],
        },
      ],
      hasauthorization: false,
    },
    {
      id: 'hea_mai_org_ck41lpvy4000042vo5noq9dgb',
      name: 'ASSEFAZ',
      nickName: 'ASSEFAZ',
      allowOwner: true,
      type: 'Tele+Físico',
      attendanceTelephone: null,
      administrativeTelephone: null,
      validationRules: {
        healthInsurancePlanNumberLength: 17,
      },
      portalAccreditation: {
        clinics: null,
      },
      coreAccreditation: {
        clinics: null,
        professionals: null,
      },
      warningMessage: null,
      packageType: 'amparoTotal',
      selfRegistration: true,
      isCommunicationAllowed: true,
      hasEligibilityCheck: false,
      externalIds: {},
      cmExtraFields: null,
      isUnderAgeImmediateCareAllowed: false,
      hasAps: true,
      healthProducts: [
        {
          id: 'hea_pro_ck41lpvy4000042vo5noq9dgb',
          healthMaintenanceOrganizationId: 'hea_mai_org_ck41lpvy4000042vo5noq9dgb',
          name: 'ASSEFAZ',
          nickName: 'ASSEFAZ',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [],
        },
      ],
      hasauthorization: false,
    },
    {
      id: 'hea_mai_org_ckd96eb7j00022qo2g6mbdn1o',
      name: 'ASSEFAZ COM ACO',
      nickName: 'ASSEFAZ - ACO',
      allowOwner: true,
      type: 'Tele+Físico',
      attendanceTelephone: null,
      administrativeTelephone: null,
      validationRules: null,
      portalAccreditation: {
        clinics: null,
      },
      coreAccreditation: {
        clinics: null,
        professionals: null,
      },
      warningMessage: null,
      packageType: 'amparoTotal',
      selfRegistration: true,
      isCommunicationAllowed: false,
      hasEligibilityCheck: false,
      externalIds: {
        aco: 'aco_ckbjrpiq80000r8oshas12ida',
      },
      cmExtraFields: null,
      isUnderAgeImmediateCareAllowed: false,
      hasAps: false,
      healthProducts: [
        {
          id: 'hea_pro_ckd96eb7j00022qo2g6mbdn1o',
          healthMaintenanceOrganizationId: 'hea_mai_org_ckd96eb7j00022qo2g6mbdn1o',
          name: 'ASSEFAZ COM ACO',
          nickName: 'ASSEFAZ - ACO',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [],
        },
      ],
      hasauthorization: false,
    },
    {
      id: 'hea_mai_org_ck08o9uxs0001n4vobi2r1kik',
      name: 'CARE PLUS',
      nickName: 'CARE PLUS',
      allowOwner: true,
      type: 'Tele+Físico',
      attendanceTelephone: null,
      administrativeTelephone: null,
      validationRules: {
        healthInsurancePlanNumberLength: 12,
      },
      portalAccreditation: {
        clinics: null,
      },
      coreAccreditation: {
        clinics: null,
        professionals: null,
      },
      warningMessage: null,
      packageType: 'amparoTotal',
      selfRegistration: true,
      isCommunicationAllowed: true,
      hasEligibilityCheck: false,
      externalIds: {},
      cmExtraFields: null,
      isUnderAgeImmediateCareAllowed: false,
      hasAps: true,
      healthProducts: [
        {
          id: 'hea_pro_ck08o9uxs0001n4vobi2r1kik',
          healthMaintenanceOrganizationId: 'hea_mai_org_ck08o9uxs0001n4vobi2r1kik',
          name: 'CARE PLUS',
          nickName: 'CARE PLUS',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [
            {
              id: 'con_clkmufrqz0000v3zubgrqbalh',
              status: 'actual',
            },
          ],
        },
      ],
      hasauthorization: false,
    },
    {
      id: 'hea_mai_org_cjyhq4mao0003apvo9sb814em',
      name: 'CNU',
      nickName: 'CNU',
      allowOwner: true,
      type: 'Tele+Físico',
      attendanceTelephone: null,
      administrativeTelephone: null,
      validationRules: {
        healthInsurancePlanNumberLength: 17,
      },
      portalAccreditation: {
        clinics: null,
      },
      coreAccreditation: {
        clinics: null,
        professionals: null,
      },
      warningMessage: null,
      packageType: 'amparoTotal',
      selfRegistration: true,
      isCommunicationAllowed: true,
      hasEligibilityCheck: false,
      externalIds: {},
      cmExtraFields: null,
      isUnderAgeImmediateCareAllowed: false,
      hasAps: true,
      healthProducts: [
        {
          id: 'hea_pro_cjyhq4mao0003apvo9sb814em',
          healthMaintenanceOrganizationId: 'hea_mai_org_cjyhq4mao0003apvo9sb814em',
          name: 'CNU',
          nickName: 'CNU',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [],
        },
      ],
      hasauthorization: false,
    },
    {
      id: 'hea_mai_org_cjyhpxra00001apvo551t6omf',
      name: 'OMINT',
      nickName: 'OMINT',
      allowOwner: true,
      type: 'Tele+Físico',
      attendanceTelephone: null,
      administrativeTelephone: null,
      validationRules: {
        healthInsurancePlanNumberLength: 13,
      },
      portalAccreditation: {
        clinics: null,
      },
      coreAccreditation: {
        clinics: null,
        professionals: null,
      },
      warningMessage: null,
      packageType: 'amparoTotal',
      selfRegistration: true,
      isCommunicationAllowed: true,
      hasEligibilityCheck: false,
      externalIds: {},
      cmExtraFields: null,
      isUnderAgeImmediateCareAllowed: false,
      hasAps: false,
      healthProducts: [
        {
          id: 'hea_pro_cjyhpxra00001apvo551t6omf',
          healthMaintenanceOrganizationId: 'hea_mai_org_cjyhpxra00001apvo551t6omf',
          name: 'OMINT',
          nickName: 'OMINT',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [],
        },
      ],
      hasauthorization: false,
    },
    {
      id: 'hea_mai_org_cjyhpwbog0000apvo8yz7ht75',
      name: 'PARTICULAR',
      nickName: 'PARTICULAR',
      allowOwner: false,
      type: 'Tele+Físico',
      attendanceTelephone: null,
      administrativeTelephone: null,
      validationRules: null,
      portalAccreditation: {
        clinics: null,
      },
      coreAccreditation: {
        clinics: null,
        professionals: null,
      },
      warningMessage: null,
      packageType: 'amparoTotal',
      selfRegistration: true,
      isCommunicationAllowed: true,
      hasEligibilityCheck: false,
      externalIds: {},
      cmExtraFields: null,
      isUnderAgeImmediateCareAllowed: false,
      hasAps: false,
      healthProducts: [
        {
          id: 'hea_pro_cjyhpwbog0000apvo8yz7ht75',
          healthMaintenanceOrganizationId: 'hea_mai_org_cjyhpwbog0000apvo8yz7ht75',
          name: 'PARTICULAR',
          nickName: 'PARTICULAR',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [
            {
              id: 'con_cl1wivpec00002czugemmh0i9',
              status: 'actual',
            },
          ],
        },
        {
          id: 'hea_pro_clr0s8bm0000008jw5p686mly',
          healthMaintenanceOrganizationId: 'hea_mai_org_cjyhpwbog0000apvo8yz7ht75',
          name: 'PLANO NOVO 1',
          nickName: 'PLANO NOVO 1',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [],
        },
      ],
      hasauthorization: false,
    },
    {
      id: 'hea_mai_org_ckbwsnxxm0r780nweavvdh7ot',
      name: 'POSTAL',
      nickName: 'POSTAL',
      allowOwner: true,
      type: 'Tele+Físico',
      attendanceTelephone: null,
      administrativeTelephone: null,
      validationRules: null,
      portalAccreditation: {
        clinics: null,
      },
      coreAccreditation: {
        clinics: null,
        professionals: null,
      },
      warningMessage: null,
      packageType: 'amparoTotal',
      selfRegistration: true,
      isCommunicationAllowed: true,
      hasEligibilityCheck: false,
      externalIds: {},
      cmExtraFields: null,
      isUnderAgeImmediateCareAllowed: false,
      hasAps: false,
      healthProducts: [
        {
          id: 'hea_pro_ckbwsnxxm0r780nweavvdh7ot',
          healthMaintenanceOrganizationId: 'hea_mai_org_ckbwsnxxm0r780nweavvdh7ot',
          name: 'POSTAL',
          nickName: 'POSTAL',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [
            {
              id: 'con_cl6nvcbz200000ammaa3afy3d',
              status: 'actual',
            },
          ],
        },
      ],
      hasauthorization: false,
    },
    {
      id: 'hea_mai_org_cl8emyhf102lt0jnnh0ish8py',
      name: 'RITA',
      nickName: 'RITA',
      allowOwner: true,
      type: 'Tele+Físico',
      attendanceTelephone: null,
      administrativeTelephone: null,
      validationRules: null,
      portalAccreditation: {
        clinics: null,
      },
      coreAccreditation: {
        clinics: null,
        professionals: null,
      },
      warningMessage: null,
      packageType: 'amparoTotal',
      selfRegistration: true,
      isCommunicationAllowed: true,
      hasEligibilityCheck: false,
      externalIds: {},
      cmExtraFields: null,
      isUnderAgeImmediateCareAllowed: false,
      hasAps: true,
      healthProducts: [
        {
          id: 'hea_pro_cl8emyhf102lt0jnnh0ish8py',
          healthMaintenanceOrganizationId: 'hea_mai_org_cl8emyhf102lt0jnnh0ish8py',
          name: 'RITA',
          nickName: 'RITA',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [],
        },
      ],
      hasauthorization: false,
    },
    {
      id: 'hea_mai_org_ck38qxl7v0003qivo1cym0y4r',
      name: 'SABIN - SULAMÉRICA',
      nickName: 'SABIN - SAS',
      allowOwner: true,
      type: 'Tele+Físico',
      attendanceTelephone: null,
      administrativeTelephone: null,
      validationRules: null,
      portalAccreditation: {
        clinics: null,
      },
      coreAccreditation: {
        clinics: null,
        professionals: null,
      },
      warningMessage: null,
      packageType: 'amparoTotal',
      selfRegistration: true,
      isCommunicationAllowed: true,
      hasEligibilityCheck: false,
      externalIds: {},
      cmExtraFields: null,
      isUnderAgeImmediateCareAllowed: false,
      hasAps: false,
      healthProducts: [
        {
          id: 'hea_pro_ck38qxl7v0003qivo1cym0y4r',
          healthMaintenanceOrganizationId: 'hea_mai_org_ck38qxl7v0003qivo1cym0y4r',
          name: 'SABIN - SULAMÉRICA',
          nickName: 'SABIN - SAS',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [],
        },
      ],
      hasauthorization: false,
    },
    {
      id: 'hea_mai_org_cjy92ryzw002ii3voawzk0w4c',
      name: 'SULAMÉRICA',
      nickName: 'SAS',
      allowOwner: true,
      type: 'Tele+Físico',
      attendanceTelephone: null,
      administrativeTelephone: null,
      validationRules: {
        healthInsurancePlanNumberLength: 17,
        healthInsuranceProductNumberLength: 3,
      },
      portalAccreditation: {
        clinics: null,
      },
      coreAccreditation: {
        clinics: null,
        professionals: null,
      },
      warningMessage: null,
      packageType: 'amparoTotal',
      selfRegistration: true,
      isCommunicationAllowed: true,
      hasEligibilityCheck: false,
      externalIds: {},
      cmExtraFields: null,
      isUnderAgeImmediateCareAllowed: false,
      hasAps: true,
      healthProducts: [
        {
          id: 'hea_pro_cjy92ryzw002ii3voawzk0w4c',
          healthMaintenanceOrganizationId: 'hea_mai_org_cjy92ryzw002ii3voawzk0w4c',
          name: 'SULAMÉRICA',
          nickName: 'SAS',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [],
        },
      ],
      hasauthorization: false,
    },
    {
      id: 'hea_mai_org_cjyhpzii00002apvogkkz6how',
      name: 'UNIMED SEGUROS',
      nickName: 'UNIMED SEGUROS',
      allowOwner: true,
      type: 'Tele+Físico',
      attendanceTelephone: null,
      administrativeTelephone: null,
      validationRules: {
        healthInsurancePlanNumberLength: 16,
      },
      portalAccreditation: {
        clinics: null,
      },
      coreAccreditation: {
        clinics: null,
        professionals: null,
      },
      warningMessage: null,
      packageType: 'amparoTotal',
      selfRegistration: true,
      isCommunicationAllowed: true,
      hasEligibilityCheck: false,
      externalIds: {},
      cmExtraFields: null,
      isUnderAgeImmediateCareAllowed: false,
      hasAps: true,
      healthProducts: [
        {
          id: 'hea_pro_cjyhpzii00002apvogkkz6how',
          healthMaintenanceOrganizationId: 'hea_mai_org_cjyhpzii00002apvogkkz6how',
          name: 'UNIMED SEGUROS',
          nickName: 'UNIMED SEGUROS',
          workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
          contracts: [
            {
              id: 'con_clooqeobp000y2jl860weh7fz',
              status: 'draft',
            },
          ],
        },
      ],
      hasauthorization: false,
    },
  ],
}
