import axios from '@/lib/axios'
import {
  filter,
  isEmpty,
} from 'ramda'

const initialState = {
  hmos: [],
  hmosWithContracts: [],
  loading: false,
}

const mutations = {
  SET_HMOS(state, payload) {
    state.hmos = payload
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_HMOS_WITH_CONTRACTS(state, payload) {
    state.hmosWithContracts = payload
  },
}

const actions = {
  listHmo({ commit }, filters = {}) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      const params = filter(item => !isEmpty(item), filters)
      axios.get('health_maintenance_organization', { params })
        .then(({ data: { healthMaintenanceOrganizations } }) => {
          commit('SET_HMOS', healthMaintenanceOrganizations)
          resolve(healthMaintenanceOrganizations)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  getById(_, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/health_maintenance_organization/${id}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
  createHmo({ commit }, attributes) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.post('health_maintenance_organization', attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  updateHmo({ commit }, { id, attributes }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.put(`health_maintenance_organization/${id}`, attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  updateHmoExternalIds({ commit }, { id, attributes }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.patch(`health_maintenance_organization/${id}/external_ids`, attributes)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  listHmosWithContracts({ commit }, filters = {}) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      const params = filter(item => !isEmpty(item), filters)
      axios.get('health_maintenance_organization/contract', { params })
        .then(({ data: { healthMaintenanceOrganizations } }) => {
          commit('SET_HMOS_WITH_CONTRACTS', healthMaintenanceOrganizations)
          resolve(healthMaintenanceOrganizations)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
}

const getters = {
  hmos: state => state.hmos,
  hmosWithContracts: state => state.hmosWithContracts,
  loading: state => state.loading,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
