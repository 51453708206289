import {
  and,
  always,
  defaultTo,
  equals,
  find,
  has,
  head,
  ifElse,
  isNil,
  isEmpty,
  not,
  or,
  propEq,
  path,
} from 'ramda'
import appearConditions from '@/appearConditions'
import { EMR_QUESTIONS } from 'amparo-enums'
import ENUM from '@/enums/structRequiredFunctions'
import { isWorkSpaceAmparo } from '@/utils/routesAccess'

const { questions } = EMR_QUESTIONS
const { EXAMS, CARE_LINES } = ENUM

const generateMandatoryConditionMessage = isMedicalDoctorEncounter => ifElse(
  equals(true),
  always('Para finalizar a consulta, insira o CID trabalhado na mesma'),
  always('Para finalizar a consulta, insira o CIPESC trabalhado na mesma'),
)(isMedicalDoctorEncounter)

const hasExamValue = (examId, { exams }) => has(examId, exams)
const patientHasCareLine = (careLineId, { careLinesPatient }) => !isNil(find(
  propEq(
    careLineId,
    'careLineId',
  ),
  careLinesPatient,
))

const mandatoryBloodPressureForPresentialAppointments = ({
  encounterData,
  patientData,
  appointments,
}) => {
  if (isNil(encounterData)) return null
  const appointment = head(defaultTo(null, appointments))
  if (isNil(appointment)) return null
  const patientCareLines = patientData.careLinesPatient

  if (and(
    propEq('Consulta Presencial', 'type', appointment),
    or(
      path(['patient', 'isFirstAppointment'], appointment),
      find(propEq(CARE_LINES.ARTERIAL_HYPERTENSION, 'careLineId'), patientCareLines),
    ),
  )) {
    if (!hasExamValue(EXAMS.SYSTOLIC_PRESSURE, encounterData)
      || !hasExamValue(EXAMS.DIASTOLIC_PRESSURE, encounterData)) {
      return 'Para finalizar a consulta, insira a medição de "Pressão Sentado" nos exames'
    }
  }
  return null
}

const mandatoryObesityExams = ({
  encounterData,
  patientData,
}) => {
  if (isNil(encounterData)) return null

  if (patientHasCareLine(CARE_LINES.OBESITY, patientData)) {
    if (!hasExamValue(EXAMS.WEIGHT, encounterData)) {
      return 'Para finalizar a consulta, insira a medição de "Peso" nos exames'
    }
    if (!hasExamValue(EXAMS.HEIGHT, encounterData)) {
      return 'Para finalizar a consulta, insira a medição de "Altura" nos exames'
    }
  }

  return null
}

const mandatoryConditions = ({ encounterData, user }) => {
  if (or(
    not(isWorkSpaceAmparo(user.workSpaceId)),
    isNil(encounterData),
  )) return null

  const newConditions = encounterData[questions.conditions]
  const isMedicalDoctorEncounter = appearConditions.isMedicalDoctorEncounter({ professional: user })
  if (and(
    isMedicalDoctorEncounter,
    isNil(
      find(
        propEq('CID10', 'version'),
        defaultTo([], newConditions),
      ),
    ),
  )) {
    return generateMandatoryConditionMessage(isMedicalDoctorEncounter)
  }
  return null
}

const getErrorMessage = question => `Campo "${question.title || question.label}" é obrigatório.`

const alwaysWhenDisplayTrue = ({ question, encounterData }) => {
  const res = encounterData[question.id]
  const errorMessage = getErrorMessage(question)

  if (isNil(path(['conditionalValue'], res))) return errorMessage

  if (res.conditionalValue && (isEmpty(res.value) || isNil(res.value))) {
    if (question.id === questions.medicalCertificate) return null
    return errorMessage
  }
  return null
}

export default {
  mandatoryConditions,
  mandatoryBloodPressureForPresentialAppointments,
  mandatoryObesityExams,
  always: ({ question, encounterData }) => {
    const res = encounterData[question.id]
    if (isNil(res) || isEmpty(res)) return getErrorMessage(question)
    return null
  },
  alwaysWhenDisplayTrue,
}
