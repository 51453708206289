export default {
  diseases: [
    {
      id: 'dis_ck1grwgdh00i9mgpq7kbahs98',
      name: 'Hidrocele',
      version: 'CIAP2',
      code: 'Y86',
    },
    {
      id: 'dis_ck1tmzbsl00spl0pqekeb7bpd',
      name: 'Hidrocele e espermatocele',
      version: 'CID10',
      code: 'N43',
    },
    {
      id: 'dis_ck02niq9p04szbbpqdwlxa2tu',
      name: 'Hidrocele congênita',
      version: 'CID10',
      code: 'P835',
    },
    {
      id: 'dis_ck02niq9b042nbbpq582ogl6l',
      name: 'Hidrocele infectada',
      version: 'CID10',
      code: 'N431',
    },
    {
      id: 'dis_ck02niq9b042mbbpqcbbbhrqn',
      name: 'Hidrocele encistada',
      version: 'CID10',
      code: 'N430',
    },
    {
      id: 'dis_ck02niq9b042obbpq90qg1yvo',
      name: 'Outra hidrocele',
      version: 'CID10',
      code: 'N432',
    },
    {
      id: 'dis_ck02niq9b042pbbpq0pd2ee29',
      name: 'Hidrocele não especificada',
      version: 'CID10',
      code: 'N433',
    },
  ],
}
