<template>
  <v-flex
    d-flex
    column
    class="full-width"
    align-end
  >
    <v-flex
      v-for="(type, index) of chartConfig.types"
      :key="index"
      class="full-width"
    >
      <who-chart
        class="my-4 full-width"
        :patient="patient"
        :metric="chartConfig.metric"
        :type="type"
        :y-title="chartConfig.yTitle"
        :exams="exams"
        :exam-id="chartConfig.examId"
        :min-age="chartConfig.minAge"
        :max-age="chartConfig.maxAge"
      />
    </v-flex>
  </v-flex>
</template>

<script>
import {
  equals,
  forEach,
  length,
} from 'ramda'
import WhoChart from './WhoChart'

export default {
  components: {
    WhoChart,
  },
  props: [
    'patient',
    'chartConfig',
    'exams',
  ],
  mounted() {
    setTimeout(() => {
      this.hideMetricLegend()
    }, length(this.chartConfig.types) * 1000)
  },
  methods: {
    hideMetricLegend() {
      const legendContainer = document.querySelectorAll('.apexcharts-legend')
      forEach(
        (container) => {
          forEach(
            (element) => {
              if (equals(element.getAttribute('rel'), '1')) {
                const firstElement = element
                firstElement.style.display = 'none'
              }
            },
            container.childNodes,
          )
        },
        legendContainer,
      )
    },
  },
}
</script>

<style lang="stylus" scoped>
.full-width
  width 100%
</style>
