<template>
  <div>
    <component-title />
    <create-patient />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import CreatePatient from '@/components/CreatePatient'

export default {
  name: 'CreatePatientPage',
  components: { ComponentTitle, CreatePatient },
}
</script>
