<template>
  <div>
    <v-container
      class="px-0"
      fluid
    >
      <v-row
        class="filters-container ml-0"
      >
        <v-col>
          <v-text-field
            v-model="nameFilter"
            filled
            label="Buscar por Nome"
            clearable
            no-data-text="Digite para buscar"
            @input="searchGroup(nameFilter)"
          />
        </v-col>
      </v-row>
      <v-row
        justify="center"
        align="center"
      >
        <v-col
          cols="9"
        >
          <h1
            class="ml-10 pl-0 pr-5 font-weight-medium"
          >
            Gerenciar Grupos
          </h1>
        </v-col>
        <v-col
          class="py-0 pl-0 pr-5"
        >
          <v-btn
            class="text-border my-4 font-weight-lightbold"
            color="primary"
            data-testid="disease-group__new-group--button"
            @click="openCreateGroupDialog"
          >
            Adicionar Novo Grupo
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        class="mx-4"
      >
        <v-col>
          <v-card
            v-for="(disease, index) in diseaseGroups"
            :key="index"
            class="mt-6 mb-6"
          >
            <v-row>
              <v-col
                cols="10"
                class="ml-3"
              >
                <h3
                  :data-testid="`disease-group__group-name-${disease.name}--label`"
                >
                  {{ disease.name }}
                </h3>
              </v-col>
              <v-col>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mr-2"
                      text
                      icon
                      :data-testid="`disease-group__edit-group-${disease.name}--button`"
                      @click="openEditDialog(disease)"
                      v-on="on"
                    >
                      <v-icon>mdi-account-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      text
                      icon
                      :data-testid="`disease-group__delete-group-${disease.name}--button`"
                      @click="openDeleteDialog(disease)"
                      v-on="on"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Excluir</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <create-disease-group
        v-if="showEditDiseaseGroupDialog"
        edit-group
        :group-data="editedGroup"
        @exitEditDialog="exitEditDialog"
      />

      <create-disease-group
        v-if="showCreateDiseaseGroupDialog"
        @closeCreateGroupDialog="exitCreateDialog"
      />

      <delete-disease-group
        v-if="showDeleteGroupDialog"
        :group-data="deletedGroup"
        @exitDeleteDialog="exitDeleteDialog"
        @deleteGroup="deleteGroup"
      />
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DiseaseGroupList',
  components: {
    CreateDiseaseGroup: () => import('./CreateDiseaseGroup'),
    DeleteDiseaseGroup: () => import('./DeleteDiseaseGroup'),
  },
  data() {
    return {
      notFoundMessage: 'Não encontrado',
      showEditDiseaseGroupDialog: false,
      editedGroup: {},
      nameFilter: '',
      showCreateDiseaseGroupDialog: false,
      showDeleteGroupDialog: false,
      deletedGroup: {},
    }
  },
  computed: {
    ...mapGetters({
      diseaseGroups: 'billing/groups',
    }),
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
      listGroups: 'billing/listGroups',
      removeGroup: 'billing/removeGroup',
    }),
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    handleError(error) {
      if (error.data.errorCode) {
        this.buildSnackbar('error', 'Erro Interno')
      }
    },
    searchGroup(filter) {
      this.listGroups({ name: filter })
    },
    openEditDialog(groupData) {
      this.editedGroup = groupData
      this.showEditDiseaseGroupDialog = true
    },
    exitEditDialog() {
      this.editedGroup = {}
      this.showEditDiseaseGroupDialog = false
      this.listGroups()
    },
    exitCreateDialog() {
      this.showCreateDiseaseGroupDialog = false
      this.listGroups()
    },
    exitDeleteDialog() {
      this.deletedGroup = {}
      this.showDeleteGroupDialog = false
    },
    openDeleteDialog(disease) {
      this.deletedGroup = disease
      this.showDeleteGroupDialog = true
    },
    async deleteGroup() {
      try {
        await this.removeGroup({
          id: this.deletedGroup.id,
        })
        this.buildSnackbar('success', 'Grupo removido com sucesso!')
        this.showDeleteGroupDialog = false
        this.deletedGroup = {}
        this.listGroups()
      } catch (error) {
        this.buildSnackbar('error', 'Erro ao remover o grupo!')
      }
    },
    openCreateGroupDialog() {
      this.showCreateDiseaseGroupDialog = true
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'
.filters-container
  background-color #e8f8e8
</style>
