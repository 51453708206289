import axios from '@/lib/axios'

export default {
  state: {
    loadingCareLineActionReminderCommunication: false,
    careLineActionReminderCommunications: [],
  },
  mutations: {
    SET_LOADING_CARE_LINE_ACTION_REMINDER_COMMUNICATION(state, value) {
      state.loadingCareLineActionReminderCommunication = value
    },
    SET_CARE_LINE_ACTION_REMINDER_COMMUNICATIONS(state, payload) {
      state.careLineActionReminderCommunications = payload
    },
  },
  actions: {
    listCareLineActionReminderCommunication({ commit }, careLineActionId) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CARE_LINE_ACTION_REMINDER_COMMUNICATION', true)
        axios.get('/emr/care_line_action_reminder_communication', {
          params: { careLineActionId },
        })
          .then(({ data: { careLineActionReminderCommunications } }) => {
            commit('SET_CARE_LINE_ACTION_REMINDER_COMMUNICATIONS', careLineActionReminderCommunications)
            resolve(careLineActionReminderCommunications)
          })
          .catch((reject))
          .finally(() => commit('SET_LOADING_CARE_LINE_ACTION_REMINDER_COMMUNICATION', false))
      })
    },
    createCareLineActionReminderCommunication({ commit }, attributes) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CARE_LINE_ACTION_REMINDER_COMMUNICATION', true)
        axios.post('/emr/care_line_action_reminder_communication', attributes)
          .then(resolve)
          .catch(reject)
          .finally(() => commit('SET_LOADING_CARE_LINE_ACTION_REMINDER_COMMUNICATION', false))
      })
    },
    updateCareLineActionReminderCommunication({ commit }, { id, attributes }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CARE_LINE_ACTION_REMINDER_COMMUNICATION', true)
        axios.put(`/emr/care_line_action_reminder_communication/${id}`, attributes)
          .then(resolve)
          .catch(reject)
          .finally(() => commit('SET_LOADING_CARE_LINE_ACTION_REMINDER_COMMUNICATION', false))
      })
    },
    deleteCareLineActionReminderCommunication({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_CARE_LINE_ACTION_REMINDER_COMMUNICATION', true)
        axios.delete(`/emr/care_line_action_reminder_communication/${id}`)
          .then(resolve)
          .catch(reject)
          .finally(() => commit('SET_LOADING_CARE_LINE_ACTION_REMINDER_COMMUNICATION', false))
      })
    },
  },
  getters: {
    isLoadingCareLineActionReminderCommunications: state => state
      .loadingCareLineActionReminderCommunication,
    careLineActionReminderCommunications: state => state.careLineActionReminderCommunications,
  },
}
