<template>
  <div>
    <v-container
      v-if="isLoading"
      justify-center
      mt-5
      class="fill-height"
    >
      <circular-loader />
    </v-container>
    <v-container
      v-else
      fluid
    >
      <v-row>
        <v-col
          class="mr-5"
        >
          <v-form ref="form">
            <v-row>
              <v-col>
                <v-text-field
                  disabled
                  filled
                  label="Plano de Saúde"
                  :value="contract.hmo.name"
                />
              </v-col>
              <v-col>
                <v-text-field
                  disabled
                  filled
                  label="Plano"
                  :value="contract.healthProduct.name"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="contract.name"
                  clearable
                  filled
                  label="Nome"
                  :disabled="disableEdition"
                  :error="$v.contract.name.$error"
                  @blur="$v.contract.name.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="contract.description"
                  auto-grow
                  clearable
                  filled
                  label="Descrição"
                  rows="4"
                  :disabled="disableEdition"
                  :error="$v.contract.description.$error"
                  @blur="$v.contract.description.$touch()"
                />
              </v-col>
            </v-row>
            <div
              v-if="shouldShowJourneySettings"
            >
              <v-row>
                <v-col>
                  <span class="form-subtitle">Jornada de Ativação</span>
                </v-col>
              </v-row>
              <transition-group name="slide-fade">
                <v-row
                  v-for="(journey, index) of activationJourneysConfiguration"
                  :key="`${index}`"
                  class="mt-4"
                >
                  <v-col
                    class="pb-0"
                  >
                    <span class="activation-journey__title">{{ journey.name }}</span>
                    <v-select
                      v-model="journey.clinicId"
                      class="mt-4"
                      label="Unidade"
                      item-text="name"
                      item-value="id"
                      filled
                      :items="clinics"
                      :no-data-text="notFoundMessage"
                      :disabled="disableEdition"
                      :error-messages="customJourneyErrorMessage"
                      :error="$v.activationJourneysConfiguration.$error"
                      @blur="$v.activationJourneysConfiguration.$touch()"
                    />
                  </v-col>
                  <v-col
                    class="pb-0 mt-10"
                  >
                    <v-select
                      v-model="journey.doctorSlots"
                      label="Slot por Médico(a) de Família"
                      :disabled="disableEdition"
                      :items="[1,2]"
                      filled
                    />
                  </v-col>
                  <v-col
                    cols="1"
                    class="pb-0 mt-10"
                  >
                    <v-btn
                      color="error"
                      class="mt-2"
                      icon
                      :disabled="disableEdition"
                      @click="shouldOpenDeleteJourneyModal(index)"
                    >
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </transition-group>
              <v-row
                class="mt-0 mb-6"
              >
                <v-col>
                  <v-btn
                    text
                    class="pa-0"
                    color="amparo-medium-green"
                    :disabled="disableEdition"
                    @click="addActivationJourneyConfiguration"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                    Adicionar Jornada
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col>
                <span class="form-subtitle">Obrigatoriedade de RQE</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="pt-0"
              >
                <v-radio-group
                  v-model="contract.requiredRqe"
                  row
                  dense
                  mandatory
                  :disabled="disableEdition"
                >
                  <v-radio
                    color="primary"
                    label="Sim"
                    :value="true"
                  />
                  <v-radio
                    color="primary"
                    label="Não"
                    :value="false"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row
              class="mt-0"
            >
              <v-col>
                <contract-attachments
                  v-if="contractData && !disableEdition"
                  :contract-id="contractData.id"
                  :contract-attachments="contractData.contractAttachments"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col
          v-if="shouldShowArchiveButton"
          class="ml-5"
          cols="2"
        >
          <v-btn
            height="40"
            block
            class="btn btn-danger"
            @click="showArchiveContractDialog = true"
          >
            Encerrar Contrato
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      fluid
    >
      <v-row>
        <v-col>
          <div
            class="d-flex flex-column form-subtitle text-h6"
          >
            Tabela de Cobertura
            <v-btn
              class="text-subtitle-1 font-weight-lightbold text-capitalize pl-2 mb-4"
              color="primary"
              max-width="210"
              text
              @click="showDownloadXlsxDialog = true"
            >
              Download Planilha
              <v-icon right>
                mdi-download
              </v-icon>
            </v-btn>
          </div>
          <vue-dropzone
            v-if="!disableEdition"
            id="myDropzone"
            ref="myDropzone"
            class="card__dropzone"
            data-testid="dropzone--field"
            :use-custom-slot="true"
            :options="dropzoneOptions"
            @vdropzone-files-added="uploadExcelFile"
          >
            <div class="dropzone-custom-content">
              <div class="dropzone-custom-title">
                <v-icon
                  class="dropzone-custom-title__icon"
                  x-large
                >
                  mdi-file-upload-outline
                </v-icon>
                <h3>Escolha ou arraste o arquivo para ser anexado!</h3>
              </div>
            </div>
          </vue-dropzone>
        </v-col>
      </v-row>
      <contract-items-table
        :contract-items="contractItems"
        :page="page"
        @setPageAndListItems="setPageAndListItems"
      />
      <v-row>
        <v-col
          class="btn-actions d-flex justify-end"
        >
          <v-btn
            class="btn"
            :disabled="isLoading"
            @click="$router.back()"
          >
            Voltar
          </v-btn>
          <v-btn
            v-if="shouldShowSaveButton"
            class="btn"
            :disabled="shouldDisableButton"
            @click="handleOpenContractStatusChangeDialogDraft()"
          >
            Salvar
          </v-btn>
          <v-btn
            v-if="shouldShowSetAsDraft"
            class="btn"
            :disabled="shouldDisableButton"
            @click="handleOpenContractStatusChangeDialogDraft()"
          >
            Habilitar edição
          </v-btn>
          <v-btn
            v-if="shouldShowConfirmButton"
            class="btn btn-primary"
            :disabled="shouldDisableButton"
            @click="handleOpenContractStatusChangeDialogPend()"
          >
            Salvar e Pedir aprovação
          </v-btn>
          <v-btn
            v-if="shouldShowReviewButton"
            class="btn"
            color="error"
            :disabled="shouldDisableButton"
            @click="openContractStatusChangeDialog('rejected')"
          >
            Não Aprovar
          </v-btn>
          <v-btn
            v-if="shouldShowReviewButton"
            class="btn btn-primary"
            :disabled="shouldDisableButton"
            @click="openContractStatusChangeDialog('approved')"
          >
            Aprovar Contrato
          </v-btn>
          <v-btn
            v-if="shouldShowPublishButton"
            class="btn btn-primary"
            :disabled="shouldDisableButton"
            @click="handleOpenContractStatusChangeDialogPublish()"
          >
            Publicar Contrato
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="showContractStatusChangeDialog"
      persistent
      max-width="850"
    >
      <contract-status-change-alert
        v-if="showContractStatusChangeDialog"
        :new-status="statusToChange"
        @changeStatus="handleStatusChange"
        @close="closeContractStatusChangeDialog"
      />
    </v-dialog>

    <v-dialog
      v-model="showArchiveContractDialog"
      class="not-printable-area"
      max-width="450"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          Deseja realmente arquivar esse contrato?
        </v-card-title>
        <v-card-actions>
          <v-btn
            class="btn-dialog"
            color="green darken-1"
            text
            large
            @click="showArchiveContractDialog = false"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            class="btn-dialog"
            large
            color="error"
            @click="handleArchiveContract"
          >
            Arquivar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      class="not-printable-area download-dialog"
      persistent
      max-width="800"
      :scrollable="false"
    >
      <download-contract-csv-dialog
        v-if="!isLoading"
        :contract-id="contractData.id"
      />
    </v-dialog>

    <download-contract-xlsx-dialog
      v-if="!isLoading"
      :show-download-xlsx-dialog="showDownloadXlsxDialog"
      :contract="contractData"
      @closeDialog="showDownloadXlsxDialog = false"
    />

    <journey-configuration-modal
      v-if="shouldOpenDeleteConfigurationModal"
      :journey-name="journeyName"
      :index="journeyIndex"
      @deleteJourneyConfiguration="deleteJourneyConfiguration"
      @close="closeDeleteJourneyModal"
    />

    <invalid-file-type-modal
      v-if="shouldOpenInvalidFileTypeModal"
      @closeModal="closeInvalidFileTypeModal"
    />

    <contract-item-worksheet-error-modal
      v-if="shouldOpenContractItemWorksheetErrorModal"
      :sheet-errors="sheetErrors"
      :compensate-rows="2"
      @closeModal="closeContractItemWorksheetErrorModal"
    />
  </div>
</template>

<script>
import CircularLoader from '@/components/UI/CircularLoader'
import {
  required,
  minLength,
} from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import {
  always,
  any,
  append,
  concat,
  cond,
  equals,
  find,
  groupBy,
  includes,
  isEmpty,
  isNil,
  length,
  map,
  not,
  pick,
  pipe,
  prop,
  propEq,
  remove,
  sortBy,
  values,
} from 'ramda'
import moment from 'moment'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'
import uploadContractItems from '@/utils/xlsx/uploadContractItems'
import { CONTRACT } from 'amparo-enums'

const ALLOWED_EDIT_STATUS = [
  CONTRACT.status.draft.value,
]

const ALLOWED_SET_AS_DRAFT_STATUS = [
  CONTRACT.status.pending.value,
  CONTRACT.status.approved.value,
  CONTRACT.status.rejected.value,
]

const ALLOWED_PEND_STATUS = [
  CONTRACT.status.draft.value,
  CONTRACT.status.rejected.value,
]

export default {
  name: 'EditContract',
  components: {
    CircularLoader,
    ContractAttachments: () => import('./ContractAttachments'),
    ContractItemsTable: () => import('@/components/Common/ContractItemsTable'),
    ContractItemWorksheetErrorModal: () => import('./Modals/ContractItemWorksheetErrorModal'),
    ContractStatusChangeAlert: () => import('./ContractStatusChangeAlert'),
    DownloadContractXlsxDialog: () => import('@/components/Common/DownloadContractXlsxDialog'),
    InvalidFileTypeModal: () => import('./Modals/InvalidFileTypeModal'),
    JourneyConfigurationModal: () => import('./Modals/JourneyConfigurationModal'),
    VueDropzone: vue2Dropzone,
  },
  data() {
    return {
      activationJourneysConfiguration: [],
      clinics: null,
      endDateMenu: false,
      journeyIndex: 0,
      journeyName: '',
      notFoundMessage: 'Não encontrado',
      page: 1,
      sheetErrors: {},
      shouldOpenContractItemWorksheetErrorModal: false,
      shouldOpenDeleteConfigurationModal: false,
      shouldOpenInvalidFileTypeModal: false,
      showArchiveContractDialog: false,
      showContractStatusChangeDialog: false,
      showDownloadXlsxDialog: false,
      startDateMenu: false,
      statusToChange: null,
      statusToChangeHandleMethod: null,
      contract: {
        id: null,
        name: null,
        description: null,
        requiredRqe: false,
        startDate: null,
        endDate: null,
        hmo: {
          id: null,
          name: null,
        },
        healthProduct: {
          id: null,
          name: null,
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      contractData: 'billing/contract',
      contractItemPage: 'billing/contractItemPage',
      contractItems: 'billing/contractItems',
      isLoading: 'billing/isLoadingContract',
      token: 'authentication/token',
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
    }),
    dropzoneOptions() {
      return {
        acceptedFiles: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        url: 'https://httpbin.org/post',
        thumbnailWidth: 250,
        thumbnailHeight: 250,
        maxFilesize: 0.09,
        dictFileTooBig: 'Arquivo muito grande ({{filesize}}MiB). tamanho máximo: {{maxFilesize}}Mib.',
        dictInvalidFileType: 'Você não pode enviar arquivos deste tipo.',
        headers: { Authorization: `Bearer ${this.token}` },
        paramName: 'attachment',
      }
    },
    customJourneyErrorMessage() {
      if (!this.hasDefaultJourney(this.activationJourneysConfiguration)) {
        return 'Adicione uma jornada com unidade padrão'
      }

      if (!this.hasDuplicatedJourneys(this.activationJourneysConfiguration)) {
        return 'Não é permitido a repetição da mesma unidade'
      }

      return ''
    },
    formattedStartDate() {
      return this.contract.startDate
        ? moment(this.contract.startDate).format('DD/MM/YYYY')
        : ''
    },
    formattedEndDate() {
      return this.contract.endDate
        ? moment(this.contract.endDate).format('DD/MM/YYYY')
        : ''
    },
    disableEdition() {
      return !includes(this.contractData?.status, ALLOWED_EDIT_STATUS)
    },
    isPublishedContract() {
      return propEq('actual', 'status', this.contractData)
    },
    hasAccessToContractEdit() {
      return this.userHasAccessToFunctionality.contractEdit
    },
    shouldShowSaveButton() {
      return (
        includes(this.contractData?.status, values(ALLOWED_EDIT_STATUS))
        && this.hasAccessToContractEdit
      )
    },
    shouldShowSetAsDraft() {
      return (
        includes(this.contractData?.status, values(ALLOWED_SET_AS_DRAFT_STATUS))
        && this.hasAccessToContractEdit
      )
    },
    shouldShowConfirmButton() {
      return (
        includes(this.contractData?.status, values(ALLOWED_PEND_STATUS))
        && this.hasAccessToContractEdit
      )
    },
    shouldShowReviewButton() {
      return (
        propEq('pending', 'status', this.contractData)
        && this.userHasAccessToFunctionality.contractReview
      )
    },
    shouldShowPublishButton() {
      return (
        propEq('approved', 'status', this.contractData)
        && this.hasAccessToContractEdit
      )
    },
    shouldDisableButton() {
      return (
        this.isLoading
        || this.isPublishedContract
      )
    },
    shouldShowArchiveButton() {
      return this.userHasAccessToFunctionality.contractEdit
    },
    shouldShowJourneySettings() {
      return !equals('amparoConnected', this.contractData?.hmo?.packageType)
    },
  },
  validations() {
    return {
      contract: {
        name: {
          required,
          minLength: minLength(2),
        },
        description: {
          required,
        },
        requiredRqe: {
          required,
        },
      },
      activationJourneysConfiguration: {
        hasDefaultJourney: this.hasDefaultJourney,
        hasDuplicatedJourneys: this.hasDuplicatedJourneys,
      },
    }
  },
  async mounted() {
    this.setData()
    await this.setPageAndListItems()
    this.clinics = concat(
      [{
        name: 'Padrão',
        id: null,
      }],
      await this.listClinic(),
    )
  },
  methods: {
    ...mapActions({
      approveContract: 'billing/approveContract',
      archiveContract: 'billing/archiveContract',
      getContract: 'billing/getContract',
      listClinic: 'clinic/listClinic',
      listContractItem: 'billing/listContractItem',
      pendContract: 'billing/pendContract',
      publishContract: 'billing/publishContract',
      rejectContract: 'billing/rejectContract',
      setContractItemPage: 'billing/setContractItemPage',
      setSnackbar: 'snackbar/setSnackbar',
      updateContract: 'billing/updateContract',
      uploadContract: 'billing/uploadContract',
    }),
    hasDefaultJourney(journeys) {
      if (isEmpty(journeys)) return true
      const defaultJourney = find(propEq(null, 'clinicId'), journeys)

      return !isNil(defaultJourney)
    },
    hasDuplicatedJourneys(journeys) {
      return pipe(
        groupBy(prop('clinicId')),
        values,
        any(journey => length(journey) > 1),
        not,
      )(journeys)
    },
    async uploadExcelFile(file) {
      const data = await uploadContractItems(file)
      const params = { contractId: this.contractData.id, data }
      try {
        await this.uploadContract({ params })
        this.handleContractItemSuccess()
        await this.setPageAndListItems()
      } catch (err) {
        this.handleContractItemError(file, err)
      }
    },
    getHandleStatusChangeMethodName(status) {
      return cond([
        [equals('published'), always('handlePublishContract')],
        [equals('pending'), always('handlePendContract')],
        [equals('approved'), always('handleApproveContract')],
        [equals('rejected'), always('handleRejectContract')],
        [equals('draft'), always('handleDraftContract')],
      ])(status)
    },
    async closeContractStatusChangeDialog() {
      this.showContractStatusChangeDialog = false
      this.statusToChange = null
      this.statusToChangeHandleMethod = null
      await this.getContract(this.contractData.id)
    },
    async handleStatusChange(observation) {
      const handleMethod = this.statusToChangeHandleMethod
      await this[handleMethod](observation)
      this.showContractStatusChangeDialog = false
      this.statusToChange = null
      this.statusToChangeHandleMethod = null
    },
    handleOpenContractStatusChangeDialogPend() {
      this.$v.$touch()
      if (this.$v.$error) {
        this.buildSnackbar('error', 'Preencha os campos obrigatórios para confirmar o contrato!')
        return
      }
      this.handleUpdateContract(false)
      this.openContractStatusChangeDialog('pending')
    },
    handleOpenContractStatusChangeDialogDraft() {
      if (propEq('draft', 'status', this.contractData)) return this.handleUpdateContract()

      return this.openContractStatusChangeDialog('draft')
    },
    handleOpenContractStatusChangeDialogPublish() {
      this.$v.$touch()
      if (this.$v.$error) {
        this.buildSnackbar('error', 'Preencha os campos obrigatórios para publicar o contrato!')
        return
      }
      this.openContractStatusChangeDialog('published')
    },
    openContractStatusChangeDialog(newStatus) {
      this.statusToChange = newStatus
      this.statusToChangeHandleMethod = this.getHandleStatusChangeMethodName(newStatus)
      this.showContractStatusChangeDialog = true
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    formatJourneyConfiguration(journeys = []) {
      return pipe(
        map(pick(['name', 'doctorSlots', 'clinicId'])),
        sortBy(prop('name')),
      )(journeys)
    },
    setData() {
      this.contract = pick(
        [
          'description',
          'healthProduct',
          'hmo',
          'id',
          'name',
          'requiredRqe',
        ],
        this.contractData,
      )

      this.activationJourneysConfiguration = this.formatJourneyConfiguration(
        this.contractData.journeys,
      )

      if (isNil(this.contract.requiredRqe)) this.contract.requiredRqe = false
    },
    redirectToViewContract() {
      this.$router.push(`/billing/view-contract/${this.contractData.id}`)
    },
    async handleRejectContract(observation) {
      try {
        await this.rejectContract({
          id: this.contractData.id,
          attributes: { observation },
        })
        this.buildSnackbar('success', 'Contrato rejeitado com sucesso!')
        this.redirectToViewContract()
      } catch (error) {
        this.buildSnackbar('error', 'Erro ao rejeitar contrato!')
      }
    },
    async handleDraftContract() {
      await this.handleUpdateContract()
      await this.getContract(this.contractData.id)
    },
    async handleArchiveContract() {
      try {
        await this.archiveContract(this.contractData.id)
        this.showArchiveContractDialog = false
        this.buildSnackbar('success', 'Contrato arquivado com sucesso!')
        this.redirectToViewContract()
      } catch (error) {
        this.buildSnackbar('error', 'Erro ao arquivar contrato!')
      }
    },
    formatContractDataWithJourneys(contract) {
      return {
        ...this.formatContractData(contract),
        journeys: this.formatJourneyConfiguration(this.activationJourneysConfiguration),
      }
    },
    formatContractData(contract) {
      return pipe(
        pick(['name', 'description', 'requiredRqe']),
        removeNilOrEmpty,
      )(contract)
    },
    async handlePendContract(observation) {
      try {
        await this.pendContract({
          id: this.contractData.id,
          attributes: {
            ...this.formatContractData(this.contract),
            observation,
          },
        })
        await this.getContract(this.contractData.id)
        this.buildSnackbar('success', 'Contrato em pendência com sucesso!')
      } catch (error) {
        this.buildSnackbar('error', 'Erro ao colocar contrato em pendência!')
      }
    },
    async handleApproveContract() {
      try {
        await this.approveContract(this.contractData.id)
        await this.getContract(this.contractData.id)
        this.buildSnackbar('success', 'Contrato aprovado com sucesso!')
      } catch (error) {
        this.buildSnackbar('error', 'Erro ao aprovar contrato!')
      }
    },
    async handleUpdateContract(showSnackbar = true) {
      try {
        await this.updateContract({
          id: this.contractData.id,
          attributes: this.formatContractDataWithJourneys(this.contract),
        })

        if (showSnackbar) {
          await this.getContract(this.contractData.id)
          this.buildSnackbar('success', 'Contrato atualizado com sucesso!')
        }
      } catch (error) {
        this.buildSnackbar('error', 'Erro ao atualizar contrato!')
      }
    },
    async handlePublishContract() {
      this.$v.$touch()
      if (this.$v.$error) {
        this.buildSnackbar('error', 'Preencha os campos obrigatórios para publicar o contrato!')
        return
      }
      try {
        await this.publishContract({
          id: this.contractData.id,
          attributes: this.formatContractData(this.contract),
        })
        this.buildSnackbar('success', 'Contrato publicado com sucesso!')
        this.redirectToViewContract()
      } catch (error) {
        this.buildSnackbar('error', 'Erro ao publicar contrato!')
      }
    },
    showSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    handleContractItemSuccess() {
      this.showSnackbar('success', 'Itens do Contrato adicionados com sucesso!')
    },
    handleContractItemError(file, err) {
      setTimeout(() => {
        this.$refs.myDropzone.removeFile(file[0])
      }, 7000)

      if (err.response.data.errorCode === 'contract_status_invalid') {
        this.showSnackbar('error', 'O contrato não pode ser alterado.')
        return
      }

      if (file[0].status === 'error') {
        this.shouldOpenInvalidFileTypeModal = true
        return
      }

      this.sheetErrors = err?.response?.data?.errors
      this.shouldOpenContractItemWorksheetErrorModal = true
      this.showSnackbar('error', 'Erro ao enviar a planilha')
    },
    addActivationJourneyConfiguration() {
      this.activationJourneysConfiguration = append(
        {
          clinic: null,
          doctorSlots: 1,
          name: `Jornada ${this.activationJourneysConfiguration.length + 1}`,
        },
        this.activationJourneysConfiguration,
      )
    },
    closeDeleteJourneyModal() {
      this.shouldOpenDeleteConfigurationModal = false
    },
    updateJourneyNameOnCascate() {
      for (let index = 0; index < this.activationJourneysConfiguration.length; index += 1) {
        this.activationJourneysConfiguration[index].name = `Jornada ${index + 1}`
      }
    },
    deleteJourneyConfiguration(index) {
      this.activationJourneysConfiguration = remove(index, 1, this.activationJourneysConfiguration)
      this.shouldOpenDeleteConfigurationModal = !this.shouldOpenDeleteConfigurationModal
      this.updateJourneyNameOnCascate()
    },
    shouldOpenDeleteJourneyModal(index) {
      this.journeyName = `Jornada ${index + 1}`
      this.journeyIndex = index
      this.shouldOpenDeleteConfigurationModal = true
    },
    async setPageAndListItems(contractItemPage) {
      this.setContractItemPage(contractItemPage)
      this.page = contractItemPage
      if (!isNil(this.contract.id)) await this.listContractItem({ contractId: this.contract.id })
    },
    closeInvalidFileTypeModal() {
      this.shouldOpenInvalidFileTypeModal = false
    },
    closeContractItemWorksheetErrorModal() {
      this.shouldOpenContractItemWorksheetErrorModal = false
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'
.card__dropzone
  width 100%

.form-subtitle
  font-size 18px

.dropzone
  height 300px
  width 100%
  border 2px dashed primary-color

#myDropzone > .dz-preview .dz-details
  background-color #55D058 !important

.dropzone-custom-content
  text-align center

.dropzone-custom-title
  color primary-color
  margin-top 60px !important

.dropzone-custom-title__icon
  color primary-color !important
  font-size 80px
  margin-bottom 10px

.btn-actions
  gap 16px

.filters__date-menu
  width 100%

.data-conflict__icon
  margin-right 10px
  color #ce1414 !important

.data-conflict__text
  font-size 16px

.activation-journey__title
  color #007200
</style>
