export default {
  isPatientOnActivationJourney: false,
  isFirstAppointment: false,
  shouldBlockNextAppointments: false,
  journeys: [
    {
      clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
      doctorSlots: 1,
    },
    {
      clinicId: null,
      doctorSlots: 1,
    },
  ],
  patientConditions: [],
  activationJourney: {},
}
