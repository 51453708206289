<template>
  <div class="text-center mt-5">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-chip
          class="m-2 p-2"
          color="#007200"
          outlined
          v-on="on"
        >
          <v-icon left>
            mdi-credit-card
          </v-icon>
          Crédito:
          <v-progress-circular
            v-if="isLoading"
            class="ml-4"
            :width="2"
            :size="25"
            color="primary"
            indeterminate
          />
          <span v-else>&nbsp;{{ summary.credit_card | formatMoney }}</span>
        </v-chip>
      </template>
      <span>Valor total obtido</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-chip
          class="ma-2"
          color="#007200"
          outlined
          v-on="on"
        >
          <v-icon left>
            mdi-credit-card-outlined
          </v-icon>
          Débito:
          <v-progress-circular
            v-if="isLoading"
            class="ml-6"
            :width="2"
            :size="25"
            color="primary"
            indeterminate
          />
          <span v-else>&nbsp;{{ summary.debit_card | formatMoney }}</span>
        </v-chip>
      </template>
      <span>Valor total obtido</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-chip
          class="ma-2"
          color="#007200"
          outlined
          v-on="on"
        >
          <v-icon left>
            mdi-cash
          </v-icon>
          Dinheiro:
          <v-progress-circular
            v-if="isLoading"
            class="ml-6"
            :width="2"
            :size="25"
            color="primary"
            indeterminate
          />
          <span v-else>&nbsp;{{ summary.money | formatMoney }}</span>
        </v-chip>
      </template>
      <span>Valor total obtido</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-chip
          class="ma-2"
          color="#007200"
          outlined
          v-on="on"
        >
          <v-icon left>
            mdi-help-circle-outlined
          </v-icon>
          Não Informado:
          <v-progress-circular
            v-if="isLoading"
            class="ml-6"
            :width="2"
            :size="25"
            color="primary"
            indeterminate
          />
          <span v-else>&nbsp;{{ summary.other | formatMoney }}</span>
        </v-chip>
      </template>
      <span>Valor total obtido</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-chip
          class="ma-2"
          color="#007200"
          outlined
          v-on="on"
        >
          <v-icon left>
            mdi-help-circle-outline
          </v-icon>
          Plano:
          <v-progress-circular
            v-if="isLoading"
            class="ml-4"
            :width="2"
            :size="25"
            color="primary"
            indeterminate
          />
          <span v-else>&nbsp;{{ summary.hmo | formatMoney }}</span>
        </v-chip>
      </template>
      <span>Valor total obtido</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'OrderSummaryChips',
  computed: {
    ...mapGetters({
      summary: 'billing/summary',
      isLoading: 'billing/isLoadingSummary',
    }),
  },
  async activated() {
    await this.getSummary()
  },
  methods: {
    ...mapActions({
      getSummary: 'billing/getSummary',
    }),
  },
}
</script>
