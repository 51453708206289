import axios from '@/lib/axios'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'

const initialState = {
  loading: false,
  professionals: [],
}

const mutations = {
  SET_PROFESSIONALS(state, payload) {
    state.professionals = payload
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
}

const actions = {
  getProfessional({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.get(`professional/${id}`)
        .then(resolve)
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  listProfessional({ commit }, filters = {}) {
    const params = removeNilOrEmpty(filters)
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.get('professional', { params })
        .then(({ data: { professionals } }) => {
          commit('SET_PROFESSIONALS', professionals)
          resolve(professionals)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  listAllProfessional() {
    return new Promise((resolve, reject) => {
      axios.get('professional/list_all')
        .then(({ data: { professionals } }) => {
          resolve(professionals)
        })
        .catch(reject)
    })
  },
  listProfessionalAgendaDays(_, filters = {}) {
    const params = removeNilOrEmpty(filters)
    return new Promise((resolve, reject) => {
      axios.get('professional/agenda_days', { params })
        .then(({ data: { agendaWeekdays } }) => {
          resolve(agendaWeekdays)
        })
        .catch(reject)
    })
  },
}

const getters = {
  professionals: state => state.professionals,
  loading: state => state.loading,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
