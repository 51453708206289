import { rest } from 'msw'
import baseUrl from '../../../src/utils/baseUrlTest'
import groupData from '../fixtures/groupData'

export default [
  rest.get(baseUrl('/billing/group'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(groupData),
  )),
  rest.post(baseUrl('/billing/group'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      id: 'gro_fake_id_01',
      ...req.body,
    }),
  )),
  rest.put(baseUrl('/billing/group/:id'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(req.body),
  )),
  rest.delete(baseUrl('/billing/group/:id'), (req, res, ctx) => res(
    ctx.status(200),
  )),
]
