import axios from '@/lib/axios'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'

export default {
  state: {
    loadingCommunication: false,
    communications: [],
    communicationFilters: {},
  },
  mutations: {
    SET_LOADING_COMMUNICATION(state, payload) {
      state.loadingCommunication = payload
    },
    SET_COMMUNICATIONS(state, payload) {
      state.communications = payload
    },
    SET_COMMUNICATION_FILTERS(state, payload) {
      state.communicationFilters = payload
    },
  },
  actions: {
    listCommunication({ commit, state }) {
      return new Promise((resolve, reject) => {
        const filters = removeNilOrEmpty(state.communicationFilters)
        commit('SET_LOADING_COMMUNICATION', true)
        axios.get('emr/communication', {
          params: filters,
        })
          .then(({ data: { communications } }) => {
            commit('SET_COMMUNICATIONS', communications)
            resolve(communications)
          })
          .catch(reject)
          .finally(() => commit('SET_LOADING_COMMUNICATION', false))
      })
    },
    createCommunication({ commit }, attributes) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_COMMUNICATION', true)
        axios.post('emr/communication', attributes)
          .then(({ data }) => resolve(data))
          .catch(reject)
          .finally(() => commit('SET_LOADING_COMMUNICATION', false))
      })
    },
    updateCommunication({ commit }, { id, attributes }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING_COMMUNICATION', true)
        axios.put(`emr/communication/${id}`, attributes)
          .then(({ data }) => resolve(data))
          .catch(reject)
          .finally(() => commit('SET_LOADING_COMMUNICATION', false))
      })
    },
    deleteCommunication(_, communicationId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/emr/communication/${communicationId}`)
          .then(resolve)
          .catch(reject)
      })
    },
    setCommunicationFilters({ commit }, filters) {
      commit('SET_COMMUNICATION_FILTERS', { ...filters })
    },
  },
  getters: {
    isLoadingCommunication: state => state.loadingCommunication,
    communications: state => state.communications,
    communicationConditions: state => state.communicationConditions,
    communicationFilters: state => state.communicationFilters,
  },
}
