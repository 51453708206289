import moment from 'moment'
import {
  and,
  equals,
  filter,
  identity,
  includes,
  isNil,
  keys,
  map,
  pipe,
} from 'ramda'

const AgendaRegisterMixin = {
  data: () => ({
    menuStartDay: '',
    menuEndDay: '',
    selectedDaysOfTheWeek: {},
    dayOfTheWeek: '',
    selectedRoom: '',
    selectedReserve: '',
    reason: '',
    otherReason: null,
    startDate: null,
    endDate: null,
    daysOfTheWeek: [
      {
        text: 'Domingo',
        value: 0,
      },
      {
        text: 'Segunda-feira',
        value: 1,
      },
      {
        text: 'Terça-feira',
        value: 2,
      },
      {
        text: 'Quarta-feira',
        value: 3,
      },
      {
        text: 'Quinta-feira',
        value: 4,
      },
      {
        text: 'Sexta-feira',
        value: 5,
      },
      {
        text: 'Sábado',
        value: 6,
      },
    ],
    startTime: '',
    startWorkHours: [],
    endTime: '',
    interval: 5,
    maxSlots: '',
    extended: ['1', '2', '3', '4'],
    healthMaintenanceOrganizations: null,
    roomsList: [],
    slotInterval: 0,
  }),
  computed: {
    formatedStartDate: {
      get() { return isNil(this.startDate) ? null : moment(this.startDate).utc().format('DD/MM/YYYY') },
      set() { this.startDate = null },
    },
    formatedEndDate: {
      get() { return isNil(this.endDate) ? null : moment(this.endDate).utc().format('DD/MM/YYYY') },
      set() { this.endDate = null },
    },
    buildEndWorkHourList() {
      if (!this.startTime) return []
      const clinicEndTime = this.clinic.endTime
      const endWorkHours = []
      const time = moment(this.startTime, 'HH:mm')
      while (moment(time, 'HH:mm') <= moment(clinicEndTime, 'HH:mm')) {
        time.add(this.interval, 'minutes')
        if (moment(time, 'HH:mm') > moment(clinicEndTime, 'HH:mm')) break
        endWorkHours.push(time.format('HH:mm'))
      }
      if (time.format('HH:mm') === moment(clinicEndTime, 'HH:mm').add(1, 'minutes').format('HH:mm')) {
        endWorkHours.push(time.add(-1, 'minutes').format('HH:mm'))
      }
      return endWorkHours
    },
    appointmentInterval() {
      const item = []
      for (let interval = 5; interval <= 60; interval += 5) {
        item.push({
          text: `${interval} minutos`,
          value: interval,
        })
      }
      return item
    },
  },
  created() {
    this.prepareFields()
  },
  methods: {
    buildstartWorkHoursList() {
      const time = moment(this.clinic.startTime, 'HH:mm')
      this.startWorkHours.push(time.format('HH:mm'))
      while (moment(time, 'HH:mm') < moment(this.clinic.endTime, 'HH:mm')) {
        time.add(5, 'minutes')
        if (moment(time, 'HH:mm') >= moment(this.clinic.endTime, 'HH:mm')) break
        this.startWorkHours.push(time.format('HH:mm'))
      }
    },
    isSameOrAfterStartDate() {
      return isNil(this.endDate) ? true : moment(this.endDate).isSameOrAfter(this.startDate)
    },
    isSameOrAfterToday() {
      const today = moment().format('YYYY-MM-DD')
      return moment(this.startDate).isSameOrAfter(today)
    },
    getDaysOftheWeek() {
      return pipe(
        filter(identity),
        keys,
        map(parseInt),
      )(this.selectedDaysOfTheWeek)
    },
    allowedDatesByMultipleDaysOfTheWeek(date) {
      return and(
        includes(moment(date).day(), this.getDaysOftheWeek()),
        moment(date).isAfter(moment().subtract(1, 'days')),
      )
    },
    allowedDates(date) {
      return and(
        equals(moment(date).day(), this.dayOfTheWeek),
        moment(date).isAfter(moment().subtract(1, 'days')),
      )
    },
    getRooms() {
      const params = {
        clinicId: this.clinic.id,
      }
      this.$http.get('room', { params })
        .then((res) => { this.roomsList = res.data.rooms })
        .catch(err => err)
    },
    getHealthMaintenanceOrganizations() {
      this.$http.get('health_maintenance_organization')
        .then((res) => {
          this.healthMaintenanceOrganizations = res.data.healthMaintenanceOrganizations
        })
        .catch(err => err)
    },
    resetFields() {
      this.menuStartDay = ''
      this.menuEndDay = ''
      this.dayOfTheWeek = ''
      this.selectedRoom = ''
      this.selectedReserve = ''
      this.reason = ''
      this.startDate = null
      this.endDate = null
      this.startTime = ''
      this.startWorkHours = []
      this.endTime = ''
      this.interval = 5
      this.maxSlots = ''
      this.healthMaintenanceOrganizations = null
      this.roomsList = []
      this.slotInterval = 0
      this.$v.$reset()
      this.prepareFields()
    },
    async prepareFields() {
      await this.getRooms()
      await this.getHealthMaintenanceOrganizations()
    },
    setErrorMessage(error) {
      if ((error.errors.startDate) === 'Start date should be equal or after than now') {
        return `Hora ou data inicial menor que atual. \n
          Por favor, escolha outro horário ou inicie uma agenda com a data de amanhã.`
      }
      if ((error.errors.startTime) === 'Start time should be equal or after than now') {
        return 'Hora inicial menor que atual.'
      }
      if (error.errors.agendaReserves) return 'Operadora não cadastrada.'
      if (error.errors.startTime || error.errors.endTime) return 'Horário inválido.'
      if (error.errors.slots === 'None slots found') return 'Não há agenda criada neste período.'
      if (error.errors.slots) return 'Espaço já ocupado.'
      if (error.errorCode === 'agenda_invalid') return 'Espaço de agenda já ocupado.'
      if (error.errorCode === 'agenda_delete_invalid') return 'Existem consultas registradas em períodos futuros.'
      if (error.errorCode === 'restriction_already_exists') return 'Não foi possível finalizar a solicitação, pois já existe restrição para o tempo definido'
      return 'Erro interno, tente novamente em alguns instantes. Caso o problema persiste entre em contato com o suporte.'
    },
  },
}

export default AgendaRegisterMixin
