import axios from '@/lib/axios'

const initialState = {
  patientTermAttachments: [],
  loading: false,
}

const mutations = {
  SET_PATIENT_TERM_ATTACHMENTS(state, payload) {
    state.patientTermAttachments = payload
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
}

const actions = {
  listPatientTermAttachment({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.get('/patient_term_attachment', { params })
        .then(({ data: { patientTermAttachments } }) => {
          commit('SET_PATIENT_TERM_ATTACHMENTS', patientTermAttachments)
          resolve(patientTermAttachments)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
  createPatientTermAttachment({ commit }, { file, params }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      axios.post('/patient_term_attachment', file, { params })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
        .finally(() => commit('SET_LOADING', false))
    })
  },
}

const getters = {
  patientTermAttachments: state => state.patientTermAttachments,
  isLoading: state => state.loading,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
