<template>
  <div>
    <v-layout
      column
    >
      <v-flex
        px-4
        py-12
        d-flex
        text-xs-left
        text-sm-center
        justify-center
      >
        <h1 class="title-h1">
          Olá {{ user.firstAndLastName }}!
        </h1>
      </v-flex>
      <v-divider />
      <site-map />
    </v-layout>
    <v-dialog
      :value="!hasAcceptedTerms"
      transition="dialog-bottom-transition"
      persistent
      max-width="800"
    >
      <v-card>
        <terms-of-service
          v-if="!hasAcceptedTerms"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SiteMap from './SiteMap'

export default {
  name: 'IndexPage',
  components: {
    SiteMap,
    TermsOfService: () => import('./TermsOfService'),
  },
  data() {
    return {
      showTermsOfService: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'authentication/user',
      hasAcceptedTerms: 'authentication/hasAcceptedTerms',
    }),
  },
  mounted() {
    this.setHasAcceptedTerms()
  },
  methods: {
    ...mapActions({
      setHasAcceptedTerms: 'authentication/setHasAcceptedTerms',
    }),
  },
}
</script>
