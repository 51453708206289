export default {
  themes: {
    light: {
      primary: '#55D058',
      secondary: '#B0BEC5',
      accent: '#8C9EFF',
      error: '#B71C1C',
      success: '#55D058',
      warning: '#FFC107',
      info: '#3975ba',
      danger: '#d43f33',

      dustyGray: '#9c9c9c',
      japaneseLaurel: '#018701',
      internationalKleinBlue: '#071dad',
      rioGrande: '#cad100',
      mineShaft: '#222',
      grenadier: '#c42e00',
      goldDrop: '#e88300',
      eveningSea: '#034C45',
      gray: '#868686',

      'dark-gray': '#212121',
      'thin-gray': '#e0e0e0',
      'medium-gray': '#868686',
      'dark-green': '#007200',
      'light-green': '#a4dca3',
      'lighter-green': '#e8f8e8',
      'amparo-green': '#00AF66',
      'amparo-orange': '#FF7F32',
      'amparo-medium-green': '#007200',
      'amparo-light-green': '#6DD36E',
      'amparo-gray': '#707372',
      'tech-red': '#B71C1C',
      'tech-yellow': '#CAD100',
      'medium-red': '#BC204B',
    },
  },
}
