import { rest } from 'msw'
import contractListData from '../fixtures/billing/contract/list'
import contractGetByIdData from '../fixtures/billing/contract/getById'
import baseUrl from '../../../src/utils/baseUrlTest'

export default [
  rest.get(baseUrl('/billing/contract'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(contractListData),
  )),
  rest.get(baseUrl('/billing/contract/:id'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(contractGetByIdData),
  )),
  rest.post(baseUrl('/billing/contract/clone'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({}),
  )),
  rest.patch(baseUrl('/billing/contract/publish/multiple'), (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({}),
  )),
]
