<template>
  <div>
    <component-title />
    <schedule-filters />
    <schedule-list-container />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'

import ScheduleFilters from './ScheduleFilters'
import ScheduleListContainer from './ScheduleListContainer'

export default {
  name: 'ScheduleContainer',
  components: {
    ComponentTitle,
    ScheduleFilters,
    ScheduleListContainer,
  },
}
</script>
