<template>
  <v-layout class="ages">
    <v-flex xs12>
      <v-data-table
        :key="key"
        class="careline__table"
        :headers="headers"
        :items="careLineAges"
        hide-default-footer
        disable-pagination
        :search="search"
        no-data-text="Nenhum diagnóstico ativo"
      >
        <template #item="{ item }">
          <tr>
            <td>{{ item.name }}</td>
            <td>{{ item.initialAge }}</td>
            <td>{{ item.endAge }}</td>
            <td>
              <v-btn
                icon
                dark
                @click="showDeleteDialog(item.id)"
              >
                <v-icon color="error">
                  mdi-close-circle
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
    <v-dialog
      v-model="deleteDialog"
      max-width="450"
    >
      <v-card class="pa-3">
        <v-card-title class="text-h5">
          Deseja realmente excluir?
        </v-card-title>
        <v-card-actions>
          <v-row>
            <v-col xs6>
              <v-btn
                class="btn btn-primary"
                block
                @click="closeDeleteDialog"
              >
                Cancelar
              </v-btn>
            </v-col>
            <v-col xs6>
              <v-btn
                block
                color="error"
                @click="deleteActiveAge"
              >
                Excluir
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { inc } from 'ramda'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      search: null,
      headers: [
        {
          text: 'Nome da Faixa',
          value: 'name',
        },
        {
          text: 'Idade Inicial',
          value: 'initialAge',
        },
        {
          text: 'Idade Final',
          value: 'endAge',
        },
        {
          text: 'Excluir',
          value: '',
          sortable: false,
          width: '5%',
        },
      ],
      key: 0,
      deleteDialog: false,
      ageToDelete: null,
      refreshInterval: null,
    }
  },
  computed: {
    ...mapGetters({
      careLineAges: 'careLine/careLineAges',
    }),
  },
  mounted() {
    this.setRefreshListInterval()
  },
  beforeDestroy() {
    this.clearRefreshInterval()
  },
  deactivated() {
    this.clearRefreshInterval()
  },
  methods: {
    ...mapActions({
      listCareLineAge: 'careLine/listCareLineAge',
      deleteCareLineAge: 'careLine/deleteCareLineAge',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    setRefreshListInterval() {
      const REFRESH_TIME = 30000
      this.refreshInterval = setInterval(() => {
        this.listCareLineAge()
      }, REFRESH_TIME)
    },
    clearRefreshInterval() {
      clearInterval(this.refreshInterval)
    },
    assignmentStatusIcon({ assignmentStatus }) {
      const icons = {
        succeded: 'mdi-check',
        processing: 'mdi-progress-clock',
        failed: 'mdi-alert-circle-outline',
        queue: 'mdi-progress-clock',
      }
      return icons[assignmentStatus] || ''
    },
    assignmentStatusMessage({ assignmentStatus }) {
      const messages = {
        succeded: 'Concluído',
        processing: 'Atribuindo pacientes à linha de cuidado',
        failed: 'Erro ao atribuir pacientes à linha de cuidado, tente novamente',
        queue: 'Aguardando para atribuir pacientes à linha de cuidado',
      }
      return messages[assignmentStatus] || ''
    },
    assignmentStatusColor({ assignmentStatus }) {
      const colors = {
        succeded: 'primary',
        processing: 'warning',
        failed: 'error',
        queue: 'warning',
      }
      return colors[assignmentStatus] || ''
    },
    showDeleteDialog(id) {
      this.deleteDialog = true
      this.diagnosticToDelete = id
    },
    closeDeleteDialog() {
      this.deleteDialog = false
    },
    async deleteActiveAge() {
      try {
        await this.deleteCareLineAge(this.diagnosticToDelete)
        await this.listCareLineAge()
        this.setSnackbar({
          status: 'success',
          message: 'Faixa etária excluida com sucesso',
        })
        this.closeDeleteDialog()
      } catch (error) {
        this.setSnackbar({
          status: 'error',
          message: 'Erro ao deletar faixa etária',
        })
      }
    },
    resetTable() {
      this.key = inc(this.key)
    },
  },
}
</script>

<style lang="stylus" scoped>
.ages
  display flex
  flex-direction column

.careline__table
  width 100%

.table__status
  margin-top 20px !important
  margin-bottom @margin-top

.careline__list
  margin-top 5rem

.careline__assignment-status
  display: flex
  justify-content: center
  margin-top: 2rem

</style>
