<template>
  <div>
    <emr-encounter-resume
      :emr="emr"
      :is-print-mode="true"
      :should-show-minimized-questions="true"
      :should-show-section-name="shouldShowSectionName"
      :items-per-row="shouldShowSectionName ? 1 : 2"
    />
  </div>
</template>

<script>
import {
  path,
  any,
  equals,
} from 'ramda'
import EmrEncounterResume from '@/pages/Emr/EmrHistory/EmrEncounterResume'


export default {
  name: 'EmrEncounterResumePrint',
  components: {
    EmrEncounterResume,
  },
  props: ['emr'],
  computed: {
    shouldShowSectionName() {
      const sectionFormNames = ['SOAP']
      const encounterFormName = path(['emr', 'encounterFormName'], this)
      const result = any(equals(encounterFormName), sectionFormNames)
      return result
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../../style/_core/colors.styl'
</style>
