<template>
  <content-loader
    :width="600"
    :height="600"
    :speed="4"
    primary-color="#f3f3f3"
    secondary-color="#ecebeb"
  >
    <rect
      x="1%"
      y="3%"
      rx="0"
      ry="0"
      width="10%"
      height="1%"
    />
    <rect
      x="13.5%"
      y="3%"
      rx="0"
      ry="0"
      width="10%"
      height="1%"
    />
    <rect
      x="26%"
      y="3%"
      rx="0"
      ry="0"
      width="10%"
      height="1%"
    />
    <rect
      x="38.6%"
      y="3%"
      rx="0"
      ry="0"
      width="10%"
      height="1%"
    />
    <rect
      x="51.2%"
      y="3%"
      rx="0"
      ry="0"
      width="10%"
      height="1%"
    />
    <rect
      x="63.8%"
      y="3%"
      rx="0"
      ry="0"
      width="10%"
      height="1%"
    />
    <rect
      x="76.4%"
      y="3%"
      rx="0"
      ry="0"
      width="10%"
      height="1%"
    />
    <rect
      x="89%"
      y="3%"
      rx="0"
      ry="0"
      width="10%"
      height="1%"
    />
    <rect
      x="1%"
      y="6%"
      rx="0"
      ry="0"
      width="98%"
      height="4%"
    />

    <rect
      x="1%"
      y="13%"
      rx="0"
      ry="0"
      width="98%"
      height="4%"
    />

    <rect
      x="1%"
      y="20%"
      rx="0"
      ry="0"
      width="98%"
      height="4%"
    />
    <rect
      x="1%"
      y="27%"
      rx="0"
      ry="0"
      width="98%"
      height="4%"
    />
    <rect
      x="1%"
      y="34%"
      rx="0"
      ry="0"
      width="98%"
      height="4%"
    />
    <rect
      x="1%"
      y="41%"
      rx="0"
      ry="0"
      width="98%"
      height="4%"
    />
    <rect
      x="1%"
      y="48%"
      rx="0"
      ry="0"
      width="98%"
      height="4%"
    />
    <rect
      x="1%"
      y="55%"
      rx="0"
      ry="0"
      width="98%"
      height="4%"
    />
    <rect
      x="1%"
      y="62%"
      rx="0"
      ry="0"
      width="98%"
      height="4%"
    />
    <rect
      x="1%"
      y="69%"
      rx="0"
      ry="0"
      width="98%"
      height="4%"
    />
    <rect
      x="1%"
      y="76%"
      rx="0"
      ry="0"
      width="98%"
      height="4%"
    />
  </content-loader>
</template>

<script>
import {
  ContentLoader,
} from 'vue-content-loader'

export default {
  components: {
    ContentLoader,
  },
}
</script>
