<template>
  <div>
    <v-layout
      v-if="encounterData"
      :class="{
        'my-3': row,
        'justify-space-around': row,
        'column': column,
      }"
    >
      <v-flex
        v-for="(stage, stageIndex) in encounterData.legacy.Stages"
        :key="stageIndex"
        :class="{
          'card__infos-container--partial-width': row,
          'card__infos-container--full-width': column,
          'ma-0': true,
          'pa-0': true,
        }"
        column
      >
        <v-flex
          v-for="(topic, topicIndex) in stage.Topics"
          :key="stage.Label+topicIndex"
          column
        >
          <div v-if="topic.Items.length > 0">
            <v-flex class="card__group-title mb-1 mt-2">
              <span
                v-if="stage.Label != topic.Label"
                class="mr-2"
              >
                {{ stage.Label }}
                <span
                  v-if="topic.Label"
                >
                  -
                </span>
              </span>
              <span
                v-if="topic.Label"
                class="mr-2"
              >
                {{ topic.Label }}
              </span>
            </v-flex>
            <v-flex
              v-for="item in topic.Items"
              :key="stageIndex+topicIndex+item.Label"
              class="mb-1 mt-0"
            >
              <span
                v-if="item.Label"
                class="mr-2 font-weight-bold text--nowrap"
              >
                {{ item.Label }}
                <span
                  v-if="item.Text"
                >
                  :
                </span>
              </span>
              <span
                v-if="item.Text"
                class="mr-2"
              >
                {{ item.Text }}
              </span>
            </v-flex>
          </div>
        </v-flex>
      </v-flex>
      <v-flex
        v-if="encounterData.legacy.Diagnostics.length > 0"
        class="card__group-title mb-1 mt-2"
      >
        Diagnósticos
      </v-flex>
      <v-flex
        v-for="(diagnostic, diagnosticIndex) in encounterData.legacy.Diagnostics"
        :key="'diag'+diagnosticIndex"
      >
        <v-flex v-if="diagnostic.Disease">
          {{ diagnostic.Disease.Label }}
        </v-flex>
      </v-flex>
      <v-flex
        v-if="encounterData.legacy.RequestedExams.length > 0"
        class="card__group-title mb-1 mt-2"
      >
        Exames Requeridos
      </v-flex>
      <v-flex
        v-for="(requestedExam, requestedExamIndex) in encounterData.legacy.RequestedExams"
        :key="'req'+requestedExamIndex"
      >
        <v-flex v-if="requestedExam.Exam">
          {{ requestedExam.Exam.name }}
        </v-flex>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: [
    'encounterData',
    'row',
    'column',
  ],
}
</script>

<style lang="stylus" scoped>
@import '../../../style/_core/colors.styl'

info-container-position(directionIsRow = true)
  if directionIsRow
    width 20%
    margin-left 20px
    margin-right 20px
  else
    margin-top 5%
    width 100%

.card__infos-container--full-width
  info-container-position(false)

.card__infos-container--partial-width
  info-container-position()

.card__group-title
  flex 0
  margin-bottom 1.2rem
  color dark-green
  font-size 1.2em

</style>
