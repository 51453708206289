<template>
  <v-expansion-panel class="transparent">
    <v-expansion-panel-header
      class="py-2"
      hide-actions
    >
      <template v-slot:default="{ open }">
        <v-flex justify-space-between>
          <v-flex>
            <v-icon class="flex-grow-0 flex-shrink-0 mr-4">
              {{ open ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
            <span class="text-h6 medium-gray-text font-weight-medium">
              {{ title }}
            </span>
          </v-flex>
          <slot name="actions" />
        </v-flex>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="medium-gray-text">
      <slot />
      <v-flex
        v-if="shouldNotShowList"
        class="font-weight-medium"
      >
        {{ noShowMessage }}
      </v-flex>
      <ul v-else>
        <li
          v-for="item in list"
          :key="item.id"
        >
          <v-row
            class="flex-nowrap"
            align="center"
            no-gutters
          >
            <v-col
              class="font-weight-medium"
              v-bind="getSectionColsSize(item)"
            >
              <v-tooltip
                bottom
                max-width="450"
              >
                <template #activator="{ on }">
                  <span
                    class="d-block text-truncate mr-1"
                    v-on="shouldShowTextTooltip(formatLabel(item)) && on"
                  >
                    {{ formatLabel(item) }}
                  </span>
                </template>
                {{ formatLabel(item) }}
              </v-tooltip>
            </v-col>
            <v-col cols="auto">
              <v-chip
                v-if="item.denied"
                class="tag--denied"
                color="red"
                text-color="white"
                label
                x-small
                disabled
              >
                negado
              </v-chip>
            </v-col>
          </v-row>
        </li>
      </ul>
    </v-expansion-panel-content>
    <v-divider />
  </v-expansion-panel>
</template>

<script>
import {
  path,
  isEmpty,
} from 'ramda'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
    formatLabel: {
      type: Function,
      default: ({ name }) => name,
    },
    noShowMessage: {
      type: String,
      default: '',
    },
  },
  computed: {
    shouldNotShowList() {
      return isEmpty(this.list)
    },
  },
  methods: {
    shouldShowTextTooltip(text, maxLength = 16) {
      if (text.length >= maxLength) return true
      return false
    },
    getSectionColsSize(item) {
      if (path(['name', 'length'], item) < 30) return { cols: 'auto' }
      if (path(['denied'], item) || path(['prescriptions'], item)) {
        return {
          cols: 6,
          md: 7,
          lg: 8,
          xl: 9,
        }
      }
      return { cols: 11 }
    },
  },
}
</script>

<style lang="stylus" scoped>
.v-chip--disabled
  opacity 1
</style>
