import { CLINIC } from 'amparo-enums'

const moment = require('moment')
const {
  equals,
  lte,
  gte,
  includes,
  and,
  converge,
  T,
  or,
  propEq,
  path,
} = require('ramda')

const MEDICAL_PROFESSION_ID = 'profession_cjz3an5zk00030nxbejbsg6wj'
const NURSE_PROFESSION_ID = 'profession_cjz3an5zj00020nxbgcea9qh5'
const NUTRI_PROFESSION_ID = 'profession_cjzbz1g7t000gb0vo907k3kv3'
const PSICO_PROFESSION_ID = 'profession_cjzbz9pgk000hb0vogejnajuo'
const NURSE_TEC_PROFESSION_ID = 'profession_cjz3an5zk00040nxbf1ple8ju'
const PHARMACEUTICAL_PROFESSION_ID = 'profession_clhp735y3000057qlgsd1193x'

const getAgeFromBirthDate = birthDate => moment().diff(moment(birthDate), 'years')

const adult = ({ patient: { birthDate } }) => gte(getAgeFromBirthDate(birthDate), 13)

const female = ({ patient: { biologicalSex } }) => equals('F', biologicalSex)

const male = ({ patient: { biologicalSex } }) => equals('M', biologicalSex)

const child = ({ patient: { birthDate } }) => lte(getAgeFromBirthDate(birthDate), 12)

const firstAppointment = T

const isMedicalDoctorEncounter = (
  { professional: { professionId } },
) => equals(
  professionId,
  MEDICAL_PROFESSION_ID,
)

const isDoctorEncounter = (
  { professional: { professionId } },
) => includes(
  professionId,
  [
    MEDICAL_PROFESSION_ID,
    NUTRI_PROFESSION_ID,
    PSICO_PROFESSION_ID,
  ],
)

const isNurseEncounter = (
  { professional: { professionId } },
) => includes(
  professionId,
  [
    NURSE_PROFESSION_ID,
    NURSE_TEC_PROFESSION_ID,
    PHARMACEUTICAL_PROFESSION_ID,
  ],
)

const isProfessionalNurseEncounter = (
  { professional: { professionId } },
) => equals(
  professionId,
  NURSE_PROFESSION_ID,
)

const isDoctorOrProfessionalNurseEncounter = converge(
  or,
  [isDoctorEncounter, isProfessionalNurseEncounter],
)

const isDoctorOrNurseEncounter = converge(or, [isDoctorEncounter, isNurseEncounter])

const isFirstDoctorOrNurseEncounter = converge(and, [isDoctorOrNurseEncounter, firstAppointment])

const isAirportClinicEncounter = ({ encounter }) => propEq(
  CLINIC.brasiliaAirport,
  'id',
  path(['clinic'], encounter) || {},
)

export default {
  adult,
  female,
  male,
  child,
  firstAppointment,
  isDoctorEncounter,
  isNurseEncounter,
  isDoctorOrProfessionalNurseEncounter,
  isDoctorOrNurseEncounter,
  isFirstDoctorOrNurseEncounter,
  isMedicalDoctorEncounter,
  isAirportClinicEncounter,
}
