export default {
  rooms: [
    {
      id: 'roo_ckn7qrhxf0000dndqc6g79f83',
      name: 'Consultório 1',
      clinic: {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    },
    {
      id: 'roo_ckn919a180000yfpq8abx3q06',
      name: 'Consultório 2',
      clinic: {
        id: 'cli_ckn7qpqml0000d5dq3mdjhkzc',
        name: 'Pinheiros',
      },
    },
  ],
}
