<template>
  <div>
    <v-form
      ref="form"
      class="login-form"
      @submit.prevent="submitNewPassword"
    >
      <v-text-field
        v-model="password"
        :type="passwordInput ? 'text' : 'password'"
        :append-icon="passwordInput ? 'mdi-eye-off' : 'mdi-eye'"
        label="Senha"
        clearable
        required
        filled
        :error-messages="$v.password.$error
          ? 'Por favor, insira a nova senha de no mínimo 8 caracteres' : ''"
        :error="$v.password.$error"
        @click:append="passwordInput = !passwordInput"
        @blur="$v.password.$touch()"
        @change="closeSnackbar"
      />
      <v-flex>
        <v-btn
          :disabled="$v.$error || !password || isSubmitting"
          :loading="isSubmitting"
          type="submit"
          color="primary"
          class="btn btn--tall font-weight-lightbold"
          large
          block
        >
          Criar nova senha
        </v-btn>
      </v-flex>
      <v-flex
        justify-center
        mt-1
      >
        <v-btn
          class="btn"
          to="/login"
          color="primary"
          text
        >
          Ir para login
        </v-btn>
      </v-flex>
    </v-form>
    <v-dialog
      v-model="alertInvalidToken"
      max-width="420"
    >
      <v-card>
        <v-card-title class="card-dialog__title">
          Requisição expirada
          <v-spacer />
          <v-btn
            icon
            @click="alertInvalidToken = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="card-dialog__content">
          <p>
            Para sua segurança, o prazo de validade para uma requisiçao de senha é de
            <strong>24 horas</strong>.
          </p>
          <p>
            Solicite uma nova requisição de senha.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-flex justify-center>
            <v-btn
              class="btn btn-primary"
              to="/login"
            >
              Ok!
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { required, minLength } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NewPasswordForm',
  data() {
    return {
      password: '',
      passwordInput: false,
      alertInvalidToken: false,
    }
  },
  validations() {
    return {
      password: { required, minLength: minLength(8) },
    }
  },
  computed: {
    ...mapGetters({
      isSubmitting: 'authentication/isSubmitting',
    }),
  },
  created() {
    this.setAlertIfInvalidToken()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
      closeSnackbar: 'snackbar/closeSnackbar',
      userNewPassword: 'authentication/userNewPassword',
    }),
    setAlertIfInvalidToken() {
      const tokenDate = moment(this.$route.query.createdAt, 'YYYY-MM-DD HH:mm')
        .subtract(3, 'hours')
        .format('YYYY-MM-DD HH:mm')
      const diffHours = moment().diff(tokenDate, 'hours')
      if (diffHours > 24) {
        this.alertInvalidToken = true
      }
    },
    submitNewPassword() {
      this.$v.$touch()
      if (this.$v.$error) return
      this.userNewPassword({
        email: this.$route.query.email.replace(' ', '+'),
        token: this.$route.query.token,
        password: this.password,
      })
        .then(() => {
          this.setSnackbar({
            status: 'success',
            message: 'Nova senha definida! Por favor, realizar login',
          })
          this.$router.push('/login')
        })
        .catch(() => {
          this.setSnackbar({
            status: 'error',
            message: 'Erro interno',
          })
        })
    },
  },
}
</script>

<style lang="stylus" scoped>
.card-dialog__content
  display flex
  flex-direction column
  text-align center
  justify-content center
  align-items center

.login-form
  background-color: #fff
  width: 98%
  margin: auto
  margin-bottom: 20px
  display: flex
  flex-direction: column
  justify-content: center
  box-shadow: 0 0 20px -4px rgba(78, 85, 89, 0.3)
  padding: 35px
  border-radius: 6px
  @media only screen and (min-width: 600px)
    width: 500px

.v-btn--large
  height 56px
  border-radius 8px
  letter-spacing 1px
</style>
