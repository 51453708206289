import axios from '@/lib/axios'

export default {
  state: {
    loadingCareLineTagPatient: false,
    careLineAvailableTags: [],
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loadingCareLineTagPatient = payload
    },
    SET_CARE_LINE_AVAILABLE_TAGS(state, payload) {
      state.careLineAvailableTags = payload
    },
  },
  actions: {
    listCareLineAvailableTags({ commit }, patientId) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios.get('/emr/care_line_tag/available', {
          params: { patientId },
        })
          .then(({ data: { availableCareLineTags } }) => {
            commit('SET_CARE_LINE_AVAILABLE_TAGS', availableCareLineTags)
            resolve(availableCareLineTags)
          })
          .catch((reject))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    createCareLineTagPatient({ commit }, attributes) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios.post('/emr/care_line_tag_patient', attributes)
          .then(resolve)
          .catch(reject)
          .finally(() => commit('SET_LOADING', false))
      })
    },
    updateCareLineTagPatientOption({ commit }, { tagId, careLineTagOptionId }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios.patch(`/emr/care_line_tag_patient/${tagId}`, { careLineTagOptionId })
          .then(resolve)
          .catch(reject)
          .finally(() => commit('SET_LOADING', false))
      })
    },
    deleteCareLineTagPatient({ commit }, careLineTagPatientId) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios.delete(`/emr/care_line_tag_patient/${careLineTagPatientId}`)
          .then(resolve)
          .catch(reject)
          .finally(() => commit('SET_LOADING', false))
      })
    },
  },
  getters: {
    isLoadingCareLineTagPatient: state => state.loadingCareLineTagPatient,
    careLineAvailableTags: state => state.careLineAvailableTags,
  },
}
