<template>
  <v-snackbar
    :value="activated"
    :color="status"
    :timeout="-1"
    top
    multi-line
  >
    <span
      class="snackbar__message"
      data-testid="snackbar__message--text"
    >
      {{ message }}
    </span>
    <v-btn
      v-if="button"
      class="ml-2"
      outlined
      small
      elevation="3"
      text
      @click="buttonClick"
    >
      {{ button.label }}
    </v-btn>
    <template v-slot:action="{ attrs }">
      <v-btn
        dark
        icon
        v-bind="attrs"
        @click="closeSnackbar"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      activated: 'snackbar/activated',
      status: 'snackbar/status',
      message: 'snackbar/message',
      button: 'snackbar/button',
    }),
  },
  methods: {
    ...mapActions({
      closeSnackbar: 'snackbar/closeSnackbar',
    }),
    buttonClick() {
      this.button.event()
      this.closeSnackbar()
    },
  },
}
</script>

<style lang="stylus" scoped>
.snackbar__message
  font-size 1.2em
</style>
