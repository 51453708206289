<template>
  <div>
    <div class="filters-container">
      <v-container
        :class="{
          'container--fluid': true,
          'px-12': $vuetify.breakpoint.smAndUp,
          'grid-list-lg': true,
        }"
      >
        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
            sm4
          >
            <v-autocomplete
              v-model="filters.careLineId"
              hide-details
              :items="careLineItems"
              item-text="name"
              item-value="id"
              clearable
              filled
              label="Linha de Cuidado"
              class="filters__field"
              @change="changedCareLineId"
              @click:clear="changedCareLineId"
            />
          </v-flex>
          <v-flex
            xs12
            sm4
          >
            <v-autocomplete
              v-model="filters.careLineTagId"
              hide-details
              :items="careLineTags"
              item-text="name"
              item-value="id"
              clearable
              filled
              label="Tag do Paciente"
              class="filters__field"
              @change="changedCareLineTag"
              @click:clear="changedCareLineTag"
            />
          </v-flex>
          <v-flex
            xs12
            sm4
          >
            <v-autocomplete
              v-model="filters.careLineTagOptionId"
              hide-details
              :items="careLineTagOptions"
              item-text="label"
              item-value="id"
              clearable
              filled
              label="Opção da Tag"
              class="filters__field"
              @change="getPatients"
              @click:clear="getPatients"
            />
          </v-flex>
          <v-flex
            xs12
            sm4
          >
            <v-select
              v-model="filters.hasNextAppointment"
              hide-details
              :items="hasNextAppointment"
              item-text="name"
              item-value="value"
              clearable
              filled
              label="Status Consulta"
              class="filters__field"
              @change="getPatients"
              @click:clear="getPatients"
            />
          </v-flex>
          <v-flex
            xs12
            sm4
          >
            <v-select
              v-model="filters.hasNextSchedule"
              hide-details
              :items="hasNextSchedule"
              item-text="name"
              item-value="value"
              clearable
              filled
              label="Status Tarefas"
              class="filters__field"
              @change="getPatients"
              @click:clear="getPatients"
            />
          </v-flex>
          <v-flex
            xs12
            sm4
          >
            <v-autocomplete
              v-model="filters.status"
              hide-details
              :items="status"
              item-text="name"
              item-value="value"
              clearable
              filled
              label="Status Engajamento"
              class="filters__field"
              @change="getPatients"
              @click:clear="getPatients"
            />
          </v-flex>
          <v-flex
            xs12
            sm4
          >
            <v-autocomplete
              v-model="filters.careTeamId"
              hide-details
              :items="careTeams"
              item-text="name"
              item-value="id"
              filled
              label="Equipe"
              class="filters__field"
              :error="$v.filters.careTeamId.$error"
              @change="getPatients"
              @click:clear="getPatients"
            />
          </v-flex>
          <v-flex
            xs12
            sm4
          >
            <v-autocomplete
              v-model="filters.healthMaintenanceOrganizationId"
              hide-details
              :items="hmos"
              item-text="name"
              item-value="id"
              clearable
              filled
              label="Operadora de Saúde"
              class="filters__field"
              @change="getPatients"
              @click:clear="getPatients"
            />
          </v-flex>
          <v-flex
            xs12
            sm4
          >
            <v-menu
              v-model="menuDate"
              class="filters__date-menu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              :nudge-right="40"
              max-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="formatFilterDateToDDMMYYYY"
                  readonly
                  label="Data da tarefa"
                  append-icon="mdi-calendar"
                  clearable
                  filled
                  v-on="on"
                  @click:clear="filters.nextScheduleDate = null; getPatients()"
                />
              </template>
              <v-date-picker
                v-model="filters.nextScheduleDate"
                locale="pt-br"
                color="primary"
                no-title
                @change="getPatients"
              />
            </v-menu>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <v-container
      v-if="isLoading"
      justify-center
      mt-12
      class="fill-height"
    >
      <circular-loader />
    </v-container>
    <v-container
      v-else
      :class="{
        'container--fluid': true,
        'px-6': $vuetify.breakpoint.smAndUp,
      }"
    >
      <v-data-table
        :headers="headers"
        :items="patients"
        hide-default-footer
        class="data-table__header"
      >
        <template v-slot:item="{ item }">
          <tr
            :class="{
              'data-table__tr': true,
              'tr-item__alert-highlight': shouldHightlightPatient(item.formsPatientCount),
            }"
          >
            <td>{{ item.healthInsurancePlanNumber }}</td>
            <td>{{ item.healthMaintenanceOrganizationName }}</td>
            <td>
              <v-layout
                d-flex
                column
              >
                <v-flex mb-1>
                  <router-link
                    class="timeline__patient-name font-weight-lightbold patient-profile-link"
                    :to="{ name: 'patientProfile', params: { id: item.id }}"
                  >
                    {{ item | formatDisplayName }}
                  </router-link>
                </v-flex>
                <v-flex mb-1>
                  <patient-medical-follow-up-accept-info-chip
                    :patient="item"
                  />
                </v-flex>
                <v-flex mb-1>
                  {{ item | getIdentifierDocument }}
                </v-flex>
                <v-flex mb-1>
                  {{ item.telphone | formatPhone }}
                </v-flex>
                <v-flex mb-1>
                  {{ item.email }}
                </v-flex>
              </v-layout>
            </td>
            <td>{{ item.status }}</td>
            <td>{{ item.birthDate | formatDate }}</td>
            <td>{{ item.birthDate | formatAge }}</td>
            <td>
              <template v-if="item.careLines">
                <div class="div__care-line">
                  {{ item.careLines }}
                </div>
              </template>
              <span v-else> - </span>
            </td>
            <td>
              <template v-if="item.lastAppointmentDate">
                <v-layout
                  d-flex
                  column
                >
                  <v-flex mb-1>
                    {{ item.lastAppointmentDate | formatDate }}
                  </v-flex>
                  <v-flex
                    v-if="item.lastAppointmentSpecialityName"
                    mb-1
                  >
                    {{ item.lastAppointmentSpecialityName }}
                  </v-flex>
                </v-layout>
              </template>
              <template v-else>
                Sem consultas
              </template>
            </td>
            <td
              v-if="item.lastAppointmentDate"
            >
              {{ calculateTimeFromLastAppointment(item.lastAppointmentDate) }}
            </td>
            <td v-else>
              -
            </td>
            <td>
              <v-layout>
                <v-flex>
                  <v-icon>mdi-calendar</v-icon>
                </v-flex>
                <v-flex>
                  <v-layout
                    v-if="shouldShowNextAppointment(item)"
                    d-flex
                    column
                    class="tr-item__next-procedure--active"
                  >
                    <v-flex mb-1>
                      {{ item.nextAppointmentDate | formatDate }}
                    </v-flex>
                    <v-flex mb-1>
                      ({{ handleStatusAppointment(item.nextAppointmentStatus) }})
                    </v-flex>
                  </v-layout>
                  <v-layout
                    v-else
                    class="tr-item__next-procedure--deactivate"
                  >
                    Sem previsão
                  </v-layout>
                </v-flex>
              </v-layout>
            </td>
            <td>
              <v-layout>
                <v-flex>
                  <v-icon>mdi-calendar</v-icon>
                </v-flex>
                <v-flex>
                  <v-layout
                    v-if="item.nextScheduleId"
                    d-flex
                    column
                    class="tr-item__next-procedure--active"
                  >
                    <v-flex mb-1>
                      {{ item.nextScheduleDate | formatDate }}
                    </v-flex>
                    <v-flex mb-1>
                      ({{ item.nextScheduleStatus }})
                    </v-flex>
                  </v-layout>
                  <v-layout
                    v-else
                    class="tr-item__next-procedure--deactivate"
                  >
                    Sem previsão
                  </v-layout>
                </v-flex>
              </v-layout>
            </td>
            <td>{{ item.careTeamName }}</td>
            <td>
              <v-chip
                :color="getChipColor(item.formsPatientCount)"
                dark
                class="chip__forms-lenght"
              >
                {{ item.formsPatientCount }}
              </v-chip>
            </td>
          </tr>
        </template>
      </v-data-table>
      <patient-radar-pagination
        :page="filters.page"
        @changePage="setPage"
      />
    </v-container>
  </div>
</template>

<script>
import {
  defaultTo,
  equals,
  find,
  hasPath,
  isNil,
  not,
  path,
  pipe,
  prop,
  propEq,
} from 'ramda'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import { APPOINTMENT } from 'amparo-enums'

const WITHOUT_CARE_LINES_ID = 'care_lin_off'

export default {
  name: 'PatientRadar',
  components: {
    CircularLoader: () => import('@/components/UI/CircularLoader'),
    PatientRadarPagination: () => import('./PatientRadarPagination'),
    PatientMedicalFollowUpAcceptInfoChip: () => import('@/components/Common/PatientMedicalFollowUpAcceptInfoChip'),
  },
  data() {
    return {
      headers: [
        {
          text: 'Marca Óptica',
          align: 'start',
          sortable: false,
        },
        {
          text: 'Operadora',
          align: 'start',
          sortable: false,
        },
        {
          text: 'Paciente',
          align: 'start',
          sortable: false,
        },
        {
          text: 'Status',
          align: 'start',
          sortable: false,
        },
        {
          text: 'Data Nasc',
          align: 'start',
          sortable: false,
        },
        {
          text: 'Idade',
          align: 'start',
          sortable: false,
        },
        {
          text: 'Linha de Cuidado',
          align: 'start',
          sortable: false,
        },
        {
          text: 'Última Consulta',
          align: 'start',
          sortable: false,
        },
        {
          text: 'Tempo sem Consulta',
          align: 'start',
          sortable: false,
        },
        {
          text: 'Próxima Consulta',
          align: 'start',
          sortable: false,
        },
        {
          text: 'Próxima Tarefa',
          align: 'start',
          sortable: false,
        },
        {
          text: 'Equipe',
          align: 'start',
          sortable: false,
        },
        {
          text: 'Formulários Pendentes',
          align: 'start',
          sortable: false,
        },
      ],
      filters: {
        careTeamId: null,
        page: 1,
      },
      hasNextAppointment: [
        { name: 'Com consulta agendada', value: true },
        { name: 'Sem consulta agendada', value: false },
      ],
      hasNextSchedule: [
        { name: 'Com tarefa agendada', value: true },
        { name: 'Sem tarefa agendada', value: false },
      ],
      status: [
        'Perdido',
        'Não Ativado',
        'Engajado',
        'Não engajado',
        'Desengajado',
        'Desativado',
        'Arquivado',
        'Ativado',
      ],
      menuDate: false,
    }
  },
  computed: {
    ...mapGetters({
      careTeams: 'careTeam/careTeamsWithNone',
      careLines: 'careLine/careLines',
      careLineTags: 'careLine/careLineTags',
      hmos: 'healthMaintenanceOrganization/hmos',
      isLoading: 'patientRadar/loading',
      patients: 'patientRadar/patients',
      count: 'patientRadar/count',
      numberOfPages: 'patientRadar/numberOfPages',
      user: 'authentication/user',
    }),
    careLineTagOptions() {
      return defaultTo([], pipe(
        find(propEq(this.filters.careLineTagId, 'id')),
        prop('careLineTagOptions'),
      )(this.careLineTags))
    },
    careLineItems() {
      return [
        ...this.careLines,
        { id: WITHOUT_CARE_LINES_ID, name: 'Sem linha de cuidado' },
      ]
    },
    formatFilterDateToDDMMYYYY() {
      return this.filters.nextScheduleDate
        ? moment(this.filters.nextScheduleDate).format('DD/MM/YYYY')
        : ''
    },
  },
  validations() {
    return {
      filters: {
        careTeamId: {
          notNull: a => !isNil(a),
        },
      },
    }
  },
  async mounted() {
    this.setCareTeamFilter()
    await this.listCareLine({ status: 'active' })
    await this.listCareTeam()
    await this.listPatients(this.filters)
    await this.listHmo()
    this.$v.filters.$touch()
  },
  methods: {
    ...mapActions({
      listCareTeam: 'careTeam/listCareTeam',
      setCareLine: 'careLine/setCareLine',
      listCareLine: 'careLine/listCareLine',
      listCareLineTag: 'careLine/listCareLineTag',
      listHmo: 'healthMaintenanceOrganization/listHmo',
      setSnackbar: 'snackbar/setSnackbar',
      listPatients: 'patientRadar/listPatients',
      getProfessional: 'professional/getProfessional',
    }),
    shouldShowNextAppointment(item) {
      return not(isNil(item.nextAppointmentDate))
        && moment().isBefore(moment(item.nextAppointmentDate))
    },
    getPatients(setDefaultPage = true) {
      this.$v.filters.$touch()

      if (this.$v.filters.$anyError) return

      if (setDefaultPage) {
        this.filters.page = 1
      }
      this.listPatients(this.filters)
    },
    calculateTimeFromLastAppointment(lastAppointmentDate) {
      if (!lastAppointmentDate) {
        return 'Sem consultas'
      }
      const daysFromLastAppointment = moment().diff(moment(lastAppointmentDate), 'days')
      if (daysFromLastAppointment < 0) {
        return 'Sem consultas'
      }

      return `${daysFromLastAppointment} dias`
    },
    handleStatusAppointment(value) {
      return prop('label', prop(value, APPOINTMENT.status))
    },
    getChipColor(formsLength) {
      return formsLength > 0 ? 'red' : 'grey'
    },
    shouldHightlightPatient(formsPatientCount) {
      return formsPatientCount > 0
    },
    shouldSearchByWithoutCareLinePaients(careLineId) {
      return equals(WITHOUT_CARE_LINES_ID, careLineId)
    },
    changedCareLineId(careLineId) {
      if (this.shouldSearchByWithoutCareLinePaients(careLineId)) {
        this.filters.careLineTagId = null
        this.filters.careLineTagOptionId = null
        this.filters.withoutCareLine = true
        this.getPatients()
      } else {
        this.filters.withoutCareLine = null
        this.getPatients()
        if (isNil(careLineId)) {
          this.filters.careLineTagId = null
          this.filters.careLineTagOptionId = null
          return
        }
        this.setCareLine({ id: careLineId })
        this.listCareLineTag()
      }
    },
    changedCareLineTag() {
      this.filters.careLineTagOptionId = null
      this.getPatients()
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    setPage(page) {
      this.filters.page = page
      this.getPatients(false)
    },
    async setCareTeamFilter() {
      const { data } = await this.getProfessional(this.user.professional.id)
      if (hasPath(['careTeams'], data)) {
        this.filters.careTeamId = path(['careTeams', 0, 'id'], data)
      }
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'

.filters-container
  width 100%
  height auto
  background-color #e8f8e8

.data-table__header
  padding 0px
  margin 0px

.data-table__header >>> .v-table thead
  background-color rgba(light-gray, .4)

.data-table__header >>> .v-table tbody tr
  padding 10px
  margin 10px !important

.data-table__header >>> .v-table tbody tr td
  padding 10px
  margin 10px !important

.data-table__header >>> .v-table thead th
  color $evening-sea !important

.tr-item__alert-highlight
  background-color #FFF6F6 !important

.chip__forms-lenght
  pointer-events none

.timeline__patient-name
  font-size 16px
  letter-spacing 0.25px
  color rgba(0, 0, 0, 0.87)

.patient-profile-link
  text-decoration none
  color $japanese-laurel

.filters__field
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.filters__date-menu
  width 100%

.div__care-line
  white-space: pre-line
</style>
