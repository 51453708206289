import axios from '@/lib/axios'
import {
  isEmpty,
} from 'ramda'

export default {
  state: {
    groups: [],
    hasContractItemGroups: false,
  },
  mutations: {
    SET_GROUPS(state, payload) {
      state.groups = payload
    },
    SET_HAS_CONTRACT_ITEMS_GROUPS(state, payload) {
      state.hasContractItemGroups = payload
    },
  },
  actions: {
    listGroups({ commit }, filters) {
      const params = isEmpty(filters?.name)
        ? {}
        : filters

      return new Promise((resolve, reject) => {
        axios.get('/billing/group', {
          params,
        })
          .then(({ data }) => {
            commit('SET_GROUPS', data)
            resolve(data)
          })
          .catch(reject)
      })
    },

    getHasContractItemGroups({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.get('/billing/contract_item_group/has_any', {
          params,
        })
          .then(({ data }) => {
            commit('SET_HAS_CONTRACT_ITEMS_GROUPS', data)
            resolve(data)
          })
          .catch(reject)
      })
    },

    createGroup(_, attributes) {
      return new Promise((resolve, reject) => {
        axios.post('/billing/group', attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    updateGroup(_, { id, attributes }) {
      return new Promise((resolve, reject) => {
        axios.put(`/billing/group/${id}`, attributes)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    removeDiseaseGroup(_, { id }) {
      return new Promise((resolve, reject) => {
        axios.delete(`/billing/disease_group/${id}`)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
    removeGroup(_, { id }) {
      return new Promise((resolve, reject) => {
        axios.delete(`/billing/group/${id}`)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(reject)
      })
    },
  },
  getters: {
    groups: state => state.groups,
    hasContractItemGroups: state => state.hasContractItemGroups,
  },
}
