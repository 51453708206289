import axios from '@/lib/axios'

const actions = {
  createCalculatorConditionItem(_, attributes) {
    return new Promise((resolve, reject) => {
      axios.post('/emr/calculator_condition_item', attributes)
        .then(({ data }) => resolve(data))
        .catch(reject)
    })
  },
  updateCalculatorConditionItem(_, { calculatorConditionItemId, attributes }) {
    return new Promise((resolve, reject) => {
      axios.put(`/emr/calculator_condition_item/${calculatorConditionItemId}`, attributes)
        .then(resolve)
        .catch(reject)
    })
  },
  deleteCalculatorConditionItem(_, calculatorConditionItemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/emr/calculator_condition_item/${calculatorConditionItemId}`)
        .then(resolve)
        .catch(reject)
    })
  },
}

export default {
  actions,
}
