<template>
  <div>
    <component-title />
    <v-container
      v-if="isLoadingGroups"
    >
      <v-row>
        <v-col
          class="d-flex mt-5 justify-center"
        >
          <circular-loader />
        </v-col>
      </v-row>
    </v-container>
    <disease-group-list
      v-else
    />
  </div>
</template>
<script>
import ComponentTitle from '@/components/ComponentTitle'
import { mapActions } from 'vuex'
import CircularLoader from '@/components/UI/CircularLoader'
import DiseaseGroupList from './DiseaseGroupList'

export default {
  name: 'DiseaseGroupPage',
  components: {
    ComponentTitle,
    DiseaseGroupList,
    CircularLoader,
  },
  data() {
    return {
      isLoadingGroups: false,
    }
  },
  async mounted() {
    this.isLoadingGroups = true
    await this.listGroups()
    this.isLoadingGroups = false
  },
  methods: {
    ...mapActions({
      listGroups: 'billing/listGroups',
    }),
  },
}
</script>
