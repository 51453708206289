import moment from 'moment'

const currentDate = moment().format('YYYY-MM-DD')

export default [
  {
    id: 'app_clmjmn5gs00830jrl04c8392w',
    interval: 30,
    status: 'scheduled',
    date: `${currentDate}T11:00:00.000Z`,
    startTime: '08:00:00',
    endTime: '08:14:59',
    isExtended: false,
    specialityId: 'spe_cjy92cswd001yi3voc4sjca8c',
    professionalId: 'pro_ck3ogpnob0002mhpqhgcf2s49',
    clinicId: 'cli_cjxyringi0000i2vog8g55l7q',
    patientId: 'pat_ck3hev3i10000xwpq686e15vr',
    agendaId: 'age_cl587ol4e00yj0jqa5xkcbsqr',
    roomId: 'roo_cjy8zhb880013i3vo0514gz8o',
    createdAt: '2023-09-14T20:32:26.476Z',
    waitingTime: null,
    activityLog: [
      {
        status: 'scheduled',
        userId: 'user_ck3ogz93d0000dvpq3c6gh540',
        userName: 'Devs + Pro',
        changedAt: '2023-09-14T20:32:26Z',
        eligibility: {
          time: '2023-07-10T13:13:40Z',
          user: 'devs+pro@ampa.ro',
          hmoId: 'hea_mai_org_cjy92rrfg002hi3vo8gra8asr',
          status: 'service-unavailable',
          manually: false,
          errorCode: 'eligibility_not_enabled',
          cardNumber: '123213223',
        },
        oldAppointmentId: 'app_clmguxbx9015u0ighbvu17a1v',
      },
    ],
    notes: null,
    type: 'Consulta Presencial',
    videoRoomId: null,
    virtualReceptionId: null,
    sequentialAppointmentId: null,
    previousAppointmentId: 'app_clmjmn5ro00860jrl3gyp0ri9',
    notifyFirstAppointment: false,
    isHmoWarningMessageRead: false,
    slots: [
      'slo_cl811bs9x00370ja6e2rzbeic',
    ],
    professional: {
      id: 'pro_ck3ogpnob0002mhpqhgcf2s49',
      name: 'Devs + Pro',
      documentNumber: '000009',
      documentType: 'CRP',
      documentState: 'SP',
      biologicalSex: 'M',
    },
    patient: {
      cpf: '22222222222',
      birthDate: '1950-06-07',
      id: 'pat_ck3hev3i10000xwpq686e15vr',
      name: 'Huguinho',
      socialName: null,
      telphone: '11972175764',
      email: 'teste@gmai.com',
      isSpecialNeeds: false,
      specialNeedsType: null,
      isFirstAppointment: false,
      foreignDocument: '',
      race: 'white',
      healthInsurancePlanNumber: null,
      externalIds: {
        portal: 'user_ckl2mq1i200002tdq81pz6lkd',
        portalError: null,
      },
      enterpriseId: null,
      healthMaintenanceOrganizationId: 'hea_mai_org_cjyhpwbog0000apvo8yz7ht75',
      healthProductId: 'hea_pro_cjyhpwbog0000apvo8yz7ht75',
      workSpaceId: 'wor_spa_ck9ek05y1000001mi8c4peb2t',
      lastCheckEligibility: {
        time: '2023-07-10T13:13:40Z',
        user: 'devs+pro@ampa.ro',
        hmoId: 'hea_mai_org_cjy92rrfg002hi3vo8gra8asr',
        status: 'service-unavailable',
        manually: false,
        errorCode: 'eligibility_not_enabled',
        cardNumber: '123213223',
      },
      healthMaintenanceOrganization: {
        id: 'hea_mai_org_cjyhpwbog0000apvo8yz7ht75',
        name: 'PARTICULAR',
        nickName: 'PARTICULAR',
        hasEligibilityCheck: false,
        warningMessage: 'Aerofolio\npara o meu palio',
      },
      healthProduct: {
        id: 'hea_pro_cjyhpwbog0000apvo8yz7ht75',
        healthMaintenanceOrganizationId: 'hea_mai_org_cjyhpwbog0000apvo8yz7ht75',
        name: 'PARTICULAR',
        nickName: 'PARTICULAR',
      },
    },
    videoRoom: null,
    order: {
      status: 'opened',
    },
    videoEncounterUrl: null,
    videoEncounterUrlPatient: null,
  },
]
