var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.encounterData)?_c('v-layout',{class:{
      'my-6 mx-2': _vm.row,
      'column': _vm.column,
    }},[_c('v-flex',{class:{
        'card__infos-container--partial-width': _vm.row,
        'card__infos-container--full-width': _vm.column,
      },attrs:{"column":""}},[_c('v-flex',{staticClass:"card__group-title"},[_vm._v(" Motivo da Anotação ")]),(!_vm.hasEvaluation)?_c('v-flex',{attrs:{"shrink":""}},[_vm._v(" Nenhum campo de avaliação foi preenchido. ")]):_vm._e(),_vm._l((_vm.questionsItems),function(questionItem){return _c('v-flex',{key:questionItem.fieldName,attrs:{"shrink":"","column":""}},[(_vm.getHasQuestionItems(questionItem))?_c('div',[_c('v-flex',{staticClass:"card__infos-sub-title"},[_vm._v(" "+_vm._s(questionItem.label)+" ")]),_vm._l((questionItem.data),function(questionItemData,index){return _c('v-flex',{key:index,staticClass:"card__infos-name"},[_vm._v(" • "+_vm._s(questionItemData.name)+" - "+_vm._s(_vm.formatStatus(questionItemData))+" ")])})],2):_vm._e()])})],2),_c('v-flex',{class:{
        'card__infos-container--partial-width': _vm.row,
        'card__infos-container--full-width': _vm.column,
      },attrs:{"ml-6":"","column":""}},[_c('v-flex',{staticClass:"card__group-title"},[_vm._v(" Prescrição ")]),(!_vm.treatments)?_c('v-flex',{attrs:{"shrink":""}},[_vm._v(" Nenhuma prescrição. ")]):_vm._e(),(_vm.treatments)?_c('v-flex',{attrs:{"column":""}},[_c('v-flex',{staticClass:"card__infos-sub-title"},[_vm._v(" Novas prescrições ")]),_vm._l((_vm.treatments),function(treatment,index){return _c('v-flex',{key:index,staticClass:"card__infos-name"},[_vm._v(" • "+_vm._s(treatment.name)+" - "+_vm._s(treatment.status)+" ")])})],2):_vm._e()],1)],1):_vm._e(),(_vm.row)?_c('v-divider',{staticClass:"mx-2"}):_vm._e(),(_vm.encounterData)?_c('v-layout',{class:{
      'my-6 mb-2 mx-2': _vm.row,
      'column': _vm.column,
    }},[_c('v-flex',{class:{
        'card__infos-container--partial-width': _vm.row,
        'card__infos-container--full-width': _vm.column,
      },attrs:{"column":""}},[_c('v-flex',{staticClass:"card__group-title"},[_vm._v(" Anotação ")]),(!_vm.evaluationAdditionalInformation)?_c('v-flex',{attrs:{"shrink":""}},[_vm._v(" Nenhuma anotação. ")]):_vm._e(),_c('v-flex',{staticClass:"card__infos-additional-information"},[_c('see-more',{attrs:{"limit-length":_vm.limitAdditionalInformationLength,"text":_vm.evaluationAdditionalInformation}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }