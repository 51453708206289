<template>
  <v-main>
    <keep-alive
      v-if="token"
      :max="10"
    >
      <router-view />
    </keep-alive>
    <router-view v-else />
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      token: 'authentication/token',
    }),
  },
}
</script>
