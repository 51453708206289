import { render, staticRenderFns } from "./UserFeedbackListContainer.vue?vue&type=template&id=c53f1734&scoped=true&"
import script from "./UserFeedbackListContainer.vue?vue&type=script&lang=js&"
export * from "./UserFeedbackListContainer.vue?vue&type=script&lang=js&"
import style0 from "./UserFeedbackListContainer.vue?vue&type=style&index=0&id=c53f1734&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c53f1734",
  null
  
)

export default component.exports