import axios from '@/lib/axios'

const initialState = {
  activePatient: {},
  queue: [],
  queueHistory: [],
  stats: {},
  queueHistoryCount: null,
  queueHistoryNumberOfPages: null,
}

const mutations = {
  SET_IMMEDIATE_CARE_QUEUE(state, payload) {
    state.queue = payload
  },
  SET_IMMEDIATE_CARE_ACTIVE(state, payload) {
    state.activePatient = payload
  },
  SET_IMMEDIATE_CARE_QUEUE_HISTORY_PAGINATION(state, { count, numberOfPages }) {
    state.queueHistoryCount = count
    state.queueHistoryNumberOfPages = numberOfPages
  },
  SET_IMMEDIATE_CARE_QUEUE_HISTORY(state, payload) {
    state.queueHistory = payload
  },
  SET_IMMEDIATE_CARE_STATS(state, payload) {
    state.stats = payload
  },
}

const actions = {
  async getQueue({ commit }, { status }) {
    const { data } = await axios.get('tele/immediate_care/queue', {
      params: {
        status,
      },
    })

    commit('SET_IMMEDIATE_CARE_QUEUE', data.immediateCareQueue)
  },
  async getActivePatient({ commit }, { professionalId }) {
    try {
      const { data } = await axios.get('tele/immediate_care', {
        params: {
          professionalId,
        },
      })

      commit('SET_IMMEDIATE_CARE_ACTIVE', data.immediateCare)
    } catch (error) {
      commit('SET_IMMEDIATE_CARE_ACTIVE', {})
    }
  },
  async getQueueHistory({ commit }, params) {
    const { data } = await axios.get('tele/immediate_care/queue', {
      params,
    })

    commit('SET_IMMEDIATE_CARE_QUEUE_HISTORY', data.immediateCareQueue)
    commit('SET_IMMEDIATE_CARE_QUEUE_HISTORY_PAGINATION', data)
  },
  async getStats({ commit }) {
    const { data } = await axios.get('/tele/immediate_care/stats')

    commit('SET_IMMEDIATE_CARE_STATS', data)
  },
}

const getters = {
  queue: ({ queue }) => queue,
  activePatient: ({ activePatient }) => activePatient,
  queueHistory: ({ queueHistory }) => queueHistory,
  queueHistoryCount: ({ queueHistoryCount }) => queueHistoryCount,
  queueHistoryNumberOfPages: ({ queueHistoryNumberOfPages }) => queueHistoryNumberOfPages,
  stats: ({ stats }) => stats,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
