<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <span
        class="activator-title"
        v-on="on"
      >
        Códigos Profissões
      </span>
    </template>
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>Códigos Profissões</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container
        :class="{
          'container--fluid': true,
          'px-12': $vuetify.breakpoint.smAndUp,
        }"
      >
        <template>
          <v-data-table
            :headers="headers"
            :items="professions"
            :footer-props="{
              'items-per-page-options': [30],
              'items-per-page-text': '',
            }"
            item-key="id"
            no-data-text="Nenhuma profissão encontrada"
          >
            <template v-slot:item="profession">
              <tr>
                <td>
                  {{ profession.item.id }}
                </td>
                <td>
                  {{ profession.item.name }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ProfessionCodeList',
  data() {
    return {
      headers: [
        { text: 'Código', value: 'id' },
        { text: 'Nome', value: 'name' },
      ],
      dialog: false,
    }
  },
  computed: {
    ...mapGetters({ professions: 'profession/professions' }),
  },
  mounted() {
    this.listProfession({ type: 'clinical' })
  },
  methods: {
    ...mapActions({ listProfession: 'profession/listProfession' }),
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.activator-title
  font-size 14px
  line-height 1.5
  letter-spacing 0.5px
  color #55d058
  cursor pointer
</style>
