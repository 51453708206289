export default {
  EXAMS: {
    SYSTOLIC_PRESSURE: 'exa_ck1jepl560005ttpq6qhpfs37',
    DIASTOLIC_PRESSURE: 'exa_ck1jepl560006ttpqc9meeam9',
    HEIGHT: 'exa_ck1jepl560001ttpqdbc7azyj',
    WEIGHT: 'exa_ck1jepl540000ttpq364x54mr',
  },
  CARE_LINES: {
    ARTERIAL_HYPERTENSION: 'care_lin_ckpy2pnln03g80m46bu2j37v3',
    OBESITY: 'care_lin_cl4d2gjao00140jlhhfrn9rf3',
  },
}
