<template>
  <json-csv
    class="csvComponent px-4 btn v-btn v-btn--large theme--light primary"
    v-bind="$props"
  >
    {{ label.toUpperCase() }}
  </json-csv>
</template>
<script>
import JsonCsv from 'vue-json-csv'

export default {
  components: {
    JsonCsv,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    file: {
      type: String,
      required: false,
      default: 'relatorio',
    },
    data: {
      type: Array,
      required: true,
    },
  },
}
</script>
<style scoped lang="stylus">
  .csvComponent
    cursor pointer
</style>
