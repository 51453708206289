export default {
  errorCode: 'contract_item_invalid',
  type: 'badRequestError',
  errorMessage: 'Contract item invalid',
  errors: {
    externalName: [
      {
        text: '"externalName" is required',
        row: 0,
      },
    ],
    name: [
      {
        text: '"name" is required',
        row: 0,
      },
    ],
    defaultSlots: [
      {
        text: '"defaultSlots" must be less than or equal to 2',
        row: 1,
      },
      {
        text: '"defaultSlots" must be a number',
        row: 2,
      },
    ],
    externalCode: [
      {
        text: '"externalCode" is required',
        row: 1,
      },
    ],
    diseaseGroup: [
      {
        text: 'Grupo 1 does not exist',
        row: 1,
      },
    ],
    hasReturnalByCid: [
      {
        text: '"hasReturnalByCid" must be one of [Sim, Não]',
        row: 1,
      },
      {
        text: '"hasReturnalByCid" is required',
        row: 2,
      },
    ],
    price: [
      {
        text: '"price" must be larger than or equal to 0',
        row: 1,
      },
      {
        text: '"price" must be a number',
        row: 2,
      },
    ],
    returnalDays: [
      {
        text: '"returnalDays" must be larger than or equal to 0',
        row: 1,
      },
      {
        text: '"returnalDays" must be larger than or equal to 0',
        row: 2,
      },
    ],
    tussCode: [
      {
        text: '"tussCode" is required',
        row: 1,
      },
      {
        text: '"tussCode" does not exist',
        row: 2,
      },
    ],
    amparoCode: [
      {
        text: '"amparoCode" does not exist',
        row: 2,
      },
    ],
    clinicName: [
      {
        text: '"clinicName" does not exist',
        row: 2,
      },
    ],
  },
}
