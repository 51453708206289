<template>
  <div>
    <component-title />
    <v-flex
      v-if="isLoadingContract"
      class="contract__loading"
    >
      <circular-loader />
    </v-flex>
    <contract-item-container
      v-else
    />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import { mapActions, mapGetters } from 'vuex'
import CircularLoader from '@/components/UI/CircularLoader'
import ContractItemContainer from './ContractItemContainer'

export default {
  name: 'ContractItemPage',
  components: {
    CircularLoader,
    ComponentTitle,
    ContractItemContainer,
  },
  computed: {
    ...mapGetters({
      isLoadingContract: 'billing/isLoadingContract',
    }),
  },
  async mounted() {
    const { contractId } = this.$route.params
    await this.getContract(contractId)
    await this.listContractItem({ contractId })
  },
  methods: {
    ...mapActions({
      getContract: 'billing/getContract',
      listContractItem: 'billing/listContractItem',
    }),
  },
}
</script>

<style lang="stylus" scoped>
.contract__loading
  margin-top 20px
  display flex
  justify-content center
</style>
