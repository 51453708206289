import { rest } from 'msw'
import sessionData from '../fixtures/sessionData'
import baseUrl from '../../../src/utils/baseUrlTest'

export default [
  rest.patch(baseUrl('/user/profile/work_space'), (req, res, ctx) => {
    const modifiedSessionData = { ...sessionData }
    modifiedSessionData.user.workSpaceId = req.body.workSpaceId

    return res(
      ctx.status(200),
      ctx.json(modifiedSessionData),
    )
  }),
]
