<template>
  <div>
    <div class="filters-container">
      <v-container
        :class="{
          'container--fluid': true,
          'px-12': $vuetify.breakpoint.smAndUp,
          'grid-list-lg': true
        }"
      >
        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
            sm6
            md3
          >
            <v-text-field
              v-model="filters.name"
              filled
              label="Buscar por nome"
              clearable
              no-data-text="Digite para buscar"
              @change="getClinics()"
              @click:clear="getClinics()"
            />
          </v-flex>
          <v-flex
            xs12
            sm6
            md3
          >
            <v-autocomplete
              v-model="filters.state"
              :items="formOptions.states"
              label="Buscar por estado"
              clearable
              append-icon
              filled
              hide-no-data
              @change="getClinics()"
              @click:clear="getClinics()"
            />
          </v-flex>

          <v-flex
            xs12
            sm6
            md3
          >
            <v-text-field
              v-model="filters.city"
              label="Buscar por cidade"
              clearable
              append-icon
              filled
              hide-no-data
              @change="getClinics()"
              @click:clear="getClinics()"
            />
          </v-flex>

          <v-flex
            xs12
            sm6
            md3
          >
            <v-btn
              class="btn-filter--height font-weight-lightbold"
              color="dark-green"
              dark
              block
              large
              @click="getClinics()"
            >
              <v-icon left>
                mdi-magnify
              </v-icon>
              Buscar Clínica
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <v-container
      :class="{
        'container--fluid': true,
        'px-12': $vuetify.breakpoint.smAndUp,
      }"
    >
      <v-layout
        v-if="isClinicsEmpty"
      >
        <h2 class="info-text font-weight-lighter">
          Nenhum resultado encontrado.
        </h2>
      </v-layout>
      <template v-else>
        <v-layout
          v-for="(clinic, index) in clinics"
          :key="index"
          class="list-item"
        >
          <v-flex
            md3
            sm6
            xs12
          >
            <div class="truncate">
              <span
                :class="{
                  'list-item__title': true
                }"
              >
                {{ clinic.name }}
                <v-chip
                  v-if="clinic.storedAt"
                  class="list__chip rounded ml-2"
                >
                  Arquivado
                </v-chip>
              </span>

              <div class="list-item__detail">
                {{ clinic.address.city }} - {{ clinic.address.state }}
              </div>
            </div>
          </v-flex>

          <v-flex
            md4
            sm6
            xs12
            column
            class="list-item__detail"
          >
            <div>
              {{ clinic.address.street }}
            </div>

            <div>
              Número: {{ clinic.address.number }}
              <span v-if="clinic.address.complement">
                - {{ clinic.address.complement }}
              </span>
            </div>

            <div>
              CEP: {{ clinic.address.zipCode | formatZipCode }}
            </div>

            <div>
              Cidade: {{ clinic.address.city }}
            </div>
            <v-divider class="hidden-sm-and-up" />
          </v-flex>

          <v-flex
            md2
            sm6
            xs12
            column
            class="list-item__detail"
          >
            <div>
              Abertura: {{ clinic.startTime | removeSecondsFromTime }}
            </div>

            <div>
              Fechamento: {{ clinic.endTime | removeSecondsFromTime }}
            </div>

            <v-divider class="hidden-sm-and-up" />
          </v-flex>

          <v-flex
            md2
            sm6
            xs12
            column
            class="list-item__detail"
          >
            <v-divider class="hidden-sm-and-up" />
          </v-flex>

          <v-flex
            md1
            justify-end
            class="list-item__icon"
          >
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  class="mr-2 font-weight-lightbold"
                  text
                  icon
                  :disabled="clinicIsArchived(clinic)"
                  @click="roomListDialog(clinic.id)"
                  v-on="on"
                >
                  <v-icon>mdi-sofa</v-icon>
                </v-btn>
              </template>
              <span>Salas</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  class="mr-2 font-weight-lightbold"
                  text
                  icon
                  :disabled="clinicIsArchived(clinic)"
                  @click="editDialog(clinic)"
                  v-on="on"
                >
                  <v-icon>mdi-account-edit</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip
              v-if="!clinic.storedAt"
              top
            >
              <template #activator="{ on }">
                <v-btn
                  class="mr-2"
                  text
                  icon
                  @click="setShowArchiveDialog(clinic)"
                  v-on="on"
                >
                  <v-icon>mdi-package-down</v-icon>
                </v-btn>
              </template>
              <span>Arquivar</span>
            </v-tooltip>
            <v-tooltip
              v-else
              top
            >
              <template #activator="{ on }">
                <v-btn
                  class="mr-2"
                  text
                  icon
                  @click="setShowArchiveDialog(clinic)"
                  v-on="on"
                >
                  <v-icon>mdi-package-up</v-icon>
                </v-btn>
              </template>
              <span>Restaurar</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </template>
      <v-flex v-if="numberOfPages > 1">
        <v-spacer />
        <v-pagination
          v-model="page"
          :length="numberOfPages"
          :total-visible="11"
        />
        <v-spacer />
        <span class="total-patients">
          {{ count }} paciente
          <span v-if="count > 1">s</span>
        </span>
      </v-flex>
    </v-container>

    <v-dialog
      v-model="showEditClinicDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1400"
    >
      <v-card>
        <v-card-title
          class="card-dialog__title"
        >
          Editar Clínica
          <v-spacer />
          <v-btn
            icon
            @click="exitEditDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <create-clinic
          v-if="showEditClinicDialog"
          edit-clinic
          :clinic-data="editedClinic"
          @exitEditDialog="exitEditDialog"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showRoomDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Salas da clínica</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="exitRoomListDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <room-list
          v-if="showRoomDialog"
          :id-clinic-room="idClinicRoom"
          @exitRoomList="exitRoomListDialog"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showStockDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Estoques da clínica</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            class="font-weight-lightbold"
            @click="exitStockListDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <clinic-stock-list
          v-if="showStockDialog"
          :id-clinic="idClinicRoom"
          @exitRoomList="exitStockListDialog"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showArchiveDialog"
      class="not-printable-area"
      max-width="450"
    >
      <v-card v-if="!selectedClinic.storedAt">
        <v-card-title class="text-subtitle-1">
          Deseja realmente arquivar essa clinica?
        </v-card-title>
        <v-card-text class="pb-0">
          <v-text-field
            v-model="selectedClinic.storedLog.reason"
            label="Motivo do arquivamento"
            :error="$v.selectedClinic.storedLog.reason.$error"
            :error-messages="$v.selectedClinic.storedLog.reason.$error
              ? 'Preenchimento obrigatório'
              : ''
            "
            @blur="$v.selectedClinic.storedLog.reason.$touch()"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="btn-dialog"
            color="green darken-1"
            text
            large
            @click="showArchiveDialog = false"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            class="btn-dialog"
            large
            color="error"
            @click="archiveClinic(selectedClinic)"
          >
            Arquivar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-text class="pb-0">
          Deseja realmente desarquivar a clínica {{ selectedClinic.name }}?
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="btn-dialog"
            color="green darken-1"
            text
            large
            @click="showArchiveDialog = false"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            class="btn-dialog"
            large
            color="primary"
            @click="unarchiveClinic(selectedClinic)"
          >
            Restaurar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  isEmpty,
  isNil,
} from 'ramda'
import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ClinicList',
  components: {
    CreateClinic: () => import('./CreateClinic'),
    RoomList: () => import('./RoomList'),
  },
  data() {
    return {
      editedClinic: {},
      idClinicRoom: '',
      selectedClinic: {
        storedLog: {
          reason: '',
        },
      },
      showErrorArchiveDialog: true,
      showArchiveDialog: false,
      showEditClinicDialog: false,
      showRoomDialog: false,
      showStockDialog: false,
      filters: {
        includeStored: true,
      },
      count: '',
      numberOfPages: null,
      page: 1,
      formOptions: {
        states: [
          'AC',
          'AL',
          'AM',
          'AP',
          'BA',
          'CE',
          'DF',
          'ES',
          'GO',
          'MA',
          'MT',
          'MS',
          'MG',
          'PA',
          'PB',
          'PR',
          'PE',
          'PI',
          'RJ',
          'RN',
          'RO',
          'RS',
          'RR',
          'SC',
          'SE',
          'SP',
          'TO',
        ],
      },
    }
  },
  validations() {
    return {
      selectedClinic: {
        storedLog: {
          reason: {
            required,
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      clinics: 'clinic/clinics',
    }),
    isClinicsEmpty() {
      return isEmpty(this.clinics)
    },
  },
  mounted() {
    this.getClinics()
  },
  methods: {
    ...mapActions({
      listClinic: 'clinic/listClinic',
      updateClinic: 'clinic/updateClinic',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    clinicIsArchived({ storedAt }) {
      return !isNil(storedAt)
    },
    getClinics() {
      this.listClinic(this.filters)
    },
    editDialog(clinicData) {
      this.editedClinic = clinicData
      this.showEditClinicDialog = true
    },
    exitEditDialog() {
      this.editedClinic = {}
      this.showEditClinicDialog = false
    },
    roomListDialog(clinicId) {
      this.idClinicRoom = clinicId
      this.showRoomDialog = true
    },
    exitRoomListDialog() {
      this.showRoomDialog = false
      this.getClinics()
    },
    stockListDialog(clinicId) {
      this.idClinicRoom = clinicId
      this.showStockDialog = true
    },
    exitStockListDialog() {
      this.showStockDialog = false
      this.getClinics()
    },
    async archiveClinic({ id }) {
      this.$v.$touch()
      if (this.$v.$error) return
      try {
        await this.updateClinic({
          id,
          attributes: {
            storedLog: {
              ...this.selectedClinic.storedLog,
              action: 'archive',
            },
            storedAt: new Date(),
          },
        })

        this.setSnackbar({
          status: 'success',
          message: 'Clínica arquivada com sucesso',
        })
      } catch ({ response }) {
        const { errorCode } = response.data

        let message = 'Não foi possível concluir a solicitação, para mais informações entre em contato com o suporte.'
        if (errorCode === 'archiving_unavailable') {
          message = 'Não foi possível concluir a solicitação, há agenda em aberto para esta clínica.'
        }

        this.setSnackbar({
          status: 'error',
          message,
        })
      }

      this.closeArchiveDialog()
    },
    async unarchiveClinic({ id }) {
      await this.updateClinic({
        id,
        attributes: {
          storedAt: null,
          storedLog: {
            ...this.selectedClinic.storedLog,
            action: 'unarchive',
          },
        },
      })

      this.closeArchiveDialog()
    },
    setShowArchiveDialog(clinic) {
      this.selectedClinic = {
        ...clinic,
        storedLog: {
          reason: '',
        },
      }
      this.showArchiveDialog = true
    },
    closeArchiveDialog() {
      this.selectedClinic = {
        storedLog: {
          reason: '',
        },
      }
      this.showArchiveDialog = false
      this.$v.$reset()
      this.getClinics()
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'
.list-item
  width 100%
  padding 20px
  border-radius 8px
  border solid 1px #e0e0e0
  margin-bottom 10px
.list-item__title
  font-size 24px
  color rgba(0, 0, 0, 0.87)
.list-item__detail
  font-size 16px
  line-height 2
  letter-spacing 0.5px
  color rgba(0, 0, 0, 0.6)

.list-item__icon
  margin-top -8px
.info-text
  letter-spacing -0.5px
  color rgba(0, 0, 0, 0.38)
  @media only screen and (min-width 601px)
    font-size 32px
  @media only screen and (min-width 1201px)
    font-size 48px
  @media only screen and (min-width 1601px)
    font-size 64px
.filters-container
  width 100%
  height auto
  background-color #e8f8e8
  @media only screen and (min-width 601px)
    height 108px
.v-card
  padding 10px
  border-radius 6px
.v-btn
  letter-spacing 1px
.v-btn.v-btn--large
  margin-top 0px
  height 56px
  border-radius 8px
.v-btn.v-btn--text
  border solid 1px thin-gray
.v-btn.btn-dialog
  padding 15px
  @media all and (min-width 601px)
    width 190px
</style>
