<template>
  <v-container
    class="mt-5"
    fluid
    fill-height
  >
    <v-layout
      align-center
      align-content-center
      justify-center
      column
    >
      <div class="watermark hidden-mobile">
        <img src="@/assets/logo-watermark.png">
      </div>
      <v-flex
        d-flex
        text-xs-center
        justify-center
      >
        <img
          width="400"
          class="logo"
          src="@/assets/logo-small.svg"
        >
      </v-flex>
      <v-flex
        class="container__new-password"
        d-flex
        mt-12
        text-xs-center
      >
        <v-layout column>
          <h1 class="title-h1 mb-12">
            {{ pageTitle }}
          </h1>
          <component :is="componentToLoad" />
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { isEmpty } from 'ramda'
import NewPasswordForm from './NewPasswordForm'
import RequestNewPasswordForm from './RequestNewPasswordForm'

export default {
  components: {
    NewPasswordForm,
    RequestNewPasswordForm,
  },
  data() {
    return {
      componentToLoad: '',
      pageTitle: '',
    }
  },
  created() {
    this.handleFormToLoad()
  },
  methods: {
    handleFormToLoad() {
      if (isEmpty(this.$route.query)) {
        this.componentToLoad = 'RequestNewPasswordForm'
        this.pageTitle = 'Solicitar nova senha'
        return
      }
      this.componentToLoad = 'NewPasswordForm'
      this.pageTitle = 'Insira a nova senha'
    },
  },
}
</script>

<style lang="stylus" sccoped>

.watermark
  z-index 0

.container__new-password, .logo
  z-index 1

</style>
