<template>
  <v-btn
    class="btn btn-danger"
    large
    :block="buttonBlock"
    @click="handleCancel()"
  >
    {{ label }}
  </v-btn>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ENCOUNTER } from 'amparo-enums'

const { encounterFormNames } = ENCOUNTER

export default {
  emits: [
    'update:encounter-canceled',
    'validate:encounter-cancel',
  ],
  props: {
    customLabel: {
      type: String,
      required: false,
      default: null,
    },
    buttonBlock: {
      type: Boolean,
      required: false,
      default: false,
    },
    redirectAfterCancel: {
      type: Boolean,
      required: false,
      default: true,
    },
    validation: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      encounter: 'emr/encounter',
      patient: 'emr/patient',
    }),
    label() {
      if (this.customLabel) return this.customLabel
      if (this.encounter.encounterFormName === encounterFormNames.annotation) return 'Cancelar anotação'
      return 'Cancelar atendimento'
    },
  },
  methods: {
    ...mapActions({
      clearEncounter: 'emr/clearEncounter',
      clearHeightAndWeight: 'emr/clearHeightAndWeight',
      clearMedication: 'emr/clearMedication',
      updateEncounter: 'emr/updateEncounter',
    }),
    cancelEncounter() {
      return this.updateEncounter({
        status: 'canceled',
      })
        .then(() => {
          if (this.redirectAfterCancel) this.$router.push(`/emr/${this.patient.id}`)
          const { encounterFormName } = this.encounter
          this.clearEncounter()
          this.clearHeightAndWeight()
          this.clearMedication()
          if (encounterFormName === encounterFormNames.immediateCare) window.close()
        })
        .then(() => delete window.MdHub)
        .catch(err => err)
        .finally(() => this.$emit('update:encounter-canceled'))
    },
    handleCancel() {
      if (this.validation) {
        this.validation.$touch()
        if (this.validation.$error) return
      }

      this.cancelEncounter()
    },
  },
}
</script>
