<template>
  <div>
    <input
      ref="fileInput"
      type="file"
      :multiple="acceptMultiple"
      class="d-none"
      @change="addFiles($event.target.files)"
    >
    <v-container
      class="dropzone__container d-flex row no-gutters align-center flex-wrap"
      :class="{
        'dropzone__container--dashed': dashed
      }"
      :style="{
        'border-color': color,
        'border-radius': borderRadius,
      }"
      fluid
      @dragover.prevent
      @drop.prevent="addFiles($event.dataTransfer.files)"
      @click="selectFiles()"
    >
      <v-flex
        v-if="!files.length"
        column
      >
        <v-icon
          v-if="icon"
          class="dropzone-custom-title__icon"
          size="50px"
          :color="color"
        >
          {{ icon }}
        </v-icon>
        <p
          class="col-12 text-center mb-0"
          :style="{ 'color': color }"
        >
          {{ defaultMessage }}
        </p>
      </v-flex>
      <v-col
        v-for="(file, index) in files"
        v-else
        :key="index"
        cols="4"
        class="pa-4"
        @click.stop
      >
        <v-card
          class="d-flex column pa-4 cursor--default"
          color="#E9F0FE"
        >
          <div class="d-flex mt-n2 mr-n2">
            <v-spacer />
            <v-btn
              icon
              @click.stop
              @click="removeFile(file)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
          <v-img
            v-if="file.src"
            class="mb-2"
            height="80"
            contain
            :src="file.src"
          />
          <v-icon
            v-else
            class="mb-2"
            color="#1E77CC"
            size="80"
          >
            mdi-file-document
          </v-icon>
          <div class="dropzone__preview-text medium-gray-text">
            <p class="d-block mb-0 text-truncate text-center">
              {{ file.name }}
            </p>
            <dropzone-select-input
              v-if="selectInputData"
              ref="dropzoneSelectInput"
              class="mt-3 pt-2"
              :file-id="file.id"
              :data="selectInputData"
              @update:inputValue="updateFile"
            />
          </div>
        </v-card>
      </v-col>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable no-await-in-loop */
import {
  forEach,
  path,
  findIndex,
  find,
  propEq,
  clone,
  isNil,
  any,
  not,
  isEmpty,
  equals,
  omit,
} from 'ramda'
import { requiredIf } from 'vuelidate/lib/validators'
import DropzoneSelectInput from '@/components/Common/DropzoneSelectInput'
import { mapActions } from 'vuex'
import { read, utils } from 'xlsx'

export default {
  components: {
    DropzoneSelectInput,
  },
  props: {
    selectInputData: {
      type: Object,
      required: false,
      default: null,
    },
    acceptedFiles: {
      type: Array,
      required: false,
      default: () => [],
    },
    acceptMultiple: {
      type: Boolean,
      required: false,
      default: true,
    },
    minLinesLimit: {
      type: Number,
      required: false,
      default: null,
    },
    maxLinesLimit: {
      type: Number,
      required: false,
      default: null,
    },
    dashed: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultMessage: {
      type: String,
      required: false,
      default: 'Escolha ou arraste os arquivos para serem anexados',
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    color: {
      type: String,
      required: false,
      default: '',
    },
    borderRadius: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      files: [],
    }
  },
  validations() {
    const validations = {}

    forEach((file) => {
      validations[`selectInput-${file.id}`] = {
        requiredIf: requiredIf(() => this.selectInputData),
      }
    })(this.files)

    return validations
  },
  computed: {
    hasValidation() {
      return not(isNil(this.selectInputData))
    },
  },
  created() {
    if (not(this.hasValidation)) this.$emit('update:validationStatus', false)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
    }),
    triggerValidationTouch() {
      if (isNil(this.$refs.dropzoneSelectInput)) return
      forEach(input => input.$v.$touch())(this.$refs.dropzoneSelectInput)
      this.verifyValidation()
    },
    verifyValidation() {
      if (isNil(this.$refs.dropzoneSelectInput)) return
      const hasError = any(input => input.$v.$error)(this.$refs.dropzoneSelectInput)
      this.$emit('update:validationStatus', hasError)
    },
    emitUpdatedFiles(files) {
      this.$emit('update:files', files)
      this.verifyValidation()
    },
    isImage(fileType) {
      if (isNil(fileType)) return false

      return fileType.startsWith('image/')
    },
    hasMultipleFilesError() {
      if (!this.acceptMultiple && !isEmpty(this.files)) {
        this.setSnackbar({
          status: 'error',
          message: 'Não é possível adicionar mais de um arquivo',
        })
        return true
      }
      return false
    },
    selectFiles() {
      if (this.hasMultipleFilesError()) return

      const fileInput = path(['fileInput'], this.$refs)
      if (fileInput) fileInput.click()
    },
    isDuplicateFile(newFile) {
      const hasDuplicate = any(
        (file) => {
          const currentComparisonFile = omit(['id', 'src'], file)
          const newComparisonFile = omit(['id', 'src'], newFile)
          return equals(currentComparisonFile, newComparisonFile)
        },
      )(this.files)
      if (hasDuplicate) {
        this.setSnackbar({
          status: 'error',
          message: 'Não é possível adicionar o mesmo arquivo novamente',
        })
        return true
      }
      return false
    },
    isValidFileFormat(newFile) {
      if (
        not(isEmpty(this.acceptedFiles))
        && not(any(fileType => newFile.type === fileType)(this.acceptedFiles))
      ) {
        this.setSnackbar({
          status: 'error',
          message: 'Formato de arquivo inválido',
        })
        return false
      }
      return true
    },
    async isInLinesRange(loadedFile) {
      if (!this.maxLinesLimit && !this.minLinesLimit) return true

      const { type } = loadedFile

      if (
        type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        && type !== 'text/csv'
      ) return true

      const workbook = read(await loadedFile.arrayBuffer())
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      const items = utils.sheet_to_json(worksheet, { header: 1 })
      const filteredItems = items.filter(row => not(isEmpty(row)))
      const itemsLength = filteredItems.length

      if (this.maxLinesLimit && itemsLength > this.maxLinesLimit) {
        this.setSnackbar({
          status: 'error',
          message: `Não é possível adicionar um arquivo com mais de ${this.maxLinesLimit} linhas`,
        })
        return false
      }

      if (this.minLinesLimit && itemsLength < this.minLinesLimit) {
        this.setSnackbar({
          status: 'error',
          message: `Não é possível adicionar um arquivo com menos de ${this.minLinesLimit} linhas`,
        })
        return false
      }

      return true
    },
    loadFile(newFile) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        const file = clone(newFile)

        reader.onload = (event) => {
          const fileId = this.files.length + 1
          file.id = fileId
          if (this.isImage(file.type)) file.src = event.target.result

          resolve(file)
        }
        reader.readAsDataURL(newFile)
      })
    },
    async addFiles(newFiles) {
      if (this.hasMultipleFilesError()) return

      for (const newFile of newFiles) {
        if (not(this.isValidFileFormat(newFile))) return
        if (this.isDuplicateFile(newFile)) return

        const loadedFile = await this.loadFile(newFile)

        if (!await this.isInLinesRange(loadedFile)) return

        this.files.push(loadedFile)
      }

      this.$refs.fileInput.value = ''
      this.emitUpdatedFiles(this.files)
    },
    updateFile({ fileId, property, value }) {
      const file = find(propEq(fileId, 'id'))(this.files)
      file[property] = value
      this.emitUpdatedFiles(this.files)
    },
    removeFile(file) {
      const indexToRemove = findIndex(propEq(file.id, 'id'))(this.files)
      this.files.splice(indexToRemove, 1)
      this.emitUpdatedFiles(this.files)
    },
    removeAllFiles() {
      this.files = []
    },
  },
}
</script>

<style lang="stylus" scoped>
.dropzone__container
  border: 2px solid #e5e5e5
  transition: 0.2s linear
  min-height: 200px
  cursor pointer

.dropzone__container--dashed
  border-style: dashed

.dropzone__container:hover
  background-color: #f6f6f6

.dropzone__preview-text
  font-size 16px

</style>
