<template>
  <div>
    <v-tabs
      grow
      height="58"
      background-color="light-green"
      hide-slider
    >
      <v-tab active-class="color: primary">
        <span class="tab__title font-weight-bold">Formulários</span>
      </v-tab>
      <v-tab active-class="color: primary">
        <span class="tab__title font-weight-bold">Questões</span>
      </v-tab>
      <v-tab-item>
        <form-list />
      </v-tab-item>
      <v-tab-item>
        <question-list />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import FormList from './FormList'
import QuestionList from './QuestionList'

export default {
  name: 'AgendaSettings',
  components: {
    FormList,
    QuestionList,
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.tab__title
  color #ffffff
</style>
