import eplPrinterAxios from '@/lib/eplPrinterAxios'

const initialState = {
  status: null,
  loading: false,
}

const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_STATUS(state, payload) {
    state.status = payload
  },
}

const actions = {
  sendTicket({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)
      eplPrinterAxios.post('/print', data)
        .then(() => {
          resolve()
        })
        .catch(() => {
          commit('SET_STATUS', 'print_failed')
          reject()
        })
        .finally(() => {
          commit('SET_LOADING', false)
        })
    })
  },
  checkHealth({ commit }) {
    return new Promise((resolve, reject) => {
      eplPrinterAxios.get('/health')
        .then(() => {
          commit('SET_STATUS', 'ok')
          resolve()
        })
        .catch(() => {
          commit('SET_STATUS', 'health_failed')
          reject()
        })
    })
  },
}

const getters = {
  loading: state => state.loading,
  status: state => state.status,
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
}
