<template>
  <div>
    <component-title />
    <div class="mt-6 mx-10">
      <v-row>
        <v-col cols="5">
          <v-col
            mb-2
            shrink
          >
            <span class="column-title">Criar anúncio</span>
          </v-col>
          <v-col
            column
            shrink
          >
            <v-text-field
              v-model="announcement.title"
              label="Título *"
              hide-details
              clearable
              outlined
              :error="$v.announcement.title.$error"
              @blur="$v.announcement.title.$touch()"
            />
            <v-text-field
              :value="announcement.tag.label"
              class="my-3"
              label="Tag"
              hide-details
              outlined
              clearable
              @click:clear="announcement.tag.label = ''"
              @input="parseTagLabelToUpperCase"
            >
              <template #append>
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="announcement.tag.color"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-circle
                    </v-icon>
                  </template>
                  <v-color-picker
                    v-model="announcement.tag.color"
                    hide-canvas
                    hide-inputs
                    hide-mode-switch
                    hide-sliders
                    show-swatches
                    mode="hexa"
                  />
                </v-menu>
              </template>
            </v-text-field>
            <markdown-editor
              v-model="announcement.message"
              class="markdown-editor"
              height="250"
              width=""
              toolbar=""
            />
            <v-btn
              v-if="containsImages"
              color="light-gray"
              class="btn my-3 "
              small
              @click="applyImageStyle"
            >
              Aplicar formatação de imagens
            </v-btn>
            <v-text-field
              v-model="announcement.signature"
              label="Assinatura *"
              class="my-3"
              clearable
              hide-details
              outlined
              :error="$v.announcement.signature.$error"
              @blur="$v.announcement.signature.$touch()"
            />
            <v-autocomplete
              v-model="announcement.permissionsIds"
              :items="permissions"
              item-text="name"
              item-value="id"
              label="Selecione as permissões *"
              multiple
              clearable
              outlined
              hide-details
              :error="$v.announcement.permissionsIds.$error"
              @blur="$v.announcement.permissionsIds.$touch()"
            >
              <template #prepend-item>
                <v-flex
                  class="mb-2"
                  justify-space-around
                >
                  <v-btn
                    depressed
                    small
                    @click="selectAll()"
                  >
                    Todas
                  </v-btn>
                  <v-btn
                    depressed
                    small
                    @click="removeAll()"
                  >
                    Nenhuma
                  </v-btn>
                </v-flex>
              </template>
            </v-autocomplete>
          </v-col>
        </v-col>
        <v-col cols="7">
          <v-flex
            mb-2
            shrink
          >
            <span class="column-title">Prévia do anúncio</span>
          </v-flex>
          <v-flex>
            <v-card
              class="card-preview"
            >
              <announcement-content
                :announcement="announcement"
              />
            </v-card>
          </v-flex>
        </v-col>
      </v-row>
      <div class="d-flex justify-end mt-2 mb-2">
        <v-btn
          class="mr-2"
          color="primary"
          to="/"
          large
          outlined
          @click="setAnnouncementDefaultValue"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          :disabled="shouldDisableSave"
          large
          @click="saveAnnouncement()"
        >
          Salvar Anúncio
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import {
  or,
  isNil,
  isEmpty,
  map,
  prop,
  toUpper,
} from 'ramda'
import { mapActions, mapGetters } from 'vuex'
import {
  required,
} from 'vuelidate/lib/validators'

export default {
  components: {
    AnnouncementContent: () => import('@/components/Announcement/AnnouncementContent'),
    ComponentTitle: () => import('@/components/ComponentTitle'),
  },
  data() {
    return {
      announcementDefaultValues: {
        message: 'Mensagem do anúncio *',
        tagColor: '#4CAF50FF',
        signature: 'Amparo Tech',
      },
      announcement: {
        signature: '',
        message: '',
        tag: {
          label: '',
          color: '',
        },
        permissionsIds: [],
      },
      announcementDate: new Date(),
      MESSAGE_MAX_LENGTH: 2500,
    }
  },
  validations() {
    return {
      announcement: {
        title: { required },
        signature: { required },
        permissionsIds: { required },
      },
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'authentication/permissions',
      user: 'authentication/user',
    }),
    shouldDisableSave() {
      return this.announcementDefaultValues.message === this.announcement.message
        || this.announcement.message.length === 0
    },
    containsImages() {
      return this.announcement.message.match(/<.*img.*>/g)
    },
  },
  async mounted() {
    this.setAnnouncementDefaultValue()
    await this.listPermission()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
      listPermission: 'authentication/listPermission',
      createAnnouncement: 'announcement/createAnnouncement',
    }),
    selectAll() {
      this.announcement.permissionsIds = map(prop('id'), this.permissions)
    },
    removeAll() {
      this.announcement.permissionsIds = []
    },
    isNilOrIsEmpty(value) {
      return or(isNil(value), isEmpty(value))
    },
    buildSnackbar(status, message) {
      this.setSnackbar({
        status,
        message,
      })
    },
    async saveAnnouncement() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.buildSnackbar('error', 'Preencha os campos obrigatórios.')
        return
      }

      try {
        if (isEmpty(this.announcement.tag.label)) this.announcement.tag = null
        await this.createAnnouncement(this.announcement)
        this.setAnnouncementDefaultValue()
        this.$v.$reset()
        this.buildSnackbar('success', 'Anúncio criado com sucesso!')
      } catch (err) {
        this.buildSnackbar('error', 'Erro ao criar anúncio.')
      }
    },
    setAnnouncementDefaultValue() {
      const {
        signature,
        tagColor,
        message,
      } = this.announcementDefaultValues
      this.announcement = {
        title: '',
        signature,
        message,
        tag: {
          label: '',
          color: tagColor,
        },
        permissionsIds: [],
      }
    },
    parseTagLabelToUpperCase(label) {
      if (this.isNilOrIsEmpty(label)) return
      this.announcement.tag.label = toUpper(label)
    },
    applyImageStyle() {
      const messageSplited = this.announcement.message.split('>')

      const newMessage = []
      for (const messagePart of messageSplited) {
        if (messagePart.includes('<img')) {
          newMessage.push(messagePart.concat(' style="width: 100%; heigth: auto; object-fit: fill;"'))
        } else {
          newMessage.push(messagePart)
        }
      }

      this.announcement.message = newMessage.join('>')
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'
@import '../../style/_core/fonts.styl'

.column-title
  @extend .big-p

.card-preview
  border 1px solid lighten(dark-gray, 50%)
  min-width 97%

.markdown-editor
  width 100%

</style>
