import axios from '@/lib/axios'

const initialState = {
  isLoading: false,
  userFeedbacks: [],
  userFeedbackPage: 1,
  userFeedbacksCount: 0,
  userFeedbacksNumberOfPages: 1,
}

const mutations = {
  SET_IS_LOADING(state, payload) {
    state.isLoading = payload
  },
  SET_USER_FEEDBACKS(state, payload) {
    state.userFeedbacks = payload
  },
  SET_USER_FEEDBACK_PAGE(state, payload) {
    state.userFeedbackPage = payload
  },
  SET_USER_FEEDBACKS_COUNT(state, payload) {
    state.userFeedbacksCount = payload
  },
  SET_USER_FEEDBACKS_NUMBER_OF_PAGES(state, payload) {
    state.userFeedbacksNumberOfPages = payload
  },
}

const actions = {
  listUserFeedback({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('SET_IS_LOADING', true)
      axios.get('/user_feedback', { params: { page: state.userFeedbackPage } })
        .then(({ data }) => {
          commit('SET_USER_FEEDBACKS', data.rows)
          commit('SET_USER_FEEDBACKS_COUNT', data.count)
          commit('SET_USER_FEEDBACKS_NUMBER_OF_PAGES', data.numberOfPages)
        })
        .catch(reject)
        .finally(() => commit('SET_IS_LOADING', false))
    })
  },
  createUserFeedback({ commit }, attributes) {
    return new Promise((resolve, reject) => {
      commit('SET_IS_LOADING', true)
      axios.post('/user_feedback', attributes)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
        .finally(() => commit('SET_IS_LOADING', false))
    })
  },
  getUserFeedbackAttachmentUrl(_, params) {
    return new Promise((resolve, reject) => {
      axios.get('/user_feedback_attachment/url', { params })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
  createUserFeedbackAttachment({ commit }, { file, userFeedbackId }) {
    return new Promise((resolve, reject) => {
      commit('SET_IS_LOADING', true)
      axios.post(`/user_feedback_attachment/${userFeedbackId}`, file)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
        .finally(() => commit('SET_IS_LOADING', false))
    })
  },
  setUserFeedbackPage({ commit }, page) {
    commit('SET_USER_FEEDBACK_PAGE', page)
  },
}

export default {
  namespaced: true,
  mutations,
  state: initialState,
  actions,
}
