var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.encounterData)?_c('v-layout',{class:{
      'my-3': _vm.row,
      'justify-space-around': _vm.row,
      'column': _vm.column,
    }},[_vm._l((_vm.encounterData.legacy.Stages),function(stage,stageIndex){return _c('v-flex',{key:stageIndex,class:{
        'card__infos-container--partial-width': _vm.row,
        'card__infos-container--full-width': _vm.column,
        'ma-0': true,
        'pa-0': true,
      },attrs:{"column":""}},_vm._l((stage.Topics),function(topic,topicIndex){return _c('v-flex',{key:stage.Label+topicIndex,attrs:{"column":""}},[(topic.Items.length > 0)?_c('div',[_c('v-flex',{staticClass:"card__group-title mb-1 mt-2"},[(stage.Label != topic.Label)?_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(stage.Label)+" "),(topic.Label)?_c('span',[_vm._v(" - ")]):_vm._e()]):_vm._e(),(topic.Label)?_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(topic.Label)+" ")]):_vm._e()]),_vm._l((topic.Items),function(item){return _c('v-flex',{key:stageIndex+topicIndex+item.Label,staticClass:"mb-1 mt-0"},[(item.Label)?_c('span',{staticClass:"mr-2 font-weight-bold text--nowrap"},[_vm._v(" "+_vm._s(item.Label)+" "),(item.Text)?_c('span',[_vm._v(" : ")]):_vm._e()]):_vm._e(),(item.Text)?_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(item.Text)+" ")]):_vm._e()])})],2):_vm._e()])}),1)}),(_vm.encounterData.legacy.Diagnostics.length > 0)?_c('v-flex',{staticClass:"card__group-title mb-1 mt-2"},[_vm._v(" Diagnósticos ")]):_vm._e(),_vm._l((_vm.encounterData.legacy.Diagnostics),function(diagnostic,diagnosticIndex){return _c('v-flex',{key:'diag'+diagnosticIndex},[(diagnostic.Disease)?_c('v-flex',[_vm._v(" "+_vm._s(diagnostic.Disease.Label)+" ")]):_vm._e()],1)}),(_vm.encounterData.legacy.RequestedExams.length > 0)?_c('v-flex',{staticClass:"card__group-title mb-1 mt-2"},[_vm._v(" Exames Requeridos ")]):_vm._e(),_vm._l((_vm.encounterData.legacy.RequestedExams),function(requestedExam,requestedExamIndex){return _c('v-flex',{key:'req'+requestedExamIndex},[(requestedExam.Exam)?_c('v-flex',[_vm._v(" "+_vm._s(requestedExam.Exam.name)+" ")]):_vm._e()],1)})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }