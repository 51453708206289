<template>
  <div>
    <v-form
      ref="form"
      class="login-form"
      @submit.prevent="submitLogin"
    >
      <v-text-field
        v-model="user.email"
        color="primary"
        label="E-mail"
        filled
        clearable
        required
        :error-messages="$v.user.email.$error ? 'Email inválido' : ''"
        :error="$v.user.email.$error"
        data-testid="login__username--field"
        @blur="$v.user.email.$touch()"
        @change="closeSnackbar"
      />

      <v-text-field
        v-model="user.password"
        color="primary"
        :type="passwordInput ? 'text' : 'password'"
        :append-icon="passwordInput ? 'mdi-eye-off' : 'mdi-eye'"
        label="Senha"
        filled
        required
        :error-messages="$v.user.password.$error ? 'Por favor, insira a senha' : ''"
        :error="$v.user.password.$error"
        data-testid="login__password--field"
        @blur="$v.user.password.$touch()"
        @click:append="passwordInput = !passwordInput"
        @change="closeSnackbar"
      />
      <v-flex>
        <v-btn
          class="mb-3 font-weight-lightbold"
          :disabled="$v.$error || !isFieldsFilled || isLogining"
          :loading="isLogining"
          type="submit"
          color="primary"
          large
          block
          data-testid="login--button"
        >
          Entrar
        </v-btn>
      </v-flex>
      <v-flex
        justify-center
        mt-1
      >
        <v-btn
          class="btn"
          color="primary"
          to="/new-password"
          text
        >
          Esqueci minha senha
        </v-btn>
      </v-flex>
    </v-form>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { path, equals } from 'ramda'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LoginForm',
  data() {
    return {
      name: 'LoginForm',
      user: {},
      passwordInput: false,
    }
  },
  validations() {
    const userValidations = {
      user: {
        email: { required, email },
        password: { required },
      },
    }
    return { ...userValidations }
  },
  computed: {
    ...mapGetters({
      isLogining: 'authentication/isLogining',
    }),
    isFieldsFilled() {
      return this.user.email && this.user.password
    },
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
      closeSnackbar: 'snackbar/closeSnackbar',
      userLogin: 'authentication/userLogin',
    }),
    async submitLogin() {
      this.$v.$touch()
      if (this.$v.$error) return
      try {
        await this.userLogin(this.user)
      } catch (error) {
        const isInvalidCredential = equals(
          path(['response', 'data', 'errorCode'], error),
          'credential_invalid',
        )

        const message = isInvalidCredential
          ? 'Usuário ou senha incorretos!'
          : 'Erro interno.'

        this.setSnackbar({
          status: 'error',
          message,
        })
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.login-form
  background-color: #fff
  width: 98%
  margin: auto
  margin-bottom: 20px
  display: flex
  flex-direction: column
  justify-content: center
  box-shadow: 0 0 20px -4px rgba(78, 85, 89, 0.3)
  padding: 35px
  border-radius: 6px
  @media only screen and (min-width: 600px)
    width: 500px

.v-btn--large
  height 56px
  border-radius 8px
  letter-spacing 1px
</style>
